import {MevacoPage} from "../../model/mevaco-page.model";
import {Action} from "@ngrx/store";
import {Element} from "../../model/product-configuration/element.model";
import {buildBendingStructure} from "../../utils/bending";
import {BendingNode} from "../../model/view-model/bending-node.viewModel";
import {Aabb3, createEmptyAabb3} from "webcad/math";
import {ShapeWithHoles} from "../../model/shape-with-holes";
import {Matrix4, transformPos} from "webcad/math/matrix4";
import {expandAabb3InPlace, isAabb3Empty} from "webcad/math";

let aabbCache = [];

export function elementsAabbAndTotalSurfaceReducer(state:MevacoPage, action:Action):MevacoPage {
  const newElements:Element[] = [];
  let anyChange:boolean = false;
  for (let i = 0; i < state.productConfiguration.elements.length; i++) {
    const element = state.productConfiguration.elements[i];
    const aabb = element.aabb;
    const cashe = aabbCache.find( x => x.aabb === aabb);
    if(!cashe ||
       cashe.data.conture !== element.shape.conture ||
       cashe.data.materialThickness !== state.productConfiguration.material.thickness ||
       cashe.data.bendingLines !== element.breakLines){
      console.debug("Recalculating element size => " + action.type);
      const thickness = +state.productConfiguration.material.thickness;

      let newAabb:Aabb3 = calculateElementAabb(element, thickness/1000);
      if(isAabb3Empty(newAabb)){
        newAabb = {
          min :{x:0,y:0,z:0},
          max :{x:0,y:0,z:0}
        }
      };

      newAabb.min.x = Math.round(newAabb.min.x*10000)/10000;
      newAabb.min.y = Math.round(newAabb.min.y*10000)/10000;
      newAabb.min.z = Math.round(newAabb.min.z*10000)/10000;
      newAabb.max.x = Math.round(newAabb.max.x*10000)/10000;
      newAabb.max.y = Math.round(newAabb.max.y*10000)/10000;
      newAabb.max.z = Math.round(newAabb.max.z*10000)/10000;

      newElements.push({
        ...element,
        aabb:newAabb,
        length3d: Math.round(10000* (newAabb.max.x - newAabb.min.x))/10,
        width3d: Math.round(10000* (newAabb.max.y - newAabb.min.y))/10,
        height3d: Math.round(10000* (newAabb.max.z - newAabb.min.z))/10,
      });
      anyChange = true;
      aabbCache.push({
        aabb: newAabb,
        data: {
          conture: element.shape.conture,
          materialThickness: state.productConfiguration.material.thickness,
          bendingLines: element.breakLines
        }
      })
    } else{
      newElements.push(element)
    }
  }

  const totalSurface = newElements.reduce<number>( (prev, curr) => prev + surfaceFromElementAabb(curr), 0);

  if (anyChange) {

    const newAabbCache = [];
    for (let i = 0; i < aabbCache.length; i++) {
      const aabbCacheElement = aabbCache[i];
      if( newElements.find( x => x.aabb === aabbCacheElement.aabb ) ){
        newAabbCache.push(aabbCacheElement);
      }
    }
    aabbCache = newAabbCache;

    return {
      ...state,
      productConfiguration: {
        ...state.productConfiguration,
        elements: newElements,
        maxElementLength: Math.max( ...newElements.map( element => element.aabb.max.x - element.aabb.min.x ) ),
        maxElementWidth: Math.max( ...newElements.map( element => element.aabb.max.y - element.aabb.min.y ) ),
        totalSurface: totalSurface,
      }
    };
  } else {
    if (state.productConfiguration.totalSurface !== totalSurface) {
      return {
        ...state,
        productConfiguration: {
          ...state.productConfiguration,
          totalSurface: totalSurface,
        }
      };
    } else {
      return state;
    }
  }
}

function surfaceFromElementAabb(element: Element): number {
  return (element.aabb.max.x - element.aabb.min.x) * (element.aabb.max.y - element.aabb.min.y) * element.quantity;
}

function calculateElementAabb(element:Element, materialThickness:number):Aabb3 {
  const aabb:Aabb3 = createEmptyAabb3();
  if(!isNaN( materialThickness )) {
    const shapeWithoutHoles: ShapeWithHoles = {
      conture: element.shape.conture,
      aabb: element.shape.aabb,
      area: 0,
      hash: "",
      holes: []
    }
    const bendingStructure = buildBendingStructure(shapeWithoutHoles, element.breakLines, false, materialThickness);
    if (bendingStructure) {
      addBendingNodeToAabb(bendingStructure, materialThickness, aabb);
    }
  }
  return  aabb;
}

function addBendingNodeToAabb(node: BendingNode, thickness:number, aabb:Aabb3):void {
  const region = node.flatRegion;

  const t2 = thickness / 2;
  //mesh._worldMatrix.copyFrom(node.world);
  if(!!node.flatRegion){
    const mt = node.world.m
    const world:Matrix4 = {
      n11: mt[0], n12: mt[4], n13: mt[8], n14: mt[12],
      n21: mt[1], n22: mt[5], n23: mt[9], n24: mt[13],
      n31: mt[2], n32: mt[6], n33: mt[10], n34: mt[14],
      n41: mt[3], n42: mt[7], n43: mt[11], n44: mt[15],
    };
    for (let i = 0; i < node.flatRegion.vertices.length; i++) {
      const vetex = node.flatRegion.vertices[i];
      vetex.z += t2;
      expandAabb3InPlace(aabb, transformPos(world, vetex));
      vetex.z -= thickness;
      expandAabb3InPlace(aabb, transformPos(world, vetex));
    }
  }

  for (let i = 0; i < node.children.length; i++) {
    const child = node.children[i];
    addBendingNodeToAabb(child, thickness,aabb);
  }
}
