export interface RuleAction {
  fieldName: string;
  code: string;
  type: string;
  message: string;
  ruleId: number;
}

export interface RuleActions {
  general: RuleAction[];
  elements: {[key: number]: RuleAction[] };
}

function anyActionInListOfType(ruleActionsList: RuleAction[], type: string): boolean {
  return ruleActionsList.some( action => action.type.toUpperCase() === type );
}

export function anyActionOfType(ruleActions: RuleActions, type: string): boolean {
  type = type.toUpperCase();
  if (anyActionInListOfType(ruleActions.general, type)) {
    return true;
  }
  for (const actionsList in ruleActions.elements) {
    if (anyActionInListOfType(ruleActions.elements[actionsList], type)) {
      return true;
    }
  }
  return false;
}
export function anyErrorAction(ruleActions: RuleActions): boolean {
  return  anyActionOfType(ruleActions, 'error');
}

export function forEachRuleAction(ruleActions: RuleActions, callback: (ruleAction: RuleAction, position: number) => void ): void {
  ruleActions.general.forEach( ruleAction => callback(ruleAction, -1));
  for (const actionsList in ruleActions.elements) {
    ruleActions.elements[actionsList].forEach(ruleAction => callback(ruleAction, +actionsList));
  }
}
