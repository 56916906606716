import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { from, fromEvent } from "rxjs";
import { map, take, withLatestFrom } from "rxjs/operators";
import {
  Bending,
  getBendParmas,
} from "../../../../../../src/app/model/dataset/bending.model";
import { MeasurementMask } from "../../../../../../src/app/model/measurement-mask.enum";
import { PlateService } from "../../../../../../src/app/services/plate.service";
import { SetError } from "../../../../../../src/app/store/actions/errorable.actions";
import {
  MevacoState,
  ProductConfigurationAddElement,
  getBendings,
  getSelectedMaterialCode,
  getSelectedMaterialThickness,
} from "../../../../../app/store";
import { DebounceInputComponent } from "../../../debounce-input/debounce-input.component";
import { TemplateShapeBuilder, TemplateUtils } from "../../templatesUtils";
import { BendsTogglesComponent } from "../bends-toggles/bends-toggles.component";

@Component({
  selector: "single-bend-casset",
  templateUrl: "./single-bend.component.html",
  styleUrls: [
    "../../../element-creator/element-creator.component.css",
    "./single-bend.component.css",
  ],
})
export class SingleBendComponent implements OnInit {
  @Output() isModalOpen = new EventEmitter();
  @Output() dismissModal = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @ViewChild("simple_h", { static: true }) simple_h: DebounceInputComponent;
  @ViewChild("simple_b", { static: true }) simple_b: DebounceInputComponent;
  @ViewChild("simple_l", { static: true }) simple_l: DebounceInputComponent;
  @ViewChild("closeButton", { static: true }) closeButton: ElementRef;
  @ViewChild("bendsToggles", { static: true })
  bendsToggles: BendsTogglesComponent;
  private disposeTabs: () => void;

  isBendingSwitched = true;

  constructor(
    private store: Store<MevacoState>,
    public templateUtils: TemplateUtils,
    private plateService: PlateService
  ) {}

  ngOnInit() {
    this.createFromSingleBendCassete();
  }

  ngAfterViewInit() {
    this.disposeTabs = this.templateUtils.setupFocusTabs([
      this.simple_b,
      this.simple_l,
      this.simple_h,
    ]);
    this.simple_l.valueChanged.subscribe((v) => {
      if (+this.simple_l.value < 300 && +this.simple_b.value < 300) {
        this.simple_b.valueControl.setValue(300);
      }
    });

    this.simple_b.valueChanged.subscribe((v) => {
      if (+this.simple_l.value < 300 && +this.simple_b.value < 300) {
        this.simple_l.valueControl.setValue(300);
      }
    });
  }

  ngOnDestroy() {
    this.disposeTabs();
  }

  isModalOpenMethod() {
    this.isModalOpen.emit(false);
  }

  dismiss(str: string) {
    this.dismissModal.emit(str);
  }

  close(input: {}) {
    this.closeModal.emit(input);
  }

  switchBending(side: boolean) {
    this.isBendingSwitched = side;
  }
  createFromSingleBendCassete() {
    const obs = fromEvent(this.closeButton.nativeElement, "click").pipe(
      map(() => {
        return {
          h: this.simple_h.value,
          b: this.simple_b.value,
          l: this.simple_l.value,
        };
      })
    );

    from(obs)
      .pipe(
        withLatestFrom(
          this.store.pipe(select(getBendings)),
          this.store.pipe(select(getSelectedMaterialThickness)),
          this.store.pipe(select(getSelectedMaterialCode))
        ),
        take(1)
      )
      .subscribe(
        async ([result, bendings, thickness, code]: [
          any,
          Bending[],
          string,
          string
        ]) => {
          const bendAngle = this.isBendingSwitched ? Math.PI / 2 : -Math.PI / 2;
          let bendParams = null;
          try {
            bendParams = getBendParmas(code, +thickness, bendAngle, bendings);
          } catch (e) {
            this.store.dispatch(
              new SetError(this.templateUtils.translate("Bending not found"))
            );
            return;
          }

          const bendReduction = bendParams.bendAllowance / 2 - bendParams.ossb;
          const h = result.h / 1000 + bendReduction;
          const b =
            result.b / 1000 +
            (this.bendsToggles.right ? bendReduction : 0) +
            (this.bendsToggles.left ? bendReduction : 0);
          const a =
            result.l / 1000 +
            (this.bendsToggles.front ? bendReduction : 0) +
            (this.bendsToggles.back ? bendReduction : 0);

          const templateShapeBuilder: TemplateShapeBuilder =
            new TemplateShapeBuilder(this.plateService, { x: 0, y: 0 });

          if (this.bendsToggles.front) {
            templateShapeBuilder.bendingBy(
              { x: b, y: 0 },
              bendParams,
              bendAngle,
              "1"
            );
            templateShapeBuilder.moveBy({ x: 0, y: -h });
            templateShapeBuilder.moveBy({ x: b, y: 0 });
            templateShapeBuilder.measurementBy(
              { x: 0, y: result.h / 1000 },
              0.1 + h,
              MeasurementMask.Plate
            );
            templateShapeBuilder.moveBy({ x: 0, y: h });
          } else {
            templateShapeBuilder.moveBy({ x: b, y: 0 });
          }
          templateShapeBuilder.measurementByWithOffset(
            { x: 0, y: this.bendsToggles.front ? bendReduction : 0 },
            { x: 0, y: result.l / 1000 },
            0.1 + h,
            MeasurementMask.Plate
          );
          if (this.bendsToggles.right) {
            templateShapeBuilder.bendingBy(
              { x: 0, y: a },
              bendParams,
              bendAngle,
              "2"
            );
            templateShapeBuilder.moveBy({ x: h, y: 0 });
            templateShapeBuilder.moveBy({ x: 0, y: a });
            if (!this.bendsToggles.front && !this.bendsToggles.left) {
              templateShapeBuilder.measurementBy(
                { x: -result.h / 1000, y: 0 },
                0.1 + h,
                MeasurementMask.Plate
              );
            }
            templateShapeBuilder.moveBy({ x: -h, y: 0 });
          } else {
            templateShapeBuilder.moveBy({ x: 0, y: a });
          }

          templateShapeBuilder.measurementByWithOffset(
            { x: this.bendsToggles.right ? -bendReduction : 0, y: 0 },
            { x: -result.b / 1000, y: 0 },
            0.1 + h,
            MeasurementMask.Plate
          );
          if (this.bendsToggles.back) {
            templateShapeBuilder.bendingBy(
              { x: -b, y: 0 },
              bendParams,
              bendAngle,
              "3"
            );
            templateShapeBuilder.moveBy({ x: 0, y: h });
            templateShapeBuilder.moveBy({ x: -b, y: 0 });
            if (
              !this.bendsToggles.front &&
              !this.bendsToggles.left &&
              !this.bendsToggles.right
            ) {
              templateShapeBuilder.measurementBy(
                { x: 0, y: -result.h / 1000 },
                0.1 + h,
                MeasurementMask.Plate
              );
            }
            templateShapeBuilder.moveBy({ x: 0, y: -h });
          } else {
            templateShapeBuilder.moveBy({ x: -b, y: 0 });
          }
          if (this.bendsToggles.left) {
            templateShapeBuilder.bendingBy(
              { x: 0, y: -a },
              bendParams,
              bendAngle,
              "4"
            );
            templateShapeBuilder.moveBy({ x: -h, y: 0 });
            templateShapeBuilder.moveBy({ x: 0, y: -a });
            if (!this.bendsToggles.front) {
              templateShapeBuilder.measurementBy(
                { x: result.h / 1000, y: 0 },
                0.1,
                MeasurementMask.Plate
              );
            }
          }
          templateShapeBuilder.addCassetteMeasurements(
            {
              x: this.bendsToggles.left ? bendReduction : 0,
              y: this.bendsToggles.front ? bendReduction : 0,
              z: 0,
            },
            {
              x: result.b / 1000,
              y: result.l / 1000,
              z: (this.isBendingSwitched ? result.h : -result.h) / 1000,
            }
          );

          const currentElement = await templateShapeBuilder.buildTemplate();
          currentElement.form = "WK";
          this.store.dispatch(
            new ProductConfigurationAddElement(currentElement)
          );
        }
      );
  }
}
