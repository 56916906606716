

  <div class="webcad-container">

    <hints></hints>

    <div class="viewContainer" #viewParent (mouseout)="hideHint()" (mouseover)="showHint()">
      <!--<canvas (mouseenter)="focusCanvas()" class="webcad" #renderTarget></canvas>-->
      <!--<canvas #cubeView class="cubeView"></canvas>-->
      <status-bar *ngIf="showView3dStatusBar | async"></status-bar>
    </div>


    <errors-visualizer></errors-visualizer>

    <div class="zoom-container">

      <button (mousedown)="zoomOut()" (mouseup)="stopAction()" (mouseleave)="stopAction()" class="zoom-btn">
        <img src="assets/Zoom_minus.svg" alt="minus">
      </button>
      <button (mousedown)="zoomIn()" (mouseup)="stopAction()" (mouseleave)="stopAction()" class="zoom-btn margin-left-5">
        <img src="assets/Zoom_plus.svg" alt="plus">
      </button>
      <button type="button" (click)="zoomToFit()" class="fit-zoom-btn">
        {{translate("Fit Zoom")}}
      </button>

    </div>

  </div>




