import {getEmptyAaabb, mergeAabb, Vector2} from 'webcad';
import {ShapeWithHoles} from './shape-with-holes';
import {aabbOfPolyline} from 'webcad/models';
import {Aabb2} from 'webcad/models';

export interface PerforationAreaModel {
  shape: ShapeWithHoles;
  offset: Vector2;
  rotation: number;
}

export function getPerforationAreasGroup(areas: PerforationAreaModel[], selectedIndex: number, dependedMode: boolean): PerforationAreaModel[] {
  const result = [];
  if (selectedIndex !== null && selectedIndex >= 0 && selectedIndex < areas.length ) {
    result.push(areas[selectedIndex]);
    if (dependedMode) {
      let selectrdAabb: Aabb2 = null;
      const aabbs = areas.map<{aabb: Aabb2; index: number}>((area, index) => {
        const aabb = aabbOfPolyline(area.shape.conture);
        if (index === selectedIndex) { selectrdAabb = aabb; }
        return {aabb: aabb, index: index};
      } );

      aabbs.sort((a, b) => a.aabb.min.x - b.aabb.min.x);
      let column: {min: number, max: number} = {
        min: aabbs[0].aabb.min.x,
        max: aabbs[0].aabb.max.x
      };

      const columns: {min: number, max: number}[] = [column];
      for (let i = 1; i < aabbs.length; i++) {
        const aabb = aabbs[i];
        if (aabb.aabb.min.x > column.max) {
          column = {
            min: aabb.aabb.min.x,
            max: aabb.aabb.max.x
          };
          columns.push(column);
        } else  {
          column.max = Math.max(aabb.aabb.max.x, column.max);
        }

      }
      column = columns.find((column) => column.min <= selectrdAabb.min.x && column.max >= selectrdAabb.min.x);

      for (let i = 0; i < aabbs.length; i++) {
        const aabb = aabbs[i].aabb;
        if (aabb.min.x <= column.max && aabb.max.x >= column.min) {
          result.push(areas[aabbs[i].index]);
        }
      }
    }
  }
  return result;
}

export function getPerforationAreasAabb(areas: PerforationAreaModel[]): Aabb2 {
  return areas.reduce( (aabb, perfArea) => mergeAabb(aabb, perfArea.shape.aabb), getEmptyAaabb());
}
