// import {el} from '@angular/platform-browser/testing/src/browser_util';

import {Matrix, RenderingManager, Scene, SubMesh, Vector3} from "webcad/babylonjs/core";

(window as any).sortVal = function (a, view) {
  const aMetadata = a.getMesh().metadata;
  const aVp = a.getMesh().getWorldMatrix().multiply(view);
  let aZ = Vector3.TransformCoordinates(
    new Vector3(
      aMetadata.region.aabb.min.x,
      aMetadata.region.aabb.min.y,
      0
    ),
    aVp
  ).z;
  aZ = Math.min(
    aZ,
    Vector3.TransformCoordinates(
      new Vector3(
        aMetadata.region.aabb.min.x,
        aMetadata.region.aabb.max.y,
        0
      ),
      aVp
    ).z
  );
  aZ = Math.min(
    aZ,
    Vector3.TransformCoordinates(
      new Vector3(
        aMetadata.region.aabb.max.x,
        aMetadata.region.aabb.max.y,
        0
      ),
      aVp
    ).z
  );
  aZ = Math.min(
    aZ,
    Vector3.TransformCoordinates(
      new Vector3(
        aMetadata.region.aabb.max.x,
        aMetadata.region.aabb.min.y,
        0
      ),
      aVp
    ).z
  );
  aZ = Math.round(aZ * 1000000) * 100 - aMetadata.renderPriority;
  aMetadata.sortVal = aZ;
  return aZ;
};

export class RenderingOrder {
  private view: Matrix;
  init(scene: Scene) {
    scene.onBeforeDrawPhaseObservable.add(() => {
      this.view = scene.activeCamera.getViewMatrix();
    });

    scene.setRenderingOrder(2, this.compare.bind(this));
    scene.setRenderingAutoClearDepthStencil(3, false);
  }

  initRenderManager(manager: RenderingManager) {
    manager.setRenderingOrder(2, this.compare.bind(this));
    manager.setRenderingAutoClearDepthStencil(3, false);
  }

  compare(a: SubMesh, b: SubMesh): number {
    const aVal = (window as any).sortVal(a, this.view);
    const bVal = (window as any).sortVal(b, this.view);
    return bVal - aVal;
  }
}
