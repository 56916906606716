import {rotateVector2, Vector2} from '../math';

export interface Aabb2 {
    min: Vector2;
    max: Vector2;
}

export function mergeAabb(l: Aabb2, k: Aabb2): Aabb2 {
    return {
        min: {
            x: Math.min(l.min.x, k.min.x),
            y: Math.min(l.min.y, k.min.y),
        },
        max: {
            x: Math.max(l.max.x, k.max.x),
            y: Math.max(l.max.y, k.max.y),
        }

    };
}

export function mergeAabbInPlace(l: Aabb2, k: Aabb2): void {
    l.min.x = Math.min(l.min.x, k.min.x);
    l.min.y = Math.min(l.min.y, k.min.y);
    l.max.x = Math.max(l.max.x, k.max.x);
    l.max.y = Math.max(l.max.y, k.max.y);
}

export function rotateAabb(aabb: Aabb2, angle: number): Aabb2 {
    const c = Math.cos(angle);
    const s = Math.sin(angle);

    const p1: Vector2 = {
        x: c * aabb.min.x - s * aabb.min.y,
        y: s * aabb.min.x + c * aabb.min.y
    };
    const p2: Vector2 = {
        x: c * aabb.max.x - s * aabb.min.y,
        y: s * aabb.max.x + c * aabb.min.y
    };
    const p3: Vector2 = {
        x: c * aabb.max.x - s * aabb.max.y,
        y: s * aabb.max.x + c * aabb.max.y
    };
    const p4: Vector2 = {
        x: c * aabb.min.x - s * aabb.max.y,
        y: s * aabb.min.x + c * aabb.max.y
    };
    return {
        min: {
            x: Math.min(Math.min(p1.x, p2.x), Math.min(p3.x, p4.x)),
            y: Math.min(Math.min(p1.y, p2.y), Math.min(p3.y, p4.y)),
        },
        max: {
            x: Math.max(Math.max(p1.x, p2.x), Math.max(p3.x, p4.x)),
            y: Math.max(Math.max(p1.y, p2.y), Math.max(p3.y, p4.y)),
        }

    };
}

export function getEmptyAaabb(): Aabb2 {
    return {
        min: {x: Number.MAX_VALUE, y: Number.MAX_VALUE},
        max: {x: -Number.MAX_VALUE, y: -Number.MAX_VALUE},
    };
}

export function setEmptyAaabb(aabb: Aabb2): void {
    aabb.min.x = Number.MAX_VALUE;
    aabb.min.y = Number.MAX_VALUE;
    aabb.max.x = -Number.MAX_VALUE;
    aabb.max.y = -Number.MAX_VALUE;

}

export function isAabb2Empty(aabb: Aabb2): boolean {
    return aabb.min.x === Number.MAX_VALUE;
}

export function expandAabb(a: Aabb2, v: Vector2): Aabb2 {
    return {
        min: {
            x: Math.min(a.min.x, v.x),
            y: Math.min(a.min.y, v.y),
        },
        max: {
            x: Math.max(a.max.x, v.x),
            y: Math.max(a.max.y, v.y),
        }
    };
}

export function expandAabbInPlace(a: Aabb2, v: Vector2): void {
    a.min.x =  Math.min(a.min.x, v.x);
    a.max.x =  Math.max(a.max.x, v.x);
    a.min.y =  Math.min(a.min.y, v.y);
    a.max.y =  Math.max(a.max.y, v.y);
}

export function createAabb2FromTwoPoints(a: Vector2, b: Vector2): Aabb2 {
    return {
        min: {x: Math.min(a.x, b.x), y: Math.min(a.y, b.y)},
        max: {x: Math.max(a.x, b.x), y: Math.max(a.y, b.y)}
    };
}

//
/**
 * @deprecated this function is there for a wile I dont want to change it to avoid regression consider using aabb2ContainsPoint
 */
export function isPointInAabb2(point: Vector2, a: Aabb2): boolean {
    return (Aabb2Size(a) > 0) && (point.x > a.min.x && point.x < a.max.x && point.y > a.min.y && point.y < a.max.y);
}

export function aabb2ContainsPoint(a: Aabb2, point: Vector2): boolean {
    return (point.x >= a.min.x && point.x <= a.max.x && point.y >= a.min.y && point.y <= a.max.y);
}

/**
 * @deprecated this function is there for a wile I dont want to change it to avoid regression consider using aabb2Surface
 */
export function Aabb2Size(aabb: Aabb2): number {
    let xSize = (aabb.max.x - aabb.min.x);
    if (xSize <= 0) {
        xSize = 1;
    }
    let ySize = (aabb.max.y - aabb.min.y);
    if (ySize <= 0) {
        ySize = 1;
    }
    return !isAabb2Empty(aabb) ? xSize * ySize : 0;
}

export function aabb2Surface(aabb: Aabb2): number {
    return (aabb.max.x - aabb.min.x) * (aabb.max.y - aabb.min.y);
}

export function isAabbInAabb(outer: Aabb2, inner: Aabb2): boolean {
    return outer.min.x < inner.min.x && outer.min.y < inner.min.y && outer.max.x > inner.max.x && outer.max.y > inner.max.y;
}

export function aabbCenter(aabb:Aabb2): Vector2 {
    return {
        x: (aabb.min.x + aabb.max.x)/2,
        y: (aabb.min.y + aabb.max.y)/2
    }
}