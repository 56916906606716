import {Action} from '@ngrx/store';
import {UserTemplate} from '../../model/user-template.model';

export const ADD_USER_TEMPLATE = 'ADD USER TEMPLATE';

export class AddUserTemplate implements Action {
  readonly type: string = ADD_USER_TEMPLATE;

  constructor(public payload: Template) {

  }
}

export const ADD_USER_TEMPLATE_SUCCESS = 'ADD USER TEMPLATES SUCCESS';

export class AddUserTemplateSuccess implements Action {
  readonly type: string = ADD_USER_TEMPLATE_SUCCESS;

  constructor(public payload: UserTemplate) {
  }

}

export interface Template {
  id: number;
  name: string;
}

export const CONFIGURE_USER_TEMPLATE = 'CONFIGURE USER TEMPLATE';

export class ConfigureUserTemplate implements Action {
  readonly type: string = CONFIGURE_USER_TEMPLATE;

  constructor(public payload: Template) {

  }
}

export const CONFIGURE_USER_TEMPLATE_SUCCESS = 'CONFIGURE USER TEMPLATE SUCCESS';

export class ConfigureUserTemplateSuccess implements Action {
  readonly type: string = CONFIGURE_USER_TEMPLATE_SUCCESS;

  constructor(public payload: boolean) {

  }
}

export const GET_USER_TEMPLATES = 'GET USER TEMPLATES';

export class GetUserTemplates implements Action {
  readonly type: string = GET_USER_TEMPLATES;

  constructor(public payload: string = null) {

  }
}

export const GET_USER_TEMPLATES_SUCCESS = 'GET USER TEMPLATES SUCCESS';

export class GetUserTemplesSuccess implements Action {
  readonly type: string = GET_USER_TEMPLATES_SUCCESS;

  constructor(public payload: UserTemplate[]) {
  }

}

export const DELETE_USER_TEMPLATE = 'DELETE USER TEMPLATE';

export class DeleteUserTemplate implements Action {
  readonly type: string = DELETE_USER_TEMPLATE;

  constructor(public payload: number) {

  }
}

export const DELETE_USER_TEMPLATE_SUCCESS = 'DELETE USER TEMPLATE SUCCESS';

export class DeleteUserTemplateSuccess implements Action {
  readonly type: string = DELETE_USER_TEMPLATE_SUCCESS;

  constructor(public payload: number) {

  }
}


export type UserTemplateActions =
  DeleteUserTemplate
  | GetUserTemplesSuccess
  | GetUserTemplates
  | AddUserTemplate
  | DeleteUserTemplateSuccess
  | AddUserTemplateSuccess;
