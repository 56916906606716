import {UserPattern} from '../../model/user-pattern.model';
import {
  ADD_USER_PATTERN_SUCCESS, AddUserPatternSuccess,
  DELETE_USER_PATTERN_SUCCESS, GET_USER_PATTERNS_SUCCESS,
  UserPatternActions
} from '../actions/user-pattern.actions';

export function userPatternsReducer(state: UserPattern[] = [], action: UserPatternActions): UserPattern[] {
  switch (action.type) {
    case GET_USER_PATTERNS_SUCCESS:
      return action.payload as UserPattern[];
    case DELETE_USER_PATTERN_SUCCESS: {
      const id = state.findIndex((v) => v.id === action.payload);
      if (id > -1) {
        const newState = state.splice(id, 1);
        return [...state];
      }
      break;
    }
    case ADD_USER_PATTERN_SUCCESS: {
      return [...state, (<AddUserPatternSuccess>action).payload];
    }
  }
  return state;
}
