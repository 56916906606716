import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import {
  MatSlideToggle,
  MatSlideToggleChange,
} from "@angular/material/slide-toggle";
import { Action, Store, select } from "@ngrx/store";
import { Observable, Subject, Subscription, combineLatest, of } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { RangeSlideDirective } from "../../../directives/range.directive";
import { getPositionIconUrl } from "../../../model/dataset/dataset.model";
import {
  HoleSize,
  sizeToString,
  stringToSize,
} from "../../../model/dataset/perforation-format.model";
import { PerforationType } from "../../../model/product-configuration/perforation-type.model";
import {
  formatToString,
  getCurrentAreaOpen,
  getPerforationSizeData,
  is2d,
  sizeStringToPerforationSize,
} from "../../../model/product-configuration/perforation.model";
import {
  Subpanel,
  SubpanelCurrentState,
  Subpanels,
} from "../../../model/right-panel.model";
import { SmallerHoleSize } from "../../../model/smaller-hole-size.model";
import { TranslationProvider } from "../../../providers/translation.provider";
import { PossibleOptionsService } from "../../../services/possible-options.service";
import {
  EnableEffect,
  EnableFading,
  EnablePerfaction,
  PerforationFormatChange,
  PerforationGeometryChange,
  PerforationPositionChange,
  PerforationShapeChange,
  PerforationSizeChange,
  PerforationTypeChange,
  ProductConfigurationSetP1,
  ProductConfigurationSetP2,
  ProductConfigurationSetPerfactionForm,
  ProductConfigurationSetPerfactionFormat,
  ProductConfigurationSetPerfactionLabel,
  ProductConfigurationSetPerfactionSizes,
  SetPerfactionConfig,
  SetPerfactionEditorState,
  SetSubpanelState,
  ShowCustomPatternEditor,
  ToggleCustomPattern,
  WallpaperAllowHolesUpdate,
  WallpaperChange,
  WallpaperZoomUpdate,
} from "../../../store/actions";
import {
  MevacoState,
  areAllPerforationsAreasSimpleRectangle,
  getIsCustomPerforation,
  getIsPerfactionEnabled,
  getMultiSizePatternForms,
  getMultiSizePatternLabels,
  getMultiSizePatternSpacing,
  getPerfactionBrightness,
  getPerfactionIsInverted,
  getPerforationSize,
  getSelectedMultiSizePattern,
  getSmallerHolesForCurrentConfiguration,
  getWallpaperName,
  isEffectEnabled,
  isFadingEnabled,
  perfactionEditorIsOpened,
  perforationPositionCanBeSelected,
} from "../../../store/reducers";
import { Dropdown, DropdownOption } from "../../dropdown/dropdown.component";
import { MultiselectOption } from "../../multiselect/multiselect.component";
import { SliderControl } from "../../slider/slider.control";

@Component({
  selector: "perforation-configurator",
  templateUrl: "./perforation-configurator.component.html",
  styleUrls: ["./perforation-configurator.component.css"],
})
export class PerforationConfiguratorComponent implements OnInit {
  constructor(
    private translationProvider: TranslationProvider,
    private store: Store<MevacoState>,
    private possibleOptionsService: PossibleOptionsService
  ) {
    this.zoomInput = new FormGroup({
      input: new FormControl(1),
      holes: new FormControl(),
    });
  }
  public environment = environment;
  public perforation: Dropdown;
  public geometry: Dropdown;
  public perforationTypeDropdown: Dropdown;
  public size: Dropdown;
  public format: Dropdown;
  public wallpaper: Dropdown;
  public showP1Input: Observable<boolean>;
  public showP2Input: Observable<boolean>;
  public p1: Observable<string>;
  public position: Dropdown;
  public openArea: Observable<string>;
  public p2: Observable<string>;
  public currentSize: HoleSize;
  public zoomInput: FormGroup;
  public holesValue: Subject<boolean> = new Subject();
  public holesEnabled: boolean;
  public allowHalfHoles: Observable<boolean>;
  public allowZoom: Observable<boolean>;
  public minZoom: Observable<number>;
  public maxZoom: Observable<number>;
  public sliderValue = 0;
  public SubpanelCurrentState = SubpanelCurrentState;
  public Subpanels = Subpanels;
  public effectsSubpanelState: Subpanel;
  public fadingSubpanelState: Subpanel;
  private subscriptions: Subscription[] = [];
  public perfactionEditorIsOpened$: Observable<boolean>;
  public PerforationType = PerforationType;
  public isPerfactionEnabled = this.store.pipe(select(getIsPerfactionEnabled));
  public isEffectEnabled = this.store.pipe(select(isEffectEnabled));
  public isFadingEnabled = this.store.pipe(select(isFadingEnabled));
  public brightnessControl = new SliderControl(
    this.store.pipe(select(getPerfactionBrightness)),
    (v) => this.store.dispatch(new SetPerfactionConfig({ brightness: v }))
  );
  inverted = this.store.pipe(select(getPerfactionIsInverted));

  public brightness = 0;
  public holeSizes = [];
  public selectedHoleSizes: number[];
  public selectedHoleSizesData: SmallerHoleSize[];

  public costomPattern = this.store.pipe(select(getIsCustomPerforation));
  @Input() titleColor = "default";
  @ViewChild("fadingToggle", { static: true })
  fadingSubpanelToggle: MatSlideToggle;
  @ViewChild("effectsToggle", { static: true })
  effectsSubpanelToggle: MatSlideToggle;
  @ViewChild("perfactionToggle", { static: true })
  perfactionToggle: MatSlideToggle;
  @ViewChild(RangeSlideDirective, { static: true })
  _controlNonStatic: RangeSlideDirective;
  public disablePositions: Observable<boolean>;

  mockedValues = ["One", "Two", "Three", "Four"];
  public perfactionFormDropdown = new Dropdown(
    this.translate("perfactionForm"),
    this.store.pipe(
      select(getMultiSizePatternForms),
      map((forms) =>
        forms.map(
          (form) => new DropdownOption("text", this.translate(form), true, form)
        )
      )
    ),
    this.store.pipe(
      select(getSelectedMultiSizePattern),
      map((pefactionConf) => pefactionConf.form)
    ),
    (value) =>
      this.store.dispatch(new ProductConfigurationSetPerfactionForm(value)),
    null
  );
  public perfactionLabelDropdown = new Dropdown(
    this.translate("perfactionLabel"),
    this.store.pipe(
      select(getMultiSizePatternLabels),
      map((labels) =>
        labels.map(
          (label) =>
            new DropdownOption("text", this.translate(label), true, label)
        )
      )
    ),
    this.store.pipe(
      select(getSelectedMultiSizePattern),
      map((pefactionConf) => pefactionConf.label)
    ),
    (value) =>
      this.store.dispatch(new ProductConfigurationSetPerfactionLabel(value)),
    null
  );
  public thirdDropdown = new Dropdown(
    this.translate("perfactionSpacing"),
    this.store.pipe(
      select(getMultiSizePatternSpacing),
      map((formats) =>
        formats.map(
          (format) => new DropdownOption("text", format, true, format)
        )
      )
    ),
    this.store.pipe(
      select(getSelectedMultiSizePattern),
      map((pefactionConf) => {
        return pefactionConf.format;
      })
    ),
    (value) =>
      this.store.dispatch(new ProductConfigurationSetPerfactionFormat(value)),
    null
  );

  // public setPerforationRotation(r: string) {
  //   this.store.dispatch(new ProductConfigurationSetPerforationRotation(+r));
  // }
  public showPositions: Observable<boolean> = this.store.pipe(
    select(areAllPerforationsAreasSimpleRectangle)
  );

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }

  createDropDown(
    name: string,
    value: Observable<string>,
    options: Observable<DropdownOption[]>,
    actionFactory: (string) => Action,
    possibleOptions: Observable<string[]> = of(null)
  ) {
    return new Dropdown(
      name,
      options,
      value,
      (v) => this.store.dispatch(actionFactory(v)),
      null,
      possibleOptions
    );
  }

  ngOnInit() {
    this.perfactionEditorIsOpened$ = this.store.pipe(
      select(perfactionEditorIsOpened)
    );

    this.store
      .pipe(
        select(
          (state) => state.model.productConfiguration.wallpaper.halfHolesEnabled
        )
      )
      .subscribe((x) => (this.holesEnabled = x === 1));

    this.perforationTypeDropdown = this.createDropDown(
      this.translate("Basic Pattern Definition"),
      this.store.pipe(
        select((state) => state.model.productConfiguration.activePerforation)
      ) as Observable<string>,
      this.getPerforationTypes(),
      (value) => new PerforationTypeChange(value)
    );

    this.store
      .pipe(
        select(getPerforationSize),
        map((v) => {
          return sizeStringToPerforationSize(v);
        })
      )
      .subscribe((v) => (this.currentSize = v));
    this.geometry = this.createDropDown(
      this.translate("Geometry"),
      this.store.pipe(
        select(
          (state) => state.model.productConfiguration.perforation.geometryType
        )
      ) as Observable<string>,
      this.getPerforationsTypeOptions(),
      (value) => new PerforationGeometryChange(value)
    );

    this.perforation = this.createDropDown(
      this.translate("Perforation"),
      this.store.pipe(
        select(
          (state) =>
            state.model.productConfiguration.perforation.perforationType
        )
      ) as Observable<string>,
      this.getPerforationsOptions(),
      (value) => new PerforationShapeChange(value),
      this.possibleOptionsService.possiblePerforations
    );

    this.size = this.createDropDown(
      this.translate("Size"),
      this.store.pipe(
        select((state) => state.model.productConfiguration.perforation.size)
      ) as Observable<string>,
      this.getSizeOptions(),
      (value) => new PerforationSizeChange(value),
      this.possibleOptionsService.possiblePerforationsSizes
    );

    this.wallpaper = this.createDropDown(
      this.translate("Wallpaper"),
      this.store.pipe(
        select((state) => state.model.productConfiguration.wallpaper.name)
      ) as Observable<string>,
      this.getWallpaperOptions(),
      (value) => new WallpaperChange(value),
      this.possibleOptionsService.possibleWallpapers
    );

    this.allowZoom = combineLatest([
      this.store.pipe(select(getWallpaperName)),
      this.store.pipe(select((store) => store.model.dataset)),
    ]).pipe(
      map(([value, dataset]) => {
        if (dataset) {
          const wallpaperType = dataset.wallpaperTypes.find(
            (x) => x.name === value
          );
          console.log("WALLPAPER TYPE", { wallpaperType });
          return wallpaperType
            ? wallpaperType.wallpapers[0].zoomEnabled === 1
            : true;
        } else {
          return true;
        }
      })
    );

    this.allowHalfHoles = combineLatest([
      this.store.pipe(select(getWallpaperName)),
      this.store.pipe(select((store) => store.model.dataset)),
    ]).pipe(
      map(([value, dataset]) => {
        if (dataset) {
          const wallpaperType = dataset.wallpaperTypes.find(
            (x) => x.name === value
          );
          return wallpaperType
            ? wallpaperType.wallpapers[0].halfHolesEnabled === 1
            : true;
        } else {
          return true;
        }
      })
    );

    this.maxZoom = combineLatest([
      this.wallpaper.value,
      this.store.pipe(select((store) => store.model.dataset)),
    ]).pipe(
      map(([value, dataset]) => {
        if (dataset) {
          const wallpaperType = dataset.wallpaperTypes.find(
            (x) => x.name === value
          );
          return wallpaperType ? wallpaperType.wallpapers[0].zoomMax : 2;
        } else {
          return 2;
        }
      })
    );

    this.minZoom = combineLatest([
      this.wallpaper.value,
      this.store.pipe(select((store) => store.model.dataset)),
    ]).pipe(
      map(([value, dataset]) => {
        if (dataset) {
          const wallpaperType = dataset.wallpaperTypes.find(
            (x) => x.name === value
          );
          return wallpaperType ? wallpaperType.wallpapers[0].zoomMin : 0.5;
        } else {
          return 0.5;
        }
      })
    );
    // format can be set to "Individual" if perforation.spacing is set to "Individual" otherwise it will bw set to perforation.forma
    const formatObservable: Observable<string> = this.store
      .pipe(select((state) => state.model.productConfiguration.perforation))
      .pipe(
        map((perforation) =>
          perforation.spacing === "Individual"
            ? "Individual"
            : perforation.format
        ),
        distinctUntilChanged()
      );

    this.subscriptions.push(
      combineLatest([
        this.store.pipe(
          select((state) => state.model.productConfiguration.perforation.size)
        ),
        this.store.pipe(
          select(
            (state) => state.model.productConfiguration.perforation.geometryType
          )
        ),
        this.store.pipe(select(getSmallerHolesForCurrentConfiguration)),
        this.store.pipe(select(getSelectedMultiSizePattern)),
      ])
        .pipe(
          filter(
            ([selectedSize, selectedGeometry, smallerSizes, perfaction]) =>
              !!smallerSizes
          )
        )
        .subscribe(
          ([
            selectedSize,
            selectedGeometry,
            smallerSizes,
            multiSizePattern,
          ]) => {
            const selectedHoleSizes =
              (multiSizePattern ? multiSizePattern.holeSizes : []) || [];
            this.holeSizes = this.getHoleSizes(
              smallerSizes.sizes,
              selectedHoleSizes
            );
            this.selectedHoleSizes = this.holeSizes
              .filter((holeSize) => holeSize.checked)
              .map((val) => val.value);
            this.selectedHoleSizesData = this.holeSizes.filter(
              (holeSize) => holeSize.checked
            );
          }
        )
    );

    this.format = this.createDropDown(
      this.translate("Format"),
      formatObservable,
      this.getFormatOptions(),
      (value) => new PerforationFormatChange(value),
      this.possibleOptionsService.possiblePerforationFormats
    );

    this.format.showIndividualOption = combineLatest([
      this.store.pipe(
        select((store) => store.model.productConfiguration.perforation)
      ),
      this.store.pipe(select((store) => store.model.dataset)),
    ]).pipe(
      map(([perforation, dataset]) => {
        const size = getPerforationSizeData(perforation, dataset);
        return !!size && size.individual;
      })
    );

    this.showP1Input = this.store
      .pipe(
        select((store) => store.model.productConfiguration.perforation.spacing)
      )
      .pipe(map((spacing) => spacing === "Individual"));

    this.showP2Input = this.store
      .pipe(select((store) => store.model.productConfiguration.perforation))
      .pipe(
        map(
          (perforation) =>
            perforation.spacing === "Individual" && is2d(perforation)
        )
      );

    this.p1 = this.store.pipe(
      select((store) => store.model.productConfiguration.perforation.format1)
    ) as Observable<string>;
    this.p2 = this.store.pipe(
      select((store) => store.model.productConfiguration.perforation.format2)
    ) as Observable<string>;

    this.openArea = combineLatest([
      this.store.pipe(
        select((store) => store.model.productConfiguration.perforation)
      ),
      this.store.pipe(select((store) => store.model.dataset)),
    ]).pipe(
      map(([perforation, dataset]) => getCurrentAreaOpen(perforation, dataset))
    );

    this.position = this.createDropDown(
      this.translate("lochanfang"),
      this.store.pipe(
        select((store) => store.model.productConfiguration.perforation.position)
      ) as Observable<string>,
      this.getPositionOptions(),
      (value) => new PerforationPositionChange(value),
      this.possibleOptionsService.possiblePerforationPositions
    );

    this.disablePositions = this.store.pipe(
      select(perforationPositionCanBeSelected),
      map((b) => !b)
    );
  }

  public setP1(p1: string) {
    this.store.dispatch(new ProductConfigurationSetP1(p1));
  }

  public setP2(p2: string) {
    this.store.dispatch(new ProductConfigurationSetP2(p2));
  }

  private getWallpaperOptions(): Observable<DropdownOption[]> {
    return this.store.pipe(select((store) => store.model.dataset)).pipe(
      map((dataset) => {
        if (!dataset) {
          return [
            new DropdownOption(
              "text",
              "",
              true,
              "",
              this.translate("Wallpaper")
            ),
          ];
        }
        return dataset.wallpaperTypes.map(
          (wallpaperType) =>
            new DropdownOption(
              "text",
              this.translate(wallpaperType.name, "Lochung"),
              true,
              wallpaperType.name,
              this.translate("Wallpaper")
            )
        );
      })
    );
  }

  public setZoom($event) {
    this.sliderValue = $event;
    this.store.dispatch(new WallpaperZoomUpdate($event));
  }

  public togglePerfaction(isChecked: boolean) {
    if (isChecked) {
      this.store.dispatch(new SetPerfactionEditorState(true));
    }
  }

  public togglePerfactionFeature(isChecked: boolean) {
    this.store.dispatch(new EnablePerfaction(isChecked));
  }

  activeInversion(v) {
    this.store.dispatch(new SetPerfactionConfig({ inverted: v.checked }));
  }

  private findKeyByValue(set, value) {
    for (const k in set) {
      if (set.hasOwnProperty(k)) {
        if (set[k] == value) {
          return +k;
        }
      }
    }
    return undefined;
  }
  private getPerforationsTypeOptions(): Observable<DropdownOption[]> {
    return this.store.pipe(select((store) => store.model.dataset)).pipe(
      map((dataset) => {
        if (!dataset) {
          return [
            new DropdownOption(
              "text",
              "",
              true,
              "",
              this.translate("Geometry")
            ),
          ];
        }
        return dataset.perforationTypes.map(
          (perfType) =>
            new DropdownOption(
              "text",
              this.translate(perfType.name),
              true,
              perfType.name,
              this.translate("Geometry")
            )
        );
      })
    );
  }
  public allowHolesChangeState(): void {
    this.holesEnabled = !this.holesEnabled;
    this.store.dispatch(
      new WallpaperAllowHolesUpdate(this.holesEnabled ? 1 : 0)
    );
  }

  private getPerforationsOptions(): Observable<DropdownOption[]> {
    return combineLatest([
      this.store.pipe(select((store) => store.model.dataset)),
      this.store.pipe(
        select(
          (store) => store.model.productConfiguration.perforation.geometryType
        )
      ),
    ]).pipe(
      debounceTime(1),
      map(([dataset, geomType]) => {
        if (!dataset) {
          return [];
        }
        const perfTypeData = dataset.perforationTypes.find(
          (x) => x.name === geomType
        );
        if (!perfTypeData) {
          return [];
        }
        return perfTypeData.perforations.map(
          (x) =>
            new DropdownOption(
              "withImage",
              {
                name: this.translate(x.name, "Lochungsauswahl"),
                imgUrl:
                  dataset.perforationAssetsUrl +
                  dataset.perforationIcons[x.name],
              },
              true,
              x.name,
              this.translate("Perforation")
            )
        );
      })
    );
  }

  private getPerforationTypes() {
    return of([
      new DropdownOption(
        "text",
        PerforationType.CLASSIC,
        true,
        PerforationType.CLASSIC,
        this.translate("Basic Pattern Definition")
      ),
      new DropdownOption(
        "text",
        PerforationType.WALLPAPER,
        true,
        PerforationType.WALLPAPER,
        this.translate("Basic Pattern Definition")
      ),
      new DropdownOption(
        "text",
        PerforationType.PERFACTION,
        true,
        PerforationType.PERFACTION,
        this.translate("Basic Pattern Definition")
      ),
    ]);
  }

  private getSizeOptions(): Observable<DropdownOption[]> {
    return combineLatest([
      this.store.pipe(select((store) => store.model.dataset)),
      this.store.pipe(
        select(
          (store) => store.model.productConfiguration.perforation.geometryType
        )
      ),
      this.store.pipe(
        select(
          (store) =>
            store.model.productConfiguration.perforation.perforationType
        )
      ),
    ]).pipe(
      debounceTime(1),
      map(([dataset, geomType, perfType]) => {
        if (!dataset) {
          return [];
        }
        const perfTypeData = dataset.perforationTypes.find(
          (x) => x.name === geomType
        );
        if (!perfTypeData) {
          return [];
        }
        const perfData = perfTypeData.perforations.find(
          (x) => x.name === perfType
        );
        if (!perfData) {
          return [];
        }

        return perfData.sizes.map((x) => {
          const value = sizeToString(x);
          return new DropdownOption(
            "text",
            value,
            true,
            value,
            this.translate("Size")
          );
        });
      })
    );
  }

  getHoleSizes(
    sizes: { form: string; width: number; lenght: number; toolId: string }[],
    seleted: string[]
  ) {
    const values = [
      {
        image: null,
        label: this.translate("no hole"),
        value: "no_hole",
        metadata: "no_tool",
        disabled: false,
        checked: seleted.some((toolId) => toolId === "no_tool"),
      },
      ...sizes.map((size) => {
        return {
          image: "assets/" + size.form.toLowerCase() + ".svg",
          label: size.form,
          value:
            size.lenght !== 0
              ? size.width.toString() + " x " + size.lenght.toString()
              : size.width.toString(),
          metadata: size.toolId,
          disabled: false,
          checked: seleted.some((toolId) => toolId === size.toolId),
        };
      }),
    ];

    return values;
  }

  private getFormatOptions(): Observable<DropdownOption[]> {
    return combineLatest([
      this.store.pipe(select((store) => store.model.dataset)),
      this.store.pipe(
        select(
          (store) => store.model.productConfiguration.perforation.geometryType
        )
      ),
      this.store.pipe(
        select(
          (store) =>
            store.model.productConfiguration.perforation.perforationType
        )
      ),
      this.store.pipe(
        select((store) => store.model.productConfiguration.perforation.size)
      ),
    ]).pipe(
      debounceTime(1),
      map(([dataset, geomType, perfType, perfSize]) => {
        if (!dataset) {
          return [];
        }
        const perfTypeData = dataset.perforationTypes.find(
          (x) => x.name === geomType
        );
        if (!perfTypeData) {
          return [];
        }
        const perfData = perfTypeData.perforations.find(
          (x) => x.name === perfType
        );
        if (!perfData) {
          return [];
        }

        const perfSizeData = perfData.sizes.find((x) => {
          if (!!perfSize) {
            const size = stringToSize(perfSize);
            return x.length === size.length && x.width === size.width;
          }
          return false;
        });
        if (!perfSizeData) {
          return [];
        }

        return perfSizeData.formats.map((x) => {
          const value = formatToString(x);
          return new DropdownOption(
            "textWithAnnotation",
            {
              text: value,
              annotation:
                this.translate("open area") + " : " + x.areaOpen + "%",
            },
            true,
            value,
            this.translate("Format")
          );
        });
      })
    );
  }

  private getPositionOptions(): Observable<DropdownOption[]> {
    return combineLatest([
      this.store.pipe(select((store) => store.model.dataset)),
      this.store.pipe(
        select(
          (store) =>
            store.model.productConfiguration.perforation.perforationType
        )
      ),
    ]).pipe(
      debounceTime(1),
      map(([dataset, perfType]) => {
        if (!dataset) {
          return [];
        }
        const perforationEndings = dataset.endingsMapping[perfType];
        if (!perforationEndings) {
          return [];
        }
        return perforationEndings
          .filter((x) => x)
          .map(
            (x) =>
              new DropdownOption(
                "withImage",
                {
                  name: this.translate("Lochanfang_" + x),
                  imgUrl: getPositionIconUrl(dataset, x),
                },
                true,
                x
              )
          );
      })
    );
  }

  toggleUseCustom() {
    this.store.dispatch(new ToggleCustomPattern());
  }

  openCustomPatternEditor() {
    this.store.dispatch(new ShowCustomPatternEditor(true));
  }

  closeSubpanel(event: MatSlideToggleChange, subpanelName: Subpanels) {
    if (!event.checked) {
      this.store.dispatch(
        new SetSubpanelState({
          name: subpanelName,
          state: SubpanelCurrentState.CLOSED,
        })
      );
    }
  }

  enableFading(event: MatSlideToggleChange) {
    this.store.dispatch(new EnableFading(event.checked));
  }

  enableEffect(event: MatSlideToggleChange) {
    this.store.dispatch(new EnableEffect(event.checked));
  }

  openSubpanel(isChecked: boolean, subpanelName: Subpanels) {
    if (isChecked) {
      this.store.dispatch(
        new SetSubpanelState({
          name: subpanelName,
          state: SubpanelCurrentState.OPENED,
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.brightnessControl.omDestroy();
  }

  changeHoleSizes(event: {
    originalEvent: any;
    value: any;
    itemValue: any;
    options: MultiselectOption[];
  }) {
    const ids = event.options
      .filter((x) => !!x)
      .filter((x) => !x.disabled)
      .map((x) => x.metadata);
    this.store.dispatch(new ProductConfigurationSetPerfactionSizes(ids));
  }
}
