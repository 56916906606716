import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store, select } from "@ngrx/store";
import jwtDecode from "jwt-decode";
import { BehaviorSubject, Observable, Subscription, combineLatest } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { anyActionOfType } from "../../model/rule-action.model";
import { TranslationProvider } from "../../providers/translation.provider";
import { ProductConfigurationService } from "../../services/product-configuration.service";
import { WorkLoadService } from "../../services/work-load.service";
import {
  MevacoState,
  getRuleActions,
  incompleteReason,
} from "../../store/reducers";

type ConfigurationStatus = "incomplete" | "warning" | "ready" | "updating";
@Component({
  selector: "app-subnit-buttons",
  templateUrl: "./subnit-buttons.component.html",
  styleUrls: ["./subnit-buttons.component.css"],
})
export class SubnitButtonsComponent implements OnInit, OnDestroy {
  @ViewChild("warningModal", { static: true }) warningModal;
  @ViewChild("incompleteModal", { static: true }) incompleteModal;

  public incompleteReason = this.store.pipe(select(incompleteReason));
  private cartSub: Subscription;
  private configIdSub: Subscription;
  private jwtPayload: Observable<any>;
  private _configId: string;
  public model: any;
  public buttonPressTime = new BehaviorSubject<number>(0);
  public configHeadNote: string;
  public configurationStatus: Observable<ConfigurationStatus> = combineLatest([
    this.incompleteReason,
    this.store.pipe(select(getRuleActions)),
    this.workLoadService.isWorking,
  ]).pipe(
    map(([incompleteReasonValue, ruleActions, isWorking]) => {
      if (!!isWorking) {
        return "updating";
      } else if (incompleteReasonValue) {
        return "incomplete";
      } else if (anyActionOfType(ruleActions, "Warning")) {
        return "warning";
      }
      return "ready";
    })
  );

  constructor(
    private store: Store<MevacoState>,
    public workLoadService: WorkLoadService,
    private productConfigurationService: ProductConfigurationService,
    private activatedRoute: ActivatedRoute,
    private translationProvider: TranslationProvider,
    private modalService: NgbModal
  ) {
    this.jwtPayload = this.activatedRoute.queryParams.pipe(
      map((params) => {
        const jwt = Object.keys(params)[0];
        const payload: any = jwtDecode(jwt);
        return payload;
      })
    );
    combineLatest([this.buttonPressTime, this.configurationStatus])
      .pipe(debounceTime(500))
      .subscribe(async ([buttonPressTime, configurationStatus]) => {
        const now = Date.now();
        if (now - 700 < buttonPressTime) {
          switch (configurationStatus as ConfigurationStatus) {
            case "ready":
              {
                const generated = await this.productConfigurationService
                  .generateCartData(+this._configId.substr(1))
                  .toPromise();
                if (generated) {
                  window.parent.postMessage(
                    {
                      func: "onCloseDesigner",
                      message: { config_status: "valid" },
                    },
                    "*"
                  );
                }
              }
              break;
            case "warning":
              this.modalService
                .open(this.warningModal, {
                  centered: true,
                  backdrop: "static",
                  windowClass: "custom-modal-window",
                  backdropClass: "custom-modal-backdrop",
                })
                .result.then(async () => {
                  const generated = await this.productConfigurationService
                    .generateCartData(+this._configId.substr(1))
                    .toPromise();
                  if (generated) {
                    window.parent.postMessage(
                      {
                        func: "onCloseDesigner",
                        message: { config_status: "valid" },
                      },
                      "*"
                    );
                  }
                });
              break;
            case "incomplete":
              this.configHeadNote =
                await this.productConfigurationService.getGetConfigHeadNote(
                  this._configId
                );
              this.modalService
                .open(this.incompleteModal, {
                  centered: true,
                  backdrop: "static",
                  windowClass: "custom-modal-window",
                  backdropClass: "custom-modal-backdrop",
                })
                .result.then(async () => {
                  await this.productConfigurationService.saveIncompleteConfiguration(
                    this._configId,
                    this.configHeadNote
                  );
                  window.parent.postMessage(
                    {
                      func: "onCloseDesigner",
                      message: { config_status: "invalid" },
                    },
                    "*"
                  );
                });
              break;
          }
        }
      });
  }

  ngOnInit() {
    /*
    this.cartSub = combineLatest(
      this.workLoadService.isWorking,
    ).subscribe(async ([isWorking, queued]) => {
      if (!isWorking && queued !== 'updating') {
        this.cartUsageQueued.next('updating');
        const generated = await this.productConfigurationService.generateCartData(+this._configId.substr(1)).toPromise();
        if (generated) {
        }
      }
    });
    */
    this.configIdSub = this.jwtPayload
      .pipe(
        map((payload) => {
          return payload.configId;
        })
      )
      .subscribe((configId) => {
        this._configId = configId;
      });
  }
  ngOnDestroy() {
    //this.cartSub.unsubscribe();
    this.configIdSub.unsubscribe();
  }

  /*
  async closeDesigner(confStatus: ConfigurationStatus) {
    if (!this.cartUsageQueued.value) {
      setTimeout(() => {this.cartUsageQueued.next(true); }, 500);
      // this.cartUsageQueued.next(true);
    }
  }
*/

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
  /*
  openWarningModal(content: any) {
    .result.then((result) => {
      this.addToCart();
    }, (reason) => {

    });
  }
*/
  pressButton() {
    this.buttonPressTime.next(Date.now());
  }
}
