import { BehaviorSubject, Observable } from "rxjs";
import { View3D, Webcad } from "../../core";
import { Vector2 } from "../../math";
import { DragArrowModel } from "../../models/drag-arrow.model";
import { ViewState } from "../../models/view-state.model";
import { changeMapValue } from "../../utils";
import { DragArrowsVisualizer } from "../../visualizers/drag-arrows.visualizer";
import { DragArrowModelManager } from "./drag-arrow-model.manager";
import {Node, Scene} from "@babylonjs/core";

export class DragArrowsManager {
  models: Map<number, DragArrowModel> = new Map<number, DragArrowModel>();
  managers: Map<number, DragArrowModelManager> = new Map<
    number,
    DragArrowModelManager
  >();
  rootNode: Node;
  visualizer: DragArrowsVisualizer = new DragArrowsVisualizer();
  private lastViewState: ViewState;
  private shouldUpdate: boolean = false;

  constructor(private scene: Scene, private webcad: Webcad) {}

  async init(): Promise<void> {
    this.rootNode = new Node("ArrowsManager", this.scene);
    await this.visualizer.init(this.rootNode, this.models, this.webcad);
    this.scene.onBeforeRenderObservable.add((ed, es) => {
      if (
        this.shouldUpdate ||
        this.webcad.viewState !== this.lastViewState ||
        this.scene._activeAnimatables.length > 0
      ) {
        this.lastViewState = this.webcad.viewState;
        this.visualizer.updateVisualization(this.models);
        this.shouldUpdate = false;
        (<View3D<any>>this.webcad).shouldUpdate = true;
      }
    });
    return;
  }

  public getDragArrowModel(): DragArrowModelManager {
    const dragArrowModel: DragArrowModel = {
      position: { x: 0, y: 0 },
      rotation: 0,
      offset: { x: 0, y: 0 },
      moveDirection: null,
      visible: false,
      onDragObservable: new BehaviorSubject<Vector2>({ x: 0, y: 0 }),
    };

    return this.addDragArrow(dragArrowModel);
  }

  private addDragArrow(dragArrowModel: DragArrowModel): DragArrowModelManager {
    let i: number = 0;
    let done: boolean = false;
    while (!done) {
      if (!this.models.get(i)) {
        this.models = changeMapValue(this.models, dragArrowModel, i);
        const manager: DragArrowModelManager = new DragArrowModelManager(
          i,
          this
        );
        this.managers.set(i, manager);
        this.shouldUpdate = true;
        return manager;
      } else {
        i++;
      }
    }
  }

  public setModelWithId(id: number, model: DragArrowModel): void {
    this.models = changeMapValue(this.models, model, id);
    this.shouldUpdate = true;
  }

  public getModelWithId(id: number): DragArrowModel {
    return this.models.get(id);
  }

  public setPositionOfModel(id: number, position: Vector2): void {
    const newModel: DragArrowModel = {
      ...this.models.get(id),
      position: position,
    };
    this.models = changeMapValue(this.models, newModel, id);
    this.shouldUpdate = true;
  }

  public getPositionOfModel(id: number): Vector2 {
    return this.models.get(id).position;
  }

  public setRotationOfModel(id: number, rotation: number): void {
    const newModel: DragArrowModel = {
      ...this.models.get(id),
      rotation: rotation,
    };
    this.models = changeMapValue(this.models, newModel, id);
    this.shouldUpdate = true;
  }

  public getRotationOfModel(id: number): number {
    return this.models.get(id).rotation;
  }

  public setMoveDirectionOfModel(id: number, direction: Vector2): void {
    const newModel: DragArrowModel = {
      ...this.models.get(id),
      moveDirection: direction,
    };
    this.models = changeMapValue(this.models, newModel, id);
    this.shouldUpdate = true;
  }

  public getMoveDirectionOfModel(id: number): Vector2 {
    return this.models.get(id).moveDirection;
  }

  public getModelDragObservable(id: number): Observable<Vector2> {
    return this.models.get(id).onDragObservable;
  }

  public setVisibilityOfModel(id: number, visibility: boolean): void {
    const newModel: DragArrowModel = {
      ...this.models.get(id),
      visible: visibility,
    };
    this.models = changeMapValue(this.models, newModel, id);
    this.shouldUpdate = true;
  }

  public getVisibilityOfModel(id: number): boolean {
    return this.models.get(id).visible;
  }

  public disposeModel(id: number): void {
    this.models = new Map<number, DragArrowModel>([
      ...Array.from(this.models.entries()),
    ]);
    this.managers.get(id).dispose();
    this.models.delete(id);
    this.managers.delete(id);
    this.shouldUpdate = true;
  }

  public dispose(): void {
    this.managers.forEach((m) => {
      m.dispose();
    });
    this.managers.clear();
    this.visualizer.dispose();
    this.models.clear();
  }
}
