import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslationProvider } from '../../../../providers/translation.provider';
import { ChangeTemplateModal, getExpandedMetalTemplate } from '../../../../store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExpandedMetalParams } from '../../../../model/basic-templates.model';
import { SetExpandedMetalHeight, SetExpandedMetalWidth } from '../../../../store/actions/element-creator.actions';
import { TemplateUtils } from "../../templatesUtils";
import { Dropdown, DropdownOption } from '../../../../components/dropdown/dropdown.component';
import { of } from 'rxjs';

@Component({
  selector: 'mevaco-rectangle-expanded-metal',
  templateUrl: './rectangle-expanded-metal.component.html',
  styleUrls: ['./rectangle-expanded-metal.component.css']
})
export class RectangleExpandedMetalComponent implements OnInit{

  @Output() dismissModal = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() isModalOpen = new EventEmitter();

  expandedMetalParams: ExpandedMetalParams = null;
  meshType: Dropdown;
  meshSize: Dropdown;
  strandWidth:Dropdown;
  typeOfCut: Dropdown;
  materialType: Dropdown;
  thickness: Dropdown;
  materialGrade: Dropdown;
  surfaceTreatment: Dropdown;

  constructor( 
    private readonly store: Store, 
    private readonly translationProvider: TranslationProvider,
    public templateUtils: TemplateUtils ){}

  ngOnInit(): void {
    this.registerParams();
  }

  registerParams() {
    this.store.pipe(select(getExpandedMetalTemplate)).subscribe((v) => this.expandedMetalParams = { ...v });
    this.createTemplate();
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }



  setWidth(event: Event & {target: HTMLInputElement}){
    const { value } = event.target;
    this.store.dispatch(new SetExpandedMetalWidth(+value));
  }

  setHeight(event: Event & {target: HTMLInputElement}){
    const { value } = event.target;
    this.store.dispatch(new SetExpandedMetalHeight(+value));
  }

  createTemplate(){
    this.meshType = this.createDummyDropdown('Mesh Type'); 
    this.meshSize = this.createDummyDropdown('Mesh Size'); 
    this.typeOfCut = this.createDummyDropdown('Type Of Cut'); 
    this.materialType = this.createDummyDropdown('Material'); 
    this.thickness = this.createDummyDropdown('Thickness'); 
    this.materialGrade = this.createDummyDropdown('MaterialGrade'); 
  }


  //TODO: will be removed when replaced with actual data
  createDummyDropdown(name: string){
    return new Dropdown(name, of([new DropdownOption('text', 'No data', true, 'dummy')]), null, () => {});
  }

  dismiss(str: string) {
    this.store.dispatch(new ChangeTemplateModal(false));
    this.dismissModal.emit(str);
  }

  isModalOpenMethod() {
    this.isModalOpen.emit(false);
  }

}
