import {Injectable} from '@angular/core';
import {Webcad} from 'webcad';
import {MeasurementsManager} from 'webcad';
import {WebcadProvider} from './webcad.provider';
import {SceneProvider} from './scene.provider';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';

@Injectable()
export class MeasurementsManagerProvider {
  private _measurementsManager: BehaviorSubject<MeasurementsManager>;
  private webCadSubscription: Subscription;
  private sceneSubscription: Subscription;
  private _isReady: boolean;

  public get measurementsManager(): Observable<MeasurementsManager> {
    return this._measurementsManager;
  }

  constructor(private webcadProvider: WebcadProvider, private sceneProvider: SceneProvider) {
    this._isReady = false;
    this._measurementsManager = new BehaviorSubject(null);
    this.webCadSubscription = this.webcadProvider.getObservable().subscribe((value) => {
      if (value !== null && this.sceneProvider.getScene() !== null) {
        const manager = new MeasurementsManager(this.sceneProvider.getScene(), value);
        manager.init().then(() => {
            this._isReady = true;
            this._measurementsManager.next(manager);
          }
        );
      }
    });
    this.sceneSubscription = this.sceneProvider.getSubscription().subscribe((value) => {
      if (value !== null && this.webcadProvider.getWebcad() !== null) {
        const manager = new MeasurementsManager(value, this.webcadProvider.getWebcad());
        manager.init().then(() => {
            this._isReady = true;
            this._measurementsManager.next(manager);
          }
        );
      }
    });
  }

  getMeasurementsManager(): MeasurementsManager {
    return this._measurementsManager.getValue();
  }

  getSubsciption(): Observable<MeasurementsManager> {
    return this._measurementsManager.asObservable();
  }

  dispose() {
    this._measurementsManager.getValue().dispose();
    this._isReady = false;
  }

  isReady() {
    return this._isReady;
  }

}
