<div class="fading-editor-content-container">
  <h4 class="fading-editor-header">
    <span class="fading-editor-header-text">{{
      translate("fading", "designer")
    }}</span>
  </h4>

  <div class="fading-editor-body">
    <section class="fading-radio__container">
      <div class="fading-radio__linear">
        <div class="square">
          <svg viewBox="0 0 100 100" class="square-object">
            <polygon
              (click)="toggleTriangle('firstTriangle')"
              class="square-triangle square-triangle--first"
              [class.disabled]="fadingTypeEnum.RADIAL === fadingType"
              [class.square-triangle--active]="
                squareTriangles.firstTriangle.isSelected
              "
              points="5,5 50,50 95,5"
            />
            <polygon
              (click)="toggleTriangle('secondTriangle')"
              class="square-triangle square-triangle--second"
              [class.disabled]="fadingTypeEnum.RADIAL === fadingType"
              [class.square-triangle--active]="
                squareTriangles.secondTriangle.isSelected
              "
              points="5,5 50,50 5,95"
            />
            <polygon
              (click)="toggleTriangle('thirdTriangle')"
              class="square-triangle square-triangle--third"
              [class.disabled]="fadingTypeEnum.RADIAL === fadingType"
              [class.square-triangle--active]="
                squareTriangles.thirdTriangle.isSelected
              "
              points="5,95 50,50 95,95"
            />
            <polygon
              (click)="toggleTriangle('fourthTriangle')"
              class="square-triangle square-triangle--fourth"
              [class.disabled]="fadingTypeEnum.RADIAL === fadingType"
              [class.square-triangle--active]="
                squareTriangles.fourthTriangle.isSelected
              "
              points="95,5 50,50 95,95"
            />
          </svg>
        </div>
      </div>

      <div class="fading-radio__switch-wrapper">
        <div
          class="fading-radio__switch"
          (click)="setFadingType(fadingTypeEnum.LINEAR)"
          [class.fading-radio__switch--active]="
            fadingType === fadingTypeEnum.LINEAR
          "
        >
          <span>
            {{ translate("linear", "designer") }}
          </span>
        </div>
        <div
          class="fading-radio__switch"
          (click)="setFadingType(fadingTypeEnum.RADIAL)"
          [class.fading-radio__switch--active]="
            fadingType === fadingTypeEnum.RADIAL
          "
        >
          <span>
            {{ translate("radial", "designer") }}
          </span>
        </div>
      </div>

      <div class="fading-radio__radial">
        <div class="shapes-square">
          <div
            *ngFor="let square of squareShapes | keyvalue; let i = index"
            (click)="toggleShape(square.key)"
            class="shape shape-{{ i + 1 }}"
            [class.disabled]="fadingTypeEnum.LINEAR === fadingType"
            [ngClass]="
              square.value.isSelected ? 'shape-' + (i + 1) + '--active' : ''
            "
          ></div>
        </div>
      </div>
    </section>

    <div class="fading-sliders">
      <app-slider
        [labelText]="translate('Density', 'designer')"
        [sliderControl]="densityControl"
        [options]="{
          floor: -100,
          ceil: 100,
          showSelectionBarFromValue: 0
        }"
      >
      </app-slider>
      <app-slider
        [labelText]="translate('Range', 'designer')"
        [sliderControl]="rangeControl"
        [options]="{
          floor: -100,
          ceil: 100,
          showSelectionBarFromValue: 0
        }"
      >
      </app-slider>
    </div>
  </div>
</div>

<span class="fading-editor-button" (click)="toggleFadingSubpanel()">
  <img class="fading-editor-arrow" src="assets/down-arrow.svg" alt="arrow" />
</span>
