<div class="circular-slider__container">

  <div class="circular-slider__face"
  [ngStyle]="{
    'width': (props.radius + 10) + 'px',
    'height': (props.radius + 10) + 'px',
    'transform': 'translate(-' + ((props.radius + 10) / 2) + 'px, -' +  ((props.radius + 10) / 2) + 'px)'
  }">
      <input 
      class="circular-slider__input" 
      #element 
      type="number"
      min="0"
      max="360" 
      [value]="angleInDegrees" 
      placeholder="{{placeholder}}" 
      [formControl]="valueControl" 
      (change)="updateBasedOnInput(+$event.target.value)"
      [ngStyle]="{
        'width': props.radius + 'px'
      }"
      [tabIndex]="tabIndex">

  </div>

  <svg #circle [attr.height]="getContainerWidth()"
  [attr.width]="getContainerWidth()"
  class="circular-svg"
  >

<defs>
 <linearGradient *ngFor="let segment of segments"
                 [attr.key]="segment.id"
                 [attr.id]="getGradientId(segment.id)"
                 [attr.x1]="segment.arcs.fromX.toFixed(2)"
                 [attr.y1]="segment.arcs.fromY.toFixed(2)"
                 [attr.x2]="segment.arcs.toX.toFixed(2)"
                 [attr.y2]="segment.arcs.toY.toFixed(2)">
   <stop offset="0%" [attr.stop-color]="segment.colors.fromColor"/>
   <stop offset="1" [attr.stop-color]="segment.colors.toColor"/>
 </linearGradient>
</defs>

<!-- circle-donut -->
<g [attr.transform]="getTranslate()">
 <circle
   [attr.r]="props.radius"
   [attr.stroke-width]="props.strokeWidth"
   [attr.stroke]="props.bgCircleColor"
   fill="transparent"/>

 <path *ngFor="let segment of segments"

       [attr.d]="segment.d"
       [attr.key]="segment.id"
       [attr.stroke-width]="props.strokeWidth + 2"
       [attr.stroke]="getGradientUrl(segment.id)"
       fill="transparent"/>


 <!-- start icon -->
 <g #startIcon hidden
 [attr.fill]="this.props.gradientColorFrom"
 [attr.transform]="getTranslateFrom(start.fromX, start.fromY)">
 <circle [attr.r]="((props.strokeWidth - 1) / 2)"
        [attr.fill]="props.bgCircleColor"
        [attr.stroke]="props.gradientColorFrom"
        stroke-width="1"/>
        
   <ng-content select=".acs-start-icon"></ng-content>
 </g>

 <!-- stop icon -->
 <g #stopIcon
    class="thumb-icon"
    [attr.fill]="props.gradientColorTo"
    [attr.transform]="getTranslateFrom(stop.realToX, stop.realToY)">
   <circle [attr.r]="((props.strokeWidth + 3))"
           [attr.fill]="props.gradientColorTo"
           [attr.stroke]="props.gradientColorTo"
           stroke-width="1"/>

   <ng-content select=".acs-stop-icon"></ng-content>
 </g>

</g>
</svg>
</div>
