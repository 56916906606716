import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { Observable, throwError as observableThrowError } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { AddPatternParams } from "../../model/client-server.Params/add-pattern-params";
import { UserPatternService } from "../../services/user-pattern.service";
import { SetError } from "../actions/errorable.actions";
import {
  ADD_USER_PATTERN,
  AddUserPattern,
  AddUserPatternSuccess,
  DELETE_USER_PATTERN,
  DeleteUserPattern,
  DeleteUserPatternSuccess,
  GET_USER_PATTERNS,
  GetUserPatterns,
  GetUserTemplesSuccess,
} from "../actions/user-pattern.actions";
import { MevacoState } from "../reducers";

@Injectable()
export class UserPatternEffects {
  constructor(
    private store: Store<MevacoState>,
    private actions: Actions,
    private userPatternService: UserPatternService
  ) {}

  addPattern: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(ADD_USER_PATTERN),
      withLatestFrom(this.store),
      switchMap(([action, state]) => {
        const addPatternParams: AddPatternParams = {
          imageId: null,
          template: null,
          configId: null,
          name: null,
          publicPattern: false,
        };
        const addUserAction: AddUserPattern = action as AddUserPattern;
        const currentState: MevacoState = state;
        const pattern =
          currentState.model.productConfiguration.perforation.custom;
        addPatternParams.imageId = addUserAction.payload.previewImageId;
        addPatternParams.template = JSON.stringify(pattern);
        addPatternParams.configId =
          currentState.model.productConfiguration.configId;
        addPatternParams.name = addUserAction.payload.name;
        addPatternParams.publicPattern = addUserAction.payload.publicPattern;
        return this.userPatternService.addUserPattern(addPatternParams).pipe(
          map((response) => {
            if (response) {
              return new AddUserPatternSuccess(response);
            } else {
              return new SetError("Cannot add Pattern");
            }
          }),
          catchError((error) => observableThrowError(() => error))
        );
      })
    )
  );

  getUserPatterns: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(GET_USER_PATTERNS),
      withLatestFrom(this.store),
      switchMap(([action, state]) => {
        const getAction: GetUserPatterns = <GetUserPatterns>action;
        const id =
          getAction.payload === null
            ? state.model.productConfiguration.configId
            : getAction.payload;
        return this.userPatternService.getUserPatterns(id).pipe(
          map((response) => {
            return new GetUserTemplesSuccess(response);
          }),
          catchError((error) => observableThrowError(() => error))
        );
      })
    )
  );

  deletePattern: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(DELETE_USER_PATTERN),
      switchMap((action) => {
        return this.userPatternService
          .deleteUserPattern((<DeleteUserPattern>action).payload)
          .pipe(
            map((response) => {
              if (response) {
                return new DeleteUserPatternSuccess(
                  (<DeleteUserPattern>action).payload
                );
              } else {
                return new SetError("Cannot delete Pattern");
              }
            }),
            catchError((error) => observableThrowError(() => error))
          );
      })
    )
  );
}
