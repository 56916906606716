import {Vector2, Vector3} from "webcad/math";

export function arrayToMapConvert<K, T>(array: any[], convert: (any) => T): Map<K, T> {
  const result = new Map<K, T>();

  for (let i = 0; i < array.length; i++) {
    let obj = array[i];
    result.set(obj.id, convert(obj));

  }
  return result;
}

export function arrayConvert<T>(array: any[], convert: (any) => T): Array<T> {
  const result = new Array<T>();
  for (let i = 0; i < array.length; i++) {
    let obj = array[i];
    result.push(convert(obj));

  }
  return result;
}

export function mapToArray<T>(map: Map<number | string, T>, convert: (T) => any): any[] {
  const result = [];
  map.forEach((v) => {
    result.push(convert(v));
  });
  return result;
}

export function changeMapValue<U, T>(map: Map<U, T>, newValue: T, key: U): Map<U, T> {
  const newMap = new Map<U, T>();
  map.forEach((value: T, key: U, map: Map<U, T>) => newMap.set(key, value));
  newMap.set(key, newValue);
  return newMap;
}

export function changeValuesInMap<U, T>(map: Map<U, T>, newValues: Map<U, T>) {
  newValues.forEach((value: T, key: U, map: Map<U, T>) => map.set(key, value));
  return map;
}

export function EqualByValues<T>(a: T, b: T) {
  if (a === null && b !== null) {
    return false;
  }
  if (a !== null && b === null) {
    return false;
  }
  if (a === null && b === null) {
    return true;
  }
  if (a instanceof Object && b instanceof Object) {
    const keys = Object.keys(a);
    for (const key of keys) {
      if (!EqualByValues(a[key], b[key])) {
        return false;
      }
    }
  } else {
    return a === b;
  }
  return true;
}

export function cloneObject<T>(obj: T): T {
  if(obj === null || obj === undefined){
    return obj;
  }
  if (obj instanceof Array) {
    return obj.map(v => cloneObject(v)) as any;
  }
  const clone = {} as T;
  const keys = Object.keys(obj);
  for (const k of keys) {
    if (!(obj[k] instanceof Object)) {
      clone[k] = obj[k];
    } else if (obj[k] instanceof Map) {
      clone[k] = new Map<any, any>(Array.from(obj[k].entries()));
    } else if (obj[k] instanceof Array) {
      clone[k] = obj[k].map(v => v instanceof Object ? cloneObject(v) : v);
    } else {
      clone[k] = cloneObject(obj[k]);
    }
  }
  return clone as T;
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export function roundDecimal(input: number, decimalNumber: number = 1) {
  if (!isNaN(input)) {
    if (input === Number.POSITIVE_INFINITY || input === Number.NEGATIVE_INFINITY) {
      return 0;
    }
    const multiplier = Math.pow(10, decimalNumber);
    const result = Math.round(input * multiplier) / multiplier;
    return result;
  } else {
    return 0;
  }
}

export function vector2ToArrayNumber(vectors: Vector2[]) : number[] {
  const result: number[] = [];
  for (const vector of vectors) {
    result.push(vector.x, vector.y);
  }
  return result;
}

export function vector3ToArrayNumber(vectors: Vector3[]) : number[] {
  const result: number[] = [];
  for (const vector of vectors) {
    result.push(vector.x, vector.y, vector.z);
  }
  return result;
}
