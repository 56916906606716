import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {getPointsFromPolyLine} from 'webcad/models';
import {aabbOfPolylines, Segment} from 'webcad/models/segment.model';

const svgSize = 30;

@Injectable({
  providedIn: 'root'
})
export class ShapeSvgPathService {

  promisses: {[key: string]: Promise<string>} = {};
  constructor(private http: HttpClient) { }

  getPath(shape: string, l: number, w: number ): Promise<string> {
    if ( this.promisses[shape] ) {
      return this.promisses[shape];
    }
    const promise =  this.http.get<Segment[][]>(environment.api_url + '/api/dataset/getShapePolyline', {
      params: new HttpParams()
        .set('shape', shape)
        .set('w', w.toString())
        .set('l', l.toString())
    }).pipe(
      map(polylines => {
        let path = '';
        const aabb = aabbOfPolylines(polylines);
        const sizeX = aabb.max.x - aabb.min.x;
        const sizeY = aabb.max.y - aabb.min.y;
        const size = Math.max( sizeX, sizeY);
        const xOffset = (1 - (sizeX / size)) / 2 * svgSize;
        const yOffset = (1 - (sizeY / size)) / 2 * svgSize;
        for (let i = 0; i < polylines.length; i++) {
          const polyline = polylines[i];
          const points = getPointsFromPolyLine( polyline ).map( point => {
            const x = svgSize * (point.x - aabb.min.x) / size + xOffset;
            const y = svgSize * (aabb.max.y - point.y) / size + yOffset;
            return x + ' ' + y;
          });
          path += ' M ' + points.join(' L ');

        }
        return path;
      }),
      catchError(error => observableThrowError(error))
    ).toPromise();
    this.promisses[shape] = promise;
    return promise;

  }
}
