import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store, select } from "@ngrx/store";
import {
  ContextMenuComponent,
  ContextMenuService,
} from "@perfectmemory/ngx-contextmenu";
import jwtDecode from "jwt-decode";
import { BehaviorSubject, Observable, Subscription, combineLatest } from "rxjs";
import { distinctUntilChanged, map, tap } from "rxjs/operators";
import { AppMode } from "../../model/app-mode.enum";
import { DownloadDxfParams } from "../../model/client-server.Params/download-dxf-params";
import { Message } from "../../model/error.model";
import { PlateMaterialType } from "../../model/plate.model";
import { ProductType, Step } from "../../model/product-configuration/product-configuration.model";
import { MevacoPointerProvider } from "../../providers/mevaco-pointer.provider";
import { SvgIconProvider } from "../../providers/svg-icon.provider";
import { TranslationProvider } from "../../providers/translation.provider";
import { PlateService } from "../../services/plate.service";
import { WorkLoadService } from "../../services/work-load.service";
import {DatasetLoad, GetTranslations, ProductConfigurationLoad, SetAppMode, SetStep} from "../../store/actions";
import { SetError } from "../../store/actions/errorable.actions";
import { ValidateConfig } from "../../store/actions/save.actions";
import {
  MevacoState,
  blockUser,
  currentElementImportId,
  customPatternEditorIsClosed,
  customPatternEditorIsOpened,
  getDownloadDxfParams,
  getProductConfiguration,
  getProductType,
  getRightPanelWidth,
  getRuleActions,
  getStep,
  getTranslations,
  isDataReady,
  isDxfAllowedOrganizationUnit,
  isImporting,
  isMevacoUser,
  perfactionEditorIsOpened,
  showStartPopup, loaded,
} from "../../store/reducers";
import {LoadMountings} from "../../store/actions/mountings.actions";

@Component({
  selector: "full",
  templateUrl: "./full.component.html",
  styleUrls: ["./full.component.css"],
  animations: [
    trigger("openClose1", [
      state(
        "open",
        style({
          width: "{{contentWidth1}}" + "px",
        }),
        { params: { contentWidth1: 0 } }
      ),
      state(
        "closed",
        style({
          width: "calc(100vw - 440px)",
        })
      ),
      transition("open => closed", [animate("0.5s")]),
      transition("closed => open", [animate("0.3s")]),
    ]),
    trigger("openClose2", [
      state(
        "open",
        style({
          width: "{{contentWidth2}}" + "px",
        }),
        { params: { contentWidth2: 0 } }
      ),
      state(
        "closed",
        style({
          width: "calc(100vw - 50px)",
        })
      ),
      transition("open => closed", [animate("0.5s")]),
      transition("closed => open", [animate("0.3s")]),
    ]),
    trigger("openClose3", [
      state(
        "open",
        style({
          width: "{{contentWidth3}}" + "px",
        }),
        { params: { contentWidth3: 0 } }
      ),
      state(
        "closed",
        style({
          width: "calc(100vw - 100px)",
        })
      ),
      transition("open => closed", [animate("0.5s")]),
      transition("closed => open", [animate("0.3s")]),
    ]),
  ],
})
export class FullComponent implements OnInit, OnDestroy {
  @ViewChild("contextMenu", { static: true })
  private configurationId2: string;
  private customerId = '';
  public contextMenu: ContextMenuComponent<any>; // TODO fix type
  public PlateMaterialType = PlateMaterialType;
  public step: Observable<Step>;
  public customPatternEditorIsClosed = this.store.pipe(
    select(customPatternEditorIsClosed)
  );
  public blockUser = this.store.pipe(select(blockUser));
  private stepValue: Step;
  public Step = Step;
  public dataReady: Observable<boolean>;
  public configId: Observable<string>;
  private _configId: string;
  public configuratorOpen = false;
  public debugInfo: Observable<any>;
  public showDebug = false;
  private jwtPayload: Observable<any>;
  public mode: Observable<string>;
  public downloadDxfParams: DownloadDxfParams;
  private downloadDxfQueued: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  // private canDownload: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private downloadSub: Subscription;
  public isWarningModal: boolean;
  private initValidation: Subscription;
  public  productType  = this.store.pipe(select(getProductType));
  public  loaded = this.store.pipe(select(loaded));
  public openWidth;
  public closedWidth;
  public errorMessage: Message;
  private errorSubscription: Subscription;
  @ViewChild("errorModal", { static: true })
  private errorModal: any;
  public isLowRes = false;
  public isImporting: Observable<boolean>;
  public importId: Observable<number | null>;
  public isMevacoUser: Observable<boolean>;
  public isDxfAllowedOrganizationUnit = this.store.pipe(
    select(isDxfAllowedOrganizationUnit)
  );
  // public isWorking: boolean;
  public position: number;
  public isStartPopup: Observable<boolean>;
  public customPatternEditorIsOpened: Observable<boolean>;
  public perfactionEditorIsOpened: Observable<boolean>;
  public messageVisualizerOpen = false;
  public contentWidth1 = 0; // in px for openClose1
  public contentWidth2 = 0; // in px for openClose1
  public contentWidth3 = 0; // in px for openClose3
  private rulesSub: Subscription;
  private subscriptions: Subscription[] = [];
  rightPanelWidth = 0;

  constructor(
    private store: Store<MevacoState>,
    private contextMenuService: ContextMenuService<unknown>,
    private activatedRoute: ActivatedRoute,
    private translationProvider: TranslationProvider,
    private workLoadService: WorkLoadService,
    private plateService: PlateService,
    private modalService: NgbModal,
    private svgIconProvider: SvgIconProvider,
    public pointerProvider: MevacoPointerProvider,
    private cdRef: ChangeDetectorRef
  ) {
    this.jwtPayload = this.activatedRoute.queryParams.pipe(
      map((params) => {
        const jwt = Object.keys(params)[0];
        const payload: any = jwtDecode(jwt);

        return payload;
      })
    );

    this.subscriptions.push(
      this.store
        .pipe(select((store) => store.model.drawing.plate.position))
        .subscribe((x) => (this.position = x))
    );

    this.subscriptions.push(
      this.store
        .pipe(select(getDownloadDxfParams))
        .subscribe((v) => (this.downloadDxfParams = v))
    );

    this.configId = this.jwtPayload.pipe(
      map((payload) => {
        this._configId = payload.configId;
        return payload.configId;
      })
    );

    this.mode = this.jwtPayload.pipe(
      map((payload) => payload.mode || "design"),
      tap((mode) => {
        this.store.dispatch(
          new SetAppMode(mode === "design" ? AppMode.editor : AppMode.viewer)
        );
      })
    );

    this.jwtPayload
      .pipe(
        map((payload) => payload.language),
        distinctUntilChanged()
      )
      .subscribe((lang) => {
        if (lang) {
          translationProvider.lang = lang;
          this.store.dispatch(new GetTranslations(lang));
        }
      });

  }

  @HostListener("window:resize")
  resize() {
    this.contentWidth1 = window.innerWidth - (this.rightPanelWidth + 440);
    this.contentWidth2 = window.innerWidth - (this.rightPanelWidth + 50);
    this.contentWidth3 = window.innerWidth - (this.rightPanelWidth + 100);
  }

  ngOnInit() {
    this.isStartPopup = this.store.pipe(select(showStartPopup));
    this.isImporting = this.store.pipe(select(isImporting));
    this.step = this.store.pipe(select(getStep));
    this.step.subscribe((v) => (this.stepValue = v));
    this.dataReady = this.store.pipe(select(isDataReady));
    this.customPatternEditorIsOpened = this.store.pipe(
      select(customPatternEditorIsOpened)
    );
    this.perfactionEditorIsOpened = this.store.pipe(
      select(perfactionEditorIsOpened)
    );

    this.subscriptions.push(
      this.store.pipe(select(getRightPanelWidth)).subscribe((value) => {
        this.rightPanelWidth = value;
        if (value / innerWidth < 0.6) {
          this.contentWidth1 = window.innerWidth - (value + 440);
          this.contentWidth2 = window.innerWidth - (value + 50);
          this.contentWidth3 = window.innerWidth - (value + 100);
        } else {
          this.contentWidth1 = innerWidth * 0.6;
          this.contentWidth2 = innerWidth * 0.6;
          this.contentWidth3 = innerWidth * 0.6;
        }
      })
    );

    this.initValidation = this.dataReady.subscribe((v) => {
      if (v) {
        this.store.dispatch(new ValidateConfig());
        this.initValidation.unsubscribe();
      }
    });

    this.debugInfo = combineLatest([
      this.store.pipe(select(getProductConfiguration)),
      this.store.pipe(select(getRuleActions)),
    ]).pipe(
      map(([state, errors]) => {
        return { errors: errors, ...state };
      })
    );

    this.subscriptions.push(
      combineLatest([
        this.workLoadService.isWorking,
        this.downloadDxfQueued,
      ]).subscribe(([isWorking, queued]) => {
        if (this.downloadDxfParams && !isWorking && queued) {
          this.plateService.downloadDxf(this.downloadDxfParams);
          this.downloadDxfQueued.next(false);
        }
      })
    );

    this.subscriptions.push(
      this.store
        .pipe(
          distinctUntilChanged(),
          select((state) => state.model.message)
        )
        .subscribe((v) => {
          this.errorMessage = v;
          const importOngoing =
            this.stepValue === Step.import ||
            this.stepValue === Step.import_attachment ||
            this.stepValue === Step.import_pattern;
          const inCockpitOrTemplate =
            this.stepValue === Step.cockpit || this.stepValue === Step.template;
          if (!!v.message && !importOngoing && inCockpitOrTemplate) {
            this.modalService
              .open(this.errorModal, {
                centered: true,
                backdrop: "static",
                windowClass: "custom-modal-window",
                backdropClass: "custom-modal-backdrop",
              })
              .result.then(
                (o) => {
                  this.store.dispatch(new SetError(null));
                },
                (n) => {
                  this.store.dispatch(new SetError(null));
                }
              );
          }
        })
    );

    this.isMevacoUser = this.store.pipe(
      select(isMevacoUser),
      map((x) => x === "Yes")
    );

    // this.store.pipe(select(getProductType), map(x => x as ProductType))
    // .subscribe(res =>{
    //     this.productType = res;
    // });

    this.importId = this.store.pipe(select(currentElementImportId));
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  public onContextMenu($event: MouseEvent): void {
    console.log($event);
    // TODO fix type def at constructor
    // this.contextMenuService.show.next({
    //   contextMenu: this.contextMenu,
    //   event: $event,
    //   item: null,
    // });
    $event.preventDefault();
    $event.stopPropagation();
  }

  setStep() {
    this.store.dispatch(new SetStep(Step.cockpit));
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }

  toggleDebugger() {
    this.showDebug = !this.showDebug;
  }

  printNotTranslated() {
    this.translationProvider.printNotTranslated();
  }

  downloadDxf(): any {
    if (this.downloadDxfParams) {
      this.downloadDxfQueued.next(true);
    }
  }

  showWarningModal(content: any) {
    this.modalService.open(content, { centered: true, backdrop: "static" });
    this.isWarningModal = true;
  }

  downloadImportDxf(importId: number): void {
    this.plateService.downloadImportDxf(importId);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.downloadSub.unsubscribe();
    this.rulesSub.unsubscribe();
    this.initValidation.unsubscribe();
    this.errorSubscription.unsubscribe();
  }
}
