import {Component, Input, OnInit, Output} from '@angular/core';
import {MevacoState} from '../../store/reducers';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {forEachRuleAction, RuleActions} from '../../model/rule-action.model';
import {TranslationProvider} from '../../providers/translation.provider';
import { TooltipType } from '../../model/tooltip-type.enum';

export interface ToolTipConfig {
  fieldName: string;
  positionIndex?: number;
  helpText: string;
}

@Component({
  selector: 'rule-action',
  templateUrl: './rule-action.component.html',
  styleUrls: ['./rule-action.component.css'],
})
export class RuleActionComponent implements OnInit {

  @Input()
  public config: ToolTipConfig;
  @Input()
  public hasError: boolean;

  public TooltipType = TooltipType;

  private iconUrlBs: BehaviorSubject<string>;
  private typeBs: BehaviorSubject<TooltipType>;
  private contentHtmlBs: BehaviorSubject<string>;

  private ruleActionsSub: Subscription;

  constructor(private store: Store<MevacoState>, private translationProvider: TranslationProvider) {
    this.iconUrlBs = new BehaviorSubject('');
    this.typeBs = new BehaviorSubject(TooltipType.none);
    this.contentHtmlBs = new BehaviorSubject('');
  }

  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }

  @Output() public get type(): Observable<TooltipType> {
    return this.typeBs.pipe(distinctUntilChanged());
  };

  public get contentHtml(): Observable<string> {
    return this.contentHtmlBs.pipe(distinctUntilChanged());
  }

  public get headerText(): Observable<string> {
    return this.typeBs.pipe(distinctUntilChanged(), map(type => {
      switch (type) {
        case TooltipType.error:
          return 'Error';
        case TooltipType.warning:
          return 'Warning';
        case TooltipType.hint:
          return 'Hint';
        case TooltipType.help:
          return 'Help';
        default:
          return '';
      }
    }));
  }

  public get iconUrl(): Observable<string> {
    return this.typeBs.pipe(distinctUntilChanged(), map(type => {
      if(this.hasError)
        return 'assets/hint-icon-error.svg';
      else {
        switch (type) {
          case TooltipType.error:
            return 'assets/hint-icon-error.svg';
          case TooltipType.warning:
            return 'assets/hint-icon-warning.svg';
          case TooltipType.hint:
            return 'assets/hint-icon.svg';
          case TooltipType.help:
            return 'assets/hint-icon.svg';
          default:
            return '';
        }
      }
    }));
  }


  ngOnInit() {
    this.ruleActionsSub = this.store
      .select(store => store.model.rulesActions)
      .subscribe(this.updateContent.bind(this));
  }

  ngOnDestroy() {
    this.ruleActionsSub.unsubscribe();
  }

  private updateContent(rulesActions: RuleActions) {
    let type: TooltipType = TooltipType.none;
    let content = '';
    forEachRuleAction(rulesActions, action => {
      if (action.fieldName === this.config.fieldName) {
        type = Math.max(type, tooltipTypeFromString(action.type));
        content += '<b>[Rule' + action.ruleId + ']</b> ' + this.translate(action.code, 'rule');
      }
    });
    if (type === TooltipType.none && !!this.config.helpText) {
      type = TooltipType.help;
      content = this.translate(this.config.helpText);
    }
    this.typeBs.next(type);
    this.contentHtmlBs.next(content);
  }
}

function tooltipTypeFromString(s: string): TooltipType {
  switch (s) {
    case 'Error':
      return TooltipType.error;
    case 'Warning':
      return TooltipType.warning;
    case 'Hint':
      return TooltipType.hint;
    default:
      return TooltipType.none;
  }
}
