import { CubeViewController } from "../controllers/cube-view.controller";
import { AssetsProvider, BabylonAssetsProvider } from "../providers";
import { RotateCamera } from "./rotate-camera";
import {ArcRotateCamera, Camera, Color3, Color4, DirectionalLight, Engine, Scene, Vector3} from "@babylonjs/core";

export class CubeView3d {
  protected engine: Engine;
  public scene: Scene;
  protected camera: ArcRotateCamera;
  public assets: AssetsProvider;
  protected cubeViewController: CubeViewController;
  private canvas: any;

  constructor(
    private renderTarget: any,
    private cameraToObserve: RotateCamera
  ) {
    this.canvas = renderTarget;
    this.engine = new Engine(this.renderTarget.nativeElement, true);
    this.scene = new Scene(this.engine);
    this.scene.clearColor = new Color4(0, 0, 0, 0);
    this.scene.ambientColor = new Color3(1, 1, 1);
    const light = new DirectionalLight(
      "cubeLight",
      new Vector3(2, -3, 2),
      this.scene
    );
    light.diffuse = new Color3(1, 1, 1);
    light.specular = new Color3(1, 1, 1);
    this.assets = new BabylonAssetsProvider(
      this.engine,
      "/assets/",
      "arrows.babylon",
      "/assets/floor-textures/"
    );
    this.activateCubeView(this.renderTarget);
    this.engine.runRenderLoop(() => {
      // Register a render loop to repeatedly render the scene
      if (this.cubeViewController) {
        if (this.cubeViewController.shouldRotate) {
          this.cubeViewController.rotateFunction();
        }
      }
      this.scene.render();
    });
  }

  public hide(hide: boolean) {
    this.canvas.nativeElement.style.display = hide ? "none" : "";
  }

  public activateCubeView(renderTarget: any) {
    this.camera = new ArcRotateCamera(
      "cube-camera",
      this.cameraToObserve.alpha,
      this.cameraToObserve.beta,
      10,
      new Vector3(0, 0, 0),
      this.scene
    );
    this.camera.position = new Vector3(0, 0, -10);
    this.camera.inertia = 0;
    this.camera.mode = Camera.ORTHOGRAPHIC_CAMERA;
    this.camera.minZ = -20;
    this.camera.maxZ = 20;
    this.camera.orthoTop = 4.5;
    this.camera.orthoBottom = -4.5;
    this.camera.orthoLeft = -8;
    this.camera.orthoRight = 8;
    this.camera.panningSensibility = 0;
    this.scene.activeCamera = this.camera;
    const cameraToObserve = this.cameraToObserve as unknown;
    this.cubeViewController = new CubeViewController(
      this.scene,
      this.camera,
      this.assets,
      cameraToObserve as Camera
    );
    this.camera.attachControl(renderTarget.nativeElement, false);
    this.scene.onPointerObservable.add(
      this.cubeViewController.rotateCameras.bind(this.cubeViewController)
    );
  }

  public dispose(): void {
    this.camera.dispose();
    this.scene.dispose();
    this.engine.dispose();
  }

  public getCanvas() {
    return this.canvas;
  }
}
