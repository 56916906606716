import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserPattern} from '../../../../model/user-pattern.model';
import {select, Store} from '@ngrx/store';
import {MevacoState, isMevacoUser} from '../../../../store/reducers';
import {SetCustomPattern} from '../../../../store/actions';
import {environment} from '../../../../../environments/environment';
import {DeleteUserPattern} from '../../../../store/actions/user-pattern.actions';
import { TranslationProvider } from '../../../../providers/translation.provider';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-custom-pattern-load-item',
  templateUrl: './custom-pattern-load-item.component.html',
  styleUrls: ['./custom-pattern-load-item.component.css']
})
export class CustomPatternLoadItemComponent implements OnInit {
  @Input() userPattern: UserPattern;
  @Output() close = new EventEmitter<boolean>();

  getImageUrl: string;
  showDeleteQuestion = false;
  public isMevacoUser: Observable<boolean>;

  constructor(
    private store: Store<MevacoState>,
    private translationProvider: TranslationProvider
  ) { }

  ngOnInit() {
    this.isMevacoUser = this.store.pipe(
      select(  isMevacoUser ),
      map (x => x === 'Yes')
    );
  }

  getPreviewImageUrl(): string {
    return environment.api_url + '/api/mevaco/getPreviewImage/' + this.userPattern.imageGuid;
  }

  load() {
    this.store.dispatch(new SetCustomPattern(this.userPattern) );
    this.close.emit(true);
  }

  deleteThisPattern() {
    this.store.dispatch(new DeleteUserPattern(this.userPattern.id) );
  }

  translate(text: string, module: string = 'designer') {
    return this.translationProvider.translate(text, module);
  }
}
