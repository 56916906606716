import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store, select } from "@ngrx/store";
import { Observable, throwError as observableThrowError } from "rxjs";
import {
  catchError,
  first,
  map,
  mergeMap,
  switchMap,
  tap,
} from "rxjs/operators";
import { PerfactionService } from "../../services/perfaction.service";
import {
  DELETE_PERFACTION_IMAGE,
  DeletePerfactionImage,
  DeletePerfactionImageSuccessfully,
  GET_PERFACTION_IMAGE,
  GetPerfactionImage,
  GetPerfactionImageSuccessfully,
  SetPerfactionImageId,
} from "../actions";
import { SetError } from "../actions/errorable.actions";
import { UPLOAD_PERFACTION_FILE } from "../actions/perfaction.actions";
import { MevacoState, getConfigurationId } from "../reducers";

@Injectable()
export class PerfactionEffects {
  constructor(
    private actions: Actions,
    private perfactionService: PerfactionService,
    private store: Store<MevacoState>
  ) {}

  uploadPerfactionFile: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UPLOAD_PERFACTION_FILE),
      map((action) => (<any>action).payload),
      mergeMap((payload) =>
        this.store.pipe(
          select(getConfigurationId),
          first(),
          map((id) => [id, payload])
        )
      ),
      switchMap(([id, payload]) =>
        this.perfactionService.uploadPerfactionFile(payload, id).pipe(
          map((res) => {
            if (!!res.error && res.error != "") return new SetError(res.error);
            else return new SetPerfactionImageId({ id: res.data });
          }),
          catchError((error) => observableThrowError(() => error))
        )
      )
    )
  );

  deletePerfactionImage: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(DELETE_PERFACTION_IMAGE),
      switchMap((action: DeletePerfactionImage) =>
        this.perfactionService.deletePerfactionImage(action.payload.id).pipe(
          tap(() => this.perfactionService.dispatchRemoveImage()),
          map(
            (res) =>
              new DeletePerfactionImageSuccessfully({
                message: "Image was successfully removed!",
              })
          ),
          catchError((error) => observableThrowError(() => error))
        )
      )
    )
  );

  getPerfactionImage: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(GET_PERFACTION_IMAGE),
      switchMap((action: GetPerfactionImage) =>
        this.perfactionService.getPerfactionImage(action.payload.id).pipe(
          tap((file) => this.perfactionService.pushNextFile(file)),
          map((res) => new GetPerfactionImageSuccessfully(res)),
          catchError((error) => observableThrowError(() => error))
        )
      )
    )
  );
}
