<div class="dialog-modal-header">
  <div class="dialog-modal-header-content">
    <div class="dialog-modal-title-container">
      <h4 class="dialog-modal-title-primary" id="multiShape-title">
        {{ this.templateUtils.translate("Multishape") }}
      </h4>
      <span class="dialog-modal-title-secondary"
        >{{
          this.templateUtils.translate("EnterDimenstions", "designer")
        }}
        (mm):</span
      >
    </div>
    <div class="dialog-modal-window">
      <span>{{
        this.templateUtils.translate("SketchDoesntChange", "designer")
      }}</span>
    </div>
  </div>
  <button
    type="button"
    class="close btn-close"
    aria-label="Close"
    [tabIndex]="-1"
    (click)="dismiss('Cross click'); isModalOpenMethod()"
  ></button>
</div>

<div>
  <div class="dialog-modal-body multishape-modal-body">
    <div class="multi-inputs">
      <!--<img src="assets/Multishape.svg" class="basic-img" style="padding-bottom: 50px;">-->
      <!-- <label for="plate_a">a</label> -->
      <input
        type="text"
        id="plate_a"
        class="input-small multi-a"
        [tabIndex]="2"
        [ngStyle]="
          configurableTemplate.plateParams.a <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="configurableTemplate.plateParams.a"
        (input)="setMultiShapeA(this.templateUtils.roundInput($event, 0, 5000))"
        #multi_a
        required
      />
      <!-- <label for="plate_e1">e1</label> -->
      <input
        type="text"
        id="plate_e1"
        class="input-small multi-e1"
        [tabIndex]="5"
        [ngStyle]="
          configurableTemplate.plateParams.e1 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="configurableTemplate.plateParams.e1"
        (input)="
          setMultiShapeE1(
            this.templateUtils.roundInput(
              $event,
              0,
              configurableTemplate.plateParams.a / 2
            )
          )
        "
        #multi_e1
        required
      />
      <!-- <label for="plate_e2">e2</label> -->
      <input
        type="text"
        id="plate_e2"
        class="input-small multi-e2"
        [tabIndex]="6"
        (keydown)="this.templateUtils.returnToFirstElement($event, multi_b)"
        [ngStyle]="
          configurableTemplate.plateParams.e2 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="configurableTemplate.plateParams.e2"
        (input)="
          setMultiShapeE2(
            this.templateUtils.roundInput(
              $event,
              0,
              configurableTemplate.plateParams.a / 2
            )
          )
        "
        #multi_e2
        required
      />
      <!-- <label for="plate_b">b</label> -->
      <input
        type="text"
        id="plate_b"
        class="input-small multi-b"
        [tabIndex]="1"
        (keydown)="this.templateUtils.returnToLastElement($event, multi_e2)"
        [ngStyle]="
          configurableTemplate.plateParams.b <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="configurableTemplate.plateParams.b"
        (input)="setMultiShapeB(this.templateUtils.roundInput($event, 0, 5000))"
        #multi_b
        required
      />
      <!-- <label for="plate_f1">f1</label> -->
      <input
        type="text"
        id="plate_f1"
        class="input-small multi-f1"
        [tabIndex]="4"
        [ngStyle]="
          configurableTemplate.plateParams.f1 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="configurableTemplate.plateParams.f1"
        (input)="
          setMultiShapeF1(
            this.templateUtils.roundInput(
              $event,
              0,
              configurableTemplate.plateParams.b / 2
            )
          )
        "
        #multi_f1
        required
      />
      <!-- <label for="plate_f2">f2</label> -->
      <input
        type="text"
        id="plate_f2"
        class="input-small multi-f2"
        [tabIndex]="3"
        [ngStyle]="
          configurableTemplate.plateParams.f2 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="configurableTemplate.plateParams.f2"
        (input)="
          setMultiShapeF2(
            this.templateUtils.roundInput(
              $event,
              0,
              configurableTemplate.plateParams.b / 2
            )
          )
        "
        #multi_f2
        required
      />
    </div>

    <div class="multishape-creator__container">
      <div class="multishape-creator__header">
        <div class="header__header-column">
          <label class="header-column__label" for="col_num"
            >{{ this.templateUtils.translate("Columns") }}:</label
          >
          <input
            type="text"
            id="col_num"
            class="header-column__input"
            [(ngModel)]="configurableTemplate.columnNumber"
            maxlength="1"
            [tabIndex]="1"
            [ngStyle]="
              col_num.value.length === 0 || !checkIfNumber(col_num.value)
                ? { 'background-color': '#f5c6cb' }
                : { 'background-color': 'white' }
            "
            (input)="setColumns($event.target.value)"
            #col_num
            required
          />
        </div>

        <div class="header__header-row">
          <label class="header-row__label" for="row_num"
            >{{ this.templateUtils.translate("Rows") }}:</label
          >
          <input
            type="text"
            id="row_num"
            class="header-row__input"
            [(ngModel)]="configurableTemplate.rowNumber"
            maxlength="1"
            [tabIndex]="1"
            [ngStyle]="
              row_num.value.length === 0 || !checkIfNumber(row_num.value)
                ? { 'background-color': '#f5c6cb' }
                : { 'background-color': 'white' }
            "
            (input)="setRows($event.target.value)"
            #row_num
            required
          />
        </div>
      </div>

      <div class="multishape-creator__body">
        <div class="body__columns">
          <!-- <h4 class="columns__title">{{this.templateUtils.translate("Columns")}}:</h4> -->
          <div class="columns__content">
            <div
              class="column__position"
              *ngFor="let p of configurableTemplate.columns; let i = index"
            >
              <div class="position__first-input">
                <label class="first-input__label" for="cb"
                  >b.{{ i + 1 }}
                </label>
                <input
                  type="text"
                  id="cb"
                  class="first-input"
                  [value]="
                    roundDecimal(configurableTemplate.columns[i].size, 1)
                  "
                  [disabled]="i + 1 === configurableTemplate.columnNumber"
                  (input)="
                    setColumn(
                      this.templateUtils.roundInput($event, 0),
                      configurableTemplate.columns[i].margin,
                      i
                    )
                  "
                  #cb
                  required
                />
              </div>

              <div [hidden]="cb.value.length !== 0" class="alert alert-danger">
                {{
                  this.templateUtils.translate("Value is required", "designer")
                }}
              </div>

              <div
                class="position__second-input"
                *ngIf="i < configurableTemplate.columnNumber - 1"
              >
                <label class="second-input__label" for="fb"
                  >f.{{ i + 1 }}
                </label>
                <input
                  type="text"
                  id="fb"
                  class="second-input"
                  [value]="
                    roundDecimal(configurableTemplate.columns[i].margin, 1)
                  "
                  (input)="
                    setColumn(
                      configurableTemplate.columns[i].size,
                      this.templateUtils.roundInput($event, 0),
                      i
                    )
                  "
                  #fb
                  required
                />
                <div
                  [hidden]="fb.value.length !== 0"
                  class="alert alert-danger"
                >
                  {{
                    this.templateUtils.translate(
                      "Value is required",
                      "designer"
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="body__rows">
          <!-- <h4 class="rows__title">{{this.templateUtils.translate("Rows")}}:</h4> -->
          <div class="rows__content">
            <div
              class="row__position"
              *ngFor="let p of configurableTemplate.rows; let i = index"
            >
              <div class="position__first-input">
                <label class="first-input__label" for="ra"
                  >a.{{ i + 1 }}
                </label>
                <input
                  type="text"
                  id="ra"
                  class="first-input"
                  [value]="roundDecimal(configurableTemplate.rows[i].size, 1)"
                  [disabled]="i + 1 === configurableTemplate.rowNumber"
                  (input)="
                    setRow(
                      this.templateUtils.roundInput($event, 0),
                      configurableTemplate.rows[i].margin,
                      i
                    )
                  "
                  #ra
                  required
                />
              </div>

              <div [hidden]="ra.value.length !== 0" class="alert alert-danger">
                {{
                  this.templateUtils.translate("Value is required", "designer")
                }}
              </div>

              <div
                class="position__second-input"
                *ngIf="i < configurableTemplate.rowNumber - 1"
              >
                <label class="second-input__label" for="ea"
                  >e.{{ i + 1 }}
                </label>
                <input
                  type="text"
                  id="ea"
                  class="second-input"
                  [value]="roundDecimal(configurableTemplate.rows[i].margin, 1)"
                  (input)="
                    setRow(
                      configurableTemplate.rows[i].size,
                      this.templateUtils.roundInput($event, 0),
                      i
                    )
                  "
                  #ea
                  required
                />
                <div
                  [hidden]="ea.value.length !== 0"
                  class="alert alert-danger"
                >
                  {{
                    this.templateUtils.translate(
                      "Value is required",
                      "designer"
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-modal-footer">
    <span class="dialog-modal-link" (click)="this.templateUtils.setDefault()">
      {{ this.templateUtils.translate("Reset dimensions to default.") }}
    </span>
    <button
      #closeButton
      type="button"
      class="btn btn-primary btn-create"
      [tabIndex]="1"
      [disabled]="
        (reqValMultiShape | async) ||
        configurableTemplate.columnNumber == null ||
        configurableTemplate.rowNumber == null
      "
      (click)="close(configurableTemplate)"
    >
      {{ this.templateUtils.translate("Create") }}
    </button>
  </div>
</div>
