import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfiguratorComponentTypeEnum } from '../../enums/configurator-component-type.enum';
import { ConfiguratorComponentType } from '../../model/configurator-component-type.model';
import { TranslationProvider } from '../../providers/translation.provider';
// import { TranslationProvider } from 'src/app/providers/translation.provider';

@Component({
  selector: 'configurator-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './configurator-page.component.html',
  styleUrls: ['./configurator-page.component.css']
})
export class ConfiguratorPageComponent implements OnInit  {

  selections: string[] = [this.translate('Produkt','designer')];
  //#Those will be implemented later!
  // selections: string[] = ["Produkt", "Lochung", "Material", "Oberflache", "Total"];
  selectedContent = 0;
  selectedComponent: ConfiguratorComponentType;

  constructor(private readonly translationProvider: TranslationProvider){}

  ngOnInit(): void {
    this.initSelectedContent();
  }

  initSelectedContent() {
    this.setSelectedContent(ConfiguratorComponentTypeEnum.ProduktConfigurator);
  }

  setSelectedContent(index: number){
    this.selectedContent = index;
    this.setSelectedComponent(index);
  }

  async setSelectedComponent(index: number) {
    switch (index) {
      case ConfiguratorComponentTypeEnum.ProduktConfigurator:
        this.selectedComponent = await import("./components/produkt-configurator/produkt-configurator.component").then(x => x.ProduktConfiguratorComponent); 
        break;
      default: 
      this.selectedComponent = null;
       throw new Error("Selected component type is not found!");
    }
  }

  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }
}


