import {Injectable} from '@angular/core';
import {getTranslations, MevacoState} from '../store/reducers';
import {select, Store} from '@ngrx/store';
import {environment} from "../../environments/environment";

@Injectable()
export class TranslationProvider {
  private translations: { [module: string]: { [identifier: string]: string } } = null;
  private notTranslated: Map<string, string[]> = new Map<string, string[]>();
  public lang: string = 'en';

  constructor(private store: Store<MevacoState>) {
    this.store.pipe(select(getTranslations)).subscribe((value) => this.translations = value);
  }

  translate(identifier: string, module: string = 'configurator'): string {

    if(environment.disable_translation === true){
      return  identifier;
    }

    let result = "";
    if (!!this.translations) {
      if (!!this.translations[module] && this.translations[module][identifier]) {
        return this.translations[module][identifier].replace('</br>', '<br>');
      } else {
        //try to find in other modules
        for (const m in this.translations) {
          if (!!this.translations[m][identifier]) {
            if (!this.notTranslated.has(module) || !this.notTranslated.get(module).includes(identifier)) {
              console.log(`Found translation for ${identifier} in ${m};`);
              //debugger;
            }
            result = this.translations[m][identifier].replace('</br>', '<br>');
          }
        }

        if (this.notTranslated.has(module)) {
          if (!this.notTranslated.get(module).includes(identifier)) {
            this.notTranslated.get(module).push(identifier);
          }
        } else {
          this.notTranslated.set(module, [identifier]);
        }
      }
    }
    return result || this.lang + "_" +identifier;
  }

  public printNotTranslated() {
    this.notTranslated.forEach((v, k) => {
      for (const w of v) {
        console.log(k + ' : ' + w);
      }
    });
  }
}
