import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import jwtDecode from "jwt-decode";
import { Observable, throwError as observableThrowError } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { AddTemplateParams } from "../../model/client-server.Params/add-template-params";
import { UserTemplateService } from "../../services/user-template.service";
import { SetError } from "../actions/errorable.actions";
import {
  ADD_USER_TEMPLATE,
  AddUserTemplate,
  AddUserTemplateSuccess,
  CONFIGURE_USER_TEMPLATE,
  ConfigureUserTemplate,
  ConfigureUserTemplateSuccess,
  DELETE_USER_TEMPLATE,
  DeleteUserTemplate,
  DeleteUserTemplateSuccess,
  GET_USER_TEMPLATES,
  GetUserTemplesSuccess,
} from "../actions/user-template.actions";
import { MevacoState } from "../reducers";

@Injectable()
export class UserTemplateEffects {
  constructor(
    private store: Store<MevacoState>,
    private activatedRoute: ActivatedRoute,
    private actions: Actions,
    private userTemplateService: UserTemplateService
  ) {}

  addTemplate: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(ADD_USER_TEMPLATE),
      withLatestFrom(this.store),
      switchMap(([action, state]) => {
        const addTemplateParams: AddTemplateParams = {
          imageId: null,
          template: null,
          configId: null,
          name: null,
        };
        const addUserAction: AddUserTemplate = action as AddUserTemplate;
        const currentState: MevacoState = state;
        const element =
          currentState.model.productConfiguration.elements[
            addUserAction.payload.id - 1
          ];
        addTemplateParams.imageId = element.previewImageId;
        addTemplateParams.template = JSON.stringify(element);
        addTemplateParams.configId =
          currentState.model.productConfiguration.configId;
        addTemplateParams.name = addUserAction.payload.name;
        return this.userTemplateService.addUserTemplate(addTemplateParams).pipe(
          map((response) => {
            if (response) {
              return new AddUserTemplateSuccess(response);
            } else {
              return new SetError("Cannot add Template");
            }
          }),
          catchError((error) => observableThrowError(() => error))
        );
      })
    )
  );

  configureTemplate: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(CONFIGURE_USER_TEMPLATE),
      withLatestFrom(this.store),
      switchMap(([action]) => {
        return this.userTemplateService
          .configureUserTemplate((<ConfigureUserTemplate>action).payload)
          .pipe(
            map((response) => {
              if (response) {
                return new ConfigureUserTemplateSuccess(response);
              } else {
                return new SetError("Cannot configure Template");
              }
            }),
            catchError((error) => observableThrowError(() => error))
          );
      })
    )
  );

  getUserTemplates: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(GET_USER_TEMPLATES),
      // withLatestFrom(this.store),
      switchMap((action) => {
        const params = this.activatedRoute.snapshot.queryParams;
        const jwt = Object.keys(params)[0];
        const payload: any = jwtDecode(jwt);
        const id = payload.configId;

        return this.userTemplateService.getUserTemplates(id).pipe(
          map((response) => {
            return new GetUserTemplesSuccess(response);
          }),
          catchError((error) => observableThrowError(() => error))
        );
      })
    )
  );

  deleteTemplate: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(DELETE_USER_TEMPLATE),
      switchMap((action) => {
        return this.userTemplateService
          .deleteUserTemplate((<DeleteUserTemplate>action).payload)
          .pipe(
            map((response) => {
              if (response) {
                return new DeleteUserTemplateSuccess(
                  (<DeleteUserTemplate>action).payload
                );
              } else {
                return new SetError("Cannot delete Template");
              }
            }),
            catchError((error) => observableThrowError(() => error))
          );
      })
    )
  );
}
