import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { copyVector2, multiplyVector2byScalar } from "webcad/math";
import {
  aabbOfPolyline,
  aabbOfSegments,
  forEachSegmentInPolylines,
  getEmptyAaabb,
  mergeAabb,
  Segment,
} from "webcad/models";
import { Aabb2, isAabb2Empty } from "webcad/models/aabb.model";
import { Plate } from "../model";
import {
  getPerforationCustomGeomAabb,
  PerforationCustomGeom,
} from "../model/product-configuration/perforation-custom-geom";
import { Step } from "../model/product-configuration/product-configuration.model";
import { SnappingModel } from "../model/snapping.model";
import { StepAndPlate } from "../model/view-model/step-plate.viewModel";
import {
  customPatternEditorIsOpened,
  getImportedShape,
  getPerforationCustomGeom,
  getSnappingState,
  getStep,
  MevacoState,
} from "../store/reducers";

@Injectable()
export class PlateAabbProvider {
  private step: Step;
  private grid: SnappingModel;
  private importedShape: Segment[][];
  private customPatternEditorIsOpened = false;
  private perforationCustomGeom: PerforationCustomGeom;

  public get aabb(): Observable<Aabb2> {
    return this._aabb;
  }

  private _aabb: Observable<Aabb2>;
  private _currentAaBb: Aabb2;

  constructor(store: Store<MevacoState>) {
    store.pipe(select(getStep)).subscribe((v) => {
      this.step = v;
    });
    store.pipe(select(getSnappingState)).subscribe((v) => {
      this.grid = v;
    });
    store.pipe(select(getImportedShape)).subscribe((v) => {
      this.importedShape = v;
    });
    store.pipe(select(customPatternEditorIsOpened)).subscribe((v) => {
      this.customPatternEditorIsOpened = v;
    });
    store.pipe(select(getPerforationCustomGeom)).subscribe((v) => {
      this.perforationCustomGeom = v;
    });
    this._aabb = store.pipe(
      select((state) => state.model.drawing.plate),
      map((plate: Plate) => {
        this._currentAaBb = this.getPlateAAbb({
          step: this.step,
          plate: plate,
        });
        return this._currentAaBb;
      })
    );
  }

  get aabbValue(): Aabb2 {
    return this._currentAaBb;
  }

  public getPlateAAbb(stepAndPlate: StepAndPlate): Aabb2 {
    if (this.customPatternEditorIsOpened) {
      return getPerforationCustomGeomAabb(this.perforationCustomGeom);
    } else if (stepAndPlate.step === Step.import) {
      const contures: Segment[][] = this.importedShape
        ? this.importedShape
        : [];
      let aabb = getEmptyAaabb();
      for (const c of contures) {
        aabb = mergeAabb(aabb, aabbOfPolyline(c));
      }
      if (isAabb2Empty(aabb)) {
        aabb = {
          min: { x: 0, y: 0 },
          max: { x: 0, y: 0 },
        };
        if (this.grid && this.grid.size) {
          aabb.max = copyVector2(this.grid.size);
          aabb.min = copyVector2(multiplyVector2byScalar(this.grid.size, -1));
        }
      }
      return aabb;
    } else {
      const plate = stepAndPlate.plate;
      const contures =
        plate && plate.shapeWithHoles && plate.shapeWithHoles.conture
          ? [plate.shapeWithHoles.conture]
          : [];
      const segmentIterator = forEachSegmentInPolylines.bind(null, contures);
      let aabb = aabbOfSegments(segmentIterator);
      if (isAabb2Empty(aabb)) {
        aabb = {
          min: { x: 0, y: 0 },
          max: { x: 0, y: 0 },
        };
        // if (this.grid && this.grid.size) {
        //   aabb.max = copyVector2(this.grid.size);
        //   aabb.min = copyVector2(multiplyVector2byScalar(this.grid.size, -1));
        // }
      }
      return aabb;
    }
  }
}
