import { Component, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable, combineLatest, timer } from "rxjs";
import { map } from "rxjs/operators";
import { Vector2, multiplyVector2byScalar } from "webcad";
import { PointerState } from "webcad/collision";
import { subVectors2 } from "webcad/math";
import { Aabb2 } from "webcad/models/aabb.model";
import { HintStatus } from "../../model/hint.model";
import { MevacoPointerProvider } from "../../providers/mevaco-pointer.provider";
import { PlateAabbProvider } from "../../providers/plate-aabb.provider";
import { TranslationProvider } from "../../providers/translation.provider";
import { View3dProvider } from "../../providers/view3d.provider";
import { WorkLoadService } from "../../services/work-load.service";
import {
  MevacoState,
  getBendingLines,
  getGridSize,
  getHintMessage,
  getHintStatus,
} from "../../store/reducers";
import { Vector3 } from "webcad/babylonjs/core";

@Component({
  selector: "status-bar",
  templateUrl: "./status-bar.component.html",
  styleUrls: ["./status-bar.component.css"],
})
export class StatusBarComponent implements OnInit {
  public pointer: Observable<Vector2>;
  public isWorking: Observable<boolean>;
  public gridSize: Observable<number>;
  public hintStatusObs: Observable<HintStatus>;
  public hintText: Observable<string>;
  public hintStatus = HintStatus;
  sideDesc: Observable<string>;

  constructor(
    private pointerController: MevacoPointerProvider,
    private plateAabbProvider: PlateAabbProvider,
    private translationProvider: TranslationProvider,
    private store: Store<MevacoState>,
    private view3dProvider: View3dProvider,
    public workloadService: WorkLoadService
  ) {}

  ngOnInit() {
    this.pointer = combineLatest([
      this.pointerController.pointerState,
      this.plateAabbProvider.aabb,
    ]).pipe(
      map<[PointerState, Aabb2], Vector2>(([ps, aabb]) =>
        multiplyVector2byScalar(subVectors2(ps.position, aabb.min), 1000)
      )
    );
    this.isWorking = this.workloadService.isWorking;
    this.gridSize = this.store.pipe(select(getGridSize));
    this.hintStatusObs = this.store.pipe(select(getHintStatus));
    this.hintText = this.store.pipe(select(getHintMessage));
    this.sideDesc = combineLatest([
      this.store.pipe(select(getBendingLines)),
      timer(100, 100),
    ]).pipe(
      map(([bendingLines, _]) => {
        const cameraDir = this.view3dProvider.camera.getDirection(
          new Vector3(0, 0, 1)
        );
        if (bendingLines && bendingLines.length > 0) {
          if (bendingLines[0].angle > 0) {
            return cameraDir.z < 0 ? "ridge inside" : "not ridge outside";
          } else {
            return cameraDir.z < 0 ? "ridge outside" : "not ridge inside";
          }
        } else {
          return cameraDir.z < 0 ? "ridge side" : "visible side";
        }
      })
    );
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}
