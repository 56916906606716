import {
    addVectors2,
    angleBetween,
    CircleCircleIntersect2D,
    copyVector2, distanceVector2,
    dotVector2,
    getRotation2DMatrix,
    getTranslationMatrix,
    isPointBetween, lengthVector2,
    LineCircleIntersect2D,
    LineIntersect2D,
    Matrix3,
    multiplyMatrices, multiplyVector2ByFloats,
    multiplyVector2byScalar,
    pointOnLineProjection,
    projectPointToSegment,
    sqrDistanceVector2,
    sqrLengthVector2,
    subVectors2,
    transformPosition2,
    Vector2
} from '../math';
import {AngleMeasurementModel} from './angle-measurement.model';
import {MeasurementModel} from './measurement.model';
import {Aabb2, expandAabb, getEmptyAaabb, mergeAabb} from './aabb.model';
import {BoundingCircle} from '../math/bounding-circle';


export enum SegmentType {
    line = 'line',
    arc = 'arc'
}

export interface Segment {
    begin: Vector2;
    end: Vector2;
    type: SegmentType;
    origin?: Vector2;
    radius?: number;
    beginAngle?: number;
    endAngle?: number;
}

export interface SegmentAndProjectedPoint {
    segment: Segment;
    projectedPoint: Vector2;
}

export function deepCopySegment(polyline: Segment): Segment {
    const newPolyline = {...polyline};
    newPolyline.begin = copyVector2(polyline.begin);
    newPolyline.end = copyVector2(polyline.end);
    if (newPolyline.origin) {
        newPolyline.origin = copyVector2(polyline.origin);
    }
    return newPolyline;
}

export function deepCopyPolyline(polyline: Segment[]): Segment[] {
    return polyline.map(x => deepCopySegment(x));
}

export function getPointsFromSegment(p: Segment, withEnd: boolean = false): Vector2[] {

    switch (p.type) {
        case SegmentType.line:
            return getPointsFromLineSegment(p, withEnd);
        case SegmentType.arc:
            if (p.beginAngle === p.endAngle) {
                return getPointsFromLineSegment(p, withEnd);
            } else {
                return getPointsFromArcSegment(p, withEnd);
            }
    }
}

export function getPointsFromLineSegment(p: Segment, withEnd: boolean = false): Vector2[] {
    const vec: Vector2[] = [];
    vec.push(copyVector2(p.begin));
    if (withEnd) {
        vec.push(copyVector2(p.end));
    }
    return vec;
}

export function getPointsFromArcSegment(p: Segment, withEnd: boolean = false): Vector2[] {
    const vec: Vector2[] = [];
    let numberOfPoints = 8 + (Math.abs(p.beginAngle - p.endAngle) * p.radius * 100);
    // make sure it divides by 4 so that we can get better results (points on min/max x/y)
    numberOfPoints = Math.floor(numberOfPoints / 4) * 4 + 4;
    vec.push(copyVector2(p.begin));
    for (let i = 1; i < numberOfPoints; i++) {
        const a = i / numberOfPoints * (p.endAngle - p.beginAngle) + p.beginAngle;
        vec.push({
            x: Math.cos(a) * p.radius + p.origin.x,
            y: Math.sin(a) * p.radius + p.origin.y
        });
    }
    if (withEnd) {
        vec.push(copyVector2(p.end));
    }
    return vec;
}

export function forEachSegmentInPolylines(polylines: Segment[][], callback: (segment: Segment) => void): void {
    for (let i = 0; i < polylines.length; i++) {
        const polyline = polylines[i];
        for (let j = 0; j < polyline.length; j++) {
            const segment = polyline[j];
            callback(segment);
        }
    }
}

export function isPolylineClosed(segments: Segment[]): boolean {
    return (sqrDistanceVector2(segments[0].begin, segments[segments.length - 1].end) < 0.00000005);
}

export function measurementToSegment(measurement: MeasurementModel): Segment {
    return {
        begin: measurement.start,
        end: addVectors2(measurement.start, multiplyVector2byScalar(measurement.measurementDirection, measurement.exchange.value)),
        type: SegmentType.line
    };
}

export function angleMeasurementToSegment(measurement: AngleMeasurementModel): Segment {
    const beginAngle = Math.atan2(measurement.startDir.y, measurement.startDir.x);
    return {
        origin: measurement.origin,
        type: SegmentType.arc,
        beginAngle: beginAngle,
        endAngle: beginAngle + measurement.exchange.value,
        begin: {x: measurement.origin.x + Math.cos(beginAngle), y: measurement.origin.y + Math.sin(beginAngle)},
        end: {
            x: measurement.origin.x + Math.cos(beginAngle + measurement.exchange.value),
            y: measurement.origin.y + Math.sin(beginAngle + measurement.exchange.value)
        },
        radius: 1
    };
}

export function projectPointOnSegment(point: Vector2, s: Segment, noClip: boolean = false): Vector2 {
    if (s.type === SegmentType.line) {
        const dir = subVectors2(s.end, s.begin);
        const pp = pointOnLineProjection(point, s.begin, dir);
        if (noClip || isPointBetween(s.begin, s.end, pp)) {
            return pp;
        } else {
            return null;
        }
    } else if (s.type === SegmentType.arc) {
        return projectPointOnArcSegment(point, s);
    }
    return null;
}

export function IsPointInsideOfShape(point: Vector2, shape: Segment[]): boolean {
    const dir = {x: 1, y: 0};
    let numberOfIntersections = 0;
    for (const s of shape) {
        if (s.type === SegmentType.line) {
            const sDir = subVectors2(s.end, s.begin);
            const p = LineIntersect2D(dir, point, sDir, s.begin);
            if (p) {
                const toP = subVectors2(p, point);
                if (dotVector2(dir, toP) > 0) {
                    if (isPointBetween(s.begin, s.end, p)) {
                        numberOfIntersections++;
                    }
                }
            }
        } else if (s.type === SegmentType.arc) {
            const intersects = LineCircleIntersect2D(s.origin, s.radius, point, addVectors2(point, multiplyVector2byScalar(dir, 50)), false);
            for (const i of intersects) {
                const toP = subVectors2(i, point);
                if (dotVector2(dir, toP) > 0) {
                    if (isPointInArcSegment(i, s)) {
                        numberOfIntersections++;
                    }
                }
            }
        }
    }
    return !(numberOfIntersections % 2 === 0);
}

export function polylineContainsPoint(polyline: Segment[], point: Vector2): boolean {
    const intersections: number[] = [];
    for (let i = 0; i < polyline.length; i++) {
        const segment = polyline[i];
        if (segment.type === SegmentType.line ) {
            const d = subVectors2( segment.end, segment.begin);
            if (d.y != 0) {
                const t = (point.y - segment.begin.y) / d.y;
                if (t >= 0 && t <= 1) {
                    const x: number = segment.begin.x + d.x * t;
                    if (x >= point.x && intersections.indexOf(x) === -1) {
                        intersections.push(x);
                    }
                }
            }
        } else {
            const ly = point.y - segment.origin.y;
            const r2 = segment.radius * segment.radius;
            const y2 = ly * ly;
            if (r2 > y2) {
                const lx = Math.sqrt(r2 - y2);
                const p1 = {x: segment.origin.x - lx, y: point.y};
                if ( p1.x >= point.x && isPointInArcSegment(p1, segment )  && intersections.indexOf(p1.x) === -1) {
                    intersections.push(p1.x);
                }
                const p2 = {x: segment.origin.x + lx, y: point.y};
                if ( p2.x >= point.x && isPointInArcSegment(p2, segment ) && intersections.indexOf(p2.x) === -1) {
                    intersections.push(p2.x);
                }
            }
        }
    }
    return intersections.length % 2 === 1;
}

export function segmentSegmentIntersection(s1: Segment, s2: Segment): Vector2 {
    if (s1.type === SegmentType.line) {
        const segmentDir = subVectors2(s1.end, s1.begin);
        if (s2.type === SegmentType.line) {
            const p = LineIntersect2D(segmentDir, s1.begin, subVectors2(s2.end, s2.begin), s2.begin);
            if (p && isPointBetween(s1.begin, s1.end, p) && isPointBetween(s2.begin, s2.end, p)) {
                return p;
            }
        } else if (s2.type === SegmentType.arc) {
            const ps = LineCircleIntersect2D(s2.origin, s2.radius, s1.begin, s1.end, false);
            for (const p of ps) {
                if (isPointInArcSegment(p, s2) && isPointBetween(s1.begin, s1.end, p)) {
                    return p;
                }
            }
        }
    } else {
        if (s2.type === SegmentType.line) {
            const ps = LineCircleIntersect2D(s1.origin, s1.radius, s2.begin, s2.end, false);
            for (const p of ps) {
                if (isPointInArcSegment(p, s1) && isPointBetween(s2.begin, s2.end, p)) {
                    return p;
                }
            }
        } else if (s2.type === SegmentType.arc) {
            const ps = CircleCircleIntersect2D(s1.origin.x, s1.origin.y, s1.radius, s2.origin.x, s2.origin.y, s2.radius);
            for (const p of ps) {
                if (isPointInArcSegment(p, s1) && isPointInArcSegment(p, s2)) {
                    return p;
                }
            }
        }
    }
    return null;
}

export function getAngleInArcSegment(s: Segment): number {
    const beginAngle = Math.min(s.beginAngle, s.endAngle);
    const endAngle = Math.max(s.beginAngle, s.endAngle);
    return endAngle - beginAngle;
}

export function aabbOfSegments(iterator: (callback: (segment: Segment) => void) => void): Aabb2 {
    let aabb: Aabb2 = getEmptyAaabb();

    iterator((segment: Segment) => {
        aabb = mergeAabb(aabb, aabbSegment(segment));
    });
    return aabb;
}

export function aabbOfPolyline(polyline: Segment[]): Aabb2 {
    let aabb: Aabb2 = getEmptyAaabb();
    for (const s of polyline) {
        aabb = mergeAabb(aabb, aabbSegment(s));
    }
    return aabb;
}

export function aabbOfPolylines(polylines: Segment[][]): Aabb2 {
    let aabb: Aabb2 = getEmptyAaabb();
    for (const polyline of polylines) {
        for (const s of polyline) {
            aabb = mergeAabb(aabb, aabbSegment(s));
        }
    }
    return aabb;
}

export function aabbSegment(segment: Segment): Aabb2 {
    switch (segment.type) {
        case SegmentType.line:
            return {
                min: {
                    x: Math.min(segment.begin.x, segment.end.x),
                    y: Math.min(segment.begin.y, segment.end.y),
                },
                max: {
                    x: Math.max(segment.begin.x, segment.end.x),
                    y: Math.max(segment.begin.y, segment.end.y),
                }
            };

        case SegmentType.arc:
            let aabb: Aabb2 = {
                min: {
                    x: Math.min(segment.begin.x, segment.end.x),
                    y: Math.min(segment.begin.y, segment.end.y),
                },
                max: {
                    x: Math.max(segment.begin.x, segment.end.x),
                    y: Math.max(segment.begin.y, segment.end.y),
                }
            };

            if (angleBetween(0, segment.beginAngle, segment.endAngle)) {
                aabb = expandAabb(aabb, {x: segment.origin.x + segment.radius, y: segment.origin.y});
            }
            if (angleBetween(Math.PI * 0.5, segment.beginAngle, segment.endAngle)) {
                aabb = expandAabb(aabb, {x: segment.origin.x, y: segment.origin.y + segment.radius});
            }
            if (angleBetween(Math.PI, segment.beginAngle, segment.endAngle)) {
                aabb = expandAabb(aabb, {x: segment.origin.x - segment.radius, y: segment.origin.y});
            }
            if (angleBetween(Math.PI * 1.5, segment.beginAngle, segment.endAngle)) {
                aabb = expandAabb(aabb, {x: segment.origin.x, y: segment.origin.y - segment.radius});
            }
            return aabb;
    }
}

export function rotatePolylineInPlace(polyline: Segment[], rotation: number, point: Vector2 = {x: 0, y: 0}): Segment[] {
    const toOrigin: Vector2 = multiplyVector2byScalar(point, -1);
    const matrix = multiplyMatrices(
        getTranslationMatrix(point),
        multiplyMatrices(
            getRotation2DMatrix(rotation),
            getTranslationMatrix(toOrigin))
    );
    for (const s of polyline) {
        const newSegment = rotateSegmentWithMatrixInPlace(s, matrix);
        if (s.type === SegmentType.arc) {
            newSegment.beginAngle = s.beginAngle + rotation;
            newSegment.endAngle = s.endAngle + rotation;
        }
    }
    return polyline;
}

export function rotatePolyline(polyline: Segment[], rotation: number, point: Vector2 = {x: 0, y: 0}): Segment[] {
    const newPolyline = [];
    const toOrigin: Vector2 = multiplyVector2byScalar(point, -1);
    const matrix = multiplyMatrices(
        getTranslationMatrix(point),
        multiplyMatrices(
            getRotation2DMatrix(rotation),
            getTranslationMatrix(toOrigin))
    );
    for (const s of polyline) {
        const newSegment = rotateSegmentWithMatrix(s, matrix);
        if (s.type === SegmentType.arc) {
            newSegment.beginAngle = s.beginAngle + rotation;
            newSegment.endAngle = s.endAngle + rotation;
        }
        newPolyline.push(newSegment);
    }
    return newPolyline;
}

export function rotateSegment(segment: Segment, rotation: number, point?: Vector2): Segment {
    let toOrigin: Vector2 = {x: 0, y: 0};
    if (point) {
        toOrigin = multiplyVector2byScalar(point, -1);
    }
    const matrix = multiplyMatrices(
        multiplyMatrices(getTranslationMatrix(toOrigin), getRotation2DMatrix(rotation)),
        getTranslationMatrix(multiplyVector2byScalar(toOrigin, -1))
    );
    if (segment.type === SegmentType.line) {
        return rotateLineSegmentWithMatrix(segment, matrix);
    } else {
        const newSegment = rotateArcSegmentWithMatrix(segment, matrix);
        newSegment.beginAngle = segment.beginAngle + rotation;
        newSegment.endAngle = segment.endAngle + rotation;
        return newSegment;
    }
}

export function rotateSegmentWithMatrix(segment: Segment, matrix: Matrix3): Segment {
    if (segment.type === SegmentType.line) {
        return rotateLineSegmentWithMatrix(segment, matrix);
    } else {
        return rotateArcSegmentWithMatrix(segment, matrix);
    }
}

export function rotateSegmentWithMatrixInPlace(segment: Segment, matrix: Matrix3): Segment {
    if (segment.type === SegmentType.line) {
        return rotateLineSegmentWithMatrixInPlace(segment, matrix);
    } else {
        return rotateArcSegmentWithMatrixInPlace(segment, matrix);
    }
}

export function rotateLineSegment(segment: Segment, rotation: number, point?: Vector2): Segment {
    let toOrigin: Vector2 = {x: 0, y: 0};
    if (point) {
        toOrigin = multiplyVector2byScalar(point, -1);
    }
    const matrix = multiplyMatrices(
        multiplyMatrices(getTranslationMatrix(toOrigin), getRotation2DMatrix(rotation)),
        getTranslationMatrix(multiplyVector2byScalar(toOrigin, -1)));
    return rotateLineSegmentWithMatrix(segment, matrix);
}

export function rotateLineSegmentWithMatrix(segment: Segment, matrix: Matrix3): Segment {
    const newBegin = transformPosition2(matrix, segment.begin);
    const newEnd = transformPosition2(matrix, segment.end);
    return {
        ...deepCopySegment(segment),
        end: newEnd,
        begin: newBegin
    };
}

export function rotateLineSegmentWithMatrixInPlace(segment: Segment, matrix: Matrix3): Segment {
    const newBegin = transformPosition2(matrix, segment.begin);
    const newEnd = transformPosition2(matrix, segment.end);
    segment.begin = newBegin;
    segment.end = newEnd;
    return segment;
}

export function rotateArcSegment(segment: Segment, rotation: number, point?: Vector2): Segment {
    let toOrigin: Vector2 = {x: 0, y: 0};
    if (point) {
        toOrigin = multiplyVector2byScalar(point, -1);
    }
    const matrix = multiplyMatrices(
        multiplyMatrices(getTranslationMatrix(toOrigin), getRotation2DMatrix(rotation)),
        getTranslationMatrix(multiplyVector2byScalar(toOrigin, -1)));
    const s = rotateArcSegmentWithMatrix(segment, matrix);
    s.beginAngle += rotation;
    s.endAngle += rotation;
    return s;
}

export function rotateArcSegmentWithMatrix(segment: Segment, matrix: Matrix3): Segment {
    const newBegin = transformPosition2(matrix, segment.begin);
    const newEnd = transformPosition2(matrix, segment.end);
    const newOrigin = transformPosition2(matrix, segment.origin);
    return {
        ...deepCopySegment(segment),
        end: newEnd,
        begin: newBegin,
        origin: newOrigin
    };
}

export function rotateArcSegmentWithMatrixInPlace(segment: Segment, matrix: Matrix3): Segment {
    const newBegin = transformPosition2(matrix, segment.begin);
    const newEnd = transformPosition2(matrix, segment.end);
    const newOrigin = transformPosition2(matrix, segment.origin);
    segment.begin = newBegin;
    segment.end = newEnd;
    segment.origin = newOrigin;
    return segment;
}

export function getShortestPathBetweenLineSegments(s1: Segment, s2: Segment): Segment {
    const u = subVectors2(s1.end, s1.begin);
    const v = subVectors2(s2.end, s2.begin);
    const w = subVectors2(s1.begin, s2.begin);
    const a = dotVector2(u, u);         // always >= 0
    const b = dotVector2(u, v);
    const c = dotVector2(v, v);         // always >= 0
    const d = dotVector2(u, w);
    const e = dotVector2(v, w);
    const D = a * c - b * b;        // always >= 0
    let sc, sN, sD = D;       // sc = sN / sD, default sD = D >= 0
    let tc, tN, tD = D;       // tc = tN / tD, default tD = D >= 0
    const SMALL_NUM = 0.00005;
    // compute the line parameters of the two closest points
    if (D < SMALL_NUM) { // the lines are almost parallel
        sN = 0.0;         // force using point P0 on segment S1
        sD = 1.0;         // to prevent possible division by 0.0 later
        tN = e;
        tD = c;
    } else {                 // get the closest points on the infinite lines
        sN = (b * e - c * d);
        tN = (a * e - b * d);
        if (sN < 0.0) {        // sc < 0 => the s=0 edge is visible
            sN = 0.0;
            tN = e;
            tD = c;
        } else if (sN > sD) {  // sc > 1  => the s=1 edge is visible
            sN = sD;
            tN = e + b;
            tD = c;
        }
    }

    if (tN < 0.0) {            // tc < 0 => the t=0 edge is visible
        tN = 0.0;
        // recompute sc for this edge
        if (-d < 0.0) {
            sN = 0.0;
        } else if (-d > a) {
            sN = sD;
 } else {
            sN = -d;
            sD = a;
        }
    } else if (tN > tD) {      // tc > 1  => the t=1 edge is visible
        tN = tD;
        // recompute sc for this edge
        if ((-d + b) < 0.0) {
            sN = 0;
        } else if ((-d + b) > a) {
            sN = sD;
 } else {
            sN = (-d + b);
            sD = a;
        }
    }
    // finally do the division to get sc and tc
    sc = (Math.abs(sN) < SMALL_NUM ? 0.0 : sN / sD);
    tc = (Math.abs(tN) < SMALL_NUM ? 0.0 : tN / tD);

    // get the difference of the two closest points
    return {
        begin: addVectors2(s1.begin, multiplyVector2byScalar(u, sc)),
        end: addVectors2(s2.begin, multiplyVector2byScalar(v, tc)),
        type: SegmentType.line
    };  // =  S1(sc) - S2(tc)

}

export function LineSegmentsFromPath(path: Vector2[]): Segment[] {
    const segments: Segment[] = [];
    for (let i = 0; i < path.length; i++) {
        const next = i + 1 < path.length ? path[i + 1] : path[0];
        const current = path[i];
        segments.push({
            type: SegmentType.line,
            begin: current,
            end: next
        });
    }
    return removeZeroLengthSegments(segments);
}


export function getShortestPathBetweenArcSegments(s1: Segment, s2: Segment): Segment {

    // see for intersections of segments and a line that goes thru they origins
    const s1InterSections = LineCircleIntersect2D(s1.origin, s1.radius, s1.origin, s2.origin, false);
    const s1Points = [s1.begin, s1.end];
    for (const p of s1InterSections) {
        if (isPointInArcSegment(p, s1)) {
            s1Points.push(p);
        }
    }
    const s2InterSections = LineCircleIntersect2D(s2.origin, s2.radius, s2.origin, s1.origin, false);
    const s2Points = [s2.begin, s2.end];
    for (const p of s2InterSections) {
        if (isPointInArcSegment(p, s2)) {
            s2Points.push(p);
        }
    }
    const possibleSegments: Segment[] = [];
    for (const p1 of s1Points) {
        for (const p2 of s2Points) {
            possibleSegments.push({
                begin: p1,
                end: p2,
                type: SegmentType.line
            });
        }
    }
    let minDist = Number.POSITIVE_INFINITY;
    let segment = null;
    for (const s of possibleSegments) {
        const dist = sqrLengthVector2(subVectors2(s.end, s.begin));
        if (dist < minDist) {
            segment = s;
            minDist = dist;
        }
    }
    return segment;
}

export function getShortestPathBetweenLineAndArcSegments(line: Segment, arc: Segment): Segment {
    const linePoints = [line.begin, line.end];
    const arcPoints = [arc.begin, arc.end];
    const arcPointsProjectedOnLine = [];
    for (const p of arcPoints) {
        const projected = projectPointOnSegment(p, line);
        if (projected) {
            arcPointsProjectedOnLine.push(projected);
        }
    }
    for (const p of linePoints) {
        const projected = projectPointOnArcSegment(p, arc);
        if (projected) {
            arcPoints.push(projected);
        }
    }
    linePoints.push(...arcPointsProjectedOnLine);
    const pp = projectPointOnSegment(arc.origin, line);
    if (pp) {
        linePoints.push(pp);
        const intersects = LineCircleIntersect2D(arc.origin, arc.radius, arc.origin, pp, false);
        for (const p of intersects) {
            if (isPointInArcSegment(p, arc) && isPointBetween(arc.origin, pp, p)) {
                arcPoints.push(p);
            }
        }
    }
    const possibleSegments: Segment[] = [];
    for (const p1 of linePoints) {
        for (const p2 of arcPoints) {
            possibleSegments.push({
                begin: p1,
                end: p2,
                type: SegmentType.line
            });
        }
    }
    let minDist = Number.POSITIVE_INFINITY;
    let segment = null;
    for (const s of possibleSegments) {
        const dist = sqrLengthVector2(subVectors2(s.end, s.begin));
        if (dist < minDist) {
            segment = s;
            minDist = dist;
        }
    }
    return segment;
}

export function getShortestPathBetweenSegments(s1: Segment, s2: Segment): Segment {
    if (s1.type === SegmentType.line && s2.type === SegmentType.line) {
        return getShortestPathBetweenLineSegments(s1, s2);
    }
    if (s1.type === SegmentType.arc && s2.type === SegmentType.arc) {
        return getShortestPathBetweenArcSegments(s1, s2);
    }
    if (s1.type === SegmentType.line && s2.type === SegmentType.arc) {
        return getShortestPathBetweenLineAndArcSegments(s1, s2);
    } else {
        const s = getShortestPathBetweenLineAndArcSegments(s2, s1);
        const begin = copyVector2(s.begin);
        s.begin = s.end;
        s.end = begin;
        return s;
    }
}

export function getShortestPathBetweenTwoPolylines(p1: Segment[], p2: Segment[]): Segment {
    let sqrminDist = Number.MAX_VALUE;
    let result: Segment = null;
    for (const s1 of p1) {
        for (const s2 of p2) {
            const intersection = segmentSegmentIntersection(s1, s2);
            if (intersection) {
                return {begin: intersection, end: intersection, type: SegmentType.line};
            }
            const newS = getShortestPathBetweenSegments(s1, s2);
            const sqrL = sqrDistanceVector2(newS.begin, newS.end);
            if (sqrL < sqrminDist) {
                sqrminDist = sqrL;
                result = newS;
            }
        }
    }
    return result;
}

export function getShortestPathBetweenPolylinesAndSegment(polylines: Segment[][], s2: Segment): Segment {
    let sqrminDist = Number.MAX_VALUE;
    let result: Segment = null;
    for (const p1 of polylines) {
        for (const s1 of p1) {
            const intersection = segmentSegmentIntersection(s1, s2);
            if (intersection) {
                return {begin: intersection, end: intersection, type: SegmentType.line};
            }
            const newS = getShortestPathBetweenSegments(s1, s2);
            const sqrL = sqrDistanceVector2(newS.begin, newS.end);
            if (sqrL < sqrminDist) {
                sqrminDist = sqrL;
                result = newS;
            }
        }
    }
    return result;
}

export function getShortestPathBetweenPolylinesAndPolyline(polylines: Segment[][], p2: Segment[]): Segment {
    let sqrminDist = Number.MAX_VALUE;
    let result: Segment = null;
    for (const p1 of polylines) {
        for (const s1 of p1) {
            for (const s2 of p2) {
                const intersection = segmentSegmentIntersection(s1, s2);
                if (intersection) {
                    return {begin: intersection, end: intersection, type: SegmentType.line};
                }
                const newS = getShortestPathBetweenSegments(s1, s2);
                const sqrL = sqrDistanceVector2(newS.begin, newS.end);
                if (sqrL < sqrminDist) {
                    sqrminDist = sqrL;
                    result = newS;
                }
            }
        }
    }
    return result;
}

export function clipPointToLineSegment(point: Vector2, segment: Segment): Vector2 {
    const sDir = subVectors2(segment.end, segment.begin);
    const bToP = subVectors2(point, segment.begin);
    const dot = dotVector2(bToP, sDir);
    const checkProd = dotVector2(sDir, sDir);
    if (dot > 0) {
        if (dot < checkProd) {
            return point;
        } else {
            return segment.end;
        }
    } else {
        return segment.begin;
    }
}

export function isSegmentCircle(s: Segment): boolean {
    if (s.type === SegmentType.arc && !isNaN(s.beginAngle) && !isNaN(s.endAngle) && !isNaN(s.radius) && s.origin) {
        if (sqrDistanceVector2(s.begin, s.end) < 0.005) {
            if (Math.abs(Math.abs(s.endAngle - s.beginAngle) - (Math.PI * 2)) < 0.0005) {
                return true;
            }
        }
    }
    return false;
}

export function projectPointOnArcSegment(point: Vector2, segment: Segment): Vector2 {
    const originToPoint = subVectors2(point, segment.origin);
    const alpha = Math.atan2(originToPoint.y, originToPoint.x);
    const beginAngle = Math.min(segment.beginAngle, segment.endAngle);
    const endAngle = Math.max(segment.beginAngle, segment.endAngle);
    const delta = alpha - beginAngle;
    const normalizedDelta = delta - (Math.floor(delta / (Math.PI * 2)) * Math.PI * 2);
    const endMinusBegin = endAngle - beginAngle;
    if (normalizedDelta >= 0 && normalizedDelta <= endMinusBegin) {
        return {
            x: Math.cos(alpha) * segment.radius + segment.origin.x,
            y: Math.sin(alpha) * segment.radius + segment.origin.y
        };
    } else {
        return null;
    }
}

export function isPointInArcSegment(point: Vector2, s: Segment): boolean {
    const originToPoint = subVectors2(point, s.origin);
    const alpha = Math.atan2(originToPoint.y, originToPoint.x);
    const beginAngle = Math.min(s.beginAngle, s.endAngle);
    const endAngle = Math.max(s.beginAngle, s.endAngle);
    const delta = alpha - beginAngle;
    const normalizedDelta = delta - (Math.floor(delta / (Math.PI * 2)) * Math.PI * 2);
    const endMinusBegin = endAngle - beginAngle;
    const sqrRadius = s.radius * s.radius;
    if (Math.abs(sqrLengthVector2(originToPoint) - sqrRadius) < 0.0005 && normalizedDelta >= 0 && normalizedDelta <= endMinusBegin) {
        return true;
    }
    return false;
}

export function getPointOnSegment(point: Vector2, segment: Segment): Vector2 {
    switch (segment.type) {
        case SegmentType.line:
            const segmentDir = subVectors2(segment.end, segment.begin);
            const pp = projectPointToSegment(segment.begin, segmentDir, point);
            if (isPointBetween(segment.begin, segment.end, pp)) {
                return pp;
            } else {
                return null;
            }
        case SegmentType.arc:
            const projPoint = projectPointOnArcSegment(point, segment);
            if (projPoint && isPointInArcSegment(projPoint, segment)) {
                return projPoint;
            } else {
                return null;
            }
    }
}

export function PolylineArea(polyline: Segment[]): number {
    const vertices = [];
    for (const p of polyline) {
        vertices.push(...getPointsFromSegment(p));
    }
    let prevI = vertices.length - 1;
    let area = 0;
    for (let i = 0; i < vertices.length; i++) {
        const corner = vertices[i];
        const prevCorner = vertices[prevI];
        area += prevCorner.x * corner.y - prevCorner.y * corner.x;
        prevI = i;
    }

    // return Math.trunc(Math.abs(area / 2) * 10000) / 10000;
    return Math.abs(area / 2);
}

export function moveLineSegment(segment: Segment, vector: Vector2): Segment {
    return {
        ...segment,
        begin: addVectors2(segment.begin, vector),
        end: addVectors2(segment.end, vector)
    };
}

export function moveLineSegmentInPlace(segment: Segment, vector: Vector2): void {
    segment.begin.x += vector.x;
    segment.begin.y += vector.y;
    segment.end.x += vector.x;
    segment.end.y += vector.y;
}

export function moveArcSegment(segment: Segment, vector: Vector2): Segment {
    return {
        ...segment,
        begin: addVectors2(segment.begin, vector),
        end: addVectors2(segment.end, vector),
        origin: addVectors2(segment.origin, vector)
    };
}

export function moveArcSegmentInPlace(segment: Segment, vector: Vector2): void {
    segment.begin.x += vector.x;
    segment.begin.y += vector.y;
    segment.end.x += vector.x;
    segment.end.y += vector.y;
    segment.origin.x += vector.x;
    segment.origin.y += vector.y;
}

export function moveSegment(segment: Segment, vector: Vector2): Segment {
    if (segment.type === SegmentType.line) {
        return moveLineSegment(segment, vector);
    } else {
        return moveArcSegment(segment, vector);
    }
}

export function moveSegmentInPlace(segment: Segment, vector: Vector2): void {
    if (segment.type === SegmentType.line) {
        moveLineSegmentInPlace(segment, vector);
    } else {
        moveArcSegmentInPlace(segment, vector);
    }
}

export function movePolyline(polyline: Segment[], vector: Vector2): Segment[] {
    const newPolyline = [];
    for (const s of polyline) {
        newPolyline.push(moveSegment(s, vector));
    }
    return newPolyline;
}

export function movePolylineInPlace(polyline: Segment[], vector: Vector2) {
    for (const s of polyline) {
        moveSegmentInPlace(s, vector);
    }
}

export function movePolylines(polylines: Segment[][], vector: Vector2): Segment[][] {
    return polylines.map( polyline => movePolyline(polyline, vector));
}

export function scaleLineSegment(segment: Segment, xScale: number, yScale: number): Segment {
    return {
        ...segment,
        begin: multiplyVector2ByFloats(segment.begin, xScale, yScale),
        end: multiplyVector2ByFloats(segment.end, xScale, yScale)
    };
}

export function scaleArcSegment(segment: Segment, xScale: number, yScale: number): Segment {
    const newBegin = multiplyVector2ByFloats(segment.begin, xScale, yScale);
    const newOrigin = multiplyVector2ByFloats(segment.origin, xScale, yScale);
    const newRadius = distanceVector2(newOrigin, newBegin);
    return {
        ...segment,
        begin: newBegin,
        end: multiplyVector2ByFloats(segment.end, xScale, yScale),
        origin: newOrigin,
        radius: newRadius
    };
}

export function scaleSegment(segment: Segment, xScale: number, yScale: number): Segment {
    if (segment.type === SegmentType.line) {
        return scaleLineSegment(segment, xScale, yScale);
    } else {
        return scaleArcSegment(segment, xScale, yScale);
    }
}

export function scalePolyline(polyline: Segment[], xScale: number, yScale: number): Segment[] {
    // get aabb, move for half so its centered on 0,0, then flip coordinates
    const newPolyline = [];
    for (const s of polyline) {
        newPolyline.push(scaleSegment(s, xScale, yScale));
    }
    return newPolyline;
}

export function projectPointToPolyline(polyline: Segment[], point: Vector2): Vector2 {
    let sqrminDist = Number.MAX_VALUE;
    let result: Vector2 = null;
    for (const s of polyline) {
        const projected = projectPointOnSegment(point, s);
        if (projected) {
            const sqrDist = sqrDistanceVector2(projected, point);
            if (sqrDist < sqrminDist) {
                sqrminDist = sqrDist;
                result = projected;
            }
        }
    }
    return result;
}

export function projectPointToPolylines(polylines: Segment[][], point: Vector2): Vector2 {
    let sqrminDist = Number.MAX_VALUE;
    let result: Vector2 = null;

    for (const polyline of polylines) {
        for (const s of polyline) {
            const projected = projectPointOnSegment(point, s);
            if (projected) {
                const sqrDist = sqrDistanceVector2(projected, point);
                if (sqrDist < sqrminDist) {
                    sqrminDist = sqrDist;
                    result = projected;
                }
            }
        }
    }
    return result;
}


export function Aabb2ToPolyline(aabb: Aabb2): Segment[] {
    const ld: Vector2 = aabb.min;
    const ru: Vector2 = aabb.max;
    const rd: Vector2 = {x: aabb.max.x, y: aabb.min.y};
    const lu: Vector2 = {x: aabb.min.x, y: aabb.max.y};
    return [
        {
            type: SegmentType.line,
            begin: ld,
            end: rd
        },
        {
            type: SegmentType.line,
            begin: rd,
            end: ru
        },
        {
            type: SegmentType.line,
            begin: ru,
            end: lu
        },
        {
            type: SegmentType.line,
            begin: lu,
            end: ld
        }
    ];
}

export function PolylinesToVector2Arrays(polylines: Segment[][]): Vector2[][] {
    const ret: Vector2[][] = [];
    for (const polyline of polylines) {
        const points: Vector2[] = [];
        for (const segment of polyline) {
            points.push(...getPointsFromSegment(segment));
        }
        ret.push(points);
    }
    return ret;
}

export function PolylineToVector2Array(polyline: Segment[]): Vector2[] {
    const ret: Vector2[] = [];
    for (const segment of polyline) {
        ret.push(...getPointsFromSegment(segment));
    }
    return ret;
}

function getPointsFromPolyLineSegment(segment: Segment, path: Vector2[]): void {
    if (segment.type == SegmentType.arc) {
        const angle = segment.endAngle - segment.beginAngle;
        let numberOfPoints = 8 + Math.abs(angle) * segment.radius * 500;
        // make sure it divides by 4 so that we can get better results (points on min/max x/y)
        numberOfPoints = (numberOfPoints / 4) * 4 + 4;
        // numberOfPoints = 8;
        for (let i = 1; i < numberOfPoints; i++) {
            const a = (segment.endAngle - segment.beginAngle) *  i / numberOfPoints + segment.beginAngle;
            const arcPoint: Vector2 = {
                x: Math.cos(a) * segment.radius + segment.origin.x,
                y: Math.sin(a) * segment.radius + segment.origin.y
            };
            path.push(arcPoint);
        }
    } else {
        path.push(segment.begin);
    }
}

export function getPointsFromPolyLine(polyline: Segment[]): Vector2[] {
    const path: Vector2[] = [];
    for (let i = 0; i < polyline.length; i++) {
        const segmentElement = polyline[i];
        getPointsFromPolyLineSegment(segmentElement, path);
    }
    // path.push(polyline[polyline.length -1].end );
    return path;
}

export function getSegmentBoundingCircle(segment: Segment): BoundingCircle {
    if (segment.type === SegmentType.line) {
        return {
            origin : {
                x: (segment.begin.x + segment.end.x) / 2,
                y: (segment.begin.y + segment.end.y) / 2
            },
            radius: distanceVector2(segment.begin, segment.end) / 2
        };
    } else {
        if (Math.abs(segment.endAngle - segment.beginAngle) > Math.PI) {
            return {
                origin: segment.origin,
                radius: segment.radius
            };
        } else {
            return {
                origin : {
                    x: (segment.begin.x + segment.end.x) / 2,
                    y: (segment.begin.y + segment.end.y) / 2
                },
                radius: distanceVector2(segment.begin, segment.end) / 2
            };
        }
    }

}

export function isPointBetweenArcAngles(point: Vector2, segment: Segment): boolean {
    const originToPoint = subVectors2(point, segment.origin);
    const alpha = Math.atan2(originToPoint.y, originToPoint.x);
    const beginAngle = Math.min(segment.beginAngle, segment.endAngle);
    const endAngle = Math.max(segment.beginAngle, segment.endAngle);
    const delta = alpha - beginAngle;
    const normalizedDelta = delta - (Math.floor(delta / (Math.PI * 2)) * Math.PI * 2);
    const endMinusBegin = endAngle - beginAngle;
    return normalizedDelta >= -0.000001 && normalizedDelta <= endMinusBegin + 0.000001;
}


export function arcSegmentDistanceToCircle(segment: Segment, bc: BoundingCircle): number {
    if (isPointBetweenArcAngles(bc.origin, segment)) {
        return Math.max(0, distanceVector2(segment.origin, bc.origin) - segment.radius - bc.radius);
    } else {
        const s1 = sqrDistanceVector2(segment.begin, bc.origin);
        const s2 = sqrDistanceVector2(segment.begin, bc.origin);
        return Math.sqrt(Math.min(s1, s2));
    }
}

export function getClosestPointOnPolyline(point: Vector2, polyline: Segment[]): Vector2 {
    let minDist = Number.POSITIVE_INFINITY;
    let result: Vector2 = null;
    for (const segment of polyline) {
        const p = getClosestPointOnSegment(point, segment);
        const dist = sqrLengthVector2(subVectors2(p, point));
        if (dist < minDist) {
            result = p;
            minDist = dist;
        }
    }
    return result;
}

export function getClosestPointOnSegment(point: Vector2, segment: Segment): Vector2 {
    if (segment.type === SegmentType.line) {
        return getClosestPointOnLineSegment(point, segment);
    } else {
        return getClosestPointOnArc(point, segment);
    }
}

export function getClosestPointOnLineSegment(point: Vector2, line: Segment): Vector2 {
    const dir = subVectors2(line.end, line.begin);
    const pp = pointOnLineProjection(point, line.begin, dir);
    if (isPointBetween(line.begin, line.end, pp)) {
        return pp;
    } else {
        if (sqrDistanceVector2(point, line.begin) <  sqrDistanceVector2(point, line.end)) {
            return {...line.begin};
        } else {
            return {...line.end};
        }
    }
}

export function getClosestPointOnArc(point: Vector2, arc: Segment): Vector2 {
    const s1InterSections = LineCircleIntersect2D(arc.origin, arc.radius, arc.origin, point, false);
    const points = [arc.begin, arc.end];
    for (const p of s1InterSections) {
        if (isPointInArcSegment(p, arc)) {
            points.push(p);
        }
    }
    let minDist = Number.POSITIVE_INFINITY;
    let result: Vector2 = null;

    for (const p of points) {
        const dist = sqrLengthVector2(subVectors2(p, point));
        if (dist < minDist) {
            result = p;
            minDist = dist;
        }
    }
    return result;
}

export function segmentLength(segment: Segment): number {
    switch (segment.type) {
        case SegmentType.arc:
            return segment.radius * Math.abs(segment.endAngle - segment.beginAngle);
            return 0;
        case SegmentType.line:
            return distanceVector2(segment.begin, segment.end);
    }
}

export function removeZeroLengthSegments(polyline: Segment[]): Segment[] {
    return  polyline.filter( seg => segmentLength(seg) > 0);
}

export function isPointOnSegment(point: Vector2, segment: Segment): boolean {
    switch (segment.type) {
        case SegmentType.line:
            return isPointBetween(segment.begin, segment.end, point);
        case SegmentType.arc:
            return isPointInArcSegment(point, segment);
    }
}

export function margeTooSmallSegmentInPolyline(polyline: Segment[], threshold: number = 0.00005): Segment[] {
    const newPolyline = polyline.filter( s => segmentLength(s) >= threshold).map( s => deepCopySegment(s));
    for (let i = 0; i < newPolyline.length; i++) {
        const current = newPolyline[i];
        const next = newPolyline[(i + 1) % newPolyline.length];
        const px = (current.end.x + next.begin.x) / 2;
        const py = (current.end.y + next.begin.y) / 2;
        current.end.x = px;
        current.end.y = py;
        next.begin.x = px;
        next.begin.y = py;
    }
    return newPolyline;
}
