import { PointPointerCollider, PointerCollider } from "webcad/collision";
import { Pointer } from "webcad/collision/pointer";
import { PointerIntersection } from "webcad/collision/pointer-intersection";
import { Vector3 } from "webcad/math";
import { Segment } from "webcad/models";
import { HelpLine, HelpLineToSegment } from "../../model/help-line.model";
import { UnderPointerType } from "../../model/pointer-state.model";
import { ColliderPriority } from "./collider-priorities";
import { CursorType } from "./cursor.type";
import { MevacoCollider, SnappingContext } from "./mevaco.collider";
import { SegmentBaseCollider, SegmentCollider } from "./segment.collider";

export interface HelpingLineCollider extends MevacoCollider {
  helpLine: HelpLine;
}

export class HelpingLineBaseCollider
  extends SegmentBaseCollider
  implements HelpingLineCollider
{
  priority: number = ColliderPriority.HELP_LINE;
  objectType: UnderPointerType;
  public cursorType: CursorType = CursorType.envelope;

  public get object(): any {
    return this.helpLine;
  }

  constructor(public helpLine: HelpLine, snappingModelRef: SnappingContext) {
    super(HelpLineToSegment(helpLine), snappingModelRef);
    this.objectType = UnderPointerType.HELP_LINE;
  }

  getIntersection(pointer: Pointer, epsilon: number): PointerIntersection {
    if (this.snappingModelRef.snappingOptions.helpLines) {
      return super.getIntersection(pointer, epsilon);
    }
  }

  compare(other: PointerCollider): boolean {
    return (
      other instanceof HelpingLineBaseCollider &&
      this.helpLine === other.helpLine
    );
  }
}

export class HelpingLineSegmentCrossCollider
  extends PointPointerCollider
  implements SegmentCollider
{
  priority = ColliderPriority.CROSS_INTERSECTION;
  objectType: UnderPointerType;
  public cursorType: CursorType = CursorType.cross;

  public get object(): any {
    return this.segment;
  }

  constructor(
    public position: Vector3,
    public helpLine: HelpLine,
    public segment: Segment,
    public perforationAreaSegment = -1
  ) {
    super(position, "HelpingLineSegmentCross");
    this.objectType = UnderPointerType.VIRTUAL_NODE;
  }

  compare(other: PointerCollider): boolean {
    if (!super.compare(other)) {
      return false;
    }
    if (other instanceof HelpingLineSegmentCrossCollider) {
      const nc: HelpingLineSegmentCrossCollider =
        other as HelpingLineSegmentCrossCollider;
      return this.helpLine === nc.helpLine && this.segment === nc.segment;
    }
    return false;
  }
}

export class HelpLineHelpLineCrossCollider
  extends PointPointerCollider
  implements HelpingLineCollider
{
  priority = ColliderPriority.CROSS_INTERSECTION;
  objectType: UnderPointerType;
  public cursorType: CursorType = CursorType.cross;

  public get object(): any {
    return this.helpLine;
  }

  constructor(
    public position: Vector3,
    public helpLine: HelpLine,
    public helpLine2: HelpLine
  ) {
    super(position, "HelpLineHelpLineCross");
    this.objectType = UnderPointerType.VIRTUAL_NODE;
  }

  compare(other: PointerCollider): boolean {
    if (!super.compare(other)) {
      return false;
    }
    if (other instanceof HelpLineHelpLineCrossCollider) {
      const nc: HelpLineHelpLineCrossCollider =
        other as HelpLineHelpLineCrossCollider;
      return this.helpLine === nc.helpLine && this.helpLine2 === nc.helpLine2;
    }
    return false;
  }
}
