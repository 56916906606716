import { PointerCollider } from "webcad/collision";
import { Pointer } from "webcad/collision/pointer";
import { PointerIntersection } from "webcad/collision/pointer-intersection";
import {
  addVectors2,
  multiplyVector2byScalar,
  sqrDistanceVector2,
} from "webcad/math";
import {
  AngleMeasurementModel,
  MeasurementModel,
  SegmentType,
  angleMeasurementToSegment,
  projectPointOnSegment,
} from "webcad/models";
import { workingPlaneUnitSize } from "webcad/models/camera.model";
import { UnderPointerType } from "../../model/pointer-state.model";
import { ColliderPriority } from "./collider-priorities";
import { CursorType } from "./cursor.type";
import { MevacoCollider, SnappingContext } from "./mevaco.collider";
import { snapToGrid } from "./utils";

export class MeasurementCollider implements PointerCollider, MevacoCollider {
  priority: number = ColliderPriority.MEASUREMENT;
  cursorType: CursorType;
  objectType: UnderPointerType;
  name = "Measurement";

  public get object(): any {
    return this.measurement;
  }

  constructor(
    public measurement: MeasurementModel,
    private snappingModelRef: SnappingContext
  ) {
    this.objectType = UnderPointerType.MEASUREMENT;
    this.cursorType = CursorType.none;
  }

  compare(other: PointerCollider): boolean {
    return (
      other instanceof MeasurementCollider &&
      this.measurement === other.measurement
    );
  }

  getIntersection(pointer: Pointer, epsilon: number): PointerIntersection {
    const unitSize = workingPlaneUnitSize(this.snappingModelRef.camera);
    const distance = this.measurement.distance
      ? this.measurement.distance
      : 0.015 * unitSize.x;
    const offset = multiplyVector2byScalar(
      this.measurement.direction,
      distance
    );
    const measureDelta = multiplyVector2byScalar(
      this.measurement.measurementDirection,
      this.measurement.exchange.value
    );
    const measureEnd = addVectors2(this.measurement.start, measureDelta);
    const segment = {
      begin: addVectors2(this.measurement.start, offset),
      end: addVectors2(measureEnd, offset),
      type: SegmentType.line,
    };

    const point = pointer.onWorkingPlane;
    let pp = projectPointOnSegment(point, segment);

    if (pp && sqrDistanceVector2(point, pp) <= epsilon * epsilon * 2) {
      if (this.snappingModelRef.snappingOptions.grid) {
        pp = snapToGrid(
          pp,
          segment,
          this.snappingModelRef.snappingModel,
          epsilon
        );
      }
      return {
        position: { x: pp.x, y: pp.y, z: 0 },
        collider: this,
      };
    }
  }
}

export class AngleMeasurementCollider
  implements PointerCollider, MevacoCollider
{
  priority: number = ColliderPriority.MEASUREMENT;
  cursorType: CursorType;
  objectType: UnderPointerType;
  name = "AngleMeasurement";

  public get object(): any {
    return this.angleMeasurement;
  }

  constructor(
    public angleMeasurement: AngleMeasurementModel,
    private snappingModelRef: SnappingContext
  ) {
    this.objectType = UnderPointerType.ANGLE_MEASUREMENT;
    this.cursorType = CursorType.none;
  }

  compare(other: PointerCollider): boolean {
    return (
      other instanceof AngleMeasurementCollider &&
      this.angleMeasurement === other.angleMeasurement
    );
  }

  getIntersection(pointer: Pointer, epsilon: number): PointerIntersection {
    const segment = angleMeasurementToSegment(this.angleMeasurement);
    const unitSize = workingPlaneUnitSize(this.snappingModelRef.camera);
    const radius = this.angleMeasurement.radius
      ? this.angleMeasurement.radius
      : 0.05 * unitSize.x;
    segment.radius = radius;

    const point = pointer.onWorkingPlane;
    let pp = projectPointOnSegment(point, segment);

    if (pp && sqrDistanceVector2(point, pp) <= epsilon * epsilon) {
      if (this.snappingModelRef.snappingOptions.grid) {
        pp = snapToGrid(
          pp,
          segment,
          this.snappingModelRef.snappingModel,
          epsilon
        );
      }
      return {
        position: { x: pp.x, y: pp.y, z: 0 },
        collider: this,
      };
    }
  }
}

export class AutomaticMeasurementCollider
  implements PointerCollider, MevacoCollider
{
  priority: number = ColliderPriority.MEASUREMENT;
  cursorType: CursorType;
  objectType: UnderPointerType;
  name = "AutomaticMeasurement";

  public get object(): any {
    return this.measurement;
  }

  constructor(
    public measurement: MeasurementModel,
    private snappingModelRef: SnappingContext
  ) {
    this.objectType = UnderPointerType.AUTOMATIC_MEASUREMENT;
    this.cursorType = CursorType.none;
  }

  compare(other: PointerCollider): boolean {
    return (
      other instanceof MeasurementCollider &&
      this.measurement === other.measurement
    );
  }

  getIntersection(pointer: Pointer, epsilon: number): PointerIntersection {
    const unitSize = workingPlaneUnitSize(this.snappingModelRef.camera);
    const distance = this.measurement.distance
      ? this.measurement.distance
      : 0.015 * unitSize.x;
    const offset = multiplyVector2byScalar(
      this.measurement.direction,
      distance
    );
    const measureDelta = multiplyVector2byScalar(
      this.measurement.measurementDirection,
      this.measurement.exchange.value
    );
    const measureEnd = addVectors2(this.measurement.start, measureDelta);
    const segment = {
      begin: addVectors2(this.measurement.start, offset),
      end: addVectors2(measureEnd, offset),
      type: SegmentType.line,
    };

    const point = pointer.onWorkingPlane;
    let pp = projectPointOnSegment(point, segment);

    if (pp && sqrDistanceVector2(point, pp) <= epsilon * epsilon * 2) {
      if (this.snappingModelRef.snappingOptions.grid) {
        pp = snapToGrid(
          pp,
          segment,
          this.snappingModelRef.snappingModel,
          epsilon
        );
      }
      return {
        position: { x: pp.x, y: pp.y, z: 0 },
        collider: this,
      };
    }
  }
}
