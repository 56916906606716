export enum LinearDirection {
    TOP = 1,
    BOTTOM = 2,
    LEFT = 4,
    RIGHT = 8
}

export enum RadialDirection {
    TOP_LEFT = 1,
    TOP = 2,
    TOP_RIGHT = 4,
    CENTER_LEFT = 8,
    CENTER = 16,
    CENTER_RIGHT = 32,
    BOTTOM_LEFT = 64,
    BOTTOM = 128,
    BOTTOM_RIGHT = 256
}

export type Direction = LinearDirection | RadialDirection;

export enum FadingType {
    LINEAR,
    RADIAL,
}

export interface SelectedFading {
    enabled?: boolean;
    fadingType: FadingType;
    linearDirections: LinearDirection;
    radialDirections: RadialDirection;
    density: number;
    range: number;
    additionalSingleToolsIds: string[];
}

export function createLinearFadingParams(directions: Direction, density: number, range: number, ids: string[]): SelectedFading {
    return {
        fadingType: FadingType.LINEAR,
        radialDirections: null,
        linearDirections: directions as LinearDirection,
        density: density,
        range: range,
        additionalSingleToolsIds: ids
    };
}

export function createRadialFadingParams(directions: Direction, density: number, range: number, ids: string[]): SelectedFading {
    return {
        fadingType: FadingType.RADIAL,
        radialDirections: directions as RadialDirection,
        linearDirections: null,
        density: density,
        range: range,
        additionalSingleToolsIds: ids
    };
}
