<div class="dialog-modal-header">
  <div class="dialog-modal-header-content">
    <div class="dialog-modal-title-container">
      <h4 class="dialog-modal-title-primary" id="tilted-title">
        {{ this.templateUtils.translate("Paralellogram") }}
      </h4>
      <span class="dialog-modal-title-secondary"
        >{{
          this.templateUtils.translate("EnterDimenstions", "designer")
        }}
        (mm):</span
      >
    </div>
    <div class="dialog-modal-window">
      <span>{{
        this.templateUtils.translate("SketchDoesntChange", "designer")
      }}</span>
    </div>
  </div>

  <button
    type="button"
    class="close btn-close"
    aria-label="Close"
    (click)="dismiss('Cross click'); isModalOpenMethod()"
    [tabIndex]="-1"
  ></button>
</div>

<div>
  <div class="dialog-modal-body">
    <div class="paralellogram-inputs">
      <input
        type="text"
        id="tilted_a"
        class="input-small paralellogram-a"
        [tabIndex]="1"
        (keydown)="this.templateUtils.returnToLastElement($event, p_f1)"
        [ngStyle]="
          tiltedTemplate.a <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="roundDecimal(tiltedTemplate.a, 1)"
        (input)="
          setTiltedPlateA(this.templateUtils.roundInput($event, 0, 5000))
        "
        required
        #p_a
      />

      <input
        type="text"
        id="tilted_e1"
        class="input-small paralellogram-e1"
        [tabIndex]="6"
        [ngStyle]="
          tiltedTemplate.e1 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="tiltedTemplate.e1"
        (input)="
          tiltedTemplate.e1 = this.templateUtils.roundInput(
            $event,
            0,
            tiltedTemplate.a * 0.5
          );
          setTiltedTemplate(tiltedTemplate)
        "
        required
      />

      <input
        type="text"
        id="tilted_e2"
        class="input-small paralellogram-e2"
        [tabIndex]="5"
        [ngStyle]="
          tiltedTemplate.e2 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="tiltedTemplate.e2"
        (input)="
          tiltedTemplate.e2 = this.templateUtils.roundInput(
            $event,
            0,
            tiltedTemplate.a * 0.5
          );
          setTiltedTemplate(tiltedTemplate)
        "
        required
      />

      <input
        type="text"
        id="tilted_b"
        class="input-small paralellogram-b"
        [tabIndex]="2"
        [ngStyle]="
          tiltedTemplate.b <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="tiltedTemplate.b"
        (input)="
          tiltedTemplate.b = this.templateUtils.roundInput($event, 0, 5000);
          setTiltedTemplate(tiltedTemplate)
        "
        required
      />

      <input
        type="text"
        id="tilted_f1"
        class="input-small paralellogram-f1"
        [tabIndex]="8"
        (keydown)="this.templateUtils.returnToFirstElement($event, p_a)"
        [ngStyle]="
          tiltedTemplate.f1 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="tiltedTemplate.f1"
        (input)="
          tiltedTemplate.f1 = this.templateUtils.roundInput(
            $event,
            0,
            tiltedTemplate.b * 0.5
          );
          setTiltedTemplate(tiltedTemplate)
        "
        required
        #p_f1
      />

      <input
        type="text"
        id="tilted_f2"
        class="input-small paralellogram-f2"
        [tabIndex]="7"
        [ngStyle]="
          tiltedTemplate.f2 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="tiltedTemplate.f2"
        (input)="
          tiltedTemplate.f2 = this.templateUtils.roundInput(
            $event,
            0,
            tiltedTemplate.b * 0.5
          );
          setTiltedTemplate(tiltedTemplate)
        "
        required
      />

      <input
        type="text"
        id="tilted_angle"
        [tabIndex]="9"
        [ngStyle]="
          tiltedTemplate.tilt <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        class="input-small paralellogram-angle"
        [value]="tiltedTemplate.tilt * (180 / pi) | number : '1.0-2'"
        (input)="
          setTiltedPlateAngle(
            this.templateUtils.roundInput($event, null, null, 2)
          )
        "
        required
      />

      <input
        type="text"
        id="tilted_ha"
        class="input-small paralellogram-ha"
        [tabIndex]="3"
        [ngStyle]="
          tiltedTemplate.ha <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="roundDecimal(tiltedTemplate.ha, 1)"
        (input)="
          setTiltedPlateHa(this.templateUtils.roundInput($event, 0, 5000))
        "
        required
      />

      <input
        type="text"
        id="tilted_b1"
        class="input-small paralellogram-b1"
        [tabIndex]="4"
        [ngStyle]="
          tiltedTemplate.b1 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="roundDecimal(tiltedTemplate.b1, 1)"
        (input)="
          setTiltedPlateB1(this.templateUtils.roundInput($event, 0, 5000))
        "
        required
      />
    </div>
  </div>
  <div class="dialog-modal-footer">
    <span class="dialog-modal-link" (click)="this.templateUtils.setDefault()">
      {{ this.templateUtils.translate("Reset dimensions to default.") }}
    </span>
    <button
      #closeButton
      type="button"
      class="btn btn-primary btn-create"
      [disabled]="reqValParallelogram | async"
      [tabIndex]="1"
      (click)="close(tiltedTemplate)"
    >
      {{ this.templateUtils.translate("Create") }}
    </button>
  </div>
</div>
