<h4 class="bendings-checkboxes-title">{{this.templateUtils.translate('Bendings:', 'designer' )}}</h4>
<div>
  <div class="bendings-checkboxes-row">
    <div *ngIf='isTwoBend' class="toggle no-description" >
      <mat-checkbox [(ngModel)]="semiLeft" (ngModelChange)='this.left = this.left || this.semiLeft' class="base-checkbox">
      </mat-checkbox>
    </div>
    <div class="toggle">
      <mat-checkbox [(ngModel)]="left" (change)='this.semiLeft = false' class="base-checkbox">
      {{this.templateUtils.translate("Left", 'designer')}}
    </mat-checkbox>
  </div>
    <div *ngIf='isTwoBend' class="toggle no-description">
      <mat-checkbox [(ngModel)]="semiRight" (ngModelChange)='this.right = this.right || this.semiRight' class="base-checkbox">
      </mat-checkbox>
    </div>
    <div class="toggle">
      <mat-checkbox [(ngModel)]="right" (change)='this.semiRight = false' class="base-checkbox">
        {{this.templateUtils.translate("Right", 'designer')}}
      </mat-checkbox>
    </div>
  </div>
  <div class="bendings-checkboxes-row">
    <div *ngIf='isTwoBend' class="toggle no-description">
      <mat-checkbox [(ngModel)]="semiFront" (ngModelChange)='this.front = this.front || this.semiFront' class="base-checkbox">
      </mat-checkbox>
    </div>
    <div class="toggle">
      <mat-checkbox [(ngModel)]="front" (change)='this.semiFront = false' class="base-checkbox">
        {{this.templateUtils.translate("Front", 'designer')}}
      </mat-checkbox>
    </div>
    <div *ngIf='isTwoBend' class="toggle no-description">
      <mat-checkbox [(ngModel)]="semiBack" (ngModelChange)='this.back = this.back || this.semiBack' class="base-checkbox">
      </mat-checkbox>
    </div>
    <div class="toggle">
      <mat-checkbox  [(ngModel)]="back" (change)='this.semiBack = false' class="base-checkbox">
        {{this.templateUtils.translate("Back", 'designer')}}
      </mat-checkbox>
    </div>
  </div>
</div>
