import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store, select } from "@ngrx/store";
import {lastValueFrom, of, zip} from "rxjs";
import { map, switchMap, withLatestFrom } from "rxjs/operators";
import { ActionType, Plate, deepCopyPerforationAreas } from "../../model";
import { PlateShapeParams } from "../../model/client-server.Params/PlateShapeParams";
import {
  ShapeActionParams,
  initialShapeActionParams,
} from "../../model/client-server.Params/ShapeActionParams";
import { PlateService } from "../../services/plate.service";
import {
  ADD_SHAPE_TO_ADD,
  ADD_SHAPE_TO_REMOVE,
  BUILD_PLATE,
  BuildPlate,
  CALCULATE_SHAPE_FROM_POLYLINES,
  CALL_ZOOM,
  CANCEL_IMPORT,
  CalculateShapeFromPolylines,
  CalculateShapeFromPolylinesSuccess,
  CallZoom,
  LOAD_DXF,
  LoadDxf,
  LoadDxfSuccess,
  ROTATE_POLYLINE,
  RotatePolyline,
  RotatePolylineParams,
  SET_BACKGROUND_COLOR,
  //SetZones,
  SUBMIT_IMPORT,
  SetBackgroundColor,
  SetPerforationArea,
  SetPlateShape,
  UpdateDrawing,
  UpdateDrawingFail,
} from "../actions";
import { MevacoState, getPlate } from "../reducers";

import {
  Segment,
  aabbOfPolyline,
  getEmptyAaabb,
  isAabbInAabb,
  polylineContainsPoint,
  rotatePolylineInPlace,
} from "webcad/models";
import { PerforationAreaModel } from "../../model/perforation-area.model";
import { Step } from "../../model/product-configuration/product-configuration.model";
import {
  ShapeWithHoles,
  deepCopyShapeWithHoles,
} from "../../model/shape-with-holes";
import { ToolProvider } from "../../providers/tool.provider";
import { View3dProvider } from "../../providers/view3d.provider";
import {
  CREATE_PERFORATION_AREAS_FROM_SHAPE,
  CREATE_PERFORATION_AREA_FROM_ZONE,
  CREATE_PERFORATION_AREA_FROM_ZONE_SUCCESS,
  CreatePerforationAreaFromZone,
  CreatePerforationAreaFromZoneSuccess,
  CreatePerforationAreasFomShape,
  REQUEST_RENDER,
  RoundUpSelectedNodesParams,
  TREAT_CORNER,
  TREAT_SEGMENT_CORNERS,
  TreatCorner,
  TreatSegmentCorners,
  UPDATE_PLATE_SHAPE,
  UpdatePlateShape,
  ZonePerforationAreaParams,
} from "../actions/drawing.actions";
import { SetError } from "../actions/errorable.actions";
import {applySegmentsMove} from "../store.utils";

interface BuildPlateMapping {
  action: Action;
  conture: Segment[];
}

@Injectable()
export class BuildPlateEffects {
  constructor(
    private actions: Actions,
    private plateService: PlateService,
    private store: Store<MevacoState>,
    private view3dProvider: View3dProvider,
    private toolProvider: ToolProvider
  ) {}

  buildPlate = createEffect(() =>
    this.actions.pipe(
      ofType(BUILD_PLATE, ADD_SHAPE_TO_ADD, ADD_SHAPE_TO_REMOVE),
      withLatestFrom(this.store),
      switchMap(([action, state]) => {
        const params: PlateShapeParams = this.createPlateShapeParamsFromShape(
          state,
          (<BuildPlate>action).data as Segment[]
        );
        return this.plateService.applyBooleanOperation(params).pipe(
          map((v) => {
            switch (state.model.productConfiguration.step) {
              case Step.shape: {
                if (v.error) {
                  return {
                    action: new SetPlateShape(v),
                    conture: state.model.drawing.plate.shapeWithHoles,
                  };
                } else {
                  const shape =
                    !!v && v.data
                      ? !!v.data[0]
                        ? v.data[0]
                        : {
                            conture: [],
                            holes: [],
                            area: 0,
                            aabb: getEmptyAaabb(),
                          }
                      : state.model.drawing.plate.shapeWithHoles;
                  const perforationShape =
                    !!v && v.data
                      ? !!v.data.slice(1)
                        ? v.data.slice(1)
                        : []
                      : state.model.drawing.plate.perforationAreas.map(
                          (x) => x.shape
                        );
                  return {
                    action: new UpdateDrawing({
                      shape: shape,
                      perforationShapes: perforationShape,
                    }),
                    conture: shape.conture,
                  };
                }
              }
              case Step.import_pattern:
              case Step.pattern:
                if (v.error) {
                  return {
                    action: new SetPerforationArea(v),
                    conture: state.model.drawing.plate.shapeWithHoles,
                  };
                } else {
                  const shape =
                    v.data[0] || state.model.drawing.plate.shapeWithHoles;
                  const perforationShape =
                    v.data.slice(1) ||
                    state.model.drawing.plate.perforationAreas.map(
                      (x) => x.shape
                    );
                  return {
                    action: new UpdateDrawing({
                      shape: shape,
                      perforationShapes: perforationShape,
                    }),
                    conture: shape.conture,
                  };
                }
            }
          })
        );
      }),
      withLatestFrom(this.store),
      switchMap(([res, state]) => {
        switch (state.model.productConfiguration.step) {
          case Step.shape:
            if (
              (<BuildPlateMapping>res).conture.length > 0 &&
              state.model.drawing.plate.shapeWithHoles.conture.length === 0
            ) {
              return [res.action, new CallZoom()];
            } else {
              return [res.action];
            }
          case Step.import_pattern:
          case Step.pattern:
            if (state.model.drawing.plate.shapeWithHoles.conture.length === 0) {
              return [res.action, new CallZoom()];
            } else {
              return [res.action];
            }
        }
      })
    )
  );

  /*
  @Effect()
  generateZones = this.actions.pipe(
    ofType(SET_PLATE, UPDATE_DRAWING),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      if (state.model.drawing.plate.bendingLines.length > 0) {
        const thickenedBendingLines: Segment[] = [];
        for (const bendingLine of state.model.drawing.plate.bendingLines) {
          thickenedBendingLines.push({ begin: bendingLine.begin, end: bendingLine.end, type: SegmentType.line});
        }
        return this.plateService.generateZones(
          {bendingLines: thickenedBendingLines, plateShape: state.model.drawing.plate.shapeWithHoles}).pipe((map(ret => {
          if (ret.error) {
            return new SetError(ret.error);
          } else {
            return new SetZones(ret.data);
          }
        })));
      } else {
        return of(new SetZones([state.model.drawing.plate.shapeWithHoles]));
      }
    })
  );
*/

  resetTools = createEffect(
    () =>
      this.actions.pipe(
        ofType(SUBMIT_IMPORT, CANCEL_IMPORT),
        switchMap((action) => {
          this.toolProvider.resetImportTools();
          return of(null);
        })
      ),
    { dispatch: false }
  );

  // @Effect()
  updatePlate = createEffect(
    () =>
      this.actions.pipe(
        ofType(UPDATE_PLATE_SHAPE),
        withLatestFrom(this.store),
        switchMap(async ([action, state]) => {

          const plate = applySegmentsMove(state.model.drawing.plate);
          if (state.model.productConfiguration.step === Step.import_pattern) {
            const v = await lastValueFrom(this.plateService.updatePlateShape({
                shapesWithHoles:
                  plate.perforationAreas.map(
                    (x) => x.shape
                  ),
                boundary: null,
              }));
              if (v) {
                return new UpdateDrawing({
                  shape: plate.shapeWithHoles,
                  perforationShapes: v.data,
                });
              }
              return new UpdateDrawingFail({
                oldDrawing: (<UpdatePlateShape>action).payload,
                error: v.error,
              });
          }
          const shapeResponse = await lastValueFrom(this.plateService
            .updatePlateShape({
              shapesWithHoles: [plate.shapeWithHoles],
              boundary: [
                { conture: plate.boundary, holes: [] },
              ],
            }));
          if (shapeResponse.error) {
            return new UpdateDrawingFail({
              oldDrawing: (<UpdatePlateShape>action).payload,
              error: shapeResponse.error,
            });
          }

          const shape = shapeResponse.data[0];
          const perforationResponse = await lastValueFrom(this.plateService
            .updatePlateShape({
              shapesWithHoles:
                plate.perforationAreas.map(
                  (x) => x.shape
                ),
              boundary: [shape],
            }));
          if (perforationResponse.error) {
            return new UpdateDrawingFail({
              oldDrawing: (<UpdatePlateShape>action).payload,
              error: perforationResponse.error,
            });
          }
          return new UpdateDrawing({
            shape: shapeResponse.data[0],
            perforationShapes: perforationResponse.data,
          });
        })
      )
  );

  treatCorner = createEffect(() =>
    this.actions.pipe(
      ofType(TREAT_CORNER),
      withLatestFrom(this.store),
      switchMap(([action, state]) => {
        return zip(
          this.plateService.roundUpCorners({
            shapeWithHoles: [state.model.drawing.plate.shapeWithHoles],
            cornerParams: (<TreatCorner>action).payload.options,
            type: (<TreatCorner>action).payload.options.type,
            corners: (<TreatCorner>action).payload.nodes,
            perforationAreas: state.model.drawing.plate.perforationAreas.map(
              (pam) => pam.shape
            ),
          }),
          this.plateService.roundUpCorners({
            shapeWithHoles: state.model.drawing.plate.perforationAreas.map(
              (x) => x.shape
            ),
            cornerParams: (<TreatCorner>action).payload.options,
            type: (<TreatCorner>action).payload.options.type,
            corners: (<TreatCorner>action).payload.nodes,
            perforationAreas: state.model.drawing.plate.perforationAreas.map(
              (pam) => pam.shape
            ),
          })
        ).pipe(
          map(([shapeResponse, perforationResponse]) => {
            if (shapeResponse.error) {
              return new SetError(shapeResponse.error);
            } else if (perforationResponse.error) {
              return new SetError(perforationResponse.error);
            } else {
              return new UpdateDrawing({
                shape: shapeResponse.data[0],
                perforationShapes: perforationResponse.data,
              });
            }
          })
        );
      })
    )
  );

  treatSegment = createEffect(() =>
    this.actions.pipe(
      ofType(TREAT_SEGMENT_CORNERS),
      withLatestFrom(this.store),
      switchMap(([action, state]) => {
        const segment: Segment = (<TreatSegmentCorners>action).payload.segment;
        const options: RoundUpSelectedNodesParams = (<TreatSegmentCorners>(
          action
        )).payload.options;
        const beginNode = segment.begin;
        const endNode = segment.end;
        return of(
          new TreatCorner({
            nodes: [beginNode, endNode],
            options: options,
          })
        );
      })
    )
  );

  loadDxf = createEffect(() =>
    this.actions.pipe(
      ofType(LOAD_DXF),
      withLatestFrom(this.store),
      switchMap(([action, state]) => {
        return this.plateService.uploadDxfFile((<LoadDxf>action).payload).pipe(
          map((v) => {
            if (v.error) {
              return new SetError(v.error);
            } else {
              return new LoadDxfSuccess(v);
            }
          })
        );
      })
    )
  );

  calculateShapeFromPolylines = createEffect(() =>
    this.actions.pipe(
      ofType(CALCULATE_SHAPE_FROM_POLYLINES),
      withLatestFrom(this.store),
      switchMap(([action, state]) => {
        return this.plateService
          .calculateShapeFromPolylines(
            (<CalculateShapeFromPolylines>action).payload
          )
          .pipe(
            map((v) => {
              if (v.error) {
                return new SetError(v.error);
              } else {
                return new CalculateShapeFromPolylinesSuccess(v);
              }
            })
          );
      })
    )
  );

  rotatePolylines = createEffect(() =>
    this.actions.pipe(
      ofType(ROTATE_POLYLINE),
      withLatestFrom(this.store),
      switchMap(([action, state]) => {
        const drawing = state.model.drawing;
        const copy = {
          ...drawing,
          nodes: [...state.model.drawing.nodes],
          plate: {
            ...drawing.plate,
            shapeWithHoles: deepCopyShapeWithHoles(
              drawing.plate.shapeWithHoles
            ),
            perforationAreaShapes: deepCopyPerforationAreas(
              drawing.plate.perforationAreas
            ),
          },
        };
        const params: RotatePolylineParams = (<RotatePolyline>action).payload;
        rotatePolylineInPlace(params.polyline, params.rotation, params.point);
        return of(new UpdatePlateShape(copy));
      })
    )
  );

  createPerforationAreaFromShape = createEffect(() =>
    this.actions.pipe(
      ofType(CREATE_PERFORATION_AREAS_FROM_SHAPE),
      withLatestFrom(this.store.pipe(select(getPlate))),
      switchMap(([action, plate]: [CreatePerforationAreasFomShape, Plate]) => {
        return this.plateService
          .createPerforationAreasFromShape(
            plate.shapeWithHoles,
            plate.bendingLines,
            plate.perforationAreas.map((pa) => pa.shape),
            null,
            action.payload
          )
          .pipe(
            map((v) => {
              if (v.error === null) {
                return new SetError(v.error);
              } else {
                return new SetPerforationArea(v);
              }
            })
          );
      })
    )
  );

  createZonePerforationArea = createEffect(() =>
    this.actions.pipe(
      ofType(CREATE_PERFORATION_AREA_FROM_ZONE),
      withLatestFrom(this.store.pipe(select(getPlate))),
      switchMap(([action, plate]: [CreatePerforationAreaFromZone, Plate]) => {
        const payload: ZonePerforationAreaParams = action.payload;
        return this.plateService
          .createPerforationAreasFromShape(
            plate.shapeWithHoles,
            plate.bendingLines,
            plate.perforationAreas.map((pa) => pa.shape),
            payload.point,
            payload.margin
          )
          .pipe(
            map((v) => {
              if (v.error === null) {
                return new SetError(v.error);
              } else {
                return new SetPerforationArea(v);
              }
            })
          );
      })
    )
  );

  setPerforationAreasAfterFromZoneCreation = createEffect(() =>
    this.actions.pipe(
      ofType(CREATE_PERFORATION_AREA_FROM_ZONE_SUCCESS),
      withLatestFrom(this.store.pipe(select(getPlate))),
      switchMap(
        ([action, plate]: [CreatePerforationAreaFromZoneSuccess, Plate]) => {
          const perforationAreasInZone = plate.perforationAreas.filter(
            (pa: PerforationAreaModel) =>
              polylineContainsPoint(pa.shape.conture, action.payload.point)
          );
          const newPerforationAreas: ShapeWithHoles[] = plate.perforationAreas
            .filter((v) => !perforationAreasInZone.includes(v))
            .map((v) => v.shape);
          const newAreas: ShapeWithHoles[] = [
            ...newPerforationAreas,
            ...action.payload.shapes,
          ];
          return of(new SetPerforationArea({ data: newAreas, error: null }));
        }
      )
    )
  );

  requestRender = createEffect(
    () =>
      this.actions.pipe(
        ofType(REQUEST_RENDER),
        switchMap((action) => {
          this.view3dProvider.updateView();
          return of(action);
        })
      ),
    { dispatch: false }
  );

  callZoom = createEffect(
    () =>
      this.actions.pipe(
        ofType(CALL_ZOOM),
        switchMap((action) => {
          this.view3dProvider.zoomToFitPlate();
          return of(action);
        })
      ),
    { dispatch: false }
  );

  setBackGroundColor = createEffect(
    () =>
      this.actions.pipe(
        ofType(SET_BACKGROUND_COLOR),
        switchMap((action) => {
          this.view3dProvider.setBackgroundColor(
            (<SetBackgroundColor>action).payload
          );
          return of(action);
        })
      ),
    { dispatch: false }
  );

  createPlateShapeParamsFromShape(
    state: MevacoState,
    shape: Segment[]
  ): PlateShapeParams {
    const plateShapeParams: PlateShapeParams = {
      clip: shape,
      bendingLines: state.model.drawing.plate.bendingLines,
      boundary: state.model.drawing.plate.boundary,
      plateShape: [],
      perforationAreas: [],
      subjectType: "Plate",
      operation: "union",
      singleShape: true,
    };
    const shapeToSend: ShapeActionParams = initialShapeActionParams;
    shapeToSend.polylines = [];
    plateShapeParams.clip = shape;
    plateShapeParams.plateShape =
      state.model.drawing.plate.shapeWithHoles.conture.length > 0
        ? [state.model.drawing.plate.shapeWithHoles]
        : [];
    plateShapeParams.perforationAreas =
      state.model.drawing.plate.perforationAreas.length > 0
        ? state.model.drawing.plate.perforationAreas.map((x) => x.shape)
        : [];
    switch (state.model.productConfiguration.step) {
      case Step.shape:
        plateShapeParams.subjectType = "Plate";
        plateShapeParams.singleShape = true;
        break;
      case Step.import_pattern:
      case Step.pattern:
        plateShapeParams.subjectType = "PerforationArea";
        plateShapeParams.singleShape = false;
        break;
    }
    plateShapeParams.operation =
      state.model.drawing.plate.actionType === ActionType.ADD
        ? "union"
        : "difference";
    return plateShapeParams;
  }

  findPerforationAreasInZone(
    zone: ShapeWithHoles,
    perforationAreas: PerforationAreaModel[]
  ): PerforationAreaModel[] {
    const zoneAabb = zone.aabb ? zone.aabb : aabbOfPolyline(zone.conture);
    const perforationAreasInZone: PerforationAreaModel[] = [];
    for (const perforationArea of perforationAreas) {
      const shape = perforationArea.shape;
      const aabb = shape.aabb ? shape.aabb : aabbOfPolyline(shape.conture);
      if (isAabbInAabb(zoneAabb, aabb)) {
        perforationAreasInZone.push(perforationArea);
      }
    }
    return perforationAreasInZone;
  }
}
