export enum UnderPointerType {
  NODE,
  HELP_LINE,
  SEGMENT,
  GRID,
  MEASUREMENT,
  AUTOMATIC_MEASUREMENT,
  ANGLE_MEASUREMENT,
  MOUNTING,
  VIRTUAL_NODE,
  PERFORATOIN_HOLE,
  PERFORATION_AREA,
  CROSS_INTERSECTION,
  NONE,
  BENDING_LINE
}
