import {lengthVector2, subVectors2, Vector2} from './vector2';

// Column-Major
export interface Matrix3 {
    n11: number;
    n12: number;
    n13: number;
    n21: number;
    n22: number;
    n23: number;
    n31: number;
    n32: number;
    n33: number;
}

/*
xAxis.x, yAxis.x, origin.x,
xAxis.y, yAxis.y, origin.y,
      0,       0,             1

 */

export function identityMatrix3(): Matrix3 {
    return {
        n11: 1, n12: 0, n13: 0,
        n21: 0, n22: 1, n23: 0,
        n31: 0, n32: 0, n33: 1
    };
}

export function matrix3FromBasis(xAxis: Vector2, yAxis: Vector2, origin: Vector2): Matrix3 {
    return {
        n11: xAxis.x, n12: yAxis.x, n13: origin.x,
        n21: xAxis.y, n22: yAxis.y, n23: origin.y,
        n31: 0, n32: 0, n33: 1
    };
}

export function transformPosition2(m: Matrix3, v: Vector2): Vector2 {
    return {
        x: m.n11 * v.x + m.n12 * v.y + m.n13,
        y: m.n21 * v.x + m.n22 * v.y + m.n23,
    };
}

export function transformDirection2(m: Matrix3, v: Vector2): Vector2 {
    return {
        x: m.n11 * v.x + m.n12 * v.y,
        y: m.n21 * v.x + m.n22 * v.y,
    };
}

export function getInverse3(m: Matrix3): Matrix3 {
    var
        t11: number = m.n33 * m.n22 - m.n32 * m.n23,
        t12: number = m.n32 * m.n13 - m.n33 * m.n12,
        t13: number = m.n23 * m.n12 - m.n22 * m.n13,

        det = m.n11 * t11 + m.n21 * t12 + m.n31 * t13;
    if (det === 0) {
        throw new Error("can't invert Matrix3, determinant is 0");
    }

    var detInv = 1 / det;
    return {
        n11: t11 * detInv,
        n12: t12 * detInv,
        n13: t13 * detInv,
        n21: (m.n31 * m.n23 - m.n33 * m.n21) * detInv,
        n22: (m.n33 * m.n11 - m.n31 * m.n13) * detInv,
        n23: (m.n21 * m.n13 - m.n23 * m.n11) * detInv,
        n31: (m.n32 * m.n21 - m.n31 * m.n22) * detInv,
        n32: (m.n31 * m.n12 - m.n32 * m.n11) * detInv,
        n33: (m.n22 * m.n11 - m.n21 * m.n12) * detInv
    };

}

export function getRotation2DMatrix(rotation: number): Matrix3 {
    const cosTheta = Math.cos(rotation);
    const sinTheta = Math.sin(rotation);

    return {
        n11: cosTheta, n12: -sinTheta, n13: 0,
        n21: sinTheta, n22: cosTheta,  n23: 0,
        n31: 0,        n32: 0,         n33: 1
    };

}

export function getTranslationMatrix(v: Vector2): Matrix3 {
    return {
        n11: 1, n12: 0, n13: v.x,
        n21: 0, n22: 1, n23: v.y,
        n31: 0, n32: 0, n33: 1
    };
}

export function multiplyMatrices(m1: Matrix3, m2: Matrix3): Matrix3 {
    return {
        n11: m1.n11 * m2.n11 + m1.n12 * m2.n21 + m1.n13 * m2.n31,
        n12: m1.n11 * m2.n12 + m1.n12 * m2.n22 + m1.n13 * m2.n32,
        n13: m1.n11 * m2.n13 + m1.n12 * m2.n23 + m1.n13 * m2.n33,

        n21: m1.n21 * m2.n11 + m1.n22 * m2.n21 + m1.n23 * m2.n31,
        n22: m1.n21 * m2.n12 + m1.n22 * m2.n22 + m1.n23 * m2.n32,
        n23: m1.n21 * m2.n13 + m1.n22 * m2.n23 + m1.n23 * m2.n33,

        n31: m1.n31 * m2.n11 + m1.n32 * m2.n21 + m1.n33 * m2.n31,
        n32: m1.n31 * m2.n12 + m1.n32 * m2.n22 + m1.n33 * m2.n32,
        n33: m1.n31 * m2.n13 + m1.n32 * m2.n23 + m1.n33 * m2.n33
    };
}

export function getMatrixToLineLocalSpace(l0:Vector2, l1:Vector2):Matrix3 {
    var v = subVectors2(l1, l0 );
    var l = lengthVector2(v);
    // secure it outside if needed (I want this function to be as fast as possible)
    // if (l == 0)
    // {
    //     return null;
    // }

    var dx = v.x / l;
    var dy = v.y / l;

    //inverse Rotation matrix
    //  dx | dy
    // -dy | dx
    return {
        n11: dx, n12:  dy, n13: -dx * l0.x - dy * l0.y,
        n21:-dy, n22:  dx, n23: dy * l0.x - dx * l0.y,
        n31:  0, n32:   0, n33:    1
    };
}