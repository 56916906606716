
export interface SpacingFormat {
  p1: number,
  p2: number
}

export interface PerforationFormat {
  areaOpen: number,
  p1: number,
  p2: number
}

export function stringToFormat(format: string): PerforationFormat {
  if (format === '') {
    return {
      p1: 0,
      p2: 0,
      areaOpen: 0
    }
  }
  const split = format.split(' x ');
  return {
    p1: Number(split[0]),
    p2: split.length > 1 ? Number(split[1]) : 0,
    areaOpen: 0,
  }
}

export function stringToSpacingFormat(format: string): SpacingFormat {
  if (format === '') {
    return {
      p1: 0,
      p2: 0,
    }
  }
  const split = format.split(' x ');
  return {
    p1: Number(split[0]),
    p2: split.length > 1 ? Number(split[1]) : 0,
  }
}

export interface HoleSize {
  width: number;
  length: number;
}
export function stringToSize(sizeString: string): HoleSize {
  const split = sizeString.split(' x ');
  return {
    width: Number(split[0]),
    length: split.length > 1 ? Number(split[1]) : 0
  };
}

export function sizeToString(size: HoleSize) {
  if (!size) {
    return '';
  }
  return size.length !== 0 ? size.width.toString() + ' x ' + size.length.toString() : size.width.toString();
}
