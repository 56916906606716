import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Subpanel, SubpanelCurrentState, Subpanels } from '../../model/right-panel.model';
import {anyActionOfType, forEachRuleAction, RuleAction, RuleActions} from '../../model/rule-action.model';
import { TranslationProvider } from '../../providers/translation.provider';
import {
  getConfiguratorSubpanelState,
  getMessageVisualizerSubpanelState,
  getRuleActions,
  MevacoState,
  SetSubpanelState
} from '../../store';

interface RuleActionWithPosition extends RuleAction {
  position: number;
}
import { MessageVisualizerStatus } from '../../model/message-visualizer-status.enum';

@Component({
  selector: 'app-message-visualizer',
  templateUrl: './message-visualizer.component.html',
  styleUrls: ['./message-visualizer.component.css']
})
export class MessageVisualizerComponent implements OnInit {

  subscriptions: Subscription[] = [];
  // public overallErrors: RuleAction[] = [];
  // public displayErrors: RuleAction[] = [];
  private errorsClasses: string[];

  public errorMessages: RuleActionWithPosition[] = [];
  public warningMessages: RuleActionWithPosition[] = [];
  public hintMessages: RuleActionWithPosition[] = [];

  public messageVisualizerStatus = 'NONE';
  public messageVisualizerOpen = false;

  public SubpanelCurrentState = SubpanelCurrentState;
  public messageVisualizerSubpanelState: Subpanel;
  public configuratorSubpanelState: Subpanel;

  constructor(
    private store: Store<MevacoState>,
    private translationProvider: TranslationProvider
    ) { }

  ngOnInit() {

    this.subscriptions.push(this.store.pipe(select(getMessageVisualizerSubpanelState)).subscribe(messageVisualizerSubpanel => {
      this.messageVisualizerSubpanelState = messageVisualizerSubpanel;
    }));

    this.subscriptions.push(this.store.pipe(select(getConfiguratorSubpanelState)).subscribe(configuratorSubpanel => {
      this.configuratorSubpanelState = configuratorSubpanel;
    }));

    this.subscriptions.push(this.store.pipe(select(getRuleActions)).subscribe((rules: RuleActions) => {

        const errMess: RuleActionWithPosition[] = [];
        const warnMess: RuleActionWithPosition[] = [];
        const hintMess: RuleActionWithPosition[] = [];

      forEachRuleAction(rules, (rule: RuleAction, posNumber: number) => {
        const fieldName = (rule.fieldName || '').toUpperCase();
        const ruleWithPosition = {
          ...rule,
          position: fieldName === 'OVERALL' ? -1 : posNumber // 0 means OVERALL
        };
        if (rule.type.toUpperCase() === 'ERROR') {
          if (fieldName === 'OVERALL' && errMess.find(err => err.fieldName === rule.fieldName)) { return; }
          errMess.push(ruleWithPosition);
        } else if (rule.type.toUpperCase() === 'WARNING') {
          if (fieldName === 'OVERALL' && warnMess.find(err => err.fieldName === rule.fieldName)) { return; }
          warnMess.push(ruleWithPosition);
        }  else if (rule.type.toUpperCase() === 'HINT') {
          if (fieldName === 'OVERALL' && hintMess.find(err => err.fieldName === rule.fieldName)) { return; }
          hintMess.push(ruleWithPosition);
        }
      });
      this.errorMessages = [...errMess];
      this.warningMessages = [...warnMess];
      this.hintMessages = [...hintMess];

      if (this.errorMessages.length === 0 && this.warningMessages.length === 0 && this.hintMessages.length === 0 && this.messageVisualizerSubpanelState.state === SubpanelCurrentState.OPENED) {
        this.store.dispatch(new SetSubpanelState({name: Subpanels.MESSAGE_VISUALIZER_SUBPANEL, state: SubpanelCurrentState.CLOSED}));
      }

      if (anyActionOfType( rules, MessageVisualizerStatus.ERROR)) {
        this.messageVisualizerStatus = MessageVisualizerStatus.ERROR;
        return;
      } else if (anyActionOfType( rules, MessageVisualizerStatus.WARNING)) {
        this.messageVisualizerStatus = MessageVisualizerStatus.WARNING;
        return;
      } else if (anyActionOfType( rules, MessageVisualizerStatus.HINT)) {
        this.messageVisualizerStatus = MessageVisualizerStatus.HINT;
        return;
      } else {
        this.messageVisualizerStatus = MessageVisualizerStatus.NONE;
      }

    }));
  }

  toggleMessageVisualizer() {
    if (this.messageVisualizerSubpanelState.state === SubpanelCurrentState.OPENED) {
      this.store.dispatch(new SetSubpanelState({name: Subpanels.MESSAGE_VISUALIZER_SUBPANEL, state: SubpanelCurrentState.CLOSED}));
    } else {
      this.store.dispatch(new SetSubpanelState({name: Subpanels.MESSAGE_VISUALIZER_SUBPANEL, state: SubpanelCurrentState.OPENED}));
    }
  }

  // onErrorsClick() {
  //   if (this.errorsClasses.includes('expand')) this.errorsClasses.splice(2, 1, 'hide');
  //   else if (this.errorsClasses.includes('hide')) this.errorsClasses.splice(2, 1, 'expand');
  //   else this.errorsClasses.push('expand');

  //   const tempArr = this.overallErrors;
  //   const seen = new Set();
  //   const uRules = tempArr.filter((el: RuleAction) => {
  //     const duplicate = seen.has(el.ruleId);
  //     seen.add(el.ruleId);
  //     return !duplicate;
  //   });
  //   this.displayErrors = uRules;
  // }


  // findMostFrequest(arr) {
  //   let compare = "";
  //   let mostFreq = "";

  //   arr.reduce((acc, val) => {
  //     if(val in acc) acc[val]++;
  //     else acc[val] = 1;
  //     if(acc[val] > compare){
  //        compare = acc[val]
  //        mostFreq = val;
  //     } return acc;}, {})
  //   return mostFreq;
  // }

  translate(text: string, module: string = 'designer') {
    return this.translationProvider.translate(text, module);
  }

  sortMessages(messArr: RuleActionWithPosition[]) {
    return messArr.sort(function(a, b) {
      if ( a.position < b.position ) {
        return -1;
      }
      if ( a.position > b.position ) {
        return 1;
      }
      return 0;
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }


}
