import {Aabb2, aabbCenter} from "../models";
import {distanceVector2, Vector2} from "./vector2";
import {LineSegment2} from "./line-segment2";
import {BoundingCircle} from "./bounding-circle";

export function addPointToAabb2(aabb:Aabb2, point: Vector2):void {
	aabb.min.x = Math.min(point.x, aabb.min.x);
	aabb.min.y = Math.min(point.y, aabb.min.y);
	aabb.max.x = Math.max(point.x, aabb.max.x);
	aabb.max.y = Math.max(point.y, aabb.max.y);
}

//TODO not tested
export function aabbClipSegment(aabb:Aabb2, linesegment:LineSegment2):LineSegment2{
	const result:LineSegment2 = {
        p1:{...linesegment.p1},
        p2:{...linesegment.p2}
	};
    const left = result.p1.x < result.p2.x ? result.p1 : result.p2;
    const right = result.p1.x < result.p2.x ? result.p2 : result.p1;
    if(left.x < aabb.min.x && right.x >= aabb.min.x){
    	const t = (aabb.min.x - left.x) / (right.x - left.x);
        left.x = left.x + (right.x - left.x) * t;
        left.y = left.y + (right.y - left.y) * t;
	}

    if(left.x < aabb.max.x && right.x >= aabb.max.x){
        const t = (aabb.max.x - left.x) / (right.x - left.x);
        right.x = left.x + (right.x - left.x) * t;
        right.y = left.y + (right.y - left.y) * t;
    }

    const bottom = result.p1.y < result.p2.y ? result.p1 : result.p2;
    const top = result.p1.y < result.p2.y ? result.p2 : result.p1;
    if(bottom.y < aabb.min.y && top.y >= aabb.min.y){
        const t = (aabb.min.y - bottom.y) / (top.y - bottom.y);
        bottom.x = bottom.x + (top.x - bottom.x) * t;
        bottom.y = bottom.y + (top.y - bottom.y) * t;
    }

    if(bottom.y < aabb.max.y && top.y >= aabb.max.y){
        const t = (aabb.max.y - bottom.y) / (top.y - bottom.y);
        top.x = bottom.x + (top.x - bottom.x) * t;
        top.y = bottom.y + (top.y - bottom.y) * t;
    }

    return result;
}

export function getBoundingCircleFromAabb(aabb:Aabb2):BoundingCircle{
    const orgin = aabbCenter(aabb);
    return {
        origin:orgin,
        radius: distanceVector2(orgin, aabb.min)
    }
}