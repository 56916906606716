<div class="effects-editor-content-container">
  <h4 class="effects-editor-header">
    <span class="effects-editor-header-text">{{
      translate("Effects", "designer")
    }}</span>
  </h4>

  <div class="effects-editor-body">
    <div class="effects-dropdowns">
      <dropdown
        [model]="effectsDropdown"
        [tooltipConfig]="{
          fieldName: 'effects.effectsDropdown',
          helpText: 'Effects Tooltip'
        }"
      >
      </dropdown>
    </div>

    <div *ngFor="let param of selectedEffect?.savedParameters; let i = index">
      <div [ngSwitch]="param.type">
        <div class="effects-sliders">
          <div *ngSwitchCase="'slider'" class="effect-slider__container">
            <label class="effect-slider__label">
              {{ translate(param.name) }}
              <input
                type="text"
                type="number"
                [(value)]="param.currentMax"
                (change)="editSliderEffectFromInput($event, i)"
                [min]="+param.min"
                [max]="+param.max"
              />
            </label>
            <ngx-slider
              class="custom-ngx-slider one-side-slider"
              [(value)]="param.currentMax"
              (userChangeEnd)="editSliderEffect($event, i)"
              [options]="getOptionsForSlider(param.min, param.max)"
            >
            </ngx-slider>
          </div>

          <div *ngSwitchCase="'toggle'" class="effect-slider__container">
            <label class="effect-slider__label">
              {{ translate(param.name) }}
              <mat-slide-toggle
                [checked]="param.currentValue"
                (change)="editToggleEffect($event.checked, i)"
              >
              </mat-slide-toggle>
            </label>
          </div>

          <div *ngSwitchCase="'slider_range'" class="effect-slider__container">
            <label class="effect-slider__label">
              {{ param.name }}
              <div class="range-slider__inputs">
                <input
                type="text"
                type="number"
                [(value)]="param.currentMin"
                (change)="setValueFromInput($event, 'min', i)"
                [min]="+param.min"
                [max]="+param.max"
              />
              <div class="range-slider__pause">-</div>
              <input
                type="text"
                type="number"
                [(value)]="param.currentMax"
                (change)="setValueFromInput($event, 'max', i)"
                [min]="+param.min"
                [max]="+param.max"
              />
              </div>
            </label>
            <div class="range-slider__container">
              <ngx-slider
                class="custom-ngx-slider"
                [(value)]="param.currentMin"
                [(highValue)]="param.currentMax"
                (userChangeEnd)="editRangeSliderEffect($event, i)"
                [options]="getOptionsForSlider(param.min, param.max)"
              >
              </ngx-slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<span class="effects-editor-button" (click)="toggleEffectsSubpanel()">
  <img class="effects-editor-arrow" src="assets/down-arrow.svg" alt="arrow" />
</span>
