import { Component, OnInit } from "@angular/core";
import { Action, Store, select } from "@ngrx/store";
import { Observable, Subscription, combineLatest } from "rxjs";
import { debounceTime, delay, map, startWith } from "rxjs/operators";
import {
  Mounting,
  MountingPositioningType,
  mountingSizeToString,
} from "../../model/mounting.model";
import { SceneProvider } from "../../providers/scene.provider";
import { ToolProvider } from "../../providers/tool.provider";
import { TranslationProvider } from "../../providers/translation.provider";
import {
  SetMounting,
  SetMountingForm,
} from "../../store/actions/mountings.actions";
import {
  MevacoState,
  getMaterial,
  getMaterialCode,
  getMountings,
  getMountingsModel,
  getSelectedMountingForm,
  getSelectedMountingPositioning,
} from "../../store/reducers";
import { AssistedMountingTool } from "../../tools/drawingTools/mountings/assisted-mounting.tool";
import { MountingTool } from "../../tools/drawingTools/mountings/mounting.tool";
import { AttachmentImportTool } from "../../tools/editTools/import/attachment-import.tool";
import { Tool } from "../../tools/tool.interface";
import { Dropdown, DropdownOption } from "../dropdown/dropdown.component";

@Component({
  selector: "attachment-config",
  templateUrl: "./attachment-config.component.html",
  styleUrls: ["./attachment-config.component.css"],
})
export class AttachmentConfigComponent implements OnInit {
  private positioningSub: Observable<MountingPositioningType>;
  public formDropdown: Dropdown;
  public sizeDropdown: Dropdown;
  public mountingTool: MountingTool;
  public assistedMountingTool: AssistedMountingTool;
  private mountingPositioningTypes: MountingPositioningType;
  private mountingsSub: Subscription;
  private mountings: Mounting[];
  public pi = Math.PI;
  public round = Math.round;
  public rotationObservable: Observable<number>;
  public setRotation: (v: string) => void;
  public importMountingTool: AttachmentImportTool;
  public activeTool: Observable<Tool>;

  // public formTypeMenuWidth: number;
  // public sizeMenuWidth: number;
  //
  // @HostListener('window:resize', ['$event']) onResize(event) {
  //   if(event.target.innerWidth >= 900 && event.target.innerWidth < 1424) {
  //     this.formTypeMenuWidth = 140;
  //     this.sizeMenuWidth = 140;
  //   } else if(event.target.innerWidth >= 1424) {
  //     this.formTypeMenuWidth = 240;
  //     this.sizeMenuWidth = 240;
  //   }
  // }

  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private translationProvider: TranslationProvider,
    public toolProvider: ToolProvider
  ) {
    this.mountingTool = toolProvider.mountingTool;
    this.assistedMountingTool = toolProvider.assistedMountingTool;
    this.importMountingTool = toolProvider.attachmentImportTool;
  }

  ngOnInit() {
    // if(window.innerWidth >= 900 && window.innerWidth < 1424) {
    //   this.formTypeMenuWidth = 140;
    //   this.sizeMenuWidth = 140;
    // } else if(window.innerWidth >= 1424) {
    //   this.formTypeMenuWidth = 240;
    //   this.sizeMenuWidth = 240;
    // }

    this.activeTool = this.toolProvider
      .getToolObservable()
      .pipe(startWith(null), delay(0));
    this.activeTool.subscribe((v) => {
      if (v === this.mountingTool) {
        this.rotationObservable = this.mountingTool.rotation;
        this.setRotation = this.mountingTool.setRotation.bind(
          this.mountingTool
        );
      } else if (v === this.assistedMountingTool) {
        this.rotationObservable = this.assistedMountingTool.rotation;
        this.setRotation = this.assistedMountingTool.setRotation.bind(
          this.assistedMountingTool
        );
      } else if (v === this.importMountingTool) {
        this.rotationObservable = this.importMountingTool.rotation;
        this.setRotation = this.importMountingTool.setRotation.bind(
          this.importMountingTool
        );
      } else {
        this.rotationObservable = null;
      }
    });
    this.mountingsSub = this.store.pipe(select(getMountings)).subscribe((v) => {
      this.mountings = v;
    });
    this.positioningSub = this.store.pipe(
      select(getSelectedMountingPositioning)
    );
    this.formDropdown = this.createDropDown(
      this.translate("MountingFormType", "designer"),
      this.store.pipe(select(getSelectedMountingForm)) as Observable<string>,
      this.getMountingTypeOptions(),
      (value) => new SetMountingForm(value)
    );
    this.sizeDropdown = this.createDropDown(
      this.translate("MountingSize", "designer"),
      this.store.pipe(
        select(getMountingsModel),
        map((v) => {
          if (v && v.mounting.id !== null) {
            return v.mounting.id.toString();
          } else {
            return null;
          }
        })
      ) as Observable<string>,
      this.getSizeOptions(),
      (value) => {
        const id = this.mountings.findIndex((v) => v.id === Number(value));
        return new SetMounting(this.mountings[id]);
      }
    );
  }

  createDropDown(
    name: string,
    value: Observable<string>,
    options: Observable<DropdownOption[]>,
    actionFactory: (string) => Action
  ) {
    return new Dropdown(name, options, value, (value) =>
      this.store.dispatch(actionFactory(value))
    );
  }

  getMountingTypeOptions(): Observable<DropdownOption[]> {
    return combineLatest([
      this.store.pipe(select(getMountingsModel)),
      this.store.pipe(select(getMaterialCode)),
    ]).pipe(
      debounceTime(1),
      map(([mountingsModel, materialCode]) => {
        const mountingTypes: string[] = Array.from(
          mountingsModel.verticesMap.keys()
        );
        return mountingTypes.map(
          (v) => new DropdownOption("text", this.translate(v), true, v)
        );
      })
    );
  }

  getSizeOptions(): Observable<DropdownOption[]> {
    return combineLatest([
      this.store.pipe(select(getMountings)),
      this.store.pipe(select(getMaterial)),
      this.store.pipe(select(getSelectedMountingForm)),
    ]).pipe(
      debounceTime(1),
      map(([mountings, material, form]) => {
        const ret: Mounting[] = [];
        if (mountings) {
          for (const m of mountings) {
            if (m.form === form) {
              ret.push(m);
            }
          }
        }
        ret.sort((a, b) => {
          const aL = a.length !== 0 ? a.length : a.width;
          const aW = a.width;
          const bL = b.length !== 0 ? b.length : b.width;
          const bW = b.width;
          if (aW > bW) {
            return 1;
          } else if (aW < bW) {
            return -1;
          } else {
            if (aL > bL) {
              return 1;
            } else {
              return -1;
            }
          }
        });
        const options: DropdownOption[] = [];
        if (!!material.materialcodeLomoe) {
          for (const m of ret) {
            if (m.materialCode === material.materialcodeLomoe) {
              const sizeString = mountingSizeToString(m);
              const index = options.findIndex((v) => v.data === sizeString);
              if (index === -1) {
                const possible =
                  material.materialThickness > m.thicknessMin &&
                  material.materialThickness < m.thicknessMax;
                options.push(
                  new DropdownOption(
                    "text",
                    sizeString,
                    possible,
                    m.id.toString()
                  )
                );
              }
            }
          }
        }
        for (const m of ret) {
          const sizeString = mountingSizeToString(m);
          const index = options.findIndex((v) => v.data === sizeString);
          if (index === -1) {
            options.push(
              new DropdownOption("text", sizeString, false, m.id.toString())
            );
          }
        }
        return options;
      })
    );
  }

  isValueCorrect(value: any) {
    const val: number = Number(value);
    if (!isNaN(val) && val <= 50 && val >= 0) {
      this.assistedMountingTool.setNumberOfHoles(val.toString());
    }
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}
