import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslationProvider } from '../../providers/translation.provider';
import {TutorialsService} from "../../services/tutorials.service";

@Component({
  selector: 'app-tutorial-modal',
  templateUrl: './tutorial-modal.component.html',
  styleUrls: ['./tutorial-modal.component.css']
})
export class TutorialModalComponent implements OnInit {

  @Output() closeModal = new EventEmitter<{isClose: boolean}>();



  constructor(
    private translationProvider: TranslationProvider,
    private tutorialsService:TutorialsService
  ) { }

  ngOnInit() {
  }

  onCloseModal() {
    this.closeModal.emit({isClose: true});
  }

  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }

  async  openTutorial() {

    await this.tutorialsService.reportTutorialRedirect();
    const link = this.translationProvider.translate('tutorials_link', 'designer');
    window.open(link, '_blank');
    this.closeModal.emit({isClose: true});
  }
}
