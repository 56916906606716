<div class="header">
  <div>
    <h2 class="header-title">{{translate("Your Positions", 'designer')}}:</h2>
    <!-- <h6>{{ (userInfo | async)?.id }}</h6>
    <h6>{{ (userInfo | async)?.name }}</h6> -->
  </div>
  <div class="switch-views-btns">
    <span class="switch-view-btn" #list *ngIf="!isList" (click)="isList = true">{{translate("Switch to list view", 'designer')}}</span>
    <span class="switch-view-btn" #th *ngIf="isList" (click)="isList = false">{{translate("Switch to default view", 'designer')}}</span>
  </div>
</div>
<div class="positions-tools">
<div class="positions-tools-container">

    <div class="tutorial-modal" [hidden]="!isTutorialModal">
      <button type="button" class="close close-tutorial" aria-label="Close" (click)="showTutorialModal()">
        <span aria-hidden="true">&times;</span>
      </button>
      <img class="icon-help" src="assets/Icon_help.svg">
      <label class="help">{{translate("help question", 'designer')}}</label>
      <!--  Do you need help?-->
      <label class="help-desc">{{translate("StepByStepTutorialInfo", 'designer')}}</label>
      <!--  You can always watch our tutorial explaining the designer step-by-step.-->
      <button type="button" class="btn btn-outline-dark tutorial-buttons" style="margin-right: 38px" (click)="showTutorialModal()">
        <label>{{translate("Start tutorial', 'designer'")}}</label>
      </button>
      <button type="button"
      class="btn tutorial-buttons"
        (click)="showTutorialModal()">
        <label>{{translate("Watch shortcuts", 'designer')}}</label>
      </button>
    </div>


  <!--<div class="ui-grid-col-3 enable-notes">-->
  <mat-checkbox class="base-checkbox" [disabled]="disableNotesOptionObservable|async" id="enableNotes" (change)="toggleNotes($event)" [checked]="enableNotesObservable | async">{{translate("Enable notes", 'designer')}}</mat-checkbox>
  <!--&lt;!&ndash; <div class="checkbox">-->
  <!--<input type="checkbox" name="name" value="value" (change)="endableNotes($event)">{{translate("Enable Notes")}}-->
  <!--<br>-->
  <!--</div> &ndash;&gt;-->
  <!--</div>-->
  <!-- <button *ngIf="(positions | async).length > 0" class="downloadDxfBtn" (click)="downloadDxfs()">
    {{translate("Download Dxfs", 'designer')}}
  </button> -->

  <!-- <div class="duplicateConfigDiv">
    <input #configId>
    <button class="duplicateConfigBtn" (click)="duplicateConfig(configId.value)">
      {{translate("DuplicateConfig", 'designer')}}
    </button>
  </div> -->
</div>

</div>

<ng-container *ngIf="!(hideList | async)">
  <div class="positions-scrollable-container">

    <div class="positions-cards-container" *ngIf="!isList">
        <div class="add-item-wrapper">
          <div class="add-item" (click)="goToTemplates()">
            <div class="add-content">
              <div class="add-icon-wrapper">
                <i class="material-icons">
                  add
                </i>
              </div>
              <span class="add-icon-label">{{translate("Add Position", 'designer')}}</span>
            </div>
          </div>
        </div>
      <div class="position-item" *ngFor="let position of (positions | async)">
        <app-positions-item [model]="position"></app-positions-item>
      </div>

      <div *ngIf="(isMevacoUser|async) || (isTrustedOrganizationUnit|async) || (isDxfAllowedOrganizationUnit|async)" class="positions-cards-footer">
        <div class="positions-cards-footer-dxf" *ngIf="((positions | async).length > 0) && ((isMevacoUser|async) || (isDxfAllowedOrganizationUnit|async))">
          <button class="btn btn-primary" (click)="downloadDxfs()">
            {{translate("Download Dxfs", 'designer')}}
          </button>
          <button class="btn btn-primary" *ngIf="importId | async as impId" (click)="downloadImportDxf(impId)">
            {{translate("Download Imported Dxf", 'designer')}}
          </button>
        </div>
        <div class="positions-cards-input-wrapper" *ngIf="(isMevacoUser|async) || (isTrustedOrganizationUnit|async)">
          <input class="positions-cards-input" #configId>
          <button class="btn btn-primary positions-cards-btn" (click)="duplicateConfig(configId.value)">
            {{translate("DuplicateConfig", 'designer')}}
          </button>
        </div>
      </div>
  </div>

  <div *ngIf="isList" class="positions-table-container">

    <div class="positions-table">

    <div class="table-header">

      <div class="table-header-cells">
        <div class="table-header-cell w-13-p">{{translate("Preview")}}
        </div>
        <div class="table-header-cell w-12-p">{{translate("Pieces")}}
        </div>
        <div class="table-header-cell w-11-p">{{translate("a")}}
        </div>
        <div class="table-header-cell w-11-p">{{translate("b")}}
        </div>
        <div class="table-header-cell w-11-p">{{translate("Area")}}
        </div>
        <div class="table-header-cell w-11-p">{{translate("tolerance Width")}}
        </div>
        <div class="table-header-cell w-11-p">{{translate("tolerance Length")}}
        </div>
        <div class="table-header-cell w-21-p">{{translate("Note")}}
        </div>
      </div>

    </div>

    <div *ngFor="let position of (positions | async)">
      <div>
        <app-positions-item-list [model]="position" [index]="position?.position"></app-positions-item-list>
      </div>
    </div>

  </div>

    <div *ngIf="(isMevacoUser|async) || (isDxfAllowedOrganizationUnit|async)" class="positions-table-footer">
      <button
      *ngIf="(positions | async).length > 0"
      class="btn btn-primary" (click)="downloadDxfs()">
      {{translate("Download Dxfs", 'designer')}}
      </button>
      <ng-container *ngIf="(isMevacoUser|async)">
        <button class="btn btn-primary" *ngIf="(importId | async) as impId" (click)="downloadImportDxf(impId)">
          {{translate("Download Imported Dxf", 'designer')}}
        </button>
      </ng-container>
    </div>

  </div>

  <div class="box-gradient"></div>
  </div>
</ng-container>

<ng-template #closeModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-small-title">{{translate("confirmationQuestion", 'designer')}}?</h4>
    <!--   Are you sure?-->
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close(true)">{{translate("Yes")}} </button>

    <button type="button" class="btn btn-outline-dark" (click)="modal.close(false)">{{translate("No")}} </button>
  </div>
</ng-template>



