import { PointerCollider } from "webcad/collision";
import { Pointer } from "webcad/collision/pointer";
import { PointerIntersection } from "webcad/collision/pointer-intersection";
import {
  addVectors2,
  getRightPerpendicularVector2,
  multiplyVector2byScalar,
  normalizeVector2,
  sqrDistanceVector2,
  subVectors2,
  Vector2,
} from "webcad/math";
import { projectPointOnSegment, Segment, SegmentType } from "webcad/models";
import { BendingLine } from "../../model/bending-line.model";
import { UnderPointerType } from "../../model/pointer-state.model";
import { ColliderPriority } from "./collider-priorities";
import { CursorType } from "./cursor.type";
import { MevacoCollider, SnappingContext } from "./mevaco.collider";

export class BendingLineCollider implements PointerCollider, MevacoCollider {
  priority = ColliderPriority.BENDING_LINE;
  objectType: UnderPointerType;
  public cursorType: CursorType = CursorType.envelope;
  name = "BendingLineCollider";

  public get object(): any {
    return this.bendingLine;
  }

  constructor(
    public bendingLine: BendingLine,
    protected snappingModelRef: SnappingContext
  ) {
    this.objectType = UnderPointerType.BENDING_LINE;
  }

  getIntersection(pointer: Pointer, epsilon: number): PointerIntersection {
    const dir = normalizeVector2(
      subVectors2(this.bendingLine.end, this.bendingLine.begin)
    );
    const ossbOffset = multiplyVector2byScalar(
      getRightPerpendicularVector2(dir),
      this.bendingLine.bentParams.ossb -
        this.bendingLine.bentParams.bendAllowance / 2
    );

    const segments: Segment[] = [
      {
        type: SegmentType.line,
        begin: this.bendingLine.begin,
        end: this.bendingLine.end,
      },
      {
        type: SegmentType.line,
        begin: addVectors2(this.bendingLine.begin, ossbOffset),
        end: addVectors2(this.bendingLine.end, ossbOffset),
      },
      {
        type: SegmentType.line,
        begin: subVectors2(this.bendingLine.begin, ossbOffset),
        end: subVectors2(this.bendingLine.end, ossbOffset),
      },
    ];
    const point = pointer.onWorkingPlane;

    let pps: Vector2[] = segments.map((segment) =>
      projectPointOnSegment(point, segment)
    );

    let pp: Vector2 = null;
    let min = Number.MAX_VALUE;
    for (const p of pps) {
      if (!!p) {
        const dist = sqrDistanceVector2(point, p);
        if (dist <= epsilon * epsilon && dist < min) {
          min = dist;
          pp = p;
        }
      }
    }

    if (pp) {
      return {
        position: { x: pp.x, y: pp.y, z: 0 },
        collider: this,
      };
    }
  }

  compare(other: PointerCollider): boolean {
    return (
      other instanceof BendingLineCollider &&
      this.bendingLine === other.bendingLine
    );
  }
}
