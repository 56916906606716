<div class="page">
    <div class="p-header">
        <img src="../../../assets/mevaco-logo-2.svg" />
    </div>
    <div class="p-content">
        <div class="selections">
            <ng-container *ngFor="let selection of selections; index as i">
                <div [class.selected]="i === selectedContent"  (click)="setSelectedContent(i)" class="selection">
                    {{selection}}
                </div>
            </ng-container>
        </div>
        <div class="selection-content">
            <ng-container *ngComponentOutlet="selectedComponent"></ng-container>
        </div>
    </div>
</div>