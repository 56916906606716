<div class="submit-button-box">
  <div
    class="tooltip-indicator"
    placement="left"
    [ngbTooltip] = "((incompleteReason | async) !== null) ? translate(incompleteReason | async, 'designer') : null"
    tooltipClass="custom-tooltip"
    container="body"

  >
    <ng-container *ngIf="configurationStatus|async as confStatus">
      <button type="button" class="btn btn-cart" [ngClass]="{save: confStatus === 'incomplete'}"
          [disabled]=" confStatus === 'updating'"
          (click)="pressButton()"
          >
          {{ confStatus === 'updating' ? translate("Validating") : confStatus === 'incomplete' ? translate("Save Incomplete") : translate("Cart") }}
          <div *ngIf="confStatus === 'updating'" class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
      </button>
    </ng-container>
  </div>
</div>



<ng-template let-modal #warningModal>
  <div class="dialog-modal-header dialog-modal-header--small">
    <div class="dialog-modal-title-container">
      <div class="dialog-modal-title-content">
        <h4 class="dialog-modal-title dialog-modal-title--small" id="modal-basic-title">
          <img src="assets/tooltip-warning-icon.svg" alt="warning-icon" class="dialog-modal-title-img">
          <span>{{translate('Finish calc warning modal title', 'designer')}}</span>
        </h4>
      </div>
    </div>

  <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

  <div class="dialog-modal-body dialog-modal-body--small" >
    <p class="dialog-modal-description--small">{{translate('Finish calc warning modal content', 'designer')}}</p>
  </div>

  <div class="dialog-modal-footer dialog-modal-footer--small dialog-modal-footer--one-btn" >
    <button  type="button" class="btn btn-primary dialog-modal-btn--small mr-15" (click)="modal.close()"> {{translate('Yes')}}
    </button>
    <button  type="button" class="btn btn-outline-primary dialog-modal-btn--small" (click)="modal.dismiss()">  {{translate('No')}}
    </button>
  </div>
</ng-template>

<ng-template let-modal #incompleteModal>
  <div class="dialog-modal-header dialog-modal-header--small">
    <div class="dialog-modal-title-container">
      <div class="dialog-modal-title-content">
        <h4 class="dialog-modal-title dialog-modal-title--small" id="modal-basic-title">
          <img src="assets/tooltip-warning-icon.svg" alt="warning-icon" class="dialog-modal-title-img">
          <span>{{translate('Incomplete save title', 'designer')}}</span>
        </h4>
      </div>
    </div>

    <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="dialog-modal-body dialog-modal-body--small" >
    <p class="dialog-modal-description--small">{{translate('Incomplete save content', 'designer')}}</p>
  </div>
  <div class="dialog-modal-body dialog-modal-body--small" >
    <p class="dialog-modal-description--small">{{translate('Head note', 'designer')}}</p><input class="ml-4" [(ngModel)]="configHeadNote">
  </div>

  <div class="dialog-modal-footer dialog-modal-footer--small dialog-modal-footer--one-btn" >
    <button  type="button" class="btn btn-primary dialog-modal-btn--small mr-15" (click)="modal.close()"> {{translate('Save')}}
    </button>
    <button  type="button" class="btn btn-outline-primary dialog-modal-btn--small" (click)="modal.dismiss()">  {{translate('Cancel')}}
    </button>
  </div>
</ng-template>
