import { Webcad } from "webcad/core";
import { Vector2 } from "webcad/math";
import { getPointsFromSegment } from "webcad/models";
import { ModelVisualizer } from "webcad/visualizers";
import { PerforationAreaViewModel } from "../model/view-model/perforation-area.viewModel";
import libtess from "libtess";
import { getPerforationAreasGroup } from "../model/perforation-area.model";
import { createLineSystemWithDepthOffset } from "./line-system";
import {
  Color3,
  Color4,
  Engine,
  Material,
  Mesh,
  Node,
  Scene,
  StandardMaterial,
  Vector3,
  VertexBuffer
} from "webcad/babylonjs/core";

export class PerforationAreaVisualizer
  implements ModelVisualizer<PerforationAreaViewModel>
{
  private perforationOutline: Mesh[] = [];
  private perforationOutlineMesh: Mesh[] = [];
  private model: PerforationAreaViewModel;
  private rootNode: Node;
  //private shapeMaterial: BABYLON.StandardMaterial;
  engine: Engine;

  dispose(): void {
    this.disposeMeshes();
  }

  disposeMeshes(): void {
    for (const m of this.perforationOutline) {
      m.dispose();
    }
    for (const m of this.perforationOutlineMesh) {
      m.dispose();
    }
    this.perforationOutlineMesh = [];
    this.perforationOutline = [];
  }

  init(
    rootNode: Node,
    model: PerforationAreaViewModel,
    webcad: Webcad
  ): Promise<void> {
    if (model.showPerforationAreaOutline) {
      this.createPerforationOutline(model, rootNode.getScene());
    }
    this.model = model;
    this.rootNode = rootNode;
    this.engine = rootNode.getEngine();
    return Promise.resolve();
  }

  updateVisualization(newModel: PerforationAreaViewModel): void {
    if (
      this.model.perforationAreas !== newModel.perforationAreas ||
      this.model.showPerforationAreaOutline !==
        newModel.showPerforationAreaOutline ||
      this.model.plateDepth !== newModel.plateDepth ||
      this.model.selectedAreaIndex !== newModel.selectedAreaIndex ||
      this.model.dependentMode !== newModel.dependentMode
    ) {
      this.disposeMeshes();
      if (newModel.showPerforationAreaOutline) {
        this.createPerforationOutline(newModel, this.rootNode.getScene());
      }
    }
    this.model = newModel;
  }

  private createPerforationOutline(
    model: PerforationAreaViewModel,
    scene: Scene
  ): void {
    const selectedAreas = getPerforationAreasGroup(
      model.perforationAreas,
      model.selectedAreaIndex,
      model.dependentMode
    );

    for (let i = 0; i < model.perforationAreas.length; i++) {
      const outlinePoints: Vector3[][] = [];
      const colors: Color4[][] = [];
      const color = new Color4(0, 0, 1, 0.5);
      const positions: Vector2[] = [];
      const holes: Vector2[][] = [];
      const shapeWithHole = model.perforationAreas[i].shape;
      const conture = shapeWithHole.conture;
      if (conture) {
        const outline: Vector3[] = [];
        const outlineColor: Color4[] = [];
        for (const p of conture) {
          const points = getPointsFromSegment(p);
          for (const point of points) {
            positions.push(point);
            outline.push(new Vector3(point.x, point.y, 0));
            outlineColor.push(color);
          }
        }
        outline.push(outline[0]);
        outlineColor.push(outlineColor[0]);
        outlinePoints.push(outline);
        colors.push(outlineColor);
      }

      for (const hole of shapeWithHole.holes) {
        const holePos: Vector2[] = [];
        const holeOutline: Vector3[] = [];
        const holeOutlineColor: Color4[] = [];
        for (const p of hole) {
          const points = getPointsFromSegment(p);
          for (const point of points) {
            holePos.push(point);
            holeOutline.push(
              new Vector3(point.x, point.y, -model.plateDepth)
            );
            holeOutlineColor.push(color);
          }
        }
        holeOutline.push(holeOutline[0]);
        holeOutlineColor.push(holeOutlineColor[0]);
        outlinePoints.push(holeOutline);
        colors.push(holeOutlineColor);
        holes.push(holePos);
      }

      const contours: Vector2[][] = [];
      contours.push(positions);
      for (const h of holes) {
        contours.push(h);
      }

      const outline = createLineSystemWithDepthOffset(
        "perforation Outline",
        {
          lines: outlinePoints,
          colors: colors,
        },
        scene,
        -0.0004
      );
      outline.isPickable = false;
      this.perforationOutline.push(outline);

      const mesh = this.buildMesh(contours, scene);
      mesh.position.z = -0.0015;
      mesh.material = this.createMaterial(
        scene,
        selectedAreas.indexOf(model.perforationAreas[i]) !== -1
      );
      mesh.renderingGroupId = 3;
      // mesh.onBeforeRenderObservable.add((ed, es) => {
      //   this.engine.setStencilBuffer(true);
      //   this.engine.setStencilFunction(BABYLON.Engine.EQUAL);
      //   this.engine.setStencilFunctionReference(1);
      //   this.engine.setStencilMask(0xFF);
      // });
      // mesh.onAfterRenderObservable.add((ed, es) => {
      //   this.engine.setStencilFunction(BABYLON.Engine.ALWAYS);
      //   this.engine.setStencilFunctionReference(1);
      //   this.engine.setStencilMask(0xFF);
      //   this.engine.setStencilBuffer(false);
      // });

      this.perforationOutlineMesh.push(mesh);
    }
  }

  private buildMesh(contours: Vector2[][], scene: Scene): Mesh {
    const bounds = {
      min: {
        x: Number.POSITIVE_INFINITY,
        y: Number.POSITIVE_INFINITY,
      },
      max: {
        x: Number.NEGATIVE_INFINITY,
        y: Number.NEGATIVE_INFINITY,
      },
      width: 0,
      height: 0,
    };

    const tessy = new libtess.GluTesselator();
    tessy.gluTessCallback(
      libtess.gluEnum.GLU_TESS_VERTEX_DATA,
      (data: number[], polyVertArray: number[]) => {
        const x = data[0];
        const y = data[1];
        polyVertArray[polyVertArray.length] = x;
        polyVertArray[polyVertArray.length] = y;
        if (x > bounds.max.x) {
          bounds.max.x = x;
        }
        if (x < bounds.min.x) {
          bounds.min.x = x;
        }
        if (y > bounds.max.y) {
          bounds.max.y = y;
        }
        if (y < bounds.min.y) {
          bounds.min.y = y;
        }
      }
    );
    tessy.gluTessNormal(0, 0, 1);

    const triangleVerts = [];
    tessy.gluTessBeginPolygon(triangleVerts);

    for (let i = 0; i < contours.length; i++) {
      tessy.gluTessBeginContour();
      const contour = contours[i];
      for (let j = 0; j < contour.length; j++) {
        const coords = [contour[j].x, contour[j].y, 0];
        tessy.gluTessVertex(coords, coords);
      }
      tessy.gluTessEndContour();
    }

    // finish polygon (and time triangulation process)
    tessy.gluTessEndPolygon();

    bounds.width = bounds.max.x - bounds.min.x;
    bounds.height = bounds.max.y - bounds.min.y;

    const result = new Mesh("plate", scene);

    const normals = new Array<number>();
    const positions = new Array<number>();
    const uvs = new Array<number>();

    for (let i = 0; i < triangleVerts.length; i += 2) {
      const x = triangleVerts[i];
      const y = triangleVerts[i + 1];
      normals.push(0, 0, -1.0);
      positions.push(x, y, 0.0);
      uvs.push(
        (x - bounds.min.x) / bounds.width,
        (y - bounds.min.y) / bounds.height
      );
    }

    result.setVerticesData(VertexBuffer.PositionKind, positions, false);
    result.setVerticesData(VertexBuffer.NormalKind, normals, false);
    result.setVerticesData(VertexBuffer.UVKind, uvs, false);
    result._unIndexed = true;
    return result;
  }

  private createMaterial(
    scene: Scene,
    selected: boolean
  ): Material {
    const result = new StandardMaterial("shape material", scene);
    result.specularColor = new Color3(0, 0, 0);
    result.diffuseColor = new Color3(0, 0.66, 0.89);
    result.ambientColor = new Color3(0, 0.66, 0.89);
    result.alpha = selected ? 0.4 : 0.2;
    return result;
  }
}
