import {Action} from "@ngrx/store";
import {ToolModel} from "../../model/tool";
import {Vector2} from "webcad/math";

export const SET_TOOL = "SET_TOOL";
export const SET_TOOL_OFFSET = "SET_TOOL_OFFSET";

export class SetTool implements Action{
  readonly type: string = SET_TOOL;

  constructor(public payload?: ToolModel) {
  }

}

export class SetToolOffset implements Action{
  readonly type: string = SET_TOOL_OFFSET;

  constructor(public payload?: Vector2) {
  }

}
