import {Action} from '@ngrx/store';
import {UserPattern} from '../../model/user-pattern.model';

export const ADD_USER_PATTERN = 'ADD USER PATTERN';

export class AddUserPattern implements Action {
  readonly type: string = ADD_USER_PATTERN;

  constructor(public payload: Pattern) {

  }
}

export const ADD_USER_PATTERN_SUCCESS = 'ADD USER PATTERNS SUCCESS';

export class AddUserPatternSuccess implements Action {
  readonly type: string = ADD_USER_PATTERN_SUCCESS;

  constructor(public payload: UserPattern) {
  }

}

export interface Pattern {
  name: string;
  previewImageId: string;
  publicPattern: boolean;
}

export const CONFIGURE_USER_PATTERN = 'CONFIGURE USER PATTERN';

export class ConfigureUserPattern implements Action {
  readonly type: string = CONFIGURE_USER_PATTERN;

  constructor(public payload: Pattern) {

  }
}

export const CONFIGURE_USER_PATTERN_SUCCESS = 'CONFIGURE USER PATTERN SUCCESS';

export class ConfigureUserPatternSuccess implements Action {
  readonly type: string = CONFIGURE_USER_PATTERN_SUCCESS;

  constructor(public payload: boolean) {

  }
}

export const GET_USER_PATTERNS = 'GET USER PATTERNS';

export class GetUserPatterns implements Action {
  readonly type: string = GET_USER_PATTERNS;

  constructor(public payload: string = null) {

  }
}

export const GET_USER_PATTERNS_SUCCESS = 'GET USER PATTERNS SUCCESS';

export class GetUserTemplesSuccess implements Action {
  readonly type: string = GET_USER_PATTERNS_SUCCESS;

  constructor(public payload: UserPattern[]) {
  }

}

export const DELETE_USER_PATTERN = 'DELETE USER PATTERN';

export class DeleteUserPattern implements Action {
  readonly type: string = DELETE_USER_PATTERN;

  constructor(public payload: number) {

  }
}

export const DELETE_USER_PATTERN_SUCCESS = 'DELETE USER PATTERN SUCCESS';

export class DeleteUserPatternSuccess implements Action {
  readonly type: string = DELETE_USER_PATTERN_SUCCESS;

  constructor(public payload: number) {

  }
}


export type UserPatternActions =
  DeleteUserPattern
  | GetUserTemplesSuccess
  | GetUserPatterns
  | AddUserPattern
  | DeleteUserPatternSuccess
  | AddUserPatternSuccess;
