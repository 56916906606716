import {Action} from '@ngrx/store';
import {SnapOptions} from '../../model/snap-options.model';

export const SET_SNAP_OPTIONS = 'SET SNAP OPTIONS';

export class SetSnapOptions implements Action {
  readonly type: string = SET_SNAP_OPTIONS;

  constructor(public payload: SnapOptions) {
  }
}

export const SET_SNAP_OPTION_EDGE = 'SET SNAP OPTION EDGE';

export class SetSnapOptionEdge implements Action {
  readonly type: string = SET_SNAP_OPTION_EDGE;

  constructor(public payload: boolean) {
  }
}

export const SET_SNAP_OPTION_NODE = 'SET SNAP OPTION NODE';

export class SetSnapOptionNode implements Action {
  readonly type: string = SET_SNAP_OPTION_NODE;

  constructor(public payload: boolean) {
  }
}

export const SET_SNAP_OPTION_GRID = 'SET SNAP OPTION GRID';

export class SetSnapOptionGrid implements Action {
  readonly type: string = SET_SNAP_OPTION_GRID;

  constructor(public payload: boolean) {
  }
}

export const SET_SNAP_OPTION_HELP_LINE = 'SET SNAP OPTION HELP LINE';

export class SetSnapOptionHelpLine implements Action {
  readonly type: string = SET_SNAP_OPTION_HELP_LINE;

  constructor(public payload: boolean) {
  }
}

export const SET_SNAP_OPTION_MOUNTINGS = 'SET SNAP OPTIONS MOUNTINGS';

export class SetSnapOptionMountings implements Action {
  readonly type: string = SET_SNAP_OPTION_MOUNTINGS;

  constructor(public payload: boolean) {

  }
}

export const SET_MEASUREMENT_VISIBILITY = 'SET MEASUREMENT VISIBILITY';

export class SetMeasurementVisibility implements Action {
  readonly type: string = SET_MEASUREMENT_VISIBILITY;

  constructor(public payload: boolean) {

  }
}


export const SET_PERFORATION_COLLIDER = 'SET PERFORATION COLLIDER';

export class SetPerforationCollider implements Action {
  readonly type: string = SET_PERFORATION_COLLIDER;

  constructor(public payload: boolean) {

  }
}

export const SET_PERFORATION_AREA_VISIBILITY = 'SET PERFORATION AREA VISIBILITY';

export class SetPerforationAreaVisibility implements Action {
  readonly type: string = SET_PERFORATION_AREA_VISIBILITY;

  constructor(public payload: boolean) {

  }
}

export type SnapOptionsActions =
  SetSnapOptionMountings |
  SetSnapOptionEdge |
  SetSnapOptionGrid |
  SetSnapOptionHelpLine |
  SetSnapOptionNode |
  SetSnapOptions |
  SetMeasurementVisibility |
  SetPerforationCollider |
  SetPerforationAreaVisibility;
