<div class="dialog-modal-header">
  <div class="dialog-modal-header-content">
    <div class="dialog-modal-title-container">
      <h4 class="dialog-modal-title-primary" id="staircase-title">
        {{ this.templateUtils.translate("Staircase") }}
      </h4>
      <span class="dialog-modal-title-secondary"
        >{{
          this.templateUtils.translate("EnterDimenstions", "designer")
        }}
        (mm):</span
      >
    </div>
    <div class="dialog-modal-window">
      <span>{{
        this.templateUtils.translate("SketchDoesntChange", "designer")
      }}</span>
    </div>
  </div>

  <button
    type="button"
    class="close btn-close"
    aria-label="Close"
    (click)="dismiss('Cross click'); isModalOpenMethod()"
    [tabIndex]="-1"
  ></button>
</div>

<div class="dialog-modal-body">
  <div class="staircase-inputs">
    <input
      type="text"
      id="staircase_ha"
      class="input-small staircase-ha"
      [tabIndex]="2"
      [ngStyle]="
        staircaseTemplate.ha <= 0
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [(ngModel)]="staircaseTemplate.ha"
      (input)="
        staircaseTemplate.ha = this.templateUtils.roundInput($event, 0, 5000);
        setStaircaseTemplate(staircaseTemplate)
      "
      required
    />

    <input
      type="text"
      id="staircase_a1"
      class="input-small staircase-a1"
      [tabIndex]="5"
      [ngStyle]="
        staircaseTemplate.a1 <= 0
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [(ngModel)]="staircaseTemplate.a1"
      (input)="
        staircaseTemplate.a1 = this.templateUtils.roundInput($event, 0, 5000);
        setStaircaseTemplate(staircaseTemplate)
      "
      required
    />

    <input
      type="text"
      id="staircase_tilt"
      class="input-small staircase-tilt"
      [tabIndex]="1"
      (keydown)="this.templateUtils.returnToLastElement($event, s_e2)"
      [ngStyle]="
        staircaseTemplate.tilt == null || staircaseTemplate.tilt < 0
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [value]="staircaseTemplate.tilt * (180 / pi) | number : '1.0-2'"
      (input)="
        setStaircaseTilt(this.templateUtils.roundInput($event, null, null, 2))
      "
      required
      #s_t
    />

    <input
      type="text"
      id="staircase_b1"
      class="input-small staircase-b1"
      [tabIndex]="3"
      [ngStyle]="
        staircaseTemplate.b1 <= 0
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [(ngModel)]="staircaseTemplate.b1"
      (input)="
        staircaseTemplate.b1 = this.templateUtils.roundInput($event, 0, 5000);
        setStaircaseTemplate(staircaseTemplate)
      "
      required
    />

    <input
      type="text"
      id="staircase_b2"
      class="input-small staircase-b2"
      [tabIndex]="4"
      [ngStyle]="
        staircaseTemplate.b2 <= 0
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [(ngModel)]="staircaseTemplate.b2"
      (input)="
        staircaseTemplate.b2 = this.templateUtils.roundInput($event, 0, 5000);
        setStaircaseTemplate(staircaseTemplate)
      "
      required
    />

    <input
      type="text"
      id="staircase_e1"
      class="input-small staircase-e1"
      [tabIndex]="8"
      [ngStyle]="
        staircaseTemplate.e1 <= 0
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [(ngModel)]="staircaseTemplate.e1"
      (input)="
        staircaseTemplate.e1 = this.templateUtils.roundInput(
          $event,
          0,
          minf(staircaseTemplate.a1, staircaseTemplate.ha) * 0.5
        );
        setStaircaseTemplate(staircaseTemplate)
      "
      required
    />

    <input
      type="text"
      id="staircase_e2"
      class="input-small staircase-e2"
      [tabIndex]="9"
      (keydown)="this.templateUtils.returnToFirstElement($event, s_t)"
      [ngStyle]="
        staircaseTemplate.e2 <= 0
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [(ngModel)]="staircaseTemplate.e2"
      (input)="
        staircaseTemplate.e2 = this.templateUtils.roundInput(
          $event,
          0,
          minf(staircaseTemplate.a1, staircaseTemplate.ha) * 0.5
        );
        setStaircaseTemplate(staircaseTemplate)
      "
      required
      #s_e2
    />

    <input
      type="text"
      id="staircase_f1"
      class="input-small staircase-f1"
      [tabIndex]="7"
      [ngStyle]="
        staircaseTemplate.f1 <= 0
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [(ngModel)]="staircaseTemplate.f1"
      (input)="
        staircaseTemplate.f1 = this.templateUtils.roundInput(
          $event,
          0,
          staircaseTemplate.b1 * 0.5
        );
        setStaircaseTemplate(staircaseTemplate)
      "
      required
    />

    <input
      type="text"
      id="staircase_f2"
      class="input-small staircase-f2"
      [tabIndex]="6"
      [ngStyle]="
        staircaseTemplate.f2 <= 0
          ? { 'background-color': '#f5c6cb' }
          : { 'background-color': 'white' }
      "
      [(ngModel)]="staircaseTemplate.f2"
      (input)="
        staircaseTemplate.f2 = this.templateUtils.roundInput(
          $event,
          0,
          staircaseTemplate.b1 * 0.5
        );
        setStaircaseTemplate(staircaseTemplate)
      "
      required
    />
  </div>
</div>

<div class="dialog-modal-footer">
  <span class="dialog-modal-link" (click)="this.templateUtils.setDefault()">
    {{ this.templateUtils.translate("Reset dimensions to default.") }}
  </span>
  <button
    #closeButton
    type="button"
    class="btn btn-primary btn-create"
    [tabIndex]="1"
    [disabled]="reqValStaircase | async"
    (click)="close(staircaseTemplate)"
  >
    {{ this.templateUtils.translate("Create") }}
  </button>
</div>
