import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslationProvider} from '../../providers/translation.provider';
import {Dropdown, DropdownOption} from '../dropdown/dropdown.component';
import {ProductType, Step} from '../../model/product-configuration/product-configuration.model';
import {Action, select, Store} from '@ngrx/store';
import {getConfiguratorSubpanelState, getProductType, getStep, MevacoState} from '../../store/index';
import {DatasetLoad, SetSubpanelState} from '../../store/actions';
import {Observable, Subscription} from 'rxjs';
import {ProductConfigurationLoad} from '../../store/actions/product-configuration.actions';
import {ActivatedRoute} from '@angular/router';
import {LoadMountings} from '../../store/actions/mountings.actions';
import * as jwtDecode from 'jwt-decode';
import {animate, state, style, transition, trigger, } from '@angular/animations';
import { Subpanel, SubpanelCurrentState, Subpanels } from '../../model/right-panel.model';


@Component({
  selector: 'configurator',
  templateUrl: './configurator.component.html',
  styleUrls: ['./configurator.component.css'],
  animations: [
    trigger('openClose', [
      state('open', style({
        width: '100%',
        opacity: 1
      })),
      state('closed', style({
        // width: '0%',
        opacity: 0,
        transform: 'translateX(290px)',
        'z-index': 1

      })),
      transition('open => closed', [
        animate('0.5s ease-in')
      ]),
      transition('closed => open', [
        animate('0.3s ease-in')
      ])
    ]),
    trigger('openCloseConfigurator', [
      state('open', style({
        right: 0,
        opacity: 1,
      })),
      state('closed', style({
        opacity: 0,
        right: '-290px',
        width: '40px'
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.3s')
      ])
    ]),
  ]
})
export class ConfiguratorComponent implements OnInit, OnDestroy {

  public step: Observable<Step>;
  public Step = Step;
  public SubpanelCurrentStep = SubpanelCurrentState;

  public configuratorOpen = true;
  public customPatternEditorIsOpened = false;
  public configuratorSubpanelState: Subpanel;
  private subscriptions: Subscription[] = [];
  public productType = this.store.pipe(select(getProductType));


  constructor(private translationProvider: TranslationProvider, private store: Store<MevacoState>, activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    this.subscriptions.push(this.store.pipe(select(getConfiguratorSubpanelState)).subscribe(configuratorSubpanel => {
      this.configuratorSubpanelState = configuratorSubpanel;
    }));
    this.step = this.store.pipe(select(getStep));
  }

  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }

  createDropDown(name: string, value: Observable<string>, options: Observable<DropdownOption[]>, actionFactory: (string) => Action) {
    return new Dropdown(
      name,
      options,
      value,
      (value) => this.store.dispatch(actionFactory(value))
    );
  }

  toggleConfigurator() {
    if(this.configuratorSubpanelState.state === SubpanelCurrentState.OPENED) {
      this.store.dispatch(new SetSubpanelState({name: Subpanels.CONFIGURATOR_SUBPANEL, state: SubpanelCurrentState.CLOSED}))
    } else {
      this.store.dispatch(new SetSubpanelState({name: Subpanels.CONFIGURATOR_SUBPANEL, state: SubpanelCurrentState.OPENED}))
    }
  }

  ngOnDestroy(){
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }


  protected readonly ProductType = ProductType;
}
