import {Component, OnDestroy, OnInit} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import {AddOffsetToPlatePerfaction, getConfigurationElements, MevacoState} from '../../../store';
import { SegmentType, subVectors2, multiplyVector2byScalar, Segment, Vector2 } from 'webcad';
import {Element} from '../../../model/product-configuration/element.model';
import { map } from 'rxjs/operators';

interface ShapeWithHolesPaths {
  conture: string;
  holes: string[];
}
interface ElementPaths {
  pos: Vector2;
  offset: Vector2;
  shape: ShapeWithHolesPaths,
  perforationAreas: ShapeWithHolesPaths[],
}

interface ElementPath {
  pos: Vector2;
  offset: Vector2;
  path: string,
  min: Vector2;
}

@Component({
  selector: '[perfaction_plates]',
  templateUrl: './perfaction-plates.component.html',
  styleUrls: ['./perfaction-plates.component.css']
})
export class PerfactionPlatesComponent implements OnInit, OnDestroy {
  public pixelsPerMeter = 500;

  private _platesPaths: Observable<ElementPath[]> = this.store
    .pipe(
      select( getConfigurationElements ),
      map(elements => elements.filter(element => element.perforationAreas.length > 0).map(element => this.generatePath(element)))
    );

  public platesPaths: ElementPath[];
  private platesPathsSub: Subscription;
  constructor(private store: Store<MevacoState>) { }


  ngOnInit() {
    this.platesPathsSub = this._platesPaths.subscribe( (platesPathsSub) =>  {
      this.platesPaths = platesPathsSub;
    })
  }

  ngOnDestroy(): void {
    this.platesPathsSub.unsubscribe();
  }

  movePlate(pathElement: any, x: number, y: number): void {
    const plateGroup = pathElement && pathElement.parentNode;
    if ( plateGroup && plateGroup.id.startsWith('plate_') ) {
      const plateIndex = +plateGroup.id.substring(6);
      const offset = this.platesPaths[plateIndex].offset;
      offset.x = x;
      offset.y = y;
    }
  }

  applyMove(pathElement: any, x: number, y: number): void {
    const plateGroup = pathElement && pathElement.parentNode;
    if ( plateGroup && plateGroup.id.startsWith('plate_') ) {
      const plateIndex = +plateGroup.id.substring(6);
      const offset = {
        x: x / this.pixelsPerMeter,
        y: -y / this.pixelsPerMeter

      }
      this.store.dispatch(new AddOffsetToPlatePerfaction({plateIndex, offset}));
    }
  }

  generatePath(element: Element): ElementPath {
    const paths: string[] = [
      this.generatePolylinePath(element.shape.conture),
      ...element.shape.holes.map( hole => this.generatePolylinePath(hole))
    ];
     for (let i = 0; i < element.perforationAreas.length; i++) {
       const perforationArea = element.perforationAreas[i];
       paths.push(...[
         this.generatePolylinePath(perforationArea.shape.conture),
         ...perforationArea.shape.holes.map( hole => this.generatePolylinePath(hole))
       ]);
     }
    const min = {x: Number.MAX_VALUE, y: Number.MAX_VALUE};
    for(const pa of element.perforationAreas) {
      if( min.x > pa.shape.aabb.min.x * this.pixelsPerMeter) {
        min.x = pa.shape.aabb.min.x * this.pixelsPerMeter;
      }
      if( min.y > pa.shape.aabb.min.y * this.pixelsPerMeter) {
        min.y = pa.shape.aabb.min.y * this.pixelsPerMeter;
      }
    }
    if (min.x > 1000) {
      console.log("err");
    }

    return {
      pos: {
        x: element.perfactionOffset ? element.perfactionOffset.x * this.pixelsPerMeter : 0,
        y: element.perfactionOffset ? -element.perfactionOffset.y * this.pixelsPerMeter : 0
      },
      offset: {x: 0, y: 0},
      path: paths.join(' '),
      min: min
    };
  }
   generatePolylinePath(polyline: Segment[]): string {
    let currentPos = {x: polyline[0].begin.x * this.pixelsPerMeter, y: polyline[0].begin.y * this.pixelsPerMeter };
    const shapePath = [`M${currentPos.x} ${currentPos.y}`];
    for (let i = 0; i < polyline.length; i++) {
      const segment = polyline[i];
      if (segment.type === SegmentType.arc) {
        const angle = segment.endAngle - segment.beginAngle;
        if (Math.abs(angle) + 0.00001 >=  Math.PI * 2 ) { // is a circle
          const toCenter = subVectors2( multiplyVector2byScalar(segment.origin, this.pixelsPerMeter), currentPos );
          const opposite = {
            x: currentPos.x + toCenter.x * 2,
            y: currentPos.y + toCenter.y * 2
          };
          shapePath.push(`A${segment.radius * this.pixelsPerMeter} ${segment.radius * this.pixelsPerMeter} 0 1 1 ${opposite.x} ${opposite.y}`);
          shapePath.push(`A${segment.radius * this.pixelsPerMeter} ${segment.radius * this.pixelsPerMeter} 0 1 1 ${currentPos.x} ${currentPos.y}`);
        }
        const largeFlag = Math.abs(angle) > Math.PI ? 1 : 0;
        const sweepFlag = angle > 0 ? 1 : 0;
        currentPos = {x: segment.end.x * this.pixelsPerMeter, y: segment.end.y * this.pixelsPerMeter };
        shapePath.push(`A${segment.radius * this.pixelsPerMeter} ${segment.radius * this.pixelsPerMeter} 0 ${largeFlag} ${sweepFlag} ${currentPos.x} ${currentPos.y}`);
      } else {
        currentPos = {x: segment.end.x * this.pixelsPerMeter, y: segment.end.y * this.pixelsPerMeter };
        shapePath.push(`L${currentPos.x} ${currentPos.y}`);
      }
    }
    return shapePath.join( ' ');
  }

  transform(platePaths: ElementPath) {
    return  'translate(' + platePaths.min.x.toFixed(2) + ' ' + platePaths.min.y.toFixed(2) + ')';
  }

}
