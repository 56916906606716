import { Component } from '@angular/core';
import {TranslationProvider} from "../../../providers/translation.provider";
import {Action, select, Store} from "@ngrx/store";
import {
  ExpandedMetalSizeChange,
  ExpandedMetalTypeChange,
  MevacoState, SetDivingDepth, SetFeedrate, SetStegcluster,
} from "../../../store";
import {PossibleOptionsService} from "../../../services/possible-options.service";
import {Dropdown, DropdownOption} from "../../dropdown/dropdown.component";
import {combineLatest, Observable, of} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'expanded-metal-configurator',
  templateUrl: './expanded-metal-configurator.component.html',
  styleUrls: ['./expanded-metal-configurator.component.css']
})
export class ExpandedMetalConfiguratorComponent {

  public expandedMetalTypeDropdown: Dropdown;
  public expandedMetalSizeDropdown: Dropdown;

  public stegcluster = this.store.pipe(select((state) => state.model.productConfiguration.extendedMetal.stegcluster));
  public schmalPossible = this.store.pipe(select((state) => state.model.productConfiguration.configuration.schmalPossible));
  public breitPossible = this.store.pipe(select((state) => state.model.productConfiguration.configuration.breitPossible));
  public minFeedrate = this.store.pipe(select(state => state.model.productConfiguration.configuration.minFeedrate));
  public maxFeedrate = this.store.pipe(select(state => state.model.productConfiguration.configuration.maxFeedrate));
  public feedrate = this.store.pipe(select((state) => state.model.productConfiguration.extendedMetal.feedrate));
  constructor(
    private translationProvider: TranslationProvider,
    private store: Store<MevacoState>,
    private possibleOptionsService: PossibleOptionsService
  ) {
  }
  ngOnInit() {
    this.expandedMetalTypeDropdown = this.createDropDown(
      this.translate("Shape"),
      this.store.pipe(
        select((state) => state.model.productConfiguration.extendedMetal.type)
      ) as Observable<string>,
      this.getExpandedMetalTypeOptions(),
      (value) => new ExpandedMetalTypeChange(value),
      this.possibleOptionsService.possibleExpandedMetalTypes
    );

    this.expandedMetalSizeDropdown = this.createDropDown(
      this.translate("EM_Format"),
      this.store.pipe(
        select((state) => state.model.productConfiguration.extendedMetal.size)
      ) as Observable<string>,
      this.getExpandedMetalSizeOptions(),
      (value) => new ExpandedMetalSizeChange(value),
      this.possibleOptionsService.possibleExpandedMetalSizes
    );
  }
  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }

  createDropDown(
    name: string,
    value: Observable<string>,
    options: Observable<DropdownOption[]>,
    actionFactory: (string) => Action,
    possibleOptions: Observable<string[]> = of(null)
  ) {
    return new Dropdown(
      name,
      options,
      value,
      (v) => this.store.dispatch(actionFactory(v)),
      null,
      possibleOptions
    );
  }

  private getExpandedMetalTypeOptions(): Observable<DropdownOption[]> {
    return this.store.pipe(select((store) => store.model.dataset)).pipe(
      map((dataset) => {
        if (!dataset) {
          return [
            new DropdownOption(
              "text",
              "",
              true,
              "",
              this.translate("Shape")
            ),
          ];
        }
        return Object.keys( dataset.expandedMetals ).map(
          (expMatType) =>
            new DropdownOption(
              "withImage",
              {
                name: this.translate(expMatType),
                imgUrl:
                  dataset.extendedMetalAssetsUrl +
                  dataset.expandedMetalsIcons[expMatType],
              },
              true,
              expMatType,
              this.translate("Shape")
            )
        );
      })
    );
  }

  private getExpandedMetalSizeOptions(): Observable<DropdownOption[]> {
    return combineLatest([
      this.store.pipe( select((store) => store.model.dataset) ),
      this.store.pipe( select( (store) => store.model.productConfiguration.extendedMetal.type))
    ]).pipe(
      map(([dataset, expMetType]) => {
        if (!dataset || !expMetType) {
          return [
            new DropdownOption(
              "text",
              "",
              true,
              "",
              this.translate("EM_Format")
            ),
          ];
        }

        const sizes = Object.keys(dataset.expandedMetals[expMetType]);
        sizes.sort((a, b) => {
          const [a1, a2] = a.split(" x ");
          const [b1, b2] = b.split(" x ");
          return ( +a1 - +b1) * 1000 + +a2 - +b2;
        });
        return sizes.map(
          (size) =>
            new DropdownOption(
              "text",
              size,
              true,
              size,
              this.translate("EM_Format")
            )
        );
      })
    );
  }

  setStegcluster(stegcluster: string): void {
    this.store.dispatch( new SetStegcluster(stegcluster) );
  }

  setFeedrate(val: string) {
    this.store.dispatch( new SetFeedrate(val) );
  }
  setDivingDepth(val: string) {
    this.store.dispatch( new SetDivingDepth(val) );
  }
}
