export enum ColliderPriority {
  GRID = 1,
  PERFORATION_AREA = 2,
  PERFORATION = 3,
  HELP_LINE = 6,
  SEGMENT = 7,
  BENDING_LINE = 8,
  CROSS_INTERSECTION = 9,
  VIRTUAL_NODE = 10,
  MOUNTING = 11,
  NODE = 12,
  MEASUREMENT = 14,
}
