import {clamp, CosOfAngleBetween2Vectors2D, normalizeVector3, subVectors3, Vector3} from '../math';
import {AngleMeasurementModel} from '../models';
import {MeasurementsManager} from './measurements.manager';

export class AngleModelManager {

    constructor(private id: number, private manager: MeasurementsManager) {
    }


    disposeModel(): void {
        this.manager.disposeAngleModel(this.id);
    }

    public updateMeasurement(origin: Vector3, end: Vector3, startDirection?: Vector3): void {
        const oldModel: AngleMeasurementModel = this.manager.getAngleModelWithId(this.id);
        const dir = subVectors3(end, origin);
        let angle = Math.atan2(dir.y, dir.x);
        if (startDirection) {
            angle = Math.acos(clamp(CosOfAngleBetween2Vectors2D(dir, startDirection), -1, 1));
        }
        /*if(angle < 0){
            angle += Math.PI * 2;
        }*/
        const newModel: AngleMeasurementModel = {
            ...oldModel,
            origin: origin,
            visible: true,
            startDir: startDirection ? normalizeVector3(startDirection) : oldModel.startDir,
            exchange: {
                ...oldModel.exchange,
                value: angle
            }
        };
        this.manager.setAngleModelWithId(this.id, newModel);
    }

    public updateMeasurementWithAngle(origin: Vector3, angle: number, startDirection?: Vector3): void {
        const oldModel: AngleMeasurementModel = this.manager.getAngleModelWithId(this.id);
        const newModel: AngleMeasurementModel = {
            ...oldModel,
            origin: origin,
            visible: true,
            startDir: startDirection ? normalizeVector3(startDirection) : oldModel.startDir,
            exchange: {
                ...oldModel.exchange,
                value: angle
            }
        };
        this.manager.setAngleModelWithId(this.id, newModel);
    }

    setInputCallbacks(onInputLive: (value: number) => number, onInputConfirmed: (value: number) => number): void {
        const oldModel: AngleMeasurementModel = this.manager.getAngleModelWithId(this.id);
        const newModel: AngleMeasurementModel = {
            ...oldModel,
            exchange: {
                ...oldModel.exchange,
                onInputLive: onInputLive,
                onInputConfirmed: onInputConfirmed
            }
        };
        this.manager.setAngleModelWithId(this.id, newModel);
    }

    /*
        getModel():AngleMeasurementModel{
            return this.manager.getModelWithId(this.id);
        }

        setVisible(visible: boolean): void {
            this.manager.setVisible(this.id, visible);
        }

        setModel(model: AngleMeasurementModel): void {
            this.manager.setModelWithId(this.id, model);
        }
        */
    setVisible(visible: boolean) {
        const oldModel: AngleMeasurementModel = this.manager.getAngleModelWithId(this.id);
        const newModel: AngleMeasurementModel = {
            ...oldModel,
            visible: visible
        };
        this.manager.setAngleModelWithId(this.id, newModel);
    }
}
