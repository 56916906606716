import {initialMevacoPage, MevacoPage} from '../../model/mevaco-page.model';
import {drawingReducer} from './drawing.reducer';
import {changeCustomStampProperty, productConfigurationReducer} from './product-configuration.reducer';
import {
  ADD_OFFSET_TO_PLATE_PEFRACTION,
  BLOCK_USER,
  CALCULATE_SHAPE_FROM_POLYLINES_SUCCESS,
  CANCEL_IMPORT,
  CHANGE_START_POPUP,
  CHANGE_TEMPLATE_MODAL,
  CLOSE_SUBPANEL,
  DATASET_LOADED_SUCCESSFULLY,
  DatasetActions,
  DUPLICATE_CONFIGURATION_SUCCESS,
  GET_TRANSLATIONS_SUCCESS,
  GLTF_LADED,
  LOAD_DXF,
  LOAD_DXF_SUCCESS,
  LOAD_GLTF,
  MevacoPageActions,
  OPEN_SUBPANEL,
  PREVIEW_SUCCESS,
  PRODUCT_CONFIGURATION_ADD_ELEMENT,
  PRODUCT_CONFIGURATION_EDIT_ELEMENT,
  PRODUCT_CONFIGURATION_LOADED_SUCCESSFULLY,
  PRODUCT_CONFIGURATION_ONLY_ELEMENT,
  PRODUCT_CONFIGURATION_SAVED_SUCCESSFULLY,
  PRODUCT_CONFIGURATION_VALIDATED_SUCCESSFULLY,
  ProductConfigurationActions,
  SET_APP_MODE,
  SET_HEX_COLOR_STRING,
  SET_PERFACTION_EDITOR_STATE,
  SET_PERFORATION_AREA,
  SET_PERFORATION_SUCCESS,
  SET_PLATE,
  SET_SELECTED_UNIT,
  SET_STEP,
  SET_SUBPANEL_POSITION,
  SET_SUBPANEL_STATE,
  SET_SUBPANEL_WIDTH,
  SHOW_CUSTOM_PATTERN_EDITOR,
  SHOW_VIEWER_ELEMENT,
  SUBMIT_IMPORT,
  TOGGLE_CUSTOM_PATTERN_SUCCEEDED,
  UNDO_REDO_SUCCESS,
  UPDATE_DRAWING,
  UPDATE_DRAWING_FAIL,
  UpdateDrawingFailParams,
  UploadDxfResponse
} from '../actions';
import {datasetReducer} from './dataset.reducer';
import {ActionType, createNode, Plate, PlateMaterialType} from '../../model';
import {Element, ElementType} from '../../model/product-configuration/element.model';
import {
  areSimpleRectangles,
  getMinimalDistanceBetweenPerforationAreas,
  getMinimalDistanceBetweenShapeWithHolesEdges,
  getMinimalParallelEdgeDistance,
  getMinimalRadiusOfShape,
  getMinSegmentLength,
  getNumberOfArcsInShapeWithHoles,
  MinValue,
  ShapeWithHoles,
  shapeWithHolesHash
} from '../../model/shape-with-holes';
import {
  Aabb2,
  aabbOfPolyline,
  AngleMeasurementModel,
  expandAabb,
  getEmptyAaabb,
  getShortestPathBetweenTwoPolylines,
  MeasurementModel,
  movePolyline,
  projectPointOnSegment,
  Segment,
  segmentLength,
  SegmentType
} from 'webcad/models';
import {SET_ERROR, SET_SUCCESS} from '../actions/errorable.actions';
import {HelpLine} from '../../model/help-line.model';
import {LOAD_MOUNTINGS_SUCCESS, LoadMountingSuccessObject} from '../actions/mountings.actions';
import {
  initialMounting,
  isMountingCorrectlyPlaced,
  Mounting,
  MountingAttachment,
  scaleMounting
} from '../../model/mounting.model';
import {SnapOptions} from '../../model/snap-options.model';
import {MevacoResponse} from '../../model/client-server.Params/mevaco-response.model';
import {userTemplatesReducer} from './user-templates.reducer';
import {UserTemplateActions} from '../actions/user-template.actions';
import {configurableTemplatesReducer} from './basic-templates.reducer';
import {ConfigurableTemplatesActions} from '../actions/element-creator.actions';
import {ProductType, Step} from '../../model/product-configuration/product-configuration.model';
import {updateDrawingShapes} from '../store.utils';
import {initialDrawing, ShapeOrigin} from '../../model/drawing.model';
import {PerforationAreaModel} from '../../model/perforation-area.model';
import {MessageType} from '../../model/error.model';
import {ELEMENT_UPDATE_TYPES, FILTER_VALIDATIONS} from '../actions/save.actions';
import {getStampList, Perforation, PerforationModel, PositionsRow} from '../../model/perforation.model';
import {lengthVector2, multiplyVector2byScalar, sqrDistanceVector2, subVectors2, Vector2} from 'webcad/math';
import {AppMode} from '../../model/app-mode.enum';
import {HintStatus} from '../../model/hint.model';
import {initToleranceLookup, updateTolerance} from '../../model/tolerance-calculator';
import {elementsAabbAndTotalSurfaceReducer} from './elements-aabb.reducer';
import {dependentMode} from '../../utils/dependend-mode-validator';
import {Material} from '../../model/product-configuration/material.model';
import {Guid} from 'guid-typescript';
import {TemplateName} from '../../components/templates/template-name';
import {BendingLine} from '../../model/bending-line.model';
import {setElementLegsLength} from '../../model/view-model/bending-node.viewModel';
import {generateLabelReducer} from './generate-labeles.reducer';
import {shapeCorrectionReducer} from './shape-correction.reducer';
import {configurationProperties} from '../../model/change-state-result';
import {perforationReducer} from './perforation.reducer';
import {ENABLE_CUSTOM_STAMP, SET_SELECTED_CUSTOM_STAMP} from '../actions/custom-geom.actions';
import {roundDecimal} from '../../utils/utils';
import {userPatternsReducer} from './user-patterns.reducer';
import {UserPatternActions} from '../actions/user-pattern.actions';
import {SubpanelCurrentState} from "../../model/right-panel.model";
import {initialProductReducer} from "./initial-product.reducer";

export function mevacoPageReducer(state: MevacoPage = initialMevacoPage, action: MevacoPageActions): MevacoPage {
  console.info('ACTION: ' + action.type);
  // We need to wrap it to see what was changed by this action amd maybe make some mor changes
  let newState = actualMevacoPageReducer(state, action);
  newState = shapeCorrectionReducer(newState, action);
  newState = elementsAabbAndTotalSurfaceReducer(newState, action);
  newState = elementUuidReducer(newState);
  newState = customPatternMinDistance(newState, action);
  newState = generateLabelReducer(newState, action);
  newState = initialProductReducer(newState, action);
  return newState;
}
export function actualMevacoPageReducer(state: MevacoPage = initialMevacoPage, action: any): MevacoPage {
  switch (action.type) {
    case FILTER_VALIDATIONS:
      console.log(state);
      return {
        ...state
      };
    case SET_APP_MODE: {
      return {
        ...state,
        mode: action.payload
      };
    }
    case SET_SELECTED_UNIT: {
      return {
        ...state,
        selectedUnit: action.payload
      };
    }
    case CHANGE_TEMPLATE_MODAL:
      return {
        ...state,
        productConfiguration: {
          ...state.productConfiguration,
          loadTemplateModal: action.payload

        }
      };
    case CHANGE_START_POPUP:
      return {
        ...state,
        productConfiguration: {
          ...state.productConfiguration,
          showStartPopup: false
        }
      };
    case SET_ERROR:
      return {
        ...state,
        message: {
          message: action.payload,
          type: MessageType.ERROR
        },
        drawing: {
          ...state.drawing,
          isImporting: false
        }
      };
    case SET_SUCCESS:
      return {
        ...state,
        message: {
          message: action.payload,
          type: MessageType.SUCCESS
        },
        drawing: {
          ...state.drawing,
          isImporting: false
        }
      };
    case SET_STEP:
      const st: MevacoPage = {
        ...state,
        productConfiguration: {
          ...state.productConfiguration,
          step: action.payload
        },
        drawing: {
          ...state.drawing,
          snapOptions: getDefaultSnapOptionsForStep(state.productConfiguration.step, action.payload as Step, state.drawing.snapOptions),
          showPerforationBorder: (action.payload as Step) === Step.pattern && state.productConfiguration.activePerforation === 'classic',
          fillPerforation: (action.payload as Step) === Step.pattern,
          selectedHelpLineId: null,
          selectedAngleMeasurementId: null,
          selectedNodes: [],
          selectedMountings: [],
          selectedMeasurementId: null,
          selectedPerforationArea: -1
        }
      };
      switch (action.payload) {
        case Step.cockpit:
          st.drawing = {
            ...state.drawing,
            plate: {
              ...state.drawing.plate,
              position: -1
            }
          };
          const elements: Element[] = [];
          let i = 1;
          for (const element of state.productConfiguration.elements) {
            if (element.shape.conture.length > 0) {
              elements.push({...element, position: i++});
            }
          }
          st.productConfiguration = {
            ...st.productConfiguration,
            elements: elements
          };
          break;
        case Step.shape:
        case Step.attachment:
          st.drawing = updateDrawingShapes(st.drawing, st.drawing.plate.shapeWithHoles, st.drawing.plate.perforationAreas);
          break;
        case Step.pattern:
          st.drawing = updateDrawingShapes(st.drawing, st.drawing.plate.shapeWithHoles, st.drawing.plate.perforationAreas);
          st.drawing.showPerforationBorder = st.productConfiguration.activePerforation === 'classic';
          st.drawing.fillPerforation = true;
          break;
      }
      return st;
    case PRODUCT_CONFIGURATION_EDIT_ELEMENT:
      return editPosition(state, action.payload, Step.design);
    case SHOW_VIEWER_ELEMENT:
      return showViewerElement(state, action.payload);
    case PRODUCT_CONFIGURATION_ADD_ELEMENT: {
      const elements = state.productConfiguration.elements || [];
      const element: Element = {
        ...action.payload as Element,
        position: elements.length + 1
      };
      const newState: MevacoPage = {
        ...state,
        productConfiguration: {
          ...state.productConfiguration,
          elements: [...elements, element]
        }
      };
      return editPosition(newState, elements.length + 1, Step.shape);
    }
    case PRODUCT_CONFIGURATION_ONLY_ELEMENT: {
      const newState: MevacoPage = {
        ...state,
        productConfiguration: {
          ...state.productConfiguration,
          elements: [{...action.payload, position: 1}]
        }
      };
      return editPosition(newState, 1, Step.shape);
    }
    case PRODUCT_CONFIGURATION_VALIDATED_SUCCESSFULLY:
    case PRODUCT_CONFIGURATION_SAVED_SUCCESSFULLY:
      const oldConf = state.productConfiguration.configuration;
      const confResponse = action.payload.configuration;
      const newConf = {...oldConf};
      let anyConfigChange = false;
      const fullConfResponse = {
        ...confResponse.configuration
      };
      for (let i = 0; i < configurationProperties.length; i++) {
        const property = configurationProperties[i];
        fullConfResponse[property] = fullConfResponse[property] || null;
      }
      for (const propertyName in fullConfResponse) {
        if ( configurationProperties.indexOf(propertyName) !== -1 ) {
          const value = fullConfResponse[propertyName] || {id: 0};
          if (!oldConf[propertyName] || JSON.stringify(oldConf[propertyName]) !== JSON.stringify(value)) {
            newConf[propertyName] = value;
            anyConfigChange = true;
          }
        } else if (propertyName === 'singleTools') {
          const singleTools = fullConfResponse.singleTools || [];
          if (!oldConf.singleTools || JSON.stringify(oldConf.singleTools) !== JSON.stringify(singleTools)) {
            newConf.singleTools  = singleTools;
            anyConfigChange = true;
          }
        } else {
          if (oldConf[propertyName] !== fullConfResponse[propertyName]) {
            newConf[propertyName] = fullConfResponse[propertyName];
            anyConfigChange = true;
          }
        }
      }
      const newElements: Element[] = [];
      let anyElementChanged = false;

      // if there ia a different number of elements then it means that user changed it before save result arrived
      // we can skip this and wait for another response that is on the way
      if (confResponse.elementsConfiguration.length === state.productConfiguration.elements.length) {
        for (let i = 0; i < confResponse.elementsConfiguration.length; i++) {
          const respElement = {...confResponse.elementsConfiguration[i]};
          respElement.e1 = respElement.e1 || null;
          respElement.e1ist = respElement.e1ist || null;
          respElement.e2 = respElement.e2 || null;
          respElement.e2ist = respElement.e2ist || null;
          respElement.f1 = respElement.f1 || null;
          respElement.f1ist = respElement.f1ist || null;
          respElement.f2 = respElement.f2 || null;
          respElement.f2ist = respElement.f2ist || null;
          respElement.lfbIst = respElement.lfbIst || null;
          respElement.lflIst = respElement.lflIst || null;
          respElement.minInsideAngle = respElement.minInsideAngle || null;
          respElement.minOutsideAngle = respElement.minOutsideAngle || null;
          const oldElement = state.productConfiguration.elements[i];
          const newElement = {
            ...oldElement
          };
          let elementChanged = false;
          for (const propertyName in respElement) {
            if (JSON.stringify(oldElement[propertyName]) !== JSON.stringify(respElement[propertyName])) {
              newElement[propertyName] = respElement[propertyName];
              elementChanged = true;
              anyElementChanged = true;
            }
          }
          newElements[i] = elementChanged ? newElement : oldElement;
        }
      }
      const newRoot = {...state.productConfiguration};
      let anyRootChange = false;
      for (const propertyName in confResponse.root) {
        if (state.productConfiguration[propertyName] !== confResponse.root[propertyName]) {
          newRoot[propertyName] = confResponse.root[propertyName];
          anyRootChange = true;
        }
      }

      const newExtendedMetal = {...state.productConfiguration.extendedMetal};
      let anyEMChange = false;
      for (const propertyName in confResponse.extendedMetal) {
        if (state.productConfiguration.extendedMetal[propertyName] !== confResponse.extendedMetal[propertyName]) {
          newExtendedMetal[propertyName] = confResponse.extendedMetal[propertyName];
          anyEMChange = true;
        }
      }

      let result: MevacoPage;
      if (anyRootChange || anyConfigChange || anyElementChanged) {
        result = {
          ...state,
          productConfiguration: {
            ...(anyRootChange ? newRoot : state.productConfiguration),
            configuration: anyConfigChange ? newConf : state.productConfiguration.configuration,
            elements: anyElementChanged ? newElements : state.productConfiguration.elements,
            extendedMetal: anyEMChange ? newExtendedMetal : state.productConfiguration.extendedMetal
          },
          rulesActions: action.payload.actions
        };
      } else {
        result = {
          ...state,
          rulesActions: action.payload.actions
        };
      }
      action.updateHash( result.productConfiguration );
      return result;

    case UNDO_REDO_SUCCESS: {
      const newState: MevacoPage = {...state};
      newState.productConfiguration = action.payload;
      if (newState && newState.productConfiguration &&
        newState.productConfiguration.step !== Step.cockpit
        && newState.productConfiguration.step !== Step.template
        && newState.productConfiguration.step !== null
        && newState.productConfiguration.step !== undefined
      ) {
        return editPosition(newState, newState.drawing.plate.position, newState.productConfiguration.step);
      } else if (!!newState.productConfiguration.step) {
        if ((<Step>(newState.productConfiguration.step) === Step.cockpit) || (newState.productConfiguration.step === Step.template)) {
          newState.drawing = {
            ...newState.drawing,
            plate: {
              ...newState.drawing.plate,
              position: -1
            }
          };
        }
      }
      return newState;
    }
    case GET_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
        translations: action.payload
      };
    }
    case LOAD_MOUNTINGS_SUCCESS: {
      const newMountings: Mounting[] = action.payload.mountings;
      const refIdMap = new Map<number, number>();
      const payload: LoadMountingSuccessObject = action.payload;
      state.drawing.plate.mountingHoles.forEach((v, k) => {
        const index = newMountings.findIndex((m) => m.id === v.mountingRef.id);
        if (index > -1) {
          refIdMap.set(k, index);
        }
      });
      const newArray: MountingAttachment[] = [...state.drawing.plate.mountingHoles];
      refIdMap.forEach((v, k) => {
        const obj = newArray[k];
        if (obj) {
          newArray[k] = {
            mountingRef: newMountings[v],
            vertices: payload.verticesMap.get(newMountings[v].form),
            shape: payload.shapeMap.get(newMountings[v].form),
            position: obj.position,
            rotation: obj.rotation,
            possible: obj.possible
          };
        }
      });
      return {
        ...state,
        mountings: action.payload.mountings,
        drawing: {
          ...state.drawing,
          mountingsModel: {
            ...state.drawing.mountingsModel,
            verticesMap: action.payload.verticesMap,
            shapeMap: action.payload.shapeMap
          },
          plate: {
            ...state.drawing.plate,
            mountingHoles: newArray
          }
        }
      };
    }
    case LOAD_DXF: {
      return {
        ...state,
        drawing: {
          ...state.drawing,
          isImporting: true
        }
      };
    }
    case LOAD_DXF_SUCCESS: {
      const response: UploadDxfResponse = action.payload.data;
      return {
        ...state,
        productConfiguration: {
          ...state.productConfiguration,
          step: Step.import
        },
        drawing: {
          ...state.drawing,
          snapOptions: getDefaultSnapOptionsForStep(state.productConfiguration.step, Step.import, state.drawing.snapOptions),
          hintMessage: 'Select polylines to import',
          importedShape: response.shpes,
          importId: response.id,
          isImporting: false,
          plate: {
            ...state.drawing.plate,
            position: -1,
            shapeWithHoles: {
              conture: [],
              holes: []
            },
            mountingHoles: [],
            perforationAreas: [],
            defaultPerforationAreaOffset: null,
          } as Plate,
          nodes: [],
        }
      } as MevacoPage;
    }
    case CALCULATE_SHAPE_FROM_POLYLINES_SUCCESS: {
      const newShape: ShapeWithHoles = (<MevacoResponse<ShapeWithHoles[]>>action.payload).data[0];
      return {
        ...state,
        drawing: {
          ...state.drawing,
          plate: {
            ...state.drawing.plate,
            shapeWithHoles: newShape
          }
        }
      };
    }
    case CANCEL_IMPORT:
      return {
        ...state,
        drawing: {
          ...state.drawing,
          importedShape: [],
          plate: {
            position: -1,
            height: 3,
            width: 5,
            depth: 0.03,
            currentLineShapeId: -1,
            show: true,
            actionType: ActionType.REMOVE,
            plateMaterial: PlateMaterialType.shape,
            autoCenter: true,
            perforationAreas: [],
            hexColorString: '',
            mountingHoles: [],
            shapeWithHoles: {
              holes: [],
              conture: []
            },
            bendingLines: [],
            boundary : []
          } as Plate
        }
      };
    case SUBMIT_IMPORT: {
      let aabb = getEmptyAaabb();
      for (const s of state.drawing.plate.shapeWithHoles.conture) {
        aabb = expandAabb(aabb, s.begin);
      }
      const a = (aabb.max.x - aabb.min.x);
      const b = (aabb.max.y - aabb.min.y);
      const e1 = 5;
      const e2 = 5;
      const f1 = 5;
      const f2 = 5;
      const element: Element = {
        templateName: TemplateName.none,
        boundary: null,
        type: ElementType.individual,
        position: -1, // will be set inb reducer
        quantity: 1,
        a: a,
        b: b,
        aIst: a,
        bIst: b,
        e1: e1.toString(),
        e1ist: e1.toString(),
        e2: e2.toString(),
        e2ist: e2.toString(),
        f1: f1.toString(),
        f1ist: f1.toString(),
        f2: f2.toString(),
        f2ist: f2.toString(),
        lfbIst: undefined,
        lflIst: undefined,
        openMeshE: '',
        openMeshF: '',
        toleranceWidth: 0,
        toleranceLength: 0,
        label: '',
        note: '',
        unperforated: !state.drawing.plate.perforationAreas || state.drawing.plate.perforationAreas.length < 1,
        posibleCoil: 'No',
        posiblePlate: 'No',
        shapes: null,
        nodes: [],
        // name: '',
        verticesIndexes: null,
        visualizationShape: null,
        previewImageId: Guid.create().toString(),
        shape: state.drawing.plate.shapeWithHoles,
        perforationAreas: state.drawing.plate.perforationAreas,
        helpLines: Array.from(state.drawing.helpingLines.values()),
        measurements: [],
        angleMeasurements: [],
        possibleAllAcross: false,
        mountings: state.drawing.plate.mountingHoles
          .filter( mh => isMountingCorrectlyPlaced(mh, state.drawing.plate.shapeWithHoles))
          .map((v) => {
            return {
              position: v.position,
              id: v.mountingRef.id,
              rotation: v.rotation
            };
        }),
        perforationAutoCenter: true,
        minRadius: getMinimalRadiusOfShape(state.drawing.plate.shapeWithHoles),
        minParallelEdgesDistance: getMinimalParallelEdgeDistance(state.drawing.plate.shapeWithHoles),
        minMarginDistance: getMinMarginDistance(
          state.lastPerforationResponse,
          state.drawing.plate.shapeWithHoles,
          state.drawing.plate.bendingLines),
        minDistanceBetweenPerforationAndBending: getMinDistanceBetweenPerforationAndBending(
          state.lastPerforationResponse,
          state.drawing.plate.bendingLines),
        minDistanceBetweenMountingAndBending: getMinDistanceBetweenMountingAndBending(
          state.drawing.plate.mountingHoles,
          state.drawing.plate.bendingLines),
        isShapeSimpleRect: areSimpleRectangles([state.drawing.plate.shapeWithHoles]),
        isPerforationSimpleRect: areSimpleRectangles(state.drawing.plate.perforationAreas.map((v) => v.shape)),
        minMountingHolesDistance: getMinimalMountingHolesDistance(Array.from(state.drawing.plate.mountingHoles)),
        minMountingHoleEdgeDistance: getMinimalMountingHolesEdgeDistance(Array.from(state.drawing.plate.mountingHoles), [state.drawing.plate.shapeWithHoles]),
//        minOutsideAngle: minAngle(state.drawing.plate.shapeWithHoles, true),
//        minInsideAngle: minAngle(state.drawing.plate.shapeWithHoles, false),
        minSegmentLength: getMinSegmentLength(state.drawing.plate.shapeWithHoles),
        minDistanceBetweenMountingAndPerforatedArea: getMinimalMountingHolesEdgeDistance(state.drawing.plate.mountingHoles, state.drawing.plate.perforationAreas.map(x => x.shape)),
        minDistanceBetweenEdges: getMinimalDistanceBetweenShapeWithHolesEdges(state.drawing.plate.shapeWithHoles),
        numberOfArcs: getNumberOfArcsInShapeWithHoles(state.drawing.plate.shapeWithHoles),
        // perforation: cloneObject(state.drawing.perforation),
        minDistanceBetweenPerforationAreas: getMinimalDistanceBetweenPerforationAreas(state.drawing.plate.perforationAreas.map(x => x.shape)),
        breakLines: [],
        bendingLinesDistances: [],
        importId: state.drawing.importId,
        minOutsideAngle: null,
        minInsideAngle: null

        // possiblePerforationZones: [state.drawing.plate.shapeWithHoles],
      };
      const elements = state.productConfiguration.elements || [];
      element.position = elements.length + 1;
      const newState: MevacoPage = {
        ...state,
        productConfiguration: {
          ...state.productConfiguration,
          elements: [...elements, element]
        }
      };
      return editPosition(newState, elements.length + 1, Step.shape);
    }
    case UPDATE_DRAWING_FAIL: {
      const newState: MevacoPage = {
        ...state
      };
      const payload: UpdateDrawingFailParams = action.payload;
      newState.message = {
        message: payload.error,
        type: MessageType.ERROR
      };
      newState.drawing = payload.oldDrawing;
      newState.drawing = updateDrawingShapes(newState.drawing, payload.oldDrawing.plate.shapeWithHoles, payload.oldDrawing.plate.perforationAreas);
      newState.drawing = {
        ...newState.drawing,
        selectedMountings: [],
        selectedNodes: [],
        selectedMeasurementId: null,
        selectedHelpLineId: null,
        selectedAngleMeasurementId: null
      };
      return newState;
    }

    case OPEN_SUBPANEL: {
      const newState: MevacoPage = {
        ...state
      };
      newState.rightPanel = {
        ...newState.rightPanel,
        [action.payload.name]: {
          ...newState.rightPanel[action.payload.name],
          state: SubpanelCurrentState.OPENED
        }
      };
      return newState;
    }

    case CLOSE_SUBPANEL: {
      const newState: MevacoPage = {
        ...state
      };
      newState.rightPanel = {
        ...newState.rightPanel,
        [action.payload.name]: {
          ...newState.rightPanel[action.payload.name],
          state: SubpanelCurrentState.CLOSED
        }
      };
      return newState;
    }

    case SET_SUBPANEL_STATE: {
      const newState: MevacoPage = {
        ...state
      };
      if(newState.rightPanel[action.payload.name].state === action.payload.state) return newState;
      const otherOpenedPanels = {...newState.rightPanel};
      const chosenSubpanel = {...newState.rightPanel[action.payload.name]};
      delete otherOpenedPanels[action.payload.name];
      let right = action.payload.state == SubpanelCurrentState.OPENED ? 0 : -newState.rightPanel[action.payload.name].width;
      for (const [key, value] of Object.entries(otherOpenedPanels)) {
        if (value.state !== SubpanelCurrentState.OPENED) {
          continue;
        }
        const newPanelProps = {...value};

        if (action.payload.state === SubpanelCurrentState.OPENED) {
          if(chosenSubpanel.order >= value.order){
            right += newPanelProps.width;
          }
          else{
            newPanelProps.position.right += newState.rightPanel[action.payload.name].width;
            right += 0;
          }
        } else {
          if (newPanelProps.position.right > newState.rightPanel[action.payload.name].position.right) {
            newPanelProps.position.right = newPanelProps.position.right -  newState.rightPanel[action.payload.name].width;
          }
        }

        otherOpenedPanels[key] = newPanelProps;
      }

      newState.rightPanel = {
        ...otherOpenedPanels,
        [action.payload.name]: {
          ...newState.rightPanel[action.payload.name],
          position: {
            ...newState.rightPanel[action.payload.name].position,
            right: right
          },
          state: action.payload.state
        }
      };
      return newState;
    }

    case SET_SUBPANEL_POSITION: {
      const newState: MevacoPage = {
        ...state
      };
      newState.rightPanel = {
        ...newState.rightPanel,
        [action.payload.name]: {
          ...newState.rightPanel[action.payload.name],
          position: {
            ...newState.rightPanel[action.payload.name].position,
            ...action.payload.position
          }
        }
      };
      return newState;
    }

    case SET_SUBPANEL_WIDTH: {
      const newState: MevacoPage = {
        ...state
      };
      newState.rightPanel = {
        ...newState.rightPanel,
        [action.payload.name]: {
          ...newState.rightPanel[action.payload.name],
          width: action.payload.width
        }
      };
      return newState;
    }



    default: {
      let newState = {...state};
      newState.userTemplates = userTemplatesReducer(state.userTemplates, action as UserTemplateActions);
      newState.userPatterns = userPatternsReducer(state.userPatterns, action as UserPatternActions);

      newState.configurableTemplates = configurableTemplatesReducer(state.configurableTemplates, action as ConfigurableTemplatesActions);
      newState.lastPerforationResponse = perforationReducer(state.lastPerforationResponse, action);
      newState.drawing = drawingReducer(state.drawing, action);
      const showPlate = state.productConfiguration.productType === ProductType.PerforatedSheets ||
        (state.productConfiguration.productType === ProductType.ExtendedMetals && (state.productConfiguration.step !== Step.design));
      if (newState.drawing.plate.show !== showPlate) {
        newState.drawing = {
          ...newState.drawing,
          plate : {
            ...newState.drawing.plate,
            show: showPlate
          }
        };
      }
      if ( action.type !== PRODUCT_CONFIGURATION_LOADED_SUCCESSFULLY) {
        updateDependentMode(newState, state);
      }
      newState.productConfiguration = productConfigurationReducer(
        newState.productConfiguration,
        newState.dataset,
        newState,
        action as ProductConfigurationActions);
      if ( lastOfActions([PRODUCT_CONFIGURATION_LOADED_SUCCESSFULLY, DATASET_LOADED_SUCCESSFULLY, LOAD_MOUNTINGS_SUCCESS], action.type) &&
        [Step.shape, Step.pattern, Step.attachment, Step.design].indexOf(newState.productConfiguration.step) !== -1 ) {
        newState = editPosition(newState, newState.productConfiguration.currentPosition, newState.productConfiguration.step);

      }
      if (action.type !== PRODUCT_CONFIGURATION_LOADED_SUCCESSFULLY && action.type !== DUPLICATE_CONFIGURATION_SUCCESS) {
        removeBendedElementsIfMaterialWasChange(newState, state.productConfiguration.material);
      }

      newState.dataset = datasetReducer(newState.dataset, action as DatasetActions);
      if (action.type === DATASET_LOADED_SUCCESSFULLY) {
        newState.toleranceLookup = initToleranceLookup(newState.dataset.tolerance, newState.dataset.materials, newState.toleranceLookup);
      }
      if (
        (
          newState.productConfiguration.perforation !== state.productConfiguration.perforation ||
          newState.productConfiguration.wallpaper !== state.productConfiguration.wallpaper ||
          newState.productConfiguration.activePerforation !== state.productConfiguration.activePerforation ||
          newState.productConfiguration.customPerforation !== state.productConfiguration.customPerforation ||
          newState.productConfiguration.multiSizePattern !== state.productConfiguration.multiSizePattern ||
          newState.productConfiguration.effect !== state.productConfiguration.effect ||
          newState.productConfiguration.fading !== state.productConfiguration.fading ||
          newState.productConfiguration.perfaction !== state.productConfiguration.perfaction
        )
        && action.type !== PRODUCT_CONFIGURATION_LOADED_SUCCESSFULLY) {
        newState = updateAllElementsPreview(newState);
      }
      if (action.type === SET_HEX_COLOR_STRING) {
        newState = updateAllElementsPreview(newState);
      }
      if (action.type === ADD_OFFSET_TO_PLATE_PEFRACTION) {
        const element = state.productConfiguration.elements[action.payload.plateIndex];
        if (!newState.positionsToReload.includes(element.position)) {
          newState.positionsToReload = [...newState.positionsToReload, element.position];
        }
      }

      if (action.type === PRODUCT_CONFIGURATION_LOADED_SUCCESSFULLY) {
        // newState.productConfiguration.step = Step.cockpit;
        const elements: Element[] = [];
        let i = 1;
        for (const element of newState.productConfiguration.elements) {
          if (!!element && !!element.shape && !!element.shape.conture && element.shape.conture.length > 0) {
            elements.push({...element, position: i++});
          }
        }
        newState.productConfiguration.elements = elements;
      }
      if (newState.productConfiguration.step !== Step.import &&
        newState.productConfiguration.step !== Step.import_pattern &&
        newState.productConfiguration.step !== Step.import_attachment) {
        if (action.type === SET_PLATE) {
          const newHash = shapeWithHolesHash(newState.drawing.plate.shapeWithHoles);
          let error = 'Nothing Changed';
          let type = MessageType.ERROR;
          if (newHash !== state.drawing.plate.shapeWithHoles.hash) {
            error = 'Plate Shape Updated';
            type = MessageType.SUCCESS;
          }
          newState.message = {
            message: error,
            type: type
          };
          newState.drawing.plate.shapeWithHoles.hash = newHash;
        }
        if (action.type === SET_PERFORATION_AREA) {
          let newHash = 0;
          for (const pA of newState.drawing.plate.perforationAreas) {
            pA.shape.hash = shapeWithHolesHash(pA.shape);
            newHash += parseInt(pA.shape.hash, 16);
          }
          let oldHash = 0;
          for (const pA of state.drawing.plate.perforationAreas) {
            if (pA.shape.hash) {
              oldHash += parseInt(pA.shape.hash, 16);
            }
          }
          let error = 'Nothing Changed';
          let type = MessageType.ERROR;
          if (newHash !== oldHash) {
            error = 'Perforation Updated';
            type = MessageType.SUCCESS;
          }
          newState.message = {
            message: error,
            type: type
          };
        }
        if (action.type === UPDATE_DRAWING) {
          let error = 'Nothing Changed';
          let type = MessageType.ERROR;
          const newPlateHash = shapeWithHolesHash(newState.drawing.plate.shapeWithHoles);
          let newPerforationHash = '';
          for (const pA of newState.drawing.plate.perforationAreas) {
            pA.shape.hash = shapeWithHolesHash(pA.shape);
            newPerforationHash += convertBase(pA.shape.hash, 16, 10);
          }
          let oldPerforationHash = '';
          for (const pA of state.drawing.plate.perforationAreas) {
            if (pA.shape.hash) {
              oldPerforationHash += convertBase(pA.shape.hash, 16, 10);
            }
          }
          if (newPlateHash !== state.drawing.plate.shapeWithHoles.hash && oldPerforationHash !== newPerforationHash) {
            error = 'Drawing updated';
            type = MessageType.SUCCESS;
          } else if (newPlateHash !== state.drawing.plate.shapeWithHoles.hash) {
            error = 'Plate Shape Updated';
            type = MessageType.SUCCESS;
          } else if (oldPerforationHash !== newPerforationHash) {
            error = 'Perforation Updated';
            type = MessageType.SUCCESS;
          }
          newState.drawing.plate.shapeWithHoles.hash = newPlateHash;
          newState.message = {
            message: error,
            type: type
          };
        }
      }
      // WHEN TO CLEAR REMOVED AUTOMATIC MEASUREMENTS
      if (action.type !== PRODUCT_CONFIGURATION_LOADED_SUCCESSFULLY &&
        newState.mode === AppMode.editor &&
        (newState.drawing.plate.shapeWithHoles !== state.drawing.plate.shapeWithHoles ||
          newState.drawing.plate.perforationAreas !== state.drawing.plate.perforationAreas)) {
        newState.drawing.removedMeasurements = [];
      }
      if (ELEMENT_UPDATE_TYPES.includes(action.type) && newState.mode === AppMode.editor) {
        newState = saveState(newState);
        newState.productConfiguration = updateTolerance(newState.productConfiguration, newState.toleranceLookup);
        updateDependentMode(newState, state);
      }
      if (action.type === PREVIEW_SUCCESS) {
        if (newState.positionsToReload.includes(action.payload)) {
          newState.positionsToReload = newState.positionsToReload.filter(x => x !== action.payload);
          newState.productConfiguration = {
            ...newState.productConfiguration,
            elements: [...newState.productConfiguration.elements]
          };
        }
      }
      // const newSizes = updateMaxSize(newState.productConfiguration.configuration, newState.productConfiguration.elements);
      // newState.productConfiguration.configuration = newSizes.configuration;
      // newState.productConfiguration.elements = newSizes.elements;
      // newState.productConfiguration.area = newSizes.elements.map(x => x.area * x.quantity).reduce((p, c) => p + c, 0);

      if (
        state.drawing === newState.drawing &&
        state.productConfiguration === newState.productConfiguration &&
        state.dataset === newState.dataset &&
        state.userTemplates === newState.userTemplates &&
        state.userPatterns === newState.userPatterns &&
        state.configurableTemplates === newState.configurableTemplates &&
        state.positionsToReload === newState.positionsToReload &&
        state.lastPerforationResponse === newState.lastPerforationResponse
      ) {
        return state;
      } else {
        return newState;
      }
    }
    case SHOW_CUSTOM_PATTERN_EDITOR: {
      return {
        ...state,
        customPatternEditorOpened: action.payload
      };
    }
    case SET_PERFACTION_EDITOR_STATE: {
      return {
        ...state,
        perfactionEditorIsOpened: action.payload
      };
    }
    case TOGGLE_CUSTOM_PATTERN_SUCCEEDED:
      return action.payload;
      /*
      let perforation = state.productConfiguration.perforation;
      let customPatternEditorOpened = state.customPatternEditorOpened;
      const previousValue = state.productConfiguration.customPerforation;
      if ( !previousValue && (!perforation.custom || !perforation.custom.stumps.some( stamp => !!stamp))) {
        perforation = {
          ...perforation,
          custom: createPerforationCustomData(state.productConfiguration, state.dataset)
        };
        customPatternEditorOpened = true;
      }
      return {
        ...state,
        productConfiguration: {
          ...state.productConfiguration,
          customPerforation: !previousValue,
          perforation
        },
        customPatternEditorOpened
      };*/
    case SET_SELECTED_CUSTOM_STAMP: {
      return {
        ...state,
        selectedCustomStamp: action.payload
      };
    }

    case ENABLE_CUSTOM_STAMP: {
      const {changedState, newStamp} = changeCustomStampProperty(state.productConfiguration, action.payload);
      newStamp.enabled = !newStamp.enabled;
      return {
        ...state,
        productConfiguration: changedState,
        selectedCustomStamp: newStamp.enabled ? action.payload : state.selectedCustomStamp
      };
    }

    case BLOCK_USER: {
      return {
        ...state,
        blockUser: action.payload,
      };
    }

    case LOAD_GLTF: {
      const gltfs = {
        ...state.gltfs,
      };
      gltfs[action.payload] = null;
      return {
        ...state,
        gltfs
      };
    }

    case GLTF_LADED: {
      const gltfs = {
        ...state.gltfs,
      };
      gltfs[action.payload] = true;
      return {
        ...state,
        gltfs
      };
    }
  }

}

function convertBase(bigint, inputBase, outputBase) {
  // takes a bigint string and converts to different base
  let inputValues = parseBigInt(bigint, inputBase),
    outputValues = [], // output array, little-endian/lsd order
    remainder,
    len = inputValues.length,
    pos = 0,
    i;
  while (pos < len) { // while digits left in input array
    remainder = 0; // set remainder to 0
    for (i = pos; i < len; i++) {
      // long integer division of input values divided by output base
      // remainder is added to output array
      remainder = inputValues[i] + remainder * inputBase;
      inputValues[i] = Math.floor(remainder / outputBase);
      remainder -= inputValues[i] * outputBase;
      if (inputValues[i] === 0 && i === pos) {
        pos++;
      }
    }
    outputValues.push(remainder);
  }
  outputValues.reverse(); // transform to big-endian/msd order
  return formatBigInt(outputValues, outputBase);
}

function parseBigInt(bigint, base) {
  // convert bigint string to array of digit values
  const values = [];
  for (let i = 0; i < bigint.length; i++) {
    values[i] = parseInt(bigint.charAt(i), base);
  }
  return values;
}

function formatBigInt(values, base) {
  // convert array of digit values to bigint string
  let bigint = '';
  for (let i = 0; i < values.length; i++) {
    bigint += values[i].toString(base);
  }
  return bigint;
}

function updateAllElementsPreview(state: MevacoPage): MevacoPage {
  const elements = state.productConfiguration.elements;
  const toPreview = [];
  for (let i = 0; i < elements.length; i++) {
    toPreview.push(elements[i].position);
  }
  return {
    ...state,
    positionsToReload: toPreview
  };
}

function editPosition(state: MevacoPage, position: number, step: Step): MevacoPage {
  const element: Element = state.productConfiguration.elements[position - 1];
  if (!element) {
    return state;
  }
  const newState: MevacoPage = {...state};

  const plateWidth = element.b / 1000;
  const plateHeight = element.a / 1000;
  let plateDepth = 0.001;
  if (state.productConfiguration.material && state.productConfiguration.material.thickness) {
    plateDepth = (+state.productConfiguration.material.thickness) / 1000;
  }
  let nodes = [];
  const topLeftNode = createNode({x: 0, y: plateHeight, z: -plateDepth / 2}, ShapeOrigin.SHAPE);
  const bottomLeftNode = createNode({x: 0, y: 0, z: -plateDepth / 2}, ShapeOrigin.SHAPE);
  const topRightNode = createNode({x: plateWidth, y: plateHeight, z: -plateDepth / 2}, ShapeOrigin.SHAPE);
  const bottomRightNode = createNode({x: plateWidth, y: 0, z: -plateDepth / 2}, ShapeOrigin.SHAPE);
  if (element.nodes) {
    nodes = element.nodes;
  } else {
    nodes = [bottomLeftNode, bottomRightNode, topRightNode, topLeftNode];
  }
  let shape: ShapeWithHoles = {
    conture:
      [
        {
          begin: bottomLeftNode.position,
          end: bottomRightNode.position,
          type: SegmentType.line
        },
        {
          begin: bottomRightNode.position,
          end: topRightNode.position,
          type: SegmentType.line
        },
        {
          begin: topRightNode.position,
          end: topLeftNode.position,
          type: SegmentType.line
        },
        {
          begin: topLeftNode.position,
          end: bottomLeftNode.position,
          type: SegmentType.line
        }
      ]
    ,
    holes: []
  };
  if (element.shape) {
    shape = element.shape;
  }
  let perforationAreas: PerforationAreaModel[] = [];
  if (element.perforationAreas) {
    perforationAreas = element.perforationAreas;
  }
  const measurements = new Map<number, MeasurementModel>();
  if (element.measurements) {
    for (let i = 0; i < element.measurements.length; i++) {
      measurements.set(i, {
        ...element.measurements[i], exchange: {
          ...element.measurements[i].exchange,
          onInputLive: null, // to be set by tool
          onInputConfirmed: null, // to be set by tool
          inputValidation: (val) => val !== '' && !isNaN(+val),

          fromModel: (value: number) => (value * 1000).toFixed(1),
          toModel: (value: string) => (+value / 1000)
        }
      });
    }
  }
  const angleMeasurements = new Map<number, AngleMeasurementModel>();
  if (element.angleMeasurements) {
    for (let i = 0; i < element.angleMeasurements.length; i++) {
      angleMeasurements.set(i, {
        ...element.angleMeasurements[i], exchange: {
          ...element.angleMeasurements[i].exchange,
          onInputLive: null, // to be set by tool
          onInputConfirmed: null, // to be set by tool
          inputValidation: (val) => val !== '' && !isNaN(+val),

          fromModel: (value: number) => (value * 180 / Math.PI).toFixed(2),
          toModel: (value: string) => (+value / 180 * Math.PI)
        }
      });
    }
  }
  const removedMeasurements: MeasurementModel[] = element.removedMeasurements ? element.removedMeasurements.map(x => {
    return {
      ...x,
      exchange: {
        ...x.exchange,
        onInputLive: null, // to be set by tool
        onInputConfirmed: null, // to be set by tool
        inputValidation: (val) => val !== '' && !isNaN(+val),

        fromModel: (value: number) => (value * 1000).toFixed(1),
        toModel: (value: string) => (+value / 1000)
      }
    };
  }) : [];
  const helpLines = new Map<number, HelpLine>();
  if (element.helpLines) {
    for (let i = 0; i < element.helpLines.length; i++) {
      helpLines.set(i, element.helpLines[i]);
    }
  }
  const mountings: MountingAttachment[] = [];
  if (element.mountings) {
    if (state.mountings && state.mountings.length > 0 && state.drawing.mountingsModel.verticesMap.size > 0) {
      for (let i = 0; i < element.mountings.length; i++) {
        mountings[i] = {
          mountingRef: state.mountings[state.mountings.findIndex((v) => v.id === element.mountings[i].id)],
          vertices: state.drawing.mountingsModel.verticesMap.get(state.mountings[state.mountings.findIndex((v) => v.id === element.mountings[i].id)].form),
          shape: state.drawing.mountingsModel.shapeMap.get(state.mountings[state.mountings.findIndex((v) => v.id === element.mountings[i].id)].form),
          position: element.mountings[i].position,
          rotation: element.mountings[i].rotation,
          possible: state.mountings[state.mountings.findIndex((v) => v.id === element.mountings[i].id)].materialCode === state.productConfiguration.configuration.material.materialcodeLomoe
        };
      }
    } else {
      for (let i = 0; i < element.mountings.length; i++) {
        mountings[i] = {
          mountingRef: {
            ...initialMounting,
            id: element.mountings[i].id,
          },
          vertices: [],
          shape: [],
          position: element.mountings[i].position,
          rotation: element.mountings[i].rotation,
          possible: state.mountings[state.mountings.findIndex((v) => v.id === element.mountings[i].id)].materialCode === state.productConfiguration.configuration.material.materialcodeLomoe
        };
      }
    }
  }
  const snapOptions = getDefaultSnapOptionsForStep(state.productConfiguration.step, step, newState.drawing.snapOptions);
  newState.productConfiguration = {
    ...newState.productConfiguration,
    step: step
  };

  let paintHex = '';
  const system = newState.productConfiguration.surface.colorSystem;
  if (!!system) {
    const colorId = Number(newState.productConfiguration.surface.color);
    if (!!colorId) {
      paintHex = newState.dataset.paints.find((paint) => paint.system === system && paint.paintId === colorId).hexColorCode;
    }
  }
  if (!paintHex) {
    paintHex = '';
  }
  let defaultPerforationAreaOffset = null;
  if (perforationAreas && perforationAreas.length > 0) {
    defaultPerforationAreaOffset = perforationAreas[0].offset;
  }

  newState.drawing = {
    ...state.drawing,
    lastDrawnLineId: -1,
    helpingLines: helpLines,
    snapOptions: snapOptions,
    selectedMountings: [],
    selectedNodes: [],
    importedShape: [],
    automaticMeasurements: new Map<number, MeasurementModel>(),
    removedMeasurements: removedMeasurements,
    plate: {
      ...state.drawing.plate,
      defaultPerforationAreaOffset: defaultPerforationAreaOffset,
      position: position,
      height: element.a,
      width: element.b,
      depth: plateDepth,
      currentLineShapeId: -1,
      shapeWithHoles: shape,
      perforationAreas: perforationAreas,
      mountingHoles: mountings,
      autoCenter: !!element.perforationAutoCenter,
      hexColorString: paintHex,
      bendingLines: element.breakLines ? element.breakLines : [],
      bendingLinesDistances: element.bendingLinesDistances || [],
      boundary : element.boundary || [],

      // possiblePerforationZones: element.possiblePerforationZones ? element.possiblePerforationZones : [shape]
    },
    nodes: nodes,
    measurements: measurements,
    angleMeasurements: angleMeasurements,
    dependentMode: state.productConfiguration.perforation.dependentMode
  };
  newState.drawing = updateDrawingShapes(newState.drawing, shape, perforationAreas);
  newState.drawing.fillPerforation = step === Step.pattern;
  newState.drawing.showPerforationBorder = step === Step.pattern && newState.productConfiguration.activePerforation === 'classic';
  const id = position - 1;
  const newElement = {...newState.productConfiguration.elements[id]};
  newElement.nodes = newState.drawing.nodes;
  newElement.shape = newState.drawing.plate.shapeWithHoles;
  newElement.perforationAreas = newState.drawing.plate.perforationAreas;
  newElement.helpLines = Array.from(newState.drawing.helpingLines.values());
  newElement.perforationAutoCenter = newState.drawing.plate.autoCenter;
  newElement.measurements = Array.from(newState.drawing.measurements.values()).map((value) => {
    return {
      ...value,
      exchange: {
        ...value.exchange,
        onInputConfirmed: null,
        fromModel: null,
        toModel: null,
        inputValidation: null,
        onInputLive: null
      }
    };
  });
  newElement.angleMeasurements = Array.from(newState.drawing.angleMeasurements.values()).map((value) => {
    return {
      ...value,
      exchange: {
        ...value.exchange,
        onInputConfirmed: null,
        fromModel: null,
        toModel: null,
        inputValidation: null,
        onInputLive: null
      }
    };
  });
  // newElement.perforation = {
  //   ...newState.drawing.perforation,
  //   perforation: [...newState.drawing.perforation.perforation]
  // };
  const elements = [...newState.productConfiguration.elements];
  elements[id] = newElement;
  newState.productConfiguration = {
    ...newState.productConfiguration,
    currentPosition: position,
    elements: elements
  };
  return newState;
}

function showViewerElement(state: MevacoPage, position: number): MevacoPage {
  const element: Element = state.productConfiguration.elements[position - 1];
  const newState: MevacoPage = {...state};
  let plateDepth = 0.001;
  if (state.productConfiguration.material && state.productConfiguration.material.thickness) {
    plateDepth = (+state.productConfiguration.material.thickness) / 1000;
  }

  const nodes = element.nodes;
  const shape = element.shape;
  let perforationAreas: PerforationAreaModel[] = [];
  if (element.perforationAreas) {
    perforationAreas = element.perforationAreas;
  }
  const measurements = new Map<number, MeasurementModel>();
  if (element.measurements) {
    for (let i = 0; i < element.measurements.length; i++) {
      measurements.set(i, {
        ...element.measurements[i], exchange: {
          ...element.measurements[i].exchange,
          onInputLive: null, // to be set by tool
          onInputConfirmed: null, // to be set by tool
          inputValidation: (val) => val !== '' && !isNaN(+val),

          fromModel: (value: number) => (value * 1000).toFixed(1),
          toModel: (value: string) => (+value / 1000)
        }
      });
    }
  }
  const angleMeasurements = new Map<number, AngleMeasurementModel>();
  if (element.angleMeasurements) {
    for (let i = 0; i < element.angleMeasurements.length; i++) {
      angleMeasurements.set(i, {
        ...element.angleMeasurements[i], exchange: {
          ...element.angleMeasurements[i].exchange,
          onInputLive: null, // to be set by tool
          onInputConfirmed: null, // to be set by tool
          inputValidation: (val) => val !== '' && !isNaN(+val),

          fromModel: (value: number) => (value * 180 / Math.PI).toFixed(2),
          toModel: (value: string) => (+value / 180 * Math.PI)
        }
      });
    }
  }
  const removedMeasurements: MeasurementModel[] = element.removedMeasurements ? element.removedMeasurements.map(x => {
    return {
      ...x,
      exchange: {
        ...x.exchange,
        onInputLive: null, // to be set by tool
        onInputConfirmed: null, // to be set by tool
        inputValidation: (val) => val !== '' && !isNaN(+val),

        fromModel: (value: number) => (value * 1000).toFixed(1),
        toModel: (value: string) => (+value / 1000)
      }
    };
  }) : [];
  const helpLines = new Map<number, HelpLine>();
  if (element.helpLines) {
    for (let i = 0; i < element.helpLines.length; i++) {
      helpLines.set(i, element.helpLines[i]);
    }
  }
  const mountings: MountingAttachment[] = [];
  if (element.mountings) {
    if (state.mountings && state.mountings.length > 0 && state.drawing.mountingsModel.verticesMap.size > 0) {
      for (let i = 0; i < element.mountings.length; i++) {
        mountings[i] = {
          mountingRef: state.mountings[state.mountings.findIndex((v) => v.id === element.mountings[i].id)],
          vertices: state.drawing.mountingsModel.verticesMap.get(state.mountings[state.mountings.findIndex((v) => v.id === element.mountings[i].id)].form),
          shape: state.drawing.mountingsModel.shapeMap.get(state.mountings[state.mountings.findIndex((v) => v.id === element.mountings[i].id)].form),
          position: element.mountings[i].position,
          rotation: element.mountings[i].rotation,
          possible: state.mountings[state.mountings.findIndex((v) => v.id === element.mountings[i].id)].materialCode === state.productConfiguration.configuration.material.materialcodeLomoe
        };
      }
    } else {
      for (let i = 0; i < element.mountings.length; i++) {
        mountings[i] = {
          mountingRef: {
            ...initialMounting,
            id: element.mountings[i].id,
          },
          vertices: [],
          shape: [],
          position: element.mountings[i].position,
          rotation: element.mountings[i].rotation,
          possible: state.mountings[state.mountings.findIndex((v) => v.id === element.mountings[i].id)].materialCode === state.productConfiguration.configuration.material.materialcodeLomoe
        };
      }
    }
  }

  newState.productConfiguration = {
    ...newState.productConfiguration,
    step: Step.design
  };
  let paintHex = '';
  const system = newState.productConfiguration.surface.colorSystem;
  if (!!system) {
    const colorId = Number(newState.productConfiguration.surface.color);
    if (!!colorId) {
      paintHex = newState.dataset.paints.find((paint) => paint.system === system && paint.paintId === colorId).hexColorCode;
    }
  }
  if (!paintHex) {
    paintHex = '';
  }
  newState.drawing = {
    ...state.drawing,
    lastDrawnLineId: -1,
    helpingLines: helpLines,
    snapOptions: getDefaultSnapOptionsForStep(state.productConfiguration.step, Step.design, newState.drawing.snapOptions),
    selectedMountings: [],
    selectedNodes: [],
    importedShape: [],
    automaticMeasurements: new Map<number, MeasurementModel>(),
    removedMeasurements: removedMeasurements,
    plate: {
      ...state.drawing.plate,
      position: position,
      height: element.a,
      width: element.b,
      depth: plateDepth,
      currentLineShapeId: -1,
      shapeWithHoles: shape,
      perforationAreas: perforationAreas,
      mountingHoles: mountings,
      autoCenter: !!element.perforationAutoCenter,
      hexColorString: paintHex,
      bendingLines: element.breakLines,
      boundary: element.boundary || []
    },
    hint: {
      ...state.drawing.hint,
      label: {
        ...state.drawing.hint.label,
        visible: false
      },
      status: HintStatus.Disabled
    },
    nodes: nodes,
    measurements: measurements,
    angleMeasurements: angleMeasurements,
    dependentMode: state.productConfiguration.perforation.dependentMode
  };

  newState.drawing = updateDrawingShapes(newState.drawing, shape, perforationAreas);
  return newState;
}

function saveState(state: MevacoPage = initialMevacoPage) {
  const newState = {...state};
  const id = newState.customPatternEditorOpened ? -1 : newState.drawing.plate.position - 1;
  if (newState.productConfiguration.elements.length > 0 && !!newState.productConfiguration.elements[id] && newState.productConfiguration.step !== Step.cockpit) {
    const element: Element = {
      ...newState.productConfiguration.elements[id]};
    const conture = newState.drawing.plate.shapeWithHoles.conture;
    const aabb: Aabb2 = conture && conture.length > 0 ? aabbOfPolyline(newState.drawing.plate.shapeWithHoles.conture) : {
      min: {x: 0, y: 0},
      max: {x: 0, y: 0}
    };
    const size = subVectors2(aabb.max, aabb.min);
    element.aIst = element.a = round(Math.ceil( size.y * 100000 ) / 100000);
    element.bIst = element.b = round(Math.ceil( size.x * 100000 ) / 100000);

    element.nodes = newState.drawing.nodes.map((v) => {
      return {
        ...v,
        onChangeCallbacks: [],
      };
    });
    element.breakLines = newState.drawing.plate.bendingLines;
    element.boundary = newState.drawing.plate.boundary;
    element.bendingLinesDistances = newState.drawing.plate.bendingLinesDistances;
    element.shape = newState.drawing.plate.shapeWithHoles;
    element.perforationAreas = newState.drawing.plate.perforationAreas;
    element.helpLines = Array.from(newState.drawing.helpingLines.values());
    element.perforationAutoCenter = newState.drawing.plate.autoCenter;
    element.measurements = Array.from(newState.drawing.measurements.values()).map((value) => {
      return {
        ...value,
        exchange: {
          ...value.exchange,
          onInputConfirmed: null,
          fromModel: null,
          toModel: null,
          inputValidation: null,
          onInputLive: null
        }
      };
    });
    element.angleMeasurements = Array.from(newState.drawing.angleMeasurements.values()).map((value) => {
      return {
        ...value,
        exchange: {
          ...value.exchange,
          onInputConfirmed: null,
          fromModel: null,
          toModel: null,
          inputValidation: null,
          onInputLive: null
        }
      };
    });
    element.mountings = newState.drawing.plate.mountingHoles.map((v) => {
      return {
        position: v.position,
        id: v.mountingRef.id,
        rotation: v.rotation
      };
    });
    if (newState.drawing.plate.shapeWithHoles.area) {
      element.area = newState.drawing.plate.shapeWithHoles.area;
    }
    // element.perforation = cloneObject(newState.drawing.perforation);
    element.minRadius = getMinimalRadiusOfShape(newState.drawing.plate.shapeWithHoles);
    element.minParallelEdgesDistance = getMinimalParallelEdgeDistance(newState.drawing.plate.shapeWithHoles);
    element.minMarginDistance = getMinMarginDistance(newState.lastPerforationResponse, newState.drawing.plate.shapeWithHoles, newState.drawing.plate.bendingLines);
    element.minDistanceBetweenPerforationAndBending = getMinDistanceBetweenPerforationAndBending(newState.lastPerforationResponse, newState.drawing.plate.bendingLines);
    element.minDistanceBetweenMountingAndBending = getMinDistanceBetweenMountingAndBending(newState.drawing.plate.mountingHoles, newState.drawing.plate.bendingLines);
    element.isShapeSimpleRect = areSimpleRectangles([newState.drawing.plate.shapeWithHoles]);
    element.isPerforationSimpleRect = areSimpleRectangles(newState.drawing.plate.perforationAreas.map((v) => v.shape));

    function roundToString(v: number): string {
      return (+((v * 1000).toFixed(2))).toString();
    }
    function round(v: number): number {
      return +((v * 1000).toFixed(2));
    }

   /* if (element.isPerforationSimpleRect && newState.drawing.plate.perforationAreas && newState.drawing.plate.perforationAreas.length > 0) {
      const perfAabb = aabbOfPolyline(newState.drawing.plate.perforationAreas[0].shape.conture);
      element.e1 = roundToString(perfAabb.min.y - aabb.min.y);
      element.e2 = roundToString(aabb.max.y - perfAabb.max.y);
      element.f1 = roundToString(perfAabb.min.x - aabb.min.x);
      element.f2 = roundToString(aabb.max.x - perfAabb.max.x);
      const realAabb = perforationAabb(newState.lastPerforationResponse);
      if (!isAabb2Empty(realAabb)) {
        element.e1ist = roundToString(realAabb.min.y - aabb.min.y);
        element.e2ist = roundToString(aabb.max.y - realAabb.max.y);
        element.f1ist = roundToString(realAabb.min.x - aabb.min.x);
        element.f2ist = roundToString(aabb.max.x - realAabb.max.x);
        element.lfbIst = roundToString(realAabb.max.y - realAabb.min.y);
        element.lflIst = roundToString(realAabb.max.x - realAabb.min.x);
      } else {
        element.e1ist = element.e2ist = element.e2 = undefined;
        element.f1ist = element.f2ist = element.f2 = undefined;
        element.lfbIst = undefined;
        element.lflIst = undefined;
      }
    } else {
      element.e1 = element.e1ist = element.e2 = element.e2ist = element.e2 = undefined;
      element.f1 = element.f1ist = element.f2 = element.f2ist = element.f2 = undefined;
      element.lfbIst = undefined;
      element.lflIst = undefined;
    }*/
    element.minMountingHolesDistance = getMinimalMountingHolesDistance(newState.drawing.plate.mountingHoles);
    element.minMountingHoleEdgeDistance = getMinimalMountingHolesEdgeDistance(newState.drawing.plate.mountingHoles, [newState.drawing.plate.shapeWithHoles]);
    element.minDistanceBetweenMountingAndPerforatedArea = getMinimalMountingHolesEdgeDistance(state.drawing.plate.mountingHoles, state.drawing.plate.perforationAreas.map(x => x.shape));
    element.minDistanceBetweenEdges = getMinimalDistanceBetweenShapeWithHolesEdges(state.drawing.plate.shapeWithHoles);
    element.numberOfArcs = getNumberOfArcsInShapeWithHoles(state.drawing.plate.shapeWithHoles);
    element.minDistanceBetweenPerforationAreas = getMinimalDistanceBetweenPerforationAreas(state.drawing.plate.perforationAreas.map(x => x.shape));
    element.unperforated = !state.drawing.plate.perforationAreas || state.drawing.plate.perforationAreas.length < 1;
    element.removedMeasurements = state.drawing.removedMeasurements;
    element.cutoutCount = state.drawing.plate.shapeWithHoles.holes.length;
    element.mountingCount = state.drawing.plate.mountingHoles.length;
    // element.minOutsideAngle = minAngle(newState.drawing.plate.shapeWithHoles, true);
    // element.minInsideAngle = minAngle(newState.drawing.plate.shapeWithHoles, false);
    element.minSegmentLength = getMinSegmentLength(state.drawing.plate.shapeWithHoles);
    // element.minLegLength = getMinLegLength(newState.drawing.plate.shapeWithHoles, newState.drawing.plate.bendingLines, newState.drawing.plate.depth);
    setElementLegsLength(element, newState.drawing.plate.depth);
    const elements = [...newState.productConfiguration.elements];
    elements[id] = element;
    if (!newState.positionsToReload.includes(element.position)) {
      newState.positionsToReload = [...newState.positionsToReload, element.position];
    }
    newState.productConfiguration = {
      ...newState.productConfiguration,
      elements: elements,
    };
  }
  return newState;
}


function getDefaultSnapOptionsForStep(prevStep: Step, step: Step, state: SnapOptions): SnapOptions {
  if (prevStep === Step.shape || prevStep === Step.pattern || prevStep === Step.attachment) {
    switch (step) {
      case Step.shape:
        return {
          ...state,
          edges: true,
          nodes: true,
          perforation: false,
          perforationCollider: false,
          import: false,
          mountingHolesOutline: true,
          bendingLines: true
        };
      case Step.pattern:
        return {
          ...state,
          edges: true,
          nodes: true,
          perforation: true,
          perforationArea: true,
          perforationCollider: false,
          import: false,
          mountingHolesOutline: true,
          bendingLines: true
        };
      case Step.attachment:
        return {
          ...state,
          edges: true,
          nodes: true,
          perforation: false,
          perforationArea: true,
          mountingHoles: true,
          perforationCollider: false,
          import: false,
          mountingHolesOutline: true,
          bendingLines: true
        };
      case Step.design:
        return {
          edges: false,
          grid: false,
          helpLines: false,
          nodes: false,
          measurements: true,
          perforation: true,
          perforationArea: false,
          mountingHoles: true,
          perforationCollider: false,
          import: false,
          mountingHolesOutline: false,
          bendingLines: false
        };
      case Step.import:
        return {
          edges: true,
          grid: false,
          helpLines: false,
          nodes: false,
          measurements: false,
          perforation: false,
          perforationArea: false,
          mountingHoles: false,
          perforationCollider: false,
          import: true,
          mountingHolesOutline: true,
          bendingLines: true
        };
      case Step.import_pattern:
        return {
          edges: true,
          grid: false,
          helpLines: false,
          nodes: true,
          measurements: false,
          perforation: false,
          perforationArea: true,
          mountingHoles: false,
          perforationCollider: false,
          import: true,
          mountingHolesOutline: true,
          bendingLines: true
        };
      case Step.import_attachment:
        return {
          edges: true,
          grid: false,
          helpLines: true,
          nodes: true,
          measurements: true,
          perforation: false,
          perforationArea: true,
          mountingHoles: true,
          perforationCollider: false,
          import: true,
          mountingHolesOutline: true,
          bendingLines: true
        };
      default:
        return {
          edges: false,
          grid: false,
          helpLines: false,
          nodes: false,
          measurements: false,
          perforation: false,
          perforationArea: false,
          mountingHoles: false,
          perforationCollider: false,
          import: false,
          mountingHolesOutline: false,
          bendingLines: false
        };
    }
  } else {
    switch (step) {
      case Step.shape:
        return {
          ...state,
          edges: true,
          grid: true,
          helpLines: true,
          nodes: true,
          measurements: true,
          perforation: false,
          perforationArea: false,
          mountingHoles: false,
          perforationCollider: false,
          import: false,
          mountingHolesOutline: true,
          bendingLines: true
        };
      case Step.pattern:
        return {
          ...state,
          edges: true,
          grid: true,
          helpLines: true,
          nodes: true,
          measurements: true,
          perforation: true,
          perforationArea: true,
          mountingHoles: false,
          perforationCollider: false,
          import: false,
          mountingHolesOutline: true,
          bendingLines: true
        };
      case Step.attachment:
        return {
          ...state,
          edges: true,
          grid: true,
          helpLines: true,
          nodes: true,
          measurements: true,
          perforation: false,
          perforationArea: true,
          mountingHoles: true,
          perforationCollider: false,
          import: false,
          mountingHolesOutline: true,
          bendingLines: true
        };
      case Step.design:
        return {
          edges: false,
          grid: false,
          helpLines: false,
          nodes: false,
          measurements: true,
          perforation: true,
          perforationArea: false,
          mountingHoles: true,
          perforationCollider: false,
          import: false,
          mountingHolesOutline: false,
          bendingLines: false
        };
      case Step.import:
        return {
          edges: true,
          grid: false,
          helpLines: false,
          nodes: false,
          measurements: false,
          perforation: false,
          perforationArea: false,
          mountingHoles: false,
          perforationCollider: false,
          import: true,
          mountingHolesOutline: true,
          bendingLines: true
        };
      case Step.import_pattern:
        return {
          edges: true,
          grid: false,
          helpLines: false,
          nodes: true,
          measurements: false,
          perforation: false,
          perforationArea: true,
          mountingHoles: false,
          perforationCollider: false,
          import: true,
          mountingHolesOutline: true,
          bendingLines: true
        };
      case Step.import_attachment:
        return {
          edges: true,
          grid: false,
          helpLines: true,
          nodes: true,
          measurements: true,
          perforation: false,
          perforationArea: true,
          mountingHoles: true,
          perforationCollider: false,
          import: true,
          mountingHolesOutline: true,
          bendingLines: true
        };
      default:
        return {
          edges: false,
          grid: false,
          helpLines: false,
          nodes: false,
          measurements: false,
          perforation: false,
          perforationArea: false,
          mountingHoles: false,
          perforationCollider: false,
          import: false,
          mountingHolesOutline: true,
          bendingLines: false
        };
    }
  }
}

interface Output {
  min: number;
  result: MinValue;
}

function getMinMarginDistance(perforation: PerforationModel, shapeWithHoles: ShapeWithHoles, bendingLines: BendingLine[]): MinValue {
  const output: Output = {
    min: Number.POSITIVE_INFINITY,
    result: null
  };
  if (
    perforation && perforation.perforation && perforation.perforation.length > 0
    && shapeWithHoles && shapeWithHoles.conture && shapeWithHoles.conture.length > 0
  ) {

    for (const bending of bendingLines) {
      const segment: Segment = {
        type: SegmentType.line,
        begin: bending.begin,
        end: bending.end
      };
      tryMinMargin(segment, perforation.perforation, output, bending.bentParams.ossb - bending.bentParams.bendAllowance / 2);
    }

    for (const segment of shapeWithHoles.conture) {
      tryMinMargin(segment, perforation.perforation, output);
    }
    for (const hole of shapeWithHoles.holes) {
      for (const segment of hole) {
        tryMinMargin(segment, perforation.perforation, output);
      }
    }
  }
  return output.result;
}

function getMinDistanceBetweenPerforationAndBending(perforation: PerforationModel, bendings: BendingLine[]): MinValue {
  const output: Output = {
    min: Number.POSITIVE_INFINITY,
    result: null
  };
  if (
    perforation && perforation.perforation && perforation.perforation.length > 0
    && bendings && bendings.length > 0
  ) {
    for (const bending of bendings) {
      const segment: Segment = {
        type: SegmentType.line,
        begin: bending.begin,
        end: bending.end
      };
      tryMinMargin(segment, perforation.perforation, output, bending.bentParams.ossb - bending.bentParams.bendAllowance / 2);
    }
  }
  return output.result;
}

function getMinDistanceBetweenMountingAndBending(mountings: MountingAttachment[], bendings: BendingLine[]): MinValue {
  const output: Output = {
    min: Number.POSITIVE_INFINITY,
    result: null
  };

  for (const bending of bendings) {
    const bendingSegment: Segment = {
      type: SegmentType.line,
      begin: bending.begin,
      end: bending.end
    };
    mountings.forEach((mounting) => {
      const p1 = scaleMounting(mounting.mountingRef,  mounting.shape, mounting.rotation, mounting.position);
      const closestDist = getShortestPathBetweenTwoPolylines(p1, [bendingSegment]);
      if (closestDist) {
        const dist = segmentLength(closestDist) * 1000;
        if ( dist < output.min ) {
          output.min = dist;
          output.result = {
            value: dist + (bending.bentParams.ossb - bending.bentParams.bendAllowance / 2) * 1000,
            position: closestDist.begin
          };
        }
      }
    });
  }
  return output.result;
}

function tryMinMargin(segment: Segment, perforations: Perforation[], output: Output, offset = 0) {
  let minSqrDist = Number.MAX_VALUE;
  let start: Vector2;
  let end: Vector2;
  for (const perforation of perforations) {
    let holeMinX = Number.MAX_VALUE;
    let holeMinY = Number.MAX_VALUE;
    let holeMaxX = -Number.MAX_VALUE;
    let holeMaxY = -Number.MAX_VALUE;
    const polygons = getStampList(perforation, true)
    .reduce((acc, x) => acc.concat(x.polygons), []);
    for (const polygon of polygons) {
      for (const point of polygon) {
        holeMaxX = Math.max(point.x, holeMaxX);
        holeMaxY = Math.max(point.y, holeMaxY);
        holeMinX = Math.min(point.x, holeMinX);
        holeMinY = Math.min(point.y, holeMinY);
      }
    }

    for (const el of perforation.positions) {
      const minY = el.y + holeMinY;
      const maxY = el.y + holeMaxY;
      const minX = el.x + holeMinX;
      let maxX = el.x + holeMaxX;
      const count = (el as PositionsRow).count;
      if (count > 1) {
        maxX = el.x + (el as PositionsRow).dir.x * (count - 1) + holeMaxX;
      }
      const cos = Math.cos(perforation.rotation || 0);
      const sin = Math.sin(perforation.rotation || 0);

      let s: Vector2 = {x: minX, y: minY};
      if (perforation.rotation) {
        s = {
          x: cos * s.x - sin * s.y,
          y: sin * s.x + cos * s.y
        };
      }
      let p = projectPointOnSegment(s, segment);
      let d = p ? sqrDistanceVector2(p, s) + offset : Number.MAX_VALUE;
      if (d < minSqrDist) {
        minSqrDist = d;
        start = s;
        end = p;
      }

      s = {x: maxX, y: minY};
      if (perforation.rotation) {
        s = {
          x: cos * s.x - sin * s.y,
          y: sin * s.x + cos * s.y
        };
      }
      p = projectPointOnSegment(s, segment);
      d = p ? sqrDistanceVector2(p, s) + offset : Number.MAX_VALUE;
      if (d < minSqrDist) {
        minSqrDist = d;
        start = s;
        end = p;
      }

      s = {x: maxX, y: maxY};
      if (perforation.rotation) {
        s = {
          x: cos * s.x - sin * s.y,
          y: sin * s.x + cos * s.y
        };
      }
      p = projectPointOnSegment(s, segment);
      d = p ? sqrDistanceVector2(p, s) + offset : Number.MAX_VALUE;
      if (d < minSqrDist) {
        minSqrDist = d;
        start = s;
        end = p;
      }

      s = {x: minX, y: maxY};
      if (perforation.rotation) {
        s = {
          x: cos * s.x - sin * s.y,
          y: sin * s.x + cos * s.y
        };
      }
      p = projectPointOnSegment(s, segment);
      d = p ? sqrDistanceVector2(p, s) + offset : Number.MAX_VALUE;
      if (d < minSqrDist) {
        minSqrDist = d;
        start = s;
        end = p;
      }
    }
  }
  if (!!start && !!end) {
    const toEnd = subVectors2(end, start);
    const l = (lengthVector2(toEnd) + offset) * 1000;
    if (l < output.min) {
      output.min = l;
      output.result = {
        position: {x: (end.x + start.x) / 2, y: (end.y + start.y) / 2},
        value: l
      };
    }
  }
}

function getMinimalMountingHolesDistance(mountingHoles: MountingAttachment[]) {
  let min = Number.POSITIVE_INFINITY;
  let result: MinValue = null;
  for (let i = 0; i < mountingHoles.length; i++) {
    const m1 = mountingHoles[i];
    const m1Polyline = movePolyline(scaleMounting(m1.mountingRef, m1.shape, m1.rotation), m1.position);
    for (let j = i + 1; j < mountingHoles.length; j++) {
      const m2 = mountingHoles[j];
      const m2Polyline = movePolyline(scaleMounting(m2.mountingRef, m2.shape, m2.rotation), m2.position);
      const path = getShortestPathBetweenTwoPolylines(m1Polyline, m2Polyline);
      const length = lengthVector2(subVectors2(path.end, path.begin)) * 1000;
      if (length < min) {
        min = length;
        result = {
          value: length,
          position: {x: (path.begin.x + path.end.x) / 2, y: (path.begin.y + path.end.y) / 2}
        };
      }
    }
  }
  return result;
}

function getMinimalMountingHolesEdgeDistance(mountingHoles: MountingAttachment[], shapesWithHoles: ShapeWithHoles[]) {
  let min = Number.POSITIVE_INFINITY;
  let result: MinValue = null;
  for (const m of mountingHoles) {
    const m1Polyline = movePolyline(scaleMounting(m.mountingRef, m.shape, m.rotation), m.position);
    if (!!shapesWithHoles) {
      for (const shapeWithHoles of shapesWithHoles) {
        if (shapeWithHoles.conture) {
          for (const s of shapeWithHoles.conture) {
            const path = getShortestPathBetweenTwoPolylines(m1Polyline, [s]);
            const length = lengthVector2(subVectors2(path.end, path.begin)) * 1000;
            if (length < min) {
              min = length;
              result = {
                value: length,
                position: {x: (path.begin.x + path.end.x) / 2, y: (path.begin.y + path.end.y) / 2}
              };
            }
          }
        }
        if (shapeWithHoles.holes) {
          for (const hole of shapeWithHoles.holes) {
            for (const s of hole) {
              const path = getShortestPathBetweenTwoPolylines(m1Polyline, [s]);
              const length = lengthVector2(subVectors2(path.end, path.begin)) * 1000;
              if (length < min) {
                min = length;
                result = {
                  value: length,
                  position: {x: (path.begin.x + path.end.x) / 2, y: (path.begin.y + path.end.y) / 2}
                };
              }
            }
          }
        }
      }
    }
  }
  return result;
}

// function updateMaxSize(configuration: Configuration, elements: Element[]): { configuration: Configuration, elements: Element[] } {
//   const result: { configuration: Configuration, elements: Element[] } = {
//     configuration,
//     elements,
//   };
//   // Max width calculation
//   let maxCoilWidth = 0;
//   let maxCoilLength = 0;
//   let maxPlateWidth = 0;
//   let maxPlateLength = 0;
//
//   if (configuration.material && configuration.material.id !== 0) {
//     // if individual spacing
//     if (!(configuration.ewz.id !== 0 && configuration.lomoe.id === 0)) {
//       maxCoilWidth = Math.max(maxCoilWidth, Number(configuration.material.maxCoilWidth));
//       maxCoilLength = Math.max(maxCoilLength, Number(configuration.material.maxCoilLength));
//     }
//
//     maxPlateWidth = Math.max(maxPlateWidth, Number(configuration.material.maxPlateWidth));
//     maxPlateLength = Math.max(maxPlateLength, Number(configuration.material.maxPlateLength));
//   }
//
//   let found = false;
//   if (configuration.lomoe && configuration.lomoe.id !== 0) {
//     found = true;
//     const cw = Math.max(
//       Number(configuration.lomoe.coilMaxForomatV1 || 0),
//       Number(configuration.lomoe.coilMaxForomatV2 || 0));
//     maxCoilWidth = Math.min(maxCoilWidth, cw);
//
//     const pw = Math.max(
//       Number(configuration.lomoe.plateMaxForomatV2 || 0),
//       Number(configuration.lomoe.tcMaxFormat || 0));
//     maxPlateWidth = Math.min(maxPlateWidth, pw);
//     if (maxPlateWidth == 0) {
//       maxPlateLength = 0;
//     }
//     if (maxPlateWidth > 0) {
//       maxPlateLength = 2000;
//     }
//     if (maxPlateWidth > 1000) {
//       maxPlateLength = 2500;
//     }
//     if (maxPlateWidth > 1250) {
//       maxPlateLength = 3000;
//     }
//   }
//
//   const newMaxPlateWidth = !found && maxPlateWidth == 0 ? null : maxPlateWidth;
//   let newmaxPlateLength = !found && maxPlateWidth == 0 ? null : maxPlateLength;
//   const newMaxCoilWidth = !found && maxCoilWidth == 0 ? null : maxCoilWidth;
//   let newMaxCoilLength = !found && maxCoilWidth == 0 ? null : maxCoilLength;
//
//   if (newMaxCoilWidth === 0) {
//     newMaxCoilLength = 0;
//   }
//   if (newMaxPlateWidth === 0) {
//     newmaxPlateLength = 0;
//   }
//   const newMaxWidth = Math.max(configuration.maxPlateWidth, configuration.maxCoilWidth);
//   const newMaxLength = Math.max(configuration.maxPlateLength, configuration.maxCoilLength);
//
//   if (
//     configuration.maxPlateWidth !== newMaxPlateWidth ||
//     configuration.maxPlateLength !== newmaxPlateLength ||
//     configuration.maxCoilWidth !== newMaxCoilWidth ||
//     configuration.maxCoilLength !== newMaxCoilLength
//   ) {
//     result.configuration = {
//       ...configuration,
//       maxPlateWidth: newMaxPlateWidth,
//       maxPlateLength: newmaxPlateLength,
//       maxCoilWidth: newMaxCoilWidth,
//       maxCoilLength: newMaxCoilLength,
//       maxLength: newMaxLength,
//       maxWidth: newMaxWidth
//     };
//   }
//
//
//   configuration.maxWidth = Math.max(configuration.maxPlateWidth, configuration.maxCoilWidth);
//   configuration.maxLength = Math.max(configuration.maxPlateLength, configuration.maxCoilLength);
//
//   const newElements: Element[] = [];
//   for (let i = 0; i < elements.length; i++) {
//     const element = elements[i];
//     const width = element.a;
//     const length = element.b;
//     const newPosibleCoil = (maxCoilWidth >= width && maxCoilLength >= length) && !(configuration.ewz.id !== 0 && configuration.lomoe.id === 0) ? 'Yes' : 'No';
//     const newPosiblePlate = (maxPlateWidth >= width && maxPlateLength >= length) ? 'Yes' : 'No';
//     if (element.posibleCoil !== newPosibleCoil || element.posiblePlate !== newPosiblePlate) {
//       newElements[i] = {...element, posibleCoil: newPosibleCoil, posiblePlate: newPosiblePlate};
//       result.elements = newElements;
//     } else {
//       newElements[i] = element;
//     }
//   }
//
//   return result;
// }

function updateDependentMode(newState: MevacoPage, oldState: MevacoPage): void {
  if (newState.drawing.plate.position !== -1 && newState.productConfiguration.elements.length > 0) {


    const element: Element = newState.productConfiguration.elements[newState.drawing.plate.position - 1];
    const oldElement: Element = oldState.drawing.plate.position === -1 ? null : oldState.productConfiguration.elements[oldState.drawing.plate.position - 1];
    const needsUpdate: boolean = !oldElement || oldElement.perforationAreas.length !== element.perforationAreas.length || !!element.perforationAreas.find((np, i) => {
      const op = oldElement.perforationAreas[i];
      return op.rotation != np.rotation || op.shape != np.shape || op.offset != np.offset;
    });

    if (needsUpdate) {
      const perforationParams: PerforationAreaModel[] = element.perforationAreas.map(x => {
        return {
          shape: x.shape,
          offset: multiplyVector2byScalar(x.offset, 0.001),
          rotation: x.rotation * Math.PI / 180
        };
      });
      const newDependentMode = dependentMode(perforationParams);
      if (newDependentMode !== newState.productConfiguration.perforation.dependentMode) {
        newState.productConfiguration = {
          ...newState.productConfiguration,
          perforation: {
            ...newState.productConfiguration.perforation,
            dependentMode: newDependentMode
          }
        };
        newState.drawing = {
          ...newState.drawing,
          dependentMode: newDependentMode
        };
      }
    }
  }
}

function removeBendedElementsIfMaterialWasChange(newState: MevacoPage , oldMaterial: Material) {
  function isNotBended(element: Element): boolean {
    return !element.breakLines || element.breakLines.length === 0;
  }

  const newMaterial = newState.productConfiguration.material;

  if (newMaterial !== oldMaterial) {
    // look at getBendParmas to see what can affect bending parameters
    if (newMaterial.thickness !== oldMaterial.thickness || oldMaterial.quality !== newMaterial.quality) {

      const newElements = newState.productConfiguration.elements.filter( isNotBended );
      if ( newElements.length !== newState.productConfiguration.elements.length ) {

        // cleanup drawings
        newState.drawing = initialDrawing;
        newState.productConfiguration = {
          ...newState.productConfiguration,
          step: Step.cockpit,
          elements: newElements
        };
      }
    }
  }
}


// makes sure that all elements have uuid set
function elementUuidReducer(state: MevacoPage): MevacoPage {
  if (state.productConfiguration.elements.findIndex( element => !element.uuid) !== -1) {
    return {
      ...state,
      productConfiguration : {
        ...state.productConfiguration,
        elements: state.productConfiguration.elements.map( e => {
          return e.uuid ? e : {...e, uuid: Guid.create().toString()};
        })
      }
    };
  } else {
    return state;
  }
}

function customPatternMinDistance(state: MevacoPage, action: MevacoPageActions): MevacoPage {
  if (action.type === SET_PERFORATION_SUCCESS && state.customPatternEditorOpened) {
    let minDistance = Number.MAX_VALUE;
    const perforations: Perforation[] = action.payload.perforation;
    const polylines: Segment[][] = [];
    for (let i = 0; i < perforations.length; i++) {
      const perforation = perforations[i];
      for(const stamp of [perforation.stamp].concat(perforation.stamps).filter(x => !!x))
      {
        const stampPolylines = stamp.polylines;
        let position = {x: stamp.offsetX * stamp.startLfx / 1000, y: stamp.offsetY * stamp.startLfy / 1000};
        polylines.push(...stampPolylines.map( polyline => movePolyline(polyline, position)));
        position = {x: (stamp.offsetX * stamp.startLfx + stamp.offsetX) / 1000, y: stamp.offsetY * stamp.startLfy / 1000};
        polylines.push(...stampPolylines.map( polyline => movePolyline(polyline, position)));
        position = {x: (stamp.offsetX * stamp.startLfx + stamp.offsetX) / 1000, y: (stamp.offsetY * stamp.startLfy + stamp.offsetY) / 1000};
        polylines.push(...stampPolylines.map( polyline => movePolyline(polyline, position)));
        position = {x: (stamp.offsetX * stamp.startLfx) / 1000, y: (stamp.offsetY * stamp.startLfy + stamp.offsetY) / 1000};
        polylines.push(...stampPolylines.map( polyline => movePolyline(polyline, position)));
      }
    }
    for (let i = 0; i < polylines.length; i++) {
      const polyline1 = polylines[i];
      for (let j = i + 1; j < polylines.length; j++) {
        const polyline2 = polylines[j];
        const sp = getShortestPathBetweenTwoPolylines(polyline1, polyline2);
        if (sp) {
          minDistance = Math.min( segmentLength(sp), minDistance);
        }
      }
    }
    return {
      ...state,
      productConfiguration: {
        ...state.productConfiguration,
        configuration: {
          ...state.productConfiguration.configuration,
          minSpacing: roundDecimal( minDistance * 1000)
        }
      }
    };
  }
  return state;
}

const allActions: {[key: string]: boolean} = {};
function lastOfActions(actions: string[], actionType: string): boolean {
  allActions[actionType] = true;
  if (actions.indexOf(actionType) === -1) {
    return false;
  }
  for (let i = 0; i < actions.length; i++) {
    const action = actions[i];
    if ( !allActions[action] ) {
      return false;
    }
  }
}

