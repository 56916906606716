export default `
precision highp float;

#define M_PI 3.1415926535897932384626433832795

attribute vec3 position;
attribute vec4 color;//wights

uniform mat4 worldViewProjection;
uniform float radius;
uniform float scale;
uniform float angle;
/*
float radius = 0.2;
float scale = 0.02;
float angle = M_PI * 0.5;
*/
void main() {
    vec3 pos = vec3(position.y, -position.x,position.z) * scale;
    pos.x += radius * color.z;
    float t = color.x * angle;
    float cos_v = cos(t);
    float sin_v = sin(t);
    vec3 rotated = vec3(pos.x * cos_v - pos.y * sin_v, pos.y * cos_v + pos.x * sin_v, pos.z);
    gl_Position = worldViewProjection * vec4(rotated, 1.0);
}
`;
