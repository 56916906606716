import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { Observable, combineLatest, of } from "rxjs";
import { map } from "rxjs/operators";
import { TooltipType } from "../../model/tooltip-type.enum";
import { TranslationProvider } from "../../providers/translation.provider";
import { ToolTipConfig } from "../rule-action/rule-action.component";

export class DropdownOption {
  constructor(
    public type: string,
    public data: any,
    public possible: boolean,
    public value: string,
    public label: string = "",
    public translation: boolean = true,
    public metadata: any = null
  ) {}
}

const individualOption = new DropdownOption(
  "text",
  "Individual",
  true,
  "Individual"
);

export class Dropdown {
  public selectedOption: Observable<DropdownOption | null>;
  public possibleOptionsWithAny = this.possibleOptions.pipe(
    map( po => !!po ? po : 'any')
  );

  constructor(
    public label: string,
    public options: Observable<DropdownOption[]>,
    public value: Observable<string>,
    public onChnage: (string) => void,
    public showIndividualOption: Observable<boolean> = null,
    public possibleOptions: Observable<string[]> = of(null)
  ) {
    this.selectedOption = combineLatest([this.options, this.value]).pipe(
      map(([options, value]) => {
        if (value === "Individual") {
          return individualOption;
        }
        for (let i = 0; i < options.length; i++) {
          const option = options[i];
          if (option.value === value) {
            return option;
          }
        }
        return null;
      })
    );
  }
}

@Component({
  selector: "dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.css"],
})
export class DropdownComponent implements OnInit {
  @Input() model: Dropdown;
  @Input() disabled = false;
  @Input() tooltipConfig: ToolTipConfig;
  @Input() set dropdownMenuWidth(newWidth: number) {
    if (this._dropDownMenuWidth !== newWidth) {
      this._dropDownMenuWidth = newWidth;
      this.elementRef.nativeElement.style.setProperty(
        "--dropdownMenuWidth",
        newWidth + "px"
      );
    }
  }
  @Input() extraClass = "";
  private _dropDownMenuWidth = 295;
  public individualOption = individualOption;
  public dropdownType = "DEFAULT";

  constructor(
    private elementRef: ElementRef,
    private translationProvider: TranslationProvider
  ) {}

  // translate(text: string) {
  //   return this.translationProvider.translate(text);
  // }

  changeAction(option) {
    this.model.onChnage(option.value);
  }

  updateType(e: TooltipType) {
    if (e === TooltipType.error) {
      this.dropdownType = "ERROR";
    } else if (e === TooltipType.warning) {
      this.dropdownType = "WARNING";
    } else {
      this.dropdownType = "DEFAULT";
    }
  }

  ngOnInit() {
    this.elementRef.nativeElement.style.setProperty(
      "--dropdownMenuWidth",
      this._dropDownMenuWidth + "px"
    );
  }

  possible(possibleOptions: string[], option: DropdownOption): boolean {
    if (!!possibleOptions) {
      return possibleOptions.indexOf(option.value) !== -1;
    } else {
      return true;
    }
  }
  optionType(possibleOptions: string[] | string, option: DropdownOption): string {
    if (possibleOptions === 'any') {
      return 'DEFAULT';
    }
    return  possibleOptions.indexOf(option.value) === -1 ? 'ERROR' : 'DEFAULT';
  }

}
