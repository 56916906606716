import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import jwtDecode from 'jwt-decode';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TutorialsService {

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute
  ) { }

  async reportTutorialRedirect(): Promise<void> {
    const jwt = Object.keys(this.activatedRoute.snapshot.queryParams)[0];
    const payload: any = jwtDecode(jwt);
    await this.http.get<any>(environment.api_url + '/api/mevaco/reportTutorialRedirect',
      {
        params: new HttpParams()
          .set('configId', payload.configId)
          .set('userId', payload.customerId)
      }
    ).toPromise();
  }

}
