<div class="modalBody">
  <div class="titleRow">
    <div class="title">{{translate("preselect modal title")}}</div>
  </div>
  <div class="row">
    <div class="rowText">
      {{translate("preselect modal shape description")}}
    </div>
    <div class="rowBtn">
      <button class="btn" (click)="setShapeStep()">
        <div class="buttonText">
          {{translate("preselect modal shape btn")}}
        </div>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="rowText">
      {{translate("preselect modal template plate description")}}
    </div>
    <div class="rowBtn">
      <button class="btn" (click)="simpleRectangleStep()">
        <div class="buttonText">
          {{translate("preselect modal template plate btn")}}
        </div>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="rowText">
      {{translate("preselect modal new plate description")}}
    </div>
    <div class="rowBtn">
      <button class="btn" (click)="selectTemplateStep()">
        <div class="buttonText">
          {{translate("preselect modal new plate btn")}}
        </div>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="rowText">
      {{translate("preselect modal preview description")}}
    </div>
    <div class="rowBtn">
      <button class="btn" (click)="closeOnStartPopup()">
        <div class="buttonText">
          {{translate("preselect modal preview btn")}}
        </div>
      </button>
    </div>
  </div>
</div>
