import {Action} from '@ngrx/store';

export const ENABLE_CUSTOM_STAMP = 'ENABLE_CUSTOM_STAMP';

export class EnableCustomStamp implements Action {
  readonly type: string = ENABLE_CUSTOM_STAMP;

  constructor(public payload: number) {
  }
}

export const SET_CUSTOM_STAMP_FORM_TYPE = 'SET_CUSTOM_STAMP_FORM_TYPE';

export class SetCustomStampFormType implements Action {
  readonly type: string = SET_CUSTOM_STAMP_FORM_TYPE;

  constructor(public payload: {type: string}) {
  }
}

export const SET_CUSTOM_STAMP_FORM = 'SET_CUSTOM_STAMP_FORM';

export class SetCustomStampForm implements Action {
  readonly type: string = SET_CUSTOM_STAMP_FORM;

  constructor(public payload: {form: string}) {
  }
}

export const SET_CUSTOM_STAMP_WIDTH = 'SET_CUSTOM_STAMP_WIDTH';
export class SetCustomStampWidth implements Action {
  readonly type: string = SET_CUSTOM_STAMP_WIDTH;

  constructor(public payload: {width: number}) {
  }
}

export const SET_CUSTOM_STAMP_LENGTH = 'SET_CUSTOM_STAMP_LENGTH';
export class SetCustomStampLength implements Action {
  readonly type: string = SET_CUSTOM_STAMP_LENGTH;

  constructor(public payload: {length: number}) {
  }
}

export const SET_CUSTOM_STAMP_ROTATION = 'SET_CUSTOM_STAMP_ROTATION';
export class SetCustomStampRotation implements Action {
  readonly type: string = SET_CUSTOM_STAMP_ROTATION;
  constructor(public payload: {rotation: number}) {
  }
}

export const SET_CUSTOM_STAMP_OFFSET_X = 'SET_CUSTOM_STAMP_OFFSET_X';
export class SetCustomStampOffsetX implements Action {
  readonly type: string = SET_CUSTOM_STAMP_OFFSET_X;
  constructor(public payload: {offsetX: number}) {
  }
}

export const SET_CUSTOM_STAMP_OFFSET_Y = 'SET_CUSTOM_STAMP_OFFSET_Y';
export class SetCustomStampOffsetY implements Action {
  readonly type: string = SET_CUSTOM_STAMP_OFFSET_Y;
  constructor(public payload: {offsetY: number}) {
  }
}

export const SET_CUSTOM_OFFSET_X = 'SET_CUSTOM_OFFSET_X';
export class SetCustomOffsetX implements Action {
  readonly type: string = SET_CUSTOM_OFFSET_X;
  constructor(public payload: {offsetX: number}) {
  }
}

export const SET_CUSTOM_OFFSET_Y = 'SET_CUSTOM_OFFSET_Y';
export class SetCustomOffsetY implements Action {
  readonly type: string = SET_CUSTOM_OFFSET_Y;
  constructor(public payload: {offsetY: number}) {
  }
}


export const SET_CUSTOM_STAMP_START_X = 'SET_CUSTOM_STAMP_START_X';
export class SetCustomStampStartX implements Action {
  readonly type: string = SET_CUSTOM_STAMP_START_X;
  constructor(public payload: {startX: number}) {
  }
}

export const SET_CUSTOM_STAMP_START_Y = 'SET_CUSTOM_STAMP_START_Y';
export class SetCustomStampStartY implements Action {
  readonly type: string = SET_CUSTOM_STAMP_START_Y;
  constructor(public payload: {startY: number}) {
  }
}

export const SET_CUSTOM_STAMP_END_Y = 'SET_CUSTOM_STAMP_END_Y';
export class SetCustomStampEndY implements Action {
  readonly type: string = SET_CUSTOM_STAMP_END_Y;
  constructor(public payload: {endY: number}) {
  }
}

export const SET_CUSTOM_STAMP_END_X = 'SET_CUSTOM_STAMP_END_X';
export class SetCustomStampEndX implements Action {
  readonly type: string = SET_CUSTOM_STAMP_END_X;
  constructor(public payload: {endX: number}) {
  }
}

export const SET_SELECTED_CUSTOM_STAMP = 'SET_SELECTED_CUSTOM_STAMP';

export class SetSelectedCustomStamp implements Action {
  readonly type: string = SET_SELECTED_CUSTOM_STAMP;

  constructor(public payload: number) {
  }
}

export const AUTO_FIR_CUSTOM_PATTERN = 'AUTO_FIR_CUSTOM_PATTERN';
export class AutoFitCustomPattern implements Action {
  readonly type: string = AUTO_FIR_CUSTOM_PATTERN;

  constructor(public payload: 'X'|'Y') {
  }
}

export const SET_SMART_MARGIN = 'SET_SMART_MARGIN';
export class SetSmartMargin implements Action {
  readonly type: string = SET_SMART_MARGIN;

  constructor() {
  }
}
