import { Store, select } from "@ngrx/store";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { PointerState } from "webcad/collision";
import { RotateCamera } from "webcad/components";
import { DragArrowModelManager } from "webcad/controllers/dragArrow/drag-arrow-model.manager";
import { DragArrowsManager } from "webcad/controllers/dragArrow/drag-arrows.manager";
import { Webcad } from "webcad/core";
import { InstancedMesh } from "webcad/core/instanced-mesh";
import {
  Vector2,
  Vector3,
  addVectors2,
  addVectors3,
  multiplyVector3byScalar,
  safeNormalizeVector3,
  sqrDistanceVector2,
  sqrLengthVector2,
  subVectors2,
  subVectors3,
  vector2toVector3,
} from "webcad/math";
import {
  MeasurementModelManager,
  MeasurementsManager,
} from "webcad/measurements";
import {
  Aabb2,
  AngleMeasurementModel,
  MeasurementModel,
  Segment,
  aabbOfPolyline,
  createAabb2FromTwoPoints,
  isPointInAabb2,
  movePolyline,
  moveSegment,
  projectPointOnSegment,
  scalePolyline,
} from "webcad/models";
import { MoveNodeType, PointNode } from "../../model";
import { createPolylineMesh } from "../../model/client-server.Params/ShapeActionParams";
import { ShapeOrigin } from "../../model/drawing.model";
import { HelpLine, helpLinesEqual } from "../../model/help-line.model";
import {
  MountingAttachment,
  createMountingMeshFrom,
  getMountingScale,
  groupByShape,
} from "../../model/mounting.model";
import { UnderPointerType } from "../../model/pointer-state.model";
import { SnapOptions } from "../../model/snap-options.model";
import { HelpingLineCollider } from "../../providers/colliders/helpline.collider";
import {
  AngleMeasurementCollider,
  MeasurementCollider,
} from "../../providers/colliders/measurement.collider";
import { MevacoCollider } from "../../providers/colliders/mevaco.collider";
import { MountingCollider } from "../../providers/colliders/mounting.collider";
import { NodeCollider } from "../../providers/colliders/node.collider";
import { PerforationAreaCollider } from "../../providers/colliders/perforation-area.collider";
import { SegmentCollider } from "../../providers/colliders/segment.collider";
import { DragArrowsManagerProvider } from "../../providers/drag-arrows-manager.provider";
import { MeasurementsManagerProvider } from "../../providers/measurements-manager.provider";
import {
  ClosestSegments,
  MevacoPointerProvider,
} from "../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../providers/scene.provider";
import { TranslationProvider } from "../../providers/translation.provider";
import { WebcadProvider } from "../../providers/webcad.provider";
import {
  AddNodeToSelection,
  AddPerforationSegmentToSelection,
  AddShapeSegmentToSelection,
  RemoveAngleMeasurement,
  RemoveAutomaticMeasurement,
  RemoveHelpLine,
  RemoveMeasurement,
  RemoveNodeFromSelection,
  RemovePerforationSegmentSelection,
  RemoveShapeSegmentSelection,
  RequestRender,
  SelectAngleMeasurement,
  SelectAutomaticMeasurement,
  SelectHelpLine,
  SelectMeasurement,
  SetHintMessage,
  SetNodesSelection,
  SetNodesSelectionFromSegments,
  UpdatePlateShape,
} from "../../store/actions/drawing.actions";
import {
  AddMountingToSelection,
  MoveMountingByVector,
  RemoveMountinFromSelection,
  RemoveMountings,
  SetMountingsSelection,
} from "../../store/actions/mountings.actions";
import {
  SelectPerforationArea,
  SelectPerforationAreaById,
} from "../../store/actions/perforation.actions";
import {
  MevacoState,
  getMaterial,
  getMountingHoles,
  getNodesState,
  getSelectedAngleMeasurement,
  getSelectedAutomaticMeasurement,
  getSelectedHelpLine,
  getSelectedMeasurement,
  getSelectedMountings,
  getSelectedNodes,
  getSelectedSegments,
  getSnapOptions,
} from "../../store/reducers";
import { copyDrawingState } from "../../store/store.utils";
import {
  getHorizontalMeasurementDirectionForTwoPoints,
  getVerticalMeasurementDirectionForTwoPoints,
} from "../../utils/math.utils";
import { createNodeMesh } from "../../visualizers/nodes.visualizer";
import { DifferentiationTool } from "../differentiation-tool.interface";
import {
  AbstractMesh, BoundingInfo, Color3,
  Color4, LinesMesh,
  Mesh, MeshBuilder,
  Nullable,
  Observer,
  Scene,
  ShaderMaterial,
  StandardMaterial,
  Vector3 as B_Vector3,
  Vector4
} from "webcad/babylonjs/core";
import {lengthVector2} from "webcad";
export enum SelectToolMode {
  FREE,
  MULTISELECT,
  VECTOR,
}

export class SelectTool extends DifferentiationTool {
  private usageMode: SelectToolMode = SelectToolMode.FREE;
  private selectedMode: BehaviorSubject<SelectToolMode> = new BehaviorSubject(
    SelectToolMode.FREE
  );
  private selectedNodes: Map<PointNode, Mesh> = new Map<
    PointNode,
    Mesh
  >();
  private selectedSegments: Map<Segment, Mesh> = new Map<
    Segment,
    Mesh
  >();
  private selectedMountings: MountingAttachment[] = [];
  private selectedMountingsMeshes: InstancedMesh[] = [];
  private ctrl: boolean;
  private shift = false;
  private lastObjectUnderPointer: MevacoCollider;
  private pointerState: PointerState;
  private _canDrag: boolean;
  private dragging: boolean;
  private PointerClick: (pointerPosition: Vector3, ctrl?: boolean) => void;
  private PointerDown: (pointerPosition: Vector3, ctrl?: boolean) => void;
  private PointerUp: (pointerPosition: Vector3, ctrl?: boolean) => void;
  private PointerMove: (pointerPosition: Vector3, ctrl?: boolean) => void;
  private _moveSum: Vector2 = { x: 0, y: 0 };
  private scene: Scene;
  private selectionMaterial: StandardMaterial;
  private selectionMountingMaterial: ShaderMaterial;
  private selectionMesh: Mesh;
  private scalingSub: Nullable<Observer<Scene>>;
  private startDragPosition: Vector3;
  private mouseDown: boolean;
  private prevPosition: Vector3;
  private selectedMeasurement: MeasurementModel;
  private selectedAngleMeasurement: AngleMeasurementModel;
  private selectedHelpLineMesh: Mesh;
  private selectedHelpLine: HelpLine;
  private measurementsManager: MeasurementsManager;
  private verticalMeasurement: MeasurementModelManager;
  private horizontalMeasurement: MeasurementModelManager;
  private nodes: PointNode[];
  private mountings: MountingAttachment[];
  private absoluteDragStartPosition: Vector3;
  private snapOptions: SnapOptions;
  private horizontalDragArrowModel: DragArrowModelManager;
  private verticalDragArrowModel: DragArrowModelManager;
  private webcad: Webcad;
  private _dragArrowsManager: DragArrowsManager;
  private selectedAutomaticMeasurement: MeasurementModel;

  constructor(
    private pointerController: MevacoPointerProvider,
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private measurementsManagerProvider: MeasurementsManagerProvider,
    private webcadProvider: WebcadProvider,
    private dragArrowsManagerProvider: DragArrowsManagerProvider,
    private translationProvider: TranslationProvider
  ) {
    super();
    this.dragArrowsManagerProvider.subscribe((v) => {
      if (!!v) {
        this._dragArrowsManager = v;
        if (!!this.horizontalDragArrowModel) {
          this.horizontalDragArrowModel.dispose();
        }
        this.horizontalDragArrowModel =
          this._dragArrowsManager.getDragArrowModel();
        this.horizontalDragArrowModel.setModel({
          ...this.horizontalDragArrowModel.getModel(),
          position: { x: 0, y: 0 },
          offset: { x: 50, y: 0 },
          moveDirection: { x: 1, y: 0 },
          onDragObservable: new BehaviorSubject<Vector2>({ x: 0, y: 0 }),
          visible: false,
          rotation: Math.PI / 2,
        });
        if (!!this.verticalDragArrowModel) {
          this.verticalDragArrowModel.dispose();
        }
        this.verticalDragArrowModel =
          this._dragArrowsManager.getDragArrowModel();
        this.verticalDragArrowModel.setModel({
          ...this.verticalDragArrowModel.getModel(),
          position: { x: 0, y: 0 },
          offset: { x: 0, y: 70 },
          moveDirection: { x: 0, y: 1 },
          onDragObservable: new BehaviorSubject<Vector2>({ x: 0, y: 0 }),
          visible: false,
          rotation: 0,
        });
        this.horizontalDragArrowModel.subscribe((moveValue) => {
          if (this.prevPosition) {
            this.freeDrag(
               vector2toVector3(moveValue)
            );
            this.updateMeasurements();
            this.updateArrowPosition();
          }
        });
        this.verticalDragArrowModel.subscribe((moveValue) => {
          if (this.prevPosition) {
            this.freeDrag(
              vector2toVector3(moveValue)
            );
            this.updateMeasurements();
            this.updateArrowPosition();
          }
        });
      }
    });

    this.webcadProvider.getObservable().subscribe((v) => (this.webcad = v));
    this.sceneProvider.getSubscription().subscribe((scene) => {
      if (this.selectionMaterial) {
        this.selectionMaterial.dispose();
        this.selectionMaterial = null;
      }
      if (this.selectionMountingMaterial) {
        this.selectionMountingMaterial.dispose();
        this.selectionMountingMaterial = null;
      }
      if (this.selectionMesh) {
        this.selectionMesh.dispose();
        this.selectionMesh = null;
      }
      if (this.scalingSub) {
        this.scene.onBeforeRenderObservable.remove(this.scalingSub);
      }
      if (scene !== null) {
        this.scene = scene;
        this.setupSelectionMaterial();
        this.scalingSub = this.scene.onBeforeRenderObservable.add((ed, es) => {
          const activeCamera = this.scene.activeCamera as unknown;
          const scale = 0.004 * (activeCamera as RotateCamera).radius;
          if (this.selectedHelpLineMesh) {
            this.selectedHelpLineMesh.edgesWidth = scale * 20;
          }
          if (this.selectedNodes) {
            this.selectedNodes.forEach((v, k) => {
              v.scaling = new B_Vector3(scale, scale, scale);
            });
          }
        });
        this.store.dispatch(new RequestRender());
      }
    });

    this.measurementsManagerProvider.getSubsciption().subscribe((v) => {
      this.measurementsManager = v;
      if (this.verticalMeasurement) {
        this.verticalMeasurement.disposeModel();
        this.verticalMeasurement = null;
      }
      if (this.horizontalMeasurement) {
        this.horizontalMeasurement.disposeModel();
        this.horizontalMeasurement = null;
      }
      if (this.measurementsManager) {
        if (!this.verticalMeasurement) {
          this.verticalMeasurement =
            this.measurementsManager.getMeasurementModel();
          this.verticalMeasurement.setVisible(false);
          this.verticalMeasurement.setInputCallbacks(
            (value: number) => {
              const verticalVector = subVectors3(
                this.verticalMeasurement.getEnd(),
                this.verticalMeasurement.getStart()
              );
              const normalizedVertical = safeNormalizeVector3(verticalVector, {
                x: 0,
                y: 1,
                z: 0,
              });
              const verticalMoveDir = multiplyVector3byScalar(
                normalizedVertical,
                value
              );
              const horizontalVector = subVectors3(
                this.horizontalMeasurement.getEnd(),
                this.horizontalMeasurement.getStart()
              );
              this.moveInDirection(
                addVectors2(horizontalVector, verticalMoveDir)
              );
              this.updateMeasurements();
              this.updateArrowPosition();
              this.store.dispatch(new RequestRender());
              return value;
            },
            (value: number) => {
              const verticalVector = subVectors3(
                this.verticalMeasurement.getEnd(),
                this.verticalMeasurement.getStart()
              );
              const normalizedVertical = safeNormalizeVector3(verticalVector, {
                x: 0,
                y: 1,
                z: 0,
              });
              const verticalMoveDir = multiplyVector3byScalar(
                normalizedVertical,
                value
              );
              const horizontalVector = subVectors3(
                this.horizontalMeasurement.getEnd(),
                this.horizontalMeasurement.getStart()
              );
              this.moveInDirection(
                addVectors2(horizontalVector, verticalMoveDir)
              );
              this.updateMeasurements();
              this.onConfirm();
              return value;
            }
          );
        }
        if (!this.horizontalMeasurement) {
          this.horizontalMeasurement =
            this.measurementsManager.getMeasurementModel();
          this.horizontalMeasurement.setVisible(false);
          this.horizontalMeasurement.setInputCallbacks(
            (value: number) => {
              const horizontalVector = subVectors3(
                this.horizontalMeasurement.getEnd(),
                this.horizontalMeasurement.getStart()
              );
              const normalizedHorizontal = safeNormalizeVector3(
                horizontalVector,
                { x: 1, y: 0, z: 0 }
              );
              const horizontalMoveDir = multiplyVector3byScalar(
                normalizedHorizontal,
                value
              );
              const verticalVector = subVectors3(
                this.verticalMeasurement.getEnd(),
                this.verticalMeasurement.getStart()
              );
              this.moveInDirection(
                addVectors2(horizontalMoveDir, verticalVector)
              );
              this.updateMeasurements();
              this.updateArrowPosition();
              this.store.dispatch(new RequestRender());
              return value;
            },
            (value: number) => {
              const horizontalVector = subVectors3(
                this.horizontalMeasurement.getEnd(),
                this.horizontalMeasurement.getStart()
              );
              const normalizedHorizontal = safeNormalizeVector3(
                horizontalVector,
                { x: 1, y: 0, z: 0 }
              );
              const horizontalMoveDir = multiplyVector3byScalar(
                normalizedHorizontal,
                value
              );
              const verticalVector = subVectors3(
                this.verticalMeasurement.getEnd(),
                this.verticalMeasurement.getStart()
              );
              this.moveInDirection(
                addVectors2(horizontalMoveDir, verticalVector)
              );
              this.updateMeasurements();
              this.onConfirm();
              return value;
            }
          );
        }
      }
    });

    this.pointerController.pointerState.subscribe((v) => {
      this.ctrl = v.ctrl;
      this.shift = v.shift;
      let objectUnderPointer: MevacoCollider = this.lastObjectUnderPointer;
      this.pointerState = v;
      if (this.pointerState) {
        if (!this.dragging) {
          this._canDrag = this.canDrag(v.position);
        }
        objectUnderPointer = this.pointerState.intersection
          ? (this.pointerState.intersection.collider as MevacoCollider)
          : null;
        this.lastObjectUnderPointer = objectUnderPointer;
      }
      if (!objectUnderPointer) {
        this.PointerClick = this.onMouseClickOnGridOrNone;
        this.PointerDown = this.onMouseDownOnGridOrNone;
        this.PointerUp = this.onMouseUpOnGridOrNone;
        this.PointerMove = this.onMouseMoveOnGridOrNone;
      } else {
        switch (objectUnderPointer.objectType) {
          case UnderPointerType.NODE:
            this.PointerClick = this.onMouseClickOnNode;
            this.PointerDown = this.onMouseDownOnNode;
            this.PointerUp = this.onMouseUpOnNode;
            this.PointerMove = this.onMouseMoveOnNode;
            break;
          case UnderPointerType.SEGMENT:
            this.PointerClick = this.onMouseClickOnSegment;
            this.PointerDown = this.onMouseDownOnSegment;
            this.PointerUp = this.onMouseUpOnSegment;
            this.PointerMove = this.onMouseMoveOnSegment;
            break;
          case UnderPointerType.HELP_LINE:
            this.PointerClick = this.onMouseClickOnHelpLine;
            this.PointerDown = this.onMouseDownOnHelpLine;
            this.PointerUp = this.onMouseUpOnHelpLine;
            this.PointerMove = this.onMouseMoveOnHelpLine;
            break;
          case UnderPointerType.AUTOMATIC_MEASUREMENT:
          case UnderPointerType.MEASUREMENT:
            this.PointerClick = this.onMouseClickOnMeasurement;
            this.PointerDown = this.onMouseDownOnMeasurement;
            this.PointerUp = this.onMouseUpOnMeasurement;
            this.PointerMove = this.onMouseMoveOnMeasurement;
            break;
          case UnderPointerType.ANGLE_MEASUREMENT:
            this.PointerClick = this.onMouseClickOnAngleMeasurement;
            this.PointerDown = this.onMouseDownOnAngleMeasurement;
            this.PointerUp = this.onMouseUpOnAngleMeasurement;
            this.PointerMove = this.onMouseMoveOnAngleMeasurement;
            break;
          case UnderPointerType.MOUNTING:
            this.PointerClick = this.onMouseClickOnMounting;
            this.PointerDown = this.onMouseDownOnMounting;
            this.PointerUp = this.onMouseUpOnMounting;
            this.PointerMove = this.onMouseMoveOnMounting;
            break;
          case UnderPointerType.PERFORATION_AREA:
            this.PointerClick = this.onMouseClickOnPerforationArea;
            this.PointerDown = this.onMouseDownOnPerforationArea;
            this.PointerUp = this.onMouseUpOnPerforationArea;
            this.PointerMove = this.onMouseMoveOnPerforationArea;
            break;
          default:
            this.PointerClick = this.onMouseClickOnGridOrNone;
            this.PointerDown = this.onMouseDownOnGridOrNone;
            this.PointerUp = this.onMouseUpOnGridOrNone;
            this.PointerMove = this.onMouseMoveOnGridOrNone;
            break;
        }
      }
    });
    this.store.pipe(select(getNodesState)).subscribe((v) => (this.nodes = v));
    this.store
      .pipe(select(getMountingHoles))
      .subscribe((v) => (this.mountings = v));
    combineLatest([
      this.store.pipe(select(getSelectedNodes)),
      this.store.pipe(select(getSelectedSegments)),
      this.store.pipe(select(getSelectedMountings)),
      this.store.pipe(select(getMaterial)),
    ])
      .pipe(debounceTime(1))
      .subscribe(([nodes, segments, mountings, material]) => {
        // this.cancelDrag();
        const thickness =
          material && material.materialThickness
            ? material.materialThickness / 1000
            : 0.001;
        this.recreateVisualization(nodes, segments, mountings, thickness);
      });

    this.store.pipe(select(getSelectedMeasurement)).subscribe((measurement) => {
      this.selectedMeasurement = measurement;
    });
    this.store
      .pipe(select(getSelectedAutomaticMeasurement))
      .subscribe((measurement) => {
        this.selectedAutomaticMeasurement = measurement;
      });
    this.store
      .pipe(select(getSelectedAngleMeasurement))
      .subscribe((measurement) => {
        this.selectedAngleMeasurement = measurement;
      });
    this.store.pipe(select(getSelectedHelpLine)).subscribe((helpLine) => {
      if (this.selectedHelpLineMesh) {
        this.selectedHelpLineMesh.disableEdgesRendering();
        this.selectedHelpLineMesh = null;
      }
      this.selectedHelpLine = helpLine;
      if (helpLine) {
        this.selectedHelpLineMesh = this.scene.meshes.find(
          (v) => v.metadata && helpLinesEqual(v.metadata, helpLine)
        ) as LinesMesh;
        if (this.selectedHelpLineMesh) {
          this.selectedHelpLineMesh.enableEdgesRendering();
          this.selectedHelpLineMesh.edgesColor = new Color4(0, 1, 0, 1);
          const activeCamera = this.scene.activeCamera as unknown;
          this.selectedHelpLineMesh.edgesWidth =
            0.08 * (activeCamera as RotateCamera).radius;
        }
      }
    });
    this.store
      .pipe(select(getSnapOptions))
      .subscribe((v) => (this.snapOptions = v));
  }

  public get moveSum(): Vector2 {
    return this._moveSum;
  }

  public set moveSum(v: Vector2) {
    if (this.isDirty()) {
      if (!this.absoluteDragStartPosition) {
        this.absoluteDragStartPosition = {
          x: this.startDragPosition.x,
          y: this.startDragPosition.y,
          z: this.startDragPosition.z,
        };
        this.prevPosition = {
          x: this.startDragPosition.x,
          y: this.startDragPosition.y,
          z: this.startDragPosition.z,
        };
      }
      this.selectedMode.next(SelectToolMode.VECTOR);
      this.usageMode = SelectToolMode.VECTOR;
      this.moveInDirection(v);
      this.updateMeasurements();
      this.updateArrowPosition();
      this.store.dispatch(new RequestRender());
    } else {
      this._moveSum = { x: 0, y: 0 };
    }
  }

  private setupSelectionMaterial() {
    this.selectionMaterial = new StandardMaterial(
      "selectionMaterial",
      this.scene
    );
    this.selectionMaterial.ambientColor = new Color3(0.203, 0.8, 1.0);
    this.selectionMaterial.alpha = 0.3;
    this.selectionMaterial.diffuseColor = new Color3(0.203, 0.8, 1.0);
    this.selectionMaterial.specularColor = new Color3(0.203, 0.8, 1.0);

    this.selectionMountingMaterial = new ShaderMaterial(
      "shader",
      this.scene,
      {
        vertex: "mountings",
        fragment: "customColor",
      },
      {
        attributes: ["position", "instancePos"],
      }
    );
    this.selectionMountingMaterial.setVector4(
      "matColor",
      new Vector4(0.0, 0.0, 1.0, 1)
    );
    // this.selectionMountingMaterial.backFaceCulling = false;
  }

  public tryDelete() {
    this.deleteSelectedMeasurement();
    this.deleteSelectedMounting();
    this.deleteSelectedHelpLine();
  }

  public deleteSelectedHelpLine() {
    if (this.selectedHelpLine) {
      this.store.dispatch(new RemoveHelpLine(this.selectedHelpLine));
    }
  }

  public deleteSelectedMeasurement() {
    if (this.selectedMeasurement) {
      this.store.dispatch(new RemoveMeasurement(null));
    }
    if (this.selectedAngleMeasurement) {
      this.store.dispatch(new RemoveAngleMeasurement(null));
    }
    if (this.selectedAutomaticMeasurement) {
      this.store.dispatch(new RemoveAutomaticMeasurement(null));
    }
  }

  public deleteSelectedMounting() {
    if (this.selectedMountings.length > 0) {
      this.store.dispatch(new RemoveMountings(this.selectedMountings));
    }
  }

  activate() {
    if (!!this.horizontalDragArrowModel && !!this.verticalDragArrowModel) {
      this.horizontalDragArrowModel.setVisibility(false);
      this.verticalDragArrowModel.setVisibility(false);
    }
    this.store.dispatch(
      new SetHintMessage(
        this.translationProvider.translate("startSelectToolHint")
      )
    );
  }

  isDirty(): boolean {
    return (
      this.selectedNodes.size > 0 ||
      this.selectedMountings.length > 0 ||
      this.selectedHelpLine !== null ||
      this.selectedMeasurement !== null ||
      this.selectedAngleMeasurement !== null
    );
  }

  onCancel() {
    this.removeSelections();
    this.cancelDrag();
  }

  onConfirm() {
    if (this.measurementsManager) {
      this.measurementsManager.focusVisualizer(null);
    }
    if (
      this.startDragPosition &&
      this._moveSum &&
      sqrLengthVector2(this._moveSum) > 0
    ) {
      copyDrawingState(this.store).then((drawing) => {
        const nodes = Array.from(this.selectedNodes.keys());
        this.store.dispatch(
          new MoveMountingByVector({
            mountings: this.selectedMountings,
            vector: this._moveSum,
          })
        );
        const moveBias = nodes.length === 1;
        for (const n of nodes) {
          if (moveBias || n.type !== MoveNodeType.bias) {
            for (const c of n.onChangeCallbacks) {
              c({ x: this._moveSum.x, y: this._moveSum.y, z: 0 });
            }
          }
        }
        this.store.dispatch(new UpdatePlateShape(drawing));
        this.reset();
      });
    }
  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {}

  onMouseClick(pointerState: PointerState) {
    this.PointerClick(pointerState.position, pointerState.ctrl);
  }

  onMouseClickOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
    const objectUnderPointer = (
      this.lastObjectUnderPointer as MeasurementCollider
    ).measurement;
    if (!this._canDrag) {
      this.removeSelections();
      if (
        this.lastObjectUnderPointer.objectType === UnderPointerType.MEASUREMENT
      ) {
        this.store.dispatch(new SelectMeasurement(objectUnderPointer));
      } else if (
        this.lastObjectUnderPointer.objectType ===
        UnderPointerType.AUTOMATIC_MEASUREMENT
      ) {
        this.store.dispatch(new SelectAutomaticMeasurement(objectUnderPointer));
      }
    }
  }

  onMouseClickOnMounting(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnNode(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnSegment(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDown(pointerState: PointerState) {
    this.mouseDown = true;
    if (!this._canDrag) {
      this.cancelDrag();
    }
    this.startDragPosition = {
      x: pointerState.position.x,
      y: pointerState.position.y,
      z: pointerState.position.z,
    };
    this.PointerDown(pointerState.position, pointerState.ctrl);
  }

  onMouseDownOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
    const objectUnderPointer = (
      this.lastObjectUnderPointer as AngleMeasurementCollider
    ).angleMeasurement;
    if (!this._canDrag) {
      this.removeSelections();
      this.store.dispatch(new SelectAngleMeasurement(objectUnderPointer));
    }
  }

  onMouseDownOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {
    if (!this._canDrag) {
      this.removeSelections();
      this.usageMode = SelectToolMode.MULTISELECT;
    }
  }

  onMouseDownOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {
    const objectUnderPointer = (
      this.lastObjectUnderPointer as HelpingLineCollider
    ).helpLine;
    if (!this._canDrag) {
      this.removeSelections();
      this.store.dispatch(new SelectHelpLine(objectUnderPointer));
    }
  }

  onMouseDownOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnMounting(pointerPosition: Vector3, ctrl?: boolean) {
    const objectUnderPointer = (this.lastObjectUnderPointer as MountingCollider)
      .mounting;
    this.removeSingleSelections();
    if (!ctrl) {
      if (!this._canDrag) {
        this.store.dispatch(new SetNodesSelection([]));
        this.store.dispatch(new SetMountingsSelection([objectUnderPointer]));
      }
    } else {
      if (this.selectedMountings.indexOf(objectUnderPointer) !== -1) {
        this.store.dispatch(new RemoveMountinFromSelection(objectUnderPointer));
      } else {
        this.store.dispatch(new AddMountingToSelection(objectUnderPointer));
      }
    }
  }

  onMouseDownOnNode(pointerPosition: Vector3, ctrl?: boolean) {
    this.removeSingleSelections();
    const objectUnderPointer = (this.lastObjectUnderPointer as NodeCollider)
      .node;
    if (!ctrl) {
      if (!this._canDrag) {
        this.store.dispatch(new SetNodesSelection([objectUnderPointer]));
      }
    } else {
      if (this.selectedNodes.has(objectUnderPointer)) {
        this.store.dispatch(new RemoveNodeFromSelection(objectUnderPointer));
      } else {
        this.store.dispatch(new AddNodeToSelection(objectUnderPointer));
      }
    }
  }

  onMouseDownOnSegment(pointerPosition: Vector3, ctrl?: boolean) {
    this.removeSingleSelections();
    const objectUnderPointer = (this.lastObjectUnderPointer as SegmentCollider)
      .segment;
    const isPerforation =
      (this.lastObjectUnderPointer as SegmentCollider).perforationAreaSegment >
      -1;
    if (!ctrl) {
      if (!this._canDrag) {
        this.store.dispatch(
          new SetNodesSelectionFromSegments([
            {
              segment: objectUnderPointer,
              origin: isPerforation
                ? ShapeOrigin.PERFORATION
                : ShapeOrigin.SHAPE,
            },
          ])
        );
      }
    } else {
      if (this.selectedSegments.has(objectUnderPointer)) {
        if (isPerforation) {
          this.store.dispatch(
            new RemovePerforationSegmentSelection(objectUnderPointer)
          );
        } else {
          this.store.dispatch(
            new RemoveShapeSegmentSelection(objectUnderPointer)
          );
        }
      } else {
        if (isPerforation) {
          this.store.dispatch(
            new AddPerforationSegmentToSelection(objectUnderPointer)
          );
        } else {
          this.store.dispatch(
            new AddShapeSegmentToSelection(objectUnderPointer)
          );
        }
      }
    }
  }

  onMouseMove(pointerState: PointerState) {
    if (this._canDrag && !this.dragging) {
      this.store.dispatch(
        new SetHintMessage(
          this.translationProvider.translate("startDragHint", "designer")
        )
      );
    } else if (this.isDirty() && !this.dragging) {
      this.store.dispatch(
        new SetHintMessage(
          this.translationProvider.translate("deleteElementHint", "designer")
        )
      );
    } else if (this.dragging) {
      this.store.dispatch(
        new SetHintMessage(
          this.translationProvider.translate("draggingHint", "designer")
        )
      );
    }
    if (this.mouseDown) {
      if (!this.dragging) {
        this.startDrag(pointerState.position);
      }
      this.dragging = true;
      this.onDrag(pointerState.position);
      if (this.usageMode !== SelectToolMode.MULTISELECT) {
        if (
          this._canDrag &&
          this.horizontalMeasurement &&
          this.verticalMeasurement
        ) {
          this.horizontalMeasurement.setVisible(true);
          this.verticalMeasurement.setVisible(true);
          this.updateMeasurements();
        }
      }
    } else {
      this.PointerMove(pointerState.position, pointerState.ctrl);
    }
  }

  onMouseMoveOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnMounting(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnNode(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnSegment(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUp(pointerState: PointerState) {
    this.mouseDown = false;
    // Caution !! might need to look into _canDrag
    if (this.usageMode === SelectToolMode.FREE && this.dragging) {
      this.onConfirm();
    }
    this.dragging = false;
    this.usageMode = this.selectedMode.value;
    if (this.selectionMesh) {
      this.selectionMesh.dispose();
      this.selectionMesh = null;
    }
  }

  onMouseUpOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnMounting(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnNode(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnSegment(pointerPosition: Vector3, ctrl?: boolean) {}

  reset() {
    this.onCancel();
  }

  private startDrag(position: Vector3) {
    if (this._canDrag && !this.absoluteDragStartPosition) {
      this.absoluteDragStartPosition = position;
    }
    this.prevPosition = { x: position.x, y: position.y, z: position.z };
    if (this.usageMode === SelectToolMode.VECTOR) {
      this.horizontalDragArrowModel.setModel({
        ...this.horizontalDragArrowModel.getModel(),
        visible: true,
        position: { x: position.x + 0.1, y: position.y },
      });
      this.verticalDragArrowModel.setModel({
        ...this.verticalDragArrowModel.getModel(),
        visible: true,
        position: { x: position.x, y: position.y + 0.1 },
      });
    }
  }

  private cancelDrag() {
    this.selectedMode.next(SelectToolMode.FREE);
    this.usageMode = SelectToolMode.FREE;
    this._moveSum = { x: 0, y: 0 };
    this.startDragPosition = null;
    this.absoluteDragStartPosition = null;
    this.prevPosition = null;
    this.dragging = false;
    if (this.horizontalMeasurement && this.verticalMeasurement) {
      this.horizontalMeasurement.setVisible(false);
      this.verticalMeasurement.setVisible(false);
    }
    if (!!this.horizontalDragArrowModel && !!this.verticalDragArrowModel) {
      this.horizontalDragArrowModel.setVisibility(false);
      this.verticalDragArrowModel.setVisibility(false);
    }
  }

  private onSelectedMounting(position: Vector2): boolean {
    const keys = this.selectedMountings;
    for (const k of keys) {
      const scaleFactor = getMountingScale(k.mountingRef);
      const mountingPolyline = movePolyline(
        scalePolyline(k.shape, scaleFactor.x, scaleFactor.y),
        addVectors2(k.position, this._moveSum)
      );
      const aaBb = aabbOfPolyline(mountingPolyline);
      if (isPointInAabb2(position, aaBb)) {
        return true;
      }
    }
    return false;
  }

  private onSelectedNode(position: Vector2): boolean {
    const keys = Array.from(this.selectedNodes.keys());
    for (const k of keys) {
      if (
        sqrDistanceVector2(addVectors2(k.position, this._moveSum), position) <
        0.0005
      ) {
        return true;
      }
    }
    return false;
  }

  private onSelectedSegment(position: Vector2): boolean {
    const keys = Array.from(this.selectedSegments.keys());
    for (const k of keys) {
      const moved = moveSegment(k, this._moveSum);
      const pp = projectPointOnSegment(position, moved);
      if (pp) {
        if (sqrDistanceVector2(position, pp) < 0.0005) {
          return true;
        }
      }
    }
    return false;
  }

  private canDrag(position: Vector2): boolean {
    return (
      this.onSelectedNode(position) ||
      this.onSelectedSegment(position) ||
      this.onSelectedMounting(position)
    );
  }

  private onDrag(position: Vector3) {
    switch (this.usageMode) {
      case SelectToolMode.FREE:
        this.freeDrag(position);
        break;
      case SelectToolMode.MULTISELECT:
        this.multiSelect(position);
        break;
      case SelectToolMode.VECTOR:
        this.vectorDrag(position);
        break;
    }
  }

  private vectorDrag(position: Vector3) {
    this.freeDrag(position);
    this.updateArrowPosition();
  }

  private freeDrag(position: Vector3) {
    if(position.x > 1.5){
      console.log( this.prevPosition.x + ' <= ' + position.x)
    }
    if (this.shift) {
      const startDiff = subVectors2(position, this.startDragPosition);
      position = { ...position }; // do not change input

      if (Math.abs(startDiff.x) > Math.abs(startDiff.y)) {
        position.y = this.startDragPosition.y;
      } else {
        position.x = this.startDragPosition.x;
      }
    }

    const diff = subVectors2(position, this.prevPosition);
    const l = lengthVector2(diff);

    this.selectedNodes.forEach((mesh, k) => {
      mesh.position.addInPlace(new B_Vector3(diff.x, diff.y, 0));
    });
    this.selectedSegments.forEach((mesh, k) => {
      mesh.position.addInPlace(new B_Vector3(diff.x, diff.y, 0));
    });
    this.selectedMountingsMeshes.forEach((mesh) => {
      mesh.position.addInPlace(new B_Vector3(diff.x, diff.y, 0));
    });
    console.log( this.prevPosition.x + ' <= ' + position.x)
    this.prevPosition = position;
    this._moveSum = addVectors2(this._moveSum, diff);
  }

  private removeSelections() {
    this.store.dispatch(new SetNodesSelection([]));
    this.store.dispatch(new SetMountingsSelection([]));
    this.store.dispatch(new SelectPerforationAreaById(-1));
    this.removeSingleSelections();
  }

  private removeSingleSelections() {
    this.store.dispatch(new SelectHelpLine(null));
    this.store.dispatch(new SelectMeasurement(null));
    this.store.dispatch(new SelectAutomaticMeasurement(null));
    this.store.dispatch(new SelectAngleMeasurement(null));
  }

  private updateMeasurements() {
    const startPoint = this.absoluteDragStartPosition;
    const endPoint = addVectors2(this.absoluteDragStartPosition, this._moveSum);
    const horizontalEndPoint = { x: endPoint.x, y: startPoint.y, z: 0 };
    const verticalBeginPoint = {
      x: horizontalEndPoint.x,
      y: startPoint.y,
      z: 0,
    };
    const horizontalMeasurementDir =
      getHorizontalMeasurementDirectionForTwoPoints(startPoint, endPoint);
    const verticalMeasurementDir = getVerticalMeasurementDirectionForTwoPoints(
      startPoint,
      endPoint
    );
    const startPointV3 = { x: startPoint.x, y: startPoint.y, z: 0 };
    this.horizontalMeasurement.updateMeasurement(
      startPointV3,
      horizontalEndPoint,
      horizontalMeasurementDir,
      undefined,
      true
    );
    this.verticalMeasurement.updateMeasurement(
      verticalBeginPoint,
      {
        x: verticalBeginPoint.x,
        y: endPoint.y,
        z: 0,
      },
      verticalMeasurementDir,
      undefined,
      true
    );
  }

  private recreateVisualization(
    nodes: PointNode[],
    segments: Segment[],
    mountings: MountingAttachment[],
    thickness
  ) {
    this.selectedNodes.forEach((v, k) => {
      v.dispose();
    });
    this.selectedNodes.clear();
    this.selectedSegments.forEach((v, k) => {
      v.dispose();
    });
    this.selectedSegments.clear();
    this.selectedMountingsMeshes.forEach((v, k) => {
      v.dispose();
    });
    this.selectedMountingsMeshes = [];
    for (const n of nodes) {
      if (!!n) {
        const nodeMesh = createNodeMesh(n, this.scene);
        this.selectedNodes.set(n, nodeMesh);
      }
    }
    for (const s of segments) {
      if (!!s) {
        const segmentMesh = createPolylineMesh(s, this.scene);
        this.selectedSegments.set(s, segmentMesh);
      }
    }

    this.selectedMountings = mountings;
    const groups = groupByShape(mountings);
    for (const group of groups) {
      const mesh = createMountingMeshFrom(
        group[0].mountingRef,
        group[0].shape,
        this.scene,
        thickness,
        group[0].rotation,
        group.map((m) => m.position)
      );
      mesh.material = this.selectionMountingMaterial;
      mesh.renderingGroupId = 3;
      mesh.occlusionType = AbstractMesh.OCCLUSION_TYPE_NONE;
      mesh.setBoundingInfo(
        new BoundingInfo(
          new B_Vector3(
            -Number.MAX_VALUE,
            -Number.MAX_VALUE,
            -Number.MAX_VALUE
          ),
          new B_Vector3(
            Number.MAX_VALUE,
            Number.MAX_VALUE,
            Number.MAX_VALUE
          )
        )
      );
      this.selectedMountingsMeshes.push(mesh);
    }
  }

  private moveInDirection(moveVector: Vector2) {
    const babylonMoveSum = new B_Vector3(
      this._moveSum.x,
      this._moveSum.y,
      0
    ).scale(-1);
    const babylonMoveVector = new B_Vector3(
      moveVector.x,
      moveVector.y,
      0
    );
    const move = babylonMoveSum.addInPlace(babylonMoveVector);
    this.selectedNodes.forEach((mesh, k) => {
      mesh.position.addInPlace(move);
    });
    this.selectedSegments.forEach((mesh, k) => {
      mesh.position.addInPlace(move);
    });
    this.selectedMountingsMeshes.forEach((mesh) => {
      mesh.position.addInPlace(move);
    });
    this.prevPosition = addVectors3(this.absoluteDragStartPosition, {
      x: moveVector.x,
      y: moveVector.y,
      z: 0,
    });
    this._moveSum = moveVector;
  }

  private multiSelect(position: Vector3) {
    const startPoint = this.startDragPosition;
    const endPoint = position;
    const aabb = createAabb2FromTwoPoints(startPoint, endPoint);
    this.drawSelectionMesh(startPoint, endPoint);
    const withPerforation = this.snapOptions.perforationArea;
    const nodes = this.getNodesInAabb(this.nodes, aabb).filter((v) =>
      withPerforation
        ? v.origin !== ShapeOrigin.TEMPORARY
        : v.origin === ShapeOrigin.SHAPE
    );
    this.store.dispatch(new SetNodesSelection(nodes));
    if (this.snapOptions.mountingHoles) {
      const mountings = this.getMountingsInAabb(this.mountings, aabb);
      this.store.dispatch(new SetMountingsSelection(mountings));
    }
  }

  private getNodesInAabb(nodes: PointNode[], aabb: Aabb2): PointNode[] {
    const selectedNodes: PointNode[] = [];
    nodes.forEach((v, k) => {
      if (isPointInAabb2(v.position, aabb)) {
        selectedNodes.push(v);
      }
    });
    return selectedNodes;
  }

  private getMountingsInAabb(
    mountings: MountingAttachment[],
    aabb: Aabb2
  ): MountingAttachment[] {
    const selectedMountings: MountingAttachment[] = [];
    for (const v of mountings) {
      if (isPointInAabb2(v.position, aabb)) {
        selectedMountings.push(v);
      }
    }
    return selectedMountings;
  }

  private drawSelectionMesh(start: Vector3, end: Vector3) {
    if (this.selectionMesh) {
      this.selectionMesh.dispose();
      this.selectionMesh = null;
    }
    const center = new B_Vector3(
      (start.x + end.x) / 2.0,
      (start.y + end.y) / 2.0,
      start.z
    );
    const a = Math.abs(start.x - end.x);
    const b = Math.abs(start.y - end.y);
    if (a * b !== 0) {
      this.selectionMesh = MeshBuilder.CreatePlane(
        "selectionMesh",
        { width: a, height: b },
        this.scene
      );
      this.selectionMesh.material = this.selectionMaterial;
      this.selectionMesh.renderingGroupId = 3;
      this.selectionMesh.position = center;
    }
  }

  onMouseClickOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {
    const objectUnderPointer = (
      this.lastObjectUnderPointer as PerforationAreaCollider
    ).object;
    this.store.dispatch(new SelectPerforationArea(objectUnderPointer));
  }

  onMouseDownOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {
    this.onMouseDownOnGridOrNone(pointerPosition, ctrl);
  }

  onMouseMoveOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {}

  public setFreeMode() {
    this.reset();
    this.selectedMode.next(SelectToolMode.FREE);
    this.usageMode = SelectToolMode.FREE;
  }

  public setVectorMode() {
    this.reset();
    this.selectedMode.next(SelectToolMode.VECTOR);
    this.usageMode = SelectToolMode.VECTOR;
  }

  public getSelectedMode(): Observable<SelectToolMode> {
    return this.selectedMode;
  }

  private updateArrowPosition() {
    const position = addVectors2(this.absoluteDragStartPosition, this._moveSum);
    this.horizontalDragArrowModel.setModel({
      ...this.horizontalDragArrowModel.getModel(),
      visible: true,
      position: { x: position.x, y: position.y },
    });
    this.verticalDragArrowModel.setModel({
      ...this.verticalDragArrowModel.getModel(),
      visible: true,
      position: { x: position.x, y: position.y },
    });
  }
}
