import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  throwError as observableThrowError,
} from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { MevacoResponse } from "../model/client-server.Params/mevaco-response.model";

@Injectable({
  providedIn: "root",
})
export class PerfactionService {
  private perfactionImageFileSbj = new BehaviorSubject<File>(null);
  private perfactionImageFileObservable =
    this.perfactionImageFileSbj.asObservable();

  imageFileObservable = () => this.perfactionImageFileObservable;

  pushNextFile = (file: File) => this.perfactionImageFileSbj.next(file);

  dispatchRemoveImage = () => this.perfactionImageFileSbj.next(null);

  constructor(private http: HttpClient) {}

  public uploadPerfactionFile(file: File, configId: string): Observable<any> {
    const formData = new FormData();
    formData.append(file.name, file);
    formData.append("configId", configId);

    return this.http
      .post<MevacoResponse<number>>(
        environment.api_url + "/api/perfaction/uploadPerfactionFile",
        formData
      )
      .pipe(
        map((v) => {
          return v;
        }),
        catchError((e) => observableThrowError(e))
      );
  }

  public deletePerfactionImage(imageId: number): Observable<any> {
    return this.http
      .delete<MevacoResponse<any>>(
        environment.api_url +
          "/api/perfaction/deletePerfactionFile?id=" +
          imageId
      )
      .pipe(catchError((e) => observableThrowError(e)));
  }

  public getPerfactionImage(imageId: number): Observable<any> {
    return this.http
      .get<any>(
        environment.api_url + "/api/perfaction/getPerfactionFile?id=" + imageId,
        { responseType: "blob" as "json" }
      )
      .pipe(
        map((res: Response) => {
          return res;
        }),
        catchError((e) => observableThrowError(e))
      );
  }
}
