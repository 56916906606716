<div class="perforation-section" [hidden]="(activeTool | async) !== assistedMountingTool && (activeTool | async) !== mountingTool && (activeTool | async) !== importMountingTool">
  <div class="tools-caption">
    {{translate("Attachment")}}
  </div>
  <div class="dropdowns-wrapper">
    <dropdown [model]="formDropdown" [dropdownMenuWidth]="232"></dropdown>
    <dropdown [model]="sizeDropdown" [dropdownMenuWidth]="232"></dropdown>
  </div>
</div>

<div [hidden]="(activeTool | async) !== assistedMountingTool" class="perforation-section">
  <div class="tools-caption">{{translate("Assisted tool", 'designer')}}</div>

  <div class="perforation-input-wrapper mt-10">
    <label for="numberOfHoles" class="perforation-label">
        <span>{{translate("Number of Holes", 'designer')}}:</span>
        <ng-container *ngIf="!(numberOfHoles.valueAsNumber >= 0) || !(numberOfHoles.valueAsNumber <= 50) || !(numberOfHoles.value.length > 0)">
          <img 
          class="perforation-hint perforation-hint--error"
          src="assets/hint-icon.svg" 
          tooltipClass="custom-tooltip"
          [ngbTooltip]="
          (numberOfHoles.valueAsNumber >= 0 ? '' : translate('Negative value' , 'designer')) + '. ' + 
          (numberOfHoles.valueAsNumber <= 50 ? '' : translate('MaxAllowedMountings', 'designer')) + '. ' +
          (numberOfHoles.value.length > 0 ? '' : translate('Value is required' , 'designer')) + '. '
          " alt="hint">
        </ng-container>
    </label>
    <input type="number" 
      class="perforation-input" 
      [class.perforation-input--error]="!(numberOfHoles.valueAsNumber >= 0) || !(numberOfHoles.valueAsNumber <= 50) || !(numberOfHoles.value.length > 0)"
      id="numberOfHoles" min="0" max="50" 
      [value]="assistedMountingTool.numberOfHoles  | async"
      (input)="isValueCorrect($event.target.value)" 
      #numberOfHoles/>
  </div>

  <div class="perforation-input-wrapper mt-10">
      <label for="spacing" class="perforation-label"> 
          <span>{{translate("Spacing", 'designer')}}:</span> 
          <ng-container *ngIf="!(spacing.valueAsNumber >= 0) || !(spacing.value.length > 0)">
            <img 
            class="perforation-hint perforation-hint--error"
            src="assets/hint-icon.svg" 
            tooltipClass="custom-tooltip"
            [ngbTooltip]="
            (spacing.valueAsNumber >= 0 ? '' : translate('Negative value' , 'designer')) + '. ' + 
            (spacing.value.length > 0 ? '' : translate('Value is required' , 'designer')) + '. '
            " alt="hint">
          </ng-container>
      </label>
      <input type="number" 
      class="perforation-input" 
      [class.perforation-input--error]="!(spacing.valueAsNumber >= 0) || !(spacing.value.length > 0)"
      id="spacing" 
      min="0" 
      [value]="assistedMountingTool.spacing | async"
      (input)="assistedMountingTool.setSpacing($event.target.value)" 
      #spacing/>
  </div>

  <div class="perforation-input-wrapper mt-10">
    <label for="edgeDist" class="perforation-label"> 
      <span>{{translate("Distance from Edge", 'designer')}}:</span> 
      <ng-container *ngIf="!(edgeDist.valueAsNumber >= 0) || !(edgeDist.value.length > 0)">
          <img 
            class="perforation-hint perforation-hint--error"
            src="assets/hint-icon.svg" 
            tooltipClass="custom-tooltip"
            [ngbTooltip]="
            (edgeDist.valueAsNumber >= 0 ? '' : translate('Negative value' , 'designer')) + '. ' + 
            (edgeDist.value.length > 0 ? '' : translate('Value is required' , 'designer')) + '. '
            " alt="hint">
      </ng-container>
    </label>

    <input 
      type="number" 
      class="perforation-input" 
      [class.perforation-input--error]="!(edgeDist.valueAsNumber >= 0) || !(edgeDist.value.length > 0)"
      id="edgeDist" 
      min="0" 
      [value]="assistedMountingTool.edgeDist | async"
      (input)="assistedMountingTool.setEdgeDist($event.target.value)" 
      #edgeDist/>
  </div>

  <div class="perforation-input-wrapper mt-10">
    <label for="cornerDist" class="perforation-label"> 
      <span>{{translate("Distance from Corner", 'designer')}}:</span> 
      <ng-container *ngIf="!(cornerDist.valueAsNumber >= 0) || !(cornerDist.value.length > 0)">
          <img 
            class="perforation-hint perforation-hint--error"
            src="assets/hint-icon.svg" 
            tooltipClass="custom-tooltip"
            [ngbTooltip]="
            (cornerDist.valueAsNumber >= 0 ? '' : translate('Negative value' , 'designer')) + '. ' + 
            (cornerDist.value.length > 0? '' : translate('Value is required' , 'designer')) + '. '
            " alt="hint">
      </ng-container>
    </label>
    <input 
    type="number" 
    class="perforation-input" 
    [class.perforation-input--error]="!(cornerDist.valueAsNumber >= 0) || !(cornerDist.value.length > 0)"
    id="cornerDist" 
    min="0" 
    [value]="assistedMountingTool.cornerDist | async"
    (input)="assistedMountingTool.setCornerDist($event.target.value)" 
    #cornerDist/>
  </div>
</div>


<div [hidden]="(rotationObservable | async) === null" class="perforation-input-wrapper mt-20">
  <label class="perforation-label" for="rotation">
    <span>{{translate("Rotation", 'designer')}}:</span> 
      <ng-container *ngIf="!(rotation.value.length > 0) || !(rotation.valueAsNumber >= 0 && rotation.valueAsNumber <= 360)">
          <img 
            class="perforation-hint perforation-hint--error"
            src="assets/hint-icon.svg" 
            tooltipClass="custom-tooltip"
            [ngbTooltip]="
            (rotation.value.length > 0 ? '' : translate('Value is required' , 'designer')) + '. ' + 
            (rotation.valueAsNumber >= 0 && rotation.valueAsNumber <= 360 ? '' : translate('Incorrect value', 'designer')) + '. '
            " alt="hint">
      </ng-container>
  </label>
  <input 
  type="number" 
  class="perforation-input" 
  [class.perforation-input--error]="!(rotation.value.length > 0) || !(rotation.valueAsNumber >= 0 && rotation.valueAsNumber <= 360)"
  id="rotation" 
  min="0" 
  max="360" 
  [value]="round((rotationObservable | async) * (180/pi))"
  (input)="setRotation($event.target.value)" 
  #rotation/>
</div>
