<div *ngIf="(loaded | async) && (productType | async) !== null" class="root">
  <div *ngIf="(mode | async) === 'design'">
    <!-- TOP NAVIGATION CONTAINER -->
    <header class="header">
      <app-navigation></app-navigation>
    </header>
    <!-- CUSTOM PATTERN EDITOR WITH BACKDROP -->
    <ng-container *ngIf="customPatternEditorIsOpened | async">
      <custom-pattern-editor></custom-pattern-editor>
    </ng-container>

    <!-- PERFACTION EDITOR WITH BACKDROP -->
    <ng-container *ngIf="perfactionEditorIsOpened | async">
      <app-perfaction-editor></app-perfaction-editor>
    </ng-container>

    <!-- PAGE BODY CONTAINER -->
    <div class="body-container">
      <!-- POSITIONS CONTAINER - STEP 1 -->
      <div
        *ngIf="(step | async) === Step.cockpit"
        class="positions-container"
        [@openClose2]="{
          value: rightPanelWidth !== 0 ? 'open' : 'closed',
          params: { contentWidth2: contentWidth2 }
        }"
      >
        <positions-list [configuratorOpen]="configuratorOpen"></positions-list>
      </div>

      <!-- ELEMENT CREATOR - STEP 2 -->
      <div
        [@openClose2]="{
          value: rightPanelWidth !== 0 ? 'open' : 'closed',
          params: { contentWidth2: contentWidth2 }
        }"
        class="creator-container"
        *ngIf="
          (step | async) === Step.template ||
          (step | async) === Step.import ||
          (step | async) === Step.import_pattern ||
          (step | async) === Step.import_attachment
        "
      >
        <div class="is-blurred" *ngIf="isImporting | async">
          <mat-spinner
            class="importing-spinner"
            mode="indeterminate"
          ></mat-spinner>
        </div>
        <element-creator></element-creator>

        <div
          class="import-dxf-container"
          *ngIf="
            (step | async) === Step.import ||
            (step | async) === Step.import_pattern ||
            (step | async) === Step.import_attachment
          "
        >
          <import-modal></import-modal>
        </div>
      </div>

      <!-- VIEWER WITH TOOLS CONTAINER -->
      <div
        class="viewer-container"
        *ngIf="
          (step | async) !== Step.template && (step | async) !== Step.cockpit
        "
      >
        <!-- LEFT PANEL WITH TOOLS FOR VIEWER -->
        <div
          class="tools-container"
          *ngIf="
            (step | async) !== Step.template &&
            (step | async) !== Step.cockpit &&
            (step | async) !== Step.design
          "
        >
          <div
            class="h-100"
            *ngIf="
              (step | async) !== Step.cockpit &&
              (step | async) !== Step.template &&
              (step | async) !== Step.import &&
              (step | async) !== Step.import_pattern &&
              (step | async) !== Step.import_attachment
            "
          >
            <div class="h-100" *ngIf="(step | async) !== Step.design">
              <tool-panel (contextmenu)="onContextMenu($event)"></tool-panel>
              <!-- <segment-properties></segment-properties> -->
            </div>
          </div>
        </div>

        <!-- TOP BAR WITH TOOLS FOR VIEWER -->
        <div
          class="topbar-container"
          [@openClose2]="{
            value: rightPanelWidth !== 0 ? 'open' : 'closed',
            params: { contentWidth2: contentWidth2 }
          }"
        >
          <div class="topbar-static-item">
            <undo-redo
              *ngIf="
                (step | async) !== Step.cockpit &&
                (step | async) !== Step.template &&
                (step | async) !== Step.import &&
                (step | async) !== Step.import_pattern &&
                (step | async) !== Step.import_attachment
              "
            ></undo-redo>
          </div>
          <div class="topbar-flexible-item">
            <top-bar
              *ngIf="
                (step | async) !== Step.import &&
                (step | async) !== Step.import_pattern &&
                (step | async) !== Step.import_attachment
              "
              [configuratorOpen]="configuratorOpen"
            ></top-bar>
          </div>
          <div *ngIf="(step | async) === Step.design && isMevacoUser | async">
            <div class="topbar-download-item" *ngIf="position">
              <button
                class="btn btn-primary btn-download"
                (click)="downloadDxf()"
              >
                {{ translate("Download Dxfs", "designer") }}
              </button>
              <button
                class="btn btn-primary btn-download"
                *ngIf="importId | async as impId"
                (click)="downloadImportDxf(impId)"
              >
                {{ translate("Download Imported Dxf", "designer") }}
              </button>
            </div>
          </div>
        </div>

        <!-- VIEWERS CONTAINERS DISPLAYED BASED ON STEP-->
        <ng-container *ngIf="customPatternEditorIsClosed | async">
          <div
            *ngIf="(step | async) === Step.shape"
            [@openClose1]="{
              value: rightPanelWidth !== 0 ? 'open' : 'closed',
              params: { contentWidth1: contentWidth1 }
            }"
            class="webcad-container"
            #hand
          >
            <webcad-host
              (contextmenu)="onContextMenu($event)"
              [material]="PlateMaterialType.shape"
              [configuratorOpen]="configuratorOpen"
            ></webcad-host>
          </div>

          <div
            *ngIf="(step | async) === Step.pattern"
            [@openClose1]="{
              value: rightPanelWidth !== 0 ? 'open' : 'closed',
              params: { contentWidth1: contentWidth1 }
            }"
            class="webcad-container"
          >
            <webcad-host
              (contextmenu)="onContextMenu($event)"
              [material]="PlateMaterialType.shape"
              [configuratorOpen]="configuratorOpen"
            ></webcad-host>
          </div>

          <div
            *ngIf="(step | async) === Step.attachment"
            [@openClose1]="{
              value: rightPanelWidth !== 0 ? 'open' : 'closed',
              params: { contentWidth1: contentWidth1 }
            }"
            class="webcad-container"
          >
            <webcad-host
              (contextmenu)="onContextMenu($event)"
              [material]="PlateMaterialType.shape"
              [configuratorOpen]="configuratorOpen"
            ></webcad-host>
          </div>

          <div
            *ngIf="(step | async) === Step.design"
            [@openClose3]="{
              value: rightPanelWidth !== 0 ? 'open' : 'closed',
              params: { contentWidth3: contentWidth3 }
            }"
            class="webcad-container webcad-container--wider webcad-container--lower"
          >
            <webcad-host
              (contextmenu)="onContextMenu($event)"
              [material]="PlateMaterialType.real"
              [configuratorOpen]="configuratorOpen"
            ></webcad-host>
          </div>
        </ng-container>
      </div>

      <!-- STICKY BOTTOM PANEL -->
      <div
        class="sticky-bottom-panel-container"
        *ngIf="
          (step | async) === Step.design || (step | async) === Step.cockpit
        "
        [@openClose2]="{
          value: rightPanelWidth !== 0 ? 'open' : 'closed',
          params: { contentWidth2: contentWidth2 }
        }"
      >
        <app-bottom-panel></app-bottom-panel>
      </div>

      <!-- RIGHT PANEL -->
      <app-right-panel></app-right-panel>

      <ng-container *ngIf="isMevacoUser | async">
        <div *ngIf="showDebug" class="debuggerContainer">
          <div>
            {{ translate("Pointer", "designer") }}:
            {{
              (pointerProvider.pointerState | async).intersection &&
                (pointerProvider.pointerState | async).intersection.collider &&
                (pointerProvider.pointerState | async).intersection.collider
                  .name
            }}
          </div>
          <div style="width: 3000px; height: 5000px">
            <ngx-json-viewer
              [json]="debugInfo | async"
              [expanded]="false"
            ></ngx-json-viewer>
            <span>{{ configId | async }}</span>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-primary btn-debugger"
          (click)="toggleDebugger()"
        >
          {{ translate("Debug", "designer") }}
        </button>
      </ng-container>
    </div>

    <selected-shape-context-menu #contextMenu></selected-shape-context-menu>
    <div
      *ngIf="isMevacoUser | async"
      [hidden]="(step | async) !== Step.design"
      style="margin-top: 20px; margin-bottom: 10px"
    ></div>
    <button class="downloadDxfBtn" (click)="downloadDxf()">
      {{ translate("Download Dxf", "designer") }}
    </button>
    <button
      class="downloadDxfBtn"
      *ngIf="importId | async as impId"
      (click)="downloadImportDxf(impId)"
    >
      {{ translate("Download Imported Dxf", "designer") }}
    </button>
  </div>

  <div *ngIf="(mode | async) === 'view'">
    <div class="topbar-container topbar-container--viewer-layout">
      <div class="topbar-flexible-item">
        <top-bar></top-bar>
      </div>
    </div>
    <app-viewer-layout [configId]="configId | async"></app-viewer-layout>
  </div>
</div>

<configurator-page *ngIf="(loaded | async) && (productType | async) === null"></configurator-page>

<!-- MODALS -->

<!-- WARNING MODAL CONTENT -->
<ng-template #modal let-modal>
  <div class="dialog-modal-header dialog-modal-header--small">
    <div class="dialog-modal-title-container">
      <div class="dialog-modal-title-content">
        <h4
          class="dialog-modal-title dialog-modal-title--small"
          id="modal-basic-title"
        >
          <img
            src="assets/tooltip-warning-icon.svg"
            alt="warning-icon"
            class="dialog-modal-title-img"
          />
          <span>{{ translate("Warning", "designer") }}</span>
        </h4>
      </div>
    </div>

    <button
      type="button"
      class="close btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross Click'); isWarningModal = false"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="dialog-modal-body dialog-modal-body--small">
    <p class="dialog-modal-description--small">
      {{ translate("NoHolePatternWarning", "designer") }}
    </p>
  </div>

  <div
    class="dialog-modal-footer dialog-modal-footer--small dialog-modal-footer--one-btn"
  >
    <button
      type="button"
      class="btn btn-primary dialog-modal-btn--small"
      (click)="modal.close()"
    >
      {{ translate("OK") }}
    </button>
  </div>
</ng-template>

<!-- ERROR MODAL CONTENT -->
<ng-template #errorModal let-modal>
  <div class="dialog-modal-header dialog-modal-header--small">
    <div class="dialog-modal-title-container">
      <div class="dialog-modal-title-content">
        <h4
          class="dialog-modal-title dialog-modal-title--small"
          id="modal-basic-title"
        >
          <img
            src="assets/tooltip-error-icon.svg"
            alt="error-icon"
            class="dialog-modal-title-img"
          />
          <span>{{ translate("Error") }}</span>
        </h4>
      </div>
    </div>

    <button
      type="button"
      class="close btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross Click'); isWarningModal = false"
    ></button>
  </div>

  <div class="dialog-modal-body dialog-modal-body--small">
    <p class="dialog-modal-description--small">{{ errorMessage.message }}</p>
  </div>

  <div
    class="dialog-modal-footer dialog-modal-footer--small dialog-modal-footer--one-btn"
  >
    <button
      #closeButton
      type="button"
      class="btn btn-primary dialog-modal-btn--small"
      (click)="modal.close(true)"
    >
      {{ translate("OK") }}
    </button>
  </div>
</ng-template>

<design-modal *ngIf="isStartPopup | async"></design-modal>
