import {SnappingModel} from "../../model/snapping.model";
import {addVectors2, sqrDistanceVector2, Vector2} from "webcad/math";
import {Segment, segmentSegmentIntersection, SegmentType} from "webcad/models";

export function snapToGrid(position: Vector2, segment:Segment, snappingModel:SnappingModel, epsilon:number):Vector2{
  const snapSegments = getClosestSnapLinesAsSegments(position, snappingModel);
  let result = position;
  let minSqrDist:number = 100000;
  for (let i = 0; i < snapSegments.length; i++) {
    const snapSegment = snapSegments[i];
    const thisPoint = segmentSegmentIntersection(snapSegment, segment);
    if(!!thisPoint) {
      const thisSqrDist = sqrDistanceVector2(thisPoint, position);
      if (thisSqrDist <= epsilon * epsilon && thisSqrDist < minSqrDist) {
        result = thisPoint;
        minSqrDist = thisSqrDist;
      }
    }
  }
  return result;
}

function getClosestSnapLinesAsSegments(position: Vector2, snappingModel:SnappingModel): Segment[] {
  const closestX = Math.round((position.x - snappingModel.offset.x) / snappingModel.snapX) * snappingModel.snapX + snappingModel.offset.x;
  const closestY = Math.round((position.y - snappingModel.offset.y) / snappingModel.snapY) * snappingModel.snapY + snappingModel.offset.y;
  const closestXminus = Math.round((position.x - snappingModel.offset.x) / snappingModel.snapX) * snappingModel.snapX - snappingModel.offset.x;
  const closestYminus = Math.round((position.y - snappingModel.offset.y) / snappingModel.snapY) * snappingModel.snapY - snappingModel.offset.y;
  return [
    {
      type: SegmentType.line,
      begin: addVectors2({x: closestX, y: closestY}, {x: 0, y: -5}),
      end: addVectors2({x: closestX, y: closestY}, {x: 0, y: 5})
    },
    {
      type: SegmentType.line,
      begin: addVectors2({x: closestXminus, y: closestYminus}, {x: 0, y: -5}),
      end: addVectors2({x: closestXminus, y: closestYminus}, {x: 0, y: 5})
    },
    {
      type: SegmentType.line,
      begin: addVectors2({x: closestX, y: closestY}, {x: -5, y: 0}),
      end: addVectors2({x: closestX, y: closestY}, {x: 5, y: 0})
    },
    {
      type: SegmentType.line,
      begin: addVectors2({x: closestXminus, y: closestYminus}, {x: -5, y: 0}),
      end: addVectors2({x: closestXminus, y: closestYminus}, {x: 5, y: 0})
    }
  ];
}
