import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Guid } from "guid-typescript";
import { Observable, Subscription, fromEvent } from "rxjs";
import { map } from "rxjs/operators";
import { Vector2, clip } from "webcad/math";
import {
  LineSegmentsFromPath,
  PolylineArea,
  aabbOfPolyline,
} from "webcad/models";
import {
  BalconyCorner,
  BalconyCornerInputFlags,
} from "../../../../../../src/app/model/basic-templates.model";
import {
  Element,
  ElementType,
} from "../../../../../../src/app/model/product-configuration/element.model";
import {
  ShapeWithHoles,
  shapeWithHolesHash,
} from "../../../../../../src/app/model/shape-with-holes";
import { UserTemplate } from "../../../../../../src/app/model/user-template.model";
import {
  MevacoState,
  ProductConfigurationAddElement,
  getBalconyCornerTemplate,
  getUserTemplates,
  reqValBalconyCorner,
} from "../../../../../../src/app/store";
import {
  SetBalconyCornerA,
  SetBalconyCornerB,
  SetBalconyCornerB1,
  SetBalconyCornerB2,
  SetBalconyCornerE1,
  SetBalconyCornerE2,
  SetBalconyCornerF1,
  SetBalconyCornerF2,
  SetBalconyCornerF3,
  SetBalconyCornerNotchX,
  SetBalconyCornerNotchY,
  SetBalconyCornerTemplate,
} from "../../../../../../src/app/store/actions/element-creator.actions";
import { GetUserTemplates } from "../../../../../../src/app/store/actions/user-template.actions";
import { TemplateName } from "../../template-name";
import { TemplateUtils } from "../../templatesUtils";

@Component({
  selector: "mevaco-balcony-corner",
  templateUrl: "./balcony-corner.component.html",
  styleUrls: [
    "../../../element-creator/element-creator.component.css",
    "./balcony-corner.component.css",
  ],
})
export class BalconyCornerComponent implements OnInit {
  @Output() dismissModal = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() isModalOpen = new EventEmitter();

  @ViewChild("ba", { static: true }) ba: ElementRef;
  @ViewChild("bb", { static: true }) bb: ElementRef;
  @ViewChild("be1", { static: true }) be1: ElementRef;
  @ViewChild("be2", { static: true }) be2: ElementRef;
  @ViewChild("bb1", { static: true }) bb1: ElementRef;
  @ViewChild("bb2", { static: true }) bb2: ElementRef;
  @ViewChild("bf1", { static: true }) bf1: ElementRef;
  @ViewChild("bf2", { static: true }) bf2: ElementRef;
  @ViewChild("bf3", { static: true }) bf3: ElementRef;
  @ViewChild("bx1", { static: true }) bx1: ElementRef;
  @ViewChild("by1", { static: true }) by1: ElementRef;
  @ViewChild("bx2", { static: true }) bx2: ElementRef;
  @ViewChild("by2", { static: true }) by2: ElementRef;
  @ViewChild("bx3", { static: true }) bx3: ElementRef;
  @ViewChild("by3", { static: true }) by3: ElementRef;
  @ViewChild("bx4", { static: true }) bx4: ElementRef;
  @ViewChild("by4", { static: true }) by4: ElementRef;
  @ViewChild("bx5", { static: true }) bx5: ElementRef;
  @ViewChild("by5", { static: true }) by5: ElementRef;
  @ViewChild("closeButton", { static: true })
  closeButton: ElementRef;

  public templates: Observable<UserTemplate[]>;
  public balconyCornerTemplate: BalconyCorner;
  public reqValBalconyCorner: Observable<boolean>;

  private balconyCornerInputFlags: BalconyCornerInputFlags;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<MevacoState>,
    public templateUtils: TemplateUtils
  ) {}

  ngOnInit() {
    this.createFromStaircaseTemplate();
    this.balconyCornerInputFlags = {
      x1: false,
      x2: false,
      x3: false,
      x4: false,
      x5: false,
      y1: false,
      y2: false,
      y3: false,
      y4: false,
      y5: false,
    };
    this.store.dispatch(new GetUserTemplates());
    this.templates = this.store.pipe(select(getUserTemplates));
    this.subscriptions.push(
      this.store
        .pipe(select(getBalconyCornerTemplate))
        .subscribe((v) => (this.balconyCornerTemplate = { ...v }))
    );
    this.reqValBalconyCorner = this.store.pipe(select(reqValBalconyCorner));
  }

  isModalOpenMethod() {
    this.isModalOpen.emit(false);
  }

  dismiss(str: string) {
    this.dismissModal.emit(str);
  }

  close(input: {}) {
    this.closeModal.emit(input);
  }

  createFromStaircaseTemplate() {
    const obs = fromEvent(this.closeButton.nativeElement, "click").pipe(
      map(() => {
        return {
          ...this.balconyCornerTemplate,
        };
      })
    );

    obs.subscribe((result) => {
      const a = result.a / 1000;
      const b1 = result.b1 / 1000;
      const b2 = result.b2 / 1000;
      const b = b1 + b2;
      let e1 = result.e1 / 1000;
      let e2 = result.e2 / 1000;
      let f1 = result.f1 / 1000;
      let f2 = result.f2 / 1000;
      const f3 = result.f3 / 2000;
      const x1 = result.x1 / 1000;
      const x2 = result.x2 / 1000;
      const x3 = result.x3 / 1000;
      const x4 = result.x4 / 1000;
      const x5 = result.x5 / 2000;
      const y1 = result.y1 / 1000;
      const y2 = result.y2 / 1000;
      const y3 = result.y3 / 1000;
      const y4 = result.y4 / 1000;
      const y5 = result.y5 / 1000;
      const leftDownNotchPoint: Vector2 =
        x3 === 0 || y3 === 0
          ? {
              x: 0,
              y: 0,
            }
          : {
              x: x3,
              y: y3,
            };
      const leftDownNotch: Vector2[] =
        x3 === 0 || y3 === 0
          ? [
              {
                x: 0,
                y: 0,
              },
            ]
          : [
              {
                x: 0,
                y: y3,
              },
              {
                x: x3,
                y: y3,
              },
              {
                x: x3,
                y: 0,
              },
            ];
      const rightDownNotchPoint: Vector2 =
        x2 === 0 || y2 === 0
          ? {
              x: b,
              y: 0,
            }
          : {
              x: b - x2,
              y: y2,
            };
      const rightDownNotch: Vector2[] =
        x2 === 0 || y2 === 0
          ? [
              {
                x: b,
                y: 0,
              },
            ]
          : [
              {
                x: b - x2,
                y: 0,
              },
              {
                x: b - x2,
                y: y2,
              },
              {
                x: b,
                y: y2,
              },
            ];
      const rightUpNotchPoint: Vector2 =
        x1 === 0 || y1 === 0
          ? {
              x: b,
              y: a,
            }
          : {
              x: b - x1,
              y: a - y1,
            };
      const rightUpNotch: Vector2[] =
        x1 === 0 || y1 === 0
          ? [
              {
                x: b,
                y: a,
              },
            ]
          : [
              {
                x: b,
                y: a - y1,
              },
              {
                x: b - x1,
                y: a - y1,
              },
              {
                x: b - x1,
                y: a,
              },
            ];
      const leftUpNotchPoint: Vector2 =
        x4 === 0 || y4 === 0
          ? {
              x: 0,
              y: a,
            }
          : {
              x: x4,
              y: a - y4,
            };
      const leftUpNotch: Vector2[] =
        x4 === 0 || y4 === 0
          ? [
              {
                x: 0,
                y: a,
              },
            ]
          : [
              {
                x: x4,
                y: a,
              },
              {
                x: x4,
                y: a - y4,
              },
              {
                x: 0,
                y: a - y4,
              },
            ];
      const downNotchPoint: Vector2 = {
        x: b1,
        y: y5,
      };
      const downNotch: Vector2[] = [
        {
          x: b1 - x5,
          y: 0,
        },
        {
          x: b1,
          y: y5,
        },
        {
          x: b1 + x5,
          y: 0,
        },
      ];
      const upNotchPoint: Vector2 = {
        x: b1,
        y: a - y5,
      };
      const upNotch: Vector2[] = [
        {
          x: b1 + x5,
          y: a,
        },
        {
          x: b1,
          y: a - y5,
        },
        {
          x: b1 - x5,
          y: a,
        },
      ];
      const path = [
        ...leftDownNotch,
        ...downNotch,
        ...rightDownNotch,
        ...rightUpNotch,
        ...upNotch,
        ...leftUpNotch,
      ];
      const shapeWithHoles: ShapeWithHoles = {
        holes: [],
        conture: LineSegmentsFromPath(path),
      };
      shapeWithHoles.aabb = aabbOfPolyline(shapeWithHoles.conture);
      shapeWithHoles.area = PolylineArea(shapeWithHoles.conture);
      shapeWithHoles.hash = shapeWithHolesHash(shapeWithHoles);
      if (f1 < x4 || f1 < x3) {
        f1 = x4 > x3 ? x4 : x3;
      }
      if (e1 < y3 || e1 < y2 || e1 < y5) {
        e1 = y3 > y2 ? Math.max(y3, y5) : Math.max(y2, y5);
      }
      if (f2 < x1 || f2 < x2) {
        f2 = x1 > x2 ? x1 : x2;
      }
      if (e2 < y1 || e2 < y4 || e1 < y5) {
        e2 = y1 > y4 ? Math.max(y1, y5) : Math.max(y4, y5);
      }
      const ldpt = { x: f1, y: e1 };
      const rdpt = { x: downNotchPoint.x - f3, y: e1 };
      const rupt = { x: upNotchPoint.x - f3, y: a - e2 };
      const lupt = { x: f1, y: a - e2 };
      const perforationPathOne = [ldpt, rdpt, rupt, lupt];
      const perforationAreaOne = LineSegmentsFromPath(perforationPathOne);
      const perfAabbOne = aabbOfPolyline(perforationAreaOne);
      const perfAreaOne = PolylineArea(perforationAreaOne);
      const perforationAreaShapeOne: ShapeWithHoles = {
        conture: perforationAreaOne,
        holes: [],
        aabb: perfAabbOne,
        area: perfAreaOne,
      };
      perforationAreaShapeOne.hash = shapeWithHolesHash(
        perforationAreaShapeOne
      );
      const ldpt2 = { x: downNotchPoint.x + f3, y: e1 };
      const rdpt2 = { x: b - f2, y: e2 };
      const rupt2 = { x: b - f2, y: a - e2 };
      const lupt2 = { x: upNotchPoint.x + f3, y: a - e2 };
      const perforationPathTwo = [ldpt2, rdpt2, rupt2, lupt2];
      const perforationAreaTwo = LineSegmentsFromPath(perforationPathTwo);
      const perfAabbTwo = aabbOfPolyline(perforationAreaTwo);
      const perfAreaTwo = PolylineArea(perforationAreaTwo);
      const perforationAreaShapeTwo: ShapeWithHoles = {
        conture: perforationAreaTwo,
        holes: [],
        aabb: perfAabbTwo,
        area: perfAreaTwo,
      };
      perforationAreaShapeTwo.hash = shapeWithHolesHash(
        perforationAreaShapeTwo
      );
      const element: Element = {
        type: ElementType.individual,
        templateName: TemplateName.balconyCorner,
        boundary: null,
        position: -1, // will be set inb reducer
        quantity: 1,
        a: a,
        b: b,
        aIst: a,
        bIst: b,
        e1: e1.toString(),
        e1ist: e1.toString(),
        e2: e2.toString(),
        e2ist: e2.toString(),
        f1: f1.toString(),
        f1ist: f1.toString(),
        f2: f2.toString(),
        f2ist: f2.toString(),
        openMeshE: "",
        openMeshF: "",
        toleranceWidth: 0,
        toleranceLength: 0,
        label: "",
        note: "",
        unperforated: false,
        posibleCoil: "No",
        posiblePlate: "No",
        shapes: null,
        nodes: null,
        verticesIndexes: null,
        visualizationShape: null,
        previewImageId: Guid.create().toString(),
        shape: shapeWithHoles,
        perforationAreas: [
          {
            shape: perforationAreaShapeOne,
            rotation: 0,
            offset: { x: 0, y: 0 },
          },
          {
            shape: perforationAreaShapeTwo,
            rotation: 0,
            offset: { x: 0, y: 0 },
          },
        ],

        helpLines: [],
        measurements: [],
        angleMeasurements: [],
        mountings: [],
        perforationAutoCenter: true,
        area: shapeWithHoles.area,
        minRadius: null,
        minParallelEdgesDistance: null,
        minMarginDistance: null,
        isPerforationSimpleRect: null,
        isShapeSimpleRect: null,
        minMountingHoleEdgeDistance: null,
        minMountingHolesDistance: null,
        minOutsideAngle: null,
        numberOfArcs: null,
        minDistanceBetweenEdges: null,
        minDistanceBetweenMountingAndPerforatedArea: null,
        //perforation: null,
        possibleAllAcross: true,
        minDistanceBetweenPerforationAreas: null,
        lfbIst: undefined,
        lflIst: undefined,

        breakLines: [],
        bendingLinesDistances: [],
        //possiblePerforationZones: []
      };
      this.store.dispatch(new ProductConfigurationAddElement(element));
    });
  }

  setBalconyCornerTemplate(template: BalconyCorner) {
    this.store.dispatch(new SetBalconyCornerTemplate({ ...template }));
  }

  setBalconyCornerA(newA: any) {
    this.store.dispatch(new SetBalconyCornerA(newA));
  }

  setBalconyCornerB(newB: any) {
    this.store.dispatch(new SetBalconyCornerB(newB));
  }

  setBalconyCornerB1(newB1: any) {
    this.store.dispatch(new SetBalconyCornerB1(newB1));
  }

  setBalconyCornerB2(newB2: any) {
    this.store.dispatch(new SetBalconyCornerB2(newB2));
  }

  setBalconyCornerE1(newE1: any) {
    this.store.dispatch(new SetBalconyCornerE1(newE1));
  }

  setBalconyCornerE2(newE2: any) {
    this.store.dispatch(new SetBalconyCornerE2(newE2));
  }

  setBalconyCornerF1(newF1: any) {
    this.store.dispatch(new SetBalconyCornerF1(newF1));
  }

  setBalconyCornerF2(newF2: any) {
    this.store.dispatch(new SetBalconyCornerF2(newF2));
  }

  setBalconyCornerF3(newF3: any) {
    this.store.dispatch(new SetBalconyCornerF3(newF3));
  }

  setBalconyCornerNotchX(index: number, v: any) {
    this.store.dispatch(
      new SetBalconyCornerNotchX({
        index: index,
        x: clip(v, this.balconyCornerTemplate.b * 0.5),
        y: clip(
          this.balconyCornerTemplate["y" + index.toString()],
          this.balconyCornerTemplate.a * 0.5
        ),
        fill: this.balconyCornerInputFlags,
      })
    );
    this.balconyCornerInputFlags["x" + index.toString()] = true;
  }

  setBalconyCornerNotchY(index: number, v: any) {
    this.store.dispatch(
      new SetBalconyCornerNotchY({
        index: index,
        x: clip(
          this.balconyCornerTemplate["x" + index.toString()],
          this.balconyCornerTemplate.b * 0.5
        ),
        y: clip(v, this.balconyCornerTemplate.a * 0.5),
        fill: this.balconyCornerInputFlags,
      })
    );
    this.balconyCornerInputFlags["y" + index.toString()] = true;
  }
}
