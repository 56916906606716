import {Vector2} from "webcad/math";

export interface SnappingModel {
  offset: Vector2;
  size: Vector2;
  snapX: number;
  snapY: number;
  majorLineFrequency: number;
}

export const initialSnappingModel: SnappingModel = {
  offset: {x: 0, y: 0},
  size: {x: 10, y: 10},
  snapY: 0.1,
  snapX: 0.1,
  majorLineFrequency: 1
};
