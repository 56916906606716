import {Action} from '@ngrx/store';
import {ConfigurationItem} from '../../model/product-configuration/configuration-item.model';
import {SET_PERFORATION_AREA, SET_PLATE, /*SET_ZONES, */UPDATE_DRAWING} from './plate.actions';
import {
  CLEAR_ALL_ELEMENTS, COPY_POSITION_SUCCESS, DUPLICATE_CONFIGURATION_SUCCESS,
  PERFORATION_FORMAT_CHANGE,
  PERFORATION_GEOMETRY_CHANGE,
  PERFORATION_POSITION_CHANGE,
  PERFORATION_SHAPE_CHANGE,
  PERFORATION_SIZE_CHANGE,
  PRODUCT_CONFIGURATION_ADD_ELEMENT,
  PRODUCT_CONFIGURATION_ONLY_ELEMENT,
  PRODUCT_CONFIGURATION_EDIT_ELEMENT,
  PRODUCT_CONFIGURATION_REMOVE_ELEMENT,
  PRODUCT_CONFIGURATION_REMOVE_ALL_ELEMENTS,
  PRODUCT_CONFIGURATION_SET_COLOR,
  PRODUCT_CONFIGURATION_SET_COLOR_GROUP,
  PRODUCT_CONFIGURATION_SET_COLOR_SYSTEM,
  PRODUCT_CONFIGURATION_SET_FOIL,
  PRODUCT_CONFIGURATION_SET_LEVEL_OF_GLOSS,
  PRODUCT_CONFIGURATION_SET_MATERIAL,
  PRODUCT_CONFIGURATION_SET_MATERIAL_THICKNESS,
  PRODUCT_CONFIGURATION_SET_P1,
  PRODUCT_CONFIGURATION_SET_P2,
  PRODUCT_CONFIGURATION_SET_POLISHING,
  PRODUCT_CONFIGURATION_SET_QUALITY,
  PRODUCT_CONFIGURATION_SET_SURFACE_TYPE,
  PRODUCT_CONFIGURATION_SET_UNDERCOATING,
  SET_NOTE,
  SET_NOTE_ACTIVE, SET_PERFORATION_AUTO_CENTER,
  SET_QUANTITY,
  PRODUCT_CONFIGURATION_LOADED_SUCCESSFULLY,
  WALLPAPER_ZOOM_UPDATE,
  WALLPAPER_ALLOW_HOLES_UPDATE,
  SET_MIN_MAX_REANDER, WALLPAPER_CHANGE,
} from './product-configuration.actions';
import {
  ADD_ANGLE_MEASUREMENT,
  ADD_HELP_LINE,
  ADD_MEASUREMENT,
  REMOVE_ANGLE_MEASUREMENT, REMOVE_AUTOMATIC_MEASUREMENT,
  REMOVE_HELP_LINE,
  REMOVE_MEASUREMENT,
  SET_CURRENT_PERFORATION_AREA_ROTATION
} from './drawing.actions';
import {
  ADD_MOUNTING,
  ADD_MOUNTINGS,
  CHANGE_PERFORATION_OFFSET,
  MOVE_MOUNTING_BY_VECTOR,
  REMOVE_MOUNTINGS
} from './mountings.actions';
import {CALCULATE_SHAPE_FROM_POLYLINES_SUCCESS, LOAD_DXF_SUCCESS, SET_STEP, UPDATE_MOUNTINGS} from './mevaco-page.actions';
import {CLEAR_PERFORATION, SET_PERFORATION_SUCCESS, SET_SELECTED_PERFORATION_AREA_OFFSET} from './perforation.actions';
import {DATASET_LOADED_SUCCESSFULLY, MATERIAL_FOR_WALLPAPER_LOADED_SUCCESSFULLY} from './dataset.actions';
import {RuleAction} from '../../model/rule-action.model';

export const SET_LOMOE = 'SET LOMOE';

export class SetLomoe implements Action {
  readonly type: string = SET_LOMOE;

  constructor(public payload: ConfigurationItem) {
  }
}
export const FILTER_VALIDATIONS = 'FILTER_VALIDATIONS';
export class FilterValidations implements Action {
  readonly type: string = FILTER_VALIDATIONS;
  constructor() {}
}

export const SET_MATERIAL = 'SET MATERIAL';

export class SetMaterial implements Action {
  readonly type: string = SET_MATERIAL;

  constructor(public payload: any) {
  }
}

export const VALIDATE_CONFIG = 'VALIDATE_CONFIG';

export class ValidateConfig implements Action {
  readonly type: string = VALIDATE_CONFIG;

  constructor(public payload?: any) {
  }

}
export type SaveActions = SetLomoe | SetMaterial;
export const ELEMENT_UPDATE_TYPES: string[] = [
  SET_PERFORATION_SUCCESS,
  CLEAR_PERFORATION,
  ADD_MOUNTING,
  ADD_MEASUREMENT,
  REMOVE_MEASUREMENT,
  ADD_ANGLE_MEASUREMENT,
  REMOVE_ANGLE_MEASUREMENT,
  ADD_MOUNTINGS,
  MOVE_MOUNTING_BY_VECTOR,
  REMOVE_MOUNTINGS,
  ADD_HELP_LINE,
  REMOVE_HELP_LINE,
  UPDATE_DRAWING,
  SET_PERFORATION_AREA,
  CHANGE_PERFORATION_OFFSET,
  SET_CURRENT_PERFORATION_AREA_ROTATION,
  SET_PERFORATION_AUTO_CENTER,
  DUPLICATE_CONFIGURATION_SUCCESS,
  REMOVE_AUTOMATIC_MEASUREMENT,
  /*SET_ZONES,*/
  SET_SELECTED_PERFORATION_AREA_OFFSET
];

export const START_SAVE_TYPES: string[] = [
  SET_PERFORATION_SUCCESS,
  CLEAR_PERFORATION,
  ADD_MOUNTING,
  ADD_MEASUREMENT,
  REMOVE_MEASUREMENT,
  ADD_ANGLE_MEASUREMENT,
  REMOVE_ANGLE_MEASUREMENT,
  ADD_MOUNTINGS,
  MOVE_MOUNTING_BY_VECTOR,
  REMOVE_MOUNTINGS,
  ADD_HELP_LINE,
  REMOVE_HELP_LINE,
  SET_NOTE,
  SET_QUANTITY,
  SET_NOTE_ACTIVE,
  CLEAR_ALL_ELEMENTS,
  PRODUCT_CONFIGURATION_REMOVE_ELEMENT,
  PRODUCT_CONFIGURATION_REMOVE_ALL_ELEMENTS,
  COPY_POSITION_SUCCESS,
  SET_STEP,
  DUPLICATE_CONFIGURATION_SUCCESS,
  REMOVE_AUTOMATIC_MEASUREMENT,
  /*SET_ZONES,*/
  MATERIAL_FOR_WALLPAPER_LOADED_SUCCESSFULLY,
  SET_MIN_MAX_REANDER
];
