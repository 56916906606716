<!-- <div [class]="errorsClasses.join(' ')" *ngIf="overallErrors.length !== 0" (click)="onErrorsClick()">
  <div class="errorContainer">
    <div class="hasErrors">
      <img *ngIf="errorsClasses[1] === 'error'" class="errorImg" src="../../../assets/Icon_Ausrufezeichen.svg" />
      <img *ngIf="errorsClasses[1] === 'warning'" class="errorImg" src="../../../assets/warning-sign-on-a-triangular-background.png" />
      <img *ngIf="errorsClasses[1] === 'hint'" class="errorImg" src="../../../assets/question.png" />
    </div>
    <div class="errorText">
      {{translate("Overall Issues", 'designer')}}
    </div>
      <div class="buttonDown"><div class="buttonDownImg"></div></div>
    </div>

    <div *ngFor="let error of displayErrors">
      <div class="errorIssueContainer">
        [{{error.ruleId}}] {{translate(error.code, 'rule')}}
      </div>
    </div>
</div> -->


<div class="message-visualizer-body">

  <section class="message-section" *ngIf="errorMessages.length > 0">
    <header class="message-section-header">
      <h2 class="message-section-title">
        <img class="message-section-img" src="assets/tooltip-error-icon.svg" alt="icon">
        <span class="message-section-text message-section-text--error">{{translate('Errors', 'designer')}}</span>
      </h2>
    </header>
    <div class="message" *ngFor="let message of sortMessages(errorMessages)">
      <h4 class="message-title">
        <span class="message-title--error">{{"Error [" + message.ruleId + "]"}}</span>
        <span>{{
            message.position === -1 ?
            translate('Overall', 'designer') :
            (translate('Rule Position', 'designer') + ' ' + message.position)
          }}
        </span>
      </h4>
      <p class="message-description" [innerHTML]="translate(message.code, 'rule')"></p>
      <!-- <div class="message-btn-wrapper">
        <button type="button" class="message-btn">
            <div class="message-btn-arrow-wrapper">
              <img src="assets/down-arrow.svg" alt="arrow" class="message-btn-arrow">
            </div>
            <span>{{translate('Go to', 'designer')}}</span>
        </button>
      </div> -->
    </div>
  </section>


  <section class="message-section" *ngIf="warningMessages.length > 0">
    <header class="message-section-header">
      <h2 class="message-section-title">
        <img class="message-section-img" src="assets/tooltip-warning-icon.svg" alt="icon">
        <span class="message-section-text message-section-text--warning">{{translate('Warnings', 'designer')}}</span>
      </h2>
    </header>
    <div class="message" *ngFor="let message of sortMessages(warningMessages)">
      <h4 class="message-title">
        <span class="message-title--warning">{{"Warning [" + message.ruleId + "]"}}</span>
        <span>{{
          message.position === -1 ?
          translate('Overall', 'designer') :
          (translate('Rule Position', 'designer') + ' ' + message.position)
        }}
      </span>
      </h4>
      <p class="message-description" [innerHTML]="translate(message.code, 'rule')"></p>
      <!-- <div class="message-btn-wrapper">
        <button type="button" class="message-btn">
            <div class="message-btn-arrow-wrapper">
              <img src="assets/down-arrow.svg" alt="arrow" class="message-btn-arrow">
            </div>
            <span>{{translate('Go to', 'designer')}}</span>
        </button>
      </div> -->
    </div>
  </section>


  <section class="message-section" *ngIf="hintMessages.length > 0">
    <header class="message-section-header">
      <h2 class="message-section-title">
        <img class="message-section-img message-section-img--hint" src="assets/hint-icon.svg" alt="icon">
        <span class="message-section-text message-section-text--hint">{{translate('Hints', 'designer')}}</span>
      </h2>
    </header>
    <div class="message" *ngFor="let message of sortMessages(hintMessages)">
      <h4 class="message-title">
        <span class="message-title--hint">{{"Hint [" + message.ruleId + "]"}}</span>
        <span>{{
          message.position === -1 ?
          translate('Overall', 'designer') :
          (translate('Rule Position', 'designer') + ' ' + message.position)
        }}
      </span>
      </h4>
      <p class="message-description" [innerHTML]="translate(message.code, 'rule')"></p>
      <!-- <div class="message-btn-wrapper">
        <button type="button" class="message-btn">
            <div class="message-btn-arrow-wrapper">
              <img src="assets/down-arrow.svg" alt="arrow" class="message-btn-arrow">
            </div>
            <span>{{translate('Go to', 'designer')}}</span>
        </button>
      </div> -->
    </div>
  </section>

</div>

<span class="message-visualizer-button" (click)="toggleMessageVisualizer()"
  *ngIf="messageVisualizerStatus !== 'NONE'"
  [class.message-visualizer-button--error]="messageVisualizerStatus === 'ERROR'"
  [class.message-visualizer-button--warning]="messageVisualizerStatus === 'WARNING'"
  [class.message-visualizer-button--hint]="messageVisualizerStatus === 'HINT'"
  [class.message-visualizer-button--with-configurator]="
  configuratorSubpanelState.state === SubpanelCurrentState.CLOSED &&
   messageVisualizerSubpanelState.state === SubpanelCurrentState.OPENED &&
   messageVisualizerSubpanelState.position.right === 0
   "
  >
  <img  class="configurator-arrow" src="assets/down-arrow.svg" alt="arrow">
</span>


