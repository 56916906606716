import {ChangeDetectorRef, Component} from '@angular/core';
import {map} from "rxjs/operators";
import {DatasetLoad, MevacoState, ProductConfigurationLoad} from "./store";
import {LoadMountings} from "./store/actions/mountings.actions";
import {Store} from "@ngrx/store";
import {ContextMenuService} from "@perfectmemory/ngx-contextmenu";
import {ActivatedRoute} from "@angular/router";
import jwtDecode from "jwt-decode";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(
    private store: Store<MevacoState>,
    private contextMenuService: ContextMenuService<unknown>,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      const jwt = Object.keys(params)[0];
      if (jwt) {
        const payload: any = jwtDecode(jwt);
        this.store.dispatch(new ProductConfigurationLoad(payload.configId));
        this.store.dispatch(new DatasetLoad(payload.customerId));
        this.store.dispatch(new LoadMountings());
      }
    });
  }
}
