import {Drawing} from "../../model/drawing.model";
import {PlateVisualizerViewModel} from "../../model/view-model/plate-visualizer.viewModel";
import {BendingTreeProvider} from "./bending-tree.provider";

export class PlateVisualizerViewModelProvider {

  constructor(private bendingTreeViewModelProvider: BendingTreeProvider){

  }
  private viewModel: PlateVisualizerViewModel;

  getViewModel(newModel: Drawing): PlateVisualizerViewModel {

    const bendingTree = this.bendingTreeViewModelProvider.getViewModel(newModel);
    if(!newModel){
      this.viewModel = null;
    }else if(
      !this.viewModel ||
      this.viewModel.plate !== newModel.plate ||
      this.viewModel.bendingTree !== bendingTree
    ){
      this.viewModel = {
        plate: newModel.plate,
        bendingTree: bendingTree,
      };
    }
    return this.viewModel;
  };
}
