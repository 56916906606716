import { Subject, Subscription, debounceTime, filter } from "rxjs";
import { Webcad } from "../core";
import { Vector3 } from "../math";
import { Aabb2, Input2DPlacementModel, isPointInAabb2 } from "../models";
import { ObjectUnderPoint } from "../models/ObjectUnderPoint";
import { Input2DModel } from "../models/input-html/input2d.model";
import { ModelVisualizer } from "../visualizers";
import { EditableElement } from "./editable-element";
import { HtmlLayerElement } from "./html-layer-element";
import { LayerSize } from "./layer-size";
import { Node } from "@babylonjs/core";

export class InputHtml<T>
  implements
    ModelVisualizer<Input2DModel<T>>,
    HtmlLayerElement,
    EditableElement
{
  private readonly width: number = 100;
  private readonly height: number = 20;
  private readonly fontSize: number = 14;

  public element: HTMLInputElement;
  private model: Input2DModel<T>;
  private layerSize: LayerSize;

  private inputSubject: Subject<string>;
  private liveUpdateSubjectSub: Subscription;
  private outputUpToDate = true;
  private lastValidValue = "";
  private webcad: Webcad;
  public get editable(): boolean {
    return !!this.model && this.model.editable;
  }

  public init(
    rootNode: Node,
    model: Input2DModel<T>,
    webcad: Webcad
  ): Promise<void> {
    this.webcad = webcad;
    this.model = model;
    this.element = document.createElement("input") as HTMLInputElement;
    this.element.style.pointerEvents = "all";
    this.element.style.width = this.width + "px";
    this.element.style.height = this.height + "px";
    this.element.style.fontSize = this.fontSize + "px";
    this.element.style.position = "absolute";
    this.element.style.textAlign = "center";
    this.element.style.zIndex = "2";
    this.setActiveMode(false);
    this.element.onclick = this.onclick.bind(this);
    this.element.oninput = this.oninput.bind(this);
    this.element.onkeyup = this.onkeyup.bind(this);
    this.element.onfocus = this.onfocus.bind(this);
    this.element.onblur = this.onblur.bind(this);

    this.inputSubject = new Subject<string>();
    this.liveUpdateSubjectSub = this.inputSubject
      .pipe(debounceTime(500), filter(this.validateValue.bind(this)))
      .subscribe((value: string) => {
        this.emitValue(value, false);
      });
    this.updateElementPosition(
      model && model.placement,
      webcad.viewState.canvasSize
    );
    this.element.tabIndex = model && model.editable ? 0 : -1;
    this.webcad.getHtmlLayer().add(this);

    return Promise.resolve();
  }

  private validateValue(value: string): boolean {
    let valid: boolean = true;
    if (
      this.model &&
      this.model.exchange &&
      this.model.exchange.inputValidation
    ) {
      valid = this.model.exchange.inputValidation(value);
    }
    if (valid) {
      this.lastValidValue = value;
    }
    return valid;
  }

  private emitValue(value: string, confirmed: boolean) {
    const exchange = this.model && this.model.exchange;
    if (!this.outputUpToDate) {
      if (exchange && exchange.onInputLive) {
        var correctValue = exchange.onInputLive(exchange.toModel(value));
        if (correctValue !== null && correctValue !== undefined) {
          this.setDisplayValue(correctValue);
        }
      }
      this.outputUpToDate = true;
    }
    if (confirmed && !!exchange && !!exchange.onInputConfirmed) {
      var correctValue = exchange.onInputConfirmed(exchange.toModel(value));
      if (correctValue !== null && correctValue !== undefined) {
        this.setDisplayValue(correctValue);
      }
    }
  }

  private setDisplayValue(value) {
    if (this.model) {
      const exchange = this.model.exchange;
      const displayVal = exchange.fromModel(value);
      if (+displayVal !== +this.element.value || isNaN(+displayVal)) {
        this.element.value = displayVal;
      }
    } else {
      this.element.value = value;
    }
  }

  private onclick(ev: MouseEvent): void {
    // this.setActiveMode(true)
    this.focus();
  }

  private onkeyup(ev: KeyboardEvent): void {
    if (ev.key === "Enter") {
      const val = (<HTMLInputElement>ev.currentTarget).value;
      if (this.validateValue(val)) {
        this.emitValue(val, true);
      }
    }
    if (ev.key === "Escape") {
      var v = this.model.exchange.value;
      this.element.value = this.model.exchange.fromModel(v);
      this.emitValue(this.element.value, false);
    }
  }

  private oninput(ev: Event): void {
    const val = (<HTMLInputElement>ev.currentTarget).value;
    if (
      !!this.model &&
      !!this.model.exchange &&
      !this.model.exchange.inputValidation(val)
    ) {
      this.element.style.color = "red";
    } else {
      this.element.style.color = "black";
    }
    this.outputUpToDate = false;
    this.inputSubject && this.inputSubject.next(val);
  }

  private updateElementPosition(
    placement: Input2DPlacementModel,
    layerSize: LayerSize
  ) {
    if (!!placement) {
      const onScreen =
        Math.abs(placement.position.x) < 1 &&
        Math.abs(placement.position.y) < 1;
      this.element.style.display = onScreen ? "inline" : "none";
      this.element.style.left =
        (placement.position.x / 2 + 0.5) * layerSize.width -
        this.width / 2 +
        "px";
      this.element.style.top =
        (-placement.position.y / 2 + 0.5) * layerSize.height -
        this.height / 2 +
        "px";
      this.element.style.transform =
        "rotate(" + Math.round((placement.angle * 180) / Math.PI) + "deg)";
    } else {
      this.element.style.display = "none";
    }
  }

  getObjectUnderPoint(point: Vector3, maxDist: number): ObjectUnderPoint {
    const xInPix = (point.x / 2 + 0.5) * this.layerSize.width;
    const yInPix = (-point.y / 2 + 0.5) * this.layerSize.width;
    const left =
      (this.model.placement.position.x / 2 + 0.5) * this.layerSize.width -
      this.width / 2;
    const top =
      (-this.model.placement.position.y / 2 + 0.5) * this.layerSize.height -
      this.height / 2;
    const aabb: Aabb2 = {
      max: { x: left + this.width, y: top },
      min: { x: left, y: top - this.height },
    };
    if (isPointInAabb2({ x: xInPix, y: yInPix }, aabb)) {
      return {
        point: { x: xInPix, y: yInPix, z: 0 },
        object: this.element,
        type: "HTMLInput",
      };
    } else {
      return null;
    }
  }

  /*
    //in screen space ({-1;-1}<->{1;1})
    public set placement(p:Input2DPlacementModel){
        this.placementBs.next(p);
    }
    */
  // public set exchange(exchange:InputDataExchangeModel<T>){
  //     /*
  //     if(!!this.valueSub){
  //         this.valueSub.unsubscribe();
  //         this.valueSub = null;
  //     }*/
  //     this.currentExchangeModel = exchange;
  //     /*
  //     if(!!this.currentExchangeModel && !!this.currentExchangeModel.value){
  //         this.valueSub = this.currentExchangeModel.value.subscribe( this.onValueFromModel.bind(this))
  //     }*/
  // }

  updateVisualization(newModel: Input2DModel<T>): void {
    // update placement
    const newSize: LayerSize = this.webcad.viewState.canvasSize;
    const newPlcment = newModel && newModel.placement;
    const oldPlcment = this.model && this.model.placement;
    if (newPlcment !== oldPlcment || this.layerSize !== newSize) {
      this.updateElementPosition(newPlcment, newSize);
    }

    const oldEditanle = !!(this.model && this.model.editable);
    const newEditanle = !!(newModel && newModel.editable);
    if (oldEditanle !== newEditanle) {
      this.element.tabIndex = newEditanle ? 0 : -1;
    }

    // update value in input
    const newValue = newModel && newModel.exchange && newModel.exchange.value;
    const oldValue =
      this.model && this.model.exchange && this.model.exchange.value;
    this.model = newModel;
    this.layerSize = newSize;

    if (newValue !== oldValue) {
      this.setDisplayValue(newValue);
      this.lastValidValue = this.element.value;
    }
  }

  public dispose() {
    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }
    this.liveUpdateSubjectSub && this.liveUpdateSubjectSub.unsubscribe();
  }

  public setActiveMode(active: boolean) {
    if (active) {
      this.element.style.backgroundColor = "white";
      this.element.style.border = "solid black 1px";
      this.element.style.pointerEvents = "all";
    } else {
      this.element.style.backgroundColor = "transparent";
      this.element.style.border = "none";
      this.element.style.pointerEvents = "none";
    }
  }

  public focus(): void {
    this.element.focus();
    // this.element.select();
  }

  public blur(): void {
    this.element.blur();
  }

  private onfocus(): void {
    this.setActiveMode(true);
    this.element.select();
  }

  private onblur(): void {
    // if(this.validateValue(this.element.value)) {
    //     this.emitValue(this.element.value, false);
    // }
    this.element.value = this.lastValidValue;
    this.element.style.color = "black";
    this.setActiveMode(false);
  }

  public hasFocus(): boolean {
    return document.activeElement === this.element;
  }

  public setFocus(focus: boolean): void {
    if (focus) {
      this.setActiveMode(true);
      this.focus();
    } else {
      this.blur();
      this.setActiveMode(false);
    }
  }
}
