<div class="configurator-accordion-wrapper">
  <h2
    class="configurator-accordion-title"
    [class.accordion-title--color-primary]="titleColor === 'primary'"
    [class.accordion-title--color-default]="titleColor === 'default'"
  >
    {{ translate("Perforation", "designer") }}
  </h2>

  <div class="perforation-types-dropdown">
    <dropdown
      [model]="perforationTypeDropdown"
      [tooltipConfig]="{
        fieldName: 'perfortaion.perforationTypeDropdown',
        helpText: 'Hint for perforation type dropdown'
      }"
    >
    </dropdown>
  </div>

  <ng-container [ngSwitch]="perforationTypeDropdown.value | async">
    <ng-container *ngSwitchCase="PerforationType.CLASSIC">
      <div class="accordion-content">
        <ng-container *ngIf="!(costomPattern | async)">
          <div class="configurator">
            <dropdown
              [model]="geometry"
              [tooltipConfig]="{
                fieldName: 'perfortaion.geometryType',
                helpText: 'Geometry Tooltip'
              }"
            >
            </dropdown>
            <dropdown
              [model]="perforation"
              [tooltipConfig]="{
                fieldName: 'perfortaion.perforationType',
                helpText: 'Perforation Tooltip'
              }"
            >
            </dropdown>
            <dropdown
              [model]="size"
              [tooltipConfig]="{
                fieldName: 'perfortaion.size',
                helpText: 'Size Tooltip'
              }"
            >
            </dropdown>
            <dropdown
              [model]="format"
              [tooltipConfig]="{
                fieldName: 'perfortaion.format',
                helpText: 'Perforation Format Tooltip'
              }"
            >
            </dropdown>
          </div>
          <div>
            <div
              *ngIf="showP1Input | async"
              class="configurator-debounce-input-wrapper"
            >
              <debounce-input
                [inputType]="'number'"
                [value]="p1 | async"
                [placeholder]="'P1'"
                (valueChanged)="setP1($event)"
              ></debounce-input>
            </div>
            <div
              *ngIf="showP2Input | async"
              class="configurator-debounce-input-wrapper"
            >
              <debounce-input
                [inputType]="'number'"
                [value]="p2 | async"
                [placeholder]="'P2'"
                (valueChanged)="setP2($event)"
              ></debounce-input>
            </div>
            <div *ngIf="showP1Input | async" class="configurator-debounce-desc">
              <span class="configurator-debounce-desc--bold">OA: </span>
              {{ openArea | async }}
            </div>
          </div>
          <dropdown
            [disabled]="(disablePositions | async) || !(showPositions | async)"
            [model]="position"
            [tooltipConfig]="{
              fieldName: 'perfortaion.position',
              helpText: 'Perforation Ending Tooltip'
            }"
          ></dropdown>
        </ng-container>

        <div
          class="toggle-input-wrapper mt-20"
          *ngIf="environment.enable_custom_patterns"
        >
          <mat-slide-toggle
            [checked]="costomPattern | async"
            (change)="toggleUseCustom()"
          >
            <span class="two-lines-text">{{
              translate("Individual Pattern", "designer")
            }}</span>
          </mat-slide-toggle>
          <div
            class="toggle-input-wrapper__open"
            *ngIf="costomPattern | async"
            (click)="openCustomPatternEditor()"
          >
            <span> {{ translate("Open") }}</span>
            <img
              class="toggle-input-wrapper__arrow"
              src="assets/right-arrow-conf.svg"
              alt="right-arrow"
            />
          </div>
        </div>

        <div class="configurator__features">
          <div class="toggle-input-wrapper">
            <mat-slide-toggle
              [checked]="isEffectEnabled | async"
              (change)="enableEffect($event)"
              #effectsToggle
            >
              <span>{{ translate("Effects", "designer") }}</span>
            </mat-slide-toggle>
            <div
              class="toggle-input-wrapper__open"
              *ngIf="effectsToggle.checked"
              (click)="
                openSubpanel(effectsToggle.checked, Subpanels.EFFECTS_SUBPANEL)
              "
            >
              <span> {{ translate("Open") }}</span>
              <img
                class="toggle-input-wrapper__arrow"
                src="assets/right-arrow-conf.svg"
                alt="right-arrow"
              />
            </div>
          </div>
          <div class="toggle-input-wrapper">
            <mat-slide-toggle
              [checked]="isFadingEnabled | async"
              (change)="enableFading($event)"
              #fadingToggle
            >
              <span>{{ translate("Fading", "designer") }}</span>
            </mat-slide-toggle>
            <div
              class="toggle-input-wrapper__open"
              *ngIf="fadingToggle.checked"
              (click)="
                openSubpanel(fadingToggle.checked, Subpanels.FADING_SUBPANEL)
              "
            >
              <span> {{ translate("Open") }}</span>
              <img
                class="toggle-input-wrapper__arrow"
                src="assets/right-arrow-conf.svg"
                alt="right-arrow"
              />
            </div>
          </div>
          <div class="toggle-input-wrapper">
            <mat-slide-toggle
              [checked]="isPerfactionEnabled | async"
              (change)="
                togglePerfactionFeature($event.checked);
                togglePerfaction(perfactionToggle.checked)
              "
              #perfactionToggle
            >
              <span>{{ translate("Picture Based", "designer") }}</span>
            </mat-slide-toggle>
            <div
              class="toggle-input-wrapper__open"
              *ngIf="perfactionToggle.checked"
              (click)="togglePerfaction(perfactionToggle.checked)"
            >
              <span> {{ translate("Open") }}</span>
              <img
                class="toggle-input-wrapper__arrow"
                src="assets/right-arrow-conf.svg"
                alt="right-arrow"
              />
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="PerforationType.WALLPAPER">
      <div class="configurator">
        <dropdown
          [model]="wallpaper"
          [tooltipConfig]="{
            fieldName: 'wallpaper.name',
            helpText: 'Wallpapers Tooltip'
          }"
        ></dropdown>
        <div class="zoom-input-control">
          <form [formGroup]="zoomInput">
            <label
              [class.zoom--disabled]="!(allowZoom | async)"
              for="formControlRange"
              >{{ translate("Zoom", "designer") }} : {{ sliderValue }}</label
            >
            <debounce-slider
              [disabled]="!(allowZoom | async)"
              [showLabel]="false"
              [min]="minZoom | async"
              [max]="maxZoom | async"
              [step]="0.1"
              (valueChanged)="setZoom(+$event)"
            >
            </debounce-slider>
          </form>
        </div>
        <div class="allow-holes-control">
          <form [formGroup]="zoomInput">
            <mat-checkbox
              class="base-checkbox"
              [disabled]="!(allowHalfHoles | async)"
              formControlName="holes"
              [checked]="this.holesEnabled"
              (change)="allowHolesChangeState()"
              #holesElement
              color="primary"
            >
              <span class="text">{{ translate("Allow half holes") }}</span>
            </mat-checkbox>
            <input #halfHolesValue [value]="this.holesEnabled" type="hidden" />
          </form>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="PerforationType.PERFACTION">
      <div class="configurator">
        <div class="perfaction-content__dropdown-wrapper">
          <dropdown
            [model]="perfactionFormDropdown"
            [tooltipConfig]="{
              fieldName: 'perfaction.perfactionFormDropdown',
              helpText: 'Perfaction Form Tooltip'
            }"
          >
          </dropdown>
        </div>

        <div class="perfaction-content__dropdown-wrapper">
          <dropdown
            [model]="perfactionLabelDropdown"
            [tooltipConfig]="{
              fieldName: 'perfaction.perfactionLabelDropdown',
              helpText: 'Perfaction Label Tooltip'
            }"
          >
          </dropdown>
        </div>

        <div class="tools-sizes">
          <label class="tools-sizes__label">
            {{ translate("tools sizes", "designer") }}
            <div class="tools-sizes__hint">
              <div
                class="tools-sizes-hint-img-wrapper"
                placement="top"
                container="body"
                [ngbTooltip]="translate('tools-sizes tooltip', 'designer')"
              >
                <img src="assets/hint-icon.svg" alt="hint" />
              </div>
            </div>
          </label>
          <app-multiselect
            [placeholder]="translate('Select holes sizes', 'designer')"
            [checkboxVisible]="false"
            [options]="holeSizes"
            [(ngModel)]="selectedHoleSizes"
            [maxSelectedLabels]="3"
            [selectionLimit]="9"
            (change)="changeHoleSizes($event)"
          >
            <div class="tools-sizes__sizes">
              <ng-container
                *ngFor="
                  let option of selectedHoleSizesData;
                  let index = index;
                  let last = last
                "
              >
                <ng-container
                  *ngIf="selectedHoleSizesData.length === 1; else multipleSizes"
                >
                  <p class="tools-sizes__size">
                    <span>{{ option.label + " " }}</span>
                    <span class="bold">{{ option.value }}</span>
                  </p>
                </ng-container>
                <ng-template #multipleSizes>
                  <p class="tools-sizes__size size-mr" *ngIf="!last">
                    <span>{{ option.label + " " }}</span>
                    <span class="bold">{{ option.value }}</span>
                    <span>{{ "," }}</span>
                  </p>
                  <p class="tools-sizes__size" *ngIf="last">
                    <span>{{ option.label + " " }}</span>
                    <span class="bold">{{ option.value }}</span>
                  </p>
                </ng-template>
              </ng-container>
            </div>
          </app-multiselect>
        </div>

        <div class="perfaction-content__dropdown-wrapper">
          <dropdown
            [model]="thirdDropdown"
            [tooltipConfig]="{
              fieldName: 'perfaction.perfactionSpacing',
              helpText: 'Perfaction Spacing Tooltip'
            }"
          >
          </dropdown>
        </div>

        <div class="toggle-input-wrapper">
          <mat-slide-toggle
            [checked]="isEffectEnabled | async"
            (change)="enableEffect($event)"
            #effectsToggle
          >
            <span>{{ translate("Effects", "designer") }}</span>
          </mat-slide-toggle>
          <div
            class="toggle-input-wrapper__open"
            *ngIf="effectsToggle.checked"
            (click)="
              openSubpanel(effectsToggle.checked, Subpanels.EFFECTS_SUBPANEL)
            "
          >
            <span> {{ translate("Open") }}</span>
            <img
              class="toggle-input-wrapper__arrow"
              src="assets/right-arrow-conf.svg"
              alt="right-arrow"
            />
          </div>
        </div>
        <div class="toggle-input-wrapper">
          <mat-slide-toggle
            [checked]="isFadingEnabled | async"
            (change)="enableFading($event)"
            #fadingToggle
          >
            <span>{{ translate("Fading", "designer") }}</span>
          </mat-slide-toggle>
          <div
            class="toggle-input-wrapper__open"
            *ngIf="fadingToggle.checked"
            (click)="
              openSubpanel(fadingToggle.checked, Subpanels.FADING_SUBPANEL)
            "
          >
            <span> {{ translate("Open") }}</span>
            <img
              class="toggle-input-wrapper__arrow"
              src="assets/right-arrow-conf.svg"
              alt="right-arrow"
            />
          </div>
        </div>
        <div class="toggle-input-wrapper">
          <mat-slide-toggle
            [checked]="isPerfactionEnabled | async"
            (change)="togglePerfactionFeature($event.checked)"
            #perfactionToggle
          >
            <span>{{ translate("Picture Based", "designer") }}</span>
          </mat-slide-toggle>
          <div
            class="toggle-input-wrapper__open"
            *ngIf="perfactionToggle.checked"
            (click)="togglePerfaction(perfactionToggle.checked)"
          >
            <span> {{ translate("Open") }}</span>
            <img
              class="toggle-input-wrapper__arrow"
              src="assets/right-arrow-conf.svg"
              alt="right-arrow"
            />
          </div>
        </div>
        <ng-container *ngIf="isPerfactionEnabled | async">
          <ng-container *ngTemplateOutlet="perfactionContent"></ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #perfactionContent>
  <div class="perfaction-content">
    <div class="perfaction-content__section">
      <div class="slider__wrapper">
        <label class="slider__label">
          <span class="label__text">{{
            translate("invert conversion", "designer")
          }}</span>
          <div class="label__hint">
            <div
              class="hint"
              container="body"
              placement="left"
              container="body"
              [ngbTooltip]="translate('invert conversion tooltip', 'designer')"
            >
              <img src="assets/hint-icon.svg" alt="hint" />
            </div>
          </div>
        </label>
        <mat-slide-toggle
          [checked]="inverted | async"
          (change)="activeInversion($event)"
        >
          <span>{{ translate("inversion activated", "designer") }}</span>
        </mat-slide-toggle>
      </div>
    </div>

    <div class="perfaction-content__section">
      <div class="slider__wrapper">
        <app-slider
          [customLabelText]="brightnessLabelTemplate"
          [sliderControl]="brightnessControl"
          [options]="{
            floor: -10,
            ceil: 10,
            showSelectionBarFromValue: 0
          }"
        >
        </app-slider>
      </div>
    </div>
  </div>

  <ng-template #brightnessLabelTemplate>
    <label class="slider__label m-0">
      <span class="label__text">{{ translate("brightness", "designer") }}</span>
      <div class="label__hint">
        <div
          class="hint"
          container="body"
          placement="left"
          container="body"
          [ngbTooltip]="translate('perfaction brightness tooltip', 'designer')"
        >
          <img src="assets/hint-icon.svg" alt="hint" />
        </div>
      </div>
    </label>
  </ng-template>
</ng-template>
