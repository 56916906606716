<div class="load-pattern__container">
  <div class="load-pattern__header">
    <h2 class="load-pattern__title">{{translate('Saved Patterns', 'designer')}}</h2>
    <button type="button" class="load-pattern__btn load-pattern__btn--close" (click)="close.emit(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="group-name">
    {{translate("Public patterns")}}
  </div>
  <div class="load-pattern__body">
    <div class="load-pattern__card" *ngFor="let p of publicPatterns">
      <app-custom-pattern-load-item
        [userPattern]="p"
        (close)="close.emit(true)"
      >
      </app-custom-pattern-load-item>
    </div>
  </div>
  <div class="group-name">
    {{translate("My patterns")}}
  </div>
  <div class="load-pattern__body">
    <div class="load-pattern__card" *ngFor="let p of userPatterns">
      <app-custom-pattern-load-item
        [userPattern]="p"
        (close)="close.emit(true)"
      >
      </app-custom-pattern-load-item>
    </div>
  </div>
</div>


