import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { customPatternEditorIsClosed, getRightPanelState, getRuleActions, MevacoState, SetSubpanelState } from '../../store';
import {RightPanel, SubpanelCurrentState, Subpanels } from '../../model/right-panel.model';
import {anyActionOfType, RuleActions} from '../../model/rule-action.model';
import { Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MessageVisualizerStatus } from '../../model/message-visualizer-status.enum';

@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.css'],
  animations: [
    trigger('openCloseConfigurator', [
      state('open', style({
        right: 0,
        opacity: 1,
      })),
      state('closed', style({
        opacity: 0,
        right: '-290px',
        width: '40px'
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.3s')
      ])
    ]),
    trigger('openCloseMessageVisualizer', [
      state('open', style({
        right: 0,
        opacity: 1,
      })),
      state('closed', style({
        opacity: 0,
        right: '-290px',
        width: '40px'
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.3s')
      ])
    ])
  ]
})
export class RightPanelComponent implements OnInit, OnDestroy {


  public customPatternEditorIsClosed = this.store.pipe( select(customPatternEditorIsClosed));
  public SubpanelCurrentState  = SubpanelCurrentState;
  public Subpanels = Subpanels;
  public MessageVisualizerStatus = MessageVisualizerStatus;
  public rightPanelConfiguration: RightPanel;
  public messageVisualizerStatus = MessageVisualizerStatus.NONE;
  private subscriptions: Subscription[] = [];

  constructor(private store: Store<MevacoState>) { }


  ngOnInit() {

    this.subscriptions.push(this.store.pipe(select(getRuleActions)).subscribe((rules: RuleActions) => {
      if (anyActionOfType( rules, MessageVisualizerStatus.ERROR)) {
        this.messageVisualizerStatus = MessageVisualizerStatus.ERROR;
        return;
      } else if (anyActionOfType( rules, MessageVisualizerStatus.WARNING)) {
        this.messageVisualizerStatus = MessageVisualizerStatus.WARNING;
        return;
      } else if (anyActionOfType( rules, MessageVisualizerStatus.HINT)) {
        this.messageVisualizerStatus = MessageVisualizerStatus.HINT;
        return;
      } else {
        this.messageVisualizerStatus = MessageVisualizerStatus.NONE;
      }
  }));

  this.subscriptions.push(this.store.pipe(select(getRightPanelState)).subscribe(rightPanel => {
      this.rightPanelConfiguration = rightPanel;
  }));

  }

  toggleSubpanel(subpanelName: Subpanels) {
    if (this.rightPanelConfiguration[subpanelName].state === SubpanelCurrentState.OPENED) {
      this.store.dispatch(new SetSubpanelState({name: subpanelName, state: SubpanelCurrentState.CLOSED}));
    } else {
      this.store.dispatch(new SetSubpanelState({name: subpanelName, state: SubpanelCurrentState.OPENED}));
    }
  }


  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
