import {SET_PLATE} from './plate.actions';
import {Action} from '@ngrx/store';
import {UPDATE_PLATE_SHAPE_FAIL} from "./drawing.actions";

export const SET_ERROR = 'SET ERROR';
export const SET_SUCCESS = 'SET SUCCESS';

export class SetError implements Action {
  readonly type: string = SET_ERROR;

  constructor(public payload: string) {
    if(!!payload) {
      console.warn(payload);
    }
  }
}
export class SetSuccess implements Action {
  readonly type: string = SET_SUCCESS;

  constructor(public payload: string) {
    if(!!payload) {
      console.log(payload);
    }
  }
}

export const ErrorableTypes = [
  SET_PLATE,
  UPDATE_PLATE_SHAPE_FAIL
];

