import {Component, HostListener, OnInit} from '@angular/core';
import {TreatCornerTool} from '../../tools/editTools/treat-corner.tool';
import {ToolProvider} from '../../providers/tool.provider';
import {TranslationProvider} from '../../providers/translation.provider';
import {Dropdown, DropdownOption} from '../dropdown/dropdown.component';
import {Observable, of} from 'rxjs';
import {roundDecimal} from '../../utils/utils';

@Component({
  selector: 'treat-corner',
  templateUrl: './treat-corner.component.html',
  styleUrls: ['./treat-corner.component.css']
})
export class TreatCornerComponent implements OnInit {
  public treatCornerTool: TreatCornerTool;
  public treatCornerActive: boolean = false;
  public cornerTypes = [
    'round',
    'rectangle',
    'cut',
    'rectangleHole',
    'oblique'
  ];

  public typeSelect: string = 'round';

  public treatCornerDropdown: Dropdown;
  public treatMenuWidth: number = 240;

  // @HostListener('window:resize', ['$event']) onResize(event) {
  //   if(event.target.innerWidth >= 900 && event.target.innerWidth < 1200) {
  //     this.treatMenuWidth = 150;
  //   } else if(event.target.innerWidth >= 1200) {
  //     this.treatMenuWidth = 240;
  //   }
  // }

  constructor(private toolProvider: ToolProvider, private translationProvider: TranslationProvider) {
    this.treatCornerTool = this.toolProvider.treatCornerTool;
    this.toolProvider.getToolObservable().subscribe((value) => this.treatCornerActive = value === this.treatCornerTool);
  }

  ngOnInit(): void {
    // if(window.innerWidth >= 900 && window.innerWidth < 1200) {
    //   this.treatMenuWidth = 150;
    // } else if(window.innerWidth >= 1200) {
    //   this.treatMenuWidth = 240;
    // }
    this.treatCornerDropdown = new Dropdown(
      this.translate('Choose treatment type'),
      this.getCornerTypes(),
      of(''),
      (value) => {
        this.treatCornerTool.changeType(value);
        this.changeType(value);
      }
    );
  }

  startTreatCorner() {
    this.toolProvider.setTool(this.toolProvider.treatCornerTool);
  }

  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }

  private getCornerTypes(): Observable<DropdownOption[]> {
    return of([
      new DropdownOption('templateDropdown', {
        name: this.translate('round', 'designer'),
        imgUrl: 'assets/RoundOpt.svg'
      }, true, 'round', ''),
      new DropdownOption('templateDropdown', {
        name: this.translate('rectangle', 'designer'),
        imgUrl: 'assets/RectangleOpt.svg'
      }, true, 'rectangle', ''),
      new DropdownOption('templateDropdown', {
        name: this.translate('cut', 'designer'),
        imgUrl: 'assets/CutOpt.svg'
      }, true, 'cut', ''),
      new DropdownOption('templateDropdown', {
        name: this.translate('rectangleHole', 'designer'),
        imgUrl: 'assets/RectangleHoleOpt.svg'
      }, true, 'rectangleHole', ''),
      new DropdownOption('templateDropdown', {
        name: this.translate('oblique', 'designer'),
        imgUrl: 'assets/ObliqueOpt.svg'
      }, true, 'oblique', '')
    ]);

  }

  private changeType(type: any) {
    this.typeSelect = String(type);
  }

  public roundInput(event, maxValue?: number, decimals = 1) {
    if (!!maxValue && maxValue !== 0) {
      if (Number(event.target.value) > maxValue) {
        event.target.value = maxValue;
      }
    }
    event.target.value = roundDecimal(event.target.value, decimals);
  }
}
