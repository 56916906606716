import {HoleSize, PerforationFormat, stringToSize} from '../dataset/perforation-format.model';
import {Dataset} from '../dataset/dataset.model';
import {PerforationSizeData} from '../dataset/perforation-size-data.model';
import {PerforationCustomGeom} from './perforation-custom-geom';

export interface Perforation {
  geometryType: string;
  perforationType: string;
  size: string;
  spacing: string;
  format: string;
  format1: string;
  format2: string;
  position: string;
  dependentMode: boolean;
  custom: PerforationCustomGeom;
}

/*
export interface PerforationSize {
  width: number;
  length: number;
}

export function sizeToString(size: PerforationSize) {
  return size.length !== 0 ? size.width.toString() + ' x ' + size.length.toString() : size.width.toString();
}
*/

export function formatToString(format: PerforationFormat) {
  let str: string = '' + format.p1.toString();
  if (format.p2 !== 0) {
    str += ' x ' + format.p2.toString();
  }
  return str;
}

export function is2d(perforation: Perforation) {
  return perforation.size && perforation.size.indexOf(' x ') !== -1;
}

export function createFormatString(perforation: Perforation, p1: string, p2: string) {
  if (is2d(perforation) && p1 !== '' && p2 !== '') {
    return p1 + ' x ' + p2;
  }
  if (!is2d(perforation) && p1 !== '') {
    return p1;
  }
  return '';
}

export function getPerforationSizeData(perforation: Perforation, dataset: Dataset): PerforationSizeData {
  if (dataset && perforation.size) {
    if (!!dataset.perforationTypes) {
      const perforationType = dataset.perforationTypes.find(x => x.name === perforation.geometryType);
      if (!!perforationType) {
        const perforationData = perforationType.perforations.find(x => x.name === perforation.perforationType);
        if (!!perforationData) {
          const s = stringToSize(perforation.size);
          return perforationData.sizes.find(x => x.width === s.width && x.length === s.length);
        }
      }
    }
  }
}

export function getPerforationFormatData(perforation: Perforation, dataset: Dataset): PerforationFormat {
  const size = getPerforationSizeData(perforation, dataset);
  if (!!size) {
    return size.formats.find(x => x.p1.toString() === perforation.format1 && x.p2.toString() === perforation.format2);
  }
}

function getOpenAreaForIndividualSpacing(perforation): number | null {
  if (perforation.format === '') {
    return null;
  }


  if (perforation.perforationType.startsWith('Rv')) {
    const w = Number(perforation.size);
    const p1 = Number(perforation.format1);
    const oa = (90.7 * w * w) / (p1 * p1);
    return oa;
  }

  if (perforation.perforationType === 'Rg' ||
    perforation.perforationType === 'Rd') {
    const w = Number(perforation.size);
    const p1 = Number(perforation.format1);
    const oa = (78.5 * w * w) / (p1 * p1);
    return oa;
  }
  if (perforation.perforationType === 'Qg' ||
    perforation.perforationType === 'Qd' ||
    perforation.perforationType === 'Qv') {
    const w = Number(perforation.size);
    const p1 = Number(perforation.format1);
    const oa = (100 * w * w) / (p1 * p1);
    return oa;
  }
  if (perforation.perforationType.startsWith('Lve') ||
    perforation.perforationType.startsWith('Lge')) {
    const size = stringToSize(perforation.size);
    const p1 = Number(perforation.format1);
    const p2 = Number(perforation.format2);
    const oa = ((size.width * size.length) / (p1 * p2)) * 100;
    return oa;
  }
  if (perforation.perforationType.startsWith('Lv') ||
    perforation.perforationType.startsWith('Lg')) {
    const size = stringToSize(perforation.size);
    const p1 = Number(perforation.format1);
    const p2 = Number(perforation.format2);
    const oa = (((size.width * size.length) - (0.215 * size.width * size.width)) / (p1 * p2)) * 100;
    return oa;
  }
}

export function getCurrentAreaOpen(perforation: Perforation, dataset: Dataset): string {
  if (perforation.spacing === 'Individual') {
    const oa = getOpenAreaForIndividualSpacing(perforation);
    if (!!oa) {
      return Number(oa).toFixed(2) + ' %';
    }
  } else {
    const format = getPerforationFormatData(perforation, dataset);
    if (format && format.areaOpen) {
      return Number(format.areaOpen).toFixed(2) + ' %';
    }
  }
  return '--.-- &';
}

export function sizeStringToPerforationSize(size: string): HoleSize {
  const s = size.split(' x ');
  return {
    length: Number(s[1]) || Number(s[0]),
    width: Number(s[0])
  };
}
