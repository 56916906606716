import { rayToVector3Distance, Vector3, vectors3Equal } from "../math";
import { Pointer } from "./pointer";
import { PointerCollider } from "./pointer-collider";
import { PointerIntersection } from "./pointer-intersection";

export class PointPointerCollider implements PointerCollider {
  constructor(public position: Vector3, public name: string) {}

  getIntersection(pointer: Pointer, epsilon: number): PointerIntersection {
    const dist = rayToVector3Distance(
      this.position,
      pointer.ray.origin,
      pointer.ray.direction
    );
    return dist > epsilon
      ? null
      : {
          collider: this,
          position: { ...this.position },
        };
  }

  compare(other: PointerCollider): boolean {
    if (other instanceof PointPointerCollider) {
      const pc: PointPointerCollider = other as PointPointerCollider;
      return vectors3Equal(this.position, pc.position);
    }
    return false;
  }
}
