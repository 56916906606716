import { ModelVisualizer, Webcad } from "webcad";
import {
  Vector3,
  addVectors2,
  getRightPerpendicularVector2,
  multiplyVector2byScalar,
  normalizeVector2,
} from "webcad/math";
import { ObjectUnderPoint } from "webcad/models/ObjectUnderPoint";
import { ChangeType, detectChangesInMap } from "webcad/utils";
import { HelpLine } from "../model/help-line.model";
import { HelpLinesViewModel } from "../model/view-model/help-lines.viewModel";
import { createLineWithDepthOffset } from "./line-system";
import { Node } from "webcad/babylonjs/core";
import {Color4, LinesMesh, Scene, Vector3 as B_Vector3} from "webcad/babylonjs/core";
export class HelpLinesVisualizer
  implements ModelVisualizer<HelpLinesViewModel>
{
  private model: HelpLinesViewModel;
  private rootNode: Node;
  private webcad: Webcad;
  private helpLineMeshes: Map<number, LinesMesh>;
  private toolHelpLinesMeshes: Map<number, LinesMesh>;

  constructor() {
    this.helpLineMeshes = new Map<number, LinesMesh>();
    this.toolHelpLinesMeshes = new Map<number, LinesMesh>();
  }

  init(
    rootNode: Node,
    model: HelpLinesViewModel,
    webcad: Webcad
  ): Promise<void> {
    this.rootNode = rootNode;
    this.model = model;
    this.webcad = webcad;
    return Promise.resolve();
  }

  updateVisualization(newModel: HelpLinesViewModel): void {
    detectChangesInMap(
      this.model.helpLines,
      newModel.helpLines,
      (type, key) => {
        switch (type) {
          case ChangeType.Added:
            const newMesh = visualizeHelpingLine(
              newModel.helpLines.get(key),
              this.webcad.scene
            );
            this.helpLineMeshes.set(key, newMesh);
            break;
          case ChangeType.Changed:
            this.helpLineMeshes.get(key).dispose();
            const changedMesh = visualizeHelpingLine(
              newModel.helpLines.get(key),
              this.webcad.scene
            );
            this.helpLineMeshes.set(key, changedMesh);
            break;
          case ChangeType.Removed:
            this.helpLineMeshes.get(key).dispose();
            this.helpLineMeshes.delete(key);
            break;
        }
      },
      (key) => {}
    );
    detectChangesInMap(
      this.model.toolHelpLines,
      newModel.toolHelpLines,
      (type, key) => {
        switch (type) {
          case ChangeType.Added:
            const newMesh = visualizeHelpingLine(
              newModel.toolHelpLines.get(key),
              this.webcad.scene
            );
            this.toolHelpLinesMeshes.set(key, newMesh);
            break;
          case ChangeType.Changed:
            this.toolHelpLinesMeshes.get(key).dispose();
            const changedMesh = visualizeHelpingLine(
              newModel.toolHelpLines.get(key),
              this.webcad.scene
            );
            this.toolHelpLinesMeshes.set(key, changedMesh);
            break;
          case ChangeType.Removed:
            this.toolHelpLinesMeshes.get(key).dispose();
            this.toolHelpLinesMeshes.delete(key);
            break;
        }
      },
      (key) => {}
    );
    if (this.model.state !== newModel.state) {
      this.helpLineMeshes.forEach((v, k) => {
        v.isVisible = newModel.state;
      });
    }
    this.model = newModel;
  }

  dispose(): void {}

  getObjectUnderPoint(point: Vector3, maxDist: number): ObjectUnderPoint {
    let closestDistance = Number.POSITIVE_INFINITY;
    let obj: ObjectUnderPoint = null;
    // for (const hL of this.model.helpLines) {
    //   const pp = projectPointOnHelpLine(hL, point, maxDist);
    //   if (pp) {
    //     const sqrDist = sqrDistanceVector2(pp, point);
    //     if (sqrDist < maxDist * maxDist) {
    //       if (sqrDist < closestDistance) {
    //         obj = {
    //           object: hL,
    //           point: {x: pp.x, y: pp.y, z: point.z},
    //           type: 'HelpLine'
    //         };
    //         closestDistance = sqrDist;
    //       }
    //     }
    //   }
    // }
    return obj;
  }
}

export function visualizeHelpingLine(
  model: HelpLine,
  scene: Scene
): LinesMesh {
  const rightPerp = getRightPerpendicularVector2(
    normalizeVector2(model.direction)
  );
  const realStartPoint = addVectors2(
    model.position,
    multiplyVector2byScalar(rightPerp, model.offset * 0.001)
  );
  const a = addVectors2(
    realStartPoint,
    multiplyVector2byScalar(model.direction, 20)
  );
  const b = addVectors2(
    realStartPoint,
    multiplyVector2byScalar(model.direction, -20)
  );
  const positions: B_Vector3[] = [
    new B_Vector3(a.x, a.y, 0),
    new B_Vector3(b.x, b.y, 0),
  ];
  const color = new Color4(0.3, 0.3, 0.3, 0.4);
  const colors: Color4[] = [color, color];
  const mesh = createLineWithDepthOffset(
    "helpLine",
    { points: positions, colors: colors, useVertexAlpha: true },
    scene,
    -0.0006
  );
  mesh.metadata = model;
  mesh.isVisible = model.visible;
  return mesh;
}
