import {Vector3} from "./vector3";
import {Aabb2} from "../models";
import {Vector2} from "./vector2";

export interface Aabb3 {
    min: Vector3;
    max: Vector3;
}

export function setEmptyAabb3(aabb: Aabb3): void {
    aabb.min.x = Number.MAX_VALUE;
    aabb.min.y = Number.MAX_VALUE;
    aabb.min.z = Number.MAX_VALUE;
    aabb.max.x = -Number.MAX_VALUE;
    aabb.max.y = -Number.MAX_VALUE;
    aabb.max.x = -Number.MAX_VALUE;
}

export function createEmptyAabb3(): Aabb3 {
    return {
        min: {
            x: Number.MAX_VALUE,
            y: Number.MAX_VALUE,
            z: Number.MAX_VALUE,
        },
        max: {
            x: -Number.MAX_VALUE,
            y: -Number.MAX_VALUE,
            z: -Number.MAX_VALUE,
        }
    }
}

export function isAabb3Empty(aabb: Aabb2): boolean {
    return aabb.min.x === Number.MAX_VALUE;
}

export function expandAabb3(a: Aabb3, v: Vector3): Aabb3 {
    return {
        min: {
            x: Math.min(a.min.x, v.x),
            y: Math.min(a.min.y, v.y),
            z: Math.min(a.min.y, v.z),
        },
        max: {
            x: Math.max(a.max.x, v.x),
            y: Math.max(a.max.y, v.y),
            z: Math.max(a.max.y, v.z),
        }
    };
}

export function expandAabb3InPlace(a: Aabb3, v: Vector3): void {
    a.min.x = Math.min(a.min.x, v.x);
    a.min.y = Math.min(a.min.y, v.y);
    a.min.z = Math.min(a.min.z, v.z);
    a.max.x = Math.max(a.max.x, v.x);
    a.max.y = Math.max(a.max.y, v.y);
    a.max.z = Math.max(a.max.z, v.z);
}
