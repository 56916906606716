
<div class="viewer-element__wrapper" (click)="show()" [ngClass]="{'viewer-element__wrapper--active' : element.position === (position | async)}">
  <div class="viewer-element__img-container">
    <div class="viewer-element__position">
      <span>{{element.position}}</span>
    </div>
    <img [src]="getImageUrl + element.previewImageId" class="viewer-element__img">
  </div>
  <div class="viewer-element__content">
    <div class="content__parameters">
        <!-- <div class="content__parameter">
            <span class="parameter__label">{{translate("Pos_v", 'designer')}}:</span>
            <p class="parameter__value">{{element.position}}</p>
        </div> -->
        <div class="content__parameter">
          <span class="parameter__label">{{translate("Area", 'designer')}}:</span>
          <p class="parameter__value">{{element.area | number : '1.0-2'}} m<sup>2</sup></p>
      </div>
      <div class="content__parameter">
        <span class="parameter__label">{{translate("Pieces", 'designer')}}:</span>
        <p class="parameter__value">{{element.quantity}}</p>
    </div>
    </div>
    <div class="content__notes">
        <span class="notes__label">{{translate("Notes", 'designer')}}:</span>
        <p class="notes__value">{{element.note}}</p>
    </div>
  </div>
  
</div>




