<div *ngIf="helpLineActive">
  <div class="help-lines-wrapper">
    <div class="drawing-tool-input-wrapper">
      <label class="drawing-tool-label" for="angleInput">{{translate("Angle")}}:</label>
      <input class="drawing-tool-input" type="number" id="angleInput" [(ngModel)]="angle" [(value)]="helpLineTool.angle.value" (input)="helpLineTool.changeAngle($event.target.value)"
        #ang>
      <div [hidden]="ang.value.length > 0" class="alert alert-danger">
        {{translate("Value is required")}}
      </div>
    </div>
    <div class="drawing-tool-input-wrapper">
      <label class="drawing-tool-label" for="offsetInput">{{translate("Offset")}}:</label>
      <input class="drawing-tool-input" type="number" id="offsetInput" [(ngModel)]="offset" [(value)]="helpLineTool.offset.value" (input)="helpLineTool.changeOffset($event.target.value)"
        #off>
      <div [hidden]="off.value.length > 0" class="alert alert-danger">
        {{translate("Value is required")}}
      </div>
    </div>
    <div class="drawing-tool-btns">
      <button class="drawing-tool-btn" (click)="setHorizontal()" [ngClass]="{'active': isHorizontal}">{{translate("Horizontal")}}</button>
      <button class="drawing-tool-btn" (click)="setVertical()" [ngClass]="{'active': isVertical}">{{translate("Vertical")}}</button>
    </div>
  </div>
</div>
