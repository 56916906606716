
import { Observable, Subject } from "rxjs";
import { Webcad } from "../core/webcad";
import { MeasurementMaterial } from "../materials/measurement.material";
import {
  addVectors3,
  multiplyVector3byScalar,
  normalizeVector3,
  Vector3,
} from "../math";
import { LineSegment3, segment3MiddlePoint } from "../math/line-segment3";
import { projectPos, transformPos, transformVec4 } from "../math/matrix4";
import { Vector4 } from "../math/vector4";
import { createMeasurementMesh } from "../measurements/utils";
import {
  Aabb2,
  expandAabb,
  getMeasurementEndPoint,
  Input3DModel,
  isPointInAabb2,
  MeasurementModel,
} from "../models/";
import { CameraModel } from "../models/camera.model";
import { ObjectUnderPoint } from "../models/ObjectUnderPoint";
import { Input3DVisualizer } from "./input-html/input3d.visualizer";
import { ModelVisualizer } from "./model.visualizer";
import {BoundingInfo, Matrix, Mesh, Node, Vector3 as B_Vector3} from "@babylonjs/core";

export interface MeasurementViewModel {
  model: MeasurementModel;
  viewMask: number;
}

export class MeasurementVisualizer
  implements ModelVisualizer<MeasurementViewModel>
{
  private template: Mesh;
  private mesh: Mesh;
  public onChangeObservable: Observable<number>;
  private viewModel: MeasurementViewModel;
  public onFocusObservable: Subject<boolean> = new Subject<boolean>();
  private isUpdating: boolean = false;
  private webcad: Webcad;

  private readonly inputVisualizer: Input3DVisualizer<number>;
  private cameraState: CameraModel;

  constructor() {
    this.onChangeObservable = new Subject<number>(); //TODO
    this.inputVisualizer = new Input3DVisualizer<number>();
  }

  setFocus(focus: boolean): void {
    this.inputVisualizer.setFocus(focus);
  }

  updateVisualization(newViewModel: MeasurementViewModel): void {
    const newMask = newViewModel ? newViewModel.viewMask : undefined;
    const newModel = newViewModel ? newViewModel.model : undefined;
    const oldMask = this.viewModel ? this.viewModel.viewMask : undefined;
    const oldModel = this.viewModel ? this.viewModel.model : undefined;
    if (
      newModel !== oldModel ||
      newMask !== oldMask ||
      this.cameraState !== this.webcad.viewState.camera
    ) {
      this.update(newViewModel, this.webcad.viewState.camera);
    }
    this.viewModel = newViewModel;
  }

  private update(newViewModel: MeasurementViewModel, newCamera: CameraModel) {
    this.isUpdating = true;
    if (!newViewModel || !newViewModel.model) {
      if (!!this.mesh) {
        this.mesh.isVisible = false;
      }
    } else {
      //set position of a viewModel to start pos and change bone 2 position in local space
      const viewModel: MeasurementViewModel = newViewModel;
      if (!!this.mesh) {
        this.mesh.isVisible =
          !!(viewModel.viewMask & viewModel.model.mask) &&
          viewModel.model.visible;
        const xAxis = new B_Vector3(
          viewModel.model.measurementDirection.x,
          viewModel.model.measurementDirection.y,
          viewModel.model.measurementDirection.z
        ).normalize();
        let zAxis = new B_Vector3(
          viewModel.model.direction.x,
          viewModel.model.direction.y,
          viewModel.model.direction.z
        ).normalize();
        if (newViewModel.model.distance < 0) {
          zAxis.x *= -1;
          zAxis.y *= -1;
          zAxis.z *= -1;
        }
        const yAxis = B_Vector3.Cross(xAxis, zAxis);
        let mat = new Matrix();
        Matrix.FromXYZAxesToRef(xAxis, yAxis, zAxis, mat);
        mat.setTranslation(
          new B_Vector3(
            viewModel.model.start.x,
            viewModel.model.start.y,
            viewModel.model.start.z
          )
        );
        this.mesh.setPreTransformMatrix(mat);

        const material: MeasurementMaterial = this.mesh
          .material as MeasurementMaterial;
        const dist = Math.max(
          0.2,
          Math.min(
            transformPos(newCamera.view, viewModel.model.start).z,
            transformPos(
              newCamera.view,
              getMeasurementEndPoint(viewModel.model)
            ).z
          )
        );
        material.scale = dist / 15;
        let distance = 0.025 * dist;
        if (newViewModel.model.distance) {
          //if undefined or 0
          distance = Math.abs(newViewModel.model.distance);
        }
        material.distance = distance;
        material.length = newViewModel.model.exchange.value;
        if (newViewModel.model.color) {
          material.color = { ...newViewModel.model.color, w: 1 };
        }
      }
    }
    this.inputVisualizer.updateVisualization(
      this.getInputModel(newViewModel, this.webcad.viewState.camera)
    );
    this.isUpdating = false;
    this.viewModel = newViewModel;
    this.cameraState = this.webcad.viewState.camera;
  }

  /*
    setVisibility(visible){
        this.mesh.visibility = visible;
        this.input.visibility = visible;
    }
    */

  init(
    rootNode: Node,
    viewModel: MeasurementViewModel,
    webcad: Webcad
  ): Promise<void> {
    this.webcad = webcad;
    this.viewModel = viewModel;
    const combined = Promise.all([
      new Promise<void>((resolve, reject) => {
        webcad.assets.getMesh("arc-mesh").then((mesh) => {
          this.template = mesh;
          this.mesh = createMeasurementMesh(mesh, webcad.scene);
          this.mesh.alwaysSelectAsActiveMesh = true;
          this.mesh.material = new MeasurementMaterial(webcad.scene);
          this.update(this.viewModel, this.webcad.viewState.camera);
          resolve();
        }, reject);
      }),
      this.inputVisualizer.init(
        rootNode,
        this.getInputModel(viewModel, this.webcad.viewState.camera),
        webcad
      ),
    ]);

    return new Promise<void>((resolve, reject) => {
      combined.then(() => {
        resolve();
      }, reject);
    });
  }

  getInputModel(
    viewModel: MeasurementViewModel,
    camera: CameraModel
  ): Input3DModel<any> {
    if (
      !!viewModel &&
      viewModel.model &&
      !!(viewModel.viewMask & viewModel.model.mask) &&
      viewModel.model.visible
    ) {
      let segment: LineSegment3 = {
        p1: { ...viewModel.model.start },
        p2: addVectors3(
          viewModel.model.start,
          multiplyVector3byScalar(
            viewModel.model.measurementDirection,
            viewModel.model.exchange.value
          )
        ),
      };
      clipSegmentByCamera(camera, segment);
      let pos: Vector3 = segment3MiddlePoint(segment);
      let dist = Math.max(
        0.2,
        Math.min(
          transformPos(camera.view, viewModel.model.start).z,
          transformPos(camera.view, getMeasurementEndPoint(viewModel.model)).z
        )
      );
      let distance = 0.025 * dist;
      if (viewModel.model.distance) {
        //if undefined or 0
        distance = viewModel.model.distance;
      }
      //let offset = workingPlaneUnitSize(camera).x * 0.01;
      let offset = dist * 0.01;
      if (viewModel.model.distance < 0) {
        offset *= -1;
      }
      pos = addVectors3(
        pos,
        multiplyVector3byScalar(viewModel.model.direction, distance + offset)
      );
      return {
        editable: viewModel.model.editable,
        position: pos,
        dir: viewModel.model.measurementDirection,
        exchange: viewModel.model.exchange,
      };
    } else {
      return null;
    }
  }

  getPointScale(worldPos: Vector4, camera: CameraModel): number {
    var view = camera.view;
    var proj = camera.projection;
    var viewPos: Vector4 = transformVec4(view, worldPos);
    var xVec: Vector4 = { x: 1, y: 0, z: viewPos.z, w: 1 };
    var screen: Vector4 = transformVec4(proj, xVec);
    var unitSize = screen.x / screen.w;
    return Math.abs(0.1 / unitSize);
  }

  dispose() {
    if (this.mesh) {
      this.mesh.dispose();
    }
    this.onChangeObservable = null;
    if (this.inputVisualizer) {
      this.inputVisualizer.dispose();
    }
  }

  getObjectUnderPoint(point: Vector3, maxDist: number): ObjectUnderPoint {
    const bi: BoundingInfo = this.mesh.getBoundingInfo();
    let aabb: Aabb2 = {
      max: { x: bi.maximum.x, y: bi.maximum.y },
      min: { x: bi.minimum.x, y: bi.minimum.y },
    };
    aabb = expandAabb(aabb, this.viewModel.model.start);
    aabb = expandAabb(
      aabb,
      addVectors3(
        this.viewModel.model.start,
        multiplyVector3byScalar(
          normalizeVector3(this.viewModel.model.measurementDirection),
          this.viewModel.model.exchange.value
        )
      )
    );
    if (isPointInAabb2(point, aabb)) {
      return {
        point: point,
        object: this.viewModel,
        type: "Measurement",
      };
    }
    return null;
  }
}

export function clipSegmentByCamera(
  camera: CameraModel,
  lineSegment: LineSegment3
): void {
  const aabb: Aabb2 = {
    min: { x: -1, y: -1 },
    max: { x: 1, y: 1 },
  };

  const pp1: Vector3 = projectPos(
    camera.projection,
    transformPos(camera.view, lineSegment.p1)
  );
  const pp2: Vector3 = projectPos(
    camera.projection,
    transformPos(camera.view, lineSegment.p2)
  );
  const leftP = pp1.x < pp2.x ? pp1 : pp2;
  const rightP = pp1.x < pp2.x ? pp2 : pp1;
  const left = pp1.x < pp2.x ? lineSegment.p1 : lineSegment.p2;
  const right = pp1.x < pp2.x ? lineSegment.p2 : lineSegment.p1;
  if (leftP.x < aabb.min.x && rightP.x >= aabb.min.x) {
    const t = (aabb.min.x - leftP.x) / (rightP.x - leftP.x);
    left.x = left.x + (right.x - left.x) * t;
    left.y = left.y + (right.y - left.y) * t;
    left.z = left.z + (right.z - left.z) * t;
    leftP.x = leftP.x + (rightP.x - leftP.x) * t;
  }

  if (leftP.x < aabb.max.x && rightP.x >= aabb.max.x) {
    const t = (aabb.max.x - leftP.x) / (rightP.x - leftP.x);
    right.x = left.x + (right.x - left.x) * t;
    right.y = left.y + (right.y - left.y) * t;
    right.z = left.z + (right.z - left.z) * t;
  }

  const bottomP = pp1.y < pp2.y ? pp1 : pp2;
  const topP = pp1.y < pp2.y ? pp2 : pp1;
  const bottom = pp1.y < pp2.y ? lineSegment.p1 : lineSegment.p2;
  const top = pp1.y < pp2.y ? lineSegment.p2 : lineSegment.p1;
  if (bottomP.y < aabb.min.y && topP.y >= aabb.min.y) {
    const t = (aabb.min.y - bottomP.y) / (topP.y - bottomP.y);
    bottom.x = bottom.x + (top.x - bottom.x) * t;
    bottom.y = bottom.y + (top.y - bottom.y) * t;
    bottom.z = bottom.z + (top.z - bottom.z) * t;
    bottomP.y = bottomP.y + (topP.y - bottomP.y) * t;
  }

  if (bottomP.y < aabb.max.y && topP.y >= aabb.max.y) {
    const t = (aabb.max.y - bottomP.y) / (topP.y - bottomP.y);
    top.x = bottom.x + (top.x - bottom.x) * t;
    top.y = bottom.y + (top.y - bottom.y) * t;
    top.z = bottom.z + (top.z - bottom.z) * t;
  }
}
