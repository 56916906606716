import {Action} from "@ngrx/store";
import {Polyline} from "../../model/polyline.model";
import {BuildPlate} from "./plate.actions";

export const ADD_LINE = 'ADD LINE';

export class AddLine implements Action {
  readonly type: string = ADD_LINE;
  constructor(public payload: Polyline) {
  }
}

export type LineActions = AddLine;
