export enum ShapeType {
  CIRCLE = 'CIRCLE',
  RECTANGLE = 'RECTANGLE',
  LINE = 'LINE'
}

export interface Shape {
  type: ShapeType;
}

export const initialShape: Shape = {
  type: ShapeType.CIRCLE
};

