<div style="margin: 30px 2px 2px 2px; ">
  <div [hidden]="!treatCornerActive">

    <!--<select id="typeSelect" class="form-control typeSelect" (change)="treatCornerTool.changeType($event.target.value)" #typeSelect>-->
    <!--<option *ngFor="let type of cornerTypes" [value]="type">{{type}} </option>-->
    <!--</select>-->
    <div class="dropdown">
      <dropdown style="margin-top: 15px" [model]="treatCornerDropdown" [tooltipConfig]="{fieldName:'templateType'}"
                [dropdownMenuWidth]="228"></dropdown>
    </div>

    <div class="treat-corner">

      <div *ngIf="typeSelect === 'round'" class="imdesc round-div">
        <input type="number" min="0" max="1000" class="input-small treat-corner-input radius-round "
               [value]="treatCornerTool.radius"
               (input)="roundInput($event, 1000); treatCornerTool.changeRadius(radius.valueAsNumber)"
               #radius>
      </div>

      <div *ngIf="typeSelect === 'rectangle'" class="imdesc rectangle-div">
        <input type="number" min="0" max="1000" class="input-small treat-corner-input width-rectangle"
               [value]="treatCornerTool.width"
               (input)="roundInput($event, 1000);treatCornerTool.changeWidth(width.valueAsNumber)"
               #width>
        <input type="number" min="0" max="1000" class="input-small treat-corner-input height-rectangle"
               [value]="treatCornerTool.height"
               (input)="roundInput($event, 1000);treatCornerTool.changeHeight(height.valueAsNumber)"
               #height>
      </div>

      <div *ngIf="typeSelect === 'cut'" class="imdesc cut-div">
       <input type="number" min="0" max="1000" class="input-small treat-corner-input  width-cut"
               [value]="treatCornerTool.width"
               (input)="roundInput($event, 1000);treatCornerTool.changeWidth(width.valueAsNumber)"
               #width>
       <input type="number" min="0" max="1000" class="input-small treat-corner-input  height-cut"
               [value]="treatCornerTool.height"
               (input)="roundInput($event, 1000);treatCornerTool.changeHeight(height.valueAsNumber)"
               #height>        
      </div>

      <div *ngIf="typeSelect === 'rectangleHole'" class="imdesc rhole-dive">
        <input type="number" min="0" max="1000" class="input-small treat-corner-input width-rectangle-hole"
               [value]="treatCornerTool.width"
               (input)="roundInput($event, 1000);treatCornerTool.changeWidth(width.valueAsNumber)"
               #width>
        <input type="number" min="0" max="1000" class="input-small treat-corner-input radius-rectangle-hole"
               [value]="treatCornerTool.radius"
               (input)="roundInput($event, 1000);treatCornerTool.changeRadius(radius.valueAsNumber)"
               #radius>
        <input type="number" min="0" max="1000" class="input-small treat-corner-input height-rectangle-hole"
               [value]="treatCornerTool.height"
               (input)="roundInput($event, 1000);treatCornerTool.changeHeight(height.valueAsNumber)"
               #height>
      </div>

      <div *ngIf="typeSelect === 'oblique'" class="imdesc oblique-div">
        <input type="number" min="0" max="1000" class="input-small treat-corner-input length-oblique"
               [value]="treatCornerTool.width"
               (input)="roundInput($event, 1000);treatCornerTool.changeLength(length.valueAsNumber)"
               #length>
        <input type="number" min="0" max="1000" class="input-small treat-corner-input rant"
               [value]="treatCornerTool.rant"
               (input)="roundInput($event, 1000);treatCornerTool.changeRant(rant.valueAsNumber)"
               #rant>
        <input type="number" min="0" max="1000" class="input-small treat-corner-input notch"
               [value]="treatCornerTool.notch"
               (input)="roundInput($event, 1000);treatCornerTool.changeNotch(notch.valueAsNumber)"
               #notch>
        <input type="number" min="0" max="1000" class="input-small treat-corner-input radius-oblique"
               [value]="treatCornerTool.radius"
               (input)="roundInput($event, 1000);treatCornerTool.changeRadius(radius.valueAsNumber)"
               #radius>
      </div>
    </div>
  </div>
</div>
