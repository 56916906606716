import {DatasetEffects} from './dataset.effects';
import {ProductConfigurationEffects} from './product-configuration.effects';
import {PerforationEffects} from './perforation.effects';
import {BuildPlateEffects} from './build-plate.effects';
import {SaveEffects} from './save.effects';
import {MountingsEffects} from './mountings.effects';
import {UserTemplateEffects} from './user-template.effects';
import {UserPatternEffects} from './user-pattern.effects';
import { PerfactionEffects } from './perfaction.effects';
import {BlockUserTillSaveEffect} from './block-user-till-save.effect';
import {ExpandedMetalMeshesEffects} from "./expanded-metal-meshes.effects";

export const mevacoEffects = [
  DatasetEffects,
  ProductConfigurationEffects,
  PerforationEffects,
  BuildPlateEffects,
  SaveEffects,
  MountingsEffects,
  UserTemplateEffects,
  UserPatternEffects,
  PerfactionEffects,
  BlockUserTillSaveEffect,
  ExpandedMetalMeshesEffects,
];
