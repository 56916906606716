import {Action} from '@ngrx/store';
import { SubpanelCurrentState, Subpanels } from '../../model/right-panel.model';


export const OPEN_SUBPANEL = 'OPEN_SUBPANEL';
export class OpenSubpanel implements Action {
  readonly type: string = OPEN_SUBPANEL;

  constructor(public payload: {name: Subpanels}) {

  }
}

export const CLOSE_SUBPANEL = 'CLOSE_SUBPANEL';
export class CloseSubpanel implements Action {
    readonly type: string = CLOSE_SUBPANEL;
  
    constructor(public payload: {name: Subpanels}) {
  
    }
}

export const SET_SUBPANEL_STATE = 'SET_SUBPANEL_STATE';
export class SetSubpanelState implements Action {
    readonly type: string = SET_SUBPANEL_STATE;
  
    constructor(public payload: {name: Subpanels, state: SubpanelCurrentState}) {
  
    }
}

export const SET_SUBPANEL_WIDTH = 'SET_SUBPANEL_WIDTH';
export class SetSubpanelWidth implements Action {
    readonly type: string = SET_SUBPANEL_WIDTH;
  
    constructor(public payload: {name: Subpanels, width: number}) {
  
    }
}

export const SET_SUBPANEL_POSITION = 'SET_SUBPANEL_POSITION';
export class SetSubpanelPosition implements Action {
    readonly type: string = SET_SUBPANEL_POSITION;
  
    constructor(public payload: {name: Subpanels, position: {top?: number, left?: number, right?: number, bottom?: number}}) {
  
    }
}

export type RightPanelActions = 
    SetSubpanelPosition |
    SetSubpanelState |
    SetSubpanelWidth |
    CloseSubpanel |
    OpenSubpanel
