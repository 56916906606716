import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  getConfigurationElements, getIsSingleToolMode,
  getProductConfiguration,
  getStep, getTotalPerforationHoles,
  MevacoState
} from '../../store/reducers';
import { select, Store } from '@ngrx/store';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {
  ClearAllElements, SetStep
} from '../../store/actions';
import { Element } from '../../model/product-configuration/element.model';
import { ProductConfiguration, Step } from '../../model/product-configuration/product-configuration.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { delay, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { TranslationProvider } from '../../providers/translation.provider';
import { PlateService } from '../../services/plate.service';
import { ReloadPositionProvider } from '../../providers/reload-position.provider';


interface ReloadState {
  reload: boolean;
  elements: Element[];
}

@Component({
  selector: 'app-bottom-panel',
  templateUrl: './bottom-panel.component.html',
  styleUrls: ['./bottom-panel.component.css']
})
export class BottomPanelComponent implements OnInit, OnDestroy {

  public positions: Observable<Element[]>;
  public areaOfAll = 0;
  private subscriptions: Subscription[] = [];
  public regex: Observable<string>;
  public step: Observable<Step>;
  public Step = Step;
  public isSingleToolMode = this.store.pipe( select(getIsSingleToolMode));
  public totalPerforationHoles = this.store.pipe( select(getTotalPerforationHoles));
  public totalPerforationHolesShort = this.totalPerforationHoles.pipe(map( count =>
    count > 1000000 ?
      (count / 1000000).toFixed(1) + 'M' :
      (count > 1000 ? (count / 1000).toFixed(1) + 'K' : count.toString())));

  constructor(private store: Store<MevacoState>,
    private translationProvider: TranslationProvider,
    private modalService: NgbModal,
    public plateService: PlateService,
    private reloadPositionProvider: ReloadPositionProvider,
    ) { }

  ngOnInit() {

    this.step = this.store.pipe(select(getStep));

    this.positions = combineLatest(
      this.reloadPositionProvider.reloadObservable,
      this.store.pipe(
        select(getConfigurationElements))).pipe(
          delay(0),
          startWith([{ reload: false, elements: [] }, []]),
          switchMap(([reload, elements]) => {
            return of({
              reload: reload,
              elements: elements
            } as ReloadState);
          }),
          distinctUntilChanged((oldState: ReloadState, newState: ReloadState) => {
            return !newState.reload;
          }),
          map((value: ReloadState) => {
            if (!!value) {
              return value.elements.filter((v) => (!!v && !!v.shape && !!v.shape.conture && v.shape.conture.length > 0));
            } else {
              return [];
            }
          }),
          tap((elements) => {
            if (!!elements) {
              this.areaOfAll = this.sumAreas(elements);
            }
          }),


        );

        this.regex = this.store.pipe(
          select(getProductConfiguration),
          map(this.getRegex.bind(this))
        );

  }

  public sumAreas(elements: Element[]) {
    let areas = 0;
    for (const element of elements) {
      areas += element.area * element.quantity;
    }
    return areas;
  }


  confirmPopup(content: any) {
    this.modalService.open(content, {
       centered: true,
       backdrop: 'static',
       windowClass: 'custom-modal-window',
       backdropClass: 'custom-modal-backdrop'
      }).result.then((result: boolean) => {
      if (result) {
        this.clearAll();
      }
    }, () => {

    });
  }

  clearAll() {
    this.store.dispatch(new ClearAllElements());
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      if (!s.closed) {
        s.unsubscribe();
      }
    }
  }

  getRegex(product: ProductConfiguration): string {
    if (product.configuration.isCustomPattern ||
      (product.effect && product.effect.enabled) ||
      (product.fading && product.fading.enabled) ||
      (product.perfaction && product.perfaction.enabled)
    ) {
      return this.translate('individual pattern regex', 'designer');
    }
    if (product.activePerforation === 'wallpaper') {
      return product.wallpaper.name ? this.translate(product.wallpaper.name, 'Lochung') : '';
    } else {
      let regex = this.translate(product.perforation.perforationType, 'Lochung');
      if (regex === null || typeof regex === 'undefined') {
        return '';
      } else {
        let tmp, size, format;
        let ctr = 0;
        let index1 = -1;
        let index2 = -1;
        while (ctr < 10) {
          index1 = regex.indexOf('{');
          index2 = regex.indexOf('}');
          if (index1 === -1 && index2 === -1) {
            break;
          }
          const prop = regex.substring(index1, index2 + 1);
          switch (prop) {
            case '{w}':
              tmp = product.perforation.size.indexOf('x');
              if (tmp === -1) {
                regex = regex.replace(prop, product.perforation.size);
              } else {
                size = product.perforation.size.substring(0, tmp - 1);
                regex = regex.replace(prop, size);
              }
              break;
            case '{l}':
              tmp = product.perforation.size.indexOf('x') + 2;
              size = product.perforation.size.substring(tmp, product.perforation.size.length);
              regex = regex.replace(prop, size);
              break;
            case '{p1}':
            case '{t}':
              format = product.perforation.format;
              tmp = format.indexOf('x');
              if (tmp === -1) {
                regex = regex.replace(prop, product.perforation.format);
              } else {
                size = product.perforation.format.substring(0, tmp - 1);
                regex = regex.replace(prop, size);
              }
              break;
            case '{p2}':
              format = product.perforation.format;
              tmp = format.indexOf('x') + 2;
              size = product.perforation.format.substring(tmp, product.perforation.format.length);
              regex = regex.replace(prop, size);
              break;
          }
          ctr++;
        }
        return regex;
      }
    }
  }

  setStep() {
    this.store.dispatch(new SetStep(Step.cockpit));
}

  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }

}
