import {
  BalconyCorner,
  BasicPlateParams,
  ConfigurableTemplates,
  MultiShape,
  Parallelogram,
  RectangleNotch,
  Staircase,
  Trapeze
} from '../../model/basic-templates.model';
import {
  BalconyNotchParams,
  ConfigurableTemplatesActions,
  RectangleNotchParams,
  SET_BALCONY_CORNER_A,
  SET_BALCONY_CORNER_B,
  SET_BALCONY_CORNER_B1,
  SET_BALCONY_CORNER_B2,
  SET_BALCONY_CORNER_E1,
  SET_BALCONY_CORNER_E2,
  SET_BALCONY_CORNER_F1,
  SET_BALCONY_CORNER_F2,
  SET_BALCONY_CORNER_F3,
  SET_BALCONY_CORNER_NOTCH_X,
  SET_BALCONY_CORNER_NOTCH_Y,
  SET_BALCONY_CORNER_TEMPLATE,
  SET_BASIC_A,
  SET_BASIC_B,
  SET_BASIC_E1,
  SET_BASIC_E2,
  SET_BASIC_F1,
  SET_BASIC_F2,
  SET_BASIC_RECTANGLE_TEMPLATE,
  SET_DEFAULT_VALUES,
  SET_EXPANDED_METAL_HEIGHT,
  SET_EXPANDED_METAL_WIDTH,
  SET_MULTI_SHAPE_A,
  SET_MULTI_SHAPE_B,
  SET_MULTI_SHAPE_E1,
  SET_MULTI_SHAPE_E2,
  SET_MULTI_SHAPE_F1,
  SET_MULTI_SHAPE_F2,
  SET_MULTI_SHAPE_TEMPLATE,
  SET_MULTISHAPE_COLUMN,
  SET_MULTISHAPE_COLUMNS_NUMBER,
  SET_MULTISHAPE_ROW,
  SET_MULTISHAPE_ROWS_NUMBER,
  SET_PARALLELOGRAM_A,
  SET_PARALLELOGRAM_B1,
  SET_PARALLELOGRAM_HA,
  SET_PARALLELOGRAM_TEMPLATE,
  SET_PARALLELOGRAM_TILT,
  SET_RECTANGLE_NOTCH_A,
  SET_RECTANGLE_NOTCH_B,
  SET_RECTANGLE_NOTCH_E1,
  SET_RECTANGLE_NOTCH_E2,
  SET_RECTANGLE_NOTCH_F1,
  SET_RECTANGLE_NOTCH_F2,
  SET_RECTANGLE_NOTCH_NOTCH_X,
  SET_RECTANGLE_NOTCH_NOTCH_Y,
  SET_RECTANGLE_NOTCH_TEMPLATE,
  SET_STAIRCASE_TEMPLATE,
  SET_STAIRCASE_TILT,
  SET_TRAPEZE_A,
  SET_TRAPEZE_ALPHA,
  SET_TRAPEZE_B1,
  SET_TRAPEZE_B2,
  SET_TRAPEZE_BETA,
  SET_TRAPEZE_HA1,
  SET_TRAPEZE_HA2,
  SET_TRAPEZE_HB,
  SET_TRAPEZE_TEMPLATE,
  SetMultishapeColumn,
  SetMultishapeColumnsNumber
} from '../actions/element-creator.actions';
import {roundDecimal} from '../../utils/utils';
import {clamp, clip} from 'webcad/math';

function recalculateMultiShape(state: ConfigurableTemplates, newMultiShape: MultiShape) {
  const newTemplate = newMultiShape.plateParams;
  newMultiShape.plateParams = {
    a: roundDecimal(newTemplate.a, 1),
    b: roundDecimal(newTemplate.b, 1),
    e1: roundDecimal(newTemplate.e1, 1),
    e2: roundDecimal(newTemplate.e2, 1),
    f1: roundDecimal(newTemplate.f1, 1),
    f2: roundDecimal(newTemplate.f2, 1),
  };
  if (newMultiShape.plateParams.a !== state.multiShape.plateParams.a ||
    newMultiShape.plateParams.e1 !== state.multiShape.plateParams.e1 ||
    newMultiShape.plateParams.e2 !== state.multiShape.plateParams.e2) {
    const plateA = newMultiShape.plateParams.a;
    const plateB = newMultiShape.plateParams.b;
    const e1 = newMultiShape.plateParams.e1;
    const e2 = newMultiShape.plateParams.e2;
    const f1 = newMultiShape.plateParams.f1;
    const f2 = newMultiShape.plateParams.f2;
    const perfMin = {x: f1, y: e1};
    const perfMax = {x: plateB - f2, y: plateA - e2};
    const maxSizeY = perfMax.y - perfMin.y;
    const basicMarginSize = 25;
    const marginsSize = (newMultiShape.rowNumber - 1) * basicMarginSize;
    const spaceToDivide = maxSizeY - marginsSize;
    const spacePerArea = spaceToDivide / newMultiShape.rowNumber;
    newMultiShape.rows = [];
    for (let i = 0; i < newMultiShape.rowNumber; i++) {
      newMultiShape.rows.push({margin: basicMarginSize, size: spacePerArea});
    }
  } else if (newMultiShape.plateParams.b !== state.multiShape.plateParams.b ||
    newMultiShape.plateParams.f1 !== state.multiShape.plateParams.f1 ||
    newMultiShape.plateParams.f2 !== state.multiShape.plateParams.f2) {
    const plateA = newMultiShape.plateParams.a;
    const plateB = newMultiShape.plateParams.b;
    const e1 = newMultiShape.plateParams.e1;
    const e2 = newMultiShape.plateParams.e2;
    const f1 = newMultiShape.plateParams.f1;
    const f2 = newMultiShape.plateParams.f2;
    const perfMin = {x: f1, y: e1};
    const perfMax = {x: plateB - f2, y: plateA - e2};
    const maxSizeX = perfMax.x - perfMin.x;
    const basicMarginSize = 25;
    const marginsSize = (newMultiShape.columnNumber - 1) * basicMarginSize;
    const spaceToDivide = maxSizeX - marginsSize;
    const spacePerArea = spaceToDivide / newMultiShape.columnNumber;
    newMultiShape.columns = [];
    for (let i = 0; i < newMultiShape.columnNumber; i++) {
      newMultiShape.columns.push({margin: basicMarginSize, size: spacePerArea});
    }
  }
  return {
    ...state,
    multiShape: newMultiShape
  };
}

export function configurableTemplatesReducer(state: ConfigurableTemplates, action: ConfigurableTemplatesActions): ConfigurableTemplates {
  switch (action.type) {
    case SET_DEFAULT_VALUES:
      return {
        ...state,
        basicRectangleTemplate: {
          a: 800,
          b: 1250,
          f2: 50,
          e1: 50,
          e2: 50,
          f1: 50,
          //name: ''
        },
        expandedMetalTemplate: {
          width: 1200,
          height: 500
        },
        multiShape: {
          plateParams: {
            f2: 50,
            a: 800,
            b: 1250,
            e1: 50,
            e2: 50,
            f1: 50,
            //name: ''
          },
          columns: [{margin: 0, size: 1150}],
          rows: [{margin: 0, size: 700}],
          columnNumber: 1,
          rowNumber: 1
        },
        parallelogramTemplate: {
          f2: 50,
          a: 800,
          b: 1250,
          e1: 50,
          e2: 50,
          f1: 50,
          b1: 602.8,
          ha: 1001.7,
          tilt: 0.92502450355
        },
        staircaseTemplate: {
          tilt: 0.92502450355,
          a1: 300,
          b3: 200,
          b1: 400,
          e1: 50,
          e2: 50,
          f1: 50,
          f2: 50,
          ha: 400,
          a2: 500,
          b2: 250
        },
        rectangleNotchTemplate: {
          a: 800,
          b: 1250,
          f2: 75,
          e1: 75,
          e2: 75,
          f1: 75,
          x1: 50,
          x2: 50,
          x3: 50,
          x4: 50,
          y1: 50,
          y2: 50,
          y3: 50,
          y4: 50
        },
        balconyCornerTemplate: {
          a: 800,
          b: 1200,
          b1: 600,
          b2: 600,
          f2: 75,
          e1: 75,
          e2: 75,
          f1: 75,
          f3: 75,
          x1: 50,
          x2: 50,
          x3: 50,
          x4: 50,
          x5: 50,
          y1: 50,
          y2: 50,
          y3: 50,
          y4: 50,
          y5: 50
        },
        trapezeTemplate: {
          a: 800,
          hb: 200,
          e1: 50,
          e2: 50,
          f1: 50,
          f2: 50,
          ha1: 824.6,
          alpha: 1.3257521,
          b1: 1250,
          b2: 850,
          beta: 1.3257521,
          ha2: 824.6
        }
      };
    case SET_PARALLELOGRAM_TEMPLATE:
      const newParallelogram: Parallelogram = action.payload;
      return {
        ...state,
        parallelogramTemplate: {
          a: roundDecimal(clip(newParallelogram.a, 5000)),
          b: roundDecimal(clip(newParallelogram.b, 5000)),
          e1: roundDecimal(clip(newParallelogram.e1, newParallelogram.a * 0.5)),
          e2: roundDecimal(clip(newParallelogram.e2, newParallelogram.a * 0.5)),
          f1: roundDecimal(clip(newParallelogram.f1, newParallelogram.b * 0.5)),
          f2: roundDecimal(clip(newParallelogram.f2, newParallelogram.b * 0.5)),
          ha: roundDecimal(clip(newParallelogram.ha, 5000)),
          tilt: newParallelogram.tilt,
          b1: roundDecimal(clip(newParallelogram.b1, 5000)),
        } as Parallelogram
      };
    case SET_STAIRCASE_TEMPLATE: {
      const newTemplate: Staircase = action.payload;
      return {
        ...state,
        staircaseTemplate: {
          tilt: clamp(newTemplate.tilt, -360, 360),
          a2: roundDecimal(clip(newTemplate.a2, 5000)),
          b1: roundDecimal(clip(newTemplate.b1, 5000)),
          ha: roundDecimal(clip(newTemplate.ha, 5000)),
          f2: roundDecimal(clip(newTemplate.f2, newTemplate.b1 * 0.5)),
          f1: roundDecimal(clip(newTemplate.f1, newTemplate.b1 * 0.5)),
          e2: roundDecimal(clip(newTemplate.e2, Math.min(newTemplate.a1, newTemplate.ha) * 0.5)),
          e1: roundDecimal(clip(newTemplate.e1, Math.min(newTemplate.a1, newTemplate.ha) * 0.5)),
          a1: roundDecimal(clip(newTemplate.a1, 5000)),
          b2: roundDecimal(clip(newTemplate.b2, 5000)),
          b3: roundDecimal(clip(newTemplate.b3, 5000)),
        }
      };
    }
    case SET_RECTANGLE_NOTCH_TEMPLATE: {
      const newTemplate: RectangleNotch = action.payload;
      return {
        ...state,
        rectangleNotchTemplate: {
          a: roundDecimal(newTemplate.a, 1),
          b: roundDecimal(newTemplate.b, 1),
          e1: roundDecimal(newTemplate.e1, 1),
          e2: roundDecimal(newTemplate.e2, 1),
          f1: roundDecimal(newTemplate.f1, 1),
          f2: roundDecimal(newTemplate.f2, 1),
          x1: roundDecimal(newTemplate.x1, 1),
          x2: roundDecimal(newTemplate.x2, 1),
          x3: roundDecimal(newTemplate.x3, 1),
          x4: roundDecimal(newTemplate.x4, 1),
          y1: roundDecimal(newTemplate.y1, 1),
          y2: roundDecimal(newTemplate.y2, 1),
          y3: roundDecimal(newTemplate.y3, 1),
          y4: roundDecimal(newTemplate.y4, 1)
        }
      };
    }
    case
    SET_RECTANGLE_NOTCH_A : {
      return {
        ...state,
        rectangleNotchTemplate: {
          ...state.rectangleNotchTemplate,
          a: roundDecimal(action.payload, 1),
        }
      };
    }
    case
    SET_RECTANGLE_NOTCH_E1 : {
      return {
        ...state,
        rectangleNotchTemplate: {
          ...state.rectangleNotchTemplate,
          e1: roundDecimal(action.payload, 1),
        }
      };
    }
    case
    SET_RECTANGLE_NOTCH_E2 : {
      return {
        ...state,
        rectangleNotchTemplate: {
          ...state.rectangleNotchTemplate,
          e2: roundDecimal(action.payload, 1),
        }
      };
    }
    case
    SET_RECTANGLE_NOTCH_B : {
      return {
        ...state,
        rectangleNotchTemplate: {
          ...state.rectangleNotchTemplate,
          b: roundDecimal(action.payload, 1),
        }
      };
    }
    case
    SET_RECTANGLE_NOTCH_F1 : {
      return {
        ...state,
        rectangleNotchTemplate: {
          ...state.rectangleNotchTemplate,
          f1: roundDecimal(action.payload, 1),
        }
      };
    }
    case
    SET_RECTANGLE_NOTCH_F2 : {
      return {
        ...state,
        rectangleNotchTemplate: {
          ...state.rectangleNotchTemplate,
          f2: roundDecimal(action.payload, 1),
        }
      };
    }

    case SET_BALCONY_CORNER_TEMPLATE: {
      const newTemplate: BalconyCorner = action.payload;
      return {
        ...state,
        balconyCornerTemplate: {
          a: roundDecimal(newTemplate.a, 1),
          b: roundDecimal(newTemplate.b, 1),
          b1: roundDecimal(newTemplate.b1, 1),
          b2: roundDecimal(newTemplate.b2, 1),
          e1: roundDecimal(newTemplate.e1, 1),
          e2: roundDecimal(newTemplate.e2, 1),
          f1: roundDecimal(newTemplate.f1, 1),
          f2: roundDecimal(newTemplate.f2, 1),
          f3: roundDecimal(newTemplate.f3, 1),
          x1: roundDecimal(newTemplate.x1, 1),
          x2: roundDecimal(newTemplate.x2, 1),
          x3: roundDecimal(newTemplate.x3, 1),
          x4: roundDecimal(newTemplate.x4, 1),
          x5: roundDecimal(newTemplate.x5, 1),
          y1: roundDecimal(newTemplate.y1, 1),
          y2: roundDecimal(newTemplate.y2, 1),
          y3: roundDecimal(newTemplate.y3, 1),
          y4: roundDecimal(newTemplate.y4, 1),
          y5: roundDecimal(newTemplate.y5, 1)
        }
      };
    }
    case
    SET_BALCONY_CORNER_A: {
      return {
        ...state,
        balconyCornerTemplate: {
          ...state.balconyCornerTemplate,
          a: roundDecimal(action.payload, 1)
        }
      };
    }
    case
    SET_BALCONY_CORNER_B: {
      return {
        ...state,
        balconyCornerTemplate: {
          ...state.balconyCornerTemplate,
          b: roundDecimal(action.payload, 1)
        }
      };
    }
    case
    SET_BALCONY_CORNER_B1: {
      return {
        ...state,
        balconyCornerTemplate: {
          ...state.balconyCornerTemplate,
          b1: roundDecimal(action.payload, 1)
        }
      };
    }
    case
    SET_BALCONY_CORNER_B2: {
      return {
        ...state,
        balconyCornerTemplate: {
          ...state.balconyCornerTemplate,
          b2: roundDecimal(action.payload, 1)
        }
      };
    }
    case
    SET_BALCONY_CORNER_E1: {
      return {
        ...state,
        balconyCornerTemplate: {
          ...state.balconyCornerTemplate,
          e1: roundDecimal(action.payload, 1)
        }
      };
    }
    case
    SET_BALCONY_CORNER_E2: {
      return {
        ...state,
        balconyCornerTemplate: {
          ...state.balconyCornerTemplate,
          e2: roundDecimal(action.payload, 1)
        }
      };
    }
    case
    SET_BALCONY_CORNER_F1: {
      return {
        ...state,
        balconyCornerTemplate: {
          ...state.balconyCornerTemplate,
          f1: roundDecimal(action.payload, 1)
        }
      };
    }
    case
    SET_BALCONY_CORNER_F2: {
      return {
        ...state,
        balconyCornerTemplate: {
          ...state.balconyCornerTemplate,
          f2: roundDecimal(action.payload, 1)
        }
      };
    }
    case
    SET_BALCONY_CORNER_F3: {
      return {
        ...state,
        balconyCornerTemplate: {
          ...state.balconyCornerTemplate,
          f3: roundDecimal(action.payload, 1)
        }
      };
    }
    case SET_MULTI_SHAPE_TEMPLATE: {
      const newMultiShape = action.payload as MultiShape;
      return recalculateMultiShape(state, newMultiShape);
    }
    case
    SET_MULTI_SHAPE_A: {
      const newMultiShape = {
        ...state.multiShape,
        plateParams: {
          ...state.multiShape.plateParams,
          a: roundDecimal(action.payload, 1)
        }
      };
      return recalculateMultiShape(state, newMultiShape);
    }
    case
    SET_MULTI_SHAPE_E1: {
      const newMultiShape = {
        ...state.multiShape,
        plateParams: {
          ...state.multiShape.plateParams,
          e1: roundDecimal(action.payload, 1)
        }
      };
      return recalculateMultiShape(state, newMultiShape);
    }
    case
    SET_MULTI_SHAPE_E2: {
      const newMultiShape = {
        ...state.multiShape,
        plateParams: {
          ...state.multiShape.plateParams,
          e2: roundDecimal(action.payload, 1)
        }
      };
      return recalculateMultiShape(state, newMultiShape);
    }
    case
    SET_MULTI_SHAPE_B: {
      const newMultiShape = {
        ...state.multiShape,
        plateParams: {
          ...state.multiShape.plateParams,
          b: roundDecimal(action.payload, 1)
        }
      };
      return recalculateMultiShape(state, newMultiShape);
    }
    case
    SET_MULTI_SHAPE_F1: {
      const newMultiShape = {
        ...state.multiShape,
        plateParams: {
          ...state.multiShape.plateParams,
          f1: roundDecimal(action.payload, 1)
        }
      };
      return recalculateMultiShape(state, newMultiShape);
    }
    case
    SET_MULTI_SHAPE_F2: {
      const newMultiShape = {
        ...state.multiShape,
        plateParams: {
          ...state.multiShape.plateParams,
          f2: roundDecimal(action.payload, 1)
        }
      };
      return recalculateMultiShape(state, newMultiShape);
    }
    case
    SET_BASIC_RECTANGLE_TEMPLATE: {
      const newTemplate: BasicPlateParams = action.payload;
      return {
        ...state,
        basicRectangleTemplate: {
          a: roundDecimal(newTemplate.a, 1),
          b: roundDecimal(newTemplate.b, 1),
          e1: roundDecimal(newTemplate.e1, 1),
          e2: roundDecimal(newTemplate.e2, 1),
          f1: roundDecimal(newTemplate.f1, 1),
          f2: roundDecimal(newTemplate.f2, 1),
        }
      };
    }
    case
    SET_BASIC_A: {
      return {
        ...state,
        basicRectangleTemplate: {
          ...state.basicRectangleTemplate,
          a: roundDecimal(<number>(action.payload), 1)
        }
      };
    }
    case
    SET_BASIC_E1: {
      return {
        ...state,
        basicRectangleTemplate: {
          ...state.basicRectangleTemplate,
          e1: roundDecimal(<number>(action.payload), 1)
        }
      };
    }
    case
    SET_BASIC_E2: {
      return {
        ...state,
        basicRectangleTemplate: {
          ...state.basicRectangleTemplate,
          e2: roundDecimal(<number>(action.payload), 1)
        }
      };
    }
    case
    SET_BASIC_B: {
      return {
        ...state,
        basicRectangleTemplate: {
          ...state.basicRectangleTemplate,
          b: roundDecimal(<number>(action.payload), 1)
        }
      };
    }
    case
    SET_BASIC_F1: {
      return {
        ...state,
        basicRectangleTemplate: {
          ...state.basicRectangleTemplate,
          f1: roundDecimal(<number>(action.payload), 1)
        }
      };
    }
    case
    SET_BASIC_F2: {
      return {
        ...state,
        basicRectangleTemplate: {
          ...state.basicRectangleTemplate,
          f2: roundDecimal(<number>(action.payload), 1)
        }
      };
    }

    case 
    SET_EXPANDED_METAL_WIDTH:{
      return{
        ...state,
        expandedMetalTemplate:{
          ...state.expandedMetalTemplate,
          width: roundDecimal(<number>action.payload, 1)
        }
      }
    }
    case 
    SET_EXPANDED_METAL_HEIGHT:{
      return{
        ...state,
        expandedMetalTemplate:{
          ...state.expandedMetalTemplate,
          height: roundDecimal(<number>action.payload, 1)
        }
      }
    }
    case
    SET_STAIRCASE_TILT:
      if (isNaN(action.payload) || isNaN(parseFloat(action.payload))) {
        return {
          ...state,
          staircaseTemplate: {
            ...state.staircaseTemplate,
            tilt: null
          }
        };
      }
      return {
        ...state,
        staircaseTemplate: {
          ...state.staircaseTemplate,
          tilt: <number>(action.payload) * Math.PI / 180
        }
      };
    case
    SET_PARALLELOGRAM_B1: {
      if (isNaN(action.payload) || isNaN(parseFloat(action.payload))) {
        return {
          ...state,
          parallelogramTemplate: {
            ...state.parallelogramTemplate,
            b1: null
          }
        };
      }
      const newB1 = Math.round(<number>(action.payload) * 1000) / 1000;
      const newTilt = Math.atan(state.parallelogramTemplate.ha / newB1);
      return {
        ...state,
        parallelogramTemplate: {
          ...state.parallelogramTemplate,
          b1: roundDecimal(newB1, 1),
          tilt: newTilt
        }
      };
    }
    case
    SET_PARALLELOGRAM_HA: {
      if (isNaN(action.payload) || isNaN(parseFloat(action.payload))) {
        return {
          ...state,
          parallelogramTemplate: {
            ...state.parallelogramTemplate,
            ha: null
          }
        };
      }
      const newHA = <number>(action.payload);
      const a = Math.sin(state.parallelogramTemplate.tilt) * newHA;
      const b1 = newHA * Math.cos(state.parallelogramTemplate.tilt);
      return {
        ...state,
        parallelogramTemplate: {
          ...state.parallelogramTemplate,
          a: roundDecimal(a, 1),
          b1: roundDecimal(b1, 1),
          ha: roundDecimal(newHA, 1)
        }
      };
    }
    case
    SET_PARALLELOGRAM_A: {
      if (isNaN(action.payload) || isNaN(parseFloat(action.payload))) {
        return {
          ...state,
          parallelogramTemplate: {
            ...state.parallelogramTemplate,
            a: null
          }
        };
      }
      const newA = <number>(action.payload);
      const ha = newA / Math.sin(state.parallelogramTemplate.tilt);
      const b1 = ha * Math.cos(state.parallelogramTemplate.tilt);
      return {
        ...state,
        parallelogramTemplate: {
          ...state.parallelogramTemplate,
          a: roundDecimal(newA, 1),
          b1: roundDecimal(b1, 1),
          ha: roundDecimal(ha, 1)
        }
      };
    }
    case
    SET_PARALLELOGRAM_TILT: {
      if (isNaN(action.payload) || isNaN(parseFloat(action.payload))) {
        return {
          ...state,
          parallelogramTemplate: {
            ...state.parallelogramTemplate,
            tilt: null
          }
        };
      }
      const newAngle = <number>(action.payload);
      const inRadians = newAngle * Math.PI / 180;
      const ha = state.parallelogramTemplate.a / Math.sin(inRadians);
      const b1 = ha * Math.cos(inRadians);
      return {
        ...state,
        parallelogramTemplate: {
          ...state.parallelogramTemplate,
          ha: Math.round(ha * 1000) / 1000,
          b1: Math.round(b1 * 1000) / 1000,
          tilt: inRadians
        }
      };
    }
    case
    SET_MULTISHAPE_COLUMN: {
      let value = (<SetMultishapeColumn>action).payload.value;
      let margin = (<SetMultishapeColumn>action).payload.margin;
      const index = (<SetMultishapeColumn>action).payload.index;
      const multiShapeState = {...state.multiShape};
      const plateA = multiShapeState.plateParams.a;
      const plateB = multiShapeState.plateParams.b;
      const e1 = multiShapeState.plateParams.e1;
      const e2 = multiShapeState.plateParams.e2;
      const f1 = multiShapeState.plateParams.f1;
      const f2 = multiShapeState.plateParams.f2;
      const perfMin = {x: f1, y: e1};
      const perfMax = {x: plateB - f2, y: plateA - e2};
      const maxSizeX = perfMax.x - perfMin.x;
      if (margin > maxSizeX) {
        margin = maxSizeX;
      }
      if (value + margin > maxSizeX) {
        value = maxSizeX - margin;
      }
      let overSpace = 0;
      let underSpace = 0;
      for (let i = 0; i < index; i++) {
        const r = multiShapeState.columns[i];
        underSpace += (r.size + r.margin);
      }

      for (let i = index + 1; i < multiShapeState.columnNumber; i++) {
        const r = multiShapeState.columns[i];
        overSpace += (r.size + r.margin);
      }

      const maxLeftSpace = maxSizeX - underSpace;

      if (value > maxLeftSpace - margin) {
        value = maxLeftSpace - margin;
      }
      let leftSpaceToDivide = maxLeftSpace - (value + margin);
      multiShapeState.columns[index].size = value;
      multiShapeState.columns[index].margin = margin;
      for (let i = index + 1; i < multiShapeState.columnNumber; i++) {
        const r = multiShapeState.columns[i];
        if (i === multiShapeState.columnNumber - 1) {
          r.size = leftSpaceToDivide;
          r.margin = 0;
          leftSpaceToDivide = 0;
        } else if ((r.size + r.margin) < leftSpaceToDivide) {
          leftSpaceToDivide -= (r.size + r.margin);
        } else {
          r.size = leftSpaceToDivide;
          r.margin = 0;
          leftSpaceToDivide = 0;
        }
      }
      return {
        ...state,
        multiShape: multiShapeState
      };
    }
    case
    SET_MULTISHAPE_ROW: {
      let value = (<SetMultishapeColumn>action).payload.value;
      let margin = (<SetMultishapeColumn>action).payload.margin;
      const index = (<SetMultishapeColumn>action).payload.index;
      const multiShapeState = {...state.multiShape};
      const plateA = multiShapeState.plateParams.a;
      const plateB = multiShapeState.plateParams.b;
      const e1 = multiShapeState.plateParams.e1;
      const e2 = multiShapeState.plateParams.e2;
      const f1 = multiShapeState.plateParams.f1;
      const f2 = multiShapeState.plateParams.f2;
      const perfMin = {x: f1, y: e1};
      const perfMax = {x: plateB - f2, y: plateA - e2};
      const maxSizeY = perfMax.y - perfMin.y;
      if (margin > maxSizeY) {
        margin = maxSizeY;
      }
      if (value + margin > maxSizeY) {
        value = maxSizeY - margin;
      }
      let overSpace = 0;
      let underSpace = 0;
      for (let i = 0; i < index; i++) {
        const r = multiShapeState.rows[i];
        underSpace += (r.size + r.margin);
      }

      for (let i = index + 1; i < multiShapeState.rowNumber; i++) {
        const r = multiShapeState.rows[i];
        overSpace += (r.size + r.margin);
      }

      const maxLeftSpace = maxSizeY - underSpace;

      if (value > maxLeftSpace - margin) {
        value = maxLeftSpace - margin;
      }
      let leftSpaceToDivide = maxLeftSpace - (value + margin);
      multiShapeState.rows[index].size = value;
      multiShapeState.rows[index].margin = margin;
      for (let i = index + 1; i < multiShapeState.rowNumber; i++) {
        const r = multiShapeState.rows[i];
        if (i === multiShapeState.rowNumber - 1) {
          r.size = leftSpaceToDivide;
          r.margin = 0;
          leftSpaceToDivide = 0;
        } else if ((r.size + r.margin) < leftSpaceToDivide) {
          leftSpaceToDivide -= (r.size + r.margin);
        } else {
          r.size = leftSpaceToDivide;
          r.margin = 0;
          leftSpaceToDivide = 0;
        }
      }
      return {
        ...state,
        multiShape: multiShapeState
      };
    }
    case
    SET_MULTISHAPE_COLUMNS_NUMBER: {
      let value: number = Number((<SetMultishapeColumnsNumber>action).payload);
      if (isNaN(value) || value < 1) {
        value = 1;
      }

      const diff = Math.abs(state.multiShape.columnNumber - value);
      const plateA = state.multiShape.plateParams.a;
      const plateB = state.multiShape.plateParams.b;
      const e1 = state.multiShape.plateParams.e1;
      const e2 = state.multiShape.plateParams.e2;
      const f1 = state.multiShape.plateParams.f1;
      const f2 = state.multiShape.plateParams.f2;
      const perfMin = {x: f1, y: e1};
      const perfMax = {x: plateB - f2, y: plateA - e2};
      const maxSizeX = perfMax.x - perfMin.x;
      const basicMarginSize = 25;
      if (diff === 0) {
        return {...state, multiShape: {...state.multiShape, plateParams: {...state.multiShape.plateParams}}};
      }
      if (state.multiShape.columnNumber > value) {
        const index = state.multiShape.columnNumber - diff;
        const newMultiShape = {...state.multiShape};
        newMultiShape.columnNumber = index;
        newMultiShape.columns.splice(index, diff);
        let usedSpace = 0;
        for (const c of newMultiShape.columns) {
          usedSpace += c.margin + c.size;
        }
        const leftSpace = maxSizeX - usedSpace;
        newMultiShape.columns[newMultiShape.columnNumber - 1].size += leftSpace;
        return {
          ...state,
          multiShape: newMultiShape
        };
      } else {
        const newMultiShape = {...state.multiShape};
        newMultiShape.columnNumber = newMultiShape.columnNumber + diff;
        const marginsSize = (newMultiShape.columnNumber - 1) * basicMarginSize;
        const spaceToDivide = maxSizeX - marginsSize;
        const spacePerArea = spaceToDivide / newMultiShape.columnNumber;
        newMultiShape.columns = [];
        for (let i = 0; i < newMultiShape.columnNumber; i++) {
          newMultiShape.columns.push({margin: basicMarginSize, size: spacePerArea});
        }
        return {
          ...state,
          multiShape: newMultiShape
        };
        return {
          ...state,
          multiShape: newMultiShape
        };
      }
    }
    case
    SET_MULTISHAPE_ROWS_NUMBER: {
      let value: number = (<SetMultishapeColumnsNumber>action).payload;
      if (isNaN(value) || value < 1) {
        value = 1;
      }

      const diff = Math.abs(state.multiShape.rowNumber - value);
      const plateA = state.multiShape.plateParams.a;
      const plateB = state.multiShape.plateParams.b;
      const e1 = state.multiShape.plateParams.e1;
      const e2 = state.multiShape.plateParams.e2;
      const f1 = state.multiShape.plateParams.f1;
      const f2 = state.multiShape.plateParams.f2;
      const perfMin = {x: f1, y: e1};
      const perfMax = {x: plateB - f2, y: plateA - e2};
      const maxSizeY = perfMax.y - perfMin.y;
      const basicMarginSize = 25;
      if (diff === 0) {
        return {...state, multiShape: {...state.multiShape, plateParams: {...state.multiShape.plateParams}}};
      }
      if (state.multiShape.rowNumber > value) {
        const index = state.multiShape.rowNumber - diff;
        const newMultiShape = {...state.multiShape};
        newMultiShape.rowNumber = index;
        newMultiShape.rows.splice(index, diff);
        let usedSpace = 0;
        for (const c of newMultiShape.rows) {
          usedSpace += c.margin + c.size;
        }
        const leftSpace = maxSizeY - usedSpace;
        newMultiShape.rows[newMultiShape.rowNumber - 1].size += leftSpace;
        return {
          ...state,
          multiShape: newMultiShape
        };
      } else {
        const newMultiShape = {...state.multiShape};
        newMultiShape.rowNumber = newMultiShape.rowNumber + diff;
        const marginsSize = (newMultiShape.rowNumber - 1) * basicMarginSize;
        const spaceToDivide = maxSizeY - marginsSize;
        const spacePerArea = spaceToDivide / newMultiShape.rowNumber;
        newMultiShape.rows = [];
        for (let i = 0; i < newMultiShape.rowNumber; i++) {
          newMultiShape.rows.push({margin: basicMarginSize, size: spacePerArea});
        }
        return {
          ...state,
          multiShape: newMultiShape
        };
      }
    }
    case SET_BALCONY_CORNER_NOTCH_X: {
      const payload = <BalconyNotchParams>(action.payload);
      if (payload.x < 0) {
        payload.x = 0;
      }
      if (payload.y < 0) {
        payload.y = 0;
      }
      const newBalconyCorner: BalconyCorner = {...state.balconyCornerTemplate};
      newBalconyCorner['x' + payload.index.toString()] = roundDecimal(payload.x);
      for (let i = 1; i < 6; i++) {
        if (i !== payload.index) {
          if (!payload.fill['x' + i.toString()]) {
            newBalconyCorner['x' + i.toString()] = roundDecimal(payload.x);
          }
        }
      }
      return {
        ...state,
        balconyCornerTemplate: newBalconyCorner
      };
    }
    case
    SET_RECTANGLE_NOTCH_NOTCH_X: {
      const payload = <RectangleNotchParams>(action.payload);
      if (payload.x < 0) {
        payload.x = 0;
      }
      if (payload.y < 0) {
        payload.y = 0;
      }
      const newRectangleNotch: RectangleNotch = {...state.rectangleNotchTemplate};
      newRectangleNotch['x' + payload.index.toString()] = roundDecimal(payload.x);
      for (let i = 1; i < 5; i++) {
        if (i !== payload.index) {
          if (!payload.fill['x' + i.toString()]) {
            newRectangleNotch['x' + i.toString()] = roundDecimal(payload.x);
          }
        }
      }
      return {
        ...state,
        rectangleNotchTemplate: newRectangleNotch
      };
    }
    case
    SET_BALCONY_CORNER_NOTCH_Y: {
      const payload = <BalconyNotchParams>(action.payload);
      if (payload.y < 0) {
        payload.y = 0;
      }
      const newBalconyCorner: BalconyCorner = {...state.balconyCornerTemplate};
      newBalconyCorner['y' + payload.index.toString()] = roundDecimal(payload.y);
      for (let i = 1; i < 6; i++) {
        if (i !== payload.index) {
          if (!payload.fill['y' + i.toString()]) {
            newBalconyCorner['y' + i.toString()] = roundDecimal(payload.y);
          }
        }
      }
      return {
        ...state,
        balconyCornerTemplate: newBalconyCorner
      };
    }
    case
    SET_RECTANGLE_NOTCH_NOTCH_Y: {
      const payload = <RectangleNotchParams>(action.payload);
      if (payload.x < 0) {
        payload.x = 0;
      }
      if (payload.y < 0) {
        payload.y = 0;
      }
      const newRectangleNotch: RectangleNotch = {...state.rectangleNotchTemplate};
      newRectangleNotch['y' + payload.index.toString()] = roundDecimal(payload.y);
      for (let i = 1; i < 5; i++) {
        if (i !== payload.index) {
          if (!payload.fill['y' + i.toString()]) {
            newRectangleNotch['y' + i.toString()] = roundDecimal(payload.y);
          }
        }
      }
      return {
        ...state,
        rectangleNotchTemplate: newRectangleNotch
      };
    }
    case
    SET_TRAPEZE_TEMPLATE: {
      const newTemplate: Trapeze = action.payload;
      return {
        ...state,
        trapezeTemplate: {
          a: roundDecimal(newTemplate.a, 1),
          e1: roundDecimal(newTemplate.e1, 1),
          e2: roundDecimal(newTemplate.e2, 1),
          f1: roundDecimal(newTemplate.f1, 1),
          f2: roundDecimal(newTemplate.f2, 1),
          ha1: roundDecimal(newTemplate.ha1, 1),
          ha2: roundDecimal(newTemplate.ha2, 1),
          hb: roundDecimal(newTemplate.hb, 1),
          b1: roundDecimal(newTemplate.b1, 1),
          b2: roundDecimal(newTemplate.b2, 1),
          alpha: newTemplate.alpha,
          beta: newTemplate.beta,
        }
      };
    }
    case
    SET_TRAPEZE_A: {
      if (isNaN(action.payload) || isNaN(parseFloat(action.payload))) {
        return {
          ...state,
          trapezeTemplate: {
            ...state.trapezeTemplate,
            a: null,
          }
        };
      }
      const newA = action.payload as number;
      const newHA1 = newA / Math.sin(state.trapezeTemplate.alpha);
      const newHA2 = newA / Math.sin(state.trapezeTemplate.beta);
      const newHB = Math.cos(state.trapezeTemplate.alpha) * newHA1;
      return {
        ...state,
        trapezeTemplate: {
          ...state.trapezeTemplate,
          a: roundDecimal(newA),
          ha1: roundDecimal(newHA1),
          ha2: roundDecimal(newHA2),
          hb: roundDecimal(newHB)
        }
      };
    }
    case
    SET_TRAPEZE_ALPHA: {
      if (isNaN(action.payload) || isNaN(parseFloat(action.payload))) {
        return {
          ...state,
          trapezeTemplate: {
            ...state.trapezeTemplate,
            alpha: null
          }
        };
      }
      const newAlpha = roundDecimal(Number(action.payload), 2) * Math.PI / 180;
      const newHA1 = state.trapezeTemplate.a / Math.sin(newAlpha);
      const currUpSum = state.trapezeTemplate.b2 + state.trapezeTemplate.hb;
      const newHB = Math.cos(newAlpha) * newHA1;
      const newB2 = currUpSum - newHB;
      return {
        ...state,
        trapezeTemplate: {
          ...state.trapezeTemplate,
          ha1: roundDecimal(newHA1),
          hb: roundDecimal(newHB),
          b2: roundDecimal(newB2),
          alpha: newAlpha
        }
      };
    }
    case
    SET_TRAPEZE_B1: {
      if (isNaN(action.payload) || isNaN(parseFloat(action.payload))) {
        return {
          ...state,
          trapezeTemplate: {
            ...state.trapezeTemplate,
            b1: null
          }
        };
      }
      const newB1 = Number(action.payload);
      const hb2 = newB1 - (state.trapezeTemplate.b2 + state.trapezeTemplate.hb);
      const newHA2 = Math.sqrt((hb2 * hb2) + (state.trapezeTemplate.a * state.trapezeTemplate.a));
      const newBeta = Math.asin(state.trapezeTemplate.a / newHA2);
      return {
        ...state,
        trapezeTemplate: {
          ...state.trapezeTemplate,
          ha2: roundDecimal(newHA2),
          beta: newBeta,
          b1: roundDecimal(newB1)
        }
      };
    }
    case
    SET_TRAPEZE_B2: {
      if (isNaN(action.payload) || isNaN(parseFloat(action.payload))) {
        return {
          ...state,
          trapezeTemplate: {
            ...state.trapezeTemplate,
            b2: null
          }
        };
      }
      const newB2 = Number(action.payload);
      const hb2 = state.trapezeTemplate.b1 - (newB2 + state.trapezeTemplate.hb);
      const newHA2 = Math.sqrt((hb2 * hb2) + (state.trapezeTemplate.a * state.trapezeTemplate.a));
      const newBeta = Math.atan2(state.trapezeTemplate.a, hb2);
      return {
        ...state,
        trapezeTemplate: {
          ...state.trapezeTemplate,
          ha2: roundDecimal(newHA2),
          beta: newBeta,
          b2: roundDecimal(newB2)
        }
      };
    }
    case
    SET_TRAPEZE_BETA: {
      if (isNaN(action.payload) || isNaN(parseFloat(action.payload))) {
        return {
          ...state,
          trapezeTemplate: {
            ...state.trapezeTemplate,
            beta: null
          }
        };
      }
      const newBeta = roundDecimal(Number(action.payload), 2) * Math.PI / 180;
      const newHA2 = state.trapezeTemplate.a / Math.sin(newBeta);
      const newHb2 = Math.sqrt((newHA2 * newHA2) - (state.trapezeTemplate.a * state.trapezeTemplate.a));
      const upLength = state.trapezeTemplate.b1 - newHb2;
      const newB2 = upLength - state.trapezeTemplate.hb;
      return {
        ...state,
        trapezeTemplate: {
          ...state.trapezeTemplate,
          ha2: roundDecimal(newHA2),
          beta: newBeta,
          b2: roundDecimal(newB2)
        }
      };
    }
    case
    SET_TRAPEZE_HA1: {
      if (isNaN(action.payload) || isNaN(parseFloat(action.payload))) {
        return {
          ...state,
          trapezeTemplate: {
            ...state.trapezeTemplate,
            ha1: null
          }
        };
      }
      const newHA1 = action.payload as number;
      const newA = newHA1 * Math.sin(state.trapezeTemplate.alpha);
      const currUpSum = state.trapezeTemplate.b2 + state.trapezeTemplate.hb;
      const newHB = Math.cos(state.trapezeTemplate.alpha) * newHA1;
      const newB2 = currUpSum - newHB;
      const newHA2 = newA / Math.sin(state.trapezeTemplate.beta);
      return {
        ...state,
        trapezeTemplate: {
          ...state.trapezeTemplate,
          ha2: roundDecimal(newHA2),
          b2: roundDecimal(newB2),
          ha1: roundDecimal(newHA1),
          hb: roundDecimal(newHB),
          a: roundDecimal(newA)
        }
      };
    }
    case
    SET_TRAPEZE_HA2: {
      if (isNaN(action.payload) || isNaN(parseFloat(action.payload))) {
        return {
          ...state,
          trapezeTemplate: {
            ...state.trapezeTemplate,
            ha2: null
          }
        };
      }
      const newHA2 = action.payload as number;
      const newA = newHA2 * Math.sin(state.trapezeTemplate.beta);
      const hb2 = state.trapezeTemplate.b1 - (state.trapezeTemplate.b2 + state.trapezeTemplate.hb);
      const newHb2 = Math.sqrt((newHA2 * newHA2) - (newA * newA));
      const diff = newHb2 - hb2;
      const newB2 = state.trapezeTemplate.b2 - diff;
      const newHA1 = newA / Math.sin(state.trapezeTemplate.alpha);
      const newHB = Math.cos(state.trapezeTemplate.alpha) * newHA1;
      return {
        ...state,
        trapezeTemplate: {
          ...state.trapezeTemplate,
          ha2: roundDecimal(newHA2),
          b2: roundDecimal(newB2),
          ha1: roundDecimal(newHA1),
          hb: roundDecimal(newHB),
          a: roundDecimal(newA),
        }
      };
    }
    case
    SET_TRAPEZE_HB: {
      if (isNaN(action.payload) || isNaN(parseFloat(action.payload))) {
        return {
          ...state,
          trapezeTemplate: {
            ...state.trapezeTemplate,
            hb: null
          }
        };
      }
      const newHB = action.payload as number;
      const la = Math.atan(newHB / state.trapezeTemplate.a);
      const newAlpha = (Math.PI / 2) - la;
      const newHA1 = Math.sqrt((newHB * newHB) + (state.trapezeTemplate.a * state.trapezeTemplate.a));
      return {
        ...state,
        trapezeTemplate: {
          ...state.trapezeTemplate,
          hb: roundDecimal(newHB),
          alpha: newAlpha,
          ha1: roundDecimal(newHA1)
        }
      };
    }
  }

  return state;
}
