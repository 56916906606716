import {MevacoPage} from '../../model/mevaco-page.model';
import {anyBendedElement} from '../../model/product-configuration/product-configuration.model';
import {SET_NOTE_ACTIVE} from '../actions';

// generateLabel is controlled by SET_NOTE_ACTIVE action but if this is cassette configuration
// then this option mast be set to true [MCASSET-74]
export function generateLabelReducer(state: MevacoPage, action: any): MevacoPage {
  let result = state;
  if (state && state.productConfiguration) {
    const current = state.productConfiguration.generateLabel;
    let newValue = current;
    if (anyBendedElement(state.productConfiguration)) {
      newValue = true;
    } else {
      if (action.type === SET_NOTE_ACTIVE) {
        newValue = action.payload;
      }
    }
    if (newValue !== current) {
      result = {
        ...state,
        productConfiguration: {
          ...state.productConfiguration,
          generateLabel: newValue
        }
      };
    }
  }
  return  result;
}
