import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Guid } from "guid-typescript";
import { Observable, Subscription, fromEvent } from "rxjs";
import { map } from "rxjs/operators";
import {
  LineIntersect2D,
  Vector2,
  normalizeVector2,
  subVectors2,
} from "webcad/math";
import {
  PolylineArea,
  Segment,
  SegmentType,
  aabbOfPolyline,
} from "webcad/models";
import { Parallelogram } from "../../../../../../src/app/model/basic-templates.model";
import { PerforationAreaModel } from "../../../../../../src/app/model/perforation-area.model";
import {
  Element,
  ElementType,
} from "../../../../../../src/app/model/product-configuration/element.model";
import {
  ShapeWithHoles,
  shapeWithHolesHash,
} from "../../../../../../src/app/model/shape-with-holes";
import { UserTemplate } from "../../../../../../src/app/model/user-template.model";
import {
  MevacoState,
  ProductConfigurationAddElement,
  getParallelogramTemplate,
  getUserTemplates,
} from "../../../../../../src/app/store";
import {
  SetParallelogramA,
  SetParallelogramB1,
  SetParallelogramHa,
  SetParallelogramTemplate,
  SetParallelogramTilt,
} from "../../../../../../src/app/store/actions/element-creator.actions";
import { GetUserTemplates } from "../../../../../../src/app/store/actions/user-template.actions";
import { roundDecimal } from "../../../../../../src/app/utils/utils";
import { reqValParallelogram } from "../../../../store/reducers";
import { TemplateName } from "../../template-name";
import { TemplateUtils } from "../../templatesUtils";

@Component({
  selector: "mevaco-tilted-plate",
  templateUrl: "./tilted-plate.component.html",
  styleUrls: [
    "../../../element-creator/element-creator.component.css",
    "./tilted-plate.component.css",
  ],
})
export class TiltedPlateComponent implements OnInit {
  @ViewChild("closeButton", { static: true })
  closeButton: ElementRef;
  @Output() dismissModal = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() isModalOpen = new EventEmitter();

  public tiltedTemplate: Parallelogram;
  public templates: Observable<UserTemplate[]>;
  public pi = Math.PI;
  public roundDecimal = roundDecimal;
  private subscriptions: Subscription[] = [];
  public reqValParallelogram: Observable<boolean>;

  constructor(
    private store: Store<MevacoState>,
    public templateUtils: TemplateUtils
  ) {}

  ngOnInit() {
    this.store.dispatch(new GetUserTemplates());
    this.templates = this.store.pipe(select(getUserTemplates));
    this.subscriptions.push(
      this.store
        .pipe(select(getParallelogramTemplate))
        .subscribe((v) => (this.tiltedTemplate = { ...v }))
    );
    this.reqValParallelogram = this.store.pipe(select(reqValParallelogram));
    this.createFromTiltedPlate();
  }

  isModalOpenMethod() {
    this.isModalOpen.emit(false);
  }

  dismiss(str: string) {
    this.dismissModal.emit(str);
  }

  close(input: {}) {
    this.closeModal.emit(input);
  }

  createFromTiltedPlate() {
    const obs = fromEvent(this.closeButton.nativeElement, "click").pipe(
      map(() => {
        return this.tiltedTemplate;
      })
    );

    obs.subscribe(
      (result) => {
        const plateA = result.a / 1000;
        const plateB = result.b / 1000;
        const e1 = result.e1 / 1000;
        const e2 = result.e2 / 1000;
        const f1 = result.f1 / 1000;
        const f2 = result.f2 / 1000;
        const b1 = result.b1 / 1000;
        const conture: Segment[] = [
          {
            type: SegmentType.line,
            begin: { x: 0, y: 0 },
            end: { x: plateB, y: 0 },
          },
          {
            type: SegmentType.line,
            begin: { x: plateB, y: 0 },
            end: { x: plateB + b1, y: plateA },
          },
          {
            type: SegmentType.line,
            begin: { x: plateB + b1, y: plateA },
            end: { x: b1, y: plateA },
          },
          {
            type: SegmentType.line,
            begin: { x: b1, y: plateA },
            end: { x: 0, y: 0 },
          },
        ];
        const shapeWithHoles: ShapeWithHoles = {
          conture: conture,
          holes: [],
        };
        shapeWithHoles.aabb = aabbOfPolyline(shapeWithHoles.conture);
        shapeWithHoles.area = PolylineArea(shapeWithHoles.conture);
        shapeWithHoles.hash = shapeWithHolesHash(shapeWithHoles);
        const dir: Vector2 = normalizeVector2(
          subVectors2({ x: b1, y: plateA }, { x: 0, y: 0 })
        );
        const xAsix: Vector2 = { x: 1, y: 0 };
        const angle = Math.atan2(dir.y, dir.x);
        const ml = f1 / Math.sin(angle);
        const mr = f2 / Math.sin(angle);
        const ld = LineIntersect2D(xAsix, { x: 0, y: e1 }, dir, {
          x: ml,
          y: 0,
        });
        const rd = LineIntersect2D(xAsix, { x: 0, y: e1 }, dir, {
          x: plateB - mr,
          y: 0,
        });
        const ru = LineIntersect2D(xAsix, { x: 0, y: plateA - e2 }, dir, {
          x: plateB - mr,
          y: 0,
        });
        const lu = LineIntersect2D(xAsix, { x: 0, y: plateA - e2 }, dir, {
          x: ml,
          y: 0,
        });
        const perfContour = [
          {
            type: SegmentType.line,
            begin: ld,
            end: rd,
          },
          {
            type: SegmentType.line,
            begin: rd,
            end: ru,
          },
          {
            type: SegmentType.line,
            begin: ru,
            end: lu,
          },
          {
            type: SegmentType.line,
            begin: lu,
            end: ld,
          },
        ];
        const perfAabb = aabbOfPolyline(perfContour);
        const perfArea = PolylineArea(perfContour);
        const perforationAreaShape: ShapeWithHoles = {
          conture: perfContour,
          holes: [],
          aabb: perfAabb,
          area: perfArea,
        };
        perforationAreaShape.hash = shapeWithHolesHash(perforationAreaShape);
        const perfAreaModel: PerforationAreaModel[] = [
          {
            shape: perforationAreaShape,
            offset: {
              x: 0,
              y: 0,
            },
            rotation: 0,
          },
        ];
        const element: Element = {
          type: ElementType.individual,
          templateName: TemplateName.parallelogram,
          boundary: null,
          position: -1, // will be set inb reducer
          quantity: 1,
          a: plateA,
          b: plateB,
          aIst: plateA,
          bIst: plateB,
          e1: e1.toString(),
          e1ist: e1.toString(),
          e2: e2.toString(),
          e2ist: e2.toString(),
          f1: f1.toString(),
          f1ist: f1.toString(),
          f2: f2.toString(),
          f2ist: f2.toString(),
          openMeshE: "",
          openMeshF: "",
          toleranceWidth: 0,
          toleranceLength: 0,
          label: "",
          note: "",
          unperforated: false,
          posibleCoil: "No",
          posiblePlate: "No",
          shapes: null,
          nodes: null,
          verticesIndexes: null,
          visualizationShape: null,
          previewImageId: Guid.create().toString(),
          shape: shapeWithHoles,
          perforationAreas: perfAreaModel,
          helpLines: [],
          measurements: [],
          angleMeasurements: [],
          mountings: [],
          perforationAutoCenter: true,
          area: shapeWithHoles.area,
          minRadius: null,
          minParallelEdgesDistance: null,
          minMarginDistance: null,
          isPerforationSimpleRect: null,
          isShapeSimpleRect: null,
          minMountingHoleEdgeDistance: null,
          minMountingHolesDistance: null,
          minOutsideAngle: null,
          numberOfArcs: null,
          minDistanceBetweenEdges: null,
          minDistanceBetweenMountingAndPerforatedArea: null,
          //perforation: null,
          possibleAllAcross: true,
          minDistanceBetweenPerforationAreas: null,
          lfbIst: undefined,
          lflIst: undefined,

          breakLines: [],
          bendingLinesDistances: [],
          //possiblePerforationZones: []
        };
        this.store.dispatch(new ProductConfigurationAddElement(element));
      },
      (reason) => {}
    );
  }

  setTiltedPlateAngle(value: any) {
    this.store.dispatch(new SetParallelogramTilt(value));
  }

  setTiltedPlateA(value: any) {
    this.store.dispatch(new SetParallelogramA(value));
  }

  setTiltedPlateHa(newHA: any) {
    this.store.dispatch(new SetParallelogramHa(newHA));
  }

  setTiltedPlateB1(newB1: any) {
    this.store.dispatch(new SetParallelogramB1(newB1));
  }

  setTiltedTemplate(template: Parallelogram) {
    this.store.dispatch(new SetParallelogramTemplate({ ...template }));
  }
}
