import {Injectable} from '@angular/core';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {UserTemplate} from '../model/user-template.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {AddTemplateParams} from '../model/client-server.Params/add-template-params';
import {Template} from '../store/actions/user-template.actions';

@Injectable()
export class UserTemplateService {
  constructor(private http: HttpClient) {

  }

  public getUserTemplates(configId: string): Observable<UserTemplate[]> {
    return this.http.get<UserTemplate[]>(environment.api_url + '/api/userTemplates/getTemplatesByConfigId/' + configId).pipe(
      map(v => v),
      catchError(error => observableThrowError(error))
    );
  }

  public addUserTemplate(parameters: AddTemplateParams): Observable<UserTemplate> {
    return this.http.post<UserTemplate>(environment.api_url + '/api/userTemplates/addTemplate', parameters).pipe(
      map(v => v),
      catchError(error => observableThrowError(error))
    );
  }

  public configureUserTemplate(data: Template): Observable<boolean> {
    return this.http.post<boolean>(environment.api_url + '/api/userTemplates/configureTemplate', data).pipe(
      map(v => v),
      catchError(error => observableThrowError(error))
    );
  }

  public deleteUserTemplate(id: number): Observable<boolean> {
    return this.http.post<boolean>(environment.api_url + '/api/userTemplates/deleteTemplate', id).pipe(
      map(v => v),
      catchError(error => observableThrowError(error))
    );
  }

}
