import {
  Color4,
  Effect,
  LinesMesh,
  MeshBuilder,
  Nullable,
  Scene,
  ShaderMaterial,
  Vector3,
  Vector4,
  Color3,
} from "webcad/babylonjs/core";

export function createLineSystemWithDepthOffset(
  name: string,
  options: {
    lines: Vector3[][];
    updatable?: boolean;
    instance?: Nullable<LinesMesh>;
    colors?: Nullable<Color4[][]>;
    useVertexAlpha?: boolean;
  },
  scene: Nullable<Scene>,
  depthOffset: number
): LinesMesh {
  createShaders();

  let mesh = MeshBuilder.CreateLineSystem(name, options, scene);
  mesh.renderingGroupId = 3;
  (mesh as any)._colorShader = new ShaderMaterial(
    "lineSystemWithDepthOffset",
    scene,
    {
      vertex: "lineSystemWithDepthOffset",
      fragment: "lineSystemWithDepthOffset",
    },
    {
      attributes: ["position", "color"],
      uniforms: ["worldViewProjection", "depthOffset"],
    }
  );
  (mesh.material as ShaderMaterial).setFloat(
    "depthOffset",
    depthOffset
  );
  mesh.visibility = options.useVertexAlpha ? 0.9999 : 1;
  return mesh;
}

export function createLineSystemWithDepthOffsetSingleColor(
  name: string,
  options: {
    lines: Vector3[][];
    color: Vector4;
    useVertexAlpha?: boolean;
  },
  scene: Nullable<Scene>,
  depthOffset: number
): LinesMesh {
  createShaders();
  const mesh = MeshBuilder.CreateLineSystem(name, options, scene);
  mesh.color = new Color3(options.color.x, options.color.y, options.color.z);
  mesh.renderingGroupId = 3;
  // (mesh as any)._colorShader = new ShaderMaterial(
  //   "lineSystemWithDepthOffset",
  //   scene,
  //   {
  //     vertex: "lineSystemWithDepthOffset",
  //     fragment: "customColor",
  //   },
  //   {
  //     attributes: ["position", "color"],
  //     uniforms: ["worldViewProjection", "depthOffset"],
  //   }
  // );
  // (mesh.material as ShaderMaterial).setFloat(
  //   "depthOffset",
  //   depthOffset
  // );
  // (mesh.material as ShaderMaterial).setVector4(
  //   "matColor",
  //   new Vector4(0.0, 0.0, 1.0, 1)
  // );
  mesh.visibility = options.useVertexAlpha ? 0.9999 : 1;
  return mesh;
}

export function createLineWithDepthOffset(
  name: string,
  options: {
    points: Vector3[];
    updatable?: boolean;
    instance?: Nullable<LinesMesh>;
    colors?: Color4[];
    useVertexAlpha?: boolean;
  },
  scene: Nullable<Scene>,
  depthOffset: number
): LinesMesh {
  createShaders();
  let mesh = MeshBuilder.CreateLines(name, options, scene);
  mesh.renderingGroupId = 3;
  (mesh as any)._colorShader = new ShaderMaterial(
    "lineSystemWithDepthOffset",
    scene,
    {
      vertex: "lineSystemWithDepthOffset",
      fragment: "lineSystemWithDepthOffset",
    },
    {
      attributes: ["position", "color"],
      uniforms: ["worldViewProjection", "depthOffset"],
    }
  );
  (mesh.material as ShaderMaterial).setFloat(
    "depthOffset",
    depthOffset
  );
  mesh.visibility = options.useVertexAlpha ? 0.9999 : 1;
  return mesh;
}

function createShaders() {
  if (!Effect.ShadersStore["lineSystemWithDepthOffsetVertexShader"]) {
    Effect.ShadersStore["lineSystemWithDepthOffsetVertexShader"] = `
      attribute vec3 position;
      attribute vec4 color;
      uniform mat4 worldViewProjection;
      varying vec4 vColor;
      void main(void) {
        gl_Position=worldViewProjection*vec4(position,1.0);
        vColor=color;
      }
    `;
    Effect.ShadersStore["lineSystemWithDepthOffsetFragmentShader"] = `
      uniform float depthOffset;
      varying vec4 vColor;
      void main(void) {
          gl_FragColor = vColor;
          gl_FragDepth = (gl_FragCoord.z / gl_FragCoord.w + depthOffset) * gl_FragCoord.w;
      }
    `;
  }
}
