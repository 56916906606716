import {Tool} from "../tool.interface";
import {ClosestSegments} from "../../providers/mevaco-pointer.provider";
import {PointerState} from "webcad/collision";
import {Store} from "@ngrx/store";
import {
  MevacoState
} from "../../store/reducers";
import {PerforationService} from "../../services/perforation.service";
import {SetTool} from "../../store/actions/tools.actions";

export class WallpaperMoveTool extends Tool {

  constructor(private store: Store<MevacoState>, private perforationService:PerforationService) {
    super();
  }

  activate() {

  }

  isDirty(): boolean {
    return false;
  }

  onCancel() {
    this.cleanup();
  }
  onConfirm() {
    this.cleanup();
  }

  cleanup(){
    this.store.dispatch( new SetTool( null))
  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {
  }


  onMouseClick(pointerState: PointerState) {
  }

  onMouseDown(pointerState: PointerState) {
  }

  onMouseMove(pointerState: PointerState) {
  }

  onMouseUp(pointerState: PointerState) {
  }

  reset() {
  }

}
