import {Actions, createEffect, ofType} from '@ngrx/effects';
import {combineLatest, filter, from, Observable, of} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {currentGltfFileName, getGltfs, getProductType, MevacoState} from '../reducers';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {GltfLoaded, LOAD_GLTF, LoadGltf} from '../actions';
import {View3dProvider} from '../../providers/view3d.provider';
import {Injectable} from '@angular/core';
import { Mesh, VertexBuffer, Matrix, Vector3, StandardMaterial, Color3, DirectionalLight, Geometry, Node, QuadraticErrorSimplification, Scene } from 'webcad/babylonjs/core';
import {environment} from '../../../environments/environment';


@Injectable()
export class ExpandedMetalMeshesEffects {
  constructor(
    private actions: Actions,
    private store: Store<MevacoState>,
    private view3dProvider: View3dProvider
  ) {}

  initLoad = createEffect(() =>
    combineLatest([
      this.store.select(currentGltfFileName),
      this.view3dProvider.engineReady// we need to just wait for it
    ])
    .pipe(
      withLatestFrom(this.store),
      filter(([[gltfFileName, _], mevacoState]) => gltfFileName && mevacoState.model.gltfs[gltfFileName] === undefined && !!this.view3dProvider.mevacoView3D),
      map(([[gltfFileName, _], mevacoState]) => {
        const stremoe = mevacoState.model.productConfiguration.configuration?.stremoe;

        const strand = +(mevacoState.model.productConfiguration.extendedMetal.feedrate);
        const minS = +stremoe.vorschubMin.replace(',', '.');
        const maxS = +stremoe.vorschubMax.replace(',', '.');
        let t = 1.0;
        if (minS !== maxS) {
          t = (strand - minS) / (maxS - minS);
        }

        const totalThickness = stremoe.gDickeMin * (1.0 - t) + stremoe.gDickeMin * t;
        return new LoadGltf(gltfFileName, stremoe.sizeS, totalThickness, strand, +mevacoState.model.productConfiguration.material.thickness);
      })
    )
  );

  loadGltf = createEffect(() => {
    return this.actions.pipe(
      ofType(LOAD_GLTF),
      switchMap((action: LoadGltf) => {
        const p = this.view3dProvider.mevacoView3D.load(`${ environment.api_url}/api/.glb?name=`, action.payload + '.glb');
        // const p = this.view3dProvider.mevacoView3D.load('/assets/gltf/expmet/', 'pattern22_2.glb');
        const o = from(p);
        const a = o.pipe(
          catchError(err => of(null as Scene)),
          filter( scene => !!scene),
          switchMap(scene => {
            return from(new Promise<GltfLoaded>(reslove => {
              const root = scene.rootNodes.find(n => n.id === '__root__') as Mesh;

              const meshes: Mesh[] = [];
              getChildrenMeshes(root, meshes);
              scene.removeMesh(root);

              // const mesh = Mesh.MergeMeshes([meshes[0]]);


              const sd = meshes[1].getPositionData();
              const snap1 = new Vector3(sd[0], sd[1], sd[2]);
              const snap2 = new Vector3(sd[3], sd[4], sd[5]);
              const snap3 = new Vector3(sd[6], sd[7], sd[8]);
              const snap4 = new Vector3(sd[9], sd[10], sd[11]);
              const snap5 = new Vector3();
              const snap6 = new Vector3();
              const isWave = sd.length > 12;
              if ( isWave ) {
                snap5.set( sd[12], sd[13], sd[14]);
                snap6.set( sd[15], sd[16], sd[17]);
              }


              const mesh = meshes[0];
              const indices = mesh.getIndices();
              const positions = mesh.getPositionData();
              const vTotal = mesh.getTotalVertices();

              const newIndices: number[] = Array(indices.length);
              const newPositions: number[] = [];
              const newNormals: number[] = [];

              for (let v = 0; v < vTotal; v++) {
                const tIndices: number[] = [];
                for (let i = 0; i < indices.length; i++) {
                  const index = indices[i];
                  if (index === v) {
                    tIndices.push(i);
                  }
                }

                const tNormals: Vector3[] = Array(tIndices.length);
                for (let i = 0; i < tIndices.length; i++) {
                  const t = Math.floor(tIndices[i] / 3);
                  const i1 = indices[t * 3 + 0];
                  const i2 = indices[t * 3 + 1];
                  const i3 = indices[t * 3 + 2];
                  const v1 = new Vector3( positions[ i1 * 3 + 0], positions[ i1 * 3 + 1], positions[ i1 * 3 + 2]);
                  const v2 = new Vector3( positions[ i2 * 3 + 0], positions[ i2 * 3 + 1], positions[ i2 * 3 + 2]);
                  const v3 = new Vector3( positions[ i3 * 3 + 0], positions[ i3 * 3 + 1], positions[ i3 * 3 + 2]);
                  const e1 = v1.subtract(v2);
                  const e2 = v1.subtract(v3);
                  tNormals[i] = e1.cross(e2).normalize();
                }
                const groups: {index: number, normal: Vector3}[][] = [];

                const ungruped = tIndices.map( (ti, i) => ({index: ti, normal: tNormals[i]}));
                while (ungruped.length > 0) {
                  const ge = ungruped.pop();
                  const group: { index: number, normal: Vector3 }[] = [ge];
                  groups.push(group);
                  let fitIndex = -1;
                  do {
                    fitIndex = ungruped.findIndex((ge2, i) =>
                      ge.normal.dot(ge2.normal) > 0.64278760968 // 50 deg
                    );
                    if (fitIndex !== -1) {
                      group.push(ungruped[fitIndex]);
                      ungruped.splice(fitIndex, 1);
                    }
                  } while (fitIndex !== -1);
                }

                for (let i = 0; i < groups.length; i++) {
                  const group = groups[i];
                  const newIndex = newPositions.length / 3;
                  const normal = new Vector3();
                  for (let j = 0; j < group.length; j++) {
                    const groupElement = group[j];
                    newIndices[groupElement.index] = newIndex;
                    normal.addInPlace(groupElement.normal);
                  }
                  normal.normalize();
                  newPositions.push(positions[v * 3], positions[v * 3 + 1], positions[v * 3 + 2]);
                  newNormals.push(normal.x, normal.y, normal.z);
                }
              }

              const d1 = snap1.subtract(snap3);
              const d2 = snap2.subtract(snap4);
              for (let i = 0; i < newPositions.length - 3; i += 3) {
                for (let j = i + 3; j < newPositions.length; j += 3) {
                  if ( (
                      ( Math.abs(newPositions[i + 0] + d1.x - newPositions[j + 0]) < 0.1 || Math.abs(newPositions[i + 0] - d1.x - newPositions[j + 0]) < 0.1) &&
                      ( Math.abs(newPositions[i + 1] + d1.y - newPositions[j + 1]) < 0.1 || Math.abs(newPositions[i + 1] - d1.y - newPositions[j + 1]) < 0.1) &&
                      ( Math.abs(newPositions[i + 2] + d1.z - newPositions[j + 2]) < 0.1 || Math.abs(newPositions[i + 2] - d1.z - newPositions[j + 2]) < 0.1)
                    ) || (
                    ( Math.abs(newPositions[i + 0] + d2.x - newPositions[j + 0]) < 0.1 || Math.abs(newPositions[i + 0] - d2.x - newPositions[j + 0]) < 0.1) &&
                    ( Math.abs(newPositions[i + 1] + d2.y - newPositions[j + 1]) < 0.1 || Math.abs(newPositions[i + 1] - d2.y - newPositions[j + 1]) < 0.1) &&
                    ( Math.abs(newPositions[i + 2] + d2.z - newPositions[j + 2]) < 0.1 || Math.abs(newPositions[i + 2] - d2.z - newPositions[j + 2]) < 0.1)
                    )
                  ) {
                    const n1 = new Vector3(newNormals[i], newNormals[i + 1], newNormals[i + 2]);
                    const n2 = new Vector3(newNormals[j], newNormals[j + 1], newNormals[j + 2]);
                    if (n1.dot(n2) > 0.64278760968) { // 50 deg
                      n1.addInPlace(n2).normalize();
                      newNormals[i] = newNormals[j] = n1.x;
                      newNormals[i + 1] = newNormals[j + 1] = n1.y;
                      newNormals[i + 2] = newNormals[j + 2] = n1.z;
                    }
                  }

                }
              }

              mesh.setIndices(newIndices);
              mesh.setVerticesData(VertexBuffer.PositionKind, newPositions);
              mesh.setVerticesData(VertexBuffer.NormalKind, newNormals);



              const modelSwd = isWave ? snap3.subtract(snap6).length() : (snap2.subtract(snap3).length() * 2);

              let m = Matrix.Identity();
              m = m.multiply(Matrix.Translation((snap1.x + snap2.x) / 2, (snap1.y + snap3.y) / 2, (snap1.z + snap2.z) / 2));
              m = m.multiply(Matrix.Scaling(0.001, 0.001 , 0.001));
              m = m.multiply(Matrix.RotationX(Math.PI / 2));
              m = m.multiply(Matrix.RotationZ(Math.PI / 2));
              const alpha =  isWave ?
                Math.atan2(snap3.y - snap6.y, snap3.z - snap6.z) :
                Math.atan2(snap2.y - snap3.y, snap2.z - snap3.z);
              m = m.multiply(Matrix.RotationY(alpha));
              m = m.multiply(Matrix.Scaling(action.swd / modelSwd, 1, 1));
              mesh.bakeTransformIntoVertices(m);
              mesh.refreshBoundingInfo();
              scene.removeMesh(mesh);


              (scene as any).gltfs = (scene as any).gltfs || {};
              (scene as any).gltfs[action.payload] = mesh;
              reslove(new GltfLoaded(action.payload));

              /*
              const simplificator = new QuadraticErrorSimplification(mesh);
              simplificator.simplify({ quality: 0.5, distance: 0 }, function(simplified) {
                (scene as any).gltfs = (scene as any).gltfs || {};
                (scene as any).gltfs[action.payload] = simplified;
                reslove(new GltfLoaded({nodeName: 'pattern22', gltfFileName: action.payload}));
              });
               */
            }));
          })
        );
        return a;
      })
    );
  });


}


function getChildrenMeshes(root: Node, meshes: Mesh[]) {
  if (!!(root as Mesh).geometry) {
    meshes.push(root as Mesh);
  }
  root.getChildren().forEach( child => getChildrenMeshes(child, meshes) );
}
