import {PRODUCT_CONFIGURATION_SET_MATERIAL_THICKNESS/*, SET_ZONES*/} from '../actions';
import {initialPlate, Plate} from '../../model/plate.model';
import {
  ADD_SHAPE_TO_ADD,
  ADD_SHAPE_TO_REMOVE,
  CHANGE_ACTION_TYPE,
  INITIALIZE_PLATE,
  RESET_CURRENT_SHAPE,
  SET_PLATE_HEIGHT,
  SET_PLATE_MATERIAL,
  SET_PLATE_WIDTH,
  SET_HEX_COLOR_STRING
} from '../actions/plate.actions';
import {changeMapValue} from '../../utils/utils';
import {ADD_LINE} from '../actions/line.actions';
import {
  ADD_MOUNTING,
  ADD_MOUNTINGS,
  MountingMoveParams,
  MOVE_MOUNTING_BY_VECTOR,
  REMOVE_MOUNTINGS
} from '../actions/mountings.actions';
import {addVectors2} from 'webcad/math';
import {MountingAttachment} from '../../model/mounting.model';
import {Action} from "@ngrx/store";


export function plateReducer(state: Plate = initialPlate, action: any): Plate {
  //const newState = {...state};
  switch (action.type) {
    case INITIALIZE_PLATE:
      return action.payload;
    case ADD_SHAPE_TO_REMOVE:
      return {
        ...state
      };
    case ADD_SHAPE_TO_ADD:
      return {
        ...state
      };
    case RESET_CURRENT_SHAPE:
      return state;
    case CHANGE_ACTION_TYPE:
      const ns = {...state};
      return {
        ...ns,
        actionType: action.payload
      };
    case ADD_LINE:
      return state;
    case SET_PLATE_WIDTH:
      return {
        ...state,
        width: action.payload * 1000
      };
    case SET_PLATE_HEIGHT:
      return {
        ...state,
        height: action.payload * 1000
      };
    case SET_PLATE_MATERIAL:
      return {
        ...state,
        plateMaterial: action.payload
      };
    case ADD_MOUNTING: {
      return {
        ...state,
        mountingHoles: [
          ...state.mountingHoles, action.payload
        ]
      };
    }
    case ADD_MOUNTINGS: {
      const toAdd: MountingAttachment[] = action.payload;
      return {
        ...state,
        mountingHoles: [...state.mountingHoles, ...toAdd]
      };
    }
    case REMOVE_MOUNTINGS: {
      const toRemove: MountingAttachment[] = action.payload;
      const mountings = [...state.mountingHoles].filter( mounting =>
        !toRemove.some( mountingToRemove => mountingToRemove === mounting )
      );
      if (state.mountingHoles.length !== mountings.length) {
        return {
          ...state,
          mountingHoles: mountings,
        };
      }
      return state;
    }
    case MOVE_MOUNTING_BY_VECTOR: {
      const params: MountingMoveParams = action.payload;
      const mountings = [...state.mountingHoles];
      for (let i = 0; i < mountings.length; i++) {
        const mountingHole = mountings[i];
        if ( params.mountings.indexOf(mountingHole) !== -1) {
          mountings[i] = {
            ...mountingHole,
            position: addVectors2(mountingHole.position, params.vector)
          };
        }
      }
      return {
        ...state,
        mountingHoles: mountings
      };
    }
    case SET_HEX_COLOR_STRING: {
      return {
        ...state,
        hexColorString: action.payload
      };
    }
    case PRODUCT_CONFIGURATION_SET_MATERIAL_THICKNESS:{
      return {
        ...state,
        depth: +action.payload / 1000
      }
    }
    /*
    case SET_ZONES: {
      return {
        ...state,
        possiblePerforationZones: action.payload
      };
    }*/
  }
  return state;
}

function getNewKey(map: Map<number, any>): number {
  const keys = Array.from(map.keys());
  if (keys.length > 0) {
    let highest = keys[0];
    for (const k of keys) {
      if (k > highest) {
        highest = k;
      }
    }
    return highest + 1;
  } else {
    return 0;
  }
}

