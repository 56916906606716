import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Options } from "ngx-slider-v2";
import { BehaviorSubject, Subscription } from "rxjs";
import { filter, first, map } from "rxjs/operators";
import {
  SelectedDesignerEffect,
  mapToEffectWithInput,
} from "../../model/effect.model";
import {
  Subpanel,
  SubpanelCurrentState,
  Subpanels,
} from "../../model/right-panel.model";
import { TranslationProvider } from "../../providers/translation.provider";
import {
  MevacoState,
  SetSubpanelState,
  getEffectInputsSelector,
  getEffects,
  getEffectsSubpanelState,
} from "../../store";
import { SetEffect } from "../../store/actions/product-configuration.actions";
import { Dropdown, DropdownOption } from "../dropdown/dropdown.component";

@Component({
  selector: "app-effects-editor",
  templateUrl: "./effects-editor.component.html",
  styleUrls: ["./effects-editor.component.css"],
})
export class EffectsEditorComponent implements OnInit, OnDestroy {
  private currentEffectSubject = new BehaviorSubject<string>(null);
  private subscriptions: Subscription[] = [];

  public effectsSubpanelState: Subpanel;
  public SubpanelCurrentState = SubpanelCurrentState;
  public selectedEffect: SelectedDesignerEffect = null;
  public autoTicks = false;
  public disabled = false;
  public invert = false;
  public showTicks = true;
  public step = 1;
  public thumbLabel = false;
  public vertical = false;
  public tickInterval = 2000;
  public color = "primary";

  public effectsDropdown = new Dropdown(
    "effects",
    this.store.pipe(
      select(getEffects),
      filter((x) => !!x),
      map((arr) =>
        arr.map(
          (value) =>
            new DropdownOption(
              "text",
              this.translate("effect_" + value.name.toString()),
              true,
              value.name.toString(),
              this.translate("Effect"),
              true,
              value
            )
        )
      )
    ),
    this.currentEffectSubject.asObservable(),
    (value) => this.currentEffectSubject.next(value),
    null
  );

  constructor(
    private store: Store<MevacoState>,
    private translationProvider: TranslationProvider
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .pipe(select(getEffectsSubpanelState))
        .subscribe(
          (effectsSubpanel) => (this.effectsSubpanelState = effectsSubpanel)
        ),
      this.effectsDropdown.selectedOption.subscribe((res) => {
        if (res === null || res.metadata == null) {
          return;
        }
        if (
          this.selectedEffect != null &&
          res.metadata.id == this.selectedEffect.id
        ) {
          return;
        }
        this.selectedEffect = mapToEffectWithInput(res.metadata, true);
        this.saveEffectToStore();
      }),
      this.store
        .pipe(
          select(getEffectInputsSelector),
          filter((x) => !!x),
          first()
        )
        .subscribe((res) => {
          this.selectedEffect = res;
          this.currentEffectSubject.next(res.name);
        })
    );
  }

  toggleEffectsSubpanel() {
    if (this.effectsSubpanelState.state === SubpanelCurrentState.OPENED) {
      this.store.dispatch(
        new SetSubpanelState({
          name: Subpanels.EFFECTS_SUBPANEL,
          state: SubpanelCurrentState.CLOSED,
        })
      );
    } else {
      this.store.dispatch(
        new SetSubpanelState({
          name: Subpanels.EFFECTS_SUBPANEL,
          state: SubpanelCurrentState.OPENED,
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  getSliderTickInterval(): number | "auto" {
    if (this.showTicks) {
      return this.autoTicks ? "auto" : this.tickInterval;
    }

    return 0;
  }

  translate(text: string, module: string = "designer") {
    return this.translationProvider.translate(text, module);
  }

  getOptionsForSlider(min: number, max: number) {
    return {
      floor: min,
      ceil: max,
      showSelectionBarFromValue: 0,
    } as Options;
  }

  editSliderEffect = (newMaxValue: any, parameterIndex: number) => {
    this.selectedEffect.savedParameters[parameterIndex].currentMax =
      newMaxValue.value;
    this.saveEffectToStore();
  };

  editToggleEffect = (v, parameterIndex: number) => {
    this.selectedEffect.savedParameters[parameterIndex].currentValue = v;
    this.saveEffectToStore();
  };

  editSliderEffectFromInput = (event: any, parameterIndex: number) => {
    this.selectedEffect.savedParameters[parameterIndex].currentMax =
      event.target.value;
    this.saveEffectToStore();
  };

  editRangeSliderEffect = (event, parameterIndex: number) => {
    const params = this.selectedEffect.savedParameters[parameterIndex];
    params.currentMax = event.highValue;
    params.currentMin = event.value;
    this.selectedEffect.savedParameters[parameterIndex] = params;
    this.saveEffectToStore();
  };

  setValueFromInput(event, type: string, parameterIndex: number) {
    const params = this.selectedEffect.savedParameters[parameterIndex];
    if (type === "min") {
      params.currentMin = event.target.value;
    } else if (type === "max") {
      params.currentMax = event.target.value;
    }
    this.selectedEffect.savedParameters[parameterIndex] = params;
    this.saveEffectToStore();
  }

  saveEffectToStore = () => {
    this.store.dispatch(
      new SetEffect(this.selectedEffect.id === -1 ? null : this.selectedEffect)
    );
  };
}
