import {DragArrowsManager} from "./drag-arrows.manager";
import {DragArrowModel} from "../../models/drag-arrow.model";
import {Subscription} from "rxjs";
import {Vector2} from "../../math";

export class DragArrowModelManager {
    private subscriptions: Subscription[] = [];

    constructor(private id: number, private manager: DragArrowsManager) {

    }

    public setModel(model: DragArrowModel): void {
        this.manager.setModelWithId(this.id, model);
    }

    public getModel(): DragArrowModel {
        return this.manager.getModelWithId(this.id);
    }

    public subscribe(callback: (vec: Vector2) => void): Subscription {
        const subscription = this.manager.getModelDragObservable(this.id).subscribe((v) => callback(v));
        this.subscriptions.push(subscription);
        return subscription;
    }

    public setPosition(position: Vector2): void {
        this.manager.setPositionOfModel(this.id, position);
    }

    public getPosition(): Vector2 {
        return this.manager.getPositionOfModel(this.id);
    }

    public setMoveDirection(direction: Vector2): void {
        this.manager.setMoveDirectionOfModel(this.id, direction);
    }

    public getMoveDirection(): Vector2 {
        return this.manager.getMoveDirectionOfModel(this.id);
    }

    public setRotation(rotation: number): void {
        this.manager.setRotationOfModel(this.id, rotation);
    }

    public getRotation(): number {
        return this.manager.getRotationOfModel(this.id);
    }

    public setVisibility(visibility: boolean): void {
        this.manager.setVisibilityOfModel(this.id, visibility);
    }

    public getVisibility(): boolean {
        return this.manager.getVisibilityOfModel(this.id);
    }

    public dispose(): void {
        for (const s of this.subscriptions) {
            if (!s.closed) {
                s.unsubscribe();
            }
        }
    }
}