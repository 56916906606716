export const environment = {
  production: true,
  api_url: "",
  enable_cassettes: true,
  enable_custom_patterns: true,
  enable_custom_patterns_tools: false,
  enable_effects: false,
  disable_translation: false,
  logo_img: 'assets/MEVACO_Logo_hoch_big_farbig_RGB.png',
};
