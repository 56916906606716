import { ElementRef, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { RotateCamera } from "webcad";
import { Drawing, ShapeOrigin } from "../model/drawing.model";
import { MevacoPage } from "../model/mevaco-page.model";
import { PlateMaterialType } from "../model/plate.model";
import { createNode } from "../model/point-node.model";
import { Element } from "../model/product-configuration/element.model";
import { Step } from "../model/product-configuration/product-configuration.model";
import { PerforationService } from "../services/perforation.service";
import { PlateAabbProvider } from "./plate-aabb.provider";
import { PlateMeshProvider } from "./plate-mesh.provider";
import { View3dProvider } from "./view3d.provider";

interface SnapshotData {
  subject: Subject<string>;
  state: MevacoPage;
  position?: number;
  mode: "customPattern" | "element";
}

@Injectable()
export class PreviewPlateProvider {
  private snapshotQueue: SnapshotData[] = [];
  private shouldRunQueue: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private _isQueueRunning: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(
    private plateMeshProvider: PlateMeshProvider,
    private perforationService: PerforationService,
    private view3DProvider: View3dProvider,
    private aabbProvider: PlateAabbProvider
  ) {
    this.shouldRunQueue.subscribe((value) => {
      if (value) {
        if (!this._isQueueRunning.value) {
          this._isQueueRunning.next(true);
          this.runSnapshotQueue().then((v) => {
            this._isQueueRunning.next(false);
          });
        }
      }
    });
  }

  public get isQueueRunning(): Observable<boolean> {
    return this._isQueueRunning.asObservable();
  }

  async runSnapshotQueue() {
    while (this.snapshotQueue.length > 0) {
      const valToRun = this.snapshotQueue[0];
      await this.takeSnapshot(
        valToRun.subject,
        valToRun.state,
        valToRun.position,
        valToRun.mode
      );
      this.snapshotQueue.splice(0, 1);
    }
  }

  addToSnapshotQueue(
    subject: Subject<string>,
    state: MevacoPage,
    position: number
  ): void {
    const data: SnapshotData = {
      subject: subject,
      state: state,
      position: position,
      mode: "element",
    };
    if (!this.isInQueue(data.subject)) {
      this.snapshotQueue.push(data);
      this.shouldRunQueue.next(true);
    }
  }

  addCustomPatternToSnapshotQueue(
    subject: Subject<string>,
    state: MevacoPage
  ): void {
    const data: SnapshotData = {
      subject: subject,
      state: state,
      mode: "customPattern",
    };
    if (!this.isInQueue(data.subject)) {
      this.snapshotQueue.push(data);
      this.shouldRunQueue.next(true);
    }
  }

  removeFromSnapshotQueue(subject: Subject<string>): void {
    const index = this.snapshotQueue.findIndex((x) => x.subject === subject);
    if (index !== -1) {
      this.snapshotQueue.splice(index, 1);
    }
  }

  isInQueue(subject: Subject<string>): boolean {
    return this.snapshotQueue.findIndex((x) => x.subject === subject) !== -1;
  }

  async takeSnapshot(
    subject: Subject<string>,
    state: MevacoPage,
    position: number,
    mode: "customPattern" | "element"
  ) {
    const div: HTMLDivElement = document.createElement("div") as HTMLDivElement;
    const elementRef: ElementRef = new ElementRef(div);
    this.view3DProvider.appendView(elementRef);
    const view3d = this.view3DProvider.mevacoView3D;
    if (mode === "element") {
      state.drawing = getPositionDrawing(state, position);
      state.drawing.plate.plateMaterial = PlateMaterialType.real;
    }
    await this.getPerforation(position, state);
    if (!this.isInQueue(subject)) {
      return;
    }
    if (!this.isInQueue(subject)) {
      return;
    }
    const rendererReady = await view3d.isReady;
    if (!rendererReady) {
      return;
    }
    if (!this.isInQueue(subject)) {
      return;
    }
    const camera = view3d.getCamera() as unknown;
    view3d.setCameraToFitAaBb(
      camera as RotateCamera,
      this.aabbProvider.getPlateAAbb({
        plate: state.drawing.plate,
        step: Step.design,
      })
    );
    const result = await view3d.createScreenShotUsingRenderTarget(state);
    if (!this.isInQueue(subject)) {
      return;
    }
    subject.next(result);
  }

  async getPerforation(id: number, state: MevacoPage): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const peroration =
        this.perforationService.getPerforationForCurrentDrawing(state);
      if (peroration) {
        peroration.subscribe((v) => {
          state.lastPerforationResponse = {
            ...state.lastPerforationResponse,
            perforation: v,
          };
          state.drawing.showPerforationBorder = false;
          state.drawing.fillPerforation = false;
          state.drawing.snapOptions = {
            perforationArea: false,
            nodes: false,
            grid: false,
            measurements: false,
            helpLines: false,
            edges: false,
            perforation: true,
            mountingHoles: true,
            perforationCollider: false,
            import: false,
            mountingHolesOutline: false,
            bendingLines: false,
          };
          resolve();
        });
      } else {
        resolve();
      }
    });
  }
}

function getPositionDrawing(state: MevacoPage, position: number): Drawing {
  const element: Element = state.productConfiguration.elements[position - 1];
  const plateWidth = element.b / 1000;
  const plateHeight = element.a / 1000;
  let plateDepth = 0.001;
  if (
    state.productConfiguration.material &&
    state.productConfiguration.material.thickness
  ) {
    plateDepth = +state.productConfiguration.material.thickness / 1000;
  }
  let nodes = [];
  const topLeftNode = createNode(
    { x: 0, y: plateHeight, z: -plateDepth / 2 },
    ShapeOrigin.SHAPE
  );
  const bottomLeftNode = createNode(
    { x: 0, y: 0, z: -plateDepth / 2 },
    ShapeOrigin.SHAPE
  );
  const topRightNode = createNode(
    { x: plateWidth, y: plateHeight, z: -plateDepth / 2 },
    ShapeOrigin.SHAPE
  );
  const bottomRightNode = createNode(
    { x: plateWidth, y: 0, z: -plateDepth / 2 },
    ShapeOrigin.SHAPE
  );
  if (element.nodes) {
    nodes = element.nodes;
  } else {
    nodes = [bottomLeftNode, bottomRightNode, topRightNode, topLeftNode];
  }
  let paintHex = "";
  const system = state.productConfiguration.surface.colorSystem;
  if (!!system) {
    const colorId = Number(state.productConfiguration.surface.color);
    if (!!colorId && !!state.dataset && !!state.dataset.paints) {
      paintHex = state.dataset.paints.find(
        (paint) => paint.system === system && paint.paintId === colorId
      ).hexColorCode;
    }
  }
  if (!paintHex) {
    paintHex = "";
  }
  return {
    ...state.drawing,
    plate: {
      ...state.drawing.plate,
      position: position,
      height: element.a,
      width: element.b,
      depth: plateDepth,
      currentLineShapeId: -1,
      shapeWithHoles: element.shape,
      hexColorString: paintHex,
      bendingLines: element.breakLines ? element.breakLines : [],
    },
    nodes: nodes,
  };
}
