import { Component, Input, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable, combineLatest } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { Vector2 } from "webcad/math";
import { HtmlLabelModel } from "webcad/models/html-label.model";
import { Message, MessageType } from "../../model/error.model";
import { HintStatus } from "../../model/hint.model";
import { Step } from "../../model/product-configuration/product-configuration.model";
import { TranslationProvider } from "../../providers/translation.provider";
import { SetError } from "../../store/actions/errorable.actions";
import {
  MevacoState,
  getActiveElement,
  getHintMessage,
  getHintStatus,
  getRuleActionsForActivePlate,
  getStep,
} from "../../store/reducers";

@Component({
  selector: "hints",
  templateUrl: "./hints.component.html",
  styleUrls: ["./hints.component.css"],
})
export class HintsComponent implements OnInit {
  public step: Observable<Step>;
  public Step = Step;
  public hintStatusObs: Observable<HintStatus>;
  public hintStatus = HintStatus;
  public hintText: Observable<string>;
  public errorObservable: Observable<Message>;
  public errorMessage: string;
  public errorType: string;
  public errorModels: Observable<HtmlLabelModel[]>;
  @Input() labelModel: HtmlLabelModel;
  public ruleCount: number = 0;

  constructor(
    private store: Store<MevacoState>,
    private translationProvider: TranslationProvider
  ) {
    this.errorObservable = this.store.pipe(
      distinctUntilChanged(),
      select((state) => state.model.message)
    );
    this.errorObservable.subscribe((v) => {
      if (v.message !== undefined) {
        this.errorMessage = v.message;
        this.errorType = v.type === MessageType.ERROR ? "danger" : "success";
      }
    });
    this.errorObservable.pipe(debounceTime(3000)).subscribe((v) => {
      if (v !== null && !!v.message) {
        this.store.dispatch(new SetError(null));
      }
    });
  }

  ngOnInit(): void {
    this.hintStatusObs = this.store.pipe(select(getHintStatus));
    this.step = this.store.pipe(select(getStep));
    this.hintText = this.store.pipe(select(getHintMessage));

    this.errorModels = combineLatest([
      this.store.pipe(select(getRuleActionsForActivePlate)),
      this.store.pipe(select(getActiveElement)),
    ]).pipe(
      map(([rules, element]) => {
        const models: HtmlLabelModel[] = [];
        if (!!rules) {
          for (const r of rules) {
            let position: Vector2 = null;
            switch (r.fieldName) {
              case "element.minRadius.value":
                position =
                  element && element.minRadius && element.minRadius.position;
                break;
              case "element.minMarginDistance.value":
                position =
                  element &&
                  element.minMarginDistance &&
                  element.minMarginDistance.position;
                break;
              case "element.minParallelEdgesDistance.value":
                position =
                  element &&
                  element.minParallelEdgesDistance &&
                  element.minParallelEdgesDistance.position;
                break;
              case "element.minDistanceBetweenMountingAndPerforatedArea.value":
                position =
                  element &&
                  element.minDistanceBetweenMountingAndPerforatedArea &&
                  element.minDistanceBetweenMountingAndPerforatedArea.position;
                break;
              case "element.minDistanceBetweenEdges.value":
                position =
                  element &&
                  element.minDistanceBetweenEdges &&
                  element.minDistanceBetweenEdges.position;
                break;
              case "element.minOutsideAngle.value":
                position =
                  element &&
                  element.minOutsideAngle &&
                  element.minOutsideAngle.position;
                break;
              case "element.minMountingHoleEdgeDistance.value":
                position =
                  element &&
                  element.minMountingHoleEdgeDistance &&
                  element.minMountingHoleEdgeDistance.position;
                break;
              case "element.minMountingHolesDistance.value":
                position =
                  element &&
                  element.minMountingHolesDistance &&
                  element.minMountingHolesDistance.position;
                break;
            }
            if (!!position) {
              models.push({
                value: r.message,
                offset: { x: 0, y: 0 },
                visible: true,
                placement: {
                  position: position,
                  angle: 0,
                },
              });
            }
          }
        }
        return models;
      })
    );
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}
