import {ErrorHandler, Injectable, Injector} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {map, shareReplay, timeout} from "rxjs/operators";
import {MevacoState} from "../store/reducers";
import {Store} from "@ngrx/store";
import {Actions} from "@ngrx/effects";
import {Observable} from "rxjs";

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(private injector:Injector){
  }
  handleError(error:Error) {
    console.error(error);
    this.injector.get(HttpClient).post(
      environment.api_url + '/api/mevaco/frontEndException',
      {
        message:error.message,
        stackTrace: error.stack,
      }).subscribe( response => {
      console.log(response);
    })
  }
}
