import {lengthVector3, multiplyVector3byScalar, subVectors3, Vector3} from "../math";
import {MeasurementModel} from "../models";
import {MeasurementsManager} from "./measurements.manager";

export class MeasurementModelManager {

    constructor(public id: number, private manager: MeasurementsManager) {
    }

    setStart(position: Vector3): void {
        this.manager.setStartForModel(this.id, position);
    }

    setEnd(position: Vector3): void {
        this.manager.setEndForModel(this.id, position);
    }

    setDirection(direction: Vector3): void {
        this.manager.setDirectionForModel(this.id, direction);
    }

    disposeModel(): void {
        this.manager.disposeModel(this.id);
    }

    setMaxValue(value: number): void {
        this.manager.setMaxValueForModel(this.id, value);
    }

    setInputCallbacks(onInputLive: (value: number) => number, onInputConfirmed: (value: number) => number): void {
        const oldModel = this.manager.getModelWithId(this.id);
        const newModel = {
            ...oldModel,
            exchange: {
                ...oldModel.exchange,
                onInputLive: onInputLive,
                onInputConfirmed: onInputConfirmed
            }
        };
        this.manager.setModelWithId(this.id, newModel);
    }

    getModel(): MeasurementModel {
        return this.manager.getModelWithId(this.id);
    }

    setLength(length: number): void {
        const oldModel = this.manager.getModelWithId(this.id);
        const newModel: MeasurementModel = {
            ...oldModel,
            exchange: {
                ...oldModel.exchange,
                value: length
            }
        };
        this.manager.setModelWithId(this.id, newModel);
    }

    updateMeasurement2(start: Vector3, measurementDirection: Vector3, direction: Vector3, value: number): void {
        const oldModel = this.manager.getModelWithId(this.id);
        const newModel: MeasurementModel = {
            ...oldModel,
            start: start,
            measurementDirection: measurementDirection,
            direction: direction,
            visible: true,
            exchange: {
                ...oldModel.exchange,
                value: value
            }
        };
        this.manager.setModelWithId(this.id, newModel);

    }

    updateMeasurement(start: Vector3, end: Vector3, dir: Vector3, maxValue: number = undefined, visible: boolean = undefined): void {
        const oldModel = this.manager.getModelWithId(this.id);
        const v = subVectors3(end, start);
        const l = lengthVector3(v);
        const mDir = l === 0 ? oldModel.measurementDirection : multiplyVector3byScalar(v, 1 / l);
        const newModel: MeasurementModel = {
            ...oldModel,
            start: start,
            measurementDirection: mDir,
            direction: dir,
            exchange: {
                ...oldModel.exchange,
                value: l
            }
        };
        if (maxValue !== undefined) {
            newModel.maxValue = maxValue;
        }
        if (visible !== undefined) {
            newModel.visible = visible;
        }
        this.manager.setModelWithId(this.id, newModel);
    }

    setVisible(visible: boolean): void {
        this.manager.setVisible(this.id, visible);
    }

    setModel(model: MeasurementModel): void {
        this.manager.setModelWithId(this.id, model);
    }

    focus(): void {
        this.manager.focusVisualizer(this.id);
    }

    blur(): void {
        this.manager.focusVisualizer(null);
    }

    getStart(): Vector3 {
        return this.manager.getStartOfModel(this.id);
    }

    getEnd(): Vector3 {
        return this.manager.getEndOfModel(this.id);
    }

    setEditable(editable: boolean): void {
        this.manager.setEditable(this.id, editable);
    }
}
