import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import {Dataset, SingleTool} from '../model/dataset/dataset.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';

import { ConfigurationItem } from '../model/product-configuration/configuration-item.model';
import { ConfigurationMaterial } from '../model/product-configuration/configuration-material.model';
import { CacheTouple } from '../utils/cache-touple';
import { LomoeParams } from '../model/client-server.Params/lomoe-params';
import { EqualByValues } from '../utils/utils';
import { MaterialParams } from '../model/client-server.Params/material-params';
import { MinMaxReanderParams } from '../model/client-server.Params/min-max-reander.params';
import { MinMaxRaender } from '../model/product-configuration/min-max-raender.model';
import { EwzParams } from "../model/client-server.Params/EwzParams";
import { WallpaperAndMaterialParams } from '../model/client-server.Params/wallpaper-and-material-params';
import { LomoeAndMaterialTuple } from '../model/LomoeAndMaterialTuple';
import {MaterialWithSingleToolsParams} from "../model/client-server.Params/MaterialWithSingleToolsParams";

@Injectable()
export class DatasetService {
  private geomatrixCache: CacheTouple<{pattern:string, position:string}, ConfigurationItem> = { key: null, data: null };
  constructor(
    private http: HttpClient
  ) {
  }

  mapDataset(dataset: any): Dataset {
    const result = dataset as Dataset;
    result.perforationIcons = {};
    for (let i = 0; i < dataset.geomatrix.length; i++) {
      const g = dataset.geomatrix[i];
      if (g.bildLoLa) {
        result.perforationIcons[g.lochungsart] = g.bildLoLa;
      } else if (g.bildLoAnfang) {
        result.perforationIcons[g.lochungsart] = g.bildLoAnfang;
      }
      // if(g.BildLoAnfang){
      //   result.positionIcon[g.CodeLochanfang] = g.BildLoAnfang;
      // }
    }
    return result;
  }

  loadDataset(customerId: string): Observable<Dataset> {
    return this.http.get<any>(`${environment.api_url}/api/dataset?customerId=${customerId}`).pipe(
      map(this.mapDataset),
      catchError(error => observableThrowError(error))
    );
  }

  loadTranslations(lang: string): Observable<{ [module: string]: { [identifier: string]: string } }> {
    return this.http.get<any>(environment.api_url + '/api/dataset/translations/' + lang).pipe(
      map(x => x),
      catchError(error => observableThrowError(error))
    );
  }
}
