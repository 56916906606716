import { Store } from "@ngrx/store";
import { Vector3 } from "webcad";
import { PointerState } from "webcad/collision";
import { UnderPointerType } from "../../model/pointer-state.model";
import { MevacoCollider } from "../../providers/colliders/mevaco.collider";
import { NodeCollider } from "../../providers/colliders/node.collider";
import { SegmentCollider } from "../../providers/colliders/segment.collider";
import {
  ClosestSegments,
  MevacoPointerProvider,
} from "../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../providers/scene.provider";
import { TranslationProvider } from "../../providers/translation.provider";
import {
  SetHintMessage,
  TreatCorner,
  TreatSegmentCorners,
} from "../../store/actions/drawing.actions";
import { MevacoState } from "../../store/reducers";
import { DifferentiationTool } from "../differentiation-tool.interface";
import {Scene} from "webcad/babylonjs/core";
export class TreatCornerTool extends DifferentiationTool {
  private scene: Scene;
  private ctrl: boolean;
  private lastObjectUnderPointer: MevacoCollider;
  private PointerState: PointerState;
  private PointerClick: (pointerPosition: Vector3, ctrl?: boolean) => void;
  private PointerDown: (pointerPosition: Vector3, ctrl?: boolean) => void;
  private PointerUp: (pointerPosition: Vector3, ctrl?: boolean) => void;
  private PointerMove: (pointerPosition: Vector3, ctrl?: boolean) => void;
  private _radius: number = 15;
  private _width: number = 40;
  private _height: number = 40;
  private _length: number = 40;
  private _rant: number = 40;
  private _notch: number = 10;
  private _cornerType: string = "round";

  constructor(
    private pointerController: MevacoPointerProvider,
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private translationProvider: TranslationProvider
  ) {
    super();

    this.pointerController.pointerState.subscribe((v) => {
      this.ctrl = v.ctrl;
      let objectUnderPointer: MevacoCollider = this.lastObjectUnderPointer;
      this.PointerState = v;
      if (this.PointerState) {
        objectUnderPointer = this.PointerState.intersection
          ? (this.PointerState.intersection.collider as MevacoCollider)
          : null;
        this.lastObjectUnderPointer = objectUnderPointer;
      }
      if (!objectUnderPointer) {
        this.PointerClick = this.onMouseClickOnGridOrNone;
        this.PointerDown = this.onMouseDownOnGridOrNone;
        this.PointerUp = this.onMouseUpOnGridOrNone;
        this.PointerMove = this.onMouseMoveOnGridOrNone;
      } else {
        switch (objectUnderPointer.objectType) {
          case UnderPointerType.NODE:
            this.PointerClick = this.onMouseClickOnNode;
            this.PointerDown = this.onMouseDownOnNode;
            this.PointerUp = this.onMouseUpOnNode;
            this.PointerMove = this.onMouseMoveOnNode;
            break;
          case UnderPointerType.SEGMENT:
            this.PointerClick = this.onMouseClickOnSegment;
            this.PointerDown = this.onMouseDownOnSegment;
            this.PointerUp = this.onMouseUpOnSegment;
            this.PointerMove = this.onMouseMoveOnSegment;
            break;
          default:
            this.PointerClick = this.onMouseClickOnGridOrNone;
            this.PointerDown = this.onMouseDownOnGridOrNone;
            this.PointerUp = this.onMouseUpOnGridOrNone;
            this.PointerMove = this.onMouseMoveOnGridOrNone;
            break;
        }
      }
    });

    this.sceneProvider.getSubscription().subscribe((scene) => {
      if (scene == null) {
        this.scene = scene;
      }
    });
  }

  public changeType(type: any) {
    this._cornerType = String(type);
  }

  public changeRadius(newRadius: string) {
    const num: number = Number(newRadius);
    if (!isNaN(num) && newRadius !== "") {
      this._radius = num;
    }
  }

  public changeWidth(newWidth: string) {
    const num: number = Number(newWidth);
    if (!isNaN(num) && newWidth !== "") {
      this._width = num;
    }
  }

  public changeHeight(newHeight: string) {
    const num: number = Number(newHeight);
    if (!isNaN(num) && newHeight !== "") {
      this._height = num;
    }
  }

  public changeLength(newLength: string) {
    const num: number = Number(newLength);
    if (!isNaN(num) && newLength !== "") {
      this._length = num;
    }
  }

  public changeRant(newRant: string) {
    const num: number = Number(newRant);
    if (!isNaN(num) && newRant !== "") {
      this._rant = num;
    }
  }

  public changeNotch(newNotch: string) {
    const num: number = Number(newNotch);
    if (!isNaN(num) && newNotch !== "") {
      this._notch = num;
    }
  }

  get radius() {
    return this._radius;
  }

  get width() {
    return this._width;
  }

  get height() {
    return this._height;
  }

  get length() {
    return this._length;
  }

  get rant() {
    return this._rant;
  }

  get notch() {
    return this._notch;
  }

  activate() {
    this.store.dispatch(
      new SetHintMessage(this.translate("treatCornerToolHint"))
    ); //Select node or segment
  }

  onCancel() {
    this.reset();
  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {}

  onConfirm() {}

  ///////////////////////////////////////////////////////////////////// DEFAULT

  onMouseClick(pointerState: PointerState) {
    this.PointerClick(pointerState.position, pointerState.ctrl);
  }

  onMouseUp(pointerState: PointerState) {
    this.PointerUp(pointerState.position, pointerState.ctrl);
  }

  onMouseDown(pointerState: PointerState) {
    this.PointerDown(pointerState.position, pointerState.ctrl);
  }

  onMouseMove(pointerState: PointerState) {
    this.PointerMove(pointerState.position, pointerState.ctrl);
  }

  ///////////////////////////////////////////////////////////////////// GRID OR NONE

  onMouseClickOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {
    this.store.dispatch(
      new SetHintMessage(this.translate("treatCornerToolHint"))
    );
  }

  onMouseUpOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {}

  ///////////////////////////////////////////////////////////////////// NODE

  onMouseClickOnNode(pointerPosition: Vector3, ctrl?: boolean) {
    this.store.dispatch(
      new TreatCorner({
        nodes: [
          (this.PointerState.intersection.collider as NodeCollider).node
            .position,
        ],
        options: {
          type: this._cornerType,
          length: Number.isNaN(this._length) ? null : this._length * 0.001,
          width: Number.isNaN(this._width) ? null : this._width * 0.001,
          height: Number.isNaN(this._height) ? null : this._height * 0.001,
          radius: Number.isNaN(this._radius) ? null : this._radius * 0.001,
          rant: Number.isNaN(this._rant) ? null : this._rant * 0.001,
          notch: Number.isNaN(this._notch) ? null : this._notch * 0.001,
        },
      })
    );
  }

  onMouseDownOnNode(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnNode(pointerPosition: Vector3, ctrl?: boolean) {
    this.store.dispatch(new SetHintMessage(this.translate("roundUpNodeHint")));
  }

  onMouseUpOnNode(pointerPosition: Vector3, ctrl?: boolean) {}

  ///////////////////////////////////////////////////////////////////// SEGMENT

  onMouseClickOnSegment(pointerPosition: Vector3, ctrl?: boolean) {
    const objectUnderPointer = this.PointerState.intersection
      .collider as SegmentCollider;
    this.store.dispatch(
      new TreatSegmentCorners({
        segment: objectUnderPointer.segment,
        options: {
          type: this._cornerType,
          length: Number.isNaN(this._length) ? null : this._length * 0.001,
          width: Number.isNaN(this._width) ? null : this._width * 0.001,
          height: Number.isNaN(this._height) ? null : this._height * 0.001,
          radius: Number.isNaN(this._radius) ? null : this._radius * 0.001,
          rant: Number.isNaN(this._rant) ? null : this._rant * 0.001,
          notch: Number.isNaN(this._notch) ? null : this._notch * 0.001,
        },
      })
    );
  }

  onMouseDownOnSegment(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnSegment(pointerPosition: Vector3, ctrl?: boolean) {
    this.store.dispatch(
      new SetHintMessage(this.translate("roundUpSegmentHint"))
    );
  }

  onMouseUpOnSegment(pointerPosition: Vector3, ctrl?: boolean) {}

  /////////////////////////////////////////////////////////////////////

  onMouseClickOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseClickOnMounting(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnMounting(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnMounting(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnMounting(pointerPosition: Vector3, ctrl?: boolean) {}

  public reset() {}

  isDirty(): boolean {
    return false;
  }

  onMouseClickOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseDownOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseMoveOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {}

  onMouseUpOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {}

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}
