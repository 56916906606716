<div class="position-container">
  <!--<div style="position: fixed; bottom: 755px;">{{model.name}}</div>-->
  <img [hidden]="!(hasErrors | async)" class="position-error-icon" src="assets/Icon_Ausrufezeichen.svg">
  <div class="position-preview">
    <div class="set-position">
      <span>{{model.position}}</span>
    </div>
    <img *ngIf="model.previewImageId" [hidden]="!imageReady" (load)="setImageReady()" [src]="(getImageUrl | async)" class="preview-image">
    <div class="spinner-wrapper" *ngIf="!model.previewImageId || !imageReady">
      <mat-spinner mode="indeterminate" diameter="50"></mat-spinner>
    </div>
    <div class="add-template-position" (click)="makeTemplateOpen()">
    </div>
    <div class="edit-position" (click)="edit()">
    </div>
    <div class="delete-position" (click)="confirmOpen()">
    </div>
  </div>
  <div class="list-item-content-wrapper">
    <div class="content-first-row">
        <div class="quantity-field-wrapper">
            <span class="quantity-field-label">{{translate("Pieces", 'designer')}}:</span>
            <input class="quantity-field-value" type="number" [value]="model.quantity" (change)="onQuantityChange($event.target.value)"/>
        </div>
        <div class="area-field-wrapper">
            <span class="area-field-label">{{translate("Area", 'designer')}}:</span>
            <span class="area-field-value">{{area | number : '1.0-2'}} m2</span>
        </div>
    </div>
    <div class="content-second-row">
        <textarea class="content-textarea" type="number" (change)="onNoteChange($event.target.value)" [placeholder]="translate('Note', 'designer')">{{model.note}}</textarea>
    </div>
  </div>
  


  <div class="card-overlay-container" [hidden]="!this.show">
    
    <span class="card-overlay-label" id="modal-small-title">{{translate("DeleteElementConfirm", 'designer')}}</span>
    <div class="card-overlay-btns">
      <button type="button" class="btn btn-primary card-overlay-btn" (click)="delete()">{{translate("Yes")}}</button>
      <button type="button" class="card-overlay-link mt-15" (click)="confirmOpen()">{{translate("No")}}</button>
    </div>
  </div>


  <div class="card-overlay-container" [hidden]="!this.show2">

    <div class="card-overlay-section">
      <span class="card-overlay-label">{{translate("Do you want to duplicate the lot position?", 'designer')}}</span>
      <div class="card-overlay-btns">
        <button type="button" class="btn btn-outline-primary card-overlay-btn" (click)="copyPosition()">
          {{translate("Duplicate", 'designer')}}
        </button>
      </div>
    </div>

    <div class="card-overlay-section">
      <span class="card-overlay-label">{{translate("Do you want to save the template?", 'designer')}}</span>
      <div class="card-overlay-input-wrapper">
        <input class="card-overlay-input" type="text" [(ngModel)]="projectName" placeholder="Project name">
      </div>
      <div class="card-overlay-btns">
        <button type="button" class="btn btn-primary card-overlay-btn" (click)="makeTemplate()">
          {{translate("Save", 'designer')}}
        </button>
        <button type="button" class="card-overlay-link mt-15" (click)="makeTemplateOpen()">{{translate("No")}}</button>
      </div>
    </div>

  </div>
</div>


