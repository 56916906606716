<div class="sticky-bottom-panel">
  <div class="bottom-panel-left">
      <div *ngIf="(positions | async).length > 0" class="bottom-panel-delete-box" (click)="confirmPopup(closeModal)">
        <div class="bottom-panel-delete-icon-wrapper">
          <mat-icon class="bottom-panel-delete-icon" svgIcon="Icon_Delete"></mat-icon>
        </div>
        <div class="bottom-panel-delete-label">{{translate("Delete All", 'designer')}}</div>
      </div>
  </div>
  <div class="bottom-panel-right">
    <div class="bottom-panel-item" *ngIf="isSingleToolMode | async">
      <div
        tooltipClass="custom-tooltip"
        class="tools-sizes-hint-img-wrapper"
        placement="top"
        container="body"
        [ngbTooltip]="translate('Perforation Holes', 'designer') + ': ' + (totalPerforationHoles|async)"
      >

        <span class="bottom-panel-label">{{translate("Perforation Holes", 'designer')}}:</span>
        <span class="bottom-panel-value">{{totalPerforationHolesShort | async}}</span>
      </div>
    </div>
    <div class="bottom-panel-item">
      <span class="bottom-panel-label">{{translate("Positions", 'designer')}}:</span>
      <span class="bottom-panel-value">{{(positions | async).length}}</span>
    </div>
    <div class="bottom-panel-item">
      <span class="bottom-panel-label">{{translate("Area", 'configurator')}}:</span>
      <span class="bottom-panel-value">{{areaOfAll.toFixed(2)}} m2</span>
    </div>
    <div class="bottom-panel-item">
      <span class="bottom-panel-label">{{translate("Summary", 'designer')}}:</span>
      <span class="bottom-panel-value">{{regex|async}}</span>
    </div>
    <div class="bottom-panel-btn-wrapper">
      <app-subnit-buttons *ngIf="(step | async) !== Step.design"></app-subnit-buttons>
      <button (click)="setStep()" *ngIf="(step | async) === Step.design" class="btn btn-primary btn-buy">{{translate("Buy button")}}</button>
    </div>
  </div>
</div>


<ng-template #closeModal let-modal>
  <div class="dialog-modal-header dialog-modal-header--small">
    <div class="dialog-modal-title-container">
      <div class="dialog-modal-title-content">
        <h4 class="dialog-modal-title dialog-modal-title--small" id="modal-basic-title">
          <span>{{translate("Remove positions modal title", 'designer')}}</span>
        </h4>
      </div>
    </div>

  <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

  <div class="dialog-modal-body dialog-modal-body--small" >
    <p class="dialog-modal-description--small">{{translate("Remove positions modal desc", 'designer')}}</p>
  </div>

  <div class="dialog-modal-footer dialog-modal-footer--small dialog-modal-footer--one-btn" >
    <button type="button" class="btn btn-primary dialog-modal-btn--small mr-15" (click)="modal.close(true)">{{translate("Yes")}}
    </button>
    <button type="button" class="btn btn-outline-primary dialog-modal-btn--small" (click)="modal.close(false)">{{translate("No")}}
    </button>
  </div>

</ng-template>
