import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, throwError as observableThrowError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { DatasetService } from "../../services/dataset.service";
import {
  DATASET_LOAD,
  DatasetLoad,
  GET_TRANSLATIONS,
  GetTranslations,
  GetTranslationsSuccess,
} from "../actions";
import { DatasetLoadedSuccessfully } from "../actions/dataset.actions";

@Injectable()
export class DatasetEffects {
  constructor(
    private actions: Actions,
    private datasetService: DatasetService
  ) {}

  loadDataset: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(DATASET_LOAD),
      switchMap((action: DatasetLoad) => {
        return this.datasetService.loadDataset(action.payload).pipe(
          map((dataset) => new DatasetLoadedSuccessfully(dataset)),
          catchError((error) => observableThrowError(error))
        );
      })
    )
  );

  loadTranslations: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(GET_TRANSLATIONS),
      switchMap((action: GetTranslations) => {
        return this.datasetService.loadTranslations(action.payload).pipe(
          map((translations) => new GetTranslationsSuccess(translations)),
          catchError((error) => observableThrowError(error))
        );
      })
    )
  );

  // @Effect()
  // getMaterial: Observable<Action> = this.actions.pipe(
  //   ofType(DATASET_MATERIA_LOAD),
  //   debounceTime(100),
  //   switchMap((action: DatasetMaterialLoad) => {
  //       return this.datasetService.getMaterial(action.payload).pipe(
  //         map(dataset => new DatasetMaterialLoadedSuccessfully(dataset)),
  //         catchError(error => observableThrowError(error))
  //       );
  //     }
  //   )
  // );
  //
  // @Effect()
  // getLomoe: Observable<Action> = this.actions.pipe(
  //   ofType(DATASET_LOMOE_LOAD),
  //   debounceTime(100),
  //   switchMap((action: DatasetMaterialLoad) => {
  //       return this.datasetService.getLomoe(action.payload).
  //       pipe(
  //         map(dataset => new DatasetLomoeLoadedSuccessfully(dataset)),
  //         catchError(error => observableThrowError(error))
  //       );
  //     }
  //   )
  // );
}
