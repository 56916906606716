<ng-template #tipContent>
  <span>{{translate("Set margin perforation", 'designer')}}</span>
</ng-template>
<ng-template #tipContent2>
  <span>{{translate("perforation placement", 'designer')}}</span>
</ng-template>
<ng-template #tipWallpaperX>
  <span>{{translate("wallpaper x", 'designer')}}</span>
</ng-template>
<ng-template #tipWallpaperY>
  <span>{{translate("wallpaper y", 'designer')}}</span>
</ng-template>
<ng-template #tipRotate>
  <span>{{translate("perforation angle", 'designer')}}</span>
</ng-template>


  <div [hidden]="!($anyOwnedActive | async)">
    <ng-container *ngIf="noBendings|async">

      <div class="tools-caption">{{translate("Perforation size")}}</div>

      <div class="tools-input-wrapper">

        <div class="tool-hint">
          <span class="tool-hint-text">{{translate("Margin:")}}</span>
          <div
          tooltipClass="custom-tooltip"
          class="hint-img-wrapper"
          container="body"
          placement="right"
          container="body"
          [ngbTooltip]="translate('Margin tooltip')"
          >
          <img src="assets/hint-icon.svg" alt="hint">
          </div>
        </div>

        <div class="perforation-input-wrapper">
          <input class="perforation-input" type="text" id="margin" (input)="roundInput($event.target.value,0, 1000);" [(ngModel)]="margin">
          <button class="btn-perforation" (click)="createPerforationAreas()">
            <img class="btn-perforation-arrow" src="assets/down-arrow.svg" alt="arrow">
          </button>
        </div>

      </div>

      <div *ngIf="margin == null" class="alert alert-danger">
        {{translate("Value is required")}}
      </div>
    </ng-container>


    <div class="perforation-section">
      <div class="tool-hint">
        <span class="tool-hint-text">{{translate("Perforation Placement")}}</span>
        <div
        tooltipClass="custom-tooltip"
        class="hint-img-wrapper"
        container="body"
        placement="right"
        container="body"
        [ngbTooltip]="tipContent2"
        >
        <img src="assets/hint-icon.svg" alt="hint">
        </div>
      </div>

      <ng-container *ngIf="(productPerforationType | async) === 'classic'">
        <ng-container *ngIf="environment.enable_custom_patterns_tools">
          <div class="auto-fit-section" *ngIf="(useCustom | async)">
              <button type="button" class="btn btn-black btn-auto-fit mr-10" (click)="autoFit('X')">{{translate('horizontal fit')}}</button>
              <button type="button" class="btn btn-black btn-auto-fit ml-10" (click)="autoFit('Y')">{{translate('vertical fit')}}</button>
          </div>
          <div class="auto-fit-section" *ngIf="(useCustom | async)">
            <button type="button" class="btn btn-black btn-auto-fit-100" (click)="smartMargin()">{{translate('smart margin')}}</button>
          </div>
        </ng-container>
        <div class="list">
          <mat-list role="list">
            <mat-radio-group>
              <mat-radio-button color="primary" [checked]="(autoCenter | async) === true" [value]="true"
                                (change)="updateAutoCenter($event)">
                {{translate("Automatic")}}
              </mat-radio-button>
              <mat-radio-button color="primary" [checked]="(autoCenter | async) === false" [value]="false"
                                (change)="updateAutoCenter($event)">
                {{translate("Manual")}}
              </mat-radio-button>
            </mat-radio-group>
          </mat-list>
        </div>

        <div *ngIf="!(autoCenter | async) && numberOfPerforationAreas === 1">
          <app-perforation-offset>
          </app-perforation-offset>

          <div class="perforation-section">

            <div class="perforation-input-wrapper" *ngIf="!(dependent | async)">
              <div class="tool-hint">
                  <span class="tool-hint-text">{{translate("Rotation")}}</span>
                  <div
                  tooltipClass="custom-tooltip"
                  class="hint-img-wrapper"
                  placement="right"
                  container="body"
                  [ngbTooltip]="tipRotate"
                  >
                  <img src="assets/hint-icon.svg" alt="hint">
                  </div>
              </div>
              <div class="perforation-input-wrapper">
                <input class="perforation-input" type="number" id="rotation1" [value]="(currentAreaRotation | async)"
                      [placeholder]="translate('select area')" #rotation1 [min]="-360" [max]="360" (input)="validate($event)">
                <button class="btn-perforation" (click)="setPerforationAreaRotation(rotation1.value)"
                        [disabled]="rotation1.value > 360">
                  <img class="btn-perforation-arrow" src="assets/down-arrow.svg" alt="arrow">
                </button>
              </div>
            </div>
          </div>

        </div>




        <div *ngIf="!(autoCenter | async) && numberOfPerforationAreas > 1">
          <app-perforation-offset>
          </app-perforation-offset>

          <div  *ngIf="!(dependent | async)"  class="perforation-input-wrapper mt-10">

            <div class="tool-hint">
              <span class="tool-hint-text">{{translate("Rotation")}}</span>
              <div
              tooltipClass="custom-tooltip"
              class="hint-img-wrapper"
              container="body"
              placement="right"
              container="body"
              [ngbTooltip]="tipContent"
              >
              <img src="assets/hint-icon.svg" alt="hint">
              </div>
            </div>

            <input class="perforation-input" type="number" id="rotation2" [value]="(currentAreaRotation | async)"
                  [placeholder]="translate('select area')" [min]="-360" [max]="360" #rotation2>
            <button class="btn-perforation" (click)="setPerforationAreaRotation(rotation2.value)">
              <img class="btn-perforation-arrow" src="assets/down-arrow.svg" alt="arrow">
            </button>
          </div>

        </div>

        <div class="tools mt-20"
            [hidden]="perforationNamedTool.hidden ? (perforationNamedTool.hidden | async) : false"
            tooltipClass="custom-tooltip custom-tooltip--small"
            [ngbTooltip]="perforationNamedTool.toolTip ? perforationNamedTool.toolTip : translate(perforationNamedTool.name)"
            [placement]="['top-left', 'right']">

          <button class="tool-btn" (click)="setTool(perforationTool)"
                  [ngClass]="{'active': (activeTool | async) === perforationTool }">
            <mat-icon svgIcon="{{perforationNamedTool.name}}"></mat-icon>
          </button>

        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="(productPerforationType | async) === 'wallpaper'">
      <div class="perforation-input-wrapper mt-10">

        <div class="tool-hint">
          <span class="tool-hint-text">{{translate("X:")}}</span>
          <div
          tooltipClass="custom-tooltip"
          class="hint-img-wrapper"
          container="body"
          placement="right"
          container="body"
          [ngbTooltip]="tipWallpaperX"
          >
          <img src="assets/hint-icon.svg" alt="hint">
          </div>
        </div>



        <div class="perforation-debounce-input">
          <debounce-input
                          [inputType]="'number'"
                          [value]="wallpaperX | async"
                          (valueChanged)="setActiveAreaWallpaperX($event)"></debounce-input>
        </div>
      </div>
      <div class="perforation-input-wrapper mt-10">

        <div class="tool-hint">
          <span class="tool-hint-text">{{translate("Y:")}}</span>
          <div
          tooltipClass="custom-tooltip"
          class="hint-img-wrapper"
          container="body"
          placement="right"
          container="body"
          [ngbTooltip]="tipWallpaperY"
          >
          <img src="assets/hint-icon.svg" alt="hint">
          </div>
        </div>


        <div class="perforation-debounce-input">
          <debounce-input
                          [inputType]="'number'"
                          [value]="wallpaperY | async"
                          (valueChanged)="setActiveAreaWallpaperY($event)"></debounce-input>
        </div>
      </div>


    </ng-container>

    <div class="tools cassette-section" [hidden]="cassettePerforationNamedTool.hidden ? (cassettePerforationNamedTool.hidden | async) : false"
         [ngbTooltip]="cassettePerforationNamedTool.toolTip ? cassettePerforationNamedTool.toolTip : translate(cassettePerforationNamedTool.name)"
         tooltipClass="custom-tooltip custom-tooltip--small"
         [placement]="['top-left', 'right']">
        <button class="tool-btn" (click)="setTool(cassettePerforationNamedTool.tool)"
                [ngClass]="{'tool-btn--active': (activeTool | async) === cassettePerforationNamedTool.tool }">
          <mat-icon class="tool-btn--svg-filter" svgIcon="{{cassettePerforationNamedTool.name}}"></mat-icon>
          <!-- <div>{{tool.name}}</div> -->
        </button>
      <input class="perforation-input cassette-input" type="number" [value]="cassettePerforationTool.getMargin() * 1000" (input)="roundInput($event, 0 , 1000, 1); cassettePerforationTool.setMargin(+$event.target.value/1000)">
    </div>

  </div>



