import {MeasurementModelManager} from 'webcad/measurements';
import {
  Vector3,
  lengthVector2,
  vector2toVector3,
  subVectors2,
  multiplyVector2byScalar,
  getRightPerpendicularVector2,
  addVectors3,
  multiplyVector3byScalar
} from 'webcad/math';
import {ClosestSegments} from '../providers/mevaco-pointer.provider';
import {getMeasurementEndPoint} from 'webcad/models';

export function updateXYMeasurements(xMeasurement: MeasurementModelManager, yMeasurement: MeasurementModelManager, start: Vector3, closestSegments: ClosestSegments): void {
  if (!start) {
    if (!!xMeasurement) {
      xMeasurement.setVisible(false);
    }
    if (!!yMeasurement) {
      yMeasurement.setVisible(false);
    }
  }
  const closest = getClosestXPointAndYPointOnSegments(start, closestSegments);
  if (!closestSegments || !closest) {
    if (!!xMeasurement) {
      xMeasurement.setVisible(false);
    }
    if (!!yMeasurement) {
      yMeasurement.setVisible(false);
    }
    return;
  }
  if (!!xMeasurement) {
    if (closest.closestX) {
      const xModel = xMeasurement.getModel();
      let xMesDir: Vector3 = xModel.measurementDirection;
      let xDir: Vector3 = xModel.direction;
      const toClosestX = subVectors2(closest.closestX, start);
      const valueX = lengthVector2(toClosestX);
      if (valueX !== 0) {
        const n = multiplyVector2byScalar(toClosestX, 1 / valueX);
        xMesDir = vector2toVector3(n);
        xDir = vector2toVector3(getRightPerpendicularVector2(n));
        xMeasurement.updateMeasurement2(
          start,
          xMesDir,
          xDir,
          valueX);
      } else {
        xMeasurement.setVisible(false);
      }
    } else {
      xMeasurement.setVisible(false);
    }
  }
  if (!!yMeasurement) {
    if (closest.closestY) {
      const yModel = yMeasurement.getModel();
      let yMesDir: Vector3 = yModel.measurementDirection;
      let yDir: Vector3 = yModel.direction;
      const toClosestY = subVectors2(closest.closestY, start);
      const valueY = lengthVector2(toClosestY);
      if (valueY !== 0) {
        const n = multiplyVector2byScalar(toClosestY, 1 / valueY);
        yMesDir = vector2toVector3(n);
        yDir = vector2toVector3(getRightPerpendicularVector2(n));
        yMeasurement.updateMeasurement2(
          start,
          yMesDir,
          yDir,
          valueY);
      } else {
        yMeasurement.setVisible(false);
      }
    } else {
      yMeasurement.setVisible(false);
    }
  }
}

export function getNewStartPosition(measurement: MeasurementModelManager, newValue: number): Vector3 {
  const model = measurement.getModel();
  const end = getMeasurementEndPoint(model);
  return addVectors3(end, multiplyVector3byScalar(model.measurementDirection, -newValue));

}

export function getNewEndPosition(measurement: MeasurementModelManager, newValue: number): Vector3 {
  const model = measurement.getModel();
  const start = model.start;
  return addVectors3(start, multiplyVector3byScalar(model.measurementDirection, newValue));
}

function getClosestXPointAndYPointOnSegments(point: Vector3, closestSegments: ClosestSegments) {
  return closestSegments && point ? {
    closestX: closestSegments.verticalSegment ? {
      x: closestSegments.verticalSegment.projectedPoint.x,
      y: closestSegments.verticalSegment.projectedPoint.y,
      z: point.z
    } : null,
    closestY: closestSegments.horizontalSegment ? {
      x: closestSegments.horizontalSegment.projectedPoint.x,
      y: closestSegments.horizontalSegment.projectedPoint.y,
      z: point.z
    } : null
  } : null;
}
