<ng-template #tipContent>
  <span>{{ translate("Help") }}</span>
</ng-template>

<div *ngIf="(utilTools | async).length > 0" class="tools-container">
  <div class="tools-block">
    <div class="tools-block-lines">
      <div class="tools-block-line"></div>
      <div class="tools-block-line"></div>
    </div>
  </div>

  <div class="tools-content">
    <h3 class="tools-caption">{{ translate("Tools") }}</h3>

    <div>
      <div class="tools">
        <div
          *ngFor="let tool of utilTools | async"
          [hidden]="tool.hidden ? (tool.hidden | async) : false"
          [ngbTooltip]="tool.toolTip ? tool.toolTip : translate(tool.name)"
          tooltipClass="custom-tooltip--small"
          [placement]="['top-left', 'right']"
        >
          <button
            class="tool-btn"
            (click)="setTool(tool)"
            [ngClass]="{ 'tool-btn--active': activeToolObject === tool.tool }"
          >
            <mat-icon svgIcon="{{ tool.name }}"></mat-icon>
          </button>
        </div>
      </div>

      <div *ngFor="let tool of utilTools | async">
        <div *ngIf="tool.controls">
          <ng-container *ngFor="let control of tool.controls">
            <button
              class="customBtn"
              *ngIf="control.type === 'actionButton'"
              (click)="control.action()"
              [disabled]="!(control.enable | async)"
              [ngClass]="{ active: (control.active | async) }"
            >
              {{ translate(control.name, "designer") }}
            </button>
            <input
              *ngIf="control.type === 'input'"
              class="customInput"
              type="number"
              (input)="
                roundInput($event, control.min, control.max, control.decimals);
                control.setVale($event.target.value)
              "
            />
          </ng-container>
        </div>
      </div>
    </div>

    <select-move></select-move>

    <treat-corner
      [hidden]="
        (stepSub | async) !== step.shape && (stepSub | async) !== step.pattern
      "
      (click)="setTreatCornerTool()"
    ></treat-corner>

    <help-line></help-line>

    <div
      [hidden]="
        (stepSub | async) !== step.attachment &&
        (stepSub | async) !== step.import_attachment
      "
      class="attachmentConfig"
    >
      <attachment-config></attachment-config>
    </div>

    <app-perforation-adjustment></app-perforation-adjustment>
  </div>
</div>
