import {Injectable} from '@angular/core';
import {WebcadProvider} from './webcad.provider';
import {SceneProvider} from './scene.provider';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {DragArrowsManager} from 'webcad/controllers/dragArrow/drag-arrows.manager';

@Injectable()
export class DragArrowsManagerProvider {
  private _dragArrowsManager: BehaviorSubject<DragArrowsManager>;
  private webCadSubscription: Subscription;
  private sceneSubscription: Subscription;
  private _isReady: boolean;



  constructor(private webcadProvider: WebcadProvider, private sceneProvider: SceneProvider) {
    this._isReady = false;
    this._dragArrowsManager = new BehaviorSubject(null);
    this.webCadSubscription = this.webcadProvider.getObservable().subscribe((value) => {
      if (value !== null && this.sceneProvider.getScene() !== null) {
        const manager = new DragArrowsManager(this.sceneProvider.getScene(), value);
        manager.init().then(() => {
            this._isReady = true;
            this._dragArrowsManager.next(manager);
          }
        );
      }
    });
    this.sceneSubscription = this.sceneProvider.getSubscription().subscribe((value) => {
      if (value !== null && this.webcadProvider.getWebcad() !== null) {
        const manager = new DragArrowsManager(value, this.webcadProvider.getWebcad());
        manager.init().then(() => {
            this._isReady = true;
            this._dragArrowsManager.next(manager);
          }
        );
      }
    });
  }

  public get dragArrowsManagerObservable(): Observable<DragArrowsManager> {
    return this._dragArrowsManager;
  }

  getdragArrowsManager(): DragArrowsManager {
    return this._dragArrowsManager.getValue();
  }

  public subscribe(callback: (dragArrowsManager: DragArrowsManager) => void): Subscription {
    return this._dragArrowsManager.subscribe((v) => callback(v));
  }

  dispose() {
    this._dragArrowsManager.getValue().dispose();
    this._isReady = false;
  }

  isReady() {
    return this._isReady;
  }

}
