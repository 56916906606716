import {Component, OnInit} from '@angular/core';
import {NamedTool, ToolControlType, ToolProvider} from '../../providers/tool.provider';
import {TranslationProvider} from '../../providers/translation.provider';
import {select, Store} from '@ngrx/store';
import {
  getAutoCenter, getIsCustomPerforation,
  getPerforationAreaModels, getProductPerforationType,
  getSelectedOrFirstPerforationAreaOffset,
  getSelectedPerforationAreaRotation,
  MevacoState
} from '../../store/reducers';
import {SetPerforationAutoCenter, SetSelectedPerforationAreaOffset} from '../../store/actions';
import {CreatePerforationAreasFomShape, SetCurrentPerforationAreaRotation} from '../../store/actions/drawing.actions';
import {map, withLatestFrom} from 'rxjs/operators';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {PerforationAdjustmentTool} from '../../tools/drawingTools/perforation-adjustment.tool';
import {Tool} from '../../tools/tool.interface';
import {PerforationModeTool} from '../../tools/perforation-mode.tool';
import {roundDecimal} from '../../utils/utils';
import {CassettePerforationCreatorTool} from '../../tools/drawingTools/cassette-perforation-creator.tool';
import {MatRadioChange} from "@angular/material/radio";
import {AutoFitCustomPattern, SetSmartMargin} from "../../store/actions/custom-geom.actions";
import { environment } from 'environments/environment';

@Component({
  selector: 'app-perforation-adjustment',
  templateUrl: './perforation-adjustment.component.html',
  styleUrls: ['./perforation-adjustment.component.css']
})
export class PerforationAdjustmentComponent implements OnInit {
  private _margin = 10;
  public environment = environment;

  public set margin( v: string ) {
    const dotString = v.replace(',', '.');
    const originalNumber = isNaN(Number(dotString)) ? NaN : parseFloat( dotString );
    let numberValue = originalNumber;
    if (numberValue < 0) {
      numberValue = 0;
    }
    if ( numberValue > 1000) {
      numberValue = 1000;
    }
    this._margin = roundDecimal(numberValue);
  }
  public get margin(): string {
    return this._margin ? this._margin.toString() : '';
  }

  public numberOfPerforationAreas: number;
  public dependent: Observable<boolean>;
  public autoCenter: Observable<boolean>;
  public currentAreaRotation: Observable<string | number>;
  public perforationTool: PerforationAdjustmentTool;
  private perforationModeTool: PerforationModeTool;
  public activeTool: Observable<Tool>;
  public $anyOwnedActive: Observable<boolean>;
  public perforationNamedTool: NamedTool;
  public wallpaperMoveNamedTool: NamedTool;
  public cassettePerforationNamedTool: NamedTool;
  public cassettePerforationTool: CassettePerforationCreatorTool;
  public productPerforationType = this.store.pipe( select(getProductPerforationType));
  public useCustom = this.store.pipe( select(getIsCustomPerforation));
  public wallpaperX:Observable<number>;
  public wallpaperY:Observable<number>;
  public toggleWallpaperMoveTool:Subject<Event> = new Subject<Event>();
  private subscriptions: Subscription[] = [];

  public noBendings: Observable<boolean>;

  constructor(private toolProvider: ToolProvider, private translationProvider: TranslationProvider, private store: Store<MevacoState>) {
    this.perforationTool = this.toolProvider.perforationAdjustmentTool;
    this.perforationModeTool = this.toolProvider.perforationModeTool;
    this.cassettePerforationTool = this.toolProvider.cassettePerforationTool;
    this.noBendings = this.store.pipe(select(state => state.model.drawing.plate.bendingLines.length === 0));

    this.activeTool = this.toolProvider.getToolObservable();
    this.$anyOwnedActive = this.activeTool.pipe(
      map(v => v === this.perforationModeTool || v === this.perforationTool || v === this.cassettePerforationTool || v === this.toolProvider.wallpapperMoveTool)
    );

    this.perforationNamedTool = {
      tool: this.perforationTool,
      hidden: of(false),
      controls: [],
      name: 'Adjustment',
      toolTip: 'PerforationAdjustmentToolToolTip'
    };

    this.wallpaperMoveNamedTool = {
      tool: this.toolProvider.wallpapperMoveTool,
      hidden: of(false),
      controls: [],
      name: 'PerforationMove',
      toolTip: 'PerforationMoveToolToolTip'
    }
    this.cassettePerforationNamedTool = {
      tool: this.cassettePerforationTool,
      hidden: this.noBendings,
      controls: [{
        type:ToolControlType.input,
        setVale: this.cassettePerforationTool.setMargin,
        min: 0,
        max: 1000,
        decimals: 1
      }],
      name: 'CassettePerforationTool',
      toolTip: 'CassettePerforationTool'
    };

    this.wallpaperX = this.store.pipe(select(getSelectedOrFirstPerforationAreaOffset), map(offset=>offset ? offset.x : 0));
    this.wallpaperY = this.store.pipe(select(getSelectedOrFirstPerforationAreaOffset), map(offset=>offset ? offset.y : 0));

  }

  ngOnInit() {
    this.store.pipe(select(getPerforationAreaModels)).subscribe((v) => {
      this.numberOfPerforationAreas = v.length;
    });

    // this.dependent = this.store.pipe(
    //   select(state => state.model.productConfiguration.perforation.dependentMode)
    // );
    this.autoCenter = this.store.pipe(
      select(getAutoCenter)
    );

    this.currentAreaRotation = this.store.pipe(
      select(getSelectedPerforationAreaRotation),
      map(x => x === undefined ? '' : x)
    );

    this.subscriptions.push(
      this.toggleWallpaperMoveTool.pipe(
        withLatestFrom( this.activeTool)
      ).subscribe( ([event,activeTool]) =>{
        if(activeTool === this.wallpaperMoveNamedTool.tool) {
          this.setTool( this.toolProvider.perforationModeTool )
        } else {
          this.setTool( this.wallpaperMoveNamedTool.tool )
        }
      })
    );
  }

  ngOnDestroy(){
    this.subscriptions.forEach( s => s.unsubscribe());
    this.subscriptions = [];
  }
  // updatePerforation(change: MatButtonToggleChange) {
  //   this.store.dispatch(new SetDependentMode(change.value));
  // }

  updateAutoCenter(change: MatRadioChange) {
    this.store.dispatch(new SetPerforationAutoCenter(change.value));
  }

  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }

  createPerforationAreas() {
    this.store.dispatch(new CreatePerforationAreasFomShape(this._margin * 0.001));
  }

  /*
  public setMarginInCassttePerforationTool(margin: number) {
    this.cassettePerforationTool.setMargin(this.margin * 0.001);
  }
  */
  setPerforationAreaRotation(r: string) {
    const v = +r;
    if (!isNaN(v)) {
      this.store.dispatch(new SetCurrentPerforationAreaRotation(v));
    }
  }

  setTool(tool: Tool) {
    this.toolProvider.setTool(tool);
  }

  validate(event) {
    if (Number(event.target.value) > 360) {
      event.target.value = 360;
    } else if (Number(event.target.value) < 0) {
      event.target.value = 0;
    }
  }

  roundInput(event, minValue?: number, maxValue?: number, decimals = 1): number {
    const value: string = event.target.value;
    const dotString = value.replace(',', '.');
    const originalNumber = isNaN(Number(dotString)) ? NaN : parseFloat( dotString );
    let numberValue = originalNumber;
    if (!!minValue && numberValue < minValue) {
      numberValue = minValue;
    }
    if (!!maxValue && maxValue !== 0) {
      if ( numberValue > maxValue) {
        numberValue = maxValue;
      }
    }
    const r = roundDecimal(numberValue, decimals);
    if (r !== originalNumber) {
      event.target.value = r.toString();
    }
    return r;
  }

  setActiveAreaWallpaperX(value: string) {
    if(!isNaN( +value )){
      this.store.dispatch( new SetSelectedPerforationAreaOffset({x:+value}))
    }
  }
  setActiveAreaWallpaperY(value: string) {
    if(!isNaN( +value )){
      this.store.dispatch( new SetSelectedPerforationAreaOffset({y:+value}))
    }
  }

  autoFit(direction: 'X'|'Y') {
    this.store.dispatch( new AutoFitCustomPattern(direction));
  }

  smartMargin() {
    this.store.dispatch( new SetSmartMargin());
  }
}
