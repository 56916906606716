import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { debounceTime } from "rxjs/operators";

export class SliderControl extends FormControl {
  private subV = this.value$.subscribe((v) =>
    this.setValue(v, { emitEvent: false })
  );
  private subC = this.valueChanges
    .pipe(debounceTime(1000))
    .subscribe(this.onChange);

  constructor(
    private value$: Observable<number>,
    private onChange: (value: number) => void
  ) {
    super();
  }

  public omDestroy() {
    this.subV.unsubscribe();
    this.subC.unsubscribe();
  }
}
