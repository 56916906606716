import {Vector2} from 'webcad';

export class Curve {
  constructor(private origin: Vector2,
              private radius: number,
              private aStartAngle: number,
              private aEndAngle: number,
              private aClockwise: boolean) {}


              private getPoint(t: number): Vector2 {
                const twoPi = Math.PI * 2;
                let deltaAngle = this.aEndAngle - this.aStartAngle;
                const samePoints = Math.abs( deltaAngle ) < Number.EPSILON;
                while ( deltaAngle < 0 ) { deltaAngle += twoPi; }
                while ( deltaAngle > twoPi ) { deltaAngle -= twoPi; }

                if ( deltaAngle < Number.EPSILON ) {

                  if ( samePoints ) {

                    deltaAngle = 0;

                  } else {

                    deltaAngle = twoPi;

                  }

                }

                if ( this.aClockwise === true && ! samePoints ) {

                  if ( deltaAngle === twoPi ) {

                    deltaAngle = - twoPi;

                  } else {

                    deltaAngle = deltaAngle - twoPi;

                  }

                }
                const angle = this.aStartAngle + t * deltaAngle;
                const x = this.origin.x + this.radius * Math.cos( angle );
                const y = this.origin.y + this.radius * Math.sin( angle );
                return { x: x, y: y};
              }

              public getPoints(numberOfPoints: number = 50, plusOne: boolean = false): Vector2[] {
                const points: Vector2[] = [];
                const pointsToGet = plusOne ? numberOfPoints + 1 : numberOfPoints;
                for (let i = 0; i < pointsToGet; i++) {
                    points.push(this.getPoint(i / numberOfPoints));
                }
                return points;
              }
}
