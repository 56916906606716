
<div class="perfaction-editor__background">

  <div class="perfaction-editor">

    <span class="perfaction-editor__close-btn" (click)="closePerfaction()">
      <img  class="perfaction-editor__arrow" src="assets/down-arrow.svg" alt="arrow">
    </span>

    <!-- <div class="perfaction-editor__config-panel" *ngIf="isConfigOpened; else openConfigBtn">
      <app-perfaction-config (close)="onCloseConfig()"></app-perfaction-config>
    </div> -->

    <ng-template #openConfigBtn>
      <span class="perfaction-editor__open-config-btn" (click)="openConfig()">
        <img  class="perfaction-editor__open-config-arrow" src="assets/gear.svg" alt="gear">
      </span>
    </ng-template>

    <div class="perfaction-editor__image-panel">

      <div class="perfaction-editor__header">

        <div class="perfaction-editor__subheader subheader">
          <h1 class="subheader__title">  {{ translate("perfaction", "designer") }}</h1>
        </div>

        <div class="perfaction-editor__subheader">
          <button type="button" class="btn btn-hint">
            <img class="btn-hint-icon" src="assets/hint-icon.svg">
            <span>{{translate('perfaction tutorial' , 'designer')}}</span>
          </button>
<!--          <button type="button" -->
<!--          class="btn btn-perfaction btn-reset" -->
<!--          tooltipClass="custom-tooltip custom-tooltip&#45;&#45;small" -->
<!--          placement="left" [ngbTooltip]="translate('perfaction reset', 'designer')" (click)="resetPerfaction()">-->
<!--          </button>-->
        </div>

      </div>

      <div class="perfaction-editor__image">
        <app-perfaction-image-panel></app-perfaction-image-panel>
      </div>

    </div>
  </div>
</div>
