import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Segment} from 'webcad/models';
import {MevacoResponse} from '../model/client-server.Params/mevaco-response.model';
import {MountingsAndShapes} from '../model/client-server.Params/get-mountings.model';

@Injectable()
export class MountingsService {
  constructor(private http: HttpClient) {
  }

  getMountingTypes(): Observable<string[]> {
    return this.http.get<string[]>(environment.api_url + '/api/mountings/GetMountingTypes');
  }

  getMountings(): Observable<MevacoResponse<MountingsAndShapes>> {
    return this.http.get<MevacoResponse<MountingsAndShapes>>(environment.api_url + '/api/mounting/GetMountings');
  }

  getDxfByName(name: string): Observable<MevacoResponse<Segment[][]>> {
    return this.http.get<MevacoResponse<Segment[][]>>(environment.api_url + 'api/mevaco/getDxfAsPolylineByName/' + name);
  }

}
