import {NodeActions} from './node.actions';
import {LineActions} from './line.actions';
import {PlateActions} from './plate.actions';
import {GridActions} from './grid.actions';
import {PerforationActions} from './perforation.actions';
import {Action} from '@ngrx/store';
import {Drawing, SegmentAndOrigin, ShapeOrigin} from '../../model/drawing.model';
import {ShapeWithHoles} from '../../model/shape-with-holes';
import {HelpLine} from '../../model/help-line.model';
import {AngleMeasurementModel, MeasurementModel, Segment} from 'webcad/models';
import {SnapOptionsActions} from './snap-options.actions';
import {PointNode} from '../../model';
import {Vector2} from 'webcad/math';
import {HintStatus} from '../../model/hint.model';

export const SELECT_SHAPE = 'SELECT SHAPE';
export const UPDATE_PLATE_SHAPE = 'UPDATE PLATE SHAPE';
export const UPDATE_PLATE_SHAPE_SUCCESS = 'UPDATE PLATE SHAPE SUCCESS';
export const UPDATE_PLATE_SHAPE_FAIL = 'UPDATE PLATE SHAPE FAIL';
export const SET_NODES_SELECTION = 'SET SHAPE NODES SELECTION';
export const ROUND_UP_SELECTED_NODES = 'ROUND UP SELECTED NODES';
export const ADD_HELP_LINE = 'ADD HELP LINE';
export const REMOVE_HELP_LINE = 'REMOVE HELP LINE';
export const ADD_TOOL_HELP_LINE = 'ADD TOOL HELP LINE';
export const REMOVE_TOOL_HELP_LINE = 'REMOVE TOOL HELP LINE';
export const REMOVE_ALL_TOOL_HELP_LINES = 'REMOVE ALL TOOL HELP LINES';
export const SET_HINT_MESSAGE = 'SET_HINT_MESSAGE';

export class SelectShape implements Action {
  readonly type: string = SELECT_SHAPE;

  constructor(public payload: number) {
  }
}

export class UpdatePlateShape implements Action {
  readonly type: string = UPDATE_PLATE_SHAPE;

  constructor(public payload: Drawing) {
  }
}

export class UpdatePlateShapeSucess implements Action {
  readonly type: string = UPDATE_PLATE_SHAPE_SUCCESS;

  constructor(public payload: ShapeWithHoles) {
  }
}

export class UpdatePlateShapeFail implements Action {
  readonly type: string = UPDATE_PLATE_SHAPE_FAIL;

  constructor(public payload: { drawing: Drawing, attribute: string, error: string }) {
  }
}

export class SetNodesSelection implements Action {
  readonly type: string = SET_NODES_SELECTION;

  constructor(public payload: PointNode[]) {

  }
}

export const SET_NODES_SELECTION_FROM_SEGMENTS = 'SET_NODES_SELECTION_FROM_SEGMENTS';

export class SetNodesSelectionFromSegments implements Action {
  readonly type: string = SET_NODES_SELECTION_FROM_SEGMENTS;

  constructor(public payload: SegmentAndOrigin[]) {

  }
}

export const TOGGLE_3D_VIEW = 'TOGGLE_3D_VIEW';

export class Toggle3DView implements Action {
  readonly type: string = TOGGLE_3D_VIEW;

  constructor(public payload: boolean) {

  }
}

export const ADD_NODE_TO_SELECTION = 'ADD NODE TO SELECTION';

export class AddNodeToSelection implements Action {
  readonly type: string = ADD_NODE_TO_SELECTION;

  constructor(public payload: PointNode) {

  }
}

export const REMOVE_NODE_FROM_SELECTION = 'REMOVE SHAPE NODE FROM SELECTION';

export class RemoveNodeFromSelection implements Action {
  readonly type: string = REMOVE_NODE_FROM_SELECTION;

  constructor(public payload: PointNode) {

  }
}

export const ADD_SHAPE_SEGMENT_TO_SELECTION = 'ADD SHAPE SEGMENT TO SELECTION';

export class AddShapeSegmentToSelection implements Action {
  readonly type: string = ADD_SHAPE_SEGMENT_TO_SELECTION;

  constructor(public payload: Segment) {

  }
}

export const REMOVE_SHAPE_SEGMENT_FROM_SELECTION = 'REMOVE SHAPE SEGMENT SELECTION';

export class RemoveShapeSegmentSelection implements Action {
  readonly type: string = REMOVE_SHAPE_SEGMENT_FROM_SELECTION;

  constructor(public payload: Segment) {

  }
}


export const ADD_PERFORATION_SEGMENT_TO_SELECTION = 'ADD PERFORATION SEGMENT TO SELECTION';

export class AddPerforationSegmentToSelection implements Action {
  readonly type: string = ADD_PERFORATION_SEGMENT_TO_SELECTION;

  constructor(public payload: Segment) {

  }
}

export const REMOVE_PERFORATION_SEGMENT_FROM_SELECTION = 'REMOVE PERFORATION SEGMENT SELECTION';

export class RemovePerforationSegmentSelection implements Action {
  readonly type: string = REMOVE_PERFORATION_SEGMENT_FROM_SELECTION;

  constructor(public payload: Segment) {

  }
}

export interface RoundUpSelectedNodesParams {
  radius: number;
  length: number;
  width: number;
  height: number;
  notch: number;
  rant: number;
  type: string;
}

export class RoundUpSelectedNodes implements Action {
  readonly type: string = ROUND_UP_SELECTED_NODES;

  constructor(public payload: RoundUpSelectedNodesParams) {

  }
}

export class AddHelpLine implements Action {
  readonly type: string = ADD_HELP_LINE;

  constructor(public payload: HelpLine) {

  }
}

export class RemoveHelpLine implements Action {
  readonly type: string = REMOVE_HELP_LINE;

  constructor(public payload: HelpLine) {

  }
}

export const SELECT_HELP_LINE = 'SELECT HELP LINE';

export class SelectHelpLine implements Action {
  readonly type: string = SELECT_HELP_LINE;

  constructor(public payload: HelpLine) {

  }
}

export class AddToolHelpLine implements Action {
  readonly type: string = ADD_TOOL_HELP_LINE;

  constructor(public payload: HelpLine) {

  }
}

export class RemoveToolHelpLine implements Action {
  readonly type: string = REMOVE_TOOL_HELP_LINE;

  constructor(public payload: HelpLine) {

  }
}

export class RemoveAllToolHelpLines implements Action {
  readonly type: string = REMOVE_ALL_TOOL_HELP_LINES;

  constructor(public payload: any = null) {
  }

}

export const SET_CLOSEST_SEGMENT_VISIBLITY = 'SET CLOSEST SEGMENT VISIBILITY';

export class SetClosestSegmentVisiblity implements Action {
  readonly type: string = SET_CLOSEST_SEGMENT_VISIBLITY;

  constructor(public payload: boolean) {

  }
}


export class SetHintMessage implements Action {
  readonly type: string = SET_HINT_MESSAGE;

  constructor(public payload: string) {

  }
}

export const SET_HINT_POSITION = 'SET HINT POSITION';
export const SET_HINT_OFFSET = 'SET HINT OFFSET';
export const SET_HINT_VISIBILITY = 'SET HINT VISIBILITY';

export class SetHintPosition implements Action {
  readonly type: string = SET_HINT_POSITION;

  constructor(public payload: Vector2) {

  }
}

export class SetHintOffset implements Action {
  readonly type: string = SET_HINT_OFFSET;

  constructor(public payload: Vector2) {

  }
}

export class SetHintVisibility implements Action {
  readonly type: string = SET_HINT_VISIBILITY;

  constructor(public payload: Boolean) {

  }
}

export const ADD_MEASUREMENT = 'ADD MEASUREMENTS';

export class AddMeasurement implements Action {
  readonly type: string = ADD_MEASUREMENT;

  constructor(public payload: MeasurementModel) {

  }
}

export const REMOVE_MEASUREMENT = 'REMOVE MEASUREMENTS';

export class RemoveMeasurement implements Action {
  readonly type: string = REMOVE_MEASUREMENT;

  constructor(public payload: MeasurementModel) {

  }
}

export const ADD_ANGLE_MEASUREMENT = 'ADD ANGLE MEASUREMENTS';

export class AddAngleMeasurement implements Action {
  readonly type: string = ADD_ANGLE_MEASUREMENT;

  constructor(public payload: AngleMeasurementModel) {

  }
}

export const REMOVE_ANGLE_MEASUREMENT = 'REMOVE Angle MEASUREMENTS';

export class RemoveAngleMeasurement implements Action {
  readonly type: string = REMOVE_ANGLE_MEASUREMENT;

  constructor(public payload: AngleMeasurementModel) {

  }
}

export const SELECT_MEASUREMENT = 'SELECT MEASUREMENT';

export class SelectMeasurement implements Action {
  readonly type: string = SELECT_MEASUREMENT;

  constructor(public payload: MeasurementModel) {

  }

}

export const SELECT_AUTOMATIC_MEASUREMENT = 'SELECT AUTOMATIC MEASUREMENT';

export class SelectAutomaticMeasurement implements Action {
  readonly type: string = SELECT_AUTOMATIC_MEASUREMENT;

  constructor(public payload: MeasurementModel) {

  }
}

export const SELECT_ANGLE_MEASUREMENT = 'SELECT ANGLE MEASUREMENT';

export class SelectAngleMeasurement implements Action {
  readonly type: string = SELECT_ANGLE_MEASUREMENT;

  constructor(public payload: AngleMeasurementModel) {

  }

}

export const CREATE_PERFORATION_AREAS_FROM_SHAPE = 'CREATE PERFORATION AREAS FROM SHAPE';

export class CreatePerforationAreasFomShape implements Action {
  readonly type: string = CREATE_PERFORATION_AREAS_FROM_SHAPE;

  constructor(public payload: number) {

  }
}

export interface ZonePerforationAreaParams {
  //shape: ShapeWithHoles;
  margin: number;
  point: Vector2;
}

export const CREATE_PERFORATION_AREA_FROM_ZONE = 'CREATE PERFORATION AREA FROM ZONE';

export class CreatePerforationAreaFromZone implements Action {
  readonly type: string = CREATE_PERFORATION_AREA_FROM_ZONE;

  constructor(public payload: ZonePerforationAreaParams) {

  }
}

export const CREATE_PERFORATION_AREA_FROM_ZONE_SUCCESS = 'CREATE PERFORATION AREA FROM ZONE SUCCESS';

export interface ZonePerforationAreaSuccessParams {
  shapes: ShapeWithHoles[];
  point: Vector2;
  //zone: ShapeWithHoles;
}

export class CreatePerforationAreaFromZoneSuccess implements Action {
  readonly type: string = CREATE_PERFORATION_AREA_FROM_ZONE_SUCCESS;

  constructor(public payload: ZonePerforationAreaSuccessParams) {

  }
}

export const CREATE_PERFORATION_AREAS_FROM_SHAPE_SUCCESS = 'CREATE PERFORATION AREAS FROM SHAPE';

export class CreatePerforationAreasFomShapeSuccess implements Action {
  readonly type: string = CREATE_PERFORATION_AREAS_FROM_SHAPE_SUCCESS;

  constructor(public payload: ShapeWithHoles[]) {

  }
}

export const REQUEST_RENDER = 'REQUEST RENDER';

export class RequestRender implements Action {
  readonly type: string = REQUEST_RENDER;

  constructor(public payload: any = null) {
  }
}


export const SET_CURRENT_PERFORATION_AREA_ROTATION = 'SET CURRENT PERFORATION AREA ROTATION';

export class SetCurrentPerforationAreaRotation implements Action {
  readonly type: string = SET_CURRENT_PERFORATION_AREA_ROTATION;

  constructor(public payload: number = null) {
  }
}

export interface CornerTreatmentParams {
  nodes: Vector2[];
  options: RoundUpSelectedNodesParams;
}

export const TREAT_CORNER = 'TREAT CORNER';

export class TreatCorner implements Action {
  readonly type: string = TREAT_CORNER;

  constructor(public payload: CornerTreatmentParams) {
  }
}

export interface SegmentTreatmentParams {
  segment: Segment;
  options: RoundUpSelectedNodesParams;
}

export const TREAT_SEGMENT_CORNERS = 'TREAT SEGMENT CORNERS';

export class TreatSegmentCorners implements Action {
  readonly type: string = TREAT_SEGMENT_CORNERS;

  constructor(public payload: SegmentTreatmentParams) {
  }
}

export const SET_HINT_STATUS = 'SET_HINT_STATUS';

export class SetHintStatus implements Action {
  readonly type: string = SET_HINT_STATUS;

  constructor(public payload: HintStatus) {

  }
}

export const HINT_DROPDOWN_LABEL_CHANGE = 'HINT DROPDOWN LABEL CHANGE';

export class HintDropdownLabelChange implements Action {
  readonly type: string = HINT_DROPDOWN_LABEL_CHANGE;

  constructor(public payload: string) {

  }
}

export const RESET_DRAWING = 'RESET DRAWING';

export class ResetDrawing implements Action {
  readonly type: string = RESET_DRAWING;
}
export const SET_AUTOMATIC_MEASUREMENTS = 'SET AUTOMATIC MEASUREMENTS';

export class SetAutomaticMeasurements implements Action {
  readonly type: string = SET_AUTOMATIC_MEASUREMENTS;

  constructor(public payload: {measurements: Map<number, MeasurementModel>, bendingLinesDistances:number[]}) {

  }
}

export const REMOVE_AUTOMATIC_MEASUREMENT = 'REMOVE AUTOMATIC MEASUREMENT';

export class RemoveAutomaticMeasurement implements Action {
  readonly type: string = REMOVE_AUTOMATIC_MEASUREMENT;

  constructor(public payload: MeasurementModel) {

  }
}

export const ROTATE_IMPORT_SHAPE = 'ROTATE_IMPORT_SHAPE';

export class RotateImportShape implements Action {
  readonly type: string = ROTATE_IMPORT_SHAPE;

  constructor(public payload: number) {

  }
}

export type DrawingActions =
  NodeActions
  | SnapOptionsActions
  | LineActions
  | PlateActions
  | GridActions
  | PerforationActions
  | UpdatePlateShape
  | UpdatePlateShapeSucess
  | UpdatePlateShapeFail
  | RoundUpSelectedNodes
  | SetClosestSegmentVisiblity
  | SetHintMessage
  | AddMeasurement
  | RemoveMeasurement
  | SelectMeasurement
  | AddAngleMeasurement
  | RemoveAngleMeasurement
  | SelectAngleMeasurement
  | AddToolHelpLine
  | RemoveToolHelpLine
  | RemoveAllToolHelpLines
  | RequestRender
  | SetHintStatus
  | SetHintVisibility
  | RotateImportShape
  | Toggle3DView
  ;
