import {LomoeItem} from "./configuration-lomoe.model";
import {ConfigurationMaterial} from "./configuration-material.model";
import {multiplyVector2byScalar, Vector2} from "webcad/math";

export function getElementMaxSize(lomoe:LomoeItem, material:ConfigurationMaterial):Vector2{

  let maxCoilWidth = 0;
  let maxCoilLength = 0;
  let maxPlateWidth  = 0;
  let maxPlateLength  = 0;

  if(material && material.id !== 0){
    maxCoilWidth = Math.max(maxCoilWidth, Number(material.maxCoilWidth));
    maxCoilLength = Math.max(maxCoilLength, Number(material.maxCoilLength));
    maxPlateWidth = Math.max(maxPlateWidth, Number(material.maxPlateWidth));
    maxPlateLength = Math.max(maxPlateLength, Number(material.maxPlateLength));
  }

  if(lomoe && lomoe.id !== 0){
    var cw = Math.max(
      Number(lomoe.coilMaxForomatV1),
      Number(lomoe.coilMaxForomatV2));
    maxCoilWidth = Math.min(maxCoilWidth, cw);
    if(maxCoilWidth === 0) {
      maxCoilLength = 0;
    }

    var pw = Math.max(
      Number(lomoe.plateMaxForomatV2),
      Number(lomoe.tcMaxFormat));
    maxPlateWidth = Math.min(maxPlateWidth, pw);
    if(maxPlateWidth == 0) {
      maxPlateLength = 0;
    }
    if(maxPlateWidth > 0) {
      maxPlateLength = 2000;
    }
    if(maxPlateWidth > 1000) {
      maxPlateLength = 2500;
    }
    if(maxPlateWidth > 1250) {
      maxPlateLength = 3000;
    }
  }

  var result:Vector2 = {
    x: Math.max(maxPlateLength, maxCoilLength),
    y: Math.max(maxPlateWidth, maxCoilWidth),
  };

  if(result.x === 0){
    result.x = 4000
  }
  if(result.y === 0){
    result.y = 1500
  }

  return multiplyVector2byScalar(result, 0.001);

}
