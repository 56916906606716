import { Component, OnInit } from '@angular/core';
import {Store} from "@ngrx/store";
import {MevacoState} from "../../store/reducers";
import {TranslationProvider} from "../../providers/translation.provider";
import {RequestRender, Toggle3DView} from "../../store/actions/drawing.actions";

@Component({
  selector: 'view-switch',
  templateUrl: './view-switch.component.html',
  styleUrls: ['./view-switch.component.css']
})
export class ViewSwitchComponent implements OnInit {
  is3D: boolean = true;

  constructor(private store: Store<MevacoState>,private translationProvider: TranslationProvider) { }

  ngOnInit() {
  }
  toggleViewMode(){
    this.store.dispatch(new Toggle3DView(this.is3D));
    this.store.dispatch(new RequestRender());
    this.is3D = !this.is3D;
  }
  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }
}
