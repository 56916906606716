import {Drawing} from "../../model/drawing.model";
import {BendingNode} from "../../model/view-model/bending-node.viewModel";
import {buildBendingStructure} from "../../utils/bending";
import {PolylineViewModel} from "../../model/view-model/polylines.viewModel";

export class BoundaryViewModelProvider {
  private model:Drawing;
  private viewModel:PolylineViewModel;

  getViewModel(newModel: Drawing): PolylineViewModel {
    if(!newModel || !newModel.plate){
      this.viewModel = null;
    } else if(
      !this.model ||
      this.model.plate.boundary !== newModel.plate.boundary ||
      this.model.snapOptions.edges !== newModel.snapOptions.edges
    ){
        if(newModel.snapOptions.edges){
          this.viewModel = {polyline:[newModel.plate.boundary], color: "#ae1300ff"};
        }
        else{
          this.viewModel = null;
        }
    }
    this.model = newModel;
    return this.viewModel;
  }
}
