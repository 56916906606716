import { Observable } from "rxjs";
import { Webcad } from "../core";
import { Vector3, sqrDistanceVector2 } from "../math";
import { AngleMeasurementModel, MeasurementModel } from "../models";
import { ObjectUnderPoint } from "../models/ObjectUnderPoint";
import { CameraModel } from "../models/camera.model";
import { ChangeType, detectChangesInMap } from "../utils";
import { MeasurementVisualizer, ModelVisualizer } from "../visualizers";
import { AngleMeasurementVisualizer } from "./angle-measurement.visualizer";
import { MeasurementsModel } from "./measurements.view.model";
import { Node } from "@babylonjs/core";

export class MeasurementsManagerVisualizer
  implements ModelVisualizer<MeasurementsModel>
{
  private measurementVisualizers: Map<number, MeasurementVisualizer>;
  private angleMeasurementVisualizers: Map<number, AngleMeasurementVisualizer>;
  private model: MeasurementsModel;
  private camera: CameraModel;
  private rootNode: Node;
  private webcad: Webcad;
  private currentFocused: number = null;

  constructor() {
    this.measurementVisualizers = new Map<number, MeasurementVisualizer>();
    this.angleMeasurementVisualizers = new Map<
      number,
      AngleMeasurementVisualizer
    >();
  }

  updateVisualization(newModel: MeasurementsModel): void {
    detectChangesInMap(
      this.model.collection,
      newModel.collection,
      (type: ChangeType, key: number) => {
        const visualizer = this.measurementVisualizers.get(key);
        switch (type) {
          case ChangeType.Changed:
            visualizer.updateVisualization({
              viewMask: 0xffffffff,
              model: getMeasurementViewModel(key, newModel),
            });
            break;
          case ChangeType.Added:
            if (visualizer) {
              visualizer.updateVisualization({
                viewMask: 0xffffffff,
                model: getMeasurementViewModel(key, newModel),
              });
            } else {
              const newVisualizer: MeasurementVisualizer =
                new MeasurementVisualizer();
              this.measurementVisualizers.set(key, newVisualizer);
              newVisualizer.init(
                this.rootNode,
                {
                  viewMask: 0xffffffff,
                  model: getMeasurementViewModel(key, newModel),
                },
                this.webcad
              );
            }
            break;
          case ChangeType.Removed:
            visualizer.updateVisualization(null);
            break;
        }
      },
      this.camera === this.webcad.viewState.camera
        ? null
        : (key) => {
            const visualizer = this.measurementVisualizers.get(key);
            visualizer.updateVisualization({
              viewMask: 0xffffffff,
              model: getMeasurementViewModel(key, newModel),
            });
          }
    );

    detectChangesInMap(
      this.model.angleModels,
      newModel.angleModels,
      (type: ChangeType, key: number) => {
        const visualizer = this.angleMeasurementVisualizers.get(key);
        switch (type) {
          case ChangeType.Changed:
            visualizer.updateVisualization(
              getAngleMeasurementViewModel(key, newModel)
            );
            break;
          case ChangeType.Added:
            if (visualizer) {
              visualizer.updateVisualization(
                getAngleMeasurementViewModel(key, newModel)
              );
            } else {
              const newVisualizer: AngleMeasurementVisualizer =
                new AngleMeasurementVisualizer();
              this.angleMeasurementVisualizers.set(key, newVisualizer);
              newVisualizer.init(
                this.rootNode,
                getAngleMeasurementViewModel(key, newModel),
                this.webcad
              );
            }
            break;
          case ChangeType.Removed:
            visualizer.updateVisualization(null);
            break;
        }
      },
      this.camera === this.webcad.viewState.camera
        ? null
        : (key) => {
            const visualizer = this.angleMeasurementVisualizers.get(key);
            visualizer.updateVisualization(
              getAngleMeasurementViewModel(key, newModel)
            );
          }
    );

    this.model = newModel;
    this.camera = this.webcad.viewState.camera;
  }

  init(
    rootNode: Node,
    model: MeasurementsModel,
    webcad: Webcad
  ): Promise<void> {
    //this.basicModel = initialMeasurementModel;
    this.model = model;
    this.webcad = webcad;
    this.camera = this.webcad.viewState.camera;
    this.rootNode = rootNode;
    const promises: Promise<any>[] = [];
    for (let i: number = 0; i < 10; i++) {
      this.measurementVisualizers.set(i, new MeasurementVisualizer());
    }
    this.measurementVisualizers.forEach((visualizer, key, map) => {
      promises.push(visualizer.init(rootNode, null, webcad));
    });
    return new Promise<void>((resolve, reject) => {
      Promise.all(promises).then(() => {
        resolve();
      }, reject);
    });
  }

  public getVisualizerInputSubscription(id: number): Observable<number> {
    return this.measurementVisualizers.get(id).onChangeObservable;
  }

  public setVisualizerFocus(id: number | null): void {
    // if (this.currentFocused === id ) {
    //     return;
    // }
    if (this.currentFocused !== null) {
      this.measurementVisualizers.get(this.currentFocused).setFocus(false);
    }
    if (id === null) {
      const canvas = this.webcad.scene.getEngine().getRenderingCanvas();
      if (canvas) {
        canvas.focus();
      }
    } else {
      const visualizer = this.measurementVisualizers.get(id);
      if (visualizer) {
        this.measurementVisualizers.get(id).setFocus(true);
      }
    }
    this.currentFocused = id;
  }

  public dispose(): void {
    this.model.collection.clear();
    this.measurementVisualizers.clear();
    this.currentFocused = null;
  }

  getObjectUnderPoint(point: Vector3, maxDist: number): ObjectUnderPoint {
    const objects: ObjectUnderPoint[] = [];
    this.angleMeasurementVisualizers.forEach((v, k, m) => {
      const obj = v.getObjectUnderPoint(point, maxDist);
      if (obj) {
        objects.push(obj);
      }
    });
    this.measurementVisualizers.forEach((v, k, m) => {
      const obj = v.getObjectUnderPoint(point, maxDist);
      if (obj) {
        objects.push(obj);
      }
    });
    let closestDist = Number.POSITIVE_INFINITY;
    let closest: ObjectUnderPoint = null;
    for (const o of objects) {
      const dist = sqrDistanceVector2(point, o.point);
      if (dist < closestDist) {
        closestDist = dist;
        closest = o;
      }
    }
    return closest;
  }
}

function getMeasurementViewModel(
  key: number,
  measurementsModel: MeasurementsModel
): MeasurementModel {
  return measurementsModel.collection.get(key);
}

function getAngleMeasurementViewModel(
  key: number,
  measurementsModel: MeasurementsModel
): AngleMeasurementModel {
  return measurementsModel.angleModels.get(key);
}
