﻿import {dotVector2, sqrLengthVector2} from './vector2';
import {Matrix3} from './matrix3';

export interface Vector3 {
    x: number;
    y: number;
    z: number;
}

export function addVectors3(a: Vector3, b: Vector3): Vector3 {
    return {
        x: a.x + b.x,
        y: a.y + b.y,
        z: a.z + b.z
    };
}

export function subVectors3(a: Vector3, b: Vector3): Vector3 {
    return {
        x: a.x - b.x,
        y: a.y - b.y,
        z: a.z - b.z
    };
}

export function sqrLengthVector3(a: Vector3): number {
    return a.x * a.x + a.y * a.y + a.z * a.z;
}

export function lengthVector3(a: Vector3): number {
    return Math.sqrt(sqrLengthVector3(a));
}

export function multiplyVector3byScalar(a: Vector3, s: number): Vector3 {
    return {
        x: a.x * s,
        y: a.y * s,
        z: a.z * s
    };
}

export function multiplyVector3byFloats(a: Vector3, x: number, y: number, z: number): Vector3 {
    return {
        x: a.x * x,
        y: a.y * y,
        z: a.z * z
    };
}

export function divideVector3byScalar(a: Vector3, s: number): Vector3 {
    return {
        x: a.x / s,
        y: a.y / s,
        z: a.z / s
    };
}

export function sqrDistanceVector3(a: Vector3, b: Vector3): number {
    const d = subVectors3(a, b);
    const dist = sqrLengthVector3(d);
    return dist;
}

export function distanceVector3(a: Vector3, b: Vector3): number {
    return Math.sqrt(sqrDistanceVector3(a, b));
}

export function normalizeVector3(a: Vector3): Vector3 {
    return divideVector3byScalar(a, lengthVector3(a))
}

export function safeNormalizeVector3(a: Vector3, v: Vector3): Vector3 {
    const l = lengthVector3(a);
    if (l < 0.000001) {
        return {...v};
    }
    return divideVector3byScalar(a, l);
}

export function dotProductVector3(a: Vector3, b: Vector3): number {
    return a.x * b.x + a.y * b.y + a.z * b.z
}

export function crossProductVector3(a: Vector3, b: Vector3): Vector3 {
    return {x: a.y * b.z - a.z * b.y, y: a.z * b.x - a.x * b.z, z: a.x * b.y - a.y * b.x};
}
export function isPointBetween3D(start: Vector3, end: Vector3, point: Vector3) {
    const startToEndDir = subVectors3(end, start);
    const startToPointDir = subVectors3(point, start);
    const startDot = dotProductVector3(startToEndDir, startToPointDir);
    const checkProd = dotProductVector3(startToEndDir, startToEndDir);
    // check if points are colinear / aligned
    const cross = crossProductVector3(startToEndDir, startToPointDir);
    if (cross.z < -0.00001 || cross.z > 0.00001) {
        return false;
    }
    // check if point is between start and end
    if (startDot === 0 || startDot === checkProd || ((checkProd > startDot) && (startDot > 0))) {
        return true;
    }
    return false;
}
export function linePlaneIntersection(lineDirection: Vector3, lineOrigin: Vector3, planeNormal: Vector3, planePosition: Vector3): Vector3 {

    // get d value
    const d = dotProductVector3(planeNormal, planePosition);

    if (dotProductVector3(planeNormal, lineDirection) === 0) {
        return null // No intersection, the line is parallel to the plane
    }

    // Compute the X value for the directed line ray intersecting the plane
    const x = (d - dotProductVector3(planeNormal, lineOrigin)) / dotProductVector3(planeNormal, lineDirection);

    // output contact point
    return addVectors3(lineOrigin, multiplyVector3byScalar(normalizeVector3(lineDirection), x)); //Make sure your ray vector is normalized

}


export function copyVector3(a: Vector3): Vector3 {
    return {
        x: a.x,
        y: a.y,
        z: a.z
    };
}

export function projectPoint3DOnPlane(point: Vector3, planeNormal: Vector3, planeOrigin: Vector3): Vector3 {
    const v = subVectors3(point, planeOrigin);
    const dist = dotProductVector3(v, planeNormal);
    const projected = subVectors3(point, multiplyVector3byScalar(planeNormal, dist));
    return {x: projected.x, y: projected.y, z: projected.z };
}

export function projectPointToSegmentV3(origin: Vector3, direction: Vector3, point: Vector3, maxDist: number = null): Vector3 {
    const mDist = maxDist * maxDist;
    const n = normalizeVector3(direction);
    const vectorFromPointToOrigin = subVectors3(origin, point);
    const e2 = subVectors3(point, origin);
    const valDp = dotProductVector3(direction, e2);
    const dot = dotProductVector3(vectorFromPointToOrigin, n);
    const vector = subVectors3(vectorFromPointToOrigin, multiplyVector3byScalar(n, dot));
    const dist = Math.abs(sqrLengthVector2(vector));
    const recArea = dotVector2(direction, direction);
    const val = dotVector2(direction, vectorFromPointToOrigin);
    const length = sqrLengthVector2(direction);
    if (maxDist) {
        if (val > 0 && val < recArea && dist < mDist) {
            return addVectors3(origin, multiplyVector3byScalar(multiplyVector3byScalar(direction, valDp), (1 / length)));
        }
    } else {
        return addVectors3(origin, multiplyVector3byScalar(multiplyVector3byScalar(direction, valDp), (1 / length)));
    }
    return null;
}

export function isPointBetweenV3(start: Vector3, end: Vector3, point: Vector3): boolean {
    const startToEndDir = subVectors3(end, start);
    const startToPointDir = subVectors3(point, start);
    const startDot = dotProductVector3(startToEndDir, startToPointDir);
    const checkProd = dotProductVector3(startToEndDir, startToEndDir);
    // check if points are colinear / aligned
    if (sqrLengthVector3(crossProductVector3(startToEndDir, startToPointDir)) !== 0) {
        return false;
    }
    // check if point is between start and end
    if (startDot === 0 || startDot === checkProd || ((checkProd > startDot) && (startDot > 0))) {
        return true;
    }
    return false;
}


export function rayToVector3Distance(v: Vector3, rayOrigin: Vector3, rayNormalizedDir: Vector3) {
    const originToV = subVectors3(v, rayOrigin);
    if (dotProductVector3(originToV, rayNormalizedDir) <= 0) {
        return distanceVector3(rayOrigin, v);
    }
    const cross = crossProductVector3(originToV, rayNormalizedDir);
    return lengthVector3(cross);
}

export function vectors3Equal(a: Vector3, b: Vector3): boolean {
    if (a === b) return true;
    if (a === null || b === null) return false;
    var dX = a.x - b.x;
    var dY = a.y - b.y;
    var dZ = a.z - b.z;
    return dX * dX + dY * dY + dZ * dZ < 0.0000001;
}

export function multiplyVector3ByMatrix3(v: Vector3, m: Matrix3): Vector3 {
    return {
        x: m.n11 * v.x + m.n12 * v.x + v.z * m.n13,
        y: m.n21 * v.x + m.n22 * v.x + v.z * m.n23,
        z: m.n31 * v.x + m.n32 * v.x + v.z * m.n33
    };
}
