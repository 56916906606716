import {
  AfterViewInit,
  ElementRef,
  Injectable,
  Renderer2,
  RendererFactory2,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { combineLatest } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { Aabb2, CubeView3d, RotateCamera } from "webcad";
import { Vector3 } from "webcad/math";
import { RenderController } from "../controllers/render.controller";
import { ZoomOption, displayGridArray } from "../model/display-grid.model";
import { Plate, PlateMaterialType } from "../model/plate.model";
import { getElementMaxSize } from "../model/product-configuration/utils";
import { SnapOptions } from "../model/snap-options.model";
import {
  SetGridOffset,
  SetGridSize,
  SetGridZoomOption,
} from "../store/actions/grid.actions";
import { SetPlateMaterial } from "../store/actions/plate.actions";
import {
  MevacoState,
  getPlate,
  getSnapOptions,
  showCube,
} from "../store/reducers";
import { MevacoView3d } from "../visualizers/mevaco-view3d";
import { MeasurementsManagerProvider } from "./measurements-manager.provider";
import { addControls } from "./mevaco-pointer.provider";
import { PerforationMarginsProvider } from "./perforation-margins.provider";
import { PlateAabbProvider } from "./plate-aabb.provider";
import { PlateMeshProvider } from "./plate-mesh.provider";
import { SceneProvider } from "./scene.provider";
import { ShapeWithHolesProvider } from "./shape-with-holes.provider";
import { WebcadProvider } from "./webcad.provider";
import {Engine, EventState, Scene} from "webcad/babylonjs/core";
@Injectable()
export class View3dProvider implements AfterViewInit {
  private _mevacoView3D: MevacoView3d;
  private cubeView3D: CubeView3d;
  private renderController: RenderController;
  private zoomOptions: ZoomOption[] = displayGridArray;
  private currentZoom = 5;
  private shouldUpdateCamera: boolean;
  private previousRadius: number;
  private originalPanningSens: number;
  private plate: Plate;
  private depth: number;
  private renderer: Renderer2;
  private snapOptions: SnapOptions;

  constructor(
    rendererFactory: RendererFactory2,
    private webCadProvider: WebcadProvider,
    private sceneProvider: SceneProvider,
    private measurementsManagerProvider: MeasurementsManagerProvider,
    private store: Store<MevacoState>,
    private plateMeshProvider: PlateMeshProvider,
    private shapeWithHolesProvider: ShapeWithHolesProvider,
    private plateAabbProvider: PlateAabbProvider,
    private perforationMarginsProvider: PerforationMarginsProvider
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  get camera(): RotateCamera {
    return this._mevacoView3D.getCamera() as RotateCamera;
  }

  get scene(): Scene {
    return this._mevacoView3D.scene;
  }

  get canvas(): ElementRef {
    return this._mevacoView3D.getCanvas();
  }

  get mevacoView3D(): MevacoView3d {
    return this._mevacoView3D;
  }

  private resolveEngineReady: () => void;
  readonly engineReady = new Promise<void>(resolve => this.resolveEngineReady = resolve);


  setPlateMaterial(material: PlateMaterialType) {
    this.store.dispatch(new SetPlateMaterial(material));
  }

  appendView(parent: ElementRef): void {
    // lazy init
    if (!this._mevacoView3D) {
      const low = window.matchMedia("(min-width: 1024px)");
      const medium = window.matchMedia("(min-width: 1424px)");
      const high = window.matchMedia("(min-width: 1664px)");
      const viewCanvas = new ElementRef(this.renderer.createElement("canvas"));
      // this.renderer.addClass(viewCanvas.nativeElement, 'webcad');
      this.renderer.setAttribute(
        viewCanvas.nativeElement,
        "style",
        "width: 100%; height: 100%; position: absolute; top: 0; left: 0; outline: none;"
      );
      this.renderer.listen(
        viewCanvas.nativeElement,
        "mouseenter",
        this.focusCanvas.bind(this)
      );

      const cubeCanvas = new ElementRef(this.renderer.createElement("canvas"));
      if (low.matches) {
        this.renderer.setAttribute(
          cubeCanvas.nativeElement,
          "style",
          // 'width: 200px;\n' +
          // 'height: 120px;\n' +
          "position: absolute;\n" +
            "outline: none;\n" +
            "bottom: 180px;\n" +
            "right: 30px;\n" +
            "z-index: 3;"
        );
      } else if (medium.matches || high.matches) {
        this.renderer.setAttribute(
          cubeCanvas.nativeElement,
          "style",
          // 'width: 200px;\n' +
          // 'height: 120px;\n' +
          "position: absolute;\n" +
            "outline: none;\n" +
            "bottom: 50px;\n" +
            "right: 0;\n" +
            "z-index: 3;"
        );
      } else {
        this.renderer.setAttribute(
          cubeCanvas.nativeElement,
          "style",
          // 'width: 200px;\n' +
          // 'height: 120px;\n' +
          "position: absolute;\n" +
            "outline: none;\n" +
            "bottom: 50px;\n" +
            "right: 0;\n" +
            "z-index: 3;"
        );
      }
      this.renderer.appendChild(parent.nativeElement, viewCanvas.nativeElement);
      this.renderer.appendChild(parent.nativeElement, cubeCanvas.nativeElement);

      this.renderer.setAttribute(cubeCanvas.nativeElement, "width", "200px");
      this.renderer.setAttribute(cubeCanvas.nativeElement, "height", "120px");

      this._mevacoView3D = new MevacoView3d(
        viewCanvas,
        this.plateMeshProvider,
        this
      );
      this._mevacoView3D.init();
      this.cubeView3D = new CubeView3d(
        cubeCanvas,
        this._mevacoView3D.getCamera() as RotateCamera
      );
      this.webCadProvider.setWebCad(this._mevacoView3D);
      // this.scene = this.mevacoView3D.scene;
      this._mevacoView3D.setCubeView(this.cubeView3D);
      this.sceneProvider.setScene(this.scene);
      this.scene.onBeforeRenderObservable.add(() => {
        const engine: Engine = this.scene.getEngine();
        const canvas = engine.getRenderingCanvas();
        if (
          canvas.clientWidth !== engine.getRenderWidth() ||
          canvas.clientHeight !== engine.getRenderHeight()
        ) {
          engine.resize();
        }
      });
      // this.camera = (<RotateCamera>this.mevacoView3D.getCamera());
      this.renderController = new RenderController(
        this.store,
        this._mevacoView3D,
        this.cubeView3D
      );
      this.renderController.init();
      addControls(this.scene, this.camera, this._mevacoView3D);
      // this.element = canvas;

      this.store.pipe(select(getPlate)).subscribe((value) => {
        this.depth = value.depth;
      });
      this.store.pipe(select(getPlate)).subscribe((plate) => {
        this.plate = plate;
      });

      this.store
        .pipe(select(getSnapOptions))
        .subscribe((options) => (this.snapOptions = options));
      this.camera.onAfterCheckInputsObservable.add(this.onZoom.bind(this));

      this.store
        .select((state) => state.model)
        .subscribe((model) => {
          this._mevacoView3D.updateModel(model);
        });

      this.plateAabbProvider.aabb.subscribe((plateAAbb) => {
        this.store.dispatch(new SetGridOffset(plateAAbb.min));
      });

      combineLatest([
        this.store.pipe(
          select(
            (state) => state.model.productConfiguration.configuration.lomoe
          )
        ),
        this.store.pipe(
          select(
            (state) => state.model.productConfiguration.configuration.material
          )
        ),
      ]).subscribe(([lomoe, material]) => {
        const maxSize = getElementMaxSize(lomoe, material);
        this.store.dispatch(new SetGridSize(maxSize));
      });

      this.perforationMarginsProvider.init();

      this.store
        .pipe(select(showCube), distinctUntilChanged())
        .subscribe((show) => {
          this.cubeView3D.hide(!show);
        });

      this.resolveEngineReady();
    }

    this.renderer.appendChild(
      parent.nativeElement,
      this._mevacoView3D.getCanvas()
    );

    const cubeNative = this.cubeView3D.getCanvas().nativeElement;
    this.renderer.appendChild(parent.nativeElement, cubeNative);
    this.renderer.setAttribute(cubeNative, "width", "200px");
    this.renderer.setAttribute(cubeNative, "height", "120px");
  }

  onZoom(eventData: RotateCamera, eventState: EventState) {
    if (this.previousRadius !== eventData.radius) {
      const closest: number = this.getClosestZoom();
      if (this.currentZoom !== closest) {
        this.currentZoom = closest;
        this.store.dispatch(
          new SetGridZoomOption(this.zoomOptions[this.currentZoom])
        );
      }
      this.camera.panningSensibility = Math.min(
        14000,
        this.originalPanningSens / (this.camera.radius / 10)
      );
      this.previousRadius = (<RotateCamera>eventData).radius;
      this.shouldUpdateCamera = true;
      this._mevacoView3D.shouldUpdate = true;
    }
  }

  getClosestZoom(): number {
    for (let i = 0; i < this.zoomOptions.length; i++) {
      if (this.camera.radius <= this.zoomOptions[i].cameraRadius) {
        return i;
      }
    }
  }

  ngAfterViewInit() {
    this.originalPanningSens = 140;
    this.shouldUpdateCamera = false;
    this._mevacoView3D.setCameraToFitAaBb(
      this.camera,
      this.plateAabbProvider.aabbValue
    );
  }

  public zoomToFitPlate() {
    this._mevacoView3D.zoomToFitAaBb(
      this.camera,
      this.plateAabbProvider.aabbValue
    );
  }

  public zoomToFitAabb(aabb: Aabb2) {
    if (!this._mevacoView3D) return;
    this._mevacoView3D.zoomToFitAaBb(this.camera, aabb);
  }

  public setBackgroundColor(newColor: Vector3) {
    this._mevacoView3D.updateBackgroundColor(newColor);
  }

  focusCanvas() {
    if (this.canvas.nativeElement) {
      this.canvas.nativeElement.focus();
    }
  }

  public updateView() {
    if (this._mevacoView3D) {
      this._mevacoView3D.shouldUpdate = true;
    }
  }
}
