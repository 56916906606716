import {Mesh, Nullable, Node, Scene, _InstancesBatch, InstancedMesh as B_InstancedMesh} from "@babylonjs/core";

export class InstancedMesh extends Mesh {
  constructor(
    name: string,
    private count: number,
    scene?: Nullable<Scene>,
    parent?: Nullable<Node>,
    source?: Nullable<Mesh>,
    doNotCloneChildren?: boolean,
    clonePhysicsImpostor?: boolean
  ) {
    super(
      name,
      scene,
      parent,
      source,
      doNotCloneChildren,
      clonePhysicsImpostor
    );
  }

  _checkOcclusionQuery(): boolean {
    this.isOccluded = false;
    return this.isOccluded;
  }

  _getInstancesRenderList(subMeshId: number): _InstancesBatch {
    const renderSelf: boolean[] = [];
    renderSelf[subMeshId] = true;
    const visibleInstances: Nullable<B_InstancedMesh[]>[] = [];
    visibleInstances[subMeshId] = [];
    return {
      mustReturn: false,
      renderSelf: renderSelf,
      visibleInstances: visibleInstances,
      hardwareInstancedRendering: [false],
    };
  }
  _processRendering(renderingMesh, subMesh, effect, fillMode, batch, hardwareInstancedRendering, onBeforeDraw, effectiveMaterial) {
    const scene = this.getScene();
    const engine = scene.getEngine();
    fillMode = this._getRenderingFillMode(fillMode);
    this._bind(subMesh, effect, fillMode);
    this._draw(subMesh, fillMode, this.count);

    engine.unbindInstanceAttributes();
    return this;
  }
}
