import {initialSnappingModel, SnappingModel} from "./snapping.model";

export interface DisplayGridModel {
  gridModel: SnappingModel;
  cameraRadius: number;
}

export interface ZoomOption {
  snapX: number;
  snapY: number;
  majorLineFrequency: number;
  cameraRadius: number;
}

export const displayGridArray: ZoomOption[] = [

  {
    snapX: 0.001,
    snapY: 0.001,
    majorLineFrequency: 0.01,
    cameraRadius: 0.2,
  },
  {
    snapX: 0.005,
    snapY: 0.005,
    majorLineFrequency: 0.05,
    cameraRadius: 1.2,
  },
  {
    snapX: 0.01,
    snapY: 0.01,
    majorLineFrequency: 0.1,
    cameraRadius: 2.2,
  },
  {
    snapX: 0.025,
    snapY: 0.025,
    majorLineFrequency: 0.5,
    cameraRadius: 3.3,
  },
  {
    snapX: 0.05,
    snapY: 0.05,
    majorLineFrequency: 0.5,
    cameraRadius: 4.2,
  },
  {
    snapX: 0.1,
    snapY: 0.1,
    majorLineFrequency: 0.5,
    cameraRadius: 7,
  },
  {
    snapX: 0.25,
    snapY: 0.25,
    majorLineFrequency: 2,
    cameraRadius: 13,
  },
];
