import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { PreviewPlateProvider } from "./preview-plate.provider";

@Injectable()
export class ReloadPositionProvider {
  _reloadObservable: BehaviorSubject<boolean> = new BehaviorSubject(true);
  positionsObservable: BehaviorSubject<Map<number, boolean>> =
    new BehaviorSubject(new Map());

  constructor(private previewPlateProvider: PreviewPlateProvider) {
    combineLatest([
      previewPlateProvider.isQueueRunning,
      this.positionsObservable,
    ])
      .pipe(
        map(([queueRunning, positions]) => {
          if (!queueRunning) {
            let allPositionsUpdated = true;
            positions.forEach((v, k) => {
              if (v) {
                allPositionsUpdated = false;
              }
            });
            return allPositionsUpdated;
          }
          return false;
        })
      )
      .subscribe((v) => {
        this._reloadObservable.next(v);
      });
  }

  public register(id: number) {
    const newMap = new Map<number, boolean>(
      Array.from([...this.positionsObservable.value.entries()])
    );
    newMap.set(id, true);
    this.positionsObservable.next(newMap);
  }

  public unregister(id: number) {
    const newMap = new Map<number, boolean>(
      Array.from([...this.positionsObservable.value.entries()])
    );
    if (newMap.has(id)) {
      newMap.delete(id);
    }
    this.positionsObservable.next(newMap);
  }

  public checkPositionState(id: number) {
    const map = this.positionsObservable.value;
    return map.has(id);
  }

  public get reloadObservable() {
    return this._reloadObservable.asObservable();
  }
}
