import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {getUserPatterns, MevacoState} from '../../../store/reducers';
import {select, Store} from '@ngrx/store';
import {GetUserPatterns} from '../../../store/actions/user-pattern.actions';
import {Subscription} from 'rxjs';
import {UserPattern} from '../../../model/user-pattern.model';
import {TranslationProvider} from "../../../providers/translation.provider";

@Component({
  selector: 'app-custom-pattern-load',
  templateUrl: './custom-pattern-load.component.html',
  styleUrls: ['./custom-pattern-load.component.css']
})
export class CustomPatternLoadComponent implements OnInit, OnDestroy {

  @Output() close = new EventEmitter<boolean>();
  private subscriptions: Subscription[] = [];
  userPatterns: UserPattern[] = [];
  publicPatterns: UserPattern[] = [];

  constructor(
    private store: Store<MevacoState>,
    private translationProvider: TranslationProvider,
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.store.pipe(select(getUserPatterns)).subscribe((v) => {
        this.userPatterns = v.filter(p => !p.publicPattern);
        this.publicPatterns = v.filter(p => p.publicPattern);
        }
      )
    );
    this.store.dispatch(new GetUserPatterns());
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      if (!s.closed) {
        s.unsubscribe();
      }
    }
    this.subscriptions = [];
  }

  translate(text: string, module: string = 'designer') {
    return this.translationProvider.translate(text, module);
  }
}
