import { Vector3 } from "../math";
import { Matrix4 } from "../math/matrix4";
import { Vector4 } from "../math/vector4";
import { CameraModel } from "../models/camera.model";
import {Camera, Matrix, Vector3 as B_Vector3, Vector4 as B_Vector4} from "@babylonjs/core";

export function babylon4ToVector4(v: B_Vector4): Vector4 {
  return {
    x: v.x,
    y: v.y,
    z: v.z,
    w: v.w,
  };
}

export function babylon3ToVector4(v: B_Vector3, w = 1): Vector4 {
  return {
    x: v.x,
    y: v.y,
    z: v.z,
    w: w,
  };
}

export function babylon3ToVector3(v: B_Vector3, w = 1): Vector3 {
  return {
    x: v.x,
    y: v.y,
    z: v.z,
  };
}

export function vector3ToBabylon3(v: Vector3, w = 1): B_Vector3 {
  return new B_Vector3(v.x, v.y, v.z);
}

export function babylonToMatrix4(m: Matrix): Matrix4 {
  return {
    n11: m.m[0],
    n12: m.m[4],
    n13: m.m[8],
    n14: m.m[12],
    n21: m.m[1],
    n22: m.m[5],
    n23: m.m[9],
    n24: m.m[13],
    n31: m.m[2],
    n32: m.m[6],
    n33: m.m[10],
    n34: m.m[14],
    n41: m.m[3],
    n42: m.m[7],
    n43: m.m[11],
    n44: m.m[15],
  };
}

export function babylonToCamera(c: Camera): CameraModel {
  if (!!c) {
    return {
      projection: babylonToMatrix4(c.getProjectionMatrix()),
      view: babylonToMatrix4(c.getViewMatrix()),
    };
  } else {
    return {
      projection: babylonToMatrix4(c.getProjectionMatrix()),
      view: babylonToMatrix4(c.getViewMatrix()),
    };
  }
}
