import * as memoizee from "memoizee";
import { Vector2 } from "webcad/math";
import {
  Aabb2,
  expandAabbInPlace,
  getEmptyAaabb,
  mergeAabb,
} from "webcad/models";
import { BitIndexMask, getValueFromMaskByIndex } from "../utils/bit-index-mask";
import { StampModel } from "./stamp.model";
import { TileModel } from "./tile.model";

export interface PositionsRow extends Vector2 {
  dir: Vector2;
  count: number;
  mask: BitIndexMask;
}

export interface Perforation {
  areEffectsEnabled: boolean;
  stamps: StampModel[];
  stamp: StampModel;
  tile: TileModel;
  positions: PositionsRow[];
  totalCount: number;
  areaIndex: number;
  rotation: number;
  punch: boolean;
  aabb: Aabb2;
}

export function getStampList(
  perf: Perforation,
  removeNulls: boolean
): StampModel[] {
  if (!perf.areEffectsEnabled) return [perf.stamp];
  return removeNulls ? perf.stamps.filter((x) => !!x) : perf.stamps;
}

function getStampCalculationMapFromPerforation<T>(
  perf: Perforation,
  calcFun: (stp: StampModel) => T,
  nullFunc: () => T = () => null
): Map<number, T> {
  const res = new Map<number, T>();
  if (!perf.areEffectsEnabled) {
    res.set(0, calcFun(perf.stamp));
  } else {
    perf.stamps.forEach((stp, i) => {
      if (stp == null) res.set(i, nullFunc());
      else res.set(i, calcFun(stp));
    });
  }
  return res;
}

export interface PerforationModel {
  perforation: Perforation[];
}

export const initialPerforationModel: PerforationModel = {
  perforation: [],
};

function binarySearch(
  ar: any[],
  el: any,
  compare_fn: (a: any, b: any) => number
): number {
  let m = 0;
  let n = ar.length - 1;
  while (m <= n) {
    const k = (n + m) >> 1;
    const cmp = compare_fn(el, ar[k]);
    if (cmp > 0) {
      m = k + 1;
    } else if (cmp < 0) {
      n = k - 1;
    } else {
      return k;
    }
  }
  return -m - 1;
}

export function holeExistsInPerforation(
  holePos: Vector2,
  perforation: Perforation
) {
  if (!perforation) {
    return -1;
  }
  return (
    0 <=
    binarySearch(
      perforation.positions,
      holePos,
      (pos: Vector2, el: Vector2 | PositionsRow) => {
        const minY = el.y - 0.001;
        const maxY = el.y + 0.001;
        if (pos.y > maxY) {
          return 1;
        }
        if (pos.y < minY) {
          return -1;
        }
        const minX = el.x - 0.001;
        let maxX = el.x + 0.001;
        const count = (el as PositionsRow).count;
        if (count > 1) {
          maxX = el.x + (el as PositionsRow).dir.x * (count - 1) + 0.001;
        }
        if (pos.x > maxX) {
          return 1;
        }
        if (pos.x < minX) {
          return -1;
        }
        return 0;
      }
    )
  );
}

export function perforationAabb(perf: PerforationModel): Aabb2 {
  let result = getEmptyAaabb();
  for (let i = 0; i < perf.perforation.length; i++) {
    result = mergeAabb(result, perf.perforation[i].aabb);
  }
  return result;
}

export const perforationHolesPositionsAabb = memoizee(
  (perf: PerforationModel): Aabb2 => {
    let result = getEmptyAaabb();
    for (let i = 0; i < perf.perforation.length; i++) {
      const p = perf.perforation[i];
      const s = Math.sin(p.rotation);
      const c = Math.cos(p.rotation);

      for (let j = 0; j < p.positions.length; j++) {
        const pr = p.positions[j];
        const pos1 = {
          x: pr.x * c - pr.y * s,
          y: pr.x * s + pr.y * c,
        };
        expandAabbInPlace(result, pos1);
        const x2 = pr.x + pr.dir.x * (pr.count - 1);
        const y2 = pr.y + pr.dir.y * (pr.count - 1);
        expandAabbInPlace(result, {
          x: x2 * c - y2 * s,
          y: x2 * s + y2 * c,
        });
      }
    }
    return result;
  },
  { max: 1 }
);

export function positionsRowAabb(
  pr: PositionsRow,
  stapmAabb: Aabb2,
  rotation: number
): Aabb2 {
  const s = Math.sin(rotation);
  const c = Math.cos(rotation);
  const x1 = pr.x * c - pr.y * s;
  const y1 = pr.x * s + pr.y * c;
  const result: Aabb2 = {
    min: {
      x: x1,
      y: y1,
    },
    max: {
      x: x1,
      y: y1,
    },
  };
  const x2 = pr.x + pr.dir.x * (pr.count - 1);
  const y2 = pr.y + pr.dir.y * (pr.count - 1);
  expandAabbInPlace(result, {
    x: x2 * c - y2 * s,
    y: x2 * s + y2 * c,
  });
  result.min.x += stapmAabb.min.x;
  result.min.y += stapmAabb.min.y;
  result.max.x += stapmAabb.max.x;
  result.max.y += stapmAabb.max.y;
  return result;
}

export function getPerforationPositions(
  perforation: Perforation,
  withBorder: boolean
): {
  holes: Float32Array;
  border: number[];
  borderMaskIndexes: number[];
  minX: number;
  maxX: number;
  minY: number;
  maskIndexes: number[];
  maxY: number;
} {
  const result: {
    holes: Float32Array;
    maskIndexes: number[];
    border: number[];
    borderMaskIndexes: number[];
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;
  } = {
    holes: new Float32Array(perforation.totalCount * 2),
    maskIndexes: [],
    borderMaskIndexes: [],
    border: [],
    minX: Number.POSITIVE_INFINITY,
    maxX: Number.NEGATIVE_INFINITY,
    minY: Number.POSITIVE_INFINITY,
    maxY: Number.NEGATIVE_INFINITY,
  };
  const positions: any[] = perforation.positions;
  const offsetX = perforation.tile.p.x;
  const offsetY = perforation.tile.p.y;
  if (withBorder && positions.length) {
    for (let j = 0; j < positions.length; j++) {
      const position = positions[j];
      const last = (position.count || 1) - 1;
      result.minX = Math.min(result.minX, position.x);
      result.maxX = Math.max(result.maxX, position.x + last * offsetX);
    }
    result.minY = positions[0].y;
    result.maxY = positions[positions.length - 1].y;
    result.maxX += 0.00002;
    result.maxY += 0.00002;

    let c = 0;
    let ci = 0;
    let ih = 0;
    let position = positions[c];
    let last = (position.count || 1) - 1;
    for (
      let y = result.minY - offsetY;
      y < result.maxY + offsetY;
      y += offsetY
    ) {
      for (
        let x = result.minX - offsetX;
        x < result.maxX + offsetX;
        x += offsetX
      ) {
        if (
          position.y > y + 0.00001 ||
          (position.y > y - 0.00001 && position.x > x + 0.00001)
        ) {
          result.border.push(x);
          result.border.push(y);
          if (perforation.areEffectsEnabled) {
            result.borderMaskIndexes.push(1);
          }
        } else if (
          position.y < y - 0.00001 ||
          (position.y < y + 0.00001 &&
            position.x + last * offsetX < x - 0.00001)
        ) {
          c++;
          ci = 0;
          if (c < positions.length) {
            position = positions[c];
            last = (position.count || 1) - 1;
          } else {
            position = {
              y: 1000,
            };
          }
          if (
            position.y > y + 0.00001 ||
            (position.y > y - 0.00001 && position.x > x + 0.00001)
          ) {
            result.border.push(x);
            result.border.push(y);
            if (perforation.areEffectsEnabled) {
              result.borderMaskIndexes.push(1);
            }
          } else {
            result.holes[ih++] = x;
            result.holes[ih++] = y;
            if (perforation.areEffectsEnabled) {
              result.maskIndexes.push(
                getValueFromMaskByIndex(position.mask, ci)
              );
              ci++;
            }
          }
        } else {
          result.holes[ih++] = x;
          result.holes[ih++] = y;
          if (perforation.areEffectsEnabled) {
            result.maskIndexes.push(getValueFromMaskByIndex(position.mask, ci));
            ci++;
          }
        }
      }
    }
  } else {
    let c = 0;
    for (let j = 0; j < positions.length; j++) {
      const position = positions[j];
      const count = position.count || 1;
      for (let k = 0; k < count; k++) {
        if (perforation.areEffectsEnabled)
          result.maskIndexes.push(getValueFromMaskByIndex(position.mask, k));
        result.holes[c++] = position.x + k * offsetX;
        result.holes[c++] = position.y;
      }
    }
  }
  return result;
}
