export default `
precision highp float;

#define M_PI 3.1415926535897932384626433832795

attribute vec3 position;
attribute vec4 color;//wights

uniform mat4 worldViewProjection;
uniform float length;
uniform float scale;
uniform float distance;
void main() {
    vec3 pos = vec3(position.x, position.y,position.z) * scale;
    pos.z += distance * color.z;
    float t = color.y * length;
    vec3 moved = vec3(pos.x + t, pos.y, pos.z);
    gl_Position = worldViewProjection * vec4(moved, 1.0);
}
`;
