import { Component, OnInit, Input } from '@angular/core';
import { UserTemplate } from '../../model/user-template.model';
import { MevacoState, ProductConfigurationAddElement } from '../../store';
import { Store } from '@ngrx/store';
import { ConfigureUserTemplate, DeleteUserTemplate } from '../../store/actions/user-template.actions';
import { environment } from '../../../environments/environment';
import { Element, ElementType } from '../../model/product-configuration/element.model';
import {TranslationProvider} from '../../providers/translation.provider';
import {Guid} from 'guid-typescript';

@Component({
  selector: 'user-template-item',
  templateUrl: './user-template-item.component.html',
  styleUrls: ['./user-template-item.component.css']
})
export class UserTemplateItemComponent implements OnInit {
  show = false;
  getImageUrl: string;
  @Input() userTemplate: UserTemplate;

  constructor(private store: Store<MevacoState>, private translationProvider: TranslationProvider) { }

  ngOnInit() {
    this.getImageUrl = environment.api_url + '/api/mevaco/getPreviewImage/';
  }

  updateTemplateName(newName: string) {
    this.store.dispatch(new ConfigureUserTemplate({
      id: this.userTemplate.id,
      name: newName
    }));
  }


  getPreviewImageUrl(): string {
    return this.getImageUrl + this.userTemplate.imageGuid;
  }

  createPosition() {
    const element = JSON.parse(this.userTemplate.template) as Element;
    element.position = -1;
    element.previewImageId = Guid.create().toString();
    this.store.dispatch(new ProductConfigurationAddElement(element));
  }

  deleteTemplate() {
    this.store.dispatch(new DeleteUserTemplate(this.userTemplate.id));
  }

  toggleModal() {
    this.show = !this.show;
  }

  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }
}
