
<div class="tutorial-modal">
  <div class="tutorial-modal-img-wrapper">
    <img src="assets/help-person.png" alt="help-img">
  </div>
  <div class="tutorial-modal-content">

    <div class="tutorial-modal-header">
      <h4 class="tutorial-modal-title" id="tutorial-modal-title">{{translate("Do you need help?', 'designer'")}}</h4>
      <button type="button" class="close btn-close" aria-label="Close" (click)="onCloseModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <p class="tutorial-modal-body">
      {{translate("tutorial description content', 'designer'")}}
    </p>


  <div class="tutorial-modal-footer" >
    <button  type="button" class="btn btn-primary btn-tutorial mr-15" (click)="openTutorial()">
      {{translate("Start tutorial', 'designer'")}}
    </button>
    <button type="button" class="tutorial-secondary-btn" (click)="onCloseModal()">
      <div class="tutorial-secondary-btn-arrow-wrapper">
        <img src="assets/down-arrow.svg" alt="arrow" class="tutorial-secondary-btn-arrow">
      </div>
      <span>{{translate("Watch shortcuts", 'designer')}}</span>
    </button>
  </div>

  </div>

</div>


