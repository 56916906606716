import {Vector2} from 'webcad/math';
import {Segment} from 'webcad/models';
import {Aabb2} from 'webcad/models';
import {aabbSegment, getEmptyAaabb, mergeAabb, mergeAabbInPlace, rotateSegment} from "webcad/models";
import * as memoizee from "memoizee";
import {Shape} from "./shape.model";

export interface StampModel {
  index: number;
  shape: string;
  h: number;
  l: number;
  rotation: number;
  offsetX: number;
  offsetY: number;
  startLfx: number;
  startLfy: number;
  polygons: Vector2[][];
  polylines: Segment[][];
}

export const getRotatedStampAabb = memoizee((stamp: StampModel, polylineIndex: number, rotation: number):Aabb2 => {
  const rotated = getRotatedStampShape(stamp, polylineIndex, rotation);
  const aabb:Aabb2 = getEmptyAaabb();
  for (let pi = 0; pi < rotated .length; pi++) {
    const polyline = rotated [pi]
    for (let si = 0; si < polyline.length; si++) {
      const segment = polyline[si];
      mergeAabbInPlace( aabb, aabbSegment(segment) );
    }
  }
  return aabb;
},{max:1000});

export const getRotatedStampShape = memoizee((stamp: StampModel, polylineIndex: number, rotation: number):Segment[][] => {
  const result:Segment[][] = [];
  for (let pi = 0; pi < stamp.polylines.length; pi++) {
    const polyline = stamp.polylines[polylineIndex === -1 ? pi : polylineIndex];
    const rotated:Segment[] = [];
    for (let si = 0; si < polyline.length; si++) {
      const segment = polyline[si];
      rotated.push(rotation === 0 ? segment : rotateSegment(segment, rotation) );
    }
    result.push(rotated);
    if(polylineIndex !== -1) {
      break;
    }
  }
  return result;
},{max:1000});
