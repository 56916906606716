<div class="btn-group" role="group" aria-label="Undo redo">
  <button
    tooltipClass="custom-tooltip--small"
    [ngbTooltip]="translate('undo tooltip', 'designer')"
    placement="bottom-left"
    type="button"
    id="undoButton"
    class="btn-undo"
    (click)="undo()"
  ></button>
  <button
    tooltipClass="custom-tooltip--small"
    [ngbTooltip]="translate('redo tooltip', 'designer')"
    placement="bottom-left"
    type="button"
    id="redoButton"
    class="btn-redo"
    (click)="redo()"
  ></button>
</div>
