import {Component, OnInit} from '@angular/core';
import {ToolProvider} from '../../providers/tool.provider';
import {HelpLineTool} from '../../tools/editTools/help-line.tool';
import {MevacoState} from '../../store/reducers';
import {SceneProvider} from '../../providers/scene.provider';
import {Store} from '@ngrx/store';
import {RemoveHelpLine} from '../../store/actions/drawing.actions';
import {TranslationProvider} from '../../providers/translation.provider';

@Component({
  selector: 'help-line',
  templateUrl: './help-line.component.html',
  styleUrls: ['./help-line.component.css']
})
export class HelpLineComponent implements OnInit {
  public helpLineTool: HelpLineTool;
  public helpLineActive: boolean = false;
  public angle: number = 0;
  public offset: number = 0;
  public isHorizontal = true;
  public isVertical = false;

  constructor(private store: Store<MevacoState>,
              private sceneProvider: SceneProvider,
              private translationProvider: TranslationProvider,
              private toolProvider: ToolProvider) {
    this.helpLineTool = this.toolProvider.helpLineTool;
    this.toolProvider.getToolObservable().subscribe((value) => this.helpLineActive = value === this.helpLineTool);
    this.helpLineTool.angle.subscribe((value) => {
      const dir = {
        x: Math.round(Math.cos(value * (Math.PI / 180)) * 100) * 0.01,
        y: Math.round(Math.sin(value * (Math.PI / 180)) * 100) * 0.01
      };
      this.isHorizontal = dir.y === 0 && (dir.x === 1 || dir.x === -1);
      this.isVertical = dir.x === 0 && (dir.y === 1 || dir.y === -1);
    });
    window.addEventListener('keyup', (event: KeyboardEvent) => {
      if (event.code === 'Delete') {
        this.deleteSelectedHelpLine();
      }
    });
  }

  ngOnInit() {
  }

  startHelpLine() {
    this.toolProvider.setTool(this.helpLineTool);
  }

  setHorizontal() {
    this.helpLineTool.changeAngle('180');
  }

  setVertical() {
    this.helpLineTool.changeAngle('90');
  }

  deleteSelectedHelpLine() {
    if (this.helpLineTool.selectedHelpLine) {
      this.store.dispatch(new RemoveHelpLine(this.helpLineTool.selectedHelpLine));
      this.helpLineTool.selectedHelpLine = null;
    }
  }

  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }
}
