import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subscription, fromEvent, merge } from "rxjs";
import {
  debounceTime,
  switchMap,
  takeUntil,
  tap,
  throttleTime,
} from "rxjs/operators";
import {
  IArc,
  IColor,
  ICoords,
  IOutput,
  IProps,
  ISegment,
  ISliderChanges,
} from "./interfaces";

const THROTTLE_DEFAULT = 50;
const DEFAULT_PROPS: IProps = {
  segments: 6,
  strokeWidth: 4,
  radius: 40,
  gradientColorFrom: "rgb(0, 159, 227)",
  gradientColorTo: "rgb(0, 159, 227)",
  bgCircleColor: "#f2f2f2",
};

@Component({
  selector: "app-circular-slider",
  templateUrl: "./circular-slider.component.html",
  styleUrls: ["./circular-slider.component.css"],
})
export class CircularSliderComponent implements OnChanges, OnInit, OnDestroy {
  @Input() props: IProps;
  @Input() startAngle = 0;
  @Input() angleLength = 0;
  @Input() angleInDegrees = 0;
  @Output() update: EventEmitter<IOutput> = new EventEmitter<IOutput>();
  public segments: ISegment[];
  public start: IArc;
  public stop: IArc;
  private startSubscription: Subscription;
  private stopSubscription: Subscription;
  private circleCenterX: number;
  private circleCenterY: number;
  @ViewChild("circle", { static: true })
  private circle: ElementRef;
  @ViewChild("stopIcon", { static: true }) private stopIcon: ElementRef;
  @ViewChild("startIcon", { static: true }) private startIcon: ElementRef;

  @Input() set value(v: string) {
    this.angleInDegrees = +v || 0;
    this.updateBasedOnInput(this.angleInDegrees);
  }
  @Input() placeholder: string;
  @Output() valueChanged = new EventEmitter<string>();
  @Input() inputType: string;
  @Input() validate: (string) => string;
  public valueControl = new FormControl();
  public sub: Subscription;
  @Input() tabIndex: number;

  private static extractMouseEventCoords(evt: MouseEvent | TouchEvent) {
    const coords: ICoords =
      evt instanceof MouseEvent
        ? {
            x: evt.clientX,
            y: evt.clientY,
          }
        : {
            x: evt.changedTouches.item(0).clientX,
            y: evt.changedTouches.item(0).clientY,
          };
    return coords;
  }

  constructor() {
    this.props = DEFAULT_PROPS;
  }

  ngOnInit() {
    this.setCircleCenter();
    this.onUpdate();
    this.setObservables();

    this.sub = this.valueControl.valueChanges
      .pipe(
        debounceTime(1000)
        // filter
      )
      .subscribe((v) => {
        this.valueControl.setValue(this.validate ? this.validate(v) : v, {
          emitEvent: false,
        });
        this.angleInDegrees = +v || 0;
        //this.angleInDegrees = +this.value;
        this.valueChanged.emit(v);
      });
  }

  ngOnChanges(changes: ISliderChanges) {
    if (changes.props) {
      this.props = changes.props.firstChange
        ? Object.assign(DEFAULT_PROPS, changes.props.currentValue)
        : DEFAULT_PROPS;
    }
    this.onUpdate();
  }

  ngOnDestroy() {
    this.closeStreams();
    this.sub.unsubscribe();
  }

  private onUpdate() {
    this.calcStartAndStop();
    this.createSegments();
    this.update.emit({
      startAngle: this.startAngle,
      angleLength: this.angleLength,
      angleInDegrees: this.angleInDegrees,
    });
    this.valueControl.setValue(this.angleInDegrees);
  }

  private setObservables() {
    const mouseMove$ = merge(
      fromEvent(document, "mousemove"),
      fromEvent(document, "touchmove")
    );
    const mouseUp$ = merge(
      fromEvent(document, "mouseup"),
      fromEvent(document, "touchend")
    );

    this.startSubscription = merge(
      fromEvent(this.startIcon.nativeElement, "touchstart"),
      fromEvent(this.startIcon.nativeElement, "mousedown").pipe(
        tap((event: any) => {
          // avoid interference by "native" dragging of <img> tags
          if (event.target && (event.target as HTMLElement).draggable) {
            event.preventDefault();
          }
          // avoid triggering other draggable parents
          event.stopPropagation();
        })
      )
    )
      .pipe(
        switchMap((_) =>
          mouseMove$.pipe(takeUntil(mouseUp$), throttleTime(THROTTLE_DEFAULT))
        )
      )
      .subscribe((res: MouseEvent | TouchEvent) => {
        this.handleStartPan(res);
      });

    this.stopSubscription = merge(
      fromEvent(this.stopIcon.nativeElement, "touchstart"),
      fromEvent(this.stopIcon.nativeElement, "mousedown").pipe(
        tap((event: any) => {
          // avoid interference by "native" dragging of <img> tags
          if (event.target && (event.target as HTMLElement).draggable) {
            event.preventDefault();
          }
          // avoid triggering other draggable parents
          event.stopPropagation();
        })
      )
    )
      .pipe(
        switchMap((_) =>
          mouseMove$.pipe(takeUntil(mouseUp$), throttleTime(THROTTLE_DEFAULT))
        )
      )
      .subscribe((res: MouseEvent | TouchEvent) => {
        this.handleStopPan(res);
      });
  }

  private closeStreams() {
    if (this.startSubscription) {
      this.startSubscription.unsubscribe();
      this.startSubscription = null;
    }
    if (this.stopSubscription) {
      this.stopSubscription.unsubscribe();
      this.stopSubscription = null;
    }
  }

  private handleStartPan(evt: MouseEvent | TouchEvent) {
    const coords = CircularSliderComponent.extractMouseEventCoords(evt);

    this.setCircleCenter();
    const currentAngleStop =
      (this.startAngle + this.angleLength) % (2 * Math.PI);
    let newAngle =
      Math.atan2(coords.y - this.circleCenterY, coords.x - this.circleCenterX) +
      Math.PI / 2;

    if (newAngle < 0) {
      newAngle += 2 * Math.PI;
    }

    const newAngleLength = currentAngleStop - newAngle;

    this.startAngle = newAngle;
    this.angleLength = newAngleLength % (2 * Math.PI);
    this.angleInDegrees = -Math.round((this.angleLength * 180.0) / Math.PI);

    this.onUpdate();
  }

  updateBasedOnInput(value: number) {
    this.angleLength =
      value !== 360
        ? (-value * Math.PI) / 180.0
        : ((-value - 1) * Math.PI) / 180.0;
    this.setCircleCenter();
    this.onUpdate();
  }

  private handleStopPan(evt: MouseEvent | TouchEvent) {
    const coords = CircularSliderComponent.extractMouseEventCoords(evt);
    this.setCircleCenter();
    const newAngle =
      Math.atan2(coords.y - this.circleCenterY, coords.x - this.circleCenterX) +
      Math.PI / 2;

    const newAngleLength = (newAngle - this.startAngle) % (2 * Math.PI);

    this.angleLength = newAngleLength;
    this.angleInDegrees = -Math.round((this.angleLength * 180.0) / Math.PI);
    this.onUpdate();
  }

  private calcStartAndStop() {
    this.start = this.calculateArcCircle(
      0,
      this.props.segments,
      this.props.radius,
      this.startAngle,
      this.angleLength
    );
    this.stop = this.calculateArcCircle(
      this.props.segments - 1,
      this.props.segments,
      this.props.radius,
      this.startAngle,
      this.angleLength
    );
  }

  private calculateArcCircle(
    indexInput,
    segments,
    radius,
    startAngleInput = 0,
    angleLengthInput = 2 * Math.PI
  ) {
    // Add 0.0001 to the possible angle so when start = stop angle, whole circle is drawn
    const startAngle = startAngleInput % (2 * Math.PI);
    const angleLength = angleLengthInput % (2 * Math.PI);
    const index = indexInput + 1;
    const fromAngle = (angleLength / segments) * (index - 1) + startAngle;
    const toAngle = (angleLength / segments) * index + startAngle;
    const fromX = radius * Math.sin(fromAngle);
    const fromY = -radius * Math.cos(fromAngle);
    const realToX = radius * Math.sin(toAngle);
    const realToY = -radius * Math.cos(toAngle);

    // add 0.005 to start drawing a little bit earlier so segments stick together
    // const toX = radius * Math.sin(toAngle + 0.005);
    // const toY = -radius * Math.cos(toAngle + 0.005);
    const toX = radius * Math.sin(toAngle);
    const toY = -radius * Math.cos(toAngle);

    return {
      fromX,
      fromY,
      toX,
      toY,
      realToX,
      realToY,
    };
  }

  private createSegments() {
    this.segments = [];
    for (let i = 0; i < this.props.segments; i++) {
      const id = i;
      const colors: IColor = {
        fromColor: this.props.gradientColorFrom,
        toColor: this.props.gradientColorTo,
      };
      const arcs: IArc = this.calculateArcCircle(
        id,
        this.props.segments,
        this.props.radius,
        this.startAngle,
        this.angleLength
      );

      this.segments.push({
        id: id,
        d: `M ${arcs.fromX.toFixed(2)} ${arcs.fromY.toFixed(2)} A ${
          this.props.radius
        } ${this.props.radius}
        0 0 0 ${arcs.toX.toFixed(2)} ${arcs.toY.toFixed(2)}`,
        colors: Object.assign({}, colors),
        arcs: Object.assign({}, arcs),
      });
    }
  }

  private setCircleCenter() {
    // todo: nicer solution to use document.body?
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = this.circle.nativeElement.getBoundingClientRect();
    const px = elemRect.left - bodyRect.left;
    const py = elemRect.top - bodyRect.top;
    const halfOfContainer = this.getContainerWidth() / 2;
    this.circleCenterX = px + halfOfContainer;
    this.circleCenterY = py + halfOfContainer;
  }

  public getContainerWidth() {
    const { strokeWidth, radius } = this.props;
    return strokeWidth + radius * 2 + 5;
  }

  public getGradientId(index) {
    return `gradient${index}`;
  }

  public getGradientUrl(index) {
    return `url(#gradient${index})`;
  }

  public getTranslate(): string {
    return ` translate(
  ${this.props.strokeWidth / 2 + this.props.radius + 1},
  ${this.props.strokeWidth / 2 + this.props.radius + 1} )`;
  }

  public getTranslateFrom(x, y): string {
    return ` translate(${x}, ${y})`;
  }
}
