import {initialNode, PointNode} from '../../model/point-node.model';
import * as NodeAction from '../actions/node.actions';
import {MevacoPageActions} from '../actions';
import {ShapeOrigin} from '../../model/drawing.model';

export function nodeReducer(state: PointNode[] = [], action: MevacoPageActions): PointNode[] {
  let newNode: PointNode = initialNode;
  switch (action.type) {
    case NodeAction.ADD_NODE:
      newNode = action.payload;
      return [...state, newNode];
    case NodeAction.REMOVE_NODE: {
      const node: PointNode = action.payload;
      const index = state.findIndex((v) => v === node);
      if (index > -1) {
        state.splice(index, 1);
        return [...state];
      }
      break;
    }
    case NodeAction.REMOVE_ALL_TEMP_NOODES: {
      const newState = state.filter((v) => v.origin !== ShapeOrigin.TEMPORARY);
      return [...newState];
    }
  }
  return state;
}
