import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store, select } from "@ngrx/store";
import { PlateMaterialType } from "../../model";
import { Step } from "../../model/product-configuration/product-configuration.model";
import {
  ActionButton,
  ToolControlType,
  ToolProvider,
} from "../../providers/tool.provider";
import { TranslationProvider } from "../../providers/translation.provider";
import { View3dProvider } from "../../providers/view3d.provider";
import { CancelImport, SetStep } from "../../store/actions";
import { RotateImportShape } from "../../store/actions/drawing.actions";
import { MevacoState, getStep } from "../../store/reducers";
import { AttachmentImportTool } from "../../tools/editTools/import/attachment-import.tool";
import { ImportSelectTool } from "../../tools/editTools/import/import-select.tool";

@Component({
  selector: "import-modal",
  templateUrl: "./import-modal.component.html",
  styleUrls: ["./import-modal.component.css"],
})
export class ImportModalComponent implements OnInit {
  public PlateMaterialType = PlateMaterialType;
  private attachmentImportTool: AttachmentImportTool;
  private importTool: ImportSelectTool;
  private timer: any;
  private zoomSpeed = 10;
  public nextStep: ActionButton;
  public addMountings: ActionButton;
  public submitImport: ActionButton;
  public title = "";
  public description = "";
  public stepOf = "";
  public note = "";
  public Step = Step;
  public rotationAngle = 0;

  @ViewChild("confirmAttachments", { static: true }) confirmAttachments;

  constructor(
    private toolProvider: ToolProvider,
    private store: Store<MevacoState>,
    private translationProvider: TranslationProvider,
    private view3dProvider: View3dProvider,
    private modalService: NgbModal
  ) {
    this.attachmentImportTool = this.toolProvider.attachmentImportTool;
    this.importTool = this.toolProvider.importTool;
    this.store.pipe(select(getStep)).subscribe((step) => {
      switch (step) {
        case Step.import:
          this.title = this.translate("Plate Contour", "designer");
          this.stepOf = "1";
          this.description = this.translate(
            "ImportShapeDescription",
            "designer"
          );
          this.note = this.translate("ImportShapeNote", "designer");
          // 'Note: In the next step, you can select the perforation area'
          this.submitImport = {
            type: ToolControlType.actionButton,
            name: "Submit Import",
            action: this.importTool.submitImport.bind(this.importTool),
            enable: this.importTool.CanImport(),
          };
          this.nextStep = {
            type: ToolControlType.actionButton,
            name: "Import Perforation",
            action: this.importTool.nextStep.bind(this.importTool),
            enable: this.importTool.CanImport(),
          };
          this.addMountings = null;
          break;
        case Step.import_pattern:
          this.title = this.translate("Perforation Selection", "designer");
          this.stepOf = "2";
          this.description = this.translate(
            "ImportPatternDescription",
            "designer"
          );
          this.note = this.translate("ImportPatternNote", "designer");
          this.submitImport = {
            type: ToolControlType.actionButton,
            name: "Submit Import",
            action: this.importTool.submitImport.bind(this.importTool),
            enable: this.importTool.CanImport(),
          };
          this.nextStep = {
            type: ToolControlType.actionButton,
            name: "Import Attachment",
            action: this.importTool.nextStep.bind(this.importTool),
            enable: this.importTool.CanImport(),
          };
          this.addMountings = null;
          break;
        case Step.import_attachment:
          this.title = this.translate("Mountings Selection", "designer");
          this.stepOf = "3";
          this.description = this.translate(
            "ImportAttachmentsDescription",
            "designer"
          );
          this.note = this.translate("ImportAttachmentsNote", "designer");
          // this.description = 'Find similar mounting holes and mark in the mounting holes';
          // this.note = 'Note: Choose the appropriate mounting method and place it with a click in your template';
          this.addMountings = {
            type: ToolControlType.actionButton,
            name: "Add Mountings",
            action: this.attachmentImportTool.addMountings.bind(
              this.attachmentImportTool
            ),
            enable: this.attachmentImportTool.anySelectedPositionObservable,
          };
          this.submitImport = {
            type: ToolControlType.actionButton,
            name: "Submit Import",
            action: async () => {
              let result = true;
              if (this.attachmentImportTool.anySelectedPosition) {
                result = await this.modalService.open(this.confirmAttachments, {
                  centered: true,
                  backdrop: "static",
                  container: ".import-modal__container",
                }).result;
              }
              if (result) {
                this.attachmentImportTool.onConfirm();
                this.importTool.submitImport();
              }
            },
            enable: this.importTool.CanImport(),
          };
          this.nextStep = null;
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {}

  reset() {
    this.store.dispatch(new CancelImport());
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }

  zoomToFit() {
    this.view3dProvider.zoomToFitPlate();
  }

  zoomIn() {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.view3dProvider.camera.radius -= 0.001 * this.zoomSpeed;
      }, 10);
    }
  }

  zoomOut() {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.view3dProvider.camera.radius += 0.001 * this.zoomSpeed;
      }, 10);
    }
  }

  stopAction() {
    clearInterval(this.timer);
    this.timer = null;
  }

  setStep(step: Step) {
    this.store.dispatch(new SetStep(step));
  }

  rotate() {
    if (!isNaN(this.rotationAngle)) {
      this.importTool.reset();
      this.store.dispatch(
        new RotateImportShape((this.rotationAngle * Math.PI) / 180)
      );
    }
  }
}
