<div *ngIf="!option">
    <span class="option-default"
    [class.option--default]="dropdownOptionType === 'DEFAULT'"
    [class.option--error]="dropdownOptionType === 'ERROR'"
    [ngbTooltip]="labelText"
    tooltipClass="custom-tooltip custom-tooltip--option"
    >{{labelText}}</span>
</div>
<ng-container *ngIf="!!option" [ngSwitch]="option.type">
  <div *ngSwitchCase="'text'"  class="option-wrapper">
    <span
    class="option-label"
    [class.option--default]="dropdownOptionType === 'DEFAULT'"
    [class.option--error]="dropdownOptionType === 'ERROR'"
    *ngIf="option.label && selected">
    {{option.label}}:
  </span>
    <span
    class="option-text"
    [ngbTooltip]="option.data"
    tooltipClass="custom-tooltip custom-tooltip--option"
    [class.option--default]="dropdownOptionType === 'DEFAULT'"
    [class.option--error]="dropdownOptionType === 'ERROR'">
    {{option.data}}
  </span>
  </div>
  <div *ngSwitchCase="'withImage'" class="option-wrapper">
    <img class="option-img" src={{option.data.imgUrl}}>
    <span
      class="option-label"
      *ngIf="option.label && selected"
      [class.option--default]="dropdownOptionType === 'DEFAULT'"
      [class.option--error]="dropdownOptionType === 'ERROR'">
    {{option.label}}:
    </span>
    <span
      class="option-text"
      [ngbTooltip]="option.data.name"
      tooltipClass="custom-tooltip custom-tooltip--option"
      [class.option--default]="dropdownOptionType === 'DEFAULT'"
      [class.option--error]="dropdownOptionType === 'ERROR'">
      {{option.data.name}}
    </span>
  </div>
  <div *ngSwitchCase="'withSvgPath'" class="option-wrapper">
    <svg height="30" width="30" class="svg-path">
      <path [attr.d]="option.data.svgPath|async" />
    </svg>
    <span
      class="option-label"
      *ngIf="option.label && selected"
      [class.option--default]="dropdownOptionType === 'DEFAULT'"
      [class.option--error]="dropdownOptionType === 'ERROR'">
    {{option.label}}:
    </span>
    <span
      class="option-text"
      [ngbTooltip]="option.data.name"
      tooltipClass="custom-tooltip custom-tooltip--option"
      [class.option--default]="dropdownOptionType === 'DEFAULT'"
      [class.option--error]="dropdownOptionType === 'ERROR'">
      {{option.data.name}}
    </span>
  </div>
  <div class="templateDropdown" *ngSwitchCase="'templateDropdown'" class="flex space-between">
<!--      <span-->
<!--      class="option-label"-->
<!--      [class.option&#45;&#45;default]="dropdownOptionType === 'DEFAULT'"-->
<!--      [class.option&#45;&#45;error]="dropdownOptionType === 'ERROR'">-->
<!--      {{option.label}}-->
<!--      </span>-->
    <span
    class="option-text"
    [ngbTooltip]="option.data.name"
    tooltipClass="custom-tooltip custom-tooltip--option"
    [class.option--default]="dropdownOptionType === 'DEFAULT'"
    [class.option--error]="dropdownOptionType === 'ERROR'">
    {{option.data.name}}
  </span>
    <img
    class="option-img ml-auto"
    src={{option.data.imgUrl}}>
  </div>
  <div *ngSwitchCase="'textWithAnnotation'" class="option-wrapper option-wrapper--annotation">
    <div class="annotation-first-box">
      <span
      class="option-label"
      *ngIf="option.label && selected"
      [class.option--default]="dropdownOptionType === 'DEFAULT'"
      [class.option--error]="dropdownOptionType === 'ERROR'">
      {{option.label}}:
    </span>
      <span
      class="option-text"
      [ngbTooltip]="option.data.text"
      tooltipClass="custom-tooltip custom-tooltip--option"
      [class.option--default]="dropdownOptionType === 'DEFAULT'"
      [class.option--error]="dropdownOptionType === 'ERROR'">
      {{option.data.text}}
    </span>
    </div>
    <div class="annotation-second-box">
     <span
     class="option-text"
     [ngbTooltip]="option.data.annotation"
     tooltipClass="custom-tooltip custom-tooltip--option"
     [class.option--default]="dropdownOptionType === 'DEFAULT'"
     [class.option--error]="dropdownOptionType === 'ERROR'">
     {{option.data.annotation}}
    </span>
    </div>
  </div>
</ng-container>
