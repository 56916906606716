import {Action} from '@ngrx/store';
import {ProductConfiguration} from '../../model/product-configuration/product-configuration.model';
import {RuleAction} from '../../model/rule-action.model';
import {Element} from '../../model/product-configuration/element.model';
import {MinMaxRaender} from '../../model/product-configuration/min-max-raender.model';
import {ChangeStateResult} from '../../model/change-state-result';
import { SelectedDesignerEffect } from '../../model/effect.model';
import { SelectedFading } from '../../model/fading.model';
import { SelectedPerfaction, SelectedPerfactionConfig, SelectedPerfactionImageConfig } from '../../model/perfaction.model';
import { Vector2 } from 'webcad';
import {MevacoPage} from "../../model/mevaco-page.model";



export const PERFORATION_TYPE_CHANGE = 'PERFORATION_TYPE_CHANGE';

export class PerforationTypeChange implements Action {
  readonly type: string = PERFORATION_TYPE_CHANGE;

  constructor(public payload: string) {
  }
}

export const PERFORATION_GEOMETRY_CHANGE = 'PERFORATION_GEOMETRY_CHANGE';

export class PerforationGeometryChange implements Action {
  readonly type: string = PERFORATION_GEOMETRY_CHANGE;

  constructor(public payload: string) {
  }
}

export const EXPANDED_METAL_TYPE_CHANGE = 'EXPANDED_METAL_TYPE_CHANGE';

export class ExpandedMetalTypeChange implements Action {
  readonly type: string = EXPANDED_METAL_TYPE_CHANGE;

  constructor(public payload: string) {
  }
}

export const EXPANDED_METAL_SIZE_CHANGE = 'EXPANDED_METAL_SIZE_CHANGE';
export class ExpandedMetalSizeChange implements Action {
  readonly type: string = EXPANDED_METAL_SIZE_CHANGE;

  constructor(public payload: string) {
  }
}

export const PERFORATION_SHAPE_CHANGE = 'PERFORATION_SHAPE_CHANGE';

export class PerforationShapeChange implements Action {
  readonly type: string = PERFORATION_SHAPE_CHANGE;

  constructor(public payload: string) {
  }
}

export const PERFORATION_SIZE_CHANGE = 'PERFORATION_SIZE_CHANGE';

export class PerforationSizeChange implements Action {
  readonly type: string = PERFORATION_SIZE_CHANGE;

  constructor(public payload: string) {
  }
}

export const WALLPAPER_CHANGE = 'WALLPAPER_CHANGE';

export class WallpaperChange implements Action {
  readonly type: string = WALLPAPER_CHANGE;

  constructor(public payload: string) {
  }
}

export const WALLPAPER_ZOOM_UPDATE = 'WALLPAPER_ZOOM_UPDATE';

export class WallpaperZoomUpdate implements Action {
  readonly type: string = WALLPAPER_ZOOM_UPDATE;

  constructor(public payload: number) {
  }
}

export const TOGGLE_CUSTOM_PATTERN = 'TOGGLE_CUSTOM_PATTERN';
export class ToggleCustomPattern implements Action {
  readonly type: string = TOGGLE_CUSTOM_PATTERN;
  constructor() {
  }
}

export const TOGGLE_CUSTOM_PATTERN_SUCCEEDED = 'TOGGLE_CUSTOM_PATTERN_SUCCEEDED';
export class ToggleCustomPatternSucceeded implements Action {
  readonly type: string = TOGGLE_CUSTOM_PATTERN_SUCCEEDED;
  constructor(public payload: MevacoPage) {
  }
}

export const WALLPAPER_ALLOW_HOLES_UPDATE = 'WALLPAPER_ALLOW_HOLES_UPDATE';

export class WallpaperAllowHolesUpdate implements Action {
  readonly type: string = WALLPAPER_ALLOW_HOLES_UPDATE;

  constructor(public payload: number) {
  }
}

export const PERFORATION_FORMAT_CHANGE = 'PERFORATION_FORMAT_CHANGE';

export class PerforationFormatChange implements Action {
  readonly type: string = PERFORATION_FORMAT_CHANGE;

  constructor(public payload: string) {
  }
}

export const PERFORATION_POSITION_CHANGE = 'PERFORATION_POSITION_CHANGE';

export class PerforationPositionChange implements Action {
  readonly type: string = PERFORATION_POSITION_CHANGE;

  constructor(public payload: string) {
  }
}


export const PRODUCT_CONFIGURATION_LOAD = 'PRODUCT_CONFIGURATION_LOAD';

export class ProductConfigurationLoad implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_LOAD;

  constructor(public payload: string) {
  }
}

export const PRODUCT_CONFIGURATION_LOADED_SUCCESSFULLY = 'PRODUCT_CONFIGURATION_LOADED_SUCCESSFULLY';

export class ProductConfigurationLoadedSuccessfully implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_LOADED_SUCCESSFULLY;

  constructor(public payload: ProductConfiguration) {
  }
}
/*
export const PRODUCT_CONFIGURATION_SAVE = 'PRODUCT_CONFIGURATION_SAVE';

export class ProductConfigurationSave implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SAVE;

  constructor(public payload: ProductConfiguration) {
  }
}
*/
export const PRODUCT_CONFIGURATION_SAVED_SUCCESSFULLY = 'PRODUCT_CONFIGURATION_SAVED_SUCCESSFULLY';

export class ProductConfigurationSavedSuccessfully implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SAVED_SUCCESSFULLY;

  constructor(public payload: ChangeStateResult, public updateHash: (configuration: ProductConfiguration) => void) {
  }
}

export const PRODUCT_CONFIGURATION_SAVE_SKIPPED = 'PRODUCT_CONFIGURATION_SAVE_SKIPPED';
export class ProductConfigurationSaveSkipped implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SAVE_SKIPPED;

  constructor() {
  }
}

export const PRODUCT_CONFIGURATION_VALIDATED_SUCCESSFULLY = 'PRODUCT_CONFIGURATION_VALIDATED_SUCCESSFULLY';
export class ProductConfigurationValidatedSuccessfully implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_VALIDATED_SUCCESSFULLY;

  constructor(public payload: ChangeStateResult, public updateHash: (configuration: ProductConfiguration) => void) {
  }
}

export const PRODUCT_CONFIGURATION_ADD_ELEMENT = 'PRODUCT_CONFIGURATION_ADD_ELEMENT';

export class ProductConfigurationAddElement implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_ADD_ELEMENT;

  constructor(public payload: Element) {
  }
}

export const PRODUCT_CONFIGURATION_ONLY_ELEMENT = 'PRODUCT_CONFIGURATION_ONLY_ELEMENT';
export class ProductConfigurationSetOnlyElement implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_ONLY_ELEMENT;

  constructor(public payload: Element) {
  }
}

export const PRODUCT_TYPE_CHANGE = "PRODUCT TYPE CHANGE"

export class ProductTypeChange implements Action{
   readonly type = PRODUCT_TYPE_CHANGE;
  constructor(public payload: number){}
}

export const PRODUCT_CONFIGURATION_REMOVE_ELEMENT = 'PRODUCT_CONFIGURATION_REMOVE_ELEMENT';

export class ProductConfigurationRemoveElement implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_REMOVE_ELEMENT;

  constructor(public payload: number) {
  }
}

export const PRODUCT_CONFIGURATION_REMOVE_ALL_ELEMENTS = 'PRODUCT_CONFIGURATION_REMOVE_ALL_ELEMENTS';
export class ProductConfigurationRemoveAllElements implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_REMOVE_ALL_ELEMENTS;

  constructor() {
  }
}

export const PRODUCT_CONFIGURATION_EDIT_ELEMENT = 'PRODUCT_CONFIGURATION_EDIT_ELEMENT';

export class ProductConfigurationEditElement implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_EDIT_ELEMENT;

  constructor(public payload: number) {
  }
}

export const SHOW_VIEWER_ELEMENT = 'SHOW_VIEWER_ELEMENT';

export class ShowViewerElement implements Action {
  readonly type: string = SHOW_VIEWER_ELEMENT;

  constructor(public payload: number) {
  }
}

export const PRODUCT_CONFIGURATION_SET_P1 = 'PRODUCT_CONFIGURATION_SET_P1';

export class ProductConfigurationSetP1 implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SET_P1;

  constructor(public payload: string) {
  }
}

export const PRODUCT_CONFIGURATION_SET_P2 = 'PRODUCT_CONFIGURATION_SET_P2';

export class ProductConfigurationSetP2 implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SET_P2;

  constructor(public payload: string) {
  }
}

export const PRODUCT_CONFIGURATION_SET_MATERIAL = 'PRODUCT_CONFIGURATION_SET_MATERIAL';

export class ProductConfigurationSetMaterial implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SET_MATERIAL;

  constructor(public payload: string) {
  }
}

export const SET_MIN_MAX_REANDER = 'SET_MIN_MAX_REANDER';

export class SetMinMaxReander implements Action {
  readonly type: string = SET_MIN_MAX_REANDER;

  constructor(public payload: MinMaxRaender) {

  }
}

export const PRODUCT_CONFIGURATION_SET_MATERIAL_THICKNESS = 'PRODUCT_CONFIGURATION_SET_MATERIAL_THICKNESS';

export class ProductConfigurationSetMaterialThickness implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SET_MATERIAL_THICKNESS;

  constructor(public payload: string) {
  }
}

export const PRODUCT_CONFIGURATION_SET_FOIL = 'PRODUCT_CONFIGURATION_SET_FOIL';

export class ProductConfigurationSetFoil implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SET_FOIL;

  constructor(public payload: string) {
  }
}

export const PRODUCT_CONFIGURATION_SET_QUALITY = 'PRODUCT_CONFIGURATION_SET_QUALITY';

export class ProductConfigurationSetQuality implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SET_QUALITY;

  constructor(public payload: string) {
  }
}


export const PRODUCT_CONFIGURATION_SET_SURFACE_TYPE = 'PRODUCT_CONFIGURATION_SET_SURFACE_TYPE';

export class ProductConfigurationSetSurfaceType implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SET_SURFACE_TYPE;

  constructor(public payload: string) {
  }
}

export const PRODUCT_CONFIGURATION_SET_POLISHING = 'PRODUCT_CONFIGURATION_SET_POLISHING';

export class ProductConfigurationSetPolishing implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SET_POLISHING;

  constructor(public payload: string) {
  }
}

export const PRODUCT_CONFIGURATION_SET_COLOR_SYSTEM = 'PRODUCT_CONFIGURATION_SET_COLOR_SYSTEM';

export class ProductConfigurationSetColorSystem implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SET_COLOR_SYSTEM;

  constructor(public payload: string) {
  }
}

export const PRODUCT_CONFIGURATION_SET_COLOR_GROUP = 'PRODUCT_CONFIGURATION_SET_COLOR_GROUP';

export class ProductConfigurationSetColorGroup implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SET_COLOR_GROUP;

  constructor(public payload: string) {
  }
}

export const PRODUCT_CONFIGURATION_SET_COLOR = 'PRODUCT_CONFIGURATION_SET_COLOR';

export class ProductConfigurationSetColor implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SET_COLOR;

  constructor(public payload: string) {
  }
}

export const PRODUCT_CONFIGURATION_SET_UNDERCOATING = 'PRODUCT_CONFIGURATION_SET_UNDERCOATING';

export class ProductConfigurationSetUndercoating implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SET_UNDERCOATING;

  constructor(public payload: string) {
  }
}

export const PRODUCT_CONFIGURATION_SET_LEVEL_OF_GLOSS = 'PRODUCT_CONFIGURATION_SET_LEVEL_OF_GLOSS';

export class ProductConfigurationSetLevelOfGloss implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SET_LEVEL_OF_GLOSS;

  constructor(public payload: string) {
  }
}

export const SET_NEED_PREVIEW_UPDATE = 'SET NEED PREVIEW UPDATE';

export class SetNeedPreviewUpdate implements Action {
  readonly type: string = SET_NEED_PREVIEW_UPDATE;

  constructor(public payload: { id: number, needUpdate: boolean }) {
  }

}

export const PREVIEW_SUCCESS = 'PREVIEW_SUCCESS';

export class PreviewSuccess implements Action {
  readonly type: string = PREVIEW_SUCCESS;

  constructor(public payload: number) {

  }

}

export const SET_PREVIEW_IMAGE_ID = 'SET PREVIEW IMAGE ID';

export class SetPreviewImageId implements Action {
  readonly type: string = SET_PREVIEW_IMAGE_ID;

  constructor(public payload: { elementId: number, imageId: number }) {
  }
}

export const SET_QUANTITY = 'SET QUANTITY';

export class SetQuantity implements Action {
  readonly type: string = SET_QUANTITY;

  constructor(public payload: { elementId: number, quantity: number }) {

  }
}


export const SET_NOTE = 'SET NOTE';

export class SetNote implements Action {
  readonly type: string = SET_NOTE;

  constructor(public payload: { elementId: number, note: string }) {

  }
}

export const CLEAR_ALL_ELEMENTS = 'Clear All Elements';

export class ClearAllElements implements Action {
  readonly type: string = CLEAR_ALL_ELEMENTS;

  constructor(public payload: any = null) {
  }
}

export const OPEN_ELEMENT_CREATOR = 'OPEN_ELEMENT_CREATOR';

export class OpenElementCreator implements Action {
  readonly type: string = OPEN_ELEMENT_CREATOR;

  constructor(public payload: any) {
  }
}

export const UNDO_STATE = 'Undo State';

export class UndoState implements Action {
  readonly type: string = UNDO_STATE;

  constructor(public payload?: any) {
  }
}

export const REDO_STATE = 'Redo State';

export class RedoState implements Action {
  readonly type: string = REDO_STATE;

  constructor(public payload?: any) {
  }
}

export const UNDO_REDO_SUCCESS = 'Undo Redo Success';

export class UndoRedoSucess implements Action {
  readonly type: string = UNDO_REDO_SUCCESS;

  constructor(public payload: ProductConfiguration) {
  }
}

// export const SET_DEPENDENT_MODE = 'SET_DEPENDENT_MODE';
//
// export class SetDependentMode implements Action {
//   readonly type: string = SET_DEPENDENT_MODE;
//
//   constructor(public payload: boolean) {
//   }
// }

export const SET_PERFORATION_AUTO_CENTER = 'SET_PERFORATION_AUTO_CENTER';

export class SetPerforationAutoCenter implements Action {
  readonly type: string = SET_PERFORATION_AUTO_CENTER;

  constructor(public payload: boolean) {
  }
}

export const SET_NOTE_ACTIVE = 'SET_NOTE_ACTIVE';

export class SetNoteActive implements Action {
  readonly type: string = SET_NOTE_ACTIVE;

  constructor(public payload: boolean) {
  }
}

export const DUPLICATE_CONFIGURATION = 'DUPLICATE CONFIGURATION';

export class DuplicateConfiguration implements Action {
  readonly type: string = DUPLICATE_CONFIGURATION;

  constructor(public payload: string) {

  }
}

export const DUPLICATE_CONFIGURATION_SUCCESS = 'DUPLICATE CONFIGURATION SUCCESS';

export class DuplicateConfigurationSuccess implements Action {
  readonly type: string = DUPLICATE_CONFIGURATION_SUCCESS;

  constructor(public payload: ProductConfiguration) {

  }
}


// export const SET_PERFORATION_ROTATION = 'SET_PERFORATION_ROTATION';
//
// export class ProductConfigurationSetPerforationRotation implements Action {
//   readonly type: string = SET_PERFORATION_ROTATION;
//
//   constructor(public payload: number) {
//   }
// }

export const COPY_POSITION = 'COPY POSITION';

export class CopyPosition implements Action {
  readonly type: string = COPY_POSITION;

  constructor(public payload: number) {

  }
}

export const SET_PERFACTION_CONFIG = 'SET_PERFACTION_CONFIG'

export class SetPerfactionConfig implements Action {
  readonly type: string = SET_PERFACTION_CONFIG;

  constructor(public payload: {
    brightness?: number;
    inverted?: boolean;
  }) {

  }
}

export const SET_PERFACTION_IMAGE_CONFIG = 'SET_PERFACTION_IMAGE_CONFIG'

export class SetPerfactionImageConfig implements Action {
  readonly type: string = SET_PERFACTION_IMAGE_CONFIG;

  constructor(public payload: SelectedPerfactionImageConfig) {

  }
}

export const DELETE_PERFACTION_IMAGE = 'DELETE_PERFACTION_IMAGE'

export class DeletePerfactionImage implements Action {
  readonly type: string = DELETE_PERFACTION_IMAGE;

  constructor(public payload: {id: number}) {

  }
}

export const DELETE_PERFACTION_IMAGE_SUCCESSFULLY = 'DELETE_PERFACTION_IMAGE_SUCCESSFULLY'

export class DeletePerfactionImageSuccessfully implements Action {
  readonly type: string = DELETE_PERFACTION_IMAGE_SUCCESSFULLY;

  constructor(public payload: {message: string}) {

  }
}

export const GET_PERFACTION_IMAGE = 'GET_PERFACTION_IMAGE'

export class GetPerfactionImage implements Action {
  readonly type: string = GET_PERFACTION_IMAGE;

  constructor(public payload: {id: number}) {

  }
}

export const GET_PERFACTION_IMAGE_SUCCESSFULLY = 'GET_PERFACTION_IMAGE_SUCCESSFULLY'

export class GetPerfactionImageSuccessfully implements Action {
  readonly type: string = GET_PERFACTION_IMAGE_SUCCESSFULLY;

  constructor(public payload: any) {

  }
}

export const SET_PERFACTION_IMAGE_ID = 'SET_PERFACTION_IMAGE_ID'

export class SetPerfactionImageId implements Action {
  readonly type: string = SET_PERFACTION_IMAGE_ID;

  constructor(public payload: {id: number}) {

  }
}

export const SET_FADINGS = 'SET_FADINGS';

export class SetFadings implements Action {
  readonly type: string = SET_FADINGS;

  constructor(public payload: SelectedFading) {

  }
}

export const SET_EFFECTS = 'SET_EFFECTS';

export class SetEffect implements Action {
  readonly type: string = SET_EFFECTS;

  constructor(public payload: SelectedDesignerEffect) {

  }
}

export const COPY_POSITION_SUCCESS = 'COPY POSITION_SUCCESS';

export class CopyPositionSuccess implements Action {
  readonly type: string = COPY_POSITION_SUCCESS;

  constructor(public payload: Element[]) {

  }
}

export const SET_CONFIG_HEAD_NOTE = 'COPY SET_CONFIG_HEAD_NOTE';

export class SetConfigHeadNote implements Action {
  readonly type: string = COPY_POSITION_SUCCESS;

  constructor(public payload: Element[]) {

  }
}

export const ADD_OFFSET_TO_PLATE_PEFRACTION = 'ADD_OFFSET_TO_PLATE_PEFRACTION';

export class AddOffsetToPlatePerfaction implements Action {
  readonly type: string = ADD_OFFSET_TO_PLATE_PEFRACTION;

  constructor(public payload: {plateIndex: number, offset: Vector2}) {
  }
}

export const PRODUCT_CONFIGURATION_SET_PERFACTION_FORM = 'PRODUCT_CONFIGURATION_SET_PERFACTION_FORM';

export class ProductConfigurationSetPerfactionForm implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SET_PERFACTION_FORM;

  constructor(public payload: string) {
  }
}

export const PRODUCT_CONFIGURATION_SET_PERFACTION_LABEL = 'PRODUCT_CONFIGURATION_SET_PERFACTION_LABEL';
export class ProductConfigurationSetPerfactionLabel implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SET_PERFACTION_LABEL;

  constructor(public payload: string) {
  }
}

export const PRODUCT_CONFIGURATION_SET_PERFACTION_FORMAT = 'PRODUCT_CONFIGURATION_SET_PERFACTION_FORMAT';
export class ProductConfigurationSetPerfactionFormat implements Action {
  readonly type: string = PRODUCT_CONFIGURATION_SET_PERFACTION_FORMAT;

  constructor(public payload: string) {
  }
}


export const PRODUCT_CONFIGURATION_SET_PERFACTION_SIZES = 'PRODUCT_CONFIGURATION_SET_PERFACTION_SIZES';

export class ProductConfigurationSetPerfactionSizes implements Action {
    readonly type: string = PRODUCT_CONFIGURATION_SET_PERFACTION_SIZES;

  constructor(public payload: string[]) {
  }
}

export const SET_STEGCLUSTER = 'SET_STEGCLUSTER';

export class SetStegcluster implements Action {
  readonly type: string = SET_STEGCLUSTER;
  constructor(public payload: string) {
  }
}

export const SET_FEEDRATE = 'SET_FEEDRATE';
export class SetFeedrate implements Action {
  readonly type: string = SET_FEEDRATE;
  constructor(public payload: string) {
  }
}

export const SET_DIVING_DEPTH = 'SET_DIVING_DEPTH';
export class SetDivingDepth implements Action {
  readonly type: string = SET_DIVING_DEPTH;
  constructor(public payload: string) {
  }
}



export type ProductConfigurationActions =
  PerforationGeometryChange |
  PerforationShapeChange |
  PerforationSizeChange |
  ProductConfigurationLoad |
  ProductConfigurationLoadedSuccessfully |
  ProductConfigurationSavedSuccessfully |
  PerforationFormatChange |
  ProductConfigurationAddElement |
  ProductConfigurationRemoveElement |
  ProductConfigurationEditElement |
  ProductConfigurationSetP1 |
  ProductConfigurationSetP2 |
  PerforationPositionChange |
  ProductConfigurationSetMaterial |
  ProductConfigurationSetMaterialThickness |
  ProductConfigurationSetQuality |
  ProductConfigurationSetSurfaceType |
  ProductConfigurationSetPolishing |
  ProductConfigurationSetColorSystem |
  ProductConfigurationSetColorGroup |
  ProductConfigurationSetColor |
  SetNoteActive |
  ProductConfigurationSetUndercoating |
  ProductConfigurationSetLevelOfGloss |
  SetNeedPreviewUpdate |
  SetPreviewImageId |
  ClearAllElements |
  OpenElementCreator |
  UndoState |
  RedoState |
  UndoRedoSucess |
  CopyPosition |
  SetMinMaxReander |
  SetEffect |
  SetFadings |
  SetPerfactionConfig |
  SetPerfactionImageConfig |
  SetPerfactionImageId |
  DeletePerfactionImage |
  DeletePerfactionImageSuccessfully;
