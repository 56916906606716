import { Injectable } from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import { Action, Store, createSelector, select } from "@ngrx/store";
import { Observable, of } from "rxjs";
import {map, switchMap, withLatestFrom} from "rxjs/operators";
import { PerforationService } from "../../services/perforation.service";
import {
  ClearPerforation,
  SetPerforationSuccess,
} from "../actions/perforation.actions";

import { multiplyVector2byScalar } from "webcad/math";
import {
  stringToFormat,
  stringToSize,
} from "../../model/dataset/perforation-format.model";
import { stampIsReady } from "../../model/product-configuration/perforation-custom-geom";
import {
  MevacoState,
  customPatternEditorIsOpened,
  getActiveElementPerforationAreas,
  getActiveElementPerforationAreasSimpleRectangle,
  getActiveElementPerforationAutoCenter,
  getActiveElementPerforationOffset,
  getActiveElementUuid,
  getEffectInputsSelector,
  getFadingInputsSelector,
  getIsCustomPerforation,
  getMultiSizePatternSelector,
  getPerfactionInputsNotNullSelector,
  getPerforationCustomGeom,
  getPerforationDependentMode,
  getPerforationFormat,
  getPerforationPosition,
  getPerforationSize,
  getPerforationType,
  getProductPerforationType,
  getStep,
  getWallpaperHalfHols,
  getWallpaperName,
  getWallpaperZoom,
  perforationPositionCanBeSelected,
} from "../reducers";
import {
  RESET_CUSTOM_PATTERN,
  ResetShowCustomPatternSUCCEEDED,
  TOGGLE_CUSTOM_PATTERN,
  ToggleCustomPatternSucceeded
} from "../actions";

@Injectable()
export class PerforationEffects {
  constructor(
    private actions: Actions,
    private perforationService: PerforationService,
    private store: Store<MevacoState>
  ) {}

  updatePerforation: Observable<Action> = createEffect(() =>
    this.store.pipe(
      select(
        createSelector(
          getProductPerforationType,
          getIsCustomPerforation,
          getStep,
          createSelector(
            getActiveElementPerforationAreas,
            getActiveElementUuid,
            getActiveElementPerforationAreasSimpleRectangle,
            getActiveElementPerforationOffset,
            (
              activeElementPerforationAreas,
              activeElementUuid,
              perforationAreasSimpleRectangle,
              perforationOffset
            ) => ({
              activeElementPerforationAreas,
              activeElementUuid,
              perforationAreasSimpleRectangle,
              perforationOffset,
            })
          ),
          createSelector(
            getPerforationType,
            getPerforationFormat,
            getPerforationSize,
            getPerforationPosition,
            createSelector(
              getEffectInputsSelector,
              getFadingInputsSelector,
              getPerfactionInputsNotNullSelector,
              getMultiSizePatternSelector,
              (effect, fading, perfaction, multiSizePattern) => ({
                effect,
                fading,
                perfaction,
                multiSizePattern,
              })
            ),
            getPerforationDependentMode,
            getActiveElementPerforationAutoCenter,
            perforationPositionCanBeSelected,
            (
              type,
              format,
              size,
              pos,
              randomization,
              dependentMode,
              autoCenter,
              posCanBeSelected
            ) => ({
              type,
              format,
              size,
              pos,
              randomization,
              dependentMode,
              autoCenter,
              posCanBeSelected,
            })
          ),
          createSelector(
            getPerforationCustomGeom,
            customPatternEditorIsOpened,
            (perforationCustomGeom, customPatternEditorOpened) => ({
              perforationCustomGeom,
              customPatternEditorOpened,
            })
          ),
          createSelector(
            getWallpaperName,
            getWallpaperZoom,
            getWallpaperHalfHols,
            (name, zoom, halfHoles) => ({ name, zoom, halfHoles })
          ),
          (
            activePerforation,
            isCustomPerforation,
            step,
            activeElement,
            perforation,
            custom,
            wallpaper
          ) => ({
            activePerforation,
            isCustomPerforation,
            step,
            activeElement,
            perforation,
            custom,
            wallpaper,
          })
        )
      ),
      switchMap((selection) => {
        if (selection.activePerforation === "wallpaper") {
          if (
            !!selection.wallpaper.name &&
            selection.activeElement.activeElementPerforationAreas &&
            selection.activeElement.activeElementPerforationAreas.length > 0
          ) {
            return this.perforationService
              .setWallpaper({
                perforationAreas: mapPerforationArea(
                  selection.activeElement.activeElementPerforationAreas
                ),
                name: selection.wallpaper.name,
                zoom: selection.wallpaper.zoom,
                halfHoles: selection.wallpaper.halfHoles,
              })
              .pipe(
                map((perforation) => {
                  console.log(1, { perforation });
                  return new SetPerforationSuccess({
                    perforation,
                    elementUuid: selection.activeElement.activeElementUuid,
                  });
                })
              );
          }
        } else if (
          selection.activePerforation === "classic" &&
          !selection.isCustomPerforation
        ) {
          if (
            selection.activeElement.activeElementPerforationAreas &&
            selection.activeElement.activeElementPerforationAreas.length > 0 &&
            selection.perforation.format !== "" &&
            selection.perforation.size !== ""
          ) {
            const size = stringToSize(selection.perforation.size);
            const format = stringToFormat(selection.perforation.format);
            let pos: number =
              selection.perforation.pos == "" ||
              isNaN(+selection.perforation.pos)
                ? null
                : +selection.perforation.pos;
            if (isNaN(pos)) {
              pos = null;
            }
            const predefined = {
              dependentMode: selection.perforation.dependentMode,
              pattern: selection.perforation.type,
              w: size.width / 1000,
              l: size.length / 1000,
              p1: format.p1 / 1000,
              p2: format.p2 / 1000,
              pos,
              posCanBeSelected: selection.perforation.posCanBeSelected,
            };

            const perforationAreas = mapPerforationArea(
              selection.activeElement.activeElementPerforationAreas
            );

            return this.perforationService
              .setPerforation({
                predefined,
                custom: null,
                multiSizePattern: null,
                perforationAreas,
                perfAreasAreSimpleRectangles:
                  selection.activeElement.perforationAreasSimpleRectangle,
                autoCenter: !!selection.perforation.autoCenter,
                effectParams: selection.perforation.randomization.effect,
                fadingParams: selection.perforation.randomization.fading,
                perfactionParams:
                  selection.perforation.randomization.perfaction,
                perforationOffset: selection.activeElement.perforationOffset,
              })
              .pipe(
                map((perforation) => {
                  console.log(2, { perforation });
                  return new SetPerforationSuccess({
                    perforation,
                    elementUuid: selection.activeElement.activeElementUuid,
                  });
                })
              );
          }
        } else if (selection.activePerforation === "perfaction") {
          if (
            selection.activeElement.activeElementPerforationAreas &&
            selection.activeElement.activeElementPerforationAreas.length > 0 &&
            selection.perforation.randomization.multiSizePattern
          ) {
            const perforationAreas = mapPerforationArea(
              selection.activeElement.activeElementPerforationAreas
            );

            return this.perforationService
              .setPerforation({
                predefined: null,
                custom: null,
                multiSizePattern:
                  selection.perforation.randomization.multiSizePattern,
                perforationAreas,
                perfAreasAreSimpleRectangles:
                  selection.activeElement.perforationAreasSimpleRectangle,
                autoCenter: !!selection.perforation.autoCenter,
                effectParams: selection.perforation.randomization.effect,
                fadingParams: selection.perforation.randomization.fading,
                perfactionParams:
                  selection.perforation.randomization.perfaction,
                perforationOffset: selection.activeElement.perforationOffset,
              })
              .pipe(
                map((perforation) => {
                  console.log(3, { perforation });
                  return new SetPerforationSuccess({
                    perforation,
                    elementUuid: selection.activeElement.activeElementUuid,
                  });
                })
              );
          }
        } else if (
          selection.activePerforation === "classic" &&
          selection.isCustomPerforation &&
          (selection.custom.customPatternEditorOpened ||
            selection.activeElement.activeElementPerforationAreas)
        ) {
          const customGeom = selection.custom.perforationCustomGeom;
          const usableStamps = customGeom.stumps.filter(
            (stamp) =>
              !!stamp &&
              stampIsReady(stamp) &&
              stamp.offsetDivX &&
              stamp.offsetDivY &&
              customGeom.offsetX &&
              customGeom.offsetY
          );
          if (usableStamps.length > 0) {
            const custom = {
              effectParams: selection.perforation.randomization.effect,
              fadingParams: selection.perforation.randomization.fading,
              dependentMode: selection.perforation.dependentMode,
              stamps: usableStamps.map((stamp) => ({
                w: stamp.w / 1000,
                l: stamp.l / 1000,
                shape: stamp.form,
                rotation: stamp.rotation,
                offsetX: customGeom.offsetX / stamp.offsetDivX,
                offsetY: customGeom.offsetY / stamp.offsetDivY,
                startX: stamp.startX,
                startY: stamp.startY,
                endX: stamp.endX,
                endY: stamp.endY,
              })),
            };

            if (selection.custom.customPatternEditorOpened) {
              return this.perforationService
                .customPerforationPreview(custom)
                .pipe(
                  map((perforation) => {
                    console.log(4, { perforation });
                    return new SetPerforationSuccess({
                      perforation,
                      elementUuid: selection.activeElement.activeElementUuid,
                    });
                  })
                );
            } else {
              const perforationAreas = mapPerforationArea(
                selection.activeElement.activeElementPerforationAreas
              );
              return this.perforationService
                .setPerforation({
                  predefined: null,
                  custom,
                  perforationAreas,
                  multiSizePattern: null,
                  perfAreasAreSimpleRectangles:
                    selection.activeElement.perforationAreasSimpleRectangle,
                  autoCenter: !!selection.perforation.autoCenter,
                  effectParams: selection.perforation.randomization.effect,
                  fadingParams: selection.perforation.randomization.fading,
                  perfactionParams:
                    selection.perforation.randomization.perfaction,
                  perforationOffset: selection.activeElement.perforationOffset,
                })
                .pipe(
                  map((perforation) => {
                    console.log(5, { perforation });
                    return new SetPerforationSuccess({
                      perforation,
                      elementUuid: selection.activeElement.activeElementUuid,
                    });
                  })
                );
            }
          }
        }
        return of(new ClearPerforation());
      })
    )
  );

  toggleCustomPattern: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(TOGGLE_CUSTOM_PATTERN),
      withLatestFrom(this.store),
      switchMap(([action, store]) => {
          const state = store.model;
          const perforation = state.productConfiguration.perforation;
          const customPatternEditorOpened = state.customPatternEditorOpened;
          const previousValue = state.productConfiguration.customPerforation;
          if (!previousValue && (!perforation.custom || !perforation.custom.stumps.some(stamp => !!stamp))) {
            return this.perforationService.getCustomPerforationFromClassic(
              perforation.perforationType,
              perforation.size,
              perforation.format,
              perforation.position
            ).pipe(map(custom => new ToggleCustomPatternSucceeded({
              ...state,
              productConfiguration: {
                ...state.productConfiguration,
                perforation: {
                  ...state.productConfiguration.perforation,
                  custom
                },
                customPerforation: !previousValue,
              },
              customPatternEditorOpened
            })))
          } else {
            return of(new ToggleCustomPatternSucceeded({
              ...state,
              productConfiguration: {
                ...state.productConfiguration,
                customPerforation: !previousValue,
              },
              customPatternEditorOpened

            }));
          }
        }
      )
    )
  );

  resetCustomPattern: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(RESET_CUSTOM_PATTERN),
      withLatestFrom(this.store),
      switchMap(([action, store]) => {
          const state = store.model;
          const perforation = state.productConfiguration.perforation;
          return this.perforationService.getCustomPerforationFromClassic(
            perforation.perforationType,
            perforation.size,
            perforation.format,
            perforation.position
          ).pipe(map(custom => new ResetShowCustomPatternSUCCEEDED(custom)));
        }
      )
    )
  );
}

function mapPerforationArea(perforationAreas) {
  return perforationAreas.map((x) => {
    return {
      shape: x.shape,
      offset: multiplyVector2byScalar(x.offset, 0.001),
      rotation: (x.rotation * Math.PI) / 180,
    };
  });
}
