import {addVectors2, addVectors3, multiplyVector3byScalar, sqrDistanceVector2, Vector3} from '../math';
import {InputDataExchangeModel} from './input-html/input2d.model';

export interface MeasurementModel {
    editable: boolean;
    start: Vector3;
    measurementDirection: Vector3; //must be normalized!
    direction: Vector3;
    distance: number;
    maxValue: number;
    focusable?: boolean;
    visible: boolean;
    exchange: InputDataExchangeModel<number>;
    color?: Vector3;
    mask:number;
}

export function getMeasurementEndPoint(m: MeasurementModel): Vector3 {
    return addVectors3(m.start, multiplyVector3byScalar(m.measurementDirection, m.exchange.value));
}

export const initialMeasurementModel: MeasurementModel = {
    start: {x: 0, y: 0, z: 0},
    measurementDirection: {x: 1, y: 0, z: 0},
    direction: {x: 0, y: 0, z: 1},
    maxValue: 1,
    distance: 0,
    focusable: true,
    visible: false,
    exchange: null,
    editable: true,
    mask: 0x0000001
};

export function measurementsEqual(m1: MeasurementModel, m2: MeasurementModel) {
    const delta = 0.0001 * 0.0001;
    const m1End = addVectors2(m1.start, m1.direction);
    const m2End = addVectors2(m2.start, m2.direction);
    return (m1 === m2) || (sqrDistanceVector2(m1.start, m2.start) <= delta && sqrDistanceVector2(m1End, m2End) <= delta);
}