import {UserTemplate} from '../../model/user-template.model';
import {
  ADD_USER_TEMPLATE_SUCCESS, AddUserTemplateSuccess,
  DELETE_USER_TEMPLATE_SUCCESS,
  GET_USER_TEMPLATES_SUCCESS,
  GetUserTemplesSuccess,
  UserTemplateActions
} from '../actions/user-template.actions';


export function userTemplatesReducer(state: UserTemplate[] = [], action: UserTemplateActions): UserTemplate[] {
  switch (action.type) {
    case GET_USER_TEMPLATES_SUCCESS:
      return (<GetUserTemplesSuccess>action).payload;
    case DELETE_USER_TEMPLATE_SUCCESS: {
      const id = state.findIndex((v) => v.id === action.payload);
      if (id > -1) {
        const newState = state.splice(id, 1);
        return [...state];
      }
      break;
    }
    case ADD_USER_TEMPLATE_SUCCESS: {
      return [...state, (<AddUserTemplateSuccess>action).payload];
    }
  }
  return state;
}
