<div class="slider__container">
  <label class="slider__label" *ngIf="isLabel">
    <ng-container
      [ngTemplateOutlet]="customLabelText"
      *ngIf="customLabelText; else labelTextBlock"
    >
    </ng-container>
    <ng-template #labelTextBlock>
      <span *ngIf="labelText">
        {{ labelText }}
      </span>
    </ng-template>
    <input
      *ngIf="isLabelInput"
      [value]="inputValue"
      (input)="inputValueChanged($event)"
      #labelInput
      type="number"
      class="slider__input"
      [min]="options.floor"
      [max]="options.ceil"
    />
  </label>
  <ngx-slider
    class="custom-ngx-slider"
    [formControl]="sliderControl"
    [value]="sliderControl.value"
    [options]="options"
    (userChangeStart)="onUserChangeStart($event)"
    (userChange)="onUserChange($event)"
    (userChangeEnd)="onUserChangeEnd($event)"
    (valueChange)="onValueChange($event)"
  >
  </ngx-slider>
</div>
