import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store, select } from "@ngrx/store";
import { Options } from "ngx-slider-v2";
import { filter, take } from "rxjs/operators";
import { TranslationProvider } from "../../../providers/translation.provider";
import { PerfactionService } from "../../../services/perfaction.service";
import {
  DeletePerfactionImage,
  GetPerfactionImage,
  MevacoState,
  SetPerfactionImageConfig,
  getPerfactionImage,
  getPerfactionInputs,
  getPerfactionPictureSize,
} from "../../../store";
import { UploadPerfactionFile } from "../../../store/actions/perfaction.actions";
import { SliderControl } from "../../slider/slider.control";
import { PerfactionPlatesComponent } from "../perfaction-plates/perfaction-plates.component";

declare const gsap: any;
declare const Draggable: any;

//const divider = 100;

@Component({
  selector: "app-perfaction-image-panel",
  templateUrl: "./perfaction-image-panel.component.html",
  styleUrls: ["./perfaction-image-panel.component.css"],
})
export class PerfactionImagePanelComponent implements OnInit, AfterViewInit {
  @ViewChild("fileChosen") uploadLink: ElementRef;
  @ViewChild("dragArea") dragArea: ElementRef;
  @ViewChildren("imgd") set svgQueryList(ref: QueryList<any>) {
    const svg = ref.first.nativeElement.children[0];
    this.init(svg);
  }

  @ViewChildren("svgImg") set svgImgQueryList(ref: QueryList<any>) {
    this.img = ref.first;
  }

  @ViewChild(PerfactionPlatesComponent) plates: PerfactionPlatesComponent;

  svg: any;
  proxy: HTMLDivElement;
  startClient: any;
  startGlobal: any;
  point: any;
  viewBox: any;
  zoom: { animation: any; scaleFactor: number; duration: number; ease: string };
  pannable: any;
  img: any;
  imgInitialBoundingClientRect: { height: number; width: number };
  fileName: string;
  private dragTarget: any;
  private imageHeight: number = 1000;

  pixelsPerCmControl = new SliderControl(
    this.store.pipe(select(getPerfactionPictureSize)),
    (_) => this.updatePerfactionImageConfig()
  );

  sliderOneOptions: Options = {
    floor: 1,
    ceil: 20,
    step: 0.1,
    showSelectionBarFromValue: 0,
  };
  imagePreview: string;
  rotationValue = 0;

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    let wheelDelta = 10;
    let deltaY = 12;

    if (event.key === "-" || event.key === "_") {
      wheelDelta *= -1;
      deltaY *= -1;
      this.onWheel({
        clientX: this.point.x,
        clientY: this.point.y,
        detail: 0,
        wheelDelta: wheelDelta,
        deltaY: deltaY,
      });
    } else if (event.key === "+" || event.key === "=") {
      this.onWheel({
        clientX: this.point.x,
        clientY: this.point.y,
        detail: 0,
        wheelDelta: wheelDelta,
        deltaY: deltaY,
      });
    }
  }

  constructor(
    private translationProvider: TranslationProvider,
    private store: Store<MevacoState>,
    private renderer: Renderer2,
    private perfactionServcie: PerfactionService,
    private modalService: NgbModal
  ) {}
  ngAfterViewInit(): void {
  }

  // based on https://codepen.io/osublake/pen/oGoyYb
  init(svg: any) {
    this.svg = svg;
    this.proxy = document.createElement("div");
    this.startClient = this.svg.createSVGPoint();
    this.startGlobal = this.svg.createSVGPoint();
    this.point = this.svg.createSVGPoint();
    this.viewBox = this.svg.viewBox.baseVal;
    this.zoom = {
      animation: gsap.timeline(),
      scaleFactor: 1.6,
      duration: 0.5,
      ease: "power2.in",
    };
    this.pannable = new Draggable(this.proxy, {
      throwResistance: 3000,
      trigger: svg,
      throwProps: true,
      onDrag: (event) => this.updateViewBox(event, this.pannable),
      onThrowUpdate: (event) => this.updateViewBox(event, this.pannable),
      onRelease: (event) => this.releaseDraggable(event, this.pannable),
      onPress: (event) => this.selectDraggable(event, this.pannable),
    });
    window.addEventListener("wheel", this.onWheel, { passive: false });
  }

  onWheel = (event) => {
    let normalized;
    let delta = event.wheelDelta;
    if (delta) {
      normalized = delta % 120 == 0 ? delta / 120 : delta / 12;
    } else {
      delta = event.deltaY || event.detail || 0;
      normalized = -(delta % 3 ? delta * 10 : delta / 3);
    }
    const scaleDelta =
      normalized > 0 ? 1 / this.zoom.scaleFactor : this.zoom.scaleFactor;
    this.point.x = event.clientX;
    this.point.y = event.clientY;
    const startPoint = this.point.matrixTransform(
      this.svg.getScreenCTM().inverse()
    );
    const fromVars = {
      ease: this.zoom.ease,
      x: this.viewBox.x,
      y: this.viewBox.y,
      width: this.viewBox.width,
      height: this.viewBox.height,
    };
    this.viewBox.x -= (startPoint.x - this.viewBox.x) * (scaleDelta - 1);
    this.viewBox.y -= (startPoint.y - this.viewBox.y) * (scaleDelta - 1);
    this.viewBox.width *= scaleDelta;
    this.viewBox.height *= scaleDelta;
    this.zoom.animation = gsap.from(this.viewBox, this.zoom.duration, fromVars);
  };

  selectDraggable = (event, draggable: any) => {
    this.startClient.x = draggable.pointerX;
    this.startClient.y = draggable.pointerY;
    this.startGlobal = this.startClient.matrixTransform(
      this.svg.getScreenCTM().inverse()
    );
    // Right mouse button
    if (event.button === 2 || event.button === 0) {
      gsap.set(this.proxy, {
        x: draggable.pointerX,
        y: draggable.pointerY,
      });
      this.dragTarget = event.target;
      this.pannable.enable().update().startDrag(event);
    }
  };

  updateViewBox = (event, draggable: any) => {
    if (this.zoom.animation.isActive()) {
      return;
    }
    //right click
    if (event.buttons === 2) {
      this.point.x = draggable.x;
      this.point.y = draggable.y;
      const moveGlobal = this.point.matrixTransform(
        this.svg.getScreenCTM().inverse()
      );
      this.viewBox.x -= moveGlobal.x - this.startGlobal.x;
      this.viewBox.y -= moveGlobal.y - this.startGlobal.y;
    } else if (event.buttons === 1) {
      this.point.x = draggable.x;
      this.point.y = draggable.y;
      const moveGlobal = this.point.matrixTransform(
        this.svg.getScreenCTM().inverse()
      );
      const offsetX = moveGlobal.x - this.startGlobal.x;
      const offsetY = moveGlobal.y - this.startGlobal.y;
      this.plates.movePlate(this.dragTarget, offsetX, offsetY);
    }
  };

  releaseDraggable = (event, draggable: any) => {
    if (this.zoom.animation.isActive()) {
      return;
    }
    if (event.button === 0) {
      this.point.x = draggable.x;
      this.point.y = draggable.y;
      const moveGlobal = this.point.matrixTransform(
        this.svg.getScreenCTM().inverse()
      );
      const offsetX = moveGlobal.x - this.startGlobal.x;
      const offsetY = moveGlobal.y - this.startGlobal.y;
      this.plates.applyMove(this.dragTarget, offsetX, offsetY);
    }
  };

  rerenderPicture(): { h: number; w: number } {
    if (!this.imgInitialBoundingClientRect) return;
    const h =
      (this.imgInitialBoundingClientRect.height /
        (this.pixelsPerCmControl.value * 100)) *
      this.plates.pixelsPerMeter;
    const w =
      (this.imgInitialBoundingClientRect.width /
        (this.pixelsPerCmControl.value * 100)) *
      this.plates.pixelsPerMeter;
    this.renderer.setAttribute(
      this.img.nativeElement,
      "style",
      `width: ${w}px; height: ${h}px; transform-origin: ${w / 2}px ${
        h / 2
      }px; transform: rotate(${-this.rotationValue}deg)`
    );
    return { h, w };
  }

  updatePerfactionImageConfig() {
    if (!!this.img && this.imagePreview) {
      const { h, w } = this.rerenderPicture();
      this.imageHeight = h;
      const size = {
        x: w / this.plates.pixelsPerMeter,
        y: this.imageHeight / this.plates.pixelsPerMeter,
      };
      this.store.dispatch(
        new SetPerfactionImageConfig({
          pictureSize: this.pixelsPerCmControl.value,
          pictureRotationDeg: this.rotationValue,
          pictureSizeAsVector: size,
        })
      );
    }
  }

  rotationEvent(v) {
    this.rotationValue = +v;
    this.updatePerfactionImageConfig();
  }

  async ngOnInit() {
    const imageId = await this.store
      .pipe(select(getPerfactionImage), take(1))
      .toPromise();
    if (imageId != null)
      this.store.dispatch(new GetPerfactionImage({ id: imageId }));
    gsap.registerPlugin(Draggable);
    this.perfactionServcie.imageFileObservable().subscribe((file) => {
      if (!file) {
        this.imagePreview = null;
        return;
      }
      this.fileName = file.name;
      if (!this.fileName) {
        this.fileName = "reloadedImage";
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreview = e.target.result;
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          this.imgInitialBoundingClientRect = {
            height: img.height,
            width: img.width,
          };

          this.imageHeight =
            (this.imgInitialBoundingClientRect.height /
              (this.pixelsPerCmControl.value * 100)) *
            this.plates.pixelsPerMeter;
          const w =
            (this.imgInitialBoundingClientRect.width /
              (this.pixelsPerCmControl.value * 100)) *
            this.plates.pixelsPerMeter;
          const size = {
            x: w / this.plates.pixelsPerMeter,
            y: this.imageHeight / this.plates.pixelsPerMeter,
          };
          this.store.dispatch(
            new SetPerfactionImageConfig({
              pictureSize: this.pixelsPerCmControl.value,
              pictureRotationDeg: this.rotationValue,
              pictureSizeAsVector: size,
            })
          );
        };
      };
      reader.readAsDataURL(file);
    });
    this.store
      .pipe(
        select(getPerfactionInputs),
        filter((x) => !!x)
      )
      .subscribe(async (perfaction) => {
        if (
          perfaction.imageConfig == null ||
          perfaction.imageConfig.pictureSize == null
        )
          return;
        // this.sliderOneValue.setValue( perfaction.imageConfig.pictureSize );
        this.rotationValue = perfaction.imageConfig.pictureRotationDeg;
        this.rerenderPicture();
      });
  }

  private savePerfactionImageConfig(): void {}

  translate(text: string, module: string = "designer") {
    return this.translationProvider.translate(text, module);
  }

  public onDragOver(e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }): void {
    e.preventDefault();
    e.stopPropagation();
    if (!this.dragArea.nativeElement.classList.contains("drag-state")) {
      this.dragArea.nativeElement.classList.add("drag-state");
    }
  }

  public onDragLeave(e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }): void {
    e.preventDefault();
    e.stopPropagation();
    if (this.dragArea.nativeElement.classList.contains("drag-state")) {
      this.dragArea.nativeElement.classList.remove("drag-state");
    }
  }

  public onDrop(e: {
    preventDefault: () => void;
    stopPropagation: () => void;
    dataTransfer: { files: any };
    target: { value: string };
  }): void {
    e.preventDefault();
    e.stopPropagation();
    if (this.dragArea.nativeElement.classList.contains("drag-state")) {
      this.dragArea.nativeElement.classList.remove("drag-state");
    }
    this.submitFile(e.dataTransfer.files);
  }

  submitFile(file: any) {
    // UploadPerfactionFile
    this.store.dispatch(new UploadPerfactionFile(file[0]));
    this.perfactionServcie.pushNextFile(file[0]);
    this.uploadLink.nativeElement.value = null;
  }

  onConfirmDeleteImage(content: any) {
    this.modalService
      .open(content, {
        centered: true,
        backdrop: "static",
        windowClass: "custom-modal-window",
        backdropClass: "custom-modal-backdrop",
      })
      .result.then(
        (result: boolean) => {
          if (result) {
            this.deleteImage();
          }
        },
        () => {}
      );
  }

  async deleteImage() {
    const imageId = await this.store
      .pipe(select(getPerfactionImage), take(1))
      .toPromise();
    this.store.dispatch(new DeletePerfactionImage({ id: imageId }));
  }
}
