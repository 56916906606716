import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationProvider } from '../../../providers/translation.provider';

@Component({
  selector: 'app-perfaction-image-tools',
  templateUrl: './perfaction-image-tools.component.html',
  styleUrls: ['./perfaction-image-tools.component.css']
})
export class PerfactionImageToolsComponent implements OnInit {

  @Input() imageName: string;
  @Output() deleteImageEvent = new EventEmitter();


  constructor(private translationProvider: TranslationProvider) { }

  ngOnInit() {
  }
  
  translate(text: string, module: string = 'designer') {
    return this.translationProvider.translate(text, module);
  }

  onDeleteImage(e){
    this.deleteImageEvent.emit(e);
  }

}
