import {LayerSize} from './layer-size';
import {HtmlLayerElement} from './html-layer-element';
import {BehaviorSubject, Observable} from 'rxjs';

export class HtmlLayer {

    private readonly rootDiv: HTMLDivElement;
    private htmlLayersElements: HtmlLayerElement[] = [];
    private readonly sizeBs: BehaviorSubject<LayerSize>;
    private _size: LayerSize;
    public get size(): Observable<LayerSize> {
        return this.sizeBs;
    }

    constructor(private canvas: HTMLCanvasElement) {
        const div: HTMLDivElement = document.createElement('div') as HTMLDivElement;
        // div.style.position = 'absolute';
        div.style.width = canvas.clientWidth + 'px';
        div.style.height = canvas.clientHeight + 'px';
        div.style.top = '0px';
        div.style.whiteSpace = 'nowrap';
        div.style['pointer-events'] = 'none';
        div.style.overflow = 'hidden';
        this.rootDiv = div;
        canvas.parentElement.appendChild(div);
        this.sizeBs = new BehaviorSubject<LayerSize>({width: canvas.clientWidth, height: canvas.clientHeight});
        this._size = {width: canvas.clientWidth, height: canvas.clientHeight};
        window.onkeydown = this.onWindowKeyDown.bind(this);
        div.onkeyup = this.onkeyup.bind(this);

    }

    private isHidden():boolean{
        return this.canvas.offsetParent === null;
    }
    public add(element: HtmlLayerElement): void {
        this.htmlLayersElements.push(element);
        this.rootDiv.appendChild(element.element);
    }

    public remove(element: HtmlLayerElement): void {
        var index = this.htmlLayersElements.indexOf(element);
        if (index > -1) {
            this.htmlLayersElements.splice(index, 1);
        }
        this.rootDiv.removeChild(element.element);
    }


    public update(size: LayerSize) {
        if (this.canvas.parentElement !== this.rootDiv.parentElement) {
            this.canvas.parentElement.appendChild(this.rootDiv);
        }
        if (this._size.width !== size.width || this._size.height !== size.height) {
            this.rootDiv.style.width = size.width + 'px';
            this.rootDiv.style.height = size.height + 'px';
            this.sizeBs.next({width: size.width, height: size.height});
            this._size = {width: size.width, height: size.height};
        }
    }

    private onWindowKeyDown(e: KeyboardEvent) {
        if (e.key === 'Tab' && !this.isHidden()) {
            var activeInputs: HTMLInputElement[] = [];
            for (let i = 0; i < this.htmlLayersElements.length; i++) {
                const htmlLayeresEment = this.htmlLayersElements[i];
                if ((htmlLayeresEment as any).editable && htmlLayeresEment.element.style.display !== 'none') {
                    activeInputs.push(htmlLayeresEment.element as HTMLInputElement);
                }
            }
            if (activeInputs.length > 0) {
                var currentIndex = activeInputs.indexOf(e.target as HTMLInputElement);
                currentIndex = (currentIndex + 1) % activeInputs.length;
                activeInputs[currentIndex].focus();
                e.preventDefault();
            }
        }
    }

    private onkeyup(e: KeyboardEvent) {
        if (e.key === 'Escape') {
            this.canvas.focus();
        }
    }

    public getCanvas(): HTMLCanvasElement {
        return this.canvas;
    }

    public getRootDiv(): HTMLDivElement {
        return this.rootDiv;
    }

    public dispose() {

    }
}