import {Component, Input, OnInit} from '@angular/core';
import {DropdownOption} from '../dropdown.component';
import {TranslationProvider} from '../../../providers/translation.provider';

@Component({
  selector: 'dropdown-option',
  templateUrl: './string-option.component.html',
  styleUrls: ['./string-option.component.css']
})
export class StringOptionComponent implements OnInit {

  @Input() option: DropdownOption;
  @Input() selected: boolean;
  @Input() labelText: string;
  @Input() dropdownOptionType = 'DEFAULT';  /* TYPES = 'ERROR', 'DEFAULT' */

  constructor(private translationProvider: TranslationProvider) {
  }

  translate(text: string, module: string = 'designer') {
    return this.translationProvider.translate(text, module);
  }

  ngOnInit() {
  }

}
