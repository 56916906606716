export function ParseDecimalNumber(value: string): number | undefined {
    const commaIndex = value.indexOf(",");
    let num;
    if (commaIndex > 0) {
        num = Number(value.replace(",", "."));
        // const whole = Number(value.substr(0, commaIndex));
        // const restV = value.substr(commaIndex + 1);
        // const rest = Number(restV) / Math.pow(10, restV.length);
        // let num = whole;
        // if (!isNaN(rest)) {
        //     num += rest;
        // }
    } else {
        num = Number(value);
    }
    if (isNaN(num)) {
        return undefined;
    }
    return num;
}