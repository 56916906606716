import {Component, OnDestroy, OnInit} from '@angular/core';
import {NamedTool, ToolProvider} from '../../providers/tool.provider';
import {SelectTool} from '../../tools/editTools/select.tool';
import {Tool} from '../../tools/tool.interface';
import {delay, startWith} from 'rxjs/operators';
import {TranslationProvider} from '../../providers/translation.provider';
import {select, Store} from '@ngrx/store';
import {getCurrentActionType, getStep, MevacoState} from '../../store/reducers';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {Step} from '../../model/product-configuration/product-configuration.model';
import {ActionType} from '../../model';
import {ChangeActionType} from '../../store/actions';
import {SelectPerforationImportTool} from '../../tools/editTools/import/select-perforation-import.tool';

@Component({
  selector: 'select-move',
  templateUrl: './select-move.component.html',
  styleUrls: ['./select-move.component.css']
})
export class SelectMoveComponent implements OnInit, OnDestroy {
  public selectTool: SelectTool;
  public round = Math.round;
  private activeTool: Tool;
  public drawingTools: Observable<NamedTool[]>;
  public measureTools: Observable<NamedTool[]>;
  public contureTools: Observable<NamedTool[]>;
  public contureToolsArray: NamedTool[] = [];
  public eckpunkteTools: Observable<NamedTool[]>;
  public stepSub: Observable<Step>;
  private $activeTool: Observable<Tool>;
  public currentActionType: Observable<ActionType>;
  public $anyOwnedActive: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private drawingToolsArray: NamedTool[] = [];
  private measureToolsArray: NamedTool[] = [];
  private subscriptions: Subscription[] = [];
  public ActionType = ActionType;
  private selectImportTool: SelectPerforationImportTool;
  constructor(private toolProvider: ToolProvider, private translationProvider: TranslationProvider, private store: Store<MevacoState>) {
    this.selectImportTool = this.toolProvider.selectPerforationImportTool;
    this.selectTool = this.toolProvider.selectTool;
    this.subscriptions.push(
      this.toolProvider.getToolObservable().pipe(startWith(this.selectTool)).subscribe((v) => {
        this.activeTool = v;
        let ownedActive = false;
        for (const t of this.drawingToolsArray) {
          if (v === t.tool) {
            ownedActive = true;
          }
        }
        for (const t of this.measureToolsArray) {
          if (v === t.tool) {
            ownedActive = true;
          }
        }
        for(const t of this.contureToolsArray) {
          if(v === t.tool){
            ownedActive = true;
          }
        }
        if (v === this.selectTool || v === this.selectImportTool) {
          ownedActive = true;
        }
        this.$anyOwnedActive.next(ownedActive);
      })
    );
    document.addEventListener('keyup', (ev: KeyboardEvent) => {
      if (this.activeTool === this.selectTool) {
        if (ev.key === 'Enter') {
          this.selectTool.onConfirm();
        }
      }
    });
  }

  ngOnInit() {
    this.drawingTools = this.toolProvider.getDrawingTools();
    this.subscriptions.push(
      this.drawingTools.subscribe((v) => {
        this.drawingToolsArray = v;
      })
    );
    this.measureTools = this.toolProvider.getMeasureTools();
    this.subscriptions.push(
      this.measureTools.subscribe((v) => {
        this.measureToolsArray = v;
      })
    );
    this.eckpunkteTools = this.toolProvider.getEckpunkteTools();
    this.contureTools = this.toolProvider.getContureTools();

    this.subscriptions.push(
      this.contureTools.subscribe((v) => {
        this.contureToolsArray = v;
      })
    );
    this.stepSub = this.store.pipe(select(getStep));
    this.$activeTool = this.toolProvider.getToolObservable().pipe(startWith(this.selectTool), delay(0));
    this.currentActionType = this.store.pipe(select(getCurrentActionType));
  }

  public setHorizontalMove(val: string) {
    if (isNaN(Number(val)) || isNaN(parseFloat(val))) {
      val = '0';
    }
    this.selectTool.moveSum = {x: Number(val) * 0.001, y: this.selectTool.moveSum.y};
  }

  public setVerticalMove(val: string) {
    if (isNaN(Number(val)) || isNaN(parseFloat(val))) {
      val = '0';
    }
    this.selectTool.moveSum = {x: this.selectTool.moveSum.x, y: Number(val) * 0.001};
  }

  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }

  setAddTool(tool: NamedTool) {
    this.store.dispatch(new ChangeActionType(ActionType.ADD));
    this.toolProvider.setTool(tool.tool);
  }

  setRemoveTool(tool: NamedTool) {
    this.store.dispatch(new ChangeActionType(ActionType.REMOVE));
    this.toolProvider.setTool(tool.tool);
  }

  setTool(tool: NamedTool) {
    this.toolProvider.setTool(tool.tool);
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      if (!s.closed) {
        s.unsubscribe();
      }
    }
    this.subscriptions = [];
  }

}
