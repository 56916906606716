import {Injectable} from "@angular/core";

@Injectable()
export class TabbingService {
  private firstFocusable: HTMLElement;
  private lastFocusable: HTMLElement;
  private _scope: HTMLElement[];

  public set scope(value: HTMLElement[] ) {
    this._scope = value;
    this.firstFocusable = null;
    this.lastFocusable = null;
    if(this._scope.length >= 1) {
      const notBasicTab = [];
      for (const e of value) {
        notBasicTab.push(e.querySelectorAll("[tabIndex] >= 1"));
      }
      notBasicTab.sort((a: HTMLElement, b: HTMLElement) => {
        if (a.tabIndex > b.tabIndex) {
          return 1;
        } else {
          return -1;
        }
      });
      this.firstFocusable = notBasicTab[0];
      this.lastFocusable = notBasicTab[notBasicTab.length - 1];

      this.firstFocusable.addEventListener('keydown', ev => {
        if(ev.shiftKey && ev.code === 'Tab') {
          ev.preventDefault();
          this.lastFocusable.focus();
        }
      });

      this.lastFocusable.addEventListener('keydown', ev => {
        if(!ev.shiftKey && ev.code === 'Tab') {
          ev.preventDefault();
          this.lastFocusable.focus();
        }
      });
    }
  }
}
