import { AdvancedDynamicTexture } from "webcad/babylonjs/gui";
import { ModelVisualizer, RotateCamera, Webcad } from "webcad";
import { Vector3 } from "webcad/math";
import { ObjectUnderPoint } from "webcad/models/ObjectUnderPoint";
import { MoveNodeType, PointNode } from "../model/point-node.model";
import {Color3, Engine, Matrix, Mesh, MeshBuilder, Node, Scene, StandardMaterial, Vector3 as B_Vector3} from "webcad/babylonjs/core";

export class NodesVisualizer
  implements ModelVisualizer<Map<number, PointNode>>
{
  private rootNode: Node;
  private model: Map<number, PointNode>;
  private webcad: Webcad;
  private nodeLayer: AdvancedDynamicTexture;
  private viewProjectionMatrix: Matrix;
  private meshes: Map<number, Mesh>;
  private sub = false;
  private engine: Engine;

  updateVisualization(newModel: Map<number, PointNode>): void {
    this.model = newModel;
  }

  init(
    rootNode: Node,
    model: Map<number, PointNode>,
    webcad: Webcad
  ): Promise<void> {
    this.rootNode = rootNode;
    this.model = model;
    this.webcad = webcad;
    this.engine = this.rootNode.getEngine();
    this.nodeLayer = AdvancedDynamicTexture.CreateFullscreenUI(
      "nodeLayer",
      true,
      this.rootNode.getScene()
    );
    this.meshes = new Map<number, Mesh>();
    return;
  }

  dispose(): void {}

  getObjectUnderPoint(point: Vector3, maxDist: number): ObjectUnderPoint {
    return null;
  }
}

export function createNodeMesh(
  node: PointNode,
  scene: Scene
): Mesh {
  const activeCamera = scene.activeCamera as unknown;

  const scale = 0.004 * (activeCamera as RotateCamera).radius;
  const nodePos = new B_Vector3(
    node.position.x,
    node.position.y,
    node.position.z - 0.001
  );
  const mesh = MeshBuilder.CreateDisc(
    node.origin.toString() + " node",
    { radius: 1 },
    scene
  );
  mesh.position = nodePos;
  mesh.renderingGroupId = 3;
  mesh.scaling = new B_Vector3(scale, scale, 1);
  const nodeMaterial = new StandardMaterial("node material", scene);
  nodeMaterial.diffuseColor =
    node.type !== MoveNodeType.bias
      ? Color3.Blue()
      : Color3.Black();
  nodeMaterial.specularColor =
    node.type !== MoveNodeType.bias
      ? Color3.Blue()
      : Color3.Black();
  nodeMaterial.ambientColor =
    node.type !== MoveNodeType.bias
      ? new Color3(0, 0.62, 0.89)
      : Color3.Black();
  mesh.material = nodeMaterial;
  return mesh;
}
