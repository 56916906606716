<div class="right-panel-wrapper">
  <div
    class="configurator-wrapper"
    [ngStyle]="{
      right: rightPanelConfiguration.configuratorPanel.position.right + 'px'
    }"
  >
    <configurator></configurator>
  </div>

  <div
    class="effects-editor-wrapper"
    [ngStyle]="{
      right: rightPanelConfiguration.effectsPanel.position.right + 'px'
    }"
  >
    <app-effects-editor></app-effects-editor>
  </div>

  <div
    class="fading-editor-wrapper"
    [ngStyle]="{
      right: rightPanelConfiguration.fadingPanel.position.right + 'px'
    }"
  >
    <app-fading-editor></app-fading-editor>
  </div>

  <div
    class="message-visualizer-wrapper"
    [ngStyle]="{
      right:
        rightPanelConfiguration.messageVisualizerPanel.position.right + 'px'
    }"
  >
    <app-message-visualizer></app-message-visualizer>
  </div>

  <ng-container
    *ngIf="
      rightPanelConfiguration.configuratorPanel.state ===
      SubpanelCurrentState.CLOSED
    "
  >
    <span
      class="configurator-button"
      (click)="toggleSubpanel(Subpanels.CONFIGURATOR_SUBPANEL)"
    >
      <mat-icon class="icon-parameter" svgIcon="Icon_Parameter"></mat-icon>
      <div class="configurator-triangle configurator-triangle--primary"></div>
    </span>
  </ng-container>

  <ng-container
    *ngIf="
      rightPanelConfiguration.messageVisualizerPanel.state ===
      SubpanelCurrentState.CLOSED
    "
  >
    <span
      class="message-visualizer-button"
      (click)="toggleSubpanel(Subpanels.MESSAGE_VISUALIZER_SUBPANEL)"
      *ngIf="messageVisualizerStatus !== MessageVisualizerStatus.NONE"
      [class.message-visualizer-button--error]="
        messageVisualizerStatus === MessageVisualizerStatus.ERROR
      "
      [class.message-visualizer-button--warning]="
        messageVisualizerStatus === MessageVisualizerStatus.WARNING
      "
      [class.message-visualizer-button--hint]="
        messageVisualizerStatus === MessageVisualizerStatus.HINT
      "
    >
      <img
        [src]="
          messageVisualizerStatus === MessageVisualizerStatus.HINT
            ? 'assets/hint-icon.svg'
            : 'assets/configurator-messages-icon.svg'
        "
        alt="messages-icon"
        class="message-visualizer-button-img"
      />
      <div
        class="configurator-triangle"
        [class.configurator-triangle--error]="
          messageVisualizerStatus === MessageVisualizerStatus.ERROR
        "
        [class.configurator-triangle--warning]="
          messageVisualizerStatus === MessageVisualizerStatus.WARNING
        "
        [class.configurator-triangle--hint]="
          messageVisualizerStatus === MessageVisualizerStatus.HINT
        "
      ></div>
    </span>
  </ng-container>
</div>
