import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Guid } from "guid-typescript";
import { Observable, Subscription, fromEvent } from "rxjs";
import { map } from "rxjs/operators";
import {
  LineIntersect2D,
  Vector2,
  normalizeVector2,
  subVectors2,
} from "webcad/math";
import {
  LineSegmentsFromPath,
  PolylineArea,
  aabbOfPolyline,
} from "webcad/models";
import { Trapeze } from "../../../../../../src/app/model/basic-templates.model";
import { PerforationAreaModel } from "../../../../../../src/app/model/perforation-area.model";
import {
  Element,
  ElementType,
} from "../../../../../../src/app/model/product-configuration/element.model";
import {
  ShapeWithHoles,
  shapeWithHolesHash,
} from "../../../../../../src/app/model/shape-with-holes";
import { UserTemplate } from "../../../../../../src/app/model/user-template.model";
import {
  SetTrapezeA,
  SetTrapezeAlpha,
  SetTrapezeB1,
  SetTrapezeB2,
  SetTrapezeBeta,
  SetTrapezeHA1,
  SetTrapezeHA2,
  SetTrapezeHB,
  SetTrapezeTemplate,
} from "../../../../../../src/app/store/actions/element-creator.actions";
import { GetUserTemplates } from "../../../../../../src/app/store/actions/user-template.actions";
import {
  MevacoState,
  ProductConfigurationAddElement,
  getTrapezeTemplate,
  getUserTemplates,
} from "../../../../../app/store";
import { reqValTrapeze } from "../../../../store/reducers";
import { TemplateName } from "../../template-name";
import { TemplateUtils } from "../../templatesUtils";

@Component({
  selector: "mevaco-trapez",
  templateUrl: "./trapez.component.html",
  styleUrls: ["./trapez.component.css"],
})
export class TrapezComponent implements OnInit {
  @Output() isModalOpen = new EventEmitter();
  @Output() dismissModal = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @ViewChild("closeButton", { static: true })
  closeButton: ElementRef;

  public trapezeTemplate: Trapeze;
  public templates: Observable<UserTemplate[]>;
  public pi = Math.PI;
  public reqValTrapeze: Observable<boolean>;
  public minf = Math.min;

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<MevacoState>,
    public templateUtils: TemplateUtils
  ) {}

  ngOnInit() {
    this.createFromTrapezeTemplate();
    this.store.dispatch(new GetUserTemplates());
    this.templates = this.store.pipe(select(getUserTemplates));
    this.subscriptions.push(
      this.store
        .pipe(select(getTrapezeTemplate))
        .subscribe((v) => (this.trapezeTemplate = { ...v }))
    );
    this.reqValTrapeze = this.store.pipe(select(reqValTrapeze));
  }

  isModalOpenMethod() {
    this.isModalOpen.emit(false);
  }

  dismiss(str: string) {
    this.dismissModal.emit(str);
  }

  close(input: {}) {
    this.closeModal.emit(input);
  }

  createFromTrapezeTemplate() {
    const obs = fromEvent(this.closeButton.nativeElement, "click").pipe(
      map(() => {
        return this.trapezeTemplate;
      })
    );

    obs.subscribe((result) => {
      const e1 = result.e1 / 1000;
      const e2 = result.e2 / 1000;
      const ha1 = result.ha1 / 1000;
      const ha2 = result.ha2 / 1000;
      const a = result.a / 1000;
      const f1 = result.f1 / 1000;
      const f2 = result.f2 / 1000;
      const b1 = result.b1 / 1000;
      const b2 = result.b2 / 1000;
      const alpha = result.alpha;
      const beta = result.beta;
      const hb = result.hb / 1000;
      const path: Vector2[] = [
        { x: 0, y: 0 },
        { x: b1, y: 0 },
        { x: hb + b2, y: a },
        { x: hb, y: a },
      ];
      const conture = LineSegmentsFromPath(path);
      const shapeWithHoles: ShapeWithHoles = {
        holes: [],
        conture: conture,
      };
      shapeWithHoles.aabb = aabbOfPolyline(shapeWithHoles.conture);
      shapeWithHoles.area = PolylineArea(shapeWithHoles.conture);
      shapeWithHoles.hash = shapeWithHolesHash(shapeWithHoles);
      const dirA: Vector2 = normalizeVector2(
        subVectors2({ x: hb, y: a }, { x: 0, y: 0 })
      );
      const dirB: Vector2 = normalizeVector2(
        subVectors2({ x: hb + b2, y: a }, { x: b1, y: 0 })
      );
      const xAsix: Vector2 = { x: 1, y: 0 };
      const ml = f1 / Math.sin(alpha);
      const mr = f2 / Math.sin(beta);
      const ld = LineIntersect2D(xAsix, { x: 0, y: e1 }, dirA, { x: ml, y: 0 });
      const rd = LineIntersect2D(xAsix, { x: 0, y: e1 }, dirB, {
        x: b1 - mr,
        y: 0,
      });
      const ru = LineIntersect2D(xAsix, { x: 0, y: a - e2 }, dirB, {
        x: b1 - mr,
        y: 0,
      });
      const lu = LineIntersect2D(xAsix, { x: 0, y: a - e2 }, dirA, {
        x: ml,
        y: 0,
      });
      const perfContour = LineSegmentsFromPath([ld, rd, ru, lu]);
      const perfAabb = aabbOfPolyline(perfContour);
      const perfArea = PolylineArea(perfContour);
      const perforationAreaShape: ShapeWithHoles = {
        conture: perfContour,
        holes: [],
        aabb: perfAabb,
        area: perfArea,
      };
      perforationAreaShape.hash = shapeWithHolesHash(perforationAreaShape);
      const perf: PerforationAreaModel = {
        shape: perforationAreaShape,
        offset: { x: 0, y: 0 },
        rotation: 0,
      };
      const element: Element = {
        type: ElementType.individual,
        templateName: TemplateName.trapeze,
        boundary: null,
        position: -1, // will be set inb reducer
        quantity: 1,
        a: a,
        b: b1,
        aIst: a,
        bIst: b1,
        e1: e1.toString(),
        e1ist: e1.toString(),
        e2: e2.toString(),
        e2ist: e2.toString(),
        f1: f1.toString(),
        f1ist: f1.toString(),
        f2: f2.toString(),
        f2ist: f2.toString(),
        openMeshE: "",
        openMeshF: "",
        toleranceWidth: 0,
        toleranceLength: 0,
        label: "",
        note: "",
        unperforated: false,
        posibleCoil: "No",
        posiblePlate: "No",
        shapes: null,
        nodes: null,
        verticesIndexes: null,
        visualizationShape: null,
        previewImageId: Guid.create().toString(),
        shape: shapeWithHoles,
        perforationAreas: [perf],
        helpLines: [],
        measurements: [],
        angleMeasurements: [],
        mountings: [],
        perforationAutoCenter: true,
        area: shapeWithHoles.area,
        minRadius: null,
        minParallelEdgesDistance: null,
        minMarginDistance: null,
        isPerforationSimpleRect: null,
        isShapeSimpleRect: null,
        minMountingHoleEdgeDistance: null,
        minMountingHolesDistance: null,
        minOutsideAngle: null,
        numberOfArcs: null,
        minDistanceBetweenEdges: null,
        minDistanceBetweenMountingAndPerforatedArea: null,
        //perforation: null,
        possibleAllAcross: true,
        minDistanceBetweenPerforationAreas: null,
        lfbIst: undefined,
        lflIst: undefined,

        breakLines: [],
        bendingLinesDistances: [],
        //possiblePerforationZones: []
      };
      this.store.dispatch(new ProductConfigurationAddElement(element));
    });
  }

  setTrapezeTemplate(template: Trapeze, attribute: string, value: any) {
    if (isNaN(value) || isNaN(parseFloat(value))) {
      template[attribute] = null;
    } else {
      template[attribute] = Number(value);
    }
    this.store.dispatch(new SetTrapezeTemplate({ ...template }));
  }

  setTrapezeA(value: any) {
    this.store.dispatch(new SetTrapezeA(value));
  }

  setTrapezeAlpha(value: any) {
    this.store.dispatch(new SetTrapezeAlpha(value));
  }

  setTrapezeB1(value: any) {
    this.store.dispatch(new SetTrapezeB1(value));
  }

  setTrapezeB2(value: any) {
    this.store.dispatch(new SetTrapezeB2(value));
  }

  setTrapezeBeta(value: any) {
    this.store.dispatch(new SetTrapezeBeta(value));
  }

  setTrapezeHA1(value: any) {
    this.store.dispatch(new SetTrapezeHA1(value));
  }

  setTrapezeHA2(value: any) {
    this.store.dispatch(new SetTrapezeHA2(value));
  }

  setTrapezeHB(value: any) {
    this.store.dispatch(new SetTrapezeHB(value));
  }
}
