import {PerforationType} from './perforation-type.model';
import {Geomatrix} from './geomatrix.model';
import {MaterialData} from './material-data.model';
import {MaterialLimits} from './material-limits.model';
import {Paint} from './paint.model';
import {Tolerance} from '../tolerance-model';
import {Bending} from './bending.model';
import { WallpaperType } from './wallpaper-type.model';
import { DesignerEffect } from '../effect.model';

export enum FoilOption {
  None= 'None',
  OneSide= 'ef',
  TwoSides= 'bf',
}

export interface FoilMaterialThickness {
  [thickness: string]: FoilOption[];
}

export interface Foils {
  [materialCode: string]: FoilMaterialThickness;
}

export interface SingleTool {
  extLenght: number;
  extWidth: number;
  foil: number;
  form: string;
  formGroup: string;
  formGroupSort: number;
  formSort: number;
  galvanizing: number;
  id: number;
  lenght: number;
  materialCode: number;
  polishing: number;
  powdercoating: number;
  thicknessFrom: number;
  thicknessTo: number;
  tool: string;
  toolId: string;
  toolSort: number;
  width: number;
  defaultSize: number;
}
export interface PerfactionPattern {
  id: number;
  form: string;
  pattern: string;
  label: string;
  geomatrixId: number;
  defaultSizes: string;
}

export interface Dataset {
  perforationAssetsUrl: string;
  perforationIcons: { [perfId: string]: string };
  extendedMetalAssetsUrl: string;
  expandedMetalsIcons: {[expMetType: string]: string}
  perforationTypes: PerforationType[];
  endingsMapping: { [name: string]: [string] };
  geomatrix: Geomatrix[];
  materials: MaterialData[];
  perforationMaterial: MaterialLimits[];
  polishedMaterials: { [materialCode: string]: [number] };
  paints: Paint[];
  paintAssetsUrl: string;
  foiles: Foils;
  tolerance: Tolerance[];
  bendings: Bending[];
  wallpaperTypes: WallpaperType[];
  singleTools: SingleTool[];
  effects: DesignerEffect[];
  expandedMetals: any;
  userAllowedProducts: string[];
  mevacoUserAllowedProducts: string[];

  perfactionPatterns: PerfactionPattern[];
}


export function getPositionIconUrl(dataset: Dataset, position: string): string | null {
  const geomatrix = dataset.geomatrix.find(x => x.codeLochanfang.toString() === position);
  return !!geomatrix ? dataset.perforationAssetsUrl + geomatrix.bildLoAnfang : null;
}

function uniq_type(a: MaterialData[]): MaterialData[] {
  const seen = {};
  const out: MaterialData[] = [];
  const len = a.length;
  let j = 0;
  for (let i = 0; i < len; i++) {
    const typeCode = a[i].materialTypeCode;
    if (seen[typeCode] !== 1) {
      seen[typeCode] = 1;
      out[j++] = a[i];
    }
  }
  return out;
}

export function getPerforationSortedMaterialTypes(dataset: Dataset): string[] {
  const perforationMaterials = dataset.materials.filter(x => x.products.indexOf('A') !== -1 && x.posiblePerforation !== 0);
  const perforationUniqByTypeMaterials = uniq_type(perforationMaterials);
  const result = perforationUniqByTypeMaterials.sort(
    (a, b) => a.groupSort - b.groupSort
  ).map(x => x.materialTypeCode);
  return result;

}

export function getMaterialThickness(dataset: Dataset, materialTypeCode: string): string[] {
  const perforationMaterialsOfType = dataset.materials.filter(x => x.products.indexOf('A') !== -1 && x.materialTypeCode === materialTypeCode && x.posiblePerforation !== 0);
  const perforationMaterialsLimitsOfType = dataset.perforationMaterial.filter(x => x.code.startsWith(materialTypeCode));

  const foundValues: number[] = [];
  const found = {};
  for (let i = 0; i < perforationMaterialsOfType.length; i++) {
    const material = perforationMaterialsOfType[i];
    const str = material.materialThickness.toString();
    if (found[str] !== 1) {
      for (let j = 0; j < perforationMaterialsLimitsOfType.length; j++) {
        const limits = perforationMaterialsLimitsOfType[j];
        if (material.materialThickness >= limits.thicknessMin && material.materialThickness >= limits.thicknessMin) {
          found[str] = 1;
          foundValues.push(material.materialThickness);
          break;
        }
      }
    }
  }
  return foundValues.sort((a, b) => a - b).map(x => (+x).toString());
}

export function getFirstMaterailCode(dataset: Dataset, materialTypeCode: string, thickness: string): string | null {
  let minSort = 99999;
  let code = null;
  const perforationMaterialsOfType = dataset.materials.filter(x => x.products.indexOf('A') !== -1 && x.materialTypeCode === materialTypeCode && x.posiblePerforation !== 0);
  for (let i = 0; i < perforationMaterialsOfType.length; i++) {
    const material = perforationMaterialsOfType[i];
    if (material.materialThickness.toString() === thickness && material.materialSort < minSort) {
      minSort = material.materialSort;
      code = material.materialCode;
      if (code === '012') {
        code = '022';
      }
    }
  }
  return code;
}

export function getMaterialQualities(dataset: Dataset, materialTypeCode: string, thickness: string): string[] {

  return dataset.materials
    .filter(material =>
      material.products.indexOf('A') !== -1 &&
      material.materialTypeCode === materialTypeCode &&
      material.materialThickness.toString() === thickness &&
      material.posiblePerforation !== 0
    )
    .sort((a, b) => a.materialSort - b.materialSort)
    .map(material => (material.materialCode === '012') ? '022' : material.materialCode)
    .filter(function(item, pos, self) {return self.indexOf(item) == pos; }); // remove duplicates
}

export function getColorSystemOptions(dataset: Dataset): string[] {
  const unque = {};
  for (let i = 0; i < dataset.paints.length; i++) {
    const paint = dataset.paints[i];
    unque[paint.system] = 1;
  }

  return Object.keys(unque);
}

export function getColorGroupOptions(dataset: Dataset, system: string): string[] {
  const unque = {};
  for (let i = 0; i < dataset.paints.length; i++) {
    const paint = dataset.paints[i];
    if (paint.system === system) {
      unque[paint.groupName] = 1;
    }
  }

  return Object.keys(unque);
}

export function getColorOptions(dataset: Dataset, system: string, group): string[] {
  const unque = {};
  for (let i = 0; i < dataset.paints.length; i++) {
    const paint = dataset.paints[i];
    if (paint.system === system && paint.groupName === group) {
      unque[paint.paintId] = 1;
    }
  }

  return Object.keys(unque);
}

export function getLevelOfGlossOptions(dataset: Dataset, paintId: number): string[] {
  const result = [];
  const paint = dataset.paints.find(x => x.paintId === paintId);
  if (paint) {
    if (paint.mat === 1) {
      result.push('1');
    }
    if (paint.satin === 1) {
      result.push('2');
    }
    if (paint.shine === 1) {
      result.push('3');
    }
  }
  return result;
}
