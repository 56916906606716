import { RotateCamera } from "webcad/components";
import { getPointsFromSegment, Segment } from "webcad/models";
import {Color4, Mesh, MeshBuilder, Scene, Vector3} from "webcad/babylonjs/core";
export interface ShapeActionParams {
  operation: string;
  polylines: Segment[];
}

export const initialShapeActionParams: ShapeActionParams = {
  operation: "POLYGON",
  polylines: [],
};

export function createPolylineMesh(
  segment: Segment,
  scene: Scene
): Mesh {
  // const activeCamera = scene.activeCamera as unknown;
  const scale = 0.004 * (scene.activeCamera as RotateCamera).radius;
  const positions = getPointsFromSegment(segment, true).map(
    (v) => new Vector3(v.x, v.y, 0)
  );
  const colors = [];
  const color = new Color4(0, 1, 0, 1);
  for (const c of positions) {
    colors.push(color);
  }
  const polylineMesh = MeshBuilder.CreateLines(
    "edge",
    {
      points: positions,
      colors: colors,
    },
    scene
  );
  polylineMesh.enableEdgesRendering();
  polylineMesh.edgesWidth = scale;
  polylineMesh.edgesColor = new Color4(0, 1, 0, 1);
  polylineMesh.renderingGroupId = 1;
  polylineMesh.onBeforeRenderObservable.add(() => {
    scene.getEngine().setDepthBuffer(false);
  });
  polylineMesh.onAfterRenderObservable.add(() => {
    scene.getEngine().setDepthBuffer(true);
  });
  return polylineMesh;
}
