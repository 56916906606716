<div class="configurator-accordion-wrapper">
  <h2 class="configurator-accordion-title"
  [class.accordion-title--color-primary]="titleColor === 'primary'"
  [class.accordion-title--color-default]="titleColor === 'default'"
  >
  {{translate("Surface")}}
</h2>
  <div class="configurator">
    <dropdown [model]="surfaceType" [tooltipConfig]="{fieldName:'surface.surfaceType', helpText:'Format Tooltip'}"></dropdown>
    <dropdown *ngIf="(surfaceType.value | async) === 'Polished'" [model]="polishedSides" [tooltipConfig]="{fieldName:'surface.polishedSides', helpText:'Sides Tooltip'}"></dropdown>
    <div *ngIf="(surfaceType.value | async) === 'Coated'">
      <dropdown [model]="colorSystem" [tooltipConfig]="{fieldName:'surface.colorSystem', helpText:'Color System Tooltip'}"></dropdown>
      <dropdown [model]="colorGroup" extraClass="hideScroll" [tooltipConfig]="{fieldName:'surface.colorGroup', helpText:'Color Group Tooltip'}"></dropdown>
      <dropdown [model]="color" extraClass="hideScroll" [tooltipConfig]="{fieldName:'surface.color', helpText:'Select Color Tooltip'}"></dropdown>
      <dropdown [model]="undercoating" [tooltipConfig]="{fieldName:'surface.undercoating', helpText:'Undercoating Tooltip'}"></dropdown>
      <dropdown [model]="levelOfGloss" [tooltipConfig]="{fieldName:'surface.levelOfGloss', helpText:'Level Of Gloss Tooltip'}"></dropdown>
    </div>
  </div>
</div>


