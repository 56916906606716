

   <span class="configurator-button" (click)="toggleConfigurator()">
    <img *ngIf="(configuratorSubpanelState.state === SubpanelCurrentStep.OPENED)" class="configurator-arrow" src="assets/down-arrow.svg" alt="arrow">
    <mat-icon class="icon-parameter" *ngIf="!(configuratorSubpanelState.state === SubpanelCurrentStep.OPENED)" svgIcon="Icon_Parameter"></mat-icon>
    <div class="configurator-triangle configurator-triangle--primary" *ngIf="!(configuratorSubpanelState.state === SubpanelCurrentStep.OPENED)"></div>
  </span>

    <div class="configurator-content-container">
        <!-- *ngIf="!(customPatternEditorIsOpened|async)" -->
        <div class="configurator-wrapper">
            <h4 class="configurator-header">
                <mat-icon class="configurator-header-icon" svgIcon="Icon_Parameter"></mat-icon>
                <span class="configurator-header-text">{{translate("Parameter")}}</span>
            </h4>
            <div class="configurator-body">
                <div *ngIf="(productType|async) === ProductType.PerforatedSheets" class="mt-10 pl-15">
                  <perforation-configurator></perforation-configurator>
                </div>
                <div *ngIf="(productType|async) === ProductType.ExtendedMetals" class="mt-10 pl-15">
                  <expanded-metal-configurator></expanded-metal-configurator>
                </div>
                <div class="mt-23 pl-15">
                    <material-configurator></material-configurator>
                </div>
                <div class="mt-23 pl-15">
                    <surface-configurator></surface-configurator>
                </div>
            </div>
        </div>

    </div>









