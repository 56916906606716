import { Subject } from "rxjs";
import { Webcad } from "../core";
import {
  linePlaneIntersection,
  pointOnLineProjection,
  sqrDistanceVector2,
  subVectors2,
  Vector2,
  vector2toVector3,
} from "../math";
import { CameraModel } from "../models/camera.model";
import { DragArrowModel } from "../models/drag-arrow.model";
import { ModelVisualizer } from "../visualizers";
import { HtmlLayerElement } from "./html-layer-element";
import { LayerSize } from "./layer-size";
import { toScreen } from "./utils";
import {Node, PointerEventTypes, PointerInfo, Scene} from "@babylonjs/core";

export class DragArrow
  implements ModelVisualizer<DragArrowModel>, HtmlLayerElement
{
  element: HTMLImageElement;
  private webcad: Webcad;
  private model: DragArrowModel;
  private layerSize: LayerSize;
  private mouseDown: boolean = false;
  private onElement: boolean;
  private scene: Scene;
  private cameraState: CameraModel;
  private dragging: boolean = false;
  private dragStart: Vector2;

  dispose(): void {
    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }
  }

  init(
    rootNode: Node,
    model: DragArrowModel,
    webcad: Webcad
  ): Promise<void> {
    this.webcad = webcad;
    this.model = model;
    this.element = new Image();
    this.element.src = "assets/upArrow.png";
    this.webcad.getHtmlLayer().add(this);
    this.element.draggable = false;
    this.element.style.pointerEvents = "all";
    this.element.onmouseenter = (ev: MouseEvent) => this.onMouseEnter(ev);
    this.element.onmouseleave = (ev: MouseEvent) => this.onMouseLeave(ev);
    this.element.onmousedown = (ev: MouseEvent) => this.onMouseDown();
    document.addEventListener("mouseup", (ev: MouseEvent) => this.onMouseUp());
    this.scene = rootNode.getScene();
    this.scene.onPointerObservable.add((ed, es) => {
      switch (ed.type) {
        case PointerEventTypes.POINTERMOVE:
          this.onMouseMove(ed);
          break;
      }
    });
    this.scene.onBeforeRenderObservable.add((ed, es) => {
      this.updateVisualization(this.model);
    });
    this.element.style.width = "3vw";
    this.element.style.height = "5vh";
    this.element.style.position = "absolute";
    this.element.style.display = "none";
    this.element.style.zIndex = "2";
    this.element.style.display = model.visible ? "inline" : "none";
    this.updateElementPosition(
      model && model.position,
      model && model.rotation,
      model && model.offset,
      webcad.viewState.canvasSize
    );
    this.layerSize = webcad.viewState.canvasSize;
    return Promise.resolve();
  }

  updateVisualization(newModel: DragArrowModel): void {
    // update placement
    const newSize: LayerSize = this.webcad.viewState.canvasSize;
    const newPlcment = newModel.position;
    const oldPlcment = this.model && this.model.position;
    const newOffset = newModel.offset;
    const oldOffset = this.model && this.model.offset;
    if (
      sqrDistanceVector2(newPlcment, oldPlcment) > 0.000001 ||
      sqrDistanceVector2(newOffset, oldOffset) > 0.000001 ||
      this.layerSize !== newSize ||
      this.cameraState !== this.webcad.viewState.camera
    ) {
      this.updateElementPosition(
        newPlcment,
        newModel.rotation,
        newModel.offset,
        newSize
      );
    }
    this.element.style.display = newModel.visible ? "inline" : "none";
    this.cameraState = this.webcad.viewState.camera;
    this.model = newModel;
    this.layerSize = newSize;
  }

  private updateElementPosition(
    position: Vector2,
    rotation: number = 0,
    offset: Vector2,
    layerSize: LayerSize
  ): void {
    if (!!position) {
      const posV3 = vector2toVector3(position);
      const onScreenPos = toScreen(posV3, this.webcad.viewState.camera);
      const onScreenX = onScreenPos.x > -1 || onScreenPos.x < 1;
      const onScreenY = onScreenPos.y > -1 || onScreenPos.y < 1;
      const onScreen = onScreenX && onScreenY;
      this.element.style.display = onScreen ? "inline" : "none";
      this.element.style.left =
        (onScreenPos.x / 2 + 0.5) * layerSize.width + offset.x + "px";
      this.element.style.top =
        (-onScreenPos.y / 2 + 0.5) * layerSize.height - offset.y + "px";
      this.element.style.transform =
        "rotate(" + Math.round((rotation * 180) / Math.PI) + "deg)";
    } else {
      this.element.style.display = "none";
    }
  }

  private onMouseDown(): void {
    this.dragStart = {...this.model.position};
    this.mouseDown = true;
  }

  private onDrag(): void {
    const untranslated = this.scene.unTranslatedPointer;
    const screenPos = vector2toVector3({
      x: (untranslated.x / this.webcad.viewState.canvasSize.width) * 2 - 1,
      y: (untranslated.y / this.webcad.viewState.canvasSize.height) * -2 + 1,
    });
    const ray = this.scene.createPickingRay(
      untranslated.x,
      untranslated.y,
      null,
      this.scene.activeCamera,
      false
    );
    const worldPos = linePlaneIntersection(
      ray.direction,
      ray.origin,
      { x: 0, y: 0, z: -1 },
      {
        x: 0,
        y: 0,
        z: 0,
      }
    );
    if (!!this.model.moveDirection) {
      const proj = pointOnLineProjection(
        worldPos,
        this.dragStart,
        this.model.moveDirection
      );
      const onScreenPos = toScreen(
        vector2toVector3(proj),
        this.webcad.viewState.camera
      );
      this.element.style.left =
        (onScreenPos.x / 2 + 0.5) * this.webcad.viewState.canvasSize.width +
        "px";
      this.element.style.top =
        (-onScreenPos.y / 2 + 0.5) * this.webcad.viewState.canvasSize.height +
        "px";
      (<Subject<Vector2>>this.model.onDragObservable).next(
        proj
      );
    } else {
      this.element.style.left =
        (screenPos.x / 2 + 0.5) * this.webcad.viewState.canvasSize.width + "px";
      this.element.style.top =
        (-screenPos.y / 2 + 0.5) * this.webcad.viewState.canvasSize.height +
        "px";
      (<Subject<Vector2>>this.model.onDragObservable).next(
        worldPos
      );
    }
  }

  private onMouseEnter(ev: MouseEvent): void {
    this.onElement = true;
  }

  private onMouseLeave(ev: MouseEvent): void {
    if (!this.mouseDown) {
      this.onElement = false;
    }
  }

  private onMouseMove(ev: PointerInfo): void {
    if (this.mouseDown && this.onElement) {
      this.onDrag();
      this.dragging = true;
    }
  }

  private onMouseUp(): void {
    this.mouseDown = false;
    this.onElement = false;
    if (this.dragging) {
      this.element.blur();
      const canvas = this.webcad.scene.getEngine().getRenderingCanvas();
      if (canvas) {
        canvas.focus();
      }
    }
    this.dragging = false;
  }
}
