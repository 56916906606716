import {Action} from '@ngrx/store';
import {Shape} from '../../model/shape.model';
import {ActionType} from '../../model/shape-action.model';
import {ShapeWithHoles} from '../../model/shape-with-holes';
import {LineShape} from '../../model/line-shape';
import {MevacoResponse} from '../../model/client-server.Params/mevaco-response.model';
import {Segment} from 'webcad/models';
import {Vector2} from 'webcad/math';
import {Drawing} from '../../model/drawing.model';
import {Plate, PlateMaterialType} from '../../model';

export const ADD_SHAPE_TO_REMOVE = 'ADD SHAPE TO REMOVE';
export const INITIALIZE_PLATE = 'INITIALIZE PLATE';
export const ADD_SHAPE_TO_ADD = 'ADD SHAPE TO ADD';
export const CHANGE_ACTION_TYPE = 'CHANGE ACTION TYPE';
export const SET_PLATE_WIDTH = 'SET PLATE WIDTH';
export const SET_PLATE_HEIGHT = 'SET PLATE HEIGHT';
export const RESET_CURRENT_SHAPE = 'REST CURRENT SHAPE';
export const BUILD_PLATE = 'BUILD PLATE';
export const SET_PLATE = 'SET PLATE';
export const SET_PERFORATION_AREA = 'SET PERFORATION AREA';
export const ADD_SHAPE_REFERENCE = 'ADD SHAPE REFERENCE';
export const SET_PLATE_MATERIAL = 'SET PLATE MATERIAL';

export class BuildPlate implements Action {
  public readonly subType: string;
  public readonly type: string = BUILD_PLATE;
  public data: any;

  constructor(type: string, data: any) {
    this.subType = type;
    this.data = data;
  }
}

export class SetPlateMaterial implements Action {
  readonly type: string = SET_PLATE_MATERIAL;

  constructor(public payload: PlateMaterialType) {

  }
}

export class AddShapeReference implements Action {
  readonly type: string = ADD_SHAPE_REFERENCE;

  constructor(public payload: LineShape) {
  }
}

export class SetPlateShape implements Action {
  readonly type: string = SET_PLATE;

  constructor(public payload: MevacoResponse<ShapeWithHoles[]>) {
  }
}

export class SetPerforationArea implements Action {
  readonly type: string = SET_PERFORATION_AREA;

  constructor(public payload: MevacoResponse<ShapeWithHoles[]>) {
  }
}

export class ChangeActionType implements Action {
  readonly type: string = CHANGE_ACTION_TYPE;

  constructor(public payload: ActionType) {
  }
}

export class AddShapeToRemove extends BuildPlate implements Action {
  type: string = ADD_SHAPE_TO_REMOVE;

  constructor(public payload: Segment[]) {
    super(ADD_SHAPE_TO_REMOVE, payload);
  }
}

export class AddShapeToAdd extends BuildPlate implements Action {
  type: string = ADD_SHAPE_TO_ADD;

  constructor(public payload: Segment[]) {
    super(ADD_SHAPE_TO_ADD, payload);
  }
}


export class InitializePlate extends BuildPlate implements Action {
  type: string = INITIALIZE_PLATE;

  constructor(public payload: Plate) {
    super(INITIALIZE_PLATE, payload);
  }
}

export class SetPlateWidth implements Action {
  type: string = SET_PLATE_WIDTH;

  constructor(public payload: number) {
  }
}

export class SetPlateHeight implements Action {
  type: string = SET_PLATE_HEIGHT;

  constructor(public payload: number) {
  }
}

export class ResetCurrentShape implements Action {
  type: string = RESET_CURRENT_SHAPE;

  constructor(public payload: any = null) {
  }
}

export const ROTATE_POLYLINE = 'ROTATE POLYLINE';

export interface RotatePolylineParams {
  polyline: Segment[];
  rotation: number;
  point: Vector2;
}

export class RotatePolyline implements Action {
  type: string = ROTATE_POLYLINE;

  constructor(public payload: RotatePolylineParams) {
  }
}

export const UPDATE_DRAWING = 'UPDATE DRAWING';

export interface UpdateDrawingParams {
  shape: ShapeWithHoles;
  perforationShapes: ShapeWithHoles[];
}

export class UpdateDrawing implements Action {
  readonly type: string = UPDATE_DRAWING;

  constructor(public payload: UpdateDrawingParams) {
  }
}

export const CALL_ZOOM = 'CALL ZOOM';

export class CallZoom implements Action {
  readonly type: string = CALL_ZOOM;

  constructor(public payload: any = null) {

  }
}

export const UPDATE_DRAWING_FAIL = 'UPDATE DRAWING FAIL';

export interface UpdateDrawingFailParams {
  oldDrawing: Drawing;
  error: string;
}

export class UpdateDrawingFail implements Action {
  readonly type: string = UPDATE_DRAWING_FAIL;

  constructor(public payload: UpdateDrawingFailParams) {
  }
}

export const SET_HEX_COLOR_STRING = 'SET HEX COLOR STRING';

export class SetHexColorString implements Action {
  readonly type: string = SET_HEX_COLOR_STRING;

  constructor(public payload: string) {

  }
}
/*
export const SET_ZONES = 'SET ZONES';

export class SetZones implements Action {
  readonly type: string = SET_ZONES;

  constructor(public payload: ShapeWithHoles[]) {

  }
}
*/

export type PlateActions =
  AddShapeToRemove
  | AddShapeToAdd
  | InitializePlate
  | ChangeActionType
  | SetPlateWidth
  | SetPlateHeight
  | ResetCurrentShape
  | SetPlateMaterial
  | SetPerforationArea;
