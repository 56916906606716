import {Aabb2, aabb2ContainsPoint, aabbCenter, getEmptyAaabb, mergeAabb} from "../models";
import {Vector2} from "./vector2";

export interface QuadTreeElement {
    readonly aabb:Aabb2
}

export class QuadNode<Element extends QuadTreeElement> {

    public children:QuadNode<Element>[] = null;
    // 2 | 3
    // -----
    // 0 | 1
    public elementsSize:Aabb2 = getEmptyAaabb();
    public elements:Element[] = [];
    public readonly center:Vector2;
    private readonly sizeToCheck:number;

    constructor(private capacity:number, private minSize:number, private size:Aabb2){
        this.center = aabbCenter( size );
        this.sizeToCheck = Math.min( size.max.x - size.min.x, size.max.y - size.min.y) * 2;
    }

    public insert(element:Element, center?: Vector2){
        this.elementsSize = mergeAabb( this.elementsSize , element.aabb );

        //aabbCenter(this.size)
        if(this.children){
            center = center || aabbCenter( element.aabb );
            this.insertToChild(center, element);
        }else{
            if(this.capacity > this.elements.length || this.minSize > this.sizeToCheck){
                this.elements.push(element)
            }else{
                this.subdivide();
                center = center || aabbCenter( element.aabb );
                this.insertToChild(center, element);
            }
        }
    }

    private insertToChild(center: Vector2, element: Element) {
        if (this.center.y > center.y) {
            if (this.center.x > center.x) {
                this.children[0].insert(element, center);
            } else {
                this.children[1].insert(element, center);
            }
        } else {
            if (this.center.x > center.x) {
                this.children[2].insert(element, center);
            } else {
                this.children[3].insert(element, center);

            }
        }
    }

    private subdivide(){
        this.children = [
            new QuadNode(this.capacity, this.minSize, {min:{x: this.size.min.x, y: this.size.min.y}, max: {x: this.center.x, y: this.center.y}}),
            new QuadNode(this.capacity, this.minSize, {min:{x: this.center.x, y: this.size.min.y}, max: {x: this.size.max.x, y: this.center.y}}),
            new QuadNode(this.capacity, this.minSize, {min:{x: this.size.min.x, y: this.center.y}, max: {x: this.center.x, y: this.size.max.y}}),
            new QuadNode(this.capacity, this.minSize, {min:{x: this.center.x, y: this.center.y}, max: {x: this.size.max.x, y: this.size.max.y}}),
        ]
        for (let i = 0; i < this.elements.length; i++) {
            this.insertToChild( aabbCenter(this.elements[i].aabb), this.elements[i] );
        }
        this.elements = null;
    }

    public pickFirstElement(point:Vector2, elementContainsPoint:(element:Element, point:Vector2)=>boolean):Element{
        if(this.children){
            for (let i = 0; i < this.children.length; i++) {
                const child = this.children[i];
                if(aabb2ContainsPoint(child.elementsSize, point)) {
                    const result =  child.pickFirstElement(point, elementContainsPoint);
                    if(result) {
                        return result;
                    }
                }
            }
        } else {
            for (let i = 0; i < this.elements.length; i++) {
                const element = this.elements[i];
                if(elementContainsPoint(element, point)){
                    return element;
                }
            }
        }
    }

    public isEmpty():boolean{
        return  (!this.elements || this.elements.length === 0) && !this.children;
    }
}
