import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {debounce, map} from "rxjs/operators";

@Injectable()
export class WorkLoadService {
  private _numberOfTasks: BehaviorSubject<number>;

  constructor() {
    this._numberOfTasks = new BehaviorSubject<number>(0);
  }

  get isWorking(): Observable<boolean> {
    return this._numberOfTasks.pipe(map((value) => value > 0));
  }

  get numberOfTasks(): BehaviorSubject<number> {
    return this._numberOfTasks;
  }

  public setNumberOfTasks(value: number) {
    this._numberOfTasks.next(value);
  }

  public incrementWorkload() {
    this._numberOfTasks.next(this._numberOfTasks.getValue() + 1);
  }

  public decrementWorkload() {
    this._numberOfTasks.next(this._numberOfTasks.getValue() - 1);
  }


}
