import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Guid } from "guid-typescript";
import * as hash from "object-hash";
import { Observable, Subscription, fromEvent } from "rxjs";
import { map } from "rxjs/operators";
import { Vector2, clip } from "webcad/math";
import {
  LineSegmentsFromPath,
  PolylineArea,
  aabbOfPolyline,
} from "webcad/models";
import {
  RectangleNotch,
  RectangleNotchInputFlags,
} from "../../../../../../src/app/model/basic-templates.model";
import {
  Element,
  ElementType,
} from "../../../../../../src/app/model/product-configuration/element.model";
import {
  ShapeWithHoles,
  shapeWithHolesHash,
} from "../../../../../../src/app/model/shape-with-holes";
import { UserTemplate } from "../../../../../../src/app/model/user-template.model";
import {
  MevacoState,
  ProductConfigurationAddElement,
  getRectangleNotchTemplate,
  getUserTemplates,
} from "../../../../../../src/app/store";
import {
  SetRectangleNotchA,
  SetRectangleNotchB,
  SetRectangleNotchE1,
  SetRectangleNotchE2,
  SetRectangleNotchF1,
  SetRectangleNotchF2,
  SetRectangleNotchNotchX,
  SetRectangleNotchNotchY,
  SetRectangleNotchTemplate,
} from "../../../../../../src/app/store/actions/element-creator.actions";
import { GetUserTemplates } from "../../../../../../src/app/store/actions/user-template.actions";
import { reqValRectangleNotch } from "../../../../store/reducers";
import { TemplateName } from "../../template-name";
import { TemplateUtils } from "../../templatesUtils";

@Component({
  selector: "mevaco-rectangle-notch",
  templateUrl: "./rectangle-notch.component.html",
  styleUrls: [
    "../../../element-creator/element-creator.component.css",
    "./rectangle-notch.component.css",
  ],
})
export class RectangleNotchComponent implements OnInit {
  @ViewChild("na", { static: true }) na: ElementRef;
  @ViewChild("nb", { static: true }) nb: ElementRef;
  @ViewChild("ne1", { static: true }) ne1: ElementRef;
  @ViewChild("ne2", { static: true }) ne2: ElementRef;
  @ViewChild("nf1", { static: true }) nf1: ElementRef;
  @ViewChild("nf2", { static: true }) nf2: ElementRef;
  @ViewChild("x1", { static: true }) x1: ElementRef;
  @ViewChild("x2", { static: true }) x2: ElementRef;
  @ViewChild("x3", { static: true }) x3: ElementRef;
  @ViewChild("x4", { static: true }) x4: ElementRef;
  @ViewChild("y1", { static: true }) y1: ElementRef;
  @ViewChild("y2", { static: true }) y2: ElementRef;
  @ViewChild("y3", { static: true }) y3: ElementRef;
  @ViewChild("y4", { static: true }) y4: ElementRef;

  @ViewChild("closeButton", { static: true })
  closeButton: ElementRef;
  @Output() dismissModal = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() isModalOpen = new EventEmitter();

  public rectangleNotchTemplate: RectangleNotch;
  public reqValRectangleNotch: Observable<boolean>;
  public templates: Observable<UserTemplate[]>;
  private rectangleNotchInputFlags: RectangleNotchInputFlags;
  private subscriptions: Subscription[] = [];

  constructor(
    public templateUtils: TemplateUtils,
    private store: Store<MevacoState>
  ) {}

  ngOnInit() {
    this.rectangleNotchInputFlags = {
      x1: false,
      x2: false,
      x3: false,
      x4: false,
      y1: false,
      y2: false,
      y3: false,
      y4: false,
    };

    this.store.dispatch(new GetUserTemplates());
    this.templates = this.store.pipe(select(getUserTemplates));
    this.subscriptions.push(
      this.store
        .pipe(select(getRectangleNotchTemplate))
        .subscribe((v) => (this.rectangleNotchTemplate = { ...v }))
    );
    this.reqValRectangleNotch = this.store.pipe(select(reqValRectangleNotch));
    this.createElementFromNotchRectangleTemplate();
  }

  isModalOpenMethod() {
    this.isModalOpen.emit(false);
  }

  createElementFromNotchRectangleTemplate() {
    const obs = fromEvent(this.closeButton.nativeElement, "click").pipe(
      map(() => {
        return { ...this.rectangleNotchTemplate };
      })
    );

    obs.subscribe((result) => {
      const a = result.a / 1000;
      const b = result.b / 1000;
      let e1 = result.e1 / 1000;
      let e2 = result.e2 / 1000;
      let f1 = result.f1 / 1000;
      let f2 = result.f2 / 1000;
      const x1 = result.x1 / 1000;
      const x2 = result.x2 / 1000;
      const x3 = result.x3 / 1000;
      const x4 = result.x4 / 1000;
      const y1 = result.y1 / 1000;
      const y2 = result.y2 / 1000;
      const y3 = result.y3 / 1000;
      const y4 = result.y4 / 1000;
      const leftDownNotchPoint: Vector2 =
        x3 === 0 || y3 === 0
          ? {
              x: 0,
              y: 0,
            }
          : {
              x: x3,
              y: y3,
            };
      const leftDownNotch: Vector2[] =
        x3 === 0 || y3 === 0
          ? [
              {
                x: 0,
                y: 0,
              },
            ]
          : [
              {
                x: 0,
                y: y3,
              },
              {
                x: x3,
                y: y3,
              },
              {
                x: x3,
                y: 0,
              },
            ];
      const rightDownNotchPoint: Vector2 =
        x2 === 0 || y2 === 0
          ? {
              x: b,
              y: 0,
            }
          : {
              x: b - x2,
              y: y2,
            };
      const rightDownNotch: Vector2[] =
        x2 === 0 || y2 === 0
          ? [
              {
                x: b,
                y: 0,
              },
            ]
          : [
              {
                x: b - x2,
                y: 0,
              },
              {
                x: b - x2,
                y: y2,
              },
              {
                x: b,
                y: y2,
              },
            ];
      const rightUpNotchPoint: Vector2 =
        x1 === 0 || y1 === 0
          ? {
              x: b,
              y: a,
            }
          : {
              x: b - x1,
              y: a - y1,
            };
      const rightUpNotch: Vector2[] =
        x1 === 0 || y1 === 0
          ? [
              {
                x: b,
                y: a,
              },
            ]
          : [
              {
                x: b,
                y: a - y1,
              },
              {
                x: b - x1,
                y: a - y1,
              },
              {
                x: b - x1,
                y: a,
              },
            ];
      const leftUpNotchPoint: Vector2 =
        x4 === 0 || y4 === 0
          ? {
              x: 0,
              y: a,
            }
          : {
              x: x4,
              y: a - y4,
            };
      const leftUpNotch: Vector2[] =
        x4 === 0 || y4 === 0
          ? [
              {
                x: 0,
                y: a,
              },
            ]
          : [
              {
                x: x4,
                y: a,
              },
              {
                x: x4,
                y: a - y4,
              },
              {
                x: 0,
                y: a - y4,
              },
            ];
      const path = [
        ...leftDownNotch,
        ...rightDownNotch,
        ...rightUpNotch,
        ...leftUpNotch,
      ];
      const shapeWithHoles: ShapeWithHoles = {
        holes: [],
        conture: LineSegmentsFromPath(path),
      };
      shapeWithHoles.aabb = aabbOfPolyline(shapeWithHoles.conture);
      shapeWithHoles.area = PolylineArea(shapeWithHoles.conture);
      shapeWithHoles.hash = shapeWithHolesHash(shapeWithHoles);
      if (f1 < x4 || f1 < x3) {
        f1 = x4 > x3 ? x4 : x3;
      }
      if (e1 < y3 || e1 < y2) {
        e1 = y3 > y2 ? y3 : y2;
      }
      if (f2 < x1 || f2 < x2) {
        f2 = x1 > x2 ? x1 : x2;
      }
      if (e2 < y1 || e2 < y4) {
        e2 = y1 > y4 ? y1 : y4;
      }
      const ldpt = { x: f1, y: e1 };
      const rdpt = { x: b - f2, y: e1 };
      const rupt = { x: b - f2, y: a - e2 };
      const lupt = { x: f1, y: a - e2 };
      const perforationPath = [ldpt, rdpt, rupt, lupt];
      const perforationArea = LineSegmentsFromPath(perforationPath);
      const perfAabb = aabbOfPolyline(perforationArea);
      const perfArea = PolylineArea(perforationArea);
      const perforationAreaShape: ShapeWithHoles = {
        conture: perforationArea,
        holes: [],
        aabb: perfAabb,
        area: perfArea,
      };
      perforationAreaShape.hash = hash(perforationAreaShape);
      const element: Element = {
        type: ElementType.individual,
        templateName: TemplateName.rectangleNotch,
        boundary: null,
        position: -1, // will be set inb reducer
        quantity: 1,
        a: a,
        b: b,
        aIst: a,
        bIst: b,
        e1: e1.toString(),
        e1ist: e1.toString(),
        e2: e2.toString(),
        e2ist: e2.toString(),
        f1: f1.toString(),
        f1ist: f1.toString(),
        f2: f2.toString(),
        f2ist: f2.toString(),
        openMeshE: "",
        openMeshF: "",
        toleranceWidth: 0,
        toleranceLength: 0,
        label: "",
        note: "",
        unperforated: false,
        posibleCoil: "No",
        posiblePlate: "No",
        shapes: null,
        nodes: null,
        verticesIndexes: null,
        visualizationShape: null,
        previewImageId: Guid.create().toString(),
        shape: shapeWithHoles,
        perforationAreas: [
          {
            shape: perforationAreaShape,
            rotation: 0,
            offset: { x: 0, y: 0 },
          },
        ],
        helpLines: [],
        measurements: [],
        angleMeasurements: [],
        mountings: [],
        perforationAutoCenter: true,
        area: shapeWithHoles.area,
        minRadius: null,
        minParallelEdgesDistance: null,
        minMarginDistance: null,
        isPerforationSimpleRect: null,
        isShapeSimpleRect: null,
        minMountingHoleEdgeDistance: null,
        minMountingHolesDistance: null,
        minOutsideAngle: null,
        numberOfArcs: null,
        minDistanceBetweenEdges: null,
        minDistanceBetweenMountingAndPerforatedArea: null,
        //perforation: null,
        possibleAllAcross: true,
        minDistanceBetweenPerforationAreas: null,
        lfbIst: undefined,
        lflIst: undefined,

        bendingLinesDistances: [],
        breakLines: [],
        //possiblePerforationZones: []
      };
      this.store.dispatch(new ProductConfigurationAddElement(element));
    });
  }

  setRectangleNotchTemplate(template: RectangleNotch) {
    this.store.dispatch(new SetRectangleNotchTemplate({ ...template }));
  }

  setRectangleNotchA(newA: any) {
    this.store.dispatch(new SetRectangleNotchA(newA));
  }

  setRectangleNotchE1(newE1: any) {
    this.store.dispatch(new SetRectangleNotchE1(newE1));
  }

  setRectangleNotchE2(newE2: any) {
    this.store.dispatch(new SetRectangleNotchE2(newE2));
  }

  setRectangleNotchB(newB: any) {
    this.store.dispatch(new SetRectangleNotchB(newB));
  }

  setRectangleNotchF1(newF1: any) {
    this.store.dispatch(new SetRectangleNotchF1(newF1));
  }

  setRectangleNotchF2(newF2: any) {
    this.store.dispatch(new SetRectangleNotchF2(newF2));
  }

  setRectangleNotchNotchX(index: number, v: any) {
    this.store.dispatch(
      new SetRectangleNotchNotchX({
        index: index,
        x: clip(v, this.rectangleNotchTemplate.b * 0.5),
        y: clip(
          this.rectangleNotchTemplate["y" + index.toString()],
          this.rectangleNotchTemplate.a * 0.5
        ),
        fill: this.rectangleNotchInputFlags,
      })
    );
    this.rectangleNotchInputFlags["x" + index.toString()] = true;
  }

  setRectangleNotchNotchY(index: number, v: any) {
    this.store.dispatch(
      new SetRectangleNotchNotchY({
        index: index,
        x: clip(v, this.rectangleNotchTemplate.b * 0.5),
        y: clip(
          this.rectangleNotchTemplate["y" + index.toString()],
          this.rectangleNotchTemplate.a * 0.5
        ),
        fill: this.rectangleNotchInputFlags,
      })
    );
    this.rectangleNotchInputFlags["y" + index.toString()] = true;
  }

  dismiss(str: string) {
    this.dismissModal.emit(str);
  }

  close() {
    this.closeModal.emit({ ...this.rectangleNotchTemplate });
  }
}
