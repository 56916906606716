<div class="nav-container">
  <div class="logo-wrapper">
    <img class="logo" [src]="logoUrl | async" />
  </div>
  <div class="nav-steps-wrapper">
    <div
    *ngIf="isNavigationStepVisible(Step.template)"
      [ngClass]="{
        'nav-button': true,
        'nav-button-active': (step | async) === Step.template
      }"
      (click)="setStep(Step.template)"
    >
      <div class="nav-image nav-image--second"></div>
      <span class="nav-button-name">{{ translate("template") }}</span>
      <img
        class="chevron-right"
        src="assets/arrow-right.svg"
        [@activation]="(step | async) === Step.template ? 'active' : 'inactive'"
      />
    </div>
    <div
    *ngIf="isNavigationStepVisible(Step.shape)"
      [ngClass]="{
        'nav-button': true,
        'nav-button-active': (step | async) === Step.shape,
        inactiveNav: !this.hasId
      }"
      (click)="setStep(Step.shape)"
    >
      <div class="nav-image nav-image--third"></div>
      <span class="nav-button-name">{{ translate("shape") }}</span>
      <img
        class="chevron-right"
        src="assets/arrow-right.svg"
        [@activation]="(step | async) === Step.shape ? 'active' : 'inactive'"
      />
    </div>
    <div
    *ngIf="isNavigationStepVisible(Step.pattern)"
      [ngClass]="{
        'nav-button': true,
        'nav-button-active': (step | async) === Step.pattern,
        inactiveNav: !this.hasId
      }"
      (click)="setStep(Step.pattern)"
    >
      <div class="nav-image nav-image--fourth"></div>
      <span class="nav-button-name">{{ productType === 0 ? translate("fine adjustment") : translate("hole pattern") }}</span>
      <img
        class="chevron-right"
        src="assets/arrow-right.svg"
        [@activation]="(step | async) === Step.pattern ? 'active' : 'inactive'"
      />
    </div>
    <div
    *ngIf="isNavigationStepVisible(Step.attachment)"
      [ngClass]="{
        'nav-button': true,
        'nav-button-active': (step | async) === Step.attachment,
        inactiveNav: !this.hasId
      }"
      (click)="setStep(Step.attachment)"
    >
      <div class="nav-image nav-image--fifth"></div>
      <span class="nav-button-name">{{ translate("attachment") }}</span>
      <img
        class="chevron-right"
        src="assets/arrow-right.svg"
        [@activation]="
          (step | async) === Step.attachment ? 'active' : 'inactive'
        "
      />
    </div>
    <div
    *ngIf="isNavigationStepVisible(Step.design)"
      [ngClass]="{
        'nav-button': true,
        'nav-button-active': (step | async) === Step.design,
        inactiveNav: !this.hasId
      }"
      (click)="setStep(Step.design)"
    >
      <div class="nav-image nav-image--sixth"></div>
      <span class="nav-button-name">{{ translate("design") }}</span>
    </div>
        <div
        *ngIf="isNavigationStepVisible(Step.cockpit)"
        [ngClass]="{
            'nav-button': true,
            'nav-button-active': (step | async) === Step.cockpit
        }"
        (click)="setStep(Step.cockpit)"
        >
            <div class="nav-image nav-image--first"></div>
            <span class="nav-button-name">{{ translate("cockpit") }}</span>
            <img class="chevron-right"
            src="assets/arrow-right.svg"
            [@activation]="(step | async) === Step.cockpit ? 'active' : 'inactive'"
            >
        </div>
    </div>
    <div class="action-icons-wrapper">
        <div class="question-icon" (click)="showTutorialModal(tutorialModal)">
            <span>{{translate('Tutorials', 'designer')}}</span>
        </div>
    <button
      type="button"
      class="close-icon"
      aria-label="Close"
      (click)="showExitModal(exitModal)"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<ng-template #exitModal let-modal>
  <div class="dialog-modal-header dialog-modal-header--small">
    <div class="dialog-modal-title-container">
      <div class="dialog-modal-title-content">
        <h4
          class="dialog-modal-title dialog-modal-title--small"
          id="modal-basic-title"
        >
          <span>{{ translate("Exit") }}</span>
        </h4>
      </div>
    </div>

    <button
      type="button"
      class="close btn-close"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="dialog-modal-body dialog-modal-body--small">
    <p class="dialog-modal-description--small">
      {{ translate("exit configuration content", "designer") }}
    </p>
  </div>

  <div
    class="dialog-modal-footer dialog-modal-footer--small dialog-modal-footer--one-btn"
  >
    <button
      #closeButton
      type="button"
      class="btn btn-primary dialog-modal-btn--small"
      (click)="exit()"
    >
      {{ translate("OK") }}
    </button>
  </div>
</ng-template>

<ng-template let-modal #tutorialModal>
  <app-tutorial-modal (closeModal)="modal.close($event)"></app-tutorial-modal>
</ng-template>
