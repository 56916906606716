import { Store } from "@ngrx/store";
import { Webcad } from "webcad";
import { PointerState } from "webcad/collision";
import {
  addVectors2,
  crossVector2,
  distanceVector2,
  dotVector2,
  getLeftPerpendicularVector2,
  getRightPerpendicularVector2,
  lengthVector2,
  LineCircleIntersect2D,
  LineIntersect2D,
  multiplyVector2byScalar,
  normalizeVector2,
  normalizeVector3,
  sqrDistanceVector2,
  sqrLengthVector2,
  subVectors2,
  subVectors3,
  Vector3,
  Vectors2Equal,
} from "webcad/math";
import {
  aabbCenter,
  aabbOfPolyline,
  clipPointToLineSegment,
  getClosestPointOnPolyline,
  getShortestPathBetweenPolylinesAndPolyline,
  getShortestPathBetweenPolylinesAndSegment,
  getShortestPathBetweenSegments,
  getShortestPathBetweenTwoPolylines,
  isPointInArcSegment,
  MeasurementModel,
  movePolyline,
  movePolylines,
  projectPointOnSegment,
  projectPointToPolyline,
  Segment,
  SegmentType,
} from "webcad/models";
import { MeasurementVisualizer } from "webcad/visualizers";
import { BendingLine } from "../../model/bending-line.model";
import { HelpLineToSegment } from "../../model/help-line.model";
import { MountingAttachment, scaleMounting } from "../../model/mounting.model";
import { UnderPointerType } from "../../model/pointer-state.model";
import {
  getRotatedStampAabb,
  getRotatedStampShape,
} from "../../model/stamp.model";
import { PerforationIntersection } from "../../providers/colliders/drawingPerforationCollider";
import { HelpingLineBaseCollider } from "../../providers/colliders/helpline.collider";
import { MevacoCollider } from "../../providers/colliders/mevaco.collider";
import { MountingIntersection } from "../../providers/colliders/mounting.collider";
import { ClosestSegments } from "../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../providers/scene.provider";
import { TranslationProvider } from "../../providers/translation.provider";
import { WebcadProvider } from "../../providers/webcad.provider";
import {
  AddMeasurement,
  SetHintMessage,
} from "../../store/actions/drawing.actions";
import { SetPerforationCollider } from "../../store/actions/snap-options.actions";
import { MevacoState } from "../../store/reducers";
import { Tool } from "../tool.interface";
import {Scene, Node} from "webcad/babylonjs/core";
export class MeasurementTool extends Tool {
  private scene: Scene;
  private beginPoint: Vector3 = null;
  private endPoint: Vector3 = null;
  private currentModel: MeasurementModel;
  private currentModelVisualizer: MeasurementVisualizer;
  private rootNode: Node;
  private webcad: Webcad;
  private beginType: UnderPointerType;
  private beginObject: any;

  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private translationProvider: TranslationProvider,
    private webcadProvider: WebcadProvider
  ) {
    super();
    this.sceneProvider.getSubscription().subscribe((scene) => {
      if (this.rootNode) {
        this.rootNode.dispose();
        this.rootNode = null;
      }
      if (scene !== null) {
        this.scene = scene;
        this.rootNode = new Node("measurementToolNode", this.scene);
      }
    });
    this.webcadProvider.getObservable().subscribe((webcad) => {
      this.webcad = webcad;
    });
  }

  activate() {
    this.store.dispatch(new SetPerforationCollider(true));
    this.currentModel = {
      editable: false,
      direction: { x: 0, y: 0, z: 0 },
      distance: 0,
      start: null,
      focusable: false,
      measurementDirection: { x: 0, y: 0, z: 0 },
      visible: false,
      exchange: {
        value: 1,
        onInputLive: null, // to be set by tool
        onInputConfirmed: null, // to be set by tool
        inputValidation: (val) => val !== "" && !isNaN(+val),

        fromModel: (value: number) => (value * 1000).toFixed(1),
        toModel: (value: string) => +value / 1000,
      },
      maxValue: 1,
      mask: 0x00000001,
    };
    if (this.currentModelVisualizer) {
      this.currentModelVisualizer.dispose();
      this.currentModelVisualizer = null;
    }
    this.currentModelVisualizer = new MeasurementVisualizer();
    this.store.dispatch(
      new SetHintMessage(this.translate("Set start element"))
    );
  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {}

  onMouseClick(pointerState: PointerState) {
    if (!this.currentModel.start) {
      if (pointerState.intersection && pointerState.intersection.collider) {
        const mevacoCollider = pointerState.intersection
          .collider as MevacoCollider;
        if (
          mevacoCollider &&
          mevacoCollider.objectType === UnderPointerType.NODE
        ) {
          this.beginPoint = pointerState.position;
          this.currentModel.start = pointerState.position;
          this.currentModel.visible = true;
          this.currentModelVisualizer.init(
            this.rootNode,
            { viewMask: 0xffffffff, model: this.currentModel },
            this.webcad
          );
          this.beginType = UnderPointerType.NODE;
          this._dirty = true;
          this.store.dispatch(
            new SetHintMessage(this.translate("Set end element"))
          );
        } else if (
          mevacoCollider &&
          mevacoCollider.objectType === UnderPointerType.SEGMENT
        ) {
          this.beginPoint = pointerState.position;
          this.currentModel.start = pointerState.position;
          this.currentModel.visible = true;
          this.currentModelVisualizer.init(
            this.rootNode,
            { viewMask: 0xffffffff, model: this.currentModel },
            this.webcad
          );
          this.beginType = UnderPointerType.SEGMENT;
          this.beginObject = mevacoCollider.object as Segment;
          this._dirty = true;
          this.store.dispatch(
            new SetHintMessage(this.translate("Set end element"))
          );
        } else if (
          mevacoCollider &&
          mevacoCollider.objectType === UnderPointerType.VIRTUAL_NODE
        ) {
          this.beginPoint = pointerState.position;
          this.currentModel.start = pointerState.position;
          this.currentModel.visible = true;
          this.currentModelVisualizer.init(
            this.rootNode,
            { viewMask: 0xffffffff, model: this.currentModel },
            this.webcad
          );
          this.beginType = UnderPointerType.VIRTUAL_NODE;
          this._dirty = true;
          this.store.dispatch(
            new SetHintMessage(this.translate("Set end element"))
          );
        } else if (
          mevacoCollider &&
          mevacoCollider.objectType === UnderPointerType.PERFORATOIN_HOLE
        ) {
          this.beginPoint = pointerState.position;
          this.currentModel.start = pointerState.position;
          this.currentModel.visible = true;
          this.currentModelVisualizer.init(
            this.rootNode,
            { viewMask: 0xffffffff, model: this.currentModel },
            this.webcad
          );
          this.beginType = UnderPointerType.PERFORATOIN_HOLE;
          this.beginObject = pointerState.intersection;
          this._dirty = true;
          this.store.dispatch(
            new SetHintMessage(this.translate("Set end element"))
          );
        } else if (
          mevacoCollider &&
          mevacoCollider.objectType === UnderPointerType.MOUNTING
        ) {
          this.beginPoint = pointerState.position;
          this.currentModel.start = pointerState.position;
          this.currentModel.visible = true;
          this.currentModelVisualizer.init(
            this.rootNode,
            { viewMask: 0xffffffff, model: this.currentModel },
            this.webcad
          );
          this.beginType = UnderPointerType.MOUNTING;
          this.beginObject = pointerState.intersection;
          this._dirty = true;
        } else if (
          mevacoCollider &&
          mevacoCollider.objectType === UnderPointerType.BENDING_LINE
        ) {
          this.beginPoint = pointerState.position;
          this.currentModel.start = pointerState.position;
          this.currentModel.visible = true;
          this.currentModelVisualizer.init(
            this.rootNode,
            { viewMask: 0xffffffff, model: this.currentModel },
            this.webcad
          );
          this.beginType = UnderPointerType.BENDING_LINE;
          this.beginObject = mevacoCollider.object as BendingLine;
          this._dirty = true;
          this.store.dispatch(
            new SetHintMessage(this.translate("Set end element"))
          );
        } else if (
          mevacoCollider &&
          mevacoCollider.objectType === UnderPointerType.HELP_LINE
        ) {
          this.beginPoint = pointerState.position;
          this.currentModel.start = pointerState.position;
          this.currentModel.visible = true;
          this.currentModelVisualizer.init(
            this.rootNode,
            { viewMask: 0xffffffff, model: this.currentModel },
            this.webcad
          );
          this.beginType = UnderPointerType.HELP_LINE;
          this.beginObject = (<HelpingLineBaseCollider>(
            pointerState.intersection.collider
          )).helpLine;
          this._dirty = true;
          this.store.dispatch(
            new SetHintMessage(this.translate("Set end element"))
          );
        }
      }
    } else if (!this.endPoint) {
      const pos = this.calculateEndPoint(pointerState);
      if (pos) {
        this.endPoint = pos;
      }
    } else {
      this.onConfirm();
    }
  }

  onMouseDown(pointerState: PointerState) {}

  onMouseMove(pointerState: PointerState) {
    if (!this.currentModel.start) {
      if (pointerState.intersection && pointerState.intersection.collider) {
        const mevacoCollider = pointerState.intersection
          .collider as MevacoCollider;
        if (
          mevacoCollider &&
          mevacoCollider.objectType === UnderPointerType.NODE
        ) {
          this.beginPoint = pointerState.position;
        }
      }
    } else if (!this.endPoint) {
      const pos = this.calculateEndPoint(pointerState);
      if (pos) {
        this.updateModelWithPoint(pos);
        this.visualizeCurrentMeasurement({ x: 0, y: 0, z: 0 });
      } else {
        this.updateModelWithPoint(pointerState.position);
        this.visualizeCurrentMeasurement({ x: 0.75, y: 0.75, z: 0.75 });
      }
    } else {
      this.updateModelDistance(pointerState.position);
      this.visualizeCurrentMeasurement({ x: 0, y: 0, z: 0 });
    }
  }

  calculateEndPoint(pointerState: PointerState): Vector3 {
    if (pointerState.intersection && pointerState.intersection.collider) {
      const mevacoCollider = pointerState.intersection
        .collider as MevacoCollider;
      if (mevacoCollider) {
        if (mevacoCollider.objectType === UnderPointerType.NODE) {
          if (
            this.beginType === UnderPointerType.NODE ||
            this.beginType === UnderPointerType.VIRTUAL_NODE
          ) {
            return pointerState.position;
          } else if (this.beginType === UnderPointerType.SEGMENT) {
            const segment = this.beginObject as Segment;
            this.currentModel.start = getClosestPointOnSegment(
              segment,
              pointerState.position
            );
            return pointerState.position;
          } else if (this.beginType === UnderPointerType.BENDING_LINE) {
            const segment = getBendingLineSegment(
              pointerState.position,
              this.beginObject as BendingLine
            );
            this.currentModel.start = getClosestPointOnSegment(
              segment,
              pointerState.position
            );
            return pointerState.position;
          } else if (this.beginType === UnderPointerType.PERFORATOIN_HOLE) {
            const beginObject = this.beginObject as PerforationIntersection;
            const nodePos = pointerState.position;
            const rotated = getRotatedStampShape(
              beginObject.stamp,
              beginObject.stampPolylineIndex,
              beginObject.perforation.rotation
            );
            const p1 = movePolyline(
              rotated[0],
              beginObject.rotatedStampPolylinePosition
            );
            const closest = getClosestPointOnPolyline(nodePos, p1);
            if (closest) {
              this.currentModel.start = {
                x: closest.x,
                y: closest.y,
                z: pointerState.position.z,
              };
              return nodePos;
            }
          } else if (this.beginType === UnderPointerType.MOUNTING) {
            const beginObject: MountingIntersection = this
              .beginObject as MountingIntersection;
            const nodePos = pointerState.position;
            if (Vectors2Equal(this.beginPoint, beginObject.object.position)) {
              return nodePos;
            } else {
              const p1 = scaleMounting(
                beginObject.object.mountingRef,
                beginObject.object.shape,
                beginObject.object.rotation,
                beginObject.object.position
              );
              const closest = projectPointToPolyline(p1, nodePos);
              if (closest) {
                this.currentModel.start = {
                  x: closest.x,
                  y: closest.y,
                  z: pointerState.position.z,
                };
                return nodePos;
              }
            }
          } else if (this.beginType === UnderPointerType.HELP_LINE) {
            let pp = projectPointOnSegment(
              pointerState.position,
              HelpLineToSegment(this.beginObject),
              true
            );
            if (!!pp) {
              pp = clipPointToLineSegment(
                pp,
                HelpLineToSegment(this.beginObject)
              );
              this.currentModel.start = {
                x: pp.x,
                y: pp.y,
                z: pointerState.position.z,
              };
            } else {
              const pToSegmentBegin = sqrLengthVector2(
                subVectors2(
                  HelpLineToSegment(this.beginObject).begin,
                  pointerState.position
                )
              );
              const pToSegmentEnd = sqrLengthVector2(
                subVectors2(
                  HelpLineToSegment(this.beginObject).end,
                  pointerState.position
                )
              );
              this.currentModel.start =
                pToSegmentBegin < pToSegmentEnd
                  ? {
                      x: HelpLineToSegment(this.beginObject).begin.x,
                      y: HelpLineToSegment(this.beginObject).begin.y,
                      z: pointerState.position.z,
                    }
                  : {
                      x: HelpLineToSegment(this.beginObject).end.x,
                      y: HelpLineToSegment(this.beginObject).end.y,
                      z: pointerState.position.z,
                    };
            }
            return pointerState.position;
          }
        }
        if (mevacoCollider.objectType === UnderPointerType.VIRTUAL_NODE) {
          if (
            this.beginType === UnderPointerType.NODE ||
            this.beginType === UnderPointerType.VIRTUAL_NODE
          ) {
            return pointerState.position;
          } else if (this.beginType === UnderPointerType.SEGMENT) {
            const segment = this.beginObject as Segment;
            this.currentModel.start = getClosestPointOnSegment(
              segment,
              pointerState.position
            );
            return pointerState.position;
          } else if (this.beginType === UnderPointerType.BENDING_LINE) {
            const segment = getBendingLineSegment(
              pointerState.position,
              this.beginObject as BendingLine
            );
            this.currentModel.start = getClosestPointOnSegment(
              segment,
              pointerState.position
            );
            return pointerState.position;
          } else if (this.beginType === UnderPointerType.PERFORATOIN_HOLE) {
            const beginObject = this.beginObject as PerforationIntersection;
            const nodePos = pointerState.position;
            const rotated = getRotatedStampShape(
              beginObject.stamp,
              beginObject.stampPolylineIndex,
              beginObject.perforation.rotation
            );
            const p1 = movePolyline(
              rotated[0],
              beginObject.rotatedStampPolylinePosition
            );
            const closest = getClosestPointOnPolyline(nodePos, p1);
            if (closest) {
              this.currentModel.start = {
                x: closest.x,
                y: closest.y,
                z: pointerState.position.z,
              };
              return nodePos;
            }
          } else if (this.beginType === UnderPointerType.MOUNTING) {
            const beginObject: MountingIntersection = this
              .beginObject as MountingIntersection;
            const nodePos = pointerState.position;
            if (Vectors2Equal(this.beginPoint, beginObject.object.position)) {
              return nodePos;
            } else {
              const p1 = scaleMounting(
                beginObject.object.mountingRef,
                beginObject.object.shape,
                beginObject.object.rotation,
                beginObject.object.position
              );
              const closest = projectPointToPolyline(p1, nodePos);
              if (closest) {
                this.currentModel.start = {
                  x: closest.x,
                  y: closest.y,
                  z: pointerState.position.z,
                };
                return nodePos;
              }
            }
          } else if (this.beginType === UnderPointerType.HELP_LINE) {
            let pp = projectPointOnSegment(
              pointerState.position,
              HelpLineToSegment(this.beginObject),
              true
            );
            if (!!pp) {
              pp = clipPointToLineSegment(
                pp,
                HelpLineToSegment(this.beginObject)
              );
              this.currentModel.start = {
                x: pp.x,
                y: pp.y,
                z: pointerState.position.z,
              };
            } else {
              const pToSegmentBegin = sqrLengthVector2(
                subVectors2(
                  HelpLineToSegment(this.beginObject).begin,
                  pointerState.position
                )
              );
              const pToSegmentEnd = sqrLengthVector2(
                subVectors2(
                  HelpLineToSegment(this.beginObject).end,
                  pointerState.position
                )
              );
              this.currentModel.start =
                pToSegmentBegin < pToSegmentEnd
                  ? {
                      x: HelpLineToSegment(this.beginObject).begin.x,
                      y: HelpLineToSegment(this.beginObject).begin.y,
                      z: pointerState.position.z,
                    }
                  : {
                      x: HelpLineToSegment(this.beginObject).end.x,
                      y: HelpLineToSegment(this.beginObject).end.y,
                      z: pointerState.position.z,
                    };
            }
            return pointerState.position;
          }
        }
        if (mevacoCollider.objectType === UnderPointerType.SEGMENT) {
          return this.calculateEndPointForSegmentStart(
            pointerState,
            mevacoCollider.object as Segment
          );
        } else if (
          mevacoCollider.objectType === UnderPointerType.BENDING_LINE
        ) {
          const segment = getBendingLineSegment(
            this.beginPoint,
            mevacoCollider.object as BendingLine
          );
          return this.calculateEndPointForSegmentStart(pointerState, segment);
        }
        if (mevacoCollider.objectType === UnderPointerType.PERFORATOIN_HOLE) {
          const endObject =
            pointerState.intersection as PerforationIntersection;
          if (this.beginType === UnderPointerType.PERFORATOIN_HOLE) {
            const beginObject = this.beginObject as PerforationIntersection;
            if (beginObject !== endObject) {
              const xDir = { x: 1, y: 0 };
              const yDir = { x: 0, y: 1 };
              const bToE = normalizeVector2(
                subVectors2(endObject.position, beginObject.position)
              );
              let measureB: Vector3 = null;
              let measureE: Vector3 = null;
              if (Math.abs(dotVector2(xDir, bToE)) > 0.5) {
                // its either on left or right more
                const endOnRight =
                  beginObject.position.x < endObject.position.x;
                const mouseOnRight = endObject.pointerDelta.x > 0;
                const beginAabb = getRotatedStampAabb(
                  beginObject.stamp,
                  beginObject.stampPolylineIndex,
                  beginObject.perforation.rotation
                );
                const endAabb = getRotatedStampAabb(
                  endObject.stamp,
                  endObject.stampPolylineIndex,
                  endObject.perforation.rotation
                );
                const xOffset = (beginAabb.max.x - beginAabb.min.x) / 2;
                const xOffsetE = (endAabb.max.x - endAabb.min.x) / 2;
                if (
                  (endOnRight && !mouseOnRight) ||
                  (!endOnRight && !mouseOnRight)
                ) {
                  measureB = {
                    x: beginObject.position.x + xOffset,
                    y: beginObject.position.y,
                    z: beginObject.position.z,
                  };
                  measureE = {
                    x: endObject.position.x - xOffsetE,
                    y: beginObject.position.y,
                    z: beginObject.position.z,
                  };
                } else {
                  measureB = {
                    x: beginObject.position.x - xOffset,
                    y: beginObject.position.y,
                    z: beginObject.position.z,
                  };
                  measureE = {
                    x: endObject.position.x + xOffsetE,
                    y: beginObject.position.y,
                    z: beginObject.position.z,
                  };
                }
              } else {
                // its more over or under
                const endIsUp = beginObject.position.y < endObject.position.y;
                const mouseIsUp = endObject.pointerDelta.y > 0;
                const beginAabb = getRotatedStampAabb(
                  beginObject.stamp,
                  beginObject.stampPolylineIndex,
                  beginObject.perforation.rotation
                );
                const endAabb = getRotatedStampAabb(
                  endObject.stamp,
                  endObject.stampPolylineIndex,
                  endObject.perforation.rotation
                );
                const yOffset = (beginAabb.max.y - beginAabb.min.y) / 2;
                const yOffsetE = (endAabb.max.y - endAabb.min.y) / 2;
                if ((endIsUp && !mouseIsUp) || (!endIsUp && !mouseIsUp)) {
                  measureB = {
                    x: beginObject.position.x,
                    y: beginObject.position.y + yOffset,
                    z: beginObject.position.z,
                  };
                  measureE = {
                    x: beginObject.position.x,
                    y: endObject.position.y - yOffsetE,
                    z: beginObject.position.z,
                  };
                } else {
                  measureB = {
                    x: beginObject.position.x,
                    y: beginObject.position.y - yOffset,
                    z: beginObject.position.z,
                  };
                  measureE = {
                    x: beginObject.position.x,
                    y: endObject.position.y + yOffsetE,
                    z: beginObject.position.z,
                  };
                }
              }
              this.currentModel.start = measureB;
              return measureE;
            }
          } else if (
            this.beginType === UnderPointerType.NODE ||
            this.beginType === UnderPointerType.VIRTUAL_NODE
          ) {
            const nodePos = this.beginPoint;
            const rotated = getRotatedStampShape(
              endObject.stamp,
              endObject.stampPolylineIndex,
              endObject.perforation.rotation
            );
            const p1 = movePolyline(
              rotated[0],
              endObject.rotatedStampPolylinePosition
            );
            const closest = getClosestPointOnPolyline(nodePos, p1);
            if (closest) {
              return { x: closest.x, y: closest.y, z: pointerState.position.z };
            }
          } else if (this.beginType === UnderPointerType.SEGMENT) {
            const segment = this.beginObject as Segment;
            const rotated = getRotatedStampShape(
              endObject.stamp,
              endObject.stampPolylineIndex,
              endObject.perforation.rotation
            );
            const p1 = movePolylines(
              rotated,
              endObject.rotatedStampPolylinePosition
            );
            const closestDist = getShortestPathBetweenPolylinesAndSegment(
              p1,
              segment
            );
            if (closestDist) {
              this.currentModel.start = {
                x: closestDist.end.x,
                y: closestDist.end.y,
                z: pointerState.position.z,
              };
              return {
                x: closestDist.begin.x,
                y: closestDist.begin.y,
                z: pointerState.position.z,
              };
            }
          } else if (this.beginType === UnderPointerType.BENDING_LINE) {
            const segment = getBendingLineSegment(
              pointerState.position,
              this.beginObject as BendingLine
            );
            const rotated = getRotatedStampShape(
              endObject.stamp,
              endObject.stampPolylineIndex,
              endObject.perforation.rotation
            );
            const p1 = movePolylines(
              rotated,
              endObject.rotatedStampPolylinePosition
            );
            const closestDist = getShortestPathBetweenPolylinesAndSegment(
              p1,
              segment
            );
            if (closestDist) {
              this.currentModel.start = {
                x: closestDist.end.x,
                y: closestDist.end.y,
                z: pointerState.position.z,
              };
              return {
                x: closestDist.begin.x,
                y: closestDist.begin.y,
                z: pointerState.position.z,
              };
            }
          } else if (this.beginType === UnderPointerType.MOUNTING) {
            const beginObject: MountingIntersection = this
              .beginObject as MountingIntersection;
            const rotated = getRotatedStampShape(
              endObject.stamp,
              endObject.stampPolylineIndex,
              endObject.perforation.rotation
            );
            const p2 = movePolyline(
              rotated[0],
              endObject.rotatedStampPolylinePosition
            );
            if (Vectors2Equal(this.beginPoint, beginObject.object.position)) {
              const pp = getClosestPointOnPolyline(this.beginPoint, p2);
              if (pp) {
                return { x: pp.x, y: pp.y, z: pointerState.position.z };
              }
            } else {
              const p1 = scaleMounting(
                beginObject.object.mountingRef,
                beginObject.object.shape,
                beginObject.object.rotation,
                beginObject.object.position
              );
              const closestDist = getShortestPathBetweenPolylinesAndPolyline(
                [p2],
                p1
              );
              if (closestDist) {
                this.currentModel.start = {
                  x: closestDist.begin.x,
                  y: closestDist.begin.y,
                  z: pointerState.position.z,
                };
                return {
                  x: closestDist.end.x,
                  y: closestDist.end.y,
                  z: pointerState.position.z,
                };
              }
            }
          } else if (this.beginType === UnderPointerType.HELP_LINE) {
            const beginObject = HelpLineToSegment(this.beginObject) as Segment;
            const rotated = getRotatedStampShape(
              endObject.stamp,
              endObject.stampPolylineIndex,
              endObject.perforation.rotation
            );
            const p1 = movePolylines(
              rotated,
              endObject.rotatedStampPolylinePosition
            );
            const closestDist = getShortestPathBetweenPolylinesAndSegment(
              p1,
              beginObject
            );
            if (closestDist) {
              this.currentModel.start = {
                x: closestDist.begin.x,
                y: closestDist.begin.y,
                z: pointerState.position.z,
              };
              return {
                x: closestDist.end.x,
                y: closestDist.end.y,
                z: pointerState.position.z,
              };
            }
          }
        }
        if (mevacoCollider.objectType === UnderPointerType.MOUNTING) {
          const endObject: MountingIntersection =
            pointerState.intersection as MountingIntersection;
          const onEndCenter = Vectors2Equal(
            pointerState.position,
            endObject.object.position
          );
          if (this.beginType === UnderPointerType.MOUNTING) {
            const beginObject: MountingIntersection = this
              .beginObject as MountingIntersection;
            const onBeginCenter = Vectors2Equal(
              this.beginPoint,
              beginObject.object.position
            );
            if (!onBeginCenter && !onEndCenter) {
              if (beginObject.object === endObject.object) {
                const polyline = scaleMounting(
                  beginObject.object.mountingRef,
                  beginObject.object.shape,
                  beginObject.object.rotation,
                  beginObject.object.position
                );
                const aabb = aabbOfPolyline(polyline);
                const center = aabbCenter(aabb);
                const dir = subVectors2(this.beginPoint, pointerState.position);
                if (Math.abs(dir.x) < Math.abs(dir.y)) {
                  this.currentModel.start = {
                    x: center.x,
                    y: aabb.min.y,
                    z: pointerState.position.z,
                  };
                  return {
                    x: center.x,
                    y: aabb.max.y,
                    z: pointerState.position.z,
                  };
                } else {
                  this.currentModel.start = {
                    x: aabb.min.x,
                    y: center.y,
                    z: pointerState.position.z,
                  };
                  return {
                    x: aabb.max.x,
                    y: center.y,
                    z: pointerState.position.z,
                  };
                }
              }
              const beginPolyline = scaleMounting(
                beginObject.object.mountingRef,
                beginObject.object.shape,
                beginObject.object.rotation,
                beginObject.object.position
              );
              const endPolyline = scaleMounting(
                endObject.object.mountingRef,
                endObject.object.shape,
                endObject.object.rotation,
                endObject.object.position
              );
              const closestDist = getShortestPathBetweenTwoPolylines(
                beginPolyline,
                endPolyline
              );
              if (closestDist) {
                this.currentModel.start = {
                  x: closestDist.begin.x,
                  y: closestDist.begin.y,
                  z: pointerState.position.z,
                };
                return {
                  x: closestDist.end.x,
                  y: closestDist.end.y,
                  z: pointerState.position.z,
                };
              }
            } else if (!onBeginCenter && onEndCenter) {
              const beginPolyline = scaleMounting(
                beginObject.object.mountingRef,
                beginObject.object.shape,
                beginObject.object.rotation,
                beginObject.object.position
              );
              const closest = projectPointToPolyline(
                beginPolyline,
                pointerState.position
              );
              if (closest) {
                this.currentModel.start = {
                  x: closest.x,
                  y: closest.y,
                  z: pointerState.position.z,
                };
                return pointerState.position;
              }
            } else if (onBeginCenter && !onEndCenter) {
              const endPolyline = scaleMounting(
                endObject.object.mountingRef,
                endObject.object.shape,
                endObject.object.rotation,
                endObject.object.position
              );
              const closest = projectPointToPolyline(
                endPolyline,
                this.beginPoint
              );
              if (closest) {
                return {
                  x: closest.x,
                  y: closest.y,
                  z: pointerState.position.z,
                };
              }
            } else if (onBeginCenter && onEndCenter) {
              return pointerState.position;
            }
          } else if (
            this.beginType === UnderPointerType.NODE ||
            this.beginType === UnderPointerType.VIRTUAL_NODE
          ) {
            if (onEndCenter) {
              return pointerState.position;
            } else {
              const endPolyline = scaleMounting(
                endObject.object.mountingRef,
                endObject.object.shape,
                endObject.object.rotation,
                endObject.object.position
              );
              const closest = projectPointToPolyline(
                endPolyline,
                this.beginPoint
              );
              if (closest) {
                return {
                  x: closest.x,
                  y: closest.y,
                  z: pointerState.position.z,
                };
              }
            }
          } else if (this.beginType === UnderPointerType.SEGMENT) {
            const segment: Segment = this.beginObject as Segment;
            const sae = getStartAndEndPosBetweenSegmentAndMounting(
              onEndCenter,
              segment,
              endObject.object,
              pointerState.position
            );
            this.currentModel.start = sae.start;
            return sae.end;
          } else if (this.beginType === UnderPointerType.BENDING_LINE) {
            const segment: Segment = getBendingLineSegment(
              pointerState.position,
              this.beginObject as BendingLine
            );
            const sae = getStartAndEndPosBetweenSegmentAndMounting(
              onEndCenter,
              segment,
              endObject.object,
              pointerState.position
            );
            this.currentModel.start = sae.start;
            return sae.end;
          } else if (this.beginType === UnderPointerType.PERFORATOIN_HOLE) {
            const beginObject = this.beginObject as PerforationIntersection;
            const rotated = getRotatedStampShape(
              beginObject.stamp,
              beginObject.stampPolylineIndex,
              beginObject.perforation.rotation
            );
            const holePolyline = movePolyline(
              rotated[0],
              beginObject.rotatedStampPolylinePosition
            );
            // const holePolyline = movePolylines(beginObject.perforation.stamp.polylines, beginObject.position);
            if (onEndCenter) {
              const closest = getClosestPointOnPolyline(
                pointerState.position,
                holePolyline
              );
              if (closest) {
                return {
                  x: closest.x,
                  y: closest.y,
                  z: pointerState.position.z,
                };
              }
            } else {
              const endPolyline = scaleMounting(
                endObject.object.mountingRef,
                endObject.object.shape,
                endObject.object.rotation,
                endObject.object.position
              );
              const closestDist = getShortestPathBetweenPolylinesAndPolyline(
                [holePolyline],
                endPolyline
              );
              if (closestDist) {
                this.currentModel.start = {
                  x: closestDist.begin.x,
                  y: closestDist.begin.y,
                  z: pointerState.position.z,
                };
                return {
                  x: closestDist.end.x,
                  y: closestDist.end.y,
                  z: pointerState.position.z,
                };
              }
            }
          } else if (this.beginType === UnderPointerType.HELP_LINE) {
            const beginObject: Segment = HelpLineToSegment(
              this.beginObject
            ) as Segment;
            if (onEndCenter) {
              const closest = projectPointOnSegment(
                pointerState.position,
                beginObject
              );
              if (closest) {
                this.currentModel.start = {
                  x: closest.x,
                  y: closest.y,
                  z: pointerState.position.z,
                };
                return pointerState.position;
              }
            } else {
              const endPolyline = scaleMounting(
                endObject.object.mountingRef,
                endObject.object.shape,
                endObject.object.rotation,
                endObject.object.position
              );
              const closestDist = getShortestPathBetweenTwoPolylines(
                [beginObject],
                endPolyline
              );
              if (closestDist) {
                this.currentModel.start = {
                  x: closestDist.begin.x,
                  y: closestDist.begin.y,
                  z: pointerState.position.z,
                };
                return {
                  x: closestDist.end.x,
                  y: closestDist.end.y,
                  z: pointerState.position.z,
                };
              }
            }
          }
        }
        if (mevacoCollider.objectType === UnderPointerType.HELP_LINE) {
          if (
            this.beginType === UnderPointerType.NODE ||
            this.beginType === UnderPointerType.VIRTUAL_NODE
          ) {
            const pos = pointerState.position;
            const xDir = { x: 1, y: 0 };
            const yDir = { x: 0, y: 1 };
            const dir = normalizeVector2(subVectors2(pos, this.beginPoint));
            if (Math.abs(dotVector2(dir, xDir)) > 0.95) {
              if (
                (<Segment>HelpLineToSegment(mevacoCollider.object)).type ===
                SegmentType.line
              ) {
                const intersection = LineIntersect2D(
                  xDir,
                  this.beginPoint,
                  subVectors2(
                    (<Segment>HelpLineToSegment(mevacoCollider.object)).end,
                    (<Segment>HelpLineToSegment(mevacoCollider.object)).begin
                  ),
                  (<Segment>HelpLineToSegment(mevacoCollider.object)).begin
                );
                return intersection
                  ? {
                      x: intersection.x,
                      y: intersection.y,
                      z: this.beginPoint.z,
                    }
                  : null;
              } else {
                const intersections = LineCircleIntersect2D(
                  (<Segment>HelpLineToSegment(mevacoCollider.object)).origin,
                  (<Segment>HelpLineToSegment(mevacoCollider.object)).radius,
                  this.beginPoint,
                  addVectors2(this.beginPoint, xDir)
                );
                for (const i of intersections) {
                  if (
                    isPointInArcSegment(
                      i,
                      <Segment>HelpLineToSegment(mevacoCollider.object)
                    )
                  ) {
                    return { x: i.x, y: i.y, z: this.beginPoint.z };
                  }
                }
              }
              return pos;
            }
            if (Math.abs(dotVector2(dir, yDir)) > 0.95) {
              if (
                (<Segment>HelpLineToSegment(mevacoCollider.object)).type ===
                SegmentType.line
              ) {
                const intersection = LineIntersect2D(
                  yDir,
                  this.beginPoint,
                  subVectors2(
                    (<Segment>HelpLineToSegment(mevacoCollider.object)).end,
                    (<Segment>HelpLineToSegment(mevacoCollider.object)).begin
                  ),
                  (<Segment>HelpLineToSegment(mevacoCollider.object)).begin
                );
                return intersection
                  ? {
                      x: intersection.x,
                      y: intersection.y,
                      z: this.beginPoint.z,
                    }
                  : null;
              } else {
                const intersections = LineCircleIntersect2D(
                  (<Segment>HelpLineToSegment(mevacoCollider.object)).origin,
                  (<Segment>HelpLineToSegment(mevacoCollider.object)).radius,
                  this.beginPoint,
                  addVectors2(this.beginPoint, yDir)
                );
                for (const i of intersections) {
                  if (
                    isPointInArcSegment(
                      i,
                      <Segment>HelpLineToSegment(mevacoCollider.object)
                    )
                  ) {
                    return { x: i.x, y: i.y, z: this.beginPoint.z };
                  }
                }
              }
            }
            let p = projectPointOnSegment(
              this.beginPoint,
              HelpLineToSegment(mevacoCollider.object) as Segment,
              true
            );
            if (!!p && sqrDistanceVector2(p, this.beginPoint) > 0.00001) {
              p = clipPointToLineSegment(
                p,
                HelpLineToSegment(mevacoCollider.object) as Segment
              );
              return { x: p.x, y: p.y, z: 0 };
            } else {
              return null;
            }
          } else if (this.beginType === UnderPointerType.SEGMENT) {
            const endSegment = HelpLineToSegment(
              mevacoCollider.object
            ) as Segment;
            if (endSegment !== this.beginObject) {
              const closestDist = getShortestPathBetweenSegments(
                this.beginObject,
                endSegment
              );
              this.currentModel.start = {
                x: closestDist.begin.x,
                y: closestDist.begin.y,
                z: pointerState.position.z,
              };
              return {
                x: closestDist.end.x,
                y: closestDist.end.y,
                z: pointerState.position.z,
              };
            }
          } else if (this.beginType === UnderPointerType.BENDING_LINE) {
            const endSegment = HelpLineToSegment(
              mevacoCollider.object
            ) as Segment;
            const beginSegment = getBendingLineSegment(
              pointerState.position,
              this.beginObject as BendingLine
            );
            if (endSegment !== this.beginObject) {
              const closestDist = getShortestPathBetweenSegments(
                beginSegment,
                endSegment
              );
              this.currentModel.start = {
                x: closestDist.begin.x,
                y: closestDist.begin.y,
                z: pointerState.position.z,
              };
              return {
                x: closestDist.end.x,
                y: closestDist.end.y,
                z: pointerState.position.z,
              };
            }
          } else if (this.beginType === UnderPointerType.PERFORATOIN_HOLE) {
            const beginObject = this.beginObject as PerforationIntersection;
            const endSegment = HelpLineToSegment(
              mevacoCollider.object
            ) as Segment;
            const rotated = getRotatedStampShape(
              beginObject.stamp,
              beginObject.stampPolylineIndex,
              beginObject.perforation.rotation
            );
            const p1 = movePolylines(
              rotated,
              beginObject.rotatedStampPolylinePosition
            );
            // const p1 = movePolylines(beginObject.perforation.stamp.polylines, beginObject.position);
            const closestDist = getShortestPathBetweenPolylinesAndSegment(
              p1,
              endSegment
            );
            if (closestDist) {
              this.currentModel.start = {
                x: closestDist.begin.x,
                y: closestDist.begin.y,
                z: pointerState.position.z,
              };
              return {
                x: closestDist.end.x,
                y: closestDist.end.y,
                z: pointerState.position.z,
              };
            }
          } else if (this.beginType === UnderPointerType.MOUNTING) {
            const beginObject: MountingIntersection = this
              .beginObject as MountingIntersection;
            const endSegment = HelpLineToSegment(
              mevacoCollider.object
            ) as Segment;
            if (Vectors2Equal(this.beginPoint, beginObject.object.position)) {
              const pp = projectPointOnSegment(this.beginPoint, endSegment);
              if (pp) {
                return { x: pp.x, y: pp.y, z: pointerState.position.z };
              }
            } else {
              const p1 = scaleMounting(
                beginObject.object.mountingRef,
                beginObject.object.shape,
                beginObject.object.rotation,
                beginObject.object.position
              );
              const closestDist = getShortestPathBetweenTwoPolylines(p1, [
                endSegment,
              ]);
              if (closestDist) {
                this.currentModel.start = {
                  x: closestDist.begin.x,
                  y: closestDist.begin.y,
                  z: pointerState.position.z,
                };
                return {
                  x: closestDist.end.x,
                  y: closestDist.end.y,
                  z: pointerState.position.z,
                };
              }
            }
          }
        }
        return null;
      }
    }
  }

  calculateEndPointForSegmentStart(
    pointerState: PointerState,
    segment: Segment
  ): Vector3 {
    if (
      this.beginType === UnderPointerType.NODE ||
      this.beginType === UnderPointerType.VIRTUAL_NODE
    ) {
      const pos = pointerState.position;
      const xDir = { x: 1, y: 0 };
      const yDir = { x: 0, y: 1 };
      const dir = normalizeVector2(subVectors2(pos, this.beginPoint));
      if (Math.abs(dotVector2(dir, xDir)) > 0.998) {
        if (segment.type === SegmentType.line) {
          const intersection = LineIntersect2D(
            xDir,
            this.beginPoint,
            subVectors2(segment.end, segment.begin),
            segment.begin
          );
          return intersection
            ? { x: intersection.x, y: intersection.y, z: this.beginPoint.z }
            : null;
        } else {
          const intersections = LineCircleIntersect2D(
            segment.origin,
            segment.radius,
            this.beginPoint,
            addVectors2(this.beginPoint, xDir)
          );
          for (const i of intersections) {
            if (isPointInArcSegment(i, segment)) {
              return { x: i.x, y: i.y, z: this.beginPoint.z };
            }
          }
        }
        return pos;
      }
      if (Math.abs(dotVector2(dir, yDir)) > 0.998) {
        if (segment.type === SegmentType.line) {
          const intersection = LineIntersect2D(
            yDir,
            this.beginPoint,
            subVectors2(segment.end, segment.begin),
            segment.begin
          );
          return intersection
            ? { x: intersection.x, y: intersection.y, z: this.beginPoint.z }
            : null;
        } else {
          const intersections = LineCircleIntersect2D(
            segment.origin,
            segment.radius,
            this.beginPoint,
            addVectors2(this.beginPoint, yDir)
          );
          for (const i of intersections) {
            if (isPointInArcSegment(i, segment)) {
              return { x: i.x, y: i.y, z: this.beginPoint.z };
            }
          }
        }
      }
      let p = projectPointOnSegment(this.beginPoint, segment as Segment, true);
      if (!!p && sqrDistanceVector2(p, this.beginPoint) > 0.00001) {
        p = clipPointToLineSegment(p, segment as Segment);
        return { x: p.x, y: p.y, z: 0 };
      } else {
        return null;
      }
    } else if (this.beginType === UnderPointerType.SEGMENT) {
      const endSegment = segment as Segment;
      if (endSegment !== this.beginObject) {
        const closestDist = getShortestPathBetweenSegments(
          this.beginObject,
          endSegment
        );
        this.currentModel.start = {
          x: closestDist.begin.x,
          y: closestDist.begin.y,
          z: pointerState.position.z,
        };
        return {
          x: closestDist.end.x,
          y: closestDist.end.y,
          z: pointerState.position.z,
        };
      }
    } else if (this.beginType === UnderPointerType.BENDING_LINE) {
      const endSegment = segment as Segment;
      const beginSegment = getBendingLineSegment(
        pointerState.position,
        this.beginObject as BendingLine
      );
      if (endSegment !== this.beginObject) {
        const closestDist = getShortestPathBetweenSegments(
          beginSegment,
          endSegment
        );
        if (distanceVector2(closestDist.begin, closestDist.end) > 0.0001) {
          this.currentModel.start = {
            x: closestDist.begin.x,
            y: closestDist.begin.y,
            z: pointerState.position.z,
          };
          return {
            x: closestDist.end.x,
            y: closestDist.end.y,
            z: pointerState.position.z,
          };
        }
      }
    } else if (this.beginType === UnderPointerType.PERFORATOIN_HOLE) {
      const beginObject = this.beginObject as PerforationIntersection;
      const endSegment = segment as Segment;

      const rotated = getRotatedStampShape(
        beginObject.stamp,
        beginObject.stampPolylineIndex,
        beginObject.perforation.rotation
      );
      const p1 = movePolylines(
        rotated,
        beginObject.rotatedStampPolylinePosition
      );
      const closestDist = getShortestPathBetweenPolylinesAndSegment(
        p1,
        endSegment
      );

      if (closestDist) {
        this.currentModel.start = {
          x: closestDist.begin.x,
          y: closestDist.begin.y,
          z: pointerState.position.z,
        };
        return {
          x: closestDist.end.x,
          y: closestDist.end.y,
          z: pointerState.position.z,
        };
      }
    } else if (this.beginType === UnderPointerType.MOUNTING) {
      const beginObject: MountingIntersection = this
        .beginObject as MountingIntersection;
      const endSegment = segment as Segment;
      if (Vectors2Equal(this.beginPoint, beginObject.object.position)) {
        const pp = projectPointOnSegment(this.beginPoint, endSegment);
        if (pp) {
          return { x: pp.x, y: pp.y, z: pointerState.position.z };
        }
      } else {
        const p1 = scaleMounting(
          beginObject.object.mountingRef,
          beginObject.object.shape,
          beginObject.object.rotation,
          beginObject.object.position
        );
        const closestDist = getShortestPathBetweenTwoPolylines(p1, [
          endSegment,
        ]);
        if (closestDist) {
          this.currentModel.start = {
            x: closestDist.begin.x,
            y: closestDist.begin.y,
            z: pointerState.position.z,
          };
          return {
            x: closestDist.end.x,
            y: closestDist.end.y,
            z: pointerState.position.z,
          };
        }
      }
    } else if (this.beginType === UnderPointerType.HELP_LINE) {
      const endSegment = segment as Segment;
      if (endSegment !== this.beginObject) {
        const closestDist = getShortestPathBetweenSegments(
          HelpLineToSegment(this.beginObject),
          endSegment
        );
        this.currentModel.start = {
          x: closestDist.begin.x,
          y: closestDist.begin.y,
          z: pointerState.position.z,
        };
        return {
          x: closestDist.end.x,
          y: closestDist.end.y,
          z: pointerState.position.z,
        };
      }
    }
    return null;
  }

  onMouseUp(pointerState: PointerState) {}

  reset() {
    this.onCancel();
    this.activate();
  }

  visualizeCurrentMeasurement(color: Vector3) {
    this.currentModelVisualizer.updateVisualization({
      viewMask: 0xffffffff,
      model: { ...this.currentModel, color: color },
    });
  }

  private updateModelWithPoint(point: Vector3) {
    const dir = subVectors3(point, this.currentModel.start);
    const v = lengthVector2(dir);
    const labelDir = normalizeVector2(getLeftPerpendicularVector2(dir));
    this.currentModel = {
      ...this.currentModel,
      measurementDirection: normalizeVector3(dir),
      direction: { x: labelDir.x, y: labelDir.y, z: dir.z },
      exchange: {
        ...this.currentModel.exchange,
        value: v,
      },
      maxValue: v,
    };
  }

  private updateModelDistance(point: Vector3) {
    const dir = normalizeVector2(
      subVectors2(this.endPoint, this.currentModel.start)
    );
    const toPoint = subVectors2(point, this.currentModel.start);
    const cross = crossVector2(dir, toPoint);

    // const size = workingPlaneUnitSize(this.webcad.viewState.camera);
    // const dist = distanceVector3(multiplyVector3byScalar(matrix4GetOrigin(view), -1), {x:toPoint.x, y: toPoint.y, z:0});

    this.currentModel = {
      ...this.currentModel,
      distance: cross,
    };
  }

  onCancel() {
    this.beginObject = null;
    this.beginType = null;
    this.currentModel = {
      editable: false,
      direction: { x: 0, y: 0, z: 0 },
      distance: 0,
      start: null,
      focusable: false,
      measurementDirection: { x: 0, y: 0, z: 0 },
      visible: false,
      exchange: {
        value: 1,
        onInputLive: null, // to be set by tool
        onInputConfirmed: null, // to be set by tool
        inputValidation: (val) => val !== "" && !isNaN(+val),

        fromModel: (value: number) => (value * 1000).toFixed(1),
        toModel: (value: string) => +value / 1000,
      },
      maxValue: 1,
      mask: 0x00000001,
    };
    if (this.currentModelVisualizer) {
      this.currentModelVisualizer.dispose();
      this.currentModelVisualizer = null;
    }
    this.endPoint = null;
    this._dirty = false;
    this.store.dispatch(new SetPerforationCollider(false));
  }

  onConfirm() {
    if (this.endPoint) {
      this.updateModelWithPoint(this.endPoint);
      this.store.dispatch(new AddMeasurement({ ...this.currentModel }));
      this.reset();
    }
  }

  isDirty() {
    return this._dirty;
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}

function getClosestPointOnSegment(segment: Segment, point: Vector3) {
  let pp = projectPointOnSegment(point, segment, true);
  if (!!pp) {
    pp = clipPointToLineSegment(pp, segment);
    return { x: pp.x, y: pp.y, z: point.z };
  } else {
    const pToSegmentBegin = sqrLengthVector2(subVectors2(segment.begin, point));
    const pToSegmentEnd = sqrLengthVector2(subVectors2(segment.end, point));
    return pToSegmentBegin < pToSegmentEnd
      ? { x: segment.begin.x, y: segment.begin.y, z: point.z }
      : { x: segment.end.x, y: segment.end.y, z: point.z };
  }
}

function getBendingLineSegment(
  position: Vector3,
  bendingLine: BendingLine
): Segment {
  const b = subVectors2(bendingLine.end, bendingLine.begin);
  const v = subVectors2(bendingLine.end, position);
  const c = crossVector2(b, v);
  let offset =
    c > 0 ? getLeftPerpendicularVector2(b) : getRightPerpendicularVector2(b);
  offset = multiplyVector2byScalar(
    normalizeVector2(offset),
    bendingLine.bentParams.ossb - bendingLine.bentParams.bendAllowance / 2
  );
  return {
    type: SegmentType.line,
    begin: addVectors2(bendingLine.begin, offset),
    end: addVectors2(bendingLine.end, offset),
  };
}

function getStartAndEndPosBetweenSegmentAndMounting(
  onEndCenter: boolean,
  segment: Segment,
  mounting: MountingAttachment,
  position: Vector3
): { start: Vector3; end: Vector3 } {
  if (onEndCenter) {
    const closest = projectPointOnSegment(position, segment);
    if (closest) {
      return {
        start: { x: closest.x, y: closest.y, z: position.z },
        end: position,
      };
    }
  } else {
    const endPolyline = scaleMounting(
      mounting.mountingRef,
      mounting.shape,
      mounting.rotation,
      mounting.position
    );
    const closestDist = getShortestPathBetweenTwoPolylines(
      [segment],
      endPolyline
    );
    if (closestDist) {
      return {
        start: {
          x: closestDist.begin.x,
          y: closestDist.begin.y,
          z: position.z,
        },
        end: { x: closestDist.end.x, y: closestDist.end.y, z: position.z },
      };
    }
  }
}
