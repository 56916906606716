<div class="image-tools">
  <div class="image-tools__name">
    <img src="assets/CAD_Icon.svg" alt="cad-icon" class="name__icon">
    <span class="name__text">{{imageName}}</span>
  </div>
  <div class="image-tools__bin">
    <img src="assets/trash-icon.svg" alt="bin" class="bin" (click)="onDeleteImage(undefined)">
  </div>
</div>


