import { Store } from "@ngrx/store";
import { CubeView3d } from "webcad/components/cube-view3d";
import { MevacoState } from "../store/reducers";
import { MevacoView3d } from "../visualizers/mevaco-view3d";
import {Scene} from "webcad/babylonjs/core";

export class RenderController {
  private scene: Scene;

  constructor(
    private store: Store<MevacoState>,
    private mevacoView3D: MevacoView3d,
    private cubeView3D?: CubeView3d
  ) {}

  init() {
    // (<RotateCamera>this.mevacoView3D.getCamera()).onCameraChangedObservable.add((ed, es) => {
    //   this.mevacoView3D.shouldUpdate = true;
    //   console.log('camera changed');
    // });
    this.mevacoView3D.getCamera().onViewMatrixChangedObservable.add(() => {
      this.mevacoView3D.shouldUpdate = true;
    });
    this.mevacoView3D
      .getCamera()
      .onProjectionMatrixChangedObservable.add(() => {
        this.mevacoView3D.shouldUpdate = true;
      });
    if (!!this.cubeView3D) {
      this.cubeView3D.scene.activeCamera.onViewMatrixChangedObservable.add(
        () => {
          this.mevacoView3D.shouldUpdate = true;
        }
      );
      this.cubeView3D.scene.activeCamera.onProjectionMatrixChangedObservable.add(
        () => {
          this.mevacoView3D.shouldUpdate = true;
        }
      );
    }
    // this.scene.onBeforeAnimationsObservable.add((ed, es) => {
    //   this.mevacoView3d.isAnimationPlaying = true;
    // });
    // this.scene.onAfterAnimationsObservable.add((ed, es) => {
    //   this.mevacoView3d.isAnimationPlaying = false;
    // });
    // this.cubeView3D.scene.onBeforeAnimationsObservable.add((ed, es) => {
    //   this.mevacoView3d.isAnimationPlaying = true;
    // });
    // this.cubeView3D.scene.onAfterAnimationsObservable.add((ed, es) => {
    //   this.mevacoView3d.isAnimationPlaying = false;
    // });
  }
}
