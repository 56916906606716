<div class="dialog-modal-header">
  <div class="dialog-modal-header-content">
    <div class="dialog-modal-title-container">
      <h4 class="dialog-modal-title-primary" id="balconyCorner-title">
        {{ this.templateUtils.translate("Balcony Corner") }}
      </h4>
      <span class="dialog-modal-title-secondary"
        >{{
          this.templateUtils.translate("EnterDimenstions", "designer")
        }}
        (mm):</span
      >
    </div>

    <div class="dialog-modal-window">
      <span>{{
        this.templateUtils.translate("SketchDoesntChange", "designer")
      }}</span>
    </div>
  </div>
  <button
    type="button"
    class="close btn-close"
    aria-label="Close"
    [tabIndex]="-1"
    (click)="dismiss('Cross click'); isModalOpenMethod()"
  ></button>
</div>

<div>
  <div class="dialog-modal-body">
    <div class="balcony-inputs">
      <input
        type="text"
        id="ba"
        class="input-small balcony-corner-a"
        [tabIndex]="3"
        [ngStyle]="
          balconyCornerTemplate.a <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="balconyCornerTemplate.a"
        (input)="
          setBalconyCornerA(this.templateUtils.roundInput($event, 0, 5000))
        "
        #ba
        required
      />

      <input
        type="text"
        id="bb"
        class="input-small balcony-corner-b"
        [tabIndex]="18"
        [ngStyle]="
          balconyCornerTemplate.b <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="balconyCornerTemplate.b1 + balconyCornerTemplate.b2"
        (input)="setBalconyCornerB(templateUtils.roundInput($event, 0, 5000))"
        #bb
        required
      />

      <input
        type="text"
        id="be1"
        class="input-small balcony-corner-e1"
        [tabIndex]="6"
        [ngStyle]="
          balconyCornerTemplate.e1 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="balconyCornerTemplate.e1"
        (input)="
          setBalconyCornerE1(
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.a / 2
            )
          )
        "
        #be1
        required
      />

      <input
        type="text"
        id="be2"
        class="input-small balcony-corner-e2"
        [tabIndex]="7"
        [ngStyle]="
          balconyCornerTemplate.e2 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="balconyCornerTemplate.e2"
        (input)="
          setBalconyCornerE2(
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.a / 2
            )
          )
        "
        #be2
        required
      />

      <input
        type="text"
        id="bb1"
        class="input-small balcony-corner-b1"
        [tabIndex]="1"
        (keydown)="this.templateUtils.returnToLastElement($event, bx3)"
        [ngStyle]="
          balconyCornerTemplate.b1 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="balconyCornerTemplate.b / 2"
        (input)="
          setBalconyCornerB1(
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.b / 2
            )
          )
        "
        #bb1
        required
      />

      <input
        type="text"
        id="bb2"
        class="input-small balcony-corner-b2"
        [tabIndex]="2"
        [ngStyle]="
          balconyCornerTemplate.b2 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="balconyCornerTemplate.b / 2"
        (input)="
          setBalconyCornerB2(
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.b / 2
            )
          )
        "
        #bb2
        required
      />

      <input
        type="text"
        id="bf1"
        class="input-small balcony-corner-f1"
        [tabIndex]="5"
        [ngStyle]="
          balconyCornerTemplate.f1 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="balconyCornerTemplate.f1"
        (input)="
          setBalconyCornerF1(
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.b1 / 2
            )
          )
        "
        #bf1
        required
      />

      <input
        type="text"
        id="bf2"
        class="input-small balcony-corner-f2"
        [tabIndex]="4"
        [ngStyle]="
          balconyCornerTemplate.f2 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="balconyCornerTemplate.f2"
        (input)="
          setBalconyCornerF2(
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.b2 / 2
            )
          )
        "
        #bf2
        required
      />

      <input
        type="text"
        id="bf3"
        class="input-small balcony-corner-f3"
        [tabIndex]="19"
        [ngStyle]="
          balconyCornerTemplate.f3 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="balconyCornerTemplate.f3"
        (input)="
          setBalconyCornerF3(
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.b1 + balconyCornerTemplate.b2
            )
          )
        "
        #bf3
        required
      />

      <input
        type="text"
        id="bx1"
        class="input-small balcony-corner-x1"
        [tabIndex]="12"
        [ngStyle]="
          balconyCornerTemplate.x1 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="balconyCornerTemplate.x1"
        (input)="
          setBalconyCornerNotchX(
            1,
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.b / 2
            )
          )
        "
        #bx1
        required
      />

      <input
        type="text"
        id="by1"
        class="input-small balcony-corner-y1"
        [tabIndex]="11"
        [ngStyle]="
          balconyCornerTemplate.y1 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="balconyCornerTemplate.y1"
        (input)="
          setBalconyCornerNotchY(
            1,
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.a / 2
            )
          )
        "
        #by1
        required
      />

      <input
        type="text"
        id="bx2"
        class="input-small balcony-corner-x2"
        [tabIndex]="13"
        [ngStyle]="
          balconyCornerTemplate.x2 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="balconyCornerTemplate.x2"
        (input)="
          setBalconyCornerNotchX(
            2,
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.b / 2
            )
          )
        "
        #bx2
        required
      />

      <input
        type="text"
        id="by2"
        class="input-small balcony-corner-y2"
        [tabIndex]="14"
        [ngStyle]="
          balconyCornerTemplate.y2 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="balconyCornerTemplate.y2"
        (input)="
          setBalconyCornerNotchY(
            2,
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.a / 2
            )
          )
        "
        #by2
        required
      />

      <input
        type="text"
        id="bx3"
        class="input-small balcony-corner-x3"
        [tabIndex]="17"
        (keydown)="this.templateUtils.returnToFirstElement($event, bb1)"
        [ngStyle]="
          balconyCornerTemplate.x3 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="balconyCornerTemplate.x3"
        (input)="
          setBalconyCornerNotchX(
            3,
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.b / 2
            )
          )
        "
        #bx3
        required
      />

      <input
        type="text"
        id="by3"
        class="input-small balcony-corner-y3"
        [tabIndex]="16"
        [ngStyle]="
          balconyCornerTemplate.y3 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="balconyCornerTemplate.y3"
        (input)="
          setBalconyCornerNotchY(
            3,
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.a / 2
            )
          )
        "
        #by3
        required
      />

      <input
        type="text"
        id="bx4"
        class="input-small balcony-corner-x4"
        [tabIndex]="8"
        [ngStyle]="
          balconyCornerTemplate.x4 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="balconyCornerTemplate.x4"
        (input)="
          setBalconyCornerNotchX(
            4,
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.b / 2
            )
          )
        "
        #bx4
        required
      />

      <input
        type="text"
        id="by4"
        class="input-small balcony-corner-y4"
        [tabIndex]="9"
        [ngStyle]="
          balconyCornerTemplate.y4 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="balconyCornerTemplate.y4"
        (input)="
          setBalconyCornerNotchY(
            4,
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.a / 2
            )
          )
        "
        #by4
        required
      />

      <input
        type="text"
        id="bx5"
        class="input-small balcony-corner-x5"
        [tabIndex]="10"
        [ngStyle]="
          balconyCornerTemplate.x5 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="balconyCornerTemplate.x5"
        (input)="
          setBalconyCornerNotchX(
            5,
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.b / 2
            )
          )
        "
        #bx5
        required
      />

      <input
        type="text"
        id="by5"
        class="input-small balcony-corner-y5"
        [tabIndex]="15"
        [ngStyle]="
          balconyCornerTemplate.y5 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="balconyCornerTemplate.y5"
        (input)="
          setBalconyCornerNotchY(
            5,
            this.templateUtils.roundInput(
              $event,
              0,
              balconyCornerTemplate.a / 2
            )
          )
        "
        #by5
        required
      />
    </div>
  </div>
  <div class="dialog-modal-footer">
    <span class="dialog-modal-link" (click)="this.templateUtils.setDefault()">
      {{ this.templateUtils.translate("Reset dimensions to default.") }}
    </span>
    <button
      #closeButton
      type="button"
      class="btn btn-primary btn-create"
      [tabIndex]="1"
      [disabled]="reqValBalconyCorner | async"
      (click)="
        close({
          a: ba.valueAsNumber,
          b: bb.valueAsNumber,
          b1: bb1.valueAsNumber,
          b2: bb2.valueAsNumber,
          e1: be1.valueAsNumber,
          e2: be2.valueAsNumber,
          f1: bf1.valueAsNumber,
          f2: bf2.valueAsNumber,
          f3: bf3.valueAsNumber,
          x1: bx1.valueAsNumber,
          x2: bx2.valueAsNumber,
          x3: bx3.valueAsNumber,
          x4: bx4.valueAsNumber,
          x5: bx5.valueAsNumber,
          y1: by1.valueAsNumber,
          y2: by2.valueAsNumber,
          y3: by3.valueAsNumber,
          y4: by4.valueAsNumber,
          y5: by5.valueAsNumber
        })
      "
    >
      {{ this.templateUtils.translate("Create") }}
    </button>
  </div>
</div>
