import { Drawing } from "../../model/drawing.model";
import { BendingNode } from "../../model/view-model/bending-node.viewModel";
import { buildBendingStructure } from "../../utils/bending";

export class BendingTreeProvider {
  private model: Drawing;
  private viewModel: BendingNode;

  getViewModel(newModel: Drawing): BendingNode {
    const model = this.model;

    if (!newModel || !newModel.plate) {
      this.viewModel = null;
    } else if (
      !this.model ||
      this.model.plate.shapeWithHoles !== newModel.plate.shapeWithHoles ||
      this.model.plate.bendingLines !== newModel.plate.bendingLines ||
      this.model.snapOptions.edges !== newModel.snapOptions.edges ||
      this.model.plate.depth !== newModel.plate.depth ||
      this.model.flat !== newModel.flat
    ) {
      if (newModel.flat) {
        console.log("NEW MODEL FLAT", this.viewModel);
        this.viewModel = buildBendingStructure(
          newModel.plate.shapeWithHoles,
          newModel.plate.bendingLines,
          true,
          newModel.plate.depth
        );
      } else {
        console.log("ELSE", this.viewModel);
        this.viewModel = buildBendingStructure(
          newModel.plate.shapeWithHoles,
          newModel.plate.bendingLines,
          newModel.snapOptions.edges,
          newModel.plate.depth
        );
      }
    }
    this.model = newModel;
    return this.viewModel;
  }
}
