import {initialShape, Shape} from './shape.model';

export enum ActionType {
  ADD = 'ADD',
  REMOVE = 'REMOVE'
}
export interface ShapeAction {
  type: ActionType;
  shape: Shape;
}

export const initialShapeAction: ShapeAction = {
  type: ActionType.REMOVE,
  shape: initialShape
};
