import { Observable, combineLatest } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { Segment } from "webcad";
import { PointerCollider } from "webcad/collision";
import { Webcad } from "webcad/core";
import {
  Vector2,
  addVectors2,
  multiplyVector2byScalar,
  rotateVector2,
  vector2toVector3,
} from "webcad/math";
import { QuadNode } from "webcad/math/quad-node";
import {
  AngleMeasurementModel,
  MeasurementModel,
  isPointInArcSegment,
  isSegmentCircle,
  segmentSegmentIntersection,
} from "webcad/models";
import { MoveNodeType, PointNode } from "../model";
import { BendingLine } from "../model/bending-line.model";
import { ShapeOrigin } from "../model/drawing.model";
import { HelpLine, HelpLineToSegment } from "../model/help-line.model";
import { MountingAttachment } from "../model/mounting.model";
import { PerforationAreaModel } from "../model/perforation-area.model";
import { ShapeWithHoles } from "../model/shape-with-holes";
import { SnapOptions } from "../model/snap-options.model";
import { SnappingModel } from "../model/snapping.model";
import { BendingLineCollider } from "./colliders/bending-line.collider";
import { ColliderItems } from "./colliders/collider-items";
import {
  DrawingPerforationCollisionTreeProvider,
  PerforationHole,
} from "./colliders/drawing-perforation-collision-tree-provider.service";
import { DrawingPerforationCollider } from "./colliders/drawingPerforationCollider";
import { GridCollider } from "./colliders/grid.collider";
import {
  HelpLineHelpLineCrossCollider,
  HelpingLineBaseCollider,
  HelpingLineSegmentCrossCollider,
} from "./colliders/helpline.collider";
import {
  AngleMeasurementCollider,
  AutomaticMeasurementCollider,
  MeasurementCollider,
} from "./colliders/measurement.collider";
import { MountingCollider } from "./colliders/mounting.collider";
import { NodeCollider } from "./colliders/node.collider";
import { PerforationAreaCollider } from "./colliders/perforation-area.collider";
import { SegmentBaseCollider } from "./colliders/segment.collider";
import { VirtualNodeCollider } from "./colliders/virtual-node.collider";
import { WebcadProvider } from "./webcad.provider";

export class MevacoCollidersProvider {
  public colliders: Observable<PointerCollider[]>;
  //public snappingContext: SnappingContext;
  private webcad: Webcad;

  constructor(
    colliderItems: Observable<ColliderItems>,
    snappingModel: Observable<SnappingModel>,
    snappingOptions: Observable<SnapOptions>,
    private webcadProvider: WebcadProvider,
    private perforationCollisionThree: DrawingPerforationCollisionTreeProvider
  ) {
    /*
    this.snappingContext = {
      snappingModel: null,
      snappingOptions: null,
      camera: null
    };
    snappingModel.subscribe((snappingModel) => {
      this.snappingContext.snappingModel = snappingModel;
    });
    snappingOptions.subscribe((snappingOptions) => {
      this.snappingContext.snappingOptions = snappingOptions;
    });
    */

    webcadProvider.getObservable().subscribe((webcad: Webcad) => {
      this.webcad = webcad;
    });
    this.colliders = combineLatest([
      colliderItems,
      snappingModel,
      snappingOptions,
      this.perforationCollisionThree.quadTree,
    ]).pipe(
      debounceTime(100),
      map(([items, model, options, perfCollisionTree]) =>
        this.getColliders.bind(this)(items, model, options, perfCollisionTree)
      )
    );
  }

  private getColliders(
    colliderItems: ColliderItems,
    snappingModel: SnappingModel,
    snappingOptions: SnapOptions,
    perfCollisionTree: QuadNode<PerforationHole>
  ): PointerCollider[] {
    if (!this.webcad) {
      return [];
    }
    const snappingContext = {
      snappingModel: snappingModel,
      snappingOptions: snappingOptions,
      camera: this.webcad.viewState.camera,
    };

    const colliders: PointerCollider[] = [];

    if (snappingContext.snappingOptions.nodes) {
      if (snappingContext.snappingOptions.perforationArea) {
        this.appendNodeColliders(colliders, colliderItems.nodes);
      } else {
        this.appendNodeColliders(
          colliders,
          colliderItems.nodes.filter(
            (v) => v.origin !== ShapeOrigin.PERFORATION
          )
        );
      }
      if (snappingContext.snappingOptions.perforationArea) {
        this.appendVirtualNodeColliders(
          colliders,
          colliderItems.nodes,
          colliderItems.perforationAreas.map((x) => x.shape)
        );
      }
      this.appendVirtualNodeColliders(colliders, colliderItems.nodes, [
        colliderItems.shapeWithHoles,
      ]);
    }

    if (snappingContext.snappingOptions.edges) {
      this.appendShapeWithHolsColliders(
        colliders,
        colliderItems.shapeWithHoles,
        snappingContext,
        -1
      );
      if (snappingContext.snappingOptions.perforationArea) {
        this.appendPerforationAreasColliders(
          colliders,
          colliderItems.perforationAreas,
          snappingContext
        );
      }
    }

    this.appendBendingLine(
      colliders,
      colliderItems.bendingLines,
      snappingContext
    );

    if (snappingContext.snappingOptions.helpLines) {
      this.appendHelpingLinesColliders(
        colliders,
        colliderItems.helpLines,
        snappingContext
      );
      this.appendHelpingLinesShapeCrossColliders(
        colliders,
        colliderItems.helpLines,
        colliderItems.shapeWithHoles
      );
      this.appendHelpLinesHelpLineCrossColliders(
        colliders,
        colliderItems.helpLines
      );

      this.appendHelpingLinesColliders(
        colliders,
        colliderItems.toolHelpLines,
        snappingContext
      );
      this.appendHelpingLinesShapeCrossColliders(
        colliders,
        colliderItems.toolHelpLines,
        colliderItems.shapeWithHoles
      );
      this.appendHelpLinesHelpLineCrossColliders(
        colliders,
        colliderItems.toolHelpLines
      );

      this.appendHelpLinesToolHelpLinesCrossColliders(
        colliders,
        colliderItems.helpLines,
        colliderItems.toolHelpLines
      );
      if (snappingContext.snappingOptions.perforationArea) {
        for (const pa of colliderItems.perforationAreas) {
          this.appendHelpingLinesShapeCrossColliders(
            colliders,
            colliderItems.helpLines,
            pa.shape
          );
          this.appendHelpingLinesShapeCrossColliders(
            colliders,
            colliderItems.toolHelpLines,
            pa.shape
          );
        }
      }
    }

    if (snappingContext.snappingOptions.measurements) {
      this.appendMeasurementsColliders(
        colliders,
        colliderItems.measurements,
        snappingContext
      );
      this.appendAngleMeasurementsColliders(
        colliders,
        colliderItems.angleMeasurements,
        snappingContext
      );
      this.appendAutomaticMeasurementsColliders(
        colliders,
        colliderItems.automaticMeasurements,
        snappingContext
      );
    }

    if (snappingContext.snappingOptions.grid) {
      colliders.push(new GridCollider(snappingContext));
    }

    if (snappingContext.snappingOptions.mountingHoles) {
      this.appendMountingsColliders(colliders, colliderItems.mountingHoles);
      this.appendMountingsVirtualNodeColliders(
        colliders,
        colliderItems.mountingHoles
      );
    }

    if (snappingContext.snappingOptions.perforationCollider) {
      colliders.push(
        new DrawingPerforationCollider(
          colliderItems.perforation,
          this.perforationCollisionThree
        )
      );
    }
    if (snappingContext.snappingOptions.import) {
      this.appendImportShapeColliders(
        colliders,
        colliderItems.importedShape,
        snappingContext
      );
    }
    return colliders;
  }

  private appendNodeColliders(
    colliders: PointerCollider[],
    nodes: PointNode[]
  ) {
    nodes.forEach((pn: PointNode) => {
      colliders.push(new NodeCollider(pn));
    });
  }

  private appendPerforationAreasColliders(
    colliders: PointerCollider[],
    perforationAreas: PerforationAreaModel[],
    snappingContext
  ) {
    for (let i = 0; i < perforationAreas.length; i++) {
      this.appendShapeWithHolsColliders(
        colliders,
        perforationAreas[i].shape,
        snappingContext,
        i
      );
      colliders.push(new PerforationAreaCollider(perforationAreas[i]));
    }
  }

  private appendShapeWithHolsColliders(
    colliders: PointerCollider[],
    shapeWithHoles: ShapeWithHoles,
    snappingContext,
    perforationAreaIndex: number
  ) {
    if (!!shapeWithHoles) {
      this.appendPolyline(
        colliders,
        shapeWithHoles.conture,
        snappingContext,
        perforationAreaIndex
      );
      this.appendPolylines(
        colliders,
        shapeWithHoles.holes,
        snappingContext,
        perforationAreaIndex
      );
    }
  }

  private appendImportShapeColliders(
    colliders: PointerCollider[],
    shape: Segment[][],
    snappingContext
  ) {
    if (!!shape) {
      this.appendPolylines(colliders, shape, snappingContext, -1);
    }
  }

  private appendPolyline(
    colliders: PointerCollider[],
    polyline: Segment[],
    snappingContext,
    perforationAreaIndex
  ) {
    for (let j = 0; j < polyline.length; j++) {
      colliders.push(
        new SegmentBaseCollider(
          polyline[j],
          snappingContext,
          perforationAreaIndex
        )
      );
    }
  }

  private appendBendingLine(
    colliders: PointerCollider[],
    bendingLines: BendingLine[],
    snappingContext
  ) {
    for (let j = 0; j < bendingLines.length; j++) {
      colliders.push(new BendingLineCollider(bendingLines[j], snappingContext));
    }
  }

  private appendPolylines(
    colliders: PointerCollider[],
    polylines: Segment[][],
    snappingContext,
    perforationAreaIndex
  ) {
    for (let i = 0; i < polylines.length; i++) {
      this.appendPolyline(
        colliders,
        polylines[i],
        snappingContext,
        perforationAreaIndex
      );
    }
  }

  private appendHelpingLinesColliders(
    colliders: PointerCollider[],
    helpingLines: Map<number, HelpLine>,
    snappingContext
  ) {
    helpingLines.forEach((helpingLine: HelpLine) => {
      colliders.push(new HelpingLineBaseCollider(helpingLine, snappingContext));
    });
  }

  private appendHelpingLinesShapeCrossColliders(
    colliders: PointerCollider[],
    helpLines: Map<number, HelpLine>,
    shapeWithHoles: ShapeWithHoles
  ) {
    helpLines.forEach((helpLine: HelpLine) => {
      const helpLineSegment = HelpLineToSegment(helpLine);
      if (!!shapeWithHoles) {
        this.appendHelpingLinesSegmentsCrossColliders(
          colliders,
          helpLine,
          helpLineSegment,
          shapeWithHoles.conture
        );
        for (const hole of shapeWithHoles.holes) {
          this.appendHelpingLinesSegmentsCrossColliders(
            colliders,
            helpLine,
            helpLineSegment,
            hole
          );
        }
      }
    });
  }

  private appendHelpingLinesSegmentsCrossColliders(
    colliders: PointerCollider[],
    helpLine: HelpLine,
    helpLineSegment: Segment,
    polyline: Segment[]
  ) {
    for (let i = 0; i < polyline.length; i++) {
      const cp = segmentSegmentIntersection(helpLineSegment, polyline[i]);
      if (cp) {
        colliders.push(
          new HelpingLineSegmentCrossCollider(
            { x: cp.x, y: cp.y, z: 0 },
            helpLine,
            polyline[i]
          )
        );
      }
    }
  }

  private appendHelpLinesHelpLineCrossColliders(
    colliders: PointerCollider[],
    helpingLines: Map<number, HelpLine>
  ) {
    const hlArray = Array.from(helpingLines.values());
    for (let i = 0; i < hlArray.length; i++) {
      const hl1 = hlArray[i];
      const hls1 = HelpLineToSegment(hl1);
      for (let j = i + 1; j < hlArray.length; j++) {
        const hl2 = hlArray[j];
        const hls2 = HelpLineToSegment(hl2);
        const cp = segmentSegmentIntersection(hls1, hls2);
        if (cp) {
          colliders.push(
            new HelpLineHelpLineCrossCollider(
              { x: cp.x, y: cp.y, z: 0 },
              hl1,
              hl2
            )
          );
        }
      }
    }
  }

  private appendHelpLinesToolHelpLinesCrossColliders(
    colliders: PointerCollider[],
    helpingLines: Map<number, HelpLine>,
    toolHelpLines: Map<number, HelpLine>
  ) {
    const hlArray = Array.from(helpingLines.values());
    const hltArray = Array.from(toolHelpLines.values());
    for (let i = 0; i < hlArray.length; i++) {
      const hl1 = hlArray[i];
      const hls1 = HelpLineToSegment(hl1);
      for (let j = 0; j < hltArray.length; j++) {
        const hl2 = hltArray[j];
        const hls2 = HelpLineToSegment(hl2);
        const cp = segmentSegmentIntersection(hls1, hls2);
        if (cp) {
          colliders.push(
            new HelpLineHelpLineCrossCollider(
              { x: cp.x, y: cp.y, z: 0 },
              hl1,
              hl2
            )
          );
        }
      }
    }
  }

  private appendMeasurementsColliders(
    colliders: PointerCollider[],
    measurements: Map<number, MeasurementModel>,
    snappingContext
  ) {
    measurements.forEach((measurement: MeasurementModel) => {
      colliders.push(new MeasurementCollider(measurement, snappingContext));
    });
  }

  private appendAutomaticMeasurementsColliders(
    colliders: PointerCollider[],
    measurements: Map<number, MeasurementModel>,
    snappingContext
  ) {
    measurements.forEach((measurement: MeasurementModel) => {
      colliders.push(
        new AutomaticMeasurementCollider(measurement, snappingContext)
      );
    });
  }

  private appendAngleMeasurementsColliders(
    colliders: PointerCollider[],
    measurements: Map<number, AngleMeasurementModel>,
    snappingContext
  ) {
    measurements.forEach((angleMeasurement: AngleMeasurementModel) => {
      colliders.push(
        new AngleMeasurementCollider(angleMeasurement, snappingContext)
      );
    });
  }

  private appendMountingsColliders(
    colliders: PointerCollider[],
    mountings: MountingAttachment[]
  ) {
    mountings.forEach((mounting: MountingAttachment) => {
      colliders.push(new MountingCollider(mounting));
    });
  }
  private appendMountingsVirtualNodeColliders(
    colliders: PointerCollider[],
    mountings: MountingAttachment[]
  ) {
    mountings.forEach((mounting: MountingAttachment) => {
      const halfWidth =
        mounting.mountingRef.length !== 0
          ? mounting.mountingRef.length / 2000
          : mounting.mountingRef.width / 2000;
      const halfHeight = mounting.mountingRef.width / 2000;
      const up = rotateVector2({ x: 0, y: halfHeight }, mounting.rotation);
      const right = rotateVector2({ x: halfWidth, y: 0 }, mounting.rotation);
      colliders.push(
        new VirtualNodeCollider(
          vector2toVector3(addVectors2(mounting.position, up))
        )
      );
      colliders.push(
        new VirtualNodeCollider(
          vector2toVector3(addVectors2(mounting.position, right))
        )
      );
      colliders.push(
        new VirtualNodeCollider(
          vector2toVector3(
            addVectors2(mounting.position, multiplyVector2byScalar(up, -1))
          )
        )
      );
      colliders.push(
        new VirtualNodeCollider(
          vector2toVector3(
            addVectors2(mounting.position, multiplyVector2byScalar(right, -1))
          )
        )
      );
    });
  }

  private appendVirtualNodeColliders(
    colliders: PointerCollider[],
    nodes: PointNode[],
    shapes: ShapeWithHoles[]
  ) {
    const txy = {};
    const tx = {};
    const ty = {};
    nodes.forEach((node) => {
      if (node.type === MoveNodeType.normal) {
        const x = Math.round(node.position.x * 100000);
        const y = Math.round(node.position.y * 100000);
        const xy = x + " " + y;
        tx[x] = x;
        ty[y] = y;
        txy[xy] = true;
      }
    });

    for (const txKey in tx) {
      const x = tx[txKey];
      for (const tyKey in ty) {
        const y = ty[tyKey];
        const xy = x + " " + y;

        if (!txy[xy]) {
          colliders.push(
            new VirtualNodeCollider({ x: x / 100000, y: y / 100000, z: 0 })
          );
        }
      }
    }
    for (const shape of shapes) {
      for (const s of shape.conture) {
        if (isSegmentCircle(s)) {
          for (let i = 0; i <= Math.PI * 2; i += Math.PI / 2) {
            const p: Vector2 = {
              x: s.origin.x + s.radius * Math.cos(i),
              y: s.origin.y + s.radius * Math.sin(i),
            };
            if (isPointInArcSegment(p, s)) {
              colliders.push(new VirtualNodeCollider({ x: p.x, y: p.y, z: 0 }));
            }
          }
        }
      }
      for (const h of shape.holes) {
        for (const s of h) {
          if (isSegmentCircle(s)) {
            for (let i = 0; i <= Math.PI * 2; i += Math.PI / 2) {
              const p: Vector2 = {
                x: s.origin.x + s.radius * Math.cos(i),
                y: s.origin.y + s.radius * Math.sin(i),
              };
              if (isPointInArcSegment(p, s)) {
                colliders.push(
                  new VirtualNodeCollider({ x: p.x, y: p.y, z: 0 })
                );
              }
            }
          }
        }
      }
    }
  }
}
