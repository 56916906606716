import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { MevacoState } from '../../../app/store';
import { SetPerfactionEditorState } from '../../../app/store/actions';
import { TranslationProvider } from '../../providers/translation.provider';

@Component({
  selector: 'app-perfaction-editor',
  templateUrl: './perfaction-editor.component.html',
  styleUrls: ['./perfaction-editor.component.css']
})
export class PerfactionEditorComponent implements OnInit {

  @Output() close = new EventEmitter<void>();
  public isConfigOpened = true;

  constructor(private translationProvider: TranslationProvider, private store: Store<MevacoState>) { }

  ngOnInit() {
  }

  resetPerfaction(){

  }

  closePerfaction(){
    this.store.dispatch(new SetPerfactionEditorState(false));
  }

  onCloseConfig(){
    this.isConfigOpened = false;
  }

  openConfig(){
    this.isConfigOpened = true;
  }

  translate(text: string, module: string = 'designer') {
    return this.translationProvider.translate(text, module);
  }
  

}
