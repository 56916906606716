import {AbstractMesh, ArcRotateCamera, ArcRotateCameraPointersInput, Matrix, Scene, Vector3} from "@babylonjs/core";

export class RotateCamera extends ArcRotateCamera {
  public rotationOrigin: Vector3;

  constructor(
    name: string,
    alpha: number,
    beta: number,
    radius: number,
    target: Vector3,
    scene: Scene,
    setActiveOnSceneIfNoneActive?: boolean
  ) {
    super(
      name,
      alpha,
      beta,
      radius,
      target,
      scene,
      setActiveOnSceneIfNoneActive
    );
    (<ArcRotateCameraPointersInput>(
      this.inputs.attached.pointers
    )).buttons = [2];
    this._isDirty = true;
  }


  public _checkInputs(): void {
    if (this.rotationOrigin) {
      if (
        this.inertialAlphaOffset !== 0 ||
        this.inertialBetaOffset !== 0 ||
        this.inertialRadiusOffset !== 0
      ) {
        let inertialAlphaOffset = this.inertialAlphaOffset;
        if (this.beta <= 0) inertialAlphaOffset *= -1;
        if (this.getScene().useRightHandedSystem) inertialAlphaOffset *= -1;
        if (this.parent && this.parent._getWorldMatrixDeterminant() < 0)
          inertialAlphaOffset *= -1;
        let newTarget = this._target;
        newTarget = newTarget.subtract(this.rotationOrigin);

        const alphaRotationMatrix = Matrix.RotationY(
          -inertialAlphaOffset
        );
        newTarget = Vector3.TransformCoordinates(
          newTarget,
          alphaRotationMatrix
        );

        newTarget = newTarget.add(this.rotationOrigin);

        this._target = newTarget;
      }
    }
    super._checkInputs();
  }

  public _updatePosition(): void {
    const currentZ = this.position.z;
    super._updatePosition();
    const scene = this.getScene();
    const untranstlated = scene.unTranslatedPointer;
    const wantedZ = this.position.z;
    const ray = scene.createPickingRay(
      untranstlated.x,
      untranstlated.y,
      null,
      this,
      false
    );
    // const positionV = linePlaneIntersection(ray.direction, ray.origin, {x: 0, y: 0, z: -1}, {x: 0, y: 0, z: 0});
    this.setPosition(
      this.position.add(ray.direction.scale(currentZ - wantedZ))
    );
  }


  public setPosition(position: Vector3): void {
    super.setPosition(position);
    this._newPosition = position.clone();
    this._position = this.position;
    this._globalPosition = this.position;
  }

  setTarget(target: AbstractMesh | Vector3): void {
    if (target) {
      super.setTarget(target);
    }
  }
}
