import {
  addVectors2,
  copyVector2,
  getIntersectionScalar,
  getRightPerpendicularVector2,
  isPointBetween,
  multiplyVector2byScalar,
  normalizeVector2,
  projectPointOnLine,
  Segment,
  SegmentType,
  subVectors2,
  Vector2
} from 'webcad';

export interface HelpLine {
  direction: Vector2;
  position: Vector2;
  offset: number;
  visible: boolean;
}

export function HelpLineToSegment(helpLine: HelpLine): Segment {
  const realPos = getRealHelpLinePosition(helpLine);
  const a = addVectors2(realPos, multiplyVector2byScalar(helpLine.direction, 20));
  const b = addVectors2(realPos, multiplyVector2byScalar(helpLine.direction, -20));
  return {
    begin: b,
    end: a,
    type: SegmentType.line
  };
}

export function getHelpLineIntersection(hl1: HelpLine, hl2: HelpLine): Vector2 {
  const realPos1 = getRealHelpLinePosition(hl1);
  const realPos2 = getRealHelpLinePosition(hl2);

  const t = getIntersectionScalar({origin: realPos1, direction: hl1.direction}, {
    origin: realPos2,
    direction: hl2.direction
  });
  if (t === null) {
    return null
  } else {
    return addVectors2(realPos1, multiplyVector2byScalar(hl1.direction, t));
  }
}

export function copyHelpLine(source: HelpLine): HelpLine {
  return {
    direction: copyVector2(source.direction),
    position: copyVector2(source.position),
    offset: source.offset,
    visible: source.visible
};
}

export function helpLinesEqual(a: HelpLine, b: HelpLine): boolean {
  return (a && b && a.position && a.direction && b.position && b.direction) &&
    (a.direction.x * 1000 * 0.001 === b.direction.x * 1000 * 0.001
      && a.direction.y * 1000 * 0.001 === b.direction.y * 1000 * 0.001) &&
    (a.offset === b.offset) &&
    (a.position.x * 1000 * 0.001 === b.position.x * 1000 * 0.001
      && a.position.y * 1000 * 0.001 === b.position.y * 1000 * 0.001);
}

export function isPointOnHelpLine(point: Vector2, helpLine: HelpLine): boolean {
  const realPos = getRealHelpLinePosition(helpLine);
  const a = addVectors2(realPos, multiplyVector2byScalar(helpLine.direction, 20));
  const b = addVectors2(realPos, multiplyVector2byScalar(helpLine.direction, -20));
  return isPointBetween(a, b, point);
}

export function getRealHelpLinePosition(model: HelpLine): Vector2 {
  const rightPerp = getRightPerpendicularVector2(normalizeVector2(model.direction));
  return addVectors2(model.position, multiplyVector2byScalar(rightPerp, model.offset * 0.001));
}

export function projectPointOnHelpLine(model: HelpLine, point: Vector2, maxDist: number) {
  const realPos = getRealHelpLinePosition(model);
  const a = addVectors2(realPos, multiplyVector2byScalar(model.direction, 20));
  const b = addVectors2(realPos, multiplyVector2byScalar(model.direction, -20));
  const dir = subVectors2(a, b);
  return projectPointOnLine(b, normalizeVector2(dir), point, maxDist);

}

export function createHelpLine(angle: number, inRadians: boolean, offset: number, point: Vector2, visible: boolean = true): HelpLine {
  const x = inRadians ? Math.cos(angle) : Math.cos(angle * Math.PI / 180);
  const y = inRadians ? Math.sin(angle) : Math.sin(angle * Math.PI / 180);
  return {
    direction: {x: x, y: y},
    offset: offset,
    position: copyVector2(point),
    visible: visible
  };
}
