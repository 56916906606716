import {MeasurementModel} from 'webcad/models';
import {Drawing} from '../drawing.model';
import {AngleMeasurementModel} from 'webcad/models';

export interface AngleMeasurementsViewModel {
  measurements: Map<number, AngleMeasurementModel>;
  state: boolean;
}

export const AngleMeasurementsViewModelPipeLine = (state: Drawing): AngleMeasurementsViewModel => {
  return {
    measurements: state.angleMeasurements,
    state: state.snapOptions.measurements
  };
};
