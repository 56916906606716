<ng-template #tipContent>
  <span>{{ translate("Drawing add tooltip", "designer") }}</span>
</ng-template>

<ng-template #tipContent2>
  <span>{{ translate("Drawing remove tooltip", "designer") }}</span>
</ng-template>

<ng-template #tipSelectContour>
  <span>{{ translate("Select contour tooltip", "designer") }}</span>
</ng-template>
<ng-template #tipSelectContourPoint>
  <span>{{ translate("Select contour points tooltip", "designer") }}</span>
</ng-template>

<div [hidden]="!selectTool.isDirty()">
  <div>
    <label for="horizontal"></label>
    <img class="horizontal" src="assets/Horizontal.svg" />
    <input
      id="horizontal"
      type="number"
      class="input-horizontal"
      [value]="round(selectTool.moveSum.x * 100000) / 100"
      (input)="setHorizontalMove($event.target.value)"
    />
  </div>
  <div>
    <label for="vertical"></label>
    <img class="vertical" src="assets/Vertical.svg" />
    <input
      id="vertical"
      type="number"
      class="input-vertical"
      [value]="round(selectTool.moveSum.y * 100000) / 100"
      (input)="setVerticalMove($event.target.value)"
    />
  </div>
</div>

<div [hidden]="!($anyOwnedActive | async)">
  <div class="tools-section">
    <div class="tools-caption">{{ translate("Forms") }}</div>

    <div *ngIf="(contureTools | async).length > 0" class="tool-hint">
      <span class="tool-hint-text">{{ translate("Select contour") }}</span>
      <div
        tooltipClass=""
        class="hint-img-wrapper"
        placement="right"
        [ngbTooltip]="tipSelectContour"
      >
        <img src="assets/hint-icon.svg" alt="hint" />
      </div>
    </div>

    <div class="tools">
      <div
        *ngFor="let tool of contureTools | async"
        [ngbTooltip]="
          tool.toolTip ? tool.toolTip : translate('Add' + tool.name)
        "
        tooltipClass="custom-tooltip--small"
        [placement]="['top-left', 'right']"
      >
        <button
          class="tool-btn"
          (click)="setAddTool(tool)"
          [ngClass]="{
            'tool-btn--active':
              ($activeTool | async) === tool.tool &&
              (currentActionType | async) === ActionType.ADD
          }"
        >
          <mat-icon svgIcon="{{ tool.name }}"></mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="(eckpunkteTools | async).length > 0" class="tool-hint">
      <span class="tool-hint-text">{{
        translate("Select corner points")
      }}</span>
      <div
        tooltipClass="custom-tooltip"
        class="hint-img-wrapper"
        placement="right"
        [ngbTooltip]="tipSelectContourPoint"
      >
        <img src="assets/hint-icon.svg" alt="hint" />
      </div>
    </div>

    <div class="tools">
      <div
        *ngFor="let tool of eckpunkteTools | async"
        tooltipClass="custom-tooltip--small"
        [ngbTooltip]="
          tool.toolTip ? tool.toolTip : translate('Add' + tool.name)
        "
        [placement]="['top-left', 'right']"
      >
        <button
          class="tool-btn"
          (click)="setAddTool(tool)"
          [ngClass]="{
            'tool-btn--active':
              ($activeTool | async) === tool.tool &&
              (currentActionType | async) === ActionType.ADD
          }"
        >
          <mat-icon svgIcon="{{ tool.name }}"></mat-icon>
        </button>

        <div *ngFor="let tool of eckpunkteTools | async">
          <div *ngIf="tool.controls">
            <button
              class="btn btn-outline-primary mt-10"
              *ngFor="let button of tool.controls"
              (click)="button.action()"
              [disabled]="!(button.enable | async)"
              [ngClass]="{ active: (button.active | async) }"
            >
              {{ translate(button.name, "designer") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="(drawingTools | async).length > 0" class="tool-hint">
      <span class="tool-hint-text">{{ translate("Add", "designer") }}</span>
      <div
        tooltipClass=""
        class="hint-img-wrapper"
        placement="right"
        [ngbTooltip]="
          translate('Add shape to perforation area tooltip', 'designer')
        "
      >
        <img src="assets/hint-icon.svg" alt="hint" />
      </div>
    </div>

    <div class="tools">
      <div
        *ngFor="let tool of drawingTools | async"
        [ngbTooltip]="
          tool.toolTip ? tool.toolTip : translate('Add' + tool.name)
        "
        tooltipClass="custom-tooltip--small"
        [placement]="['top-left', 'right']"
      >
        <button
          class="tool-btn"
          (click)="setAddTool(tool)"
          [ngClass]="{
            'tool-btn--active':
              ($activeTool | async) === tool.tool &&
              (currentActionType | async) === ActionType.ADD
          }"
        >
          <mat-icon svgIcon="{{ tool.name }}"></mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="(drawingTools | async).length > 0" class="tool-hint">
      <span class="tool-hint-text">{{ translate("Remove", "designer") }}</span>
      <div
        tooltipClass=""
        class="hint-img-wrapper"
        placement="right"
        [ngbTooltip]="
          translate('Remove shape from perforation area tooltip', 'designer')
        "
      >
        <img src="assets/hint-icon.svg" alt="hint" />
      </div>
    </div>

    <div class="tools">
      <div
        *ngFor="let tool of drawingTools | async"
        [ngbTooltip]="
          tool.toolTip ? tool.toolTip : translate('Remove' + tool.name)
        "
        tooltipClass="custom-tooltip--small"
        [placement]="['top-left', 'right']"
      >
        <button
          class="tool-btn"
          (click)="setRemoveTool(tool)"
          [ngClass]="{
            'tool-btn--active':
              ($activeTool | async) === tool.tool &&
              (currentActionType | async) === ActionType.REMOVE
          }"
        >
          <mat-icon svgIcon="{{ tool.name }}-"></mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="(measureTools | async).length > 0" class="tools-section">
    <div class="tools-caption">{{ translate("Measurement") }}</div>

    <div class="tools">
      <div
        *ngFor="let tool of measureTools | async"
        [hidden]="tool.hidden ? (tool.hidden | async) : false"
        tooltipClass="custom-tooltip--small"
        [ngbTooltip]="tool.toolTip ? tool.toolTip : translate(tool.name)"
        [placement]="['top-left', 'right']"
      >
        <button
          class="tool-btn"
          (click)="setTool(tool)"
          [ngClass]="{
            'tool-btn--active': ($activeTool | async) === tool.tool
          }"
        >
          <mat-icon svgIcon="{{ tool.name }}"></mat-icon>
        </button>
      </div>
      <div *ngFor="let tool of measureTools | async">
        <div *ngIf="tool.controls">
          <button
            class="btn btn-outline-primary mt-10"
            *ngFor="let button of tool.controls"
            (click)="button.action()"
            [disabled]="!(button.enable | async)"
            [ngClass]="{ active: (button.active | async) }"
          >
            {{ button.name }}
            {{ translate(button.name, "designer") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
