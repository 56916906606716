import { PointerCollider } from "webcad/collision";
import { Pointer } from "webcad/collision/pointer";
import { PointerIntersection } from "webcad/collision/pointer-intersection";
import { sqrDistanceVector2 } from "webcad/math";
import { projectPointOnSegment, Segment } from "webcad/models";
import { UnderPointerType } from "../../model/pointer-state.model";
import { ColliderPriority } from "./collider-priorities";
import { CursorType } from "./cursor.type";
import { MevacoCollider, SnappingContext } from "./mevaco.collider";
import { snapToGrid } from "./utils";

export interface SegmentCollider extends MevacoCollider {
  segment: Segment;
  perforationAreaSegment: number; //-1 not a perforation segment
}

export class SegmentBaseCollider implements PointerCollider, SegmentCollider {
  priority = ColliderPriority.SEGMENT;
  objectType: UnderPointerType;
  public cursorType: CursorType = CursorType.envelope;
  name = "SegmentBase";

  public get object(): any {
    return this.segment;
  }

  constructor(
    public segment: Segment,
    protected snappingModelRef: SnappingContext,
    public perforationAreaSegment = -1
  ) {
    this.objectType = UnderPointerType.SEGMENT;
  }

  getIntersection(pointer: Pointer, epsilon: number): PointerIntersection {
    const segment = this.segment;
    const point = pointer.onWorkingPlane;

    let pp = projectPointOnSegment(point, segment);

    if (pp && sqrDistanceVector2(point, pp) <= epsilon * epsilon) {
      if (this.snappingModelRef.snappingOptions.grid) {
        pp = snapToGrid(
          pp,
          segment,
          this.snappingModelRef.snappingModel,
          epsilon
        );
      }
      return {
        position: { x: pp.x, y: pp.y, z: 0 },
        collider: this,
      };
    }
  }

  compare(other: PointerCollider): boolean {
    return (
      other instanceof SegmentBaseCollider && this.segment === other.segment
    );
  }
}
