import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Subscription, combineLatest } from "rxjs";
import { toScreen } from "webcad/html-layer/utils";
import { vector2toVector3 } from "webcad/math/utils";
import { HtmlLabelModel } from "webcad/models/html-label.model";
import { TranslationProvider } from "../../providers/translation.provider";
import { View3dProvider } from "../../providers/view3d.provider";
import { WebcadProvider } from "../../providers/webcad.provider";

@Component({
  selector: "rule-error",
  templateUrl: "./rule-error.component.html",
  styleUrls: ["./rule-error.component.css"],
})
export class RuleErrorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() labelModel: HtmlLabelModel;
  readonly host: any;
  private webcadSub: Subscription;
  private changeSub: Subscription;
  private parent: HTMLDivElement;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private webCadProvider: WebcadProvider,
    private view3dProvider: View3dProvider,
    private translationProvider: TranslationProvider
  ) {
    this.host = el.nativeElement;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.webcadSub = this.webCadProvider.getObservable().subscribe((webcad) => {
      if (!!webcad) {
        if (this.parent) {
          this.parent.removeChild(this.host);
        }
        this.parent = webcad.getHtmlLayer().getRootDiv();
        webcad.getHtmlLayer().getRootDiv().appendChild(this.host);
        if (this.changeSub && !this.changeSub.closed) {
          this.changeSub.unsubscribe();
        }
        this.changeSub = combineLatest([
          this.view3dProvider.mevacoView3D.onBeforeRender,
          webcad.getHtmlLayer().size,
        ]).subscribe(([camera, size]) => {
          const posV3 = vector2toVector3(this.labelModel.placement.position);
          const onScreenPos = toScreen(posV3, webcad.viewState.camera);
          const onScreenX = onScreenPos.x > -1 || onScreenPos.x < 1;
          const onScreenY = onScreenPos.y > -1 || onScreenPos.y < 1;
          const onScreen = onScreenX && onScreenY;
          const display = onScreen ? "inline" : "none";
          const left =
            ((onScreenPos.x / 2 + 0.5) * size.width).toString() + "px";
          const top =
            ((-onScreenPos.y / 2 + 0.5) * size.height).toString() + "px";
          const transform =
            "rotate(" +
            Math.round(
              (this.labelModel.placement.angle * 180) / Math.PI
            ).toString() +
            "deg)";
          this.renderer.setAttribute(
            this.host,
            "style",
            "" +
              "z-index: 255; " +
              "position: absolute; " +
              "top: " +
              top +
              ";" +
              "left: " +
              left +
              ";" +
              "display: " +
              display +
              ";" +
              "transform: " +
              transform +
              ";" +
              "pointer-events: all;"
          );
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.parent.removeChild(this.host);
    if (this.webcadSub) {
      this.webcadSub.unsubscribe();
    }
    if (this.changeSub) {
      this.changeSub.unsubscribe();
    }
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}
