import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store, select } from "@ngrx/store";
import * as jwtDecode from "jwt-decode";
import { Observable, Subscription, combineLatest } from "rxjs";
import { delay, startWith } from "rxjs/operators";
import { PlateMaterialType } from "../../model";
import { Element } from "../../model/product-configuration/element.model";
import {
  CallZoom,
  DatasetLoad,
  ProductConfigurationLoad,
  ShowViewerElement,
} from "../../store/actions";
import { LoadMountings } from "../../store/actions/mountings.actions";
import {
  MevacoState,
  getConfigurationElements,
  getMountings,
  isDataReady,
} from "../../store/reducers";

@Component({
  selector: "app-viewer-layout",
  templateUrl: "./viewer-layout.component.html",
  styleUrls: ["./viewer-layout.component.css"],
})
export class ViewerLayoutComponent implements OnInit {
  @Input() configId: string;
  public PlateMaterialType = PlateMaterialType;
  public elements: Observable<Element[]>;
  private firstSub: Subscription;

  constructor(
    private store: Store<MevacoState>,
    activatedRoute: ActivatedRoute
  ) {
    this.elements = this.store.pipe(
      select(getConfigurationElements),
      startWith([]),
      delay(0)
    );
    this.firstSub = combineLatest([
      this.store.pipe(select(isDataReady)),
      this.elements,
      this.store.pipe(select(getMountings)),
      activatedRoute.queryParams,
    ]).subscribe(([ready, elements, mountings, params]) => {
      if (ready) {
        if (!!elements && elements.length > 0) {
          if (!!mountings && mountings.length > 0) {
            const jwt = Object.keys(params)[0];
            const payload: any = jwtDecode(jwt);
            const positionIndex =
              +payload.positionIndex > 0 ? +payload.positionIndex : 0;
            this.store.dispatch(new ShowViewerElement(positionIndex + 1));
            this.store.dispatch(new CallZoom());
            this.firstSub.unsubscribe();
          }
        }
      }
    });
  }

  ngOnInit() {
    this.store.dispatch(new ProductConfigurationLoad(this.configId));
    this.store.dispatch(new DatasetLoad());
    this.store.dispatch(new LoadMountings());
  }
}
