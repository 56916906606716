import {Vector3} from 'webcad/math';
import {Vector2} from 'webcad/math';
import {Segment} from 'webcad/models';
import {ShapeOrigin} from './drawing.model';
import {sqrDistanceVector2} from 'webcad/math';

export type changeNodeCallback = (moveDir: Vector3) => void;

export enum MoveNodeType {
  normal,
  origin,
  bias
}

export interface PointNode {
  position: Vector3;
  type?: MoveNodeType;
  origin?: ShapeOrigin;
  onChangeCallbacks?: changeNodeCallback[];
}

export const initialNode: PointNode = {
  position: {x: 0, y: 0, z: 0},
};


export function pointToString(point: Vector3): string {
  return point.x.toString() + ' : ' + point.y.toString() + ' : ' + point.z.toString();
}

export function createTempNode(position: Vector2 | Vector3): PointNode {
  return {
    position: {x: position.x, y: position.y, z: 0},
  };
}

export function createNode(position: Vector3, origin: ShapeOrigin): PointNode {
  return {
    position: {x: position.x, y: position.y, z: position.z},
    type: null,
    origin: origin,
    onChangeCallbacks: []
  };
}

export function nodesEqualsV2(a: PointNode, b: PointNode) {
  return sqrDistanceVector2(a.position, b.position) <  0.00000005;
}

export function getNodeByPosition(nodes: PointNode[], position: Vector2) {
  for (const node of nodes) {
    if (sqrDistanceVector2(node.position, position) <  0.00000005) {
      return node;
    }
  }
  return null;
}

export function getNodeByPositionAndOrigin(nodes: PointNode[], position: Vector2, origin: ShapeOrigin) {
  for (const node of nodes) {
    if(!!node){
      if (node.origin === origin && sqrDistanceVector2(node.position, position) <  0.0000005) {
        return node;
      }
    }
  }
  return null;
}

export function getBiasNodePosition(segment: Segment): Vector2 {
  const biasNodeAngle = (segment.endAngle + segment.beginAngle) * 0.5;
  return {
    x: segment.origin.x + segment.radius * Math.cos(biasNodeAngle),
    y: segment.origin.y + segment.radius * Math.sin(biasNodeAngle)
  };
}


