import {Action} from '@ngrx/store';
import {PointNode} from '../../model/point-node.model';

export const ADD_NODE = 'ADD SHAPE NODE';
export const MOVE_NODE = 'MOVE_NODE';

export class AddNode implements Action {
  readonly type: string = ADD_NODE;

  constructor(public payload: PointNode) {
  }
}


export const REMOVE_NODE = 'REMOVE NODE';

export class RemoveNode implements Action {
  readonly type: string = REMOVE_NODE;

  constructor(public payload: PointNode) {
  }
}

export const REMOVE_ALL_TEMP_NOODES = 'REMOVE ALL TEMP NODES';

export class RemoveAllTemporaryNodes implements Action {
  readonly type: string = REMOVE_ALL_TEMP_NOODES;

  constructor(public payload: any = null) {
  }
}

export type NodeActions = AddNode | RemoveNode;
