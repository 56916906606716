import {Tool} from '../../tool.interface';
import {ClosestSegments} from '../../../providers/mevaco-pointer.provider';
import {PointerState} from 'webcad/collision';
import {select, Store} from '@ngrx/store';
import {getImportedShape, MevacoState} from '../../../store/reducers';
import {Segment} from 'webcad/models';
import {SegmentCollider} from '../../../providers/colliders/segment.collider';
import {AddShapeToAdd, ChangeActionType} from '../../../store/actions';
import {ActionType} from '../../../model';
import {SetHintMessage} from "../../../store/actions/drawing.actions";
import {TranslationProvider} from "../../../providers/translation.provider";

export class OutlinePerforationImportTool extends Tool {
  private shape: Segment[][];

  constructor(private store: Store<MevacoState>, private translationProvider: TranslationProvider) {
    super();
    this.store.pipe(select(getImportedShape)).subscribe((v) => {
      this.shape = v;
    });
  }

  activate() {
    this.store.dispatch(new ChangeActionType(ActionType.ADD));
    this.store.dispatch(new SetHintMessage(this.translationProvider.translate('PerforationOutlineImportHint')));
  }

  isDirty(): boolean {
    return false;
  }

  onCancel() {
  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {
  }

  onConfirm() {
  }

  onMouseClick(pointerState: PointerState) {
    if (pointerState.intersection) {
      if ((pointerState.intersection.collider as SegmentCollider).segment) {
        const segment = (pointerState.intersection.collider as SegmentCollider).segment;
        for (const c of this.shape) {
          if (c.includes(segment)) {
            this.store.dispatch(new AddShapeToAdd(c));
            return;
          }
        }
      }
    }
  }

  onMouseDown(pointerState: PointerState) {
  }

  onMouseMove(pointerState: PointerState) {
  }

  onMouseUp(pointerState: PointerState) {
  }

  reset() {
  }

}
