import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map, switchMap, tap } from "rxjs/operators";
import { DatasetService } from "../../services/dataset.service";
import { ProductConfigurationService } from "../../services/product-configuration.service";
import {
  ADD_SHAPE_TO_ADD,
  ADD_SHAPE_TO_REMOVE,
  BUILD_PLATE,
  BlockUser,
  PRODUCT_CONFIGURATION_SAVED_SUCCESSFULLY,
  PRODUCT_CONFIGURATION_SAVE_SKIPPED,
  REDO_STATE,
  UNDO_STATE,
} from "../actions";
import {
  TREAT_CORNER,
  TREAT_SEGMENT_CORNERS,
} from "../actions/drawing.actions";
import { MevacoState } from "../reducers";

@Injectable()
export class BlockUserTillSaveEffect {
  constructor(
    private actions: Actions,
    private productConfigurationService: ProductConfigurationService,
    private datasetService: DatasetService,
    private store: Store<MevacoState>
  ) {}

  blockUserTillSaveEffect = createEffect(() =>
    this.actions.pipe(
      ofType(
        ADD_SHAPE_TO_ADD,
        ADD_SHAPE_TO_REMOVE,
        UNDO_STATE,
        REDO_STATE,
        BUILD_PLATE,
        TREAT_CORNER,
        TREAT_SEGMENT_CORNERS
      ),
      tap((action) => this.store.dispatch(new BlockUser(true))),
      switchMap((_) =>
        this.actions.pipe(
          ofType(
            PRODUCT_CONFIGURATION_SAVED_SUCCESSFULLY,
            PRODUCT_CONFIGURATION_SAVE_SKIPPED
          )
        )
      ),
      map((_) => new BlockUser(false))
    )
  );
}
