import {MevacoPage} from '../model/mevaco-page.model';
import {Drawing, initialDrawing} from '../model/drawing.model';
import {MeasurementModel} from 'webcad';
import {ParseDecimalNumber} from 'webcad/utils/parse-number';
import {MeasurementMask} from '../model/measurement-mask.enum';

export class CustomPatternDrawingProvider {
  private startX = 0;
  private rangeX = 0;
  private posX = 0;
  private startY = 0;
  private rangeY = 0;
  private posY = 0;
  private drawing: Drawing = {
    ...initialDrawing
/*    snapOptions: {
      ...initialDrawing.snapOptions,
      grid: true
    }*/
  };

  getDrawing(mevacoPage: MevacoPage): Drawing {
    const custom = mevacoPage.productConfiguration.perforation.custom;
    const stamp = custom.stumps[ mevacoPage.selectedCustomStamp];
    const perforation = mevacoPage.lastPerforationResponse.perforation[mevacoPage.selectedCustomStamp];
    const startX = stamp && stamp.startX || 0;
    const rangeX = stamp && custom.offsetX && stamp.offsetDivX ? custom.offsetX / stamp.offsetDivX : 0;
    const posX = perforation && perforation.positions && perforation.positions[0].x || 0;
    const startY = stamp && stamp.startY || 0;
    const rangeY = stamp && custom.offsetY && stamp.offsetDivY ? custom.offsetY / stamp.offsetDivY : 0;
    const posY = perforation && perforation.positions && perforation.positions[0].y || 0;

    if (
      mevacoPage.selectedCustomStamp !== -1 && (
        this.startX !== startX || this.posX !== posX || this.rangeX !== rangeX ||
        this.startY !== startY || this.posX !== posY || this.rangeX !== rangeY
      )
    ) {
      const measurements = new Map<number, MeasurementModel>();
      measurements.set(0, {
        editable: false,
        start: {x: 0, y: 0, z: 0},
        direction: {x: 0, y: -1, z: 0},
        measurementDirection: {x: 1, y: 0, z: 0},
        maxValue: 1,
        focusable: false,
        visible: true,
        distance: 0,
        exchange: {
          value: startX * rangeX / 1000,
          onInputLive: null, // to be set by tool
          onInputConfirmed: null, // to be set by tool
          inputValidation: (val) => val !== '' && ParseDecimalNumber(val) !== undefined,
          fromModel: (value: number) => (Math.round(value * 10000) / 10).toString(),
          toModel: (value: string) => (ParseDecimalNumber(value) / 1000)
        },
        mask: MeasurementMask.Plate,
      });
      measurements.set(1, {
        editable: false,
        start: {x: posX, y: 0, z: 0},
        direction: {x: 0, y: -1, z: 0},
        measurementDirection: {x: 1, y: 0, z: 0},
        maxValue: 1,
        focusable: false,
        visible: true,
        distance: 0,
        exchange: {
          value: rangeX / 1000,
          onInputLive: null, // to be set by tool
          onInputConfirmed: null, // to be set by tool
          inputValidation: (val) => val !== '' && ParseDecimalNumber(val) !== undefined,
          fromModel: (value: number) => (Math.round(value * 10000) / 10).toString(),
          toModel: (value: string) => (ParseDecimalNumber(value) / 1000)
        },
        mask: MeasurementMask.Plate,
      });
      measurements.set(2, {
        editable: false,
        start: {x: 0, y: 0, z: 0},
        direction: {x: -1, y: 0, z: 0},
        measurementDirection: {x: 0, y: 1, z: 0},
        maxValue: 1,
        focusable: false,
        visible: true,
        distance: 0,
        exchange: {
          value: startY * rangeY / 1000,
          onInputLive: null, // to be set by tool
          onInputConfirmed: null, // to be set by tool
          inputValidation: (val) => val !== '' && ParseDecimalNumber(val) !== undefined,
          fromModel: (value: number) => (Math.round(value * 10000) / 10).toString(),
          toModel: (value: string) => (ParseDecimalNumber(value) / 1000)
        },
        mask: MeasurementMask.Plate,
      });
      measurements.set(3, {
        editable: false,
        start: {x: 0, y: posY, z: 0},
        direction: {x: -1, y: 0, z: 0},
        measurementDirection: {x: 0, y: 1, z: 0},
        maxValue: 1,
        focusable: false,
        visible: true,
        distance: 0,
        exchange: {
          value: rangeY / 1000,
          onInputLive: null, // to be set by tool
          onInputConfirmed: null, // to be set by tool
          inputValidation: (val) => val !== '' && ParseDecimalNumber(val) !== undefined,
          fromModel: (value: number) => (Math.round(value * 10000) / 10).toString(),
          toModel: (value: string) => (ParseDecimalNumber(value) / 1000)
        },
        mask: MeasurementMask.Plate,
      });

      this.startX = startX;
      this.rangeX = rangeX;
      this.posX = posX;
      this.drawing = {
        ...this.drawing,
        measurements
      };
    }
    if (this.drawing.snappingModel !== mevacoPage.drawing.snappingModel ) {
      this.drawing = {
        ...this.drawing,
        snappingModel: mevacoPage.drawing.snappingModel
      };
    }
    if (this.drawing.snapOptions.grid !== mevacoPage.drawing.snapOptions.grid ) {
      this.drawing = {
        ...this.drawing,
        snapOptions: {
          ...mevacoPage.drawing.snapOptions,
          grid: mevacoPage.drawing.snapOptions.grid
        }
      };
    }
    return this.drawing;
  }
}
