import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AddUserPattern} from '../../../store/actions/user-pattern.actions';
import {select, Store} from '@ngrx/store';
import {getMevacoPageState, isMevacoUser, MevacoState} from '../../../store/reducers';
import {PreviewPlateProvider} from '../../../providers/preview-plate.provider';
import {Observable, Subject} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {MevacoPage} from '../../../model/mevaco-page.model';
import {PreviewImageService} from '../../../services/preview-image.service';
import {Guid} from 'guid-typescript';
import {TranslationProvider} from '../../../providers/translation.provider';

@Component({
  selector: 'app-custom-pattern-save',
  templateUrl: './custom-pattern-save.component.html',
  styleUrls: ['./custom-pattern-save.component.css']
})
export class CustomPatternSaveComponent implements OnInit {

  @Output() close = new EventEmitter<boolean>();
  name = '';

  imgBase64 = '';
  publicPattern = false;
  public isMevacoUser: Observable<boolean>;

  constructor(
    private store: Store<MevacoState>,
    private previewBuilder: PreviewPlateProvider,
    private previewImageService: PreviewImageService,
    private translationProvider: TranslationProvider,
  ) { }

  ngOnInit() {
    this.isMevacoUser = this.store.pipe(
      select(  isMevacoUser ),
      map (x => x === 'Yes')
    );
  }

  async save() {
    const previewSubject = new Subject<string>();
    const originalState = await this.store.pipe(select(getMevacoPageState)).pipe(take(1)).toPromise();
    const state: MevacoPage = {
      ...originalState,
      drawing: {
        ...originalState.drawing,
        snapOptions: {
          ...originalState.drawing.snapOptions,
          helpLines: false,
          grid: false,
          nodes: false,
          measurements: false
        }
      },
      customPatternEditorOpened: true,
      selectedCustomStamp: -1
    } as MevacoPage;

    this.previewBuilder.addCustomPatternToSnapshotQueue(previewSubject, state);
    const imgBase64 = await previewSubject.pipe(take(1)).toPromise();
    const dataToSend = imgBase64.replace('data:image/png;base64,', '');
    const previewImageId = Guid.create().toString();
    const id = await this.previewImageService.saveImage(
      {
        ImageData: dataToSend,
        Guid: previewImageId
      }
    ).pipe(take(1)).toPromise();

    this.store.dispatch(new AddUserPattern({
      name: this.name,
      previewImageId,
      publicPattern: this.publicPattern
    }));

    this.close.emit(false);

  }

  translate(text: string, module: string = 'designer') {
    return this.translationProvider.translate(text, module);
  }
}
