import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Webcad } from "webcad";
import { PointerIntersectionProvider, PointerState } from "webcad/collision";
import { Pointer } from "webcad/collision/pointer";
import { PointerIntersection } from "webcad/collision/pointer-intersection";
import { RotateCamera } from "webcad/components";
import {
  addVectors2,
  addVectors3,
  fModF,
  isPointBetween,
  lengthVector2,
  multiplyVector2byScalar,
  multiplyVector3byScalar,
  pointOnLineProjection,
  projectPointToSegment,
  sqrDistanceVector2,
  subVectors2,
  Vector2,
  Vector3,
} from "webcad/math";
import {
  AngleMeasurementModel,
  angleMeasurementToSegment,
  getPointsFromSegment,
  isPointInArcSegment,
  MeasurementModel,
  projectPointOnArcSegment,
  Segment,
  SegmentAndProjectedPoint,
  SegmentType,
} from "webcad/models";
import { workingPlaneUnitSize } from "webcad/models/camera.model";
import { ShapeOrigin } from "../model/drawing.model";
import {
  getHelpLineIntersection,
  HelpLine,
  HelpLineToSegment,
  projectPointOnHelpLine,
} from "../model/help-line.model";
import { HintStatus } from "../model/hint.model";
import {
  getBiasNodePosition,
  getNodeByPositionAndOrigin,
  PointNode,
} from "../model/point-node.model";
import { UnderPointerType } from "../model/pointer-state.model";
import { ShapeWithHoles } from "../model/shape-with-holes";
import { SnapOptions } from "../model/snap-options.model";
import { SnappingModel } from "../model/snapping.model";
import { SetHintPosition } from "../store/actions/drawing.actions";
import {
  getAngleMeasurementsState,
  getColliderItems,
  getHelpLines,
  getHintStatus,
  getMeasurementsState,
  getNodesState,
  getPlate,
  getSnapOptions,
  getSnappingState,
  getToolHelplines,
  MevacoState,
} from "../store/reducers/index";
import { createLineWithDepthOffset } from "../visualizers/line-system";
import { MevacoView3d } from "../visualizers/mevaco-view3d";
import { createNodeMesh } from "../visualizers/nodes.visualizer";
import { CursorType } from "./colliders/cursor.type";
import { DrawingPerforationCollisionTreeProvider } from "./colliders/drawing-perforation-collision-tree-provider.service";
import { MevacoCollider } from "./colliders/mevaco.collider";
import { SegmentCollider } from "./colliders/segment.collider";
import { MevacoCollidersProvider } from "./mevaco-colliders.provider";
import { SceneProvider } from "./scene.provider";
import { ShapeWithHolesProvider } from "./shape-with-holes.provider";
import { WebcadProvider } from "./webcad.provider";
import {
  Camera,
  Color4,
  LinesMesh,
  Matrix, Mesh,
  Nullable,
  Observer,
  Scene,
  Vector3 as B_Vector3,
  Vector2 as B_Vector2,
  Color4 as B_Color4,
  Plane, Axis, PointerEventTypes, AbstractMesh, Epsilon, Scalar
} from "webcad/babylonjs/core";
let maxGapBetweenPoints = 0.005;

export interface ClosestSegments {
  verticalSegment: SegmentAndProjectedPoint;
  horizontalSegment: SegmentAndProjectedPoint;
}

export interface HelpLineAndProjectedPoint {
  helpLine: HelpLine;
  projectedPoint: Vector2;
}

export interface ClosestSegmentAndObjects {
  projectedPoint: Vector2;
  segment: Segment;
  otherSegments: Segment[];
}

export interface ClosestHelpLineAndObjects {
  projectedPoint: Vector2;
  segment: HelpLine;
  otherSegments: HelpLine[];
}

export interface PointAndUnderMouseTypes {
  position: Vector3;
  underPointerTypes: UnderPointerType;
  object: any;
}

export interface SegmentAndNodes {
  segment: Segment;
  beginNode: PointNode;
  endNode: PointNode;
}

export enum OriginalType {
  GRID,
  SEGMENT,
  HELP_LINE,
}

export interface SegmentAndOriginalType {
  segment: Segment;
  type: OriginalType;
}

export interface SegmentAndOriginalTypeWithProjectedPoint {
  segment: SegmentAndOriginalType;
  projectedPoint: Vector2;
}

export interface ObjectAndPoint<T> {
  object: T;
  point: Vector2;
}

@Injectable()
export class MevacoPointerProvider {
  private snappingModel: SnappingModel;
  private nodes: PointNode[];
  private camera: Camera;
  private depth: number;

  private outlineSize: number;
  private originalSize: number;
  private visibleNodeMesh: Mesh;
  private lastNode: PointNode;
  private shapeWithHoles: ShapeWithHoles[];
  private visibleEdgeMesh: LinesMesh;

  private helpLines: HelpLine[];
  private helpLinesIntersections: Vector2[];
  private hightlightedMesh: Mesh;
  private hightlightedObject: any;
  private scene: Scene;
  private snapOptions: SnapOptions;
  private pointerIntersectionProvider: PointerIntersectionProvider;
  public pointerState: Observable<PointerState>;
  private sceneRenderSub: Nullable<Observer<Scene>>;
  private measurements: Map<number, MeasurementModel>;
  private angleMeasurements: Map<number, AngleMeasurementModel>;
  private webcad: Webcad;
  private mevacoCollidersProvider: MevacoCollidersProvider;
  private extraVisibleMeshes: Mesh[] = [];
  private hintStatus: HintStatus;

  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private shapeWithHolesProvider: ShapeWithHolesProvider,
    private webcadProvider: WebcadProvider,
    private perforationCollisionThree: DrawingPerforationCollisionTreeProvider
  ) {
    this.outlineSize = 4;
    this.originalSize = 10;
    this.lastNode = null;
    this.webcadProvider.getObservable().subscribe((v) => (this.webcad = v));
    this.sceneProvider.getSubscription().subscribe((value) => {
      if (this.sceneRenderSub) {
        this.scene.onBeforeRenderObservable.remove(this.sceneRenderSub);
        this.sceneRenderSub = null;
      }
      if (value !== null) {
        this.scene = value;
        this.camera = this.scene.activeCamera;
        this.sceneRenderSub = this.scene.onBeforeRenderObservable.add(
          (ed, es) => {
            const activeCamera = this.scene.activeCamera as unknown;
            const scale = 0.004 * (activeCamera as RotateCamera).radius;
            if (this.visibleNodeMesh) {
              this.visibleNodeMesh.scaling = new B_Vector3(
                scale,
                scale,
                1
              );
            }
            if (this.visibleEdgeMesh) {
              this.visibleEdgeMesh.edgesWidth = scale;
            }
          }
        );
      }
    });
    this.store.pipe(select(getNodesState)).subscribe((nodes) => {
      this.nodes = nodes;
    });
    this.store.pipe(select(getMeasurementsState)).subscribe((measurements) => {
      this.measurements = measurements;
    });
    this.store
      .pipe(select(getAngleMeasurementsState))
      .subscribe((measurements) => {
        this.angleMeasurements = measurements;
      });
    this.store.pipe(select(getSnappingState)).subscribe((snapping) => {
      this.snappingModel = snapping;
      maxGapBetweenPoints = Math.pow(snapping.snapX, 2);
    });
    this.store
      .pipe(select(getSnapOptions))
      .subscribe((value) => (this.snapOptions = value));
    combineLatest([
      this.store.pipe(select(getHelpLines)),
      this.store.pipe(select(getToolHelplines)),
    ]).subscribe(([hL, thL]) => {
      this.helpLines = [
        ...Array.from(hL.values()),
        ...Array.from(thL.values()),
      ];
      this.helpLinesIntersections = [];
      for (let i = 0; i < this.helpLines.length; i++) {
        const hL1 = this.helpLines[i];
        for (let j = i + 1; j < this.helpLines.length; j++) {
          const hL2 = this.helpLines[j];
          const intersection = getHelpLineIntersection(hL1, hL2);
          if (intersection) {
            this.helpLinesIntersections.push(intersection);
          }
        }
      }
    });
    this.store.pipe(select(getPlate)).subscribe((plate) => {
      this.depth = plate.depth;
      this.shapeWithHoles = this.shapeWithHolesProvider.getShapeWithHoles();
    });

    this.mevacoCollidersProvider = new MevacoCollidersProvider(
      this.store.pipe(select(getColliderItems)),
      this.store.pipe(select(getSnappingState)),
      this.store.pipe(select(getSnapOptions)),
      this.webcadProvider,
      this.perforationCollisionThree
    );
    this.store
      .pipe(select(getHintStatus))
      .subscribe((v) => (this.hintStatus = v));
    this.pointerIntersectionProvider = new PointerIntersectionProvider(
      this.mevacoCollidersProvider.colliders,
      this.webcadProvider.getObservable(),
      this.getColliderWithHigherPriority
    );
    this.pointerState = this.pointerIntersectionProvider.pointerState;
    this.pointerIntersectionProvider.pointerState
      .pipe(debounceTime(175))
      .subscribe(this.onPointerStateChange.bind(this));
  }

  getColliderWithHigherPriority(
    p1: PointerIntersection,
    p2: PointerIntersection,
    pointer: Pointer
  ): PointerIntersection {
    const collider1: MevacoCollider = p1.collider as MevacoCollider;
    const collider2: MevacoCollider = p2.collider as MevacoCollider;
    if (collider1.priority > collider2.priority) {
      return p1;
    } else if (collider1.priority < collider2.priority) {
      return p2;
    } else {
      const distToP1 = sqrDistanceVector2(p1.position, pointer.onWorkingPlane);
      const distToP2 = sqrDistanceVector2(p2.position, pointer.onWorkingPlane);
      if (distToP1 < distToP2) {
        return p1;
      } else {
        return p2;
      }
    }
  }

  highlightSegment(segment: Segment) {
    const positions = getPointsFromSegment(segment, true).map(
      (v) => new B_Vector3(v.x, v.y, 0)
    );
    const colors = [];
    const color = new B_Color4(0, 1, 0, 1);
    for (const c of positions) {
      colors.push(color);
    }
    this.hightlightedMesh = createLineWithDepthOffset(
      "visible Edge",
      {
        points: positions,
        colors: colors,
      },
      this.scene,
      -0.0003
    );
    this.hightlightedMesh.enableEdgesRendering();
    this.hightlightedMesh.edgesWidth = Math.abs(
      this.outlineSize *
        (this.scene.activeCamera.position.z / this.originalSize)
    );
    this.hightlightedMesh.edgesColor = new Color4(0, 0.62, 0.89, 1);
  }

  onPointerStateChange(pointerState: PointerState) {
    if (this.hintStatus !== HintStatus.Sticky) {
      this.store.dispatch(new SetHintPosition(pointerState.onScreen));
    }

    const collider: MevacoCollider = pointerState.intersection
      ? (pointerState.intersection.collider as MevacoCollider)
      : null;
    const objectUnderPointer: any = collider ? collider.object : null;
    const cursor: CursorType =
      collider &&
      (collider.cursorType as CursorType) !== null &&
      objectUnderPointer
        ? collider.cursorType
        : CursorType.none;
    // Hightlight
    if (objectUnderPointer !== this.hightlightedObject) {
      if (this.hightlightedMesh) {
        this.hightlightedMesh.dispose(false, true);
        this.hightlightedMesh = null;
      }
      if (objectUnderPointer) {
        switch (collider.objectType) {
          case UnderPointerType.NODE:
            this.hightlightedMesh = createNodeMesh(
              objectUnderPointer as PointNode,
              this.scene
            );
            const highlightedMesh = this.hightlightedMesh;
            break;
          case UnderPointerType.SEGMENT:
            this.highlightSegment(objectUnderPointer as Segment);
            break;
          case UnderPointerType.HELP_LINE:
            this.highlightSegment(
              HelpLineToSegment(objectUnderPointer as HelpLine)
            );
            break;
        }
      }
    }

    // Blias
    if (objectUnderPointer !== this.hightlightedObject) {
      if (this.extraVisibleMeshes) {
        for (const m of this.extraVisibleMeshes) {
          m.dispose();
        }
      }
      this.extraVisibleMeshes = [];
      if (
        !!collider &&
        this.snapOptions.nodes &&
        collider.objectType === UnderPointerType.SEGMENT
      ) {
        const segment: Segment = objectUnderPointer as Segment;
        const nodeType: ShapeOrigin =
          (<SegmentCollider>collider).perforationAreaSegment >= 0
            ? ShapeOrigin.PERFORATION
            : ShapeOrigin.SHAPE;
        if (segment.type === SegmentType.arc) {
          const position = getBiasNodePosition(segment);
          const node = getNodeByPositionAndOrigin(
            this.nodes,
            position,
            nodeType
          );
          if (!!node) {
            this.extraVisibleMeshes.push(createNodeMesh(node, this.scene));
          }
        }
      }
    }

    this.hightlightedObject = objectUnderPointer;
  }

  segmentToSegmentAndOriginalType(
    s: Segment,
    o: OriginalType
  ): SegmentAndOriginalType {
    return {
      segment: s,
      type: o,
    };
  }

  getClosestMeasurementInDistance(
    position: Vector2,
    maxDist: number
  ): ObjectAndPoint<MeasurementModel> {
    const measurementsArray = Array.from(this.measurements.values());
    let closest: ObjectAndPoint<MeasurementModel> = null;
    let closestDist: number = Number.POSITIVE_INFINITY;
    const unitSize = workingPlaneUnitSize(this.webcad.viewState.camera);
    for (const m of measurementsArray) {
      let distance = 0.015 * unitSize.x;
      if (m.distance) {
        // if undefined or 0
        distance = m.distance;
      }
      const start = addVectors3(
        m.start,
        multiplyVector3byScalar(m.direction, distance)
      );
      const pp = pointOnLineProjection(
        position,
        start,
        multiplyVector2byScalar(m.measurementDirection, m.exchange.value)
      );
      if (pp) {
        const dist = sqrDistanceVector2(pp, position);
        if (dist < maxDist) {
          if (!closest || dist < closestDist) {
            closest = {
              object: m,
              point: pp,
            };
            closestDist = dist;
          }
        }
      }
    }
    return closest;
  }

  getClosestAngleMeasurementInDistance(
    position: Vector2,
    maxDist: number
  ): ObjectAndPoint<AngleMeasurementModel> {
    const measurementsArray = Array.from(this.angleMeasurements.values());
    let closest: ObjectAndPoint<AngleMeasurementModel> = null;
    let closestDist: number = Number.POSITIVE_INFINITY;
    const unitSize = workingPlaneUnitSize(this.webcad.viewState.camera);

    for (const m of measurementsArray) {
      const measurementAsSegment = angleMeasurementToSegment(m);

      let radius = 0.05 * unitSize.x;
      if (m.radius) {
        // if undefined or 0
        radius = Math.abs(m.radius);
      }
      measurementAsSegment.radius = radius;
      const pp = projectPointOnArcSegment(position, measurementAsSegment);
      if (pp) {
        const distance = sqrDistanceVector2(pp, position);
        if (
          distance < maxDist &&
          isPointInArcSegment(pp, measurementAsSegment)
        ) {
          if (!closest || distance < closestDist) {
            closest = {
              object: m,
              point: pp,
            };
            closestDist = distance;
          }
        }
      }
    }
    return closest;
  }

  getClosestSnapLinesAsSegments(position: Vector2): Segment[] {
    const closestX =
      Math.round(
        (position.x - this.snappingModel.offset.x) / this.snappingModel.snapX
      ) *
        this.snappingModel.snapX +
      this.snappingModel.offset.x;
    const closestY =
      Math.round(
        (position.y - this.snappingModel.offset.y) / this.snappingModel.snapY
      ) *
        this.snappingModel.snapY +
      this.snappingModel.offset.y;
    const closestXminus =
      Math.round(
        (position.x - this.snappingModel.offset.x) / this.snappingModel.snapX
      ) *
        this.snappingModel.snapX -
      this.snappingModel.offset.x;
    const closestYminus =
      Math.round(
        (position.y - this.snappingModel.offset.y) / this.snappingModel.snapY
      ) *
        this.snappingModel.snapY -
      this.snappingModel.offset.y;
    return [
      {
        type: SegmentType.line,
        begin: addVectors2({ x: closestX, y: closestY }, { x: 0, y: -5 }),
        end: addVectors2({ x: closestX, y: closestY }, { x: 0, y: 5 }),
      },
      {
        type: SegmentType.line,
        begin: addVectors2(
          { x: closestXminus, y: closestYminus },
          { x: 0, y: -5 }
        ),
        end: addVectors2(
          { x: closestXminus, y: closestYminus },
          { x: 0, y: 5 }
        ),
      },
      {
        type: SegmentType.line,
        begin: addVectors2({ x: closestX, y: closestY }, { x: -5, y: 0 }),
        end: addVectors2({ x: closestX, y: closestY }, { x: 5, y: 0 }),
      },
      {
        type: SegmentType.line,
        begin: addVectors2(
          { x: closestXminus, y: closestYminus },
          { x: -5, y: 0 }
        ),
        end: addVectors2(
          { x: closestXminus, y: closestYminus },
          { x: 5, y: 0 }
        ),
      },
    ];
  }

  getClosestHelpLine(
    point: Vector3,
    maxDist: number
  ): HelpLineAndProjectedPoint {
    let closestDistance = Number.POSITIVE_INFINITY;
    let helpLineAndPoint: HelpLineAndProjectedPoint = null;

    for (const hli of this.helpLinesIntersections) {
      if (sqrDistanceVector2(point, hli) < maxDist * maxDist) {
        return { helpLine: null, projectedPoint: hli };
      }
    }

    for (const hL of this.helpLines) {
      const pp = projectPointOnHelpLine(hL, point, maxDist);
      if (pp) {
        const sqrDist = Math.abs(sqrDistanceVector2(pp, point));
        if (sqrDist < closestDistance) {
          closestDistance = sqrDist;
          helpLineAndPoint = { helpLine: hL, projectedPoint: pp };
        }
      }
    }
    return helpLineAndPoint;
  }

  getHelpLinesInDistance(
    point: Vector3,
    maxDist: number
  ): ClosestHelpLineAndObjects {
    let closestDistance = Number.POSITIVE_INFINITY;
    let helpLineAndPoint: HelpLineAndProjectedPoint = null;
    const otherSegments: HelpLine[] = [];
    for (const hL of this.helpLines) {
      const pp = projectPointOnHelpLine(hL, point, maxDist);
      if (pp) {
        const sqrDist = Math.abs(sqrDistanceVector2(pp, point));
        if (sqrDist < maxDist) {
          if (sqrDist < closestDistance) {
            if (helpLineAndPoint !== null) {
              otherSegments.push(helpLineAndPoint.helpLine);
            }
            closestDistance = sqrDist;
            helpLineAndPoint = { helpLine: hL, projectedPoint: pp };
          } else {
            otherSegments.push(hL);
          }
        }
      }
    }
    if (helpLineAndPoint !== null) {
      const index = otherSegments.indexOf(helpLineAndPoint.helpLine);
      if (index > -1) {
        otherSegments.splice(index, 1);
      }
      return {
        segment: helpLineAndPoint.helpLine,
        projectedPoint: helpLineAndPoint.projectedPoint,
        otherSegments: otherSegments,
      };
    }
    return null;
  }

  HelpLinesAndObjectsToSegmentsAndObjects(
    a: ClosestHelpLineAndObjects
  ): ClosestSegmentAndObjects {
    if (!a) {
      return null;
    }
    return {
      segment: HelpLineToSegment(a.segment),
      projectedPoint: a.projectedPoint,
      otherSegments: a.otherSegments.map((v) => HelpLineToSegment(v)),
    };
  }

  nearestHorizontalSegment(
    point: Vector2,
    segmentIterator: (callback: (segment: Segment) => void) => void
  ): SegmentAndProjectedPoint {
    let horizontalSegment: SegmentAndProjectedPoint = null;
    let horizontalClosestDist = Number.MAX_VALUE;
    const minUpperAngle = Math.PI / 4;
    const maxUpperAngle = (3 * Math.PI) / 4;
    segmentIterator((segment: Segment) => {
      const segmentDir = subVectors2(segment.end, segment.begin);
      const segmentRotation = Math.atan2(segmentDir.y, segmentDir.x);
      const normalizedRotatation = fModF(segmentRotation, Math.PI);
      const pp = this.getPointOnSegment(point, segment);
      if (pp !== null) {
        const distToPoint = lengthVector2(subVectors2(pp, point));
        if (
          !(
            normalizedRotatation > minUpperAngle &&
            normalizedRotatation < maxUpperAngle
          )
        ) {
          if (distToPoint < horizontalClosestDist) {
            horizontalClosestDist = distToPoint;
            horizontalSegment = { segment: segment, projectedPoint: pp };
          }
        }
      }
    });
    return horizontalSegment;
  }

  nearestVerticalSegment(
    point: Vector2,
    segmentIterator: (callback: (segment: Segment) => void) => void
  ): SegmentAndProjectedPoint {
    let verticalSegment: SegmentAndProjectedPoint = null;
    let verticalClosestDist = Number.MAX_VALUE;
    const minUpperAngle = Math.PI / 4;
    const maxUpperAngle = (3 * Math.PI) / 4;
    segmentIterator((segment: Segment) => {
      const segmentDir = subVectors2(segment.end, segment.begin);
      const segmentRotation = Math.atan2(segmentDir.y, segmentDir.x);
      const normalizedRotatation = fModF(segmentRotation, Math.PI);
      const pp = this.getPointOnSegment(point, segment);
      if (pp !== null) {
        const distToPoint = lengthVector2(subVectors2(pp, point));
        if (
          normalizedRotatation > minUpperAngle &&
          normalizedRotatation < maxUpperAngle
        ) {
          if (distToPoint < verticalClosestDist) {
            verticalClosestDist = distToPoint;
            verticalSegment = { segment: segment, projectedPoint: pp };
          }
        }
      }
    });
    return verticalSegment;
  }

  private getPointOnSegment(point: Vector2, segment: Segment): Vector2 {
    switch (segment.type) {
      case SegmentType.line:
        const segmentDir = subVectors2(segment.end, segment.begin);
        const pp = projectPointToSegment(segment.begin, segmentDir, point);
        if (isPointBetween(segment.begin, segment.end, pp)) {
          return pp;
        } else {
          return null;
        }
      case SegmentType.arc:
        const projPoint = projectPointOnArcSegment(point, segment);
        if (projPoint && isPointInArcSegment(projPoint, segment)) {
          return projPoint;
        } else {
          return null;
        }
    }
  }
}

export function checkForClosestNode(
  nodes: PointNode[],
  point: Vector2
): PointNode {
  for (const n of nodes) {
    if (sqrDistanceVector2(n.position, point) < maxGapBetweenPoints) {
      return n;
    }
  }
  return null;
}

export function addControls(scene, camera, mevacoView3D: MevacoView3d) {
  camera.inertia = 0.0;
  camera.angularSensibilityX = camera.angularSensibilityY = 500;

  const plane = Plane.FromPositionAndNormal(
    B_Vector3.Zero(),
    Axis.Z
  );

  //  const inertialPanning = BABYLON.Vector3.Zero();

  /** @lineType {BABYLON.Vector3} */
  let initialPos2D;
  let initialTarget: B_Vector3;
  let initialDepth;
  const panningFn = (pi) => {
    //initialTarget = camera.target.clone();
    const newPos2D = getCameraSpacePoint(
      pi.event.layerX,
      pi.event.layerY,
      camera
    );
    const dirX = B_Vector3.TransformNormal(
      new B_Vector3(-1, 0, 0),
      camera.getWorldMatrix()
    );
    const dirY = B_Vector3.TransformNormal(
      new B_Vector3(0, 1, 0),
      camera.getWorldMatrix()
    );
    const dX = ((newPos2D.x - initialPos2D.x) / newPos2D.z) * initialDepth;
    const dY = ((newPos2D.y - initialPos2D.y) / newPos2D.z) * initialDepth;

    dirX.scaleInPlace(dX);
    dirY.scaleInPlace(dY);

    const t = initialTarget.add(dirX);
    t.addToRef(dirY, camera.target);

    mevacoView3D.shouldUpdate = true;
  };

  const wheelPrecisionFn = () => {
    camera.wheelPrecision = (1 / camera.radius) * 1000;
  };

  const zoomFn = (p, e) => {
    const delta = zoomWheel(p, e, camera);
    zooming(delta, scene, camera, plane, camera.target);
    mevacoView3D.shouldUpdate = true;
  };

  /** Rotate the camera
   * @param {BABYLON.Scene} scene
   * @param {BABYLON.Vector2} prvScreenPos
   * @param {BABYLON.ArcRotateCamera} camera
   */
  function rotating(scene, camera, prvScreenPos) {
    const offsetX = scene.pointerX - prvScreenPos.x;
    const offsetY = scene.pointerY - prvScreenPos.y;
    prvScreenPos.copyFromFloats(scene.pointerX, scene.pointerY);
    changeInertialAlphaBetaFromOffsets(offsetX, offsetY, camera);
    mevacoView3D.shouldUpdate = true;
  }

  const prvScreenPos = B_Vector2.Zero();
  const rotateFn = () => {
    rotating(scene, camera, prvScreenPos);
  };

  const removeObservers = () => {
    scene.onPointerObservable.removeCallback(panningFn);
    scene.onPointerObservable.removeCallback(rotateFn);
  };

  scene.onPointerObservable.add((p, e) => {
    removeObservers();
    if (p.event.button === 2) {
      document.querySelector(".viewContainer").classList.add("hand");
      initialTarget = camera.target.clone();
      initialPos2D = getCameraSpacePoint(
        p.event.layerX,
        p.event.layerY,
        camera
      );
      initialDepth = getPlateCenterDepthInCameraSpace(scene, camera);
      scene.onPointerObservable.add(
        panningFn,
        PointerEventTypes.POINTERMOVE
      );
      mevacoView3D.shouldUpdate = true;
    } else if (p.event.button === 1) {
      camera.rotationOrigin = getPosition(scene, camera, plane);
      //scene.onPointerObservable.add(rotateFn, BABYLON.PointerEventTypes.POINTERMOVE);
      mevacoView3D.shouldUpdate = true;
    }
  }, PointerEventTypes.POINTERDOWN);

  scene.onPointerObservable.add((p, e) => {
    removeObservers();
    document.querySelector(".viewContainer").classList.remove("hand");
  }, PointerEventTypes.POINTERUP);

  scene.onPointerObservable.add(zoomFn, PointerEventTypes.POINTERWHEEL);

  scene.onBeforeRenderObservable.add(wheelPrecisionFn);

  // stop context menu showing on canvas right click
  scene
    .getEngine()
    .getRenderingCanvas()
    .addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
}

function normalizeCameraTarger(scene: Scene, camera: RotateCamera) {
  const plate: AbstractMesh = scene.getMeshByName("plate");
  if (!!plate) {
    const minMax: { min: B_Vector3; max: B_Vector3 } =
      plate.getHierarchyBoundingVectors();
    const center = minMax.min.add(minMax.max).scale(0.5);
    const viewMatrix = camera.getViewMatrix(true);
    const screenSpace = B_Vector3.TransformCoordinates(
      center,
      viewMatrix
    );
    const newRadiurs = Math.abs(screenSpace.z);
    const lookDir = camera.target.subtract(camera.position).normalize();
    lookDir.scaleInPlace(newRadiurs);
    camera.position.addToRef(lookDir, camera.target);
    camera.radius = newRadiurs;
  }
}

function getPlateCenterDepthInCameraSpace(
  scene: Scene,
  camera: RotateCamera
): number {
  //const center = getPlateCenter(scene);
  const viewMatrix = camera.getViewMatrix(true);
  const screenSpace = B_Vector3.TransformCoordinates(
    camera.target,
    viewMatrix
  );
  return Math.abs(screenSpace.z);
}
function getCameraSpacePoint(
  pointerX: number,
  pointerY: number,
  camera
): B_Vector3 {
  const cameraViewport = camera.viewport;
  const engine = camera.getEngine();
  var viewport = cameraViewport.toGlobal(
    engine.getRenderWidth(),
    engine.getRenderHeight()
  );

  // Moving coordinates to local viewport world
  const screen = new B_Vector3(
    ((pointerX / engine.getHardwareScalingLevel() - viewport.x) /
      viewport.width) *
      2 -
      1,
    ((pointerY / engine.getHardwareScalingLevel() -
      (engine.getRenderHeight() - viewport.y - viewport.height)) /
      viewport.height) *
      2 -
      1,
    -1.0
  );
  const inv = camera.getProjectionMatrix().clone().invert();

  const result = B_Vector3.TransformCoordinates(screen, inv);
  const m = inv.m;
  var num = screen.x * m[3] + screen.y * m[7] + screen.z * m[11] + m[15];
  if (Scalar.WithinEpsilon(num, 1.0)) {
    result.scaleInPlace(1.0 / num);
  }

  return result;
}

/** Get pos on plane.
 * @param {BABYLON.Scene} scene
 * @param {BABYLON.ArcRotateCamera} camera
 * @param {BABYLON.Plane} plane
 */
function getPosition(scene, camera, plane) {
  const ray = scene.createPickingRay(
    scene.pointerX,
    scene.pointerY,
    Matrix.Identity(),
    camera,
    false
  );
  let distance = ray.intersectsPlane(plane);

  // not using this ray again, so modifying its vectors here is fine
  if (!distance) {
    distance = 0.1;
  } else {
    distance = Math.min(10, distance);
  }

  return ray.origin.addInPlace(ray.direction.scaleInPlace(distance));
}

/** Get the wheel delta divided by the camera wheel precision.
 * @param {BABYLON.PointerInfoPre} p
 * @param {BABYLON.EventState} e
 * @param {BABYLON.ArcRotateCamera} camera
 */
function zoomWheel(p, e, camera) {
  const event = p.event;
  event.preventDefault();
  let delta = 0;
  if (event.deltaY) {
    delta = -event.deltaY; // chrome
  } else if (event.wheelDelta) {
    delta = event.wheelDelta; // anything else
  } else if (event.detail) {
    delta = -event.detail * 33; // Firefox
  }
  delta /= camera.wheelPrecision;
  return delta;
}

/** Zoom to pointer position. Zoom amount determined by delta.
 * @param {number} delta
 * @param {BABYLON.Scene} scene
 * @param {BABYLON.ArcRotateCamera} camera
 * @param {BABYLON.Plane} plane
 * @param {BABYLON.Vector3} ref
 */
function zooming(delta, scene, camera, plane, ref) {
  if (camera.radius - camera.lowerRadiusLimit < 0.01 && delta > 0) {
    return;
  } else if (camera.upperRadiusLimit - camera.radius < 0.01 && delta < 0) {
    return;
  }
  const inertiaComp = 1 - camera.inertia;
  if (
    camera.radius - (camera.inertialRadiusOffset + delta) / inertiaComp <
    camera.lowerRadiusLimit
  ) {
    delta =
      (camera.radius - camera.lowerRadiusLimit) * inertiaComp -
      camera.inertialRadiusOffset;
  } else if (
    camera.radius - (camera.inertialRadiusOffset + delta) / inertiaComp >
    camera.upperRadiusLimit
  ) {
    delta =
      (camera.radius - camera.upperRadiusLimit) * inertiaComp -
      camera.inertialRadiusOffset;
  }

  const zoomDistance = delta / inertiaComp;
  const ratio = zoomDistance / camera.radius;
  const vec = getPosition(scene, camera, plane);

  const directionToZoomLocation = vec.subtract(camera.target);
  const offset = directionToZoomLocation.scale(ratio);
  offset.scaleInPlace(inertiaComp);
  ref.addInPlace(offset);

  camera.inertialRadiusOffset += delta;
  normalizeCameraTarger(scene, camera);
}

/** Modifies the camera's inertial alpha and beta offsets.
 * @param {number} offsetX
 * @param {number} offsetY
 * @param {BABYLON.ArcRotateCamera} camera
 */
function changeInertialAlphaBetaFromOffsets(offsetX, offsetY, camera) {
  const alphaOffsetDelta = offsetX / camera.angularSensibilityX;
  const betaOffsetDelta = offsetY / camera.angularSensibilityY;
  camera.inertialAlphaOffset -= alphaOffsetDelta;
  camera.inertialBetaOffset -= betaOffsetDelta;
}

/** Sets x y or z of passed in vector to zero if less than Epsilon.
 * @param {BABYLON.Vector3} vec
 */
function zeroIfClose(vec) {
  if (Math.abs(vec.x) < Epsilon) {
    vec.x = 0;
  }
  if (Math.abs(vec.y) < Epsilon) {
    vec.y = 0;
  }
  if (Math.abs(vec.z) < Epsilon) {
    vec.z = 0;
  }
}
