import {Dataset} from "../../model/dataset/dataset.model";
import {DATASET_LOADED_SUCCESSFULLY, DatasetActions} from "../actions";

export function datasetReducer(state: Dataset|null = null, action: DatasetActions) :Dataset|null {
  switch (action.type){
    case DATASET_LOADED_SUCCESSFULLY:
      return action.payload as Dataset|null;
  }
  return state;
}
