import {Injectable} from '@angular/core';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {UserPattern} from '../model/user-pattern.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {AddPatternParams} from '../model/client-server.Params/add-pattern-params';

@Injectable()
export class UserPatternService {
  constructor(private http: HttpClient) {

  }

  public getUserPatterns(configId: string): Observable<UserPattern[]> {
    return this.http.get<UserPattern[]>(environment.api_url + '/api/userPatterns/getPatternsByConfigId/' + configId).pipe(
      map(v => v),
      catchError(error => observableThrowError(error))
    );
  }

  public addUserPattern(parameters: AddPatternParams): Observable<UserPattern> {
    return this.http.post<UserPattern>(environment.api_url + '/api/userPatterns/addPattern', parameters).pipe(
      map(v => v),
      catchError(error => observableThrowError(error))
    );
  }

  public deleteUserPattern(id: number): Observable<boolean> {
    return this.http.post<boolean>(environment.api_url + '/api/userPatterns/deletePattern', id).pipe(
      map(v => v),
      catchError(error => observableThrowError(error))
    );
  }

}
