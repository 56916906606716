
<div class="viewer-layout__wrapper">
  <div class="viewer-layout__positions">
    <div class="viewer-layout__position" *ngFor="let position of (elements | async)">
      <app-viewer-carousel-item [element]="position">
      </app-viewer-carousel-item>
    </div>
  </div>
  <div class="viewer-layout__viewer">
    <webcad-host [material]="PlateMaterialType.real"></webcad-host>
  </div>
</div>