import { ModelVisualizer, ViewModelVisualizer } from "webcad";
import { MevacoPage } from "../model/mevaco-page.model";
import { CustomPatternDrawingProvider } from "../providers/custom-pattern-drawing.provider";
import { PlateMeshProvider } from "../providers/plate-mesh.provider";
import { DrawingVisualizer } from "./drawing.visualizer";
import { PerforationVisualizer } from "./perforation.visualizer";
import { BendingTreeProvider } from "./viewmodel-provifers/bending-tree.provider";
import { PerforationViewModelProvider } from "./viewmodel-provifers/perforation-viewModelProvider";
import {ExpandedMetalVisualizer} from "./expanded-metal.vizualizer";
import {ExpandedMetalViewModelProvider} from "./viewmodel-provifers/expanded-metal-viewModelProvider";

export class MevacoPageVisualizer extends ViewModelVisualizer<MevacoPage> {
  constructor(plateMeshProvider: PlateMeshProvider) {
    const customPatternDrawingProvider = new CustomPatternDrawingProvider();
    const bendingTreeProvider = new BendingTreeProvider();
    const perforationViewModelProvider = new PerforationViewModelProvider(
      bendingTreeProvider
    );
    const expMetViewModelProvider = new ExpandedMetalViewModelProvider();

    super(
      new Map<ModelVisualizer<any>[], (model: MevacoPage) => Object>([
        [
          [new DrawingVisualizer(plateMeshProvider, bendingTreeProvider)],
          (model: MevacoPage) =>
            !model.customPatternEditorOpened
              ? model.drawing
              : customPatternDrawingProvider.getDrawing(model),
        ],
        [
          [new PerforationVisualizer()],
          perforationViewModelProvider.getViewModel.bind(
            perforationViewModelProvider
          ),
        ],
        [
          [new ExpandedMetalVisualizer()],
          expMetViewModelProvider.getViewModel.bind( expMetViewModelProvider ),
        ],
      ])
    );
  }
}
