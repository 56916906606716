import {ClosestSegments} from '../providers/mevaco-pointer.provider';
import {PointerState} from 'webcad/collision';

export abstract class Tool {
  protected _dirty: boolean = false;

  abstract onMouseMove(pointerState: PointerState);

  abstract onMouseClick(pointerState: PointerState);

  abstract onMouseDown(pointerState: PointerState);

  abstract onMouseUp(pointerState: PointerState);

  abstract onClosestSegmentsChanged(closestSegments: ClosestSegments);

  abstract reset();

  abstract activate();

  abstract onCancel();

  abstract onConfirm();

  abstract isDirty(): boolean;
}


