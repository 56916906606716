import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {MevacoState} from '../../store/reducers';
import {SetBackgroundColor} from '../../store/actions';
import {hexToRgb} from '../../utils/utils';
import { RequestRender } from '../../store/actions/drawing.actions';
import {TranslationProvider} from "../../providers/translation.provider";

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.css']
})
export class ColorPickerComponent implements OnInit {
  public white = '#ffffff';
  public grey = "#a1a1a1";
  switch: boolean = true;

  constructor(private store: Store<MevacoState>,private translationProvider: TranslationProvider) {
  }

  ngOnInit() {
  }

  setColor(content: any) {
    const newColor = hexToRgb(content.target.value);
    this.store.dispatch(new SetBackgroundColor({x: newColor.r / 255, y: newColor.g / 255, z: newColor.b / 255}));
  }

  toggleColor(){
    const newColor = hexToRgb((this.switch? this.grey:this.white));
    this.store.dispatch(new SetBackgroundColor({x: newColor.r / 255, y: newColor.g / 255, z: newColor.b / 255}));
    this.store.dispatch(new RequestRender());
    this.switch = !this.switch;
  }
  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }
}
