import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { MevacoState } from "../store";

@Directive({
  selector: "[rangeSlider]",
})
export class RangeSlideDirective implements OnInit {
  @Input("min") min: any = 0.1;
  @Input("max") max: any = 10;
  @Input("minValue") minValue: Observable<number>;
  @Input("maxValue") maxValue: Observable<number>;
  @Input("value") value: number = 0;
  private range: any;
  private _disabled: boolean;
  @Input("disabled") set disabled(v: boolean) {
    if (this.range) {
      if (v) {
        this.renderer.setAttribute(this.range, "disabled", v.toString());
      } else {
        this.renderer.removeAttribute(this.range, "disabled");
      }
    }
    this._disabled = v;
  }
  @Output() outputValue = new EventEmitter<number>();

  public localMinVal = 0.5;
  public localMaxVal = 2;

  position: number = 0;
  scale: any = 0;
  data: any;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private control: NgControl,
    private store: Store<MevacoState>
  ) {}

  ngOnInit() {
    this.data = this.getValues(this.localMinVal, this.localMaxVal);
    combineLatest([this.minValue, this.maxValue]).pipe(
      map((x, y) => {
        this.data = this.getValues(x, y);
      })
    );
    this.minValue.subscribe((x) => {
      this.localMinVal = x;
      this.data = this.getValues(this.localMinVal, this.localMaxVal);
    });
    this.maxValue.subscribe((x) => {
      this.localMaxVal = x;
      this.data = this.getValues(this.localMinVal, this.localMaxVal);
    });

    this.range = this.renderer.createElement("input");
    this.renderer.setAttribute(this.range, "type", "range");
    this.renderer.setAttribute(this.range, "min", this.data.min.toString());
    this.renderer.setAttribute(this.range, "max", this.data.max.toString());
    this.renderer.addClass(this.range, "slider");
    this.renderer.addClass(this.range, "configurator-slider");
    this.renderer.setAttribute(
      this.range,
      "value",
      this.data.position.toString()
    );

    this.store
      .pipe(
        select(
          (store) => store.model.productConfiguration.wallpaper.zoomEnabled
        )
      )
      .subscribe((x) => {
        this.position =
          this.data.min + (Math.log(x) - this.data.minValue) / this.data.scale;
        this.range.value = this.position;
        this.outputValue.emit(
          +Math.exp(
            (this.position - this.data.min) * this.data.scale +
              this.data.minValue
          ).toFixed(1)
        );
      });

    this.elementRef.nativeElement.style = "display:none";
    this.renderer.appendChild(
      this.renderer.parentNode(this.elementRef.nativeElement),
      this.range
    );

    this.renderer.listen(this.range, "input", (event) => {
      let position = +event.target.value;
      this.setPositionValue(position, this.data);
    });

    this.control.control.valueChanges.subscribe((val) => {
      this.data["value"] = val;
      this.value = val;
      this.position = this.data["position"] = +this.getPosition(this.data);
      this.range.value = this.position;
      this.outputValue.emit(val);
    });

    this.disabled = this._disabled;
  }

  setPositionValue(position, data) {
    this.value = +this.getValue(position, data).toFixed(1);
    this.control.control.setValue(this.value);
  }

  getValues(min, max) {
    this.value = this.control.control.value
      ? this.control.control.value
      : this.value;
    let data = {
      min: +this.min,
      max: +this.max,
      scale: +this.scale,
      value: +this.value,
      position: this.position,
      minValue: Math.log(+min),
      maxValue: Math.log(+max),
    };
    data["scale"] = (data.maxValue - data.minValue) / (data.max - data.min);
    if (data.value) {
      data["position"] = this.getPosition(data);
    }
    return data;
  }

  // Calculate slider value from a position
  getValue(position, data) {
    return Math.exp((position - data.min) * data.scale + data.minValue);
  }

  // Calculate slider position from a value
  getPosition(data) {
    return data.min + (Math.log(data.value) - data.minValue) / data.scale;
  }
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
