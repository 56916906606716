import {MeasurementModel} from 'webcad/models';
import {Drawing} from '../drawing.model';
import {MeasurementMask} from '../measurement-mask.enum';
import {aabbOfPolyline} from 'webcad/models';
import {aabb2Surface} from 'webcad/models';

export interface MeasurementsViewModel {
  measurements: Map<number, MeasurementModel>;
  automaticMeasurements: Map<number, MeasurementModel>;
  visible: boolean;
  measurementsMask: number;
}

export const MeasurementsViewModelPipeLine = (state: Drawing): MeasurementsViewModel => {

  let isBendedCassette: boolean = !!(state.snapOptions && !state.snapOptions.edges && state.plate.bendingLines && state.plate.bendingLines.length);

  let measurementsMask = MeasurementMask.Plate | MeasurementMask.Margings;
  if (state.flat) {
    isBendedCassette = false;
  }

  if (isBendedCassette) {
    measurementsMask = MeasurementMask.Cassette;
    const originalSurface = aabb2Surface( aabbOfPolyline(state.plate.boundary));
    const actualSurface = aabb2Surface( state.plate.shapeWithHoles.aabb );
    if(actualSurface + 0.0001 > originalSurface) {
      measurementsMask = MeasurementMask.Cassette | MeasurementMask.SecondLeg;
    }
  }


  return {
    measurements: state.measurements,
    automaticMeasurements: state.automaticMeasurements,
    visible: state.snapOptions.measurements,
    measurementsMask : measurementsMask
  };
};
