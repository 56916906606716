import {HtmlLabelModel} from 'webcad/models/html-label.model';

export const INITIAL_LABEL_MODEL: HtmlLabelModel = {
  placement: {
    position: {x: 0, y: 0},
    angle: 0
  },
  value: '',
  offset: {x: 25, y: -25},
  visible: true
};

export enum HintStatus {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
  Sticky = 'Sticky'
}

export interface HintModel {
  label: HtmlLabelModel;
  status: HintStatus;
  visible: boolean;
}

export const INITIAL_HINT_MODEL: HintModel = {
  label: INITIAL_LABEL_MODEL,
  status: HintStatus.Enabled,
  visible: true
};
