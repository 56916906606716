import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { Guid } from "guid-typescript";
import { Observable, of } from "rxjs";
import { map, switchMap, withLatestFrom } from "rxjs/operators";
import { Element } from "../../model/product-configuration/element.model";
import { ProductConfiguration } from "../../model/product-configuration/product-configuration.model";
import { PreviewImageService } from "../../services/preview-image.service";
import { ProductConfigurationService } from "../../services/product-configuration.service";
import { cloneObject } from "../../utils/utils";
import {
  COPY_POSITION,
  CopyPosition,
  CopyPositionSuccess,
  DUPLICATE_CONFIGURATION,
  DUPLICATE_CONFIGURATION_SUCCESS,
  DuplicateConfiguration,
  DuplicateConfigurationSuccess,
  PRODUCT_CONFIGURATION_LOAD,
  //  PRODUCT_CONFIGURATION_SAVE,
  PRODUCT_CONFIGURATION_SET_COLOR,
  PRODUCT_CONFIGURATION_SET_SURFACE_TYPE,
  ProductConfigurationLoad,
  ProductConfigurationLoadedSuccessfully,
  //  ProductConfigurationSave,
  REDO_STATE,
  SUBMIT_IMPORT,
  UNDO_STATE,
  UndoRedoSucess,
} from "../actions";
import { SetError } from "../actions/errorable.actions";
import { SetHexColorString } from "../actions/plate.actions";
import { ValidateConfig } from "../actions/save.actions";
import { MevacoState } from "../reducers";

@Injectable()
export class ProductConfigurationEffects {
  constructor(
    private actions: Actions,
    private productConfigurationService: ProductConfigurationService,
    private previewImageSerivce: PreviewImageService,
    private store: Store<MevacoState>
  ) {}

  load: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(PRODUCT_CONFIGURATION_LOAD),
      switchMap((action: ProductConfigurationLoad) => {
        return this.productConfigurationService
          .load(action.payload)
          .pipe(
            map(
              (configuration) =>
                new ProductConfigurationLoadedSuccessfully(configuration)
            )
          );
      })
    )
  );

  /*
  @Effect()
  save: Observable<Action> = this.actions.pipe(
    ofType(PRODUCT_CONFIGURATION_SAVE),
    debounceTime(500),
    switchMap((action: ProductConfigurationSave) => {
      return this.productConfigurationService.save(action.payload)
        .pipe(
          map(actions => new ProductConfigurationSavedSuccessfully(actions))
        );
    })
  );
*/
  undoRedo: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UNDO_STATE, REDO_STATE),
      withLatestFrom(this.store),
      switchMap(([action, state]) => {
        const configId = state.model.productConfiguration.configId;
        switch ((<any>action).type) {
          case UNDO_STATE:
            return this.productConfigurationService.undo(configId).pipe(
              map((productConfig) => {
                if (productConfig !== null) {
                  return new UndoRedoSucess(productConfig);
                }
              })
            );
          case REDO_STATE:
            return this.productConfigurationService.redo(configId).pipe(
              map((productConfig) => {
                if (productConfig !== null) {
                  return new UndoRedoSucess(productConfig);
                }
              })
            );
        }
      })
    )
  );

  setColor = createEffect(() =>
    this.actions.pipe(
      ofType(
        PRODUCT_CONFIGURATION_SET_COLOR,
        PRODUCT_CONFIGURATION_SET_SURFACE_TYPE
      ),
      withLatestFrom(this.store),
      switchMap(([action, state]) => {
        let paintHex = "";
        const system = state.model.productConfiguration.surface.colorSystem;
        if (!!system && system.length > 0) {
          const colorId = Number(
            state.model.productConfiguration.surface.color
          );
          if (!!colorId && !isNaN(colorId)) {
            paintHex = state.model.dataset.paints.find(
              (paint) => paint.system === system && paint.paintId === colorId
            ).hexColorCode;
          }
        }
        if (!paintHex && paintHex !== "") {
          paintHex = "";
          return [
            new SetError("No Color Available"),
            new SetHexColorString(paintHex),
          ];
        }
        return [new SetHexColorString(paintHex)];
      })
    )
  );

  duplicateConfiguration: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(DUPLICATE_CONFIGURATION),
      withLatestFrom(this.store),
      switchMap(([action, state]) => {
        const destination = Number(
          state.model.productConfiguration.configId.substr(1)
        );
        const source = (<DuplicateConfiguration>action).payload;
        return this.productConfigurationService
          .duplicateConfiguration({
            destinationId: destination,
            sourceId: source,
          })
          .pipe(
            map((response) => {
              if (response.error) {
                return new SetError(response.error);
              } else {
                return new DuplicateConfigurationSuccess(
                  JSON.parse(response.data) as ProductConfiguration
                );
              }
            })
          );
      })
    )
  );

  validateAfterAction: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(DUPLICATE_CONFIGURATION_SUCCESS, SUBMIT_IMPORT),
      switchMap((action) => {
        return of(new ValidateConfig());
      })
    )
  );

  copyPosition: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(COPY_POSITION),
      withLatestFrom(this.store),
      switchMap(([action, state]) => {
        const elementToCopy = state.model.productConfiguration.elements.find(
          (x) => x.position === (<CopyPosition>action).payload
        );
        if (elementToCopy) {
          const elementCopy: Element = cloneObject(elementToCopy);
          elementCopy.position += 1;
          elementCopy.previewImageId = Guid.create().toString();
          const lowerElements =
            state.model.productConfiguration.elements.filter(
              (v) => v.position < elementCopy.position
            );
          const higherElements =
            state.model.productConfiguration.elements.filter(
              (v) => v.position >= elementCopy.position
            );
          higherElements.forEach((v) => (v.position += 1));
          const newElements = [
            ...lowerElements,
            elementCopy,
            ...higherElements,
          ];
          return this.previewImageSerivce
            .copyImage({
              destinationGuid: elementCopy.previewImageId,
              sourceGuid: elementToCopy.previewImageId,
            })
            .pipe(
              map((response) => {
                if (response.data) {
                  return new CopyPositionSuccess(newElements);
                } else {
                  return new SetError("CannotCopyElement");
                }
              })
            );
        } else {
          return of(new SetError("CannotCopyElement"));
        }
      })
    )
  );
}
