import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { from, fromEvent } from "rxjs";
import { map, take, withLatestFrom } from "rxjs/operators";
import {
  Bending,
  getBendParmas,
} from "../../../../../../src/app/model/dataset/bending.model";
import { MeasurementMask } from "../../../../../../src/app/model/measurement-mask.enum";
import { PlateService } from "../../../../../../src/app/services/plate.service";
import { SetError } from "../../../../../../src/app/store/actions/errorable.actions";
import {
  MevacoState,
  ProductConfigurationAddElement,
  getBendings,
  getSelectedMaterialCode,
  getSelectedMaterialThickness,
} from "../../../../../app/store";
import { DebounceInputComponent } from "../../../debounce-input/debounce-input.component";
import { TemplateShapeBuilder, TemplateUtils } from "../../templatesUtils";
import { BendsTogglesComponent } from "../bends-toggles/bends-toggles.component";

@Component({
  selector: "two-bends-casset",
  templateUrl: "./two-bends.component.html",
  styleUrls: [
    "../../../element-creator/element-creator.component.css",
    "./two-bends.component.css",
  ],
})
export class TwoBendsComponent implements OnInit {
  @Output() isModalOpen = new EventEmitter();
  @Output() dismissModal = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @ViewChild("simple_t", { static: true }) simple_t: DebounceInputComponent;
  @ViewChild("simple_h", { static: true }) simple_h: DebounceInputComponent;
  @ViewChild("simple_b", { static: true }) simple_b: DebounceInputComponent;
  @ViewChild("simple_l", { static: true }) simple_l: DebounceInputComponent;
  @ViewChild("closeButton", { static: true }) closeButton: ElementRef;
  @ViewChild("bendsToggles", { static: true })
  bendsToggles: BendsTogglesComponent;
  private disposeTabs: () => void;

  isBendingSwitched = true;
  constructor(
    private store: Store<MevacoState>,
    private plateService: PlateService,
    public templateUtils: TemplateUtils
  ) {}

  ngOnInit() {
    this.createFromTwoBendsCassete();
  }

  isModalOpenMethod() {
    this.isModalOpen.emit(false);
  }

  dismiss(str: string) {
    this.dismissModal.emit(str);
  }
  close(input: {}) {
    this.closeModal.emit(input);
  }

  ngAfterViewInit() {
    this.disposeTabs = this.templateUtils.setupFocusTabs([
      this.simple_b,
      this.simple_l,
      this.simple_h,
      this.simple_t,
    ]);
    this.simple_l.valueChanged.subscribe((v) => {
      if (+this.simple_l.value < 300 && +this.simple_b.value < 300) {
        this.simple_b.valueControl.setValue(300);
      }
    });

    this.simple_b.valueChanged.subscribe((v) => {
      if (+this.simple_l.value < 300 && +this.simple_b.value < 300) {
        this.simple_l.valueControl.setValue(300);
      }
    });
  }

  ngOnDestroy() {
    this.disposeTabs();
  }
  switchBending(side: boolean) {
    this.isBendingSwitched = side;
  }

  createFromTwoBendsCassete() {
    const obs = fromEvent(this.closeButton.nativeElement, "click").pipe(
      map(() => {
        return {
          h: this.simple_h.value,
          b: this.simple_b.value,
          l: this.simple_l.value,
          t: this.simple_t.value,
        };
      })
    );

    from(obs)
      .pipe(
        withLatestFrom(
          this.store.pipe(select(getBendings)),
          this.store.pipe(select(getSelectedMaterialThickness)),
          this.store.pipe(select(getSelectedMaterialCode))
        ),
        take(1)
      )
      .subscribe(
        async ([result, bendings, thickness, code]: [
          any,
          Bending[],
          string,
          string
        ]) => {
          const bendAngle = this.isBendingSwitched ? Math.PI / 2 : -Math.PI / 2;
          let bendParams = null;
          try {
            bendParams = getBendParmas(code, +thickness, bendAngle, bendings);
          } catch (e) {
            this.store.dispatch(
              new SetError(this.templateUtils.translate("Bending not found"))
            );
            return;
          }

          const bendReduction = bendParams.bendAllowance / 2 - bendParams.ossb;
          const h = result.h / 1000 + 2 * bendReduction;
          const t = result.t / 1000 + bendReduction;
          const b =
            result.b / 1000 +
            (this.bendsToggles.right ? bendReduction : 0) +
            (this.bendsToggles.left ? bendReduction : 0);
          const a =
            result.l / 1000 +
            (this.bendsToggles.front ? bendReduction : 0) +
            (this.bendsToggles.back ? bendReduction : 0);

          const templateShapeBuilder: TemplateShapeBuilder =
            new TemplateShapeBuilder(this.plateService, { x: 0, y: 0 });

          if (this.bendsToggles.front) {
            templateShapeBuilder.bendingBy(
              { x: b, y: 0 },
              bendParams,
              bendAngle,
              "1"
            );
            templateShapeBuilder.moveBy({ x: 0, y: -h });
            if (!this.bendsToggles.semiFront) {
              templateShapeBuilder.moveBy({ x: b, y: 0 });
              templateShapeBuilder.measurementBy(
                { x: 0, y: result.h / 1000 },
                0.1 + h,
                MeasurementMask.Plate
              );
              templateShapeBuilder.moveBy({ x: 0, y: h });
            } else {
              templateShapeBuilder.bendingBy(
                { x: b, y: 0 },
                bendParams,
                -bendAngle,
                "1.1"
              );
              templateShapeBuilder.moveBy({ x: 0, y: -t });
              templateShapeBuilder.moveBy({ x: t, y: 0 });
              templateShapeBuilder.moveBy({ x: b - 2 * t, y: 0 });
              templateShapeBuilder.measurementBy(
                { x: 0, y: result.t / 1000 },
                0.1 + h + t,
                MeasurementMask.Plate
              );
              templateShapeBuilder.moveBy({ x: t, y: 0 });
              templateShapeBuilder.moveBy({ x: 0, y: t });
              templateShapeBuilder.measurementByWithOffset(
                { x: 0, y: bendReduction },
                { x: 0, y: result.h / 1000 },
                0.1 + h + t,
                MeasurementMask.Plate
              );
              templateShapeBuilder.moveBy({ x: 0, y: h });
            }
          } else {
            templateShapeBuilder.moveBy({ x: b, y: 0 });
          }
          templateShapeBuilder.measurementByWithOffset(
            { x: 0, y: this.bendsToggles.front ? bendReduction : 0 },
            { x: 0, y: result.l / 1000 },
            0.1 + h + t,
            MeasurementMask.Plate
          );

          if (this.bendsToggles.right) {
            templateShapeBuilder.bendingBy(
              { x: 0, y: a },
              bendParams,
              bendAngle,
              "2"
            );
            templateShapeBuilder.moveBy({ x: h, y: 0 });
            if (!this.bendsToggles.semiRight) {
              templateShapeBuilder.moveBy({ x: 0, y: a });
              if (!this.bendsToggles.front && !this.bendsToggles.left) {
                templateShapeBuilder.measurementBy(
                  { x: -result.h / 1000, y: 0 },
                  0.1 + h,
                  MeasurementMask.Plate
                );
              }
              templateShapeBuilder.moveBy({ x: -h, y: 0 });
            } else {
              templateShapeBuilder.bendingBy(
                { x: 0, y: a },
                bendParams,
                -bendAngle,
                "2.1"
              );
              templateShapeBuilder.moveBy({ x: t, y: 0 });
              templateShapeBuilder.moveBy({ x: 0, y: t });

              templateShapeBuilder.moveBy({ x: 0, y: a - 2 * t });

              if (!this.bendsToggles.front && !this.bendsToggles.left) {
                templateShapeBuilder.measurementBy(
                  { x: -result.t / 1000, y: 0 },
                  0.1 + h + t,
                  MeasurementMask.Plate
                );
              }
              templateShapeBuilder.moveBy({ x: 0, y: t });
              templateShapeBuilder.moveBy({ x: -t, y: 0 });

              if (!this.bendsToggles.front && !this.bendsToggles.left) {
                templateShapeBuilder.measurementByWithOffset(
                  { x: -bendReduction, y: 0 },
                  {
                    x: -result.h / 1000,
                    y: 0,
                  },
                  0.1 + h + t,
                  MeasurementMask.Plate
                );
              }
              templateShapeBuilder.moveBy({ x: -h, y: 0 });
            }
          } else {
            templateShapeBuilder.moveBy({ x: 0, y: a });
          }

          templateShapeBuilder.measurementByWithOffset(
            { x: this.bendsToggles.right ? -bendReduction : 0, y: 0 },
            { x: -result.b / 1000, y: 0 },
            0.1 + h + t,
            MeasurementMask.Plate
          );
          if (this.bendsToggles.back) {
            templateShapeBuilder.bendingBy(
              { x: -b, y: 0 },
              bendParams,
              bendAngle,
              "3"
            );
            templateShapeBuilder.moveBy({ x: 0, y: h });
            if (!this.bendsToggles.semiBack) {
              templateShapeBuilder.moveBy({ x: -b, y: 0 });
              if (
                !this.bendsToggles.front &&
                !this.bendsToggles.left &&
                !this.bendsToggles.right
              ) {
                templateShapeBuilder.measurementBy(
                  { x: 0, y: -result.h / 1000 },
                  0.1 + h,
                  MeasurementMask.Plate
                );
              }
              templateShapeBuilder.moveBy({ x: 0, y: -h });
            } else {
              templateShapeBuilder.bendingBy(
                { x: -b, y: 0 },
                bendParams,
                -bendAngle,
                "3.1"
              );
              templateShapeBuilder.moveBy({ x: 0, y: t });
              templateShapeBuilder.moveBy({ x: -t, y: 0 });
              templateShapeBuilder.moveBy({ x: -(b - 2 * t), y: 0 });

              if (
                !this.bendsToggles.front &&
                !this.bendsToggles.left &&
                !this.bendsToggles.right
              ) {
                templateShapeBuilder.measurementBy(
                  { x: 0, y: -result.t / 1000 },
                  0.1 + h + t,
                  MeasurementMask.Plate
                );
              }
              templateShapeBuilder.moveBy({ x: -t, y: 0 });
              templateShapeBuilder.moveBy({ x: 0, y: -t });
              if (
                !this.bendsToggles.front &&
                !this.bendsToggles.left &&
                !this.bendsToggles.right
              ) {
                templateShapeBuilder.measurementByWithOffset(
                  { x: 0, y: -bendReduction },
                  {
                    x: 0,
                    y: -result.h / 1000,
                  },
                  0.1 + h + t,
                  MeasurementMask.Plate
                );
              }
              templateShapeBuilder.moveBy({ x: 0, y: -h });
            }
          } else {
            templateShapeBuilder.moveBy({ x: -b, y: 0 });
          }

          if (this.bendsToggles.left) {
            templateShapeBuilder.bendingBy(
              { x: 0, y: -a },
              bendParams,
              bendAngle,
              "4"
            );
            templateShapeBuilder.moveBy({ x: -h, y: 0 });
            if (!this.bendsToggles.semiLeft) {
              templateShapeBuilder.moveBy({ x: 0, y: -a });
              if (!this.bendsToggles.front) {
                templateShapeBuilder.measurementBy(
                  { x: result.h / 1000, y: 0 },
                  0.1,
                  MeasurementMask.Plate
                );
              }
            } else {
              templateShapeBuilder.bendingBy(
                { x: 0, y: -a },
                bendParams,
                -bendAngle,
                "4.1"
              );
              templateShapeBuilder.moveBy({ x: -t, y: 0 });
              templateShapeBuilder.moveBy({ x: 0, y: -t });
              templateShapeBuilder.moveBy({ x: 0, y: -(a - 2 * t) });
              if (!this.bendsToggles.front) {
                templateShapeBuilder.measurementBy(
                  { x: result.t / 1000, y: 0 },
                  0.1 + t,
                  MeasurementMask.Plate
                );
              }
              templateShapeBuilder.moveBy({ x: 0, y: -t });
              templateShapeBuilder.moveBy({ x: t, y: 0 });
              if (!this.bendsToggles.front) {
                templateShapeBuilder.measurementByWithOffset(
                  { x: bendReduction, y: 0 },
                  { x: result.h / 1000, y: 0 },
                  0.1 + t,
                  MeasurementMask.Plate
                );
              }
            }
          }
          templateShapeBuilder.addCassetteMeasurements(
            {
              x: this.bendsToggles.left ? bendReduction : 0,
              y: this.bendsToggles.front ? bendReduction : 0,
              z: 0,
            },
            {
              x: result.b / 1000,
              y: result.l / 1000,
              z: (this.isBendingSwitched ? result.h : -result.h) / 1000,
            }
          );

          if (this.bendsToggles.left && this.bendsToggles.semiLeft) {
            templateShapeBuilder.addSecondLegMeasurement(
              {
                x: -(result.t / 1000),
                y: result.l / 1000,
                z: (this.isBendingSwitched ? result.h : -result.h) / 1000,
              },
              {
                x: 0,
                y: 1,
                z: 0,
              },
              {
                x: 1,
                y: 0,
                z: 0,
              },
              result.t / 1000
            );
          } else if (this.bendsToggles.right && this.bendsToggles.semiRight) {
            templateShapeBuilder.addSecondLegMeasurement(
              {
                x: result.b / 1000 - +thickness / 1000,
                y: result.l / 1000,
                z: (this.isBendingSwitched ? result.h : -result.h) / 1000,
              },
              {
                x: 0,
                y: 1,
                z: 0,
              },
              {
                x: 1,
                y: 0,
                z: 0,
              },
              result.t / 1000
            );
          } else if (this.bendsToggles.back && this.bendsToggles.semiBack) {
            templateShapeBuilder.addSecondLegMeasurement(
              {
                x: result.b / 1000,
                y: result.l / 1000 - +thickness / 1000,
                z: (this.isBendingSwitched ? result.h : -result.h) / 1000,
              },
              {
                x: 1,
                y: 0,
                z: 0,
              },
              {
                x: 0,
                y: 1,
                z: 0,
              },
              result.t / 1000
            );
          } else {
            templateShapeBuilder.addSecondLegMeasurement(
              {
                x: result.b / 1000,
                y: -(result.t / 1000),
                z: (this.isBendingSwitched ? result.h : -result.h) / 1000,
              },
              {
                x: 1,
                y: 0,
                z: 0,
              },
              {
                x: 0,
                y: 1,
                z: 0,
              },
              result.t / 1000
            );
          }

          const currentElement = await templateShapeBuilder.buildTemplate();
          currentElement.form = "WZ";
          this.store.dispatch(
            new ProductConfigurationAddElement(currentElement)
          );
        }
      );
  }
}
