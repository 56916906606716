import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store, select } from "@ngrx/store";
import { Observable, combineLatest, of } from "rxjs";
import { ProductConfigurationService } from "../../services/product-configuration.service";
import { MevacoState, getAppMode, getProductConfiguration } from "../reducers";

import {
  debounceTime,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import { AppMode } from "../../model/app-mode.enum";
import { DatasetService } from "../../services/dataset.service";
import {
  PRODUCT_CONFIGURATION_LOADED_SUCCESSFULLY,
  ProductConfigurationSaveSkipped,
  ProductConfigurationSavedSuccessfully,
  ProductConfigurationValidatedSuccessfully,
} from "../actions";
import { ErrorableTypes, SetError } from "../actions/errorable.actions";

@Injectable()
export class SaveEffects {
  constructor(
    private actions: Actions,
    private productConfigurationService: ProductConfigurationService,
    private datasetService: DatasetService,
    private store: Store<MevacoState>
  ) {}

  save: Observable<Action> = createEffect(() => combineLatest([
    this.store.pipe(select(getProductConfiguration)),
    this.store.pipe(select(getAppMode)),
  ]).pipe(
    debounceTime(500),
    filter(([productConfig, mode ]) => mode === AppMode.editor),
    switchMap(([productConfig, mode ]) => {
      return this.productConfigurationService.save(productConfig)
        .pipe(
          map(result => result ? new ProductConfigurationSavedSuccessfully(
            result,
            this.productConfigurationService.updateHash.bind(this.productConfigurationService)
          ) : new ProductConfigurationSaveSkipped())
        );
    }) ));

  validate: Observable<Action> =  createEffect(() => this.actions.pipe(
    ofType(PRODUCT_CONFIGURATION_LOADED_SUCCESSFULLY),
    withLatestFrom(this.store),
    switchMap(([action, store]) => {
      const productConfig = store.model.productConfiguration;
      return this.productConfigurationService.validate(productConfig)
        .pipe(
          map(result => new ProductConfigurationValidatedSuccessfully(
            result,
            this.productConfigurationService.updateHash.bind(this.productConfigurationService)
          ))
        );
    })
  ));

  mevacoResponse: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(...ErrorableTypes),
      switchMap((action) => {
        return of(new SetError((<any>action).payload.error));
      })
    )
  );
}
