import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Guid } from "guid-typescript";
import { Observable, Subscription, fromEvent } from "rxjs";
import { map } from "rxjs/operators";
import {
  LineIntersect2D,
  addVectors2,
  getLeftPerpendicularVector2,
  getRightPerpendicularVector2,
  multiplyVector2byScalar,
  normalizeVector2,
  subVectors2,
} from "webcad/math";
import {
  PolylineArea,
  SegmentType,
  aabbOfPolyline,
  expandAabb,
  getEmptyAaabb,
} from "webcad/models";
import { Staircase } from "../../../../../../src/app/model/basic-templates.model";
import { PerforationAreaModel } from "../../../../../../src/app/model/perforation-area.model";
import {
  Element,
  ElementType,
} from "../../../../../../src/app/model/product-configuration/element.model";
import {
  ShapeWithHoles,
  shapeWithHolesHash,
} from "../../../../../../src/app/model/shape-with-holes";
import { UserTemplate } from "../../../../../../src/app/model/user-template.model";
import {
  MevacoState,
  ProductConfigurationAddElement,
  getStaircaseTemplate,
  getUserTemplates,
} from "../../../../../../src/app/store";
import {
  SetStaircaseTemplate,
  SetStaircaseTilt,
} from "../../../../../../src/app/store/actions/element-creator.actions";
import { GetUserTemplates } from "../../../../../../src/app/store/actions/user-template.actions";
import { reqValStaircase } from "../../../../store/reducers";
import { TemplateName } from "../../template-name";
import { TemplateUtils } from "../../templatesUtils";

@Component({
  selector: "mevaco-staircase",
  templateUrl: "./staircase.component.html",
  styleUrls: [
    "../../../element-creator/element-creator.component.css",
    "./staircase.component.css",
  ],
})
export class StaircaseComponent implements OnInit {
  @Output() dismissModal = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() isModalOpen = new EventEmitter();

  @ViewChild("staircase_ha", { static: true }) staircase_ha: ElementRef;
  @ViewChild("staircase_a1", { static: true }) staircase_a1: ElementRef;
  @ViewChild("staircase_tilt", { static: true }) staircase_tilt: ElementRef;
  @ViewChild("staircase_b1", { static: true }) staircase_b1: ElementRef;
  @ViewChild("staircase_b2", { static: true }) staircase_b2: ElementRef;
  @ViewChild("staircase_e1", { static: true }) staircase_e1: ElementRef;
  @ViewChild("staircase_e2", { static: true }) staircase_e2: ElementRef;
  @ViewChild("staircase_f1", { static: true }) staircase_f1: ElementRef;
  @ViewChild("staircase_f2", { static: true }) staircase_f2: ElementRef;
  @ViewChild("closeButton", { static: true })
  closeButton: ElementRef;

  public staircaseTemplate: Staircase;
  public templates: Observable<UserTemplate[]>;
  public pi = Math.PI;
  public reqValStaircase: Observable<boolean>;
  public minf = Math.min;

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<MevacoState>,
    public templateUtils: TemplateUtils
  ) {}

  ngOnInit() {
    this.createFromStaircaseTemplate();
    this.store.dispatch(new GetUserTemplates());
    this.templates = this.store.pipe(select(getUserTemplates));
    this.subscriptions.push(
      this.store
        .pipe(select(getStaircaseTemplate))
        .subscribe((v) => (this.staircaseTemplate = { ...v }))
    );
    this.reqValStaircase = this.store.pipe(select(reqValStaircase));
  }

  isModalOpenMethod() {
    this.isModalOpen.emit(false);
  }

  dismiss(str: string) {
    this.dismissModal.emit(str);
  }

  close(input: {}) {
    this.closeModal.emit(input);
  }

  createFromStaircaseTemplate() {
    const obs = fromEvent(this.closeButton.nativeElement, "click").pipe(
      map(() => {
        return this.staircaseTemplate;
      })
    );

    obs.subscribe((result) => {
      const e1 = result.e1 / 1000;
      const e2 = result.e2 / 1000;
      const ha = result.ha / 1000;
      const a1 = result.a1 / 1000;
      const f1 = result.f1 / 1000;
      const f2 = result.f2 / 1000;
      const b1 = result.b1 / 1000;
      const b2 = result.b2 / 1000;
      const sinAlpha = Math.sin(result.tilt);
      const rb1 = (b1 - b2) / sinAlpha;
      const dir = normalizeVector2({
        x: Math.cos(result.tilt),
        y: Math.sin(result.tilt),
      });
      const leftDir = normalizeVector2(getLeftPerpendicularVector2(dir));
      let rightDown = { x: 0, y: 0 };
      let rightUp = addVectors2(rightDown, multiplyVector2byScalar(dir, a1));
      let top = addVectors2(rightUp, multiplyVector2byScalar(leftDir, b2));
      let leftUp = addVectors2(
        top,
        multiplyVector2byScalar({ x: -1, y: 0 }, rb1)
      );
      let leftDown = addVectors2(leftUp, multiplyVector2byScalar(dir, -ha));
      let bottom = LineIntersect2D(
        { x: 1, y: 0 },
        leftDown,
        leftDir,
        rightDown
      );
      if (bottom === null) {
        bottom = addVectors2(top, multiplyVector2byScalar(dir, -ha));
      }
      const shapeAabb = expandAabb(
        expandAabb(
          expandAabb(
            expandAabb(
              expandAabb(expandAabb(getEmptyAaabb(), leftUp), top),
              rightUp
            ),
            rightDown
          ),
          bottom
        ),
        leftDown
      );
      leftDown = subVectors2(leftDown, shapeAabb.min);
      leftUp = subVectors2(leftUp, shapeAabb.min);
      top = subVectors2(top, shapeAabb.min);
      rightUp = subVectors2(rightUp, shapeAabb.min);
      rightDown = subVectors2(rightDown, shapeAabb.min);
      bottom = subVectors2(bottom, shapeAabb.min);
      const conture = [
        {
          begin: leftDown,
          end: bottom,
          type: SegmentType.line,
        },
        {
          begin: bottom,
          end: rightDown,
          type: SegmentType.line,
        },
        {
          begin: rightDown,
          end: rightUp,
          type: SegmentType.line,
        },
        {
          begin: rightUp,
          end: top,
          type: SegmentType.line,
        },
        {
          begin: top,
          end: leftUp,
          type: SegmentType.line,
        },
        {
          begin: leftUp,
          end: leftDown,
          type: SegmentType.line,
        },
      ];
      const shapeWithHoles: ShapeWithHoles = {
        conture: conture,
        holes: [],
      };
      shapeWithHoles.aabb = aabbOfPolyline(shapeWithHoles.conture);
      shapeWithHoles.area = PolylineArea(shapeWithHoles.conture);
      shapeWithHoles.hash = shapeWithHolesHash(shapeWithHoles);
      const ldToBot = { x: 1, y: 0 };
      const ldToBotPerp = { x: 0, y: 1 };
      const rightPerp = normalizeVector2(getRightPerpendicularVector2(dir));
      const ld = LineIntersect2D(
        dir,
        addVectors2(leftDown, multiplyVector2byScalar(rightPerp, f1)),
        ldToBot,
        addVectors2(leftDown, multiplyVector2byScalar(ldToBotPerp, e1))
      );

      let bt = LineIntersect2D(
        ldToBot,
        addVectors2(leftDown, multiplyVector2byScalar(ldToBotPerp, e1)),
        leftDir,
        addVectors2(rightDown, multiplyVector2byScalar(dir, e1))
      );
      if (bt === null) {
        bt = LineIntersect2D(
          ldToBot,
          addVectors2(leftDown, multiplyVector2byScalar(ldToBotPerp, e1)),
          dir,
          addVectors2(bottom, multiplyVector2byScalar(leftDir, f2))
        );
      }

      const rd = LineIntersect2D(
        leftDir,
        addVectors2(rightDown, multiplyVector2byScalar(dir, e1)),
        dir,
        addVectors2(rightDown, multiplyVector2byScalar(leftDir, f2))
      );

      const ru = LineIntersect2D(
        dir,
        addVectors2(rightDown, multiplyVector2byScalar(leftDir, f2)),
        leftDir,
        addVectors2(rightUp, multiplyVector2byScalar(dir, -e2))
      );

      let tp = LineIntersect2D(
        leftDir,
        addVectors2(rightUp, multiplyVector2byScalar(dir, -e2)),
        ldToBot,
        addVectors2(leftUp, multiplyVector2byScalar({ x: 0, y: -1 }, e2))
      );
      if (tp === null) {
        tp = LineIntersect2D(
          ldToBot,
          addVectors2(leftUp, multiplyVector2byScalar({ x: 0, y: -1 }, e2)),
          dir,
          addVectors2(leftDown, multiplyVector2byScalar(rightPerp, f1))
        );
      }

      const lu = LineIntersect2D(
        ldToBot,
        addVectors2(leftUp, multiplyVector2byScalar({ x: 0, y: -1 }, e2)),
        dir,
        addVectors2(leftDown, multiplyVector2byScalar(rightPerp, f1))
      );
      const perfContour = [
        {
          begin: ld,
          end: bt,
          type: SegmentType.line,
        },
        {
          begin: bt,
          end: rd,
          type: SegmentType.line,
        },
        {
          begin: rd,
          end: ru,
          type: SegmentType.line,
        },
        {
          begin: ru,
          end: tp,
          type: SegmentType.line,
        },
        {
          begin: tp,
          end: lu,
          type: SegmentType.line,
        },
        {
          begin: lu,
          end: ld,
          type: SegmentType.line,
        },
      ];
      const perfAabb = aabbOfPolyline(perfContour);
      const perfArea = PolylineArea(perfContour);
      const perforationAreaShape: ShapeWithHoles = {
        conture: perfContour,
        holes: [],
        aabb: perfAabb,
        area: perfArea,
      };
      perforationAreaShape.hash = shapeWithHolesHash(perforationAreaShape);
      const perfAreaModel: PerforationAreaModel[] = [
        {
          shape: perforationAreaShape,
          offset: {
            x: 0,
            y: 0,
          },
          rotation: 0,
        },
      ];
      const a = shapeAabb.max.y - shapeAabb.min.y;
      const b = shapeAabb.max.x - shapeAabb.min.x;
      const element: Element = {
        type: ElementType.individual,
        templateName: TemplateName.staircase,
        boundary: null,
        position: -1, // will be set inb reducer
        quantity: 1,
        a: a,
        b: b,
        aIst: a,
        bIst: b,
        e1: e1.toString(),
        e1ist: e1.toString(),
        e2: e2.toString(),
        e2ist: e2.toString(),
        f1: f1.toString(),
        f1ist: f1.toString(),
        f2: f2.toString(),
        f2ist: f2.toString(),
        openMeshE: "",
        openMeshF: "",
        toleranceWidth: 0,
        toleranceLength: 0,
        label: "",
        note: "",
        unperforated: false,
        posibleCoil: "No",
        posiblePlate: "No",
        shapes: null,
        nodes: null,
        verticesIndexes: null,
        visualizationShape: null,
        previewImageId: Guid.create().toString(),
        shape: shapeWithHoles,
        perforationAreas: perfAreaModel,
        helpLines: [],
        measurements: [],
        angleMeasurements: [],
        mountings: [],
        perforationAutoCenter: true,
        area: shapeWithHoles.area,
        minRadius: null,
        minParallelEdgesDistance: null,
        minMarginDistance: null,
        isPerforationSimpleRect: null,
        isShapeSimpleRect: null,
        minMountingHoleEdgeDistance: null,
        minMountingHolesDistance: null,
        minOutsideAngle: null,
        numberOfArcs: null,
        minDistanceBetweenEdges: null,
        minDistanceBetweenMountingAndPerforatedArea: null,
        //perforation: null,
        possibleAllAcross: true,
        minDistanceBetweenPerforationAreas: null,
        lfbIst: undefined,
        lflIst: undefined,

        breakLines: [],
        bendingLinesDistances: [],
        //possiblePerforationZones: []
      };
      this.store.dispatch(new ProductConfigurationAddElement(element));
    });
  }

  setStaircaseTilt(value: any) {
    this.store.dispatch(new SetStaircaseTilt(value));
  }

  setStaircaseTemplate(template: Staircase) {
    this.store.dispatch(new SetStaircaseTemplate({ ...template }));
  }
}
