import {throwError as observableThrowError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {PreviewImageParams} from '../model/client-server.Params/PreviewImageParams';
import {MevacoResponse} from '../model/client-server.Params/mevaco-response.model';
import {PreviewImageCopyData} from '../model/client-server.Params/preview-image-copy-data.params';


@Injectable()
export class PreviewImageService {

  constructor(private http: HttpClient) {
  }

  getImage(id: number): Observable<any> {
    return this.http.get(environment.api_url + '/api/mevaco/getPreviewImage/' + id.toFixed(0)).pipe(
      map(v => v),
      catchError(error => observableThrowError(error))
    );
  }

  saveImage(params: PreviewImageParams): Observable<number> {
    return this.http.post(environment.api_url + '/api/mevaco/savePreviewImage/', params).pipe(
      map(v => v as number),
      catchError(error => observableThrowError(error))
    );
  }

  deleteImage(id: string): Observable<boolean> {
    return this.http.post<boolean>(environment.api_url + '/api/mevaco/deletePreviewImage/' + id, {}).pipe(
      map(v => v),
      catchError(error => observableThrowError(error))
    );
  }

  copyImage(params: PreviewImageCopyData): Observable<MevacoResponse<boolean>> {
    return this.http.post<MevacoResponse<boolean>>(environment.api_url + '/api/mevaco/CopyPreviewImageToGuid/', params).pipe(
      map(v => v),
      catchError(error => observableThrowError(error))
    );
  }
}
