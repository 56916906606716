import {Action} from '@ngrx/store';
import {Perforation} from '../../model/perforation.model';
import {PerforationAreaModel} from '../../model/perforation-area.model';
import {
  SET_MIN_MAX_REANDER,
  SET_PERFORATION_AUTO_CENTER,
  SHOW_VIEWER_ELEMENT,
  WALLPAPER_CHANGE
} from './product-configuration.actions';
import {SUBMIT_IMPORT} from './mevaco-page.actions';
import {MATERIAL_FOR_WALLPAPER_LOADED_SUCCESSFULLY} from "./dataset.actions";

export const SET_PERFORATION_SUCCESS = 'SET PERFORATION SUCCESS';
export const SET_PERFORATION = 'SET PERFORATION';
export const SET_PERFORATION_FAILURE = 'SET PERFORATION FAIL';

export const CLEAR_PERFORATION = 'CLEAR_PERFORATION';
export const SELECT_PERFORATION_AREA_BY_ID = 'SELECT_PERFORATION_AREA_BY_ID';
export const SELECT_PERFORATION_AREA = 'SELECT_PERFORATION_AREA';
export const SET_SELECTED_PERFORATION_AREA_OFFSET = 'SET_SELECTED_PERFORATION_AREA_OFFSET';

export class SetPerforationSuccess implements Action {
  readonly type: string = SET_PERFORATION_SUCCESS;

  constructor(public payload: {elementUuid:string, perforation: Perforation[]}) {
  }
}

export class SetPerforation implements Action {
  readonly type: string = SET_PERFORATION;

  constructor(public payload: any) {
  }
}

export class ClearPerforation implements Action {
  readonly type: string = CLEAR_PERFORATION;

  constructor(public payload: any = null) {
  }
}

export class SelectPerforationAreaById implements Action {
  readonly type: string = SELECT_PERFORATION_AREA_BY_ID;

  constructor(public payload: number) {
  }
}

export class SelectPerforationArea implements Action {
  readonly type: string = SELECT_PERFORATION_AREA;

  constructor(public payload: PerforationAreaModel) {
  }
}

export class SetSelectedPerforationAreaOffset implements Action {
  readonly type: string = SET_SELECTED_PERFORATION_AREA_OFFSET;

  constructor(public payload: {x?:number, y?:number}) {
  }
}

export type PerforationActions = SetPerforationSuccess | SetPerforation | ClearPerforation | SelectPerforationArea | SetPerforationSuccess;
