import {ProductConfiguration, ProductType} from './product-configuration/product-configuration.model';
import {MaterialData} from './dataset/material-data.model';
import {Tolerance} from './tolerance-model';

export interface ToleranceLookup {
  perforationTolerance: { [size: string]: Tolerance[] };
  extendedMetalE1Tolerance: { [size: string]: Tolerance[] };
  extendedMetalE2Tolerance: Tolerance[];
  extendedMetalE3Tolerance: Tolerance[];
  extendedMetalFTolerance: Tolerance[];
  extendedMetalGTolerance: Tolerance[];
  materialTolerance: { [size: string]: Tolerance[] };
}

export const initialToleranceLookup: ToleranceLookup = {
  extendedMetalE1Tolerance: {},
  extendedMetalE2Tolerance: [],
  extendedMetalE3Tolerance: [],
  extendedMetalFTolerance: [],
  extendedMetalGTolerance: [],
  materialTolerance: {},
  perforationTolerance: {}
};

export function initToleranceLookup(toleranceDataset: Tolerance[], materials: MaterialData[], toleranceLookup: ToleranceLookup): ToleranceLookup {

  toleranceLookup = {...toleranceLookup};

  toleranceLookup.perforationTolerance = {};

  toleranceLookup.extendedMetalE1Tolerance = {};

  toleranceLookup.extendedMetalE1Tolerance['2000 x 1000 mm'] = [];

  toleranceLookup.extendedMetalE1Tolerance['2500 x 1250 mm'] = [];

  toleranceLookup.extendedMetalE1Tolerance['3000 x 1500 mm'] = [];

  toleranceLookup.extendedMetalE2Tolerance = [];

  toleranceLookup.extendedMetalE3Tolerance = [];

  toleranceLookup.extendedMetalFTolerance = [];

  toleranceLookup.extendedMetalGTolerance = [];

  for (let i = 0; i < toleranceDataset.length; i++) {

    const tol = toleranceDataset[i];

    if (tol.product === 'A') {

      const mat = toleranceLookup.perforationTolerance[tol.materialGroup] = toleranceLookup.perforationTolerance[tol.materialGroup] || [];

      // put nulls at the end

      if (tol.thicknessFrom === null) {

        mat.push(tol);

      } else {

        mat.unshift(tol);

      }

    }

    if (tol.product === 'E') {

      switch (tol.productGroup) {

        case 'E11':

          toleranceLookup.extendedMetalE1Tolerance['2000 x 1000 mm'].push(tol);

          break;

        case 'E12':

          toleranceLookup.extendedMetalE1Tolerance['2500 x 1250 mm'].push(tol);

          break;

        case 'E13':

          toleranceLookup.extendedMetalE1Tolerance['3000 x 1500 mm'].push(tol);

          break;

        case 'E20':

          toleranceLookup.extendedMetalE2Tolerance.push(tol);

          break;

        case 'E30':

          toleranceLookup.extendedMetalE3Tolerance.push(tol);

          break;

      }

    }

    if (tol.product === 'F') {

      toleranceLookup.extendedMetalFTolerance.push(tol);

    }

    if (tol.product === 'G') {

      toleranceLookup.extendedMetalGTolerance.push(tol);

    }

  }


  toleranceLookup.materialTolerance = {};

  for (let i = 0; i < materials.length; i++) {

    const mat = materials[i];

    const matTol = toleranceLookup.materialTolerance[mat.materialCode] = toleranceLookup.materialTolerance[mat.materialCode] || [];

    matTol[mat.materialCode] = mat;

  }

  return toleranceLookup;
}


export function getToleranceRecord(appState: ProductConfiguration, segment, direction, toleranceLookup: ToleranceLookup): Tolerance {

  let tolArray = [];

  if (appState.productType === ProductType.PerforatedSheets) {

    tolArray = toleranceLookup.perforationTolerance[appState.material.materialType] || [];

  } else if (appState.productType === ProductType.ExtendedMetals) {

    if (appState.perforation.perforationType === 'standardFormat') {

      tolArray = toleranceLookup.extendedMetalE1Tolerance[appState.perforation.format] || [];

    } else if (appState.perforation.perforationType === 'standard') {

      tolArray = toleranceLookup.extendedMetalE3Tolerance;

    } else {

      tolArray = toleranceLookup.extendedMetalE2Tolerance;

    }

  } else if (appState.productType === ProductType.CrimpedMesh) {

    tolArray = toleranceLookup.extendedMetalFTolerance;

  } else if (appState.productType === ProductType.WeldedMesh) {

    tolArray = toleranceLookup.extendedMetalGTolerance;

  }


  for (let i = 0; i < tolArray.length; i++) {

    const tol = tolArray[i];

    if (tol.segmentMin <= segment && tol.segmentMax >= segment &&

      (tol.thicknessFrom === null || (tol.thicknessFrom <= appState.material.thickness && tol.thicknessTo >= appState.material.thickness)) &&

      (tol.direction.startsWith(direction) || tol.direction.startsWith('Dickentol'))) {

      return tol;

    }

  }

}


export function updateTolerance(appState: ProductConfiguration, toleranceLookup: ToleranceLookup): ProductConfiguration {
  const newElements = [];
  for (let i = 0; i < appState.elements.length; i++) {

    const el = {...appState.elements[i]};

    let tol = getToleranceRecord(appState, Number(el.a), 'A', toleranceLookup);

    if (tol) {

      if (!!tol.comment && tol.comment === 'Rohmaterialbreitentoleranz') {

        el.toleranceWidth = appState.configuration.material.breiteTolPlus || tol.toleranceValue || 0;

      } else {

        el.toleranceWidth = tol.toleranceValue || 0;

      }

    }

    tol = getToleranceRecord(appState, Number(el.b), 'B', toleranceLookup);

    if (tol) {

      if (!!tol.comment && tol.comment === 'Rohmateriallängentoleranz') {

        el.toleranceLength = appState.configuration.material.laengeTolPlus || tol.toleranceValue || 0;

      } else {

        el.toleranceLength = tol.toleranceValue || 0;

      }

    }
    newElements.push(el);
  }
  return {...appState, elements: newElements};

}
