import {Vector3} from "webcad/math";
import {Aabb2} from "webcad/models";

export interface Region {
  vertices:Vector3[];
  triangles:number[]; //3*index
  edges:number[]; //2*index
  aabb:Aabb2;
}

export function appendRegion(dest:Region, source:Region){
  const indexOffset = dest.vertices.length;
  dest.vertices = dest.vertices.concat(source.vertices);
  for (let i = 0; i < source.triangles.length; i++) {
    dest.triangles.push( indexOffset + source.triangles[i]);
  }
  for (let i = 0; i < source.edges.length; i++) {
    dest.edges.push( indexOffset + source.edges[i]);
  }
}
