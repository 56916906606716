import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getPlatePosition, MevacoState} from '../../../store/reducers';
import {TranslationProvider} from '../../../providers/translation.provider';
import {environment} from '../../../../environments/environment';
import {Element} from '../../../model/product-configuration/element.model';
import {CallZoom, ProductConfigurationEditElement, ShowViewerElement} from '../../../store/actions';
import {Observable} from 'rxjs';
import {delay, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-viewer-carousel-item',
  templateUrl: './viewer-carousel-item.component.html',
  styleUrls: ['./viewer-carousel-item.component.css']
})
export class ViewerCarouselItemComponent implements OnInit, AfterViewInit {

  getImageUrl: string;
  @Input() element: Element;
  public position: Observable<number>;

  constructor(private store: Store<MevacoState>, private translationProvider: TranslationProvider) {
  }

  ngOnInit() {
    this.getImageUrl = environment.api_url + '/api/mevaco/getPreviewImage/';
    this.position = this.store.pipe(select(getPlatePosition), startWith(null), delay(0));
  }

  getPreviewImageUrl(): string {
    return this.getImageUrl + this.element.previewImageId;
  }

  show() {
    this.store.dispatch(new ShowViewerElement(this.element.position));
    this.store.dispatch(new CallZoom());
  }

  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }

  ngAfterViewInit(): void {

  }
}
