import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {debounceTime, tap} from 'rxjs/operators';

@Component({
  selector: 'debounce-slider',
  templateUrl: './debounce-slider.component.html',
  styleUrls: ['./debounce-slider.component.css']
})
export class DebounceSliderComponent implements OnInit{

  @Input() min = 0;
  @Input() max = 100;
  @Input() step = 1;
  @Input() type = 1;
  @Input() showLabel = true;
  @Input() disabled = false;
  @Input() set value(v: number) {
    this.valueControl.setValue( this.validate ? this.validate(v) : v, {emitEvent: false});
    this.inputControl.setValue(this.validate ? this.validate(v) : v, {emitEvent: false});
  }
  @Output() valueChanged = new EventEmitter<string>();
  @Input() validate: (string) => string;
  valueControl = new FormControl();
  inputControl = new FormControl();

  constructor() { }

  ngOnInit() {
    this.valueControl.valueChanges
      .subscribe(v => {
      this.value = v;
      this.valueChanged.emit(this.valueControl.value);
    });

    this.inputControl.valueChanges
      .pipe(
        debounceTime(1000)
        // filter
      ).subscribe(v => {
        if (!!v.replace) {
          v = +v.replace(',', '.');
        }
        this.value = v;
        this.valueChanged.emit(this.valueControl.value);
    });
  }

  getLabelPosition() {
    let t = ((+this.inputControl.value - this.min) / this.max)
    return `calc(${2 + t * 100}% - 20px`;
  }
}
