<editable-label [textModel]="userTemplate.name" (onTextSubmit)="updateTemplateName($event)"></editable-label>
<div class="positions-carousel-item">

  <div class="carousel-item-icons-container">
    <div class="delete-position" (click)="toggleModal()"></div>
    <div class="edit-position" (click)="createPosition()"></div>
  </div>

  <div class="positions-carousel-item-content">
    <img class="positions-carousel-item-img" [src]="getPreviewImageUrl()">
  </div>

  <div class="carousel-item-overlay-container" [hidden]="!this.show">

      <span class="carousel-item-overlay-label" id="modal-small-title">{{translate("Are you sure")}}</span>
      <div class="carousel-item-overlay-btns">
        <button type="button" class="btn btn-primary carousel-item-overlay-btn" (click)="deleteTemplate()">{{translate("Yes")}}</button>
        <button type="button" class="carousel-item-overlay-link mt-10" (click)="toggleModal()">{{translate("No")}}</button>
      </div>
      
  </div>

</div>
