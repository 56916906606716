export enum MessageType {
  SUCCESS,
  ERROR
}

export interface Message {
  message: string;
  type: MessageType;
}

export const initialMessage: Message = {
  message: null,
  type: MessageType.ERROR

};
