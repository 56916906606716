import {Vector3, Vector4} from "../math";
import {CameraModel} from "../models/camera.model";
import {transformVec4} from "../math/matrix4";

export function toScreen(pos: Vector3, camera: CameraModel): Vector4 {
    var pos4: Vector4 = {x: pos.x, y: pos.y, z: pos.z, w: 1};
    pos4 = transformVec4(camera.view, pos4);
    pos4 = transformVec4(camera.projection, pos4);
    pos4.x /= pos4.w;
    pos4.y /= pos4.w;
    pos4.z /= pos4.w;
    return pos4;
}
