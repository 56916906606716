import {initialPerforationModel, PerforationModel} from '../../model/perforation.model';
import {
  CLEAR_PERFORATION,
  SET_PERFORATION,
  SET_PERFORATION_SUCCESS,
  SetPerforationSuccess
} from '../actions/perforation.actions';
import {Action} from '@ngrx/store';

export function perforationReducer(state: PerforationModel = initialPerforationModel, action: Action): PerforationModel {
  switch (action.type) {
    case SET_PERFORATION:
      return {
        ...state,
        perforation: [],
      };
    case CLEAR_PERFORATION:
      return {
        ...state,
        perforation: [],
      };
    case SET_PERFORATION_SUCCESS:
      return {
        ...state,
        perforation: (action as SetPerforationSuccess).payload.perforation
      };
      break;
  }
  return state;
}
