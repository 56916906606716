<div class="save-pattern__container">
  <div class="save-pattern__content">
    <span class="save-pattern__title">{{translate('Save pattern?', 'designer')}}</span>
    <input class="save-pattern__input" [(ngModel)]="name" [placeholder]="translate('Enter name', 'designer')">
    <button type="button" class="save-pattern__btn save-pattern__btn--save" (click)="save()">
    </button>
    <button type="button" class="save-pattern__btn save-pattern__btn--close" (click)="close.emit(false)">
      <span aria-hidden="true">&times;</span>
    </button>
    <img [src]="imgBase64">
  </div>
  <div *ngIf="isMevacoUser | async">
    <mat-checkbox [(ngModel)]="publicPattern" class="base-checkbox public-pattern-checkbox">
      {{translate('Public pattern')}}
    </mat-checkbox>
  </div>
</div>
