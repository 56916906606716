import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map, refCount, share, tap} from 'rxjs/operators';
import {ProductConfiguration} from '../model/product-configuration/product-configuration.model';
import {RuleAction} from '../model/rule-action.model';
import {Element} from "../model/product-configuration/element.model";
import {DuplicationData} from "../model/client-server.Params/duplication-data.params";
import {MevacoResponse} from "../model/client-server.Params/mevaco-response.model";
import {ChangeStateResult} from "../model/change-state-result";
//import * as hash from 'object-hash';

/*const hashOptions = {
  respectFunctionProperties: false,
  respectFunctionNames:false,
  respectType:false,
}*/

@Injectable()
export class ProductConfigurationService {

  private serverHash = null;
  constructor(private http: HttpClient) {
  }

  mapConfiguration(id: string, configuration: any): ProductConfiguration {
    const result: ProductConfiguration = configuration as ProductConfiguration;
    result.configId = id;

    //old configs load
    return result;
  }

  load(id: string): Observable<ProductConfiguration> {
    var observable : Observable<ProductConfiguration> = this.http.get<any>(environment.api_url + '/api/mevaco/GetConfiguration',
      {
        params: new HttpParams().set('configId', id)
      }
    ).pipe(
      map(this.mapConfiguration.bind(this, id)),
      share<ProductConfiguration>()
    );

    observable.subscribe((state)=>{
      this.serverHash = JSON.stringify(cleanupState(state));
    });
    return observable;
  }

  getUserInfo(id: string): Observable<Array<string>>{
    return this.http.get<any>(environment.api_url + '/api/mevaco/GetUserInfo/',
    {
      params: new HttpParams().set('configId', id)
    });
  }

  updateHash(configuration: ProductConfiguration): void{
    this.serverHash =  JSON.stringify(cleanupState(configuration));
  }
  save(configuration: ProductConfiguration): Observable<ChangeStateResult> {
    configuration = cleanupState(configuration);
    if(!this.serverHash){
      console.log("Skipping save since nothing was loaded yest");
      return of(null);
    }

    let newHash = JSON.stringify(configuration);
    if(newHash === this.serverHash){
      console.log("Skipping save since nothing was change");
      return of(null);
    }
    else {
      console.log("Saving...");
      return this.http.post<any>(environment.api_url + '/api/mevaco/ChangeState',
        {
          state: newHash,
          configId: configuration.configId
        }
      ).pipe(
        share<ChangeStateResult>()
      );
    }
  }

  validate(configuration: ProductConfiguration): Observable<ChangeStateResult> {
    return this.http.post<ChangeStateResult>(environment.api_url + '/api/mevaco/ValidateConfig',
      {
        state: JSON.stringify(configuration),
        configId: configuration.configId
      }
    );
  }


  undo(id: string): Observable<ProductConfiguration> {
    var observable : Observable<ProductConfiguration> =  this.http.get<ProductConfiguration>(environment.api_url + '/api/mevaco/UndoState/' + id.slice(1, id.length)).pipe(
      map(this.mapConfiguration.bind(this, id)),
      share<ProductConfiguration>()
    );
    observable.subscribe((state)=>{
      this.serverHash = JSON.stringify(cleanupState(state));
    });
    return observable;
  }

  redo(id: string): Observable<ProductConfiguration> {
    var observable : Observable<ProductConfiguration> =  this.http.get<ProductConfiguration>(environment.api_url + '/api/mevaco/RedoState/' + id.slice(1, id.length)).pipe(
      map(this.mapConfiguration.bind(this, id)),
      share<ProductConfiguration>()
    );
    observable.subscribe((state)=>{
      this.serverHash = JSON.stringify(cleanupState(state));
    });
    return observable;
  }

  generateCartData(configurationId:number):Observable<boolean> {
    return this.http.get<boolean>(environment.api_url + '/api/mevaco/GenerateCartData?configId=' + configurationId);
  }

  duplicateConfiguration(params: DuplicationData): Observable<MevacoResponse<string>> {
    return this.http.post<MevacoResponse<string>>(environment.api_url + '/api/mevaco/DuplicateConfiguration', params);
  }

  async getGetConfigHeadNote(configurationId): Promise<string> {
    const response = await this.http
      .get<MevacoResponse<string>>(environment.api_url + '/api/mevaco/GetGetConfigHeadNote?configId=' + configurationId).toPromise();
    return response.data;
  }

  async saveIncompleteConfiguration(configId: string, configHeadNote: string): Promise<boolean> {
    const response = await this.http.post<MevacoResponse<boolean>>(
      environment.api_url + '/api/mevaco/SaveIncompleteConfiguration',
      {configId, configHeadNote}).toPromise();
    return response.data;
  }

  async cancelChanges(configId: string): Promise<boolean> {
    const response = await this.http.post<MevacoResponse<boolean>>(
      environment.api_url + '/api/mevaco/CancelChanges',
      {configId}).toPromise();
    return response.data;
  }
}

function cleanupState(productConfiguration: ProductConfiguration): ProductConfiguration {
  return {
    ...productConfiguration,
    elements: (productConfiguration.elements || [] as Element[]).map((element: Element): Element => {
      const newElement = {
        ...element
      };
      delete newElement.nodes;
      return newElement;
    })
  };
}
