<div class="debounce-slider__container">
  <div
    #label
    class="debouce-slider__label"
    *ngIf="showLabel"
    [ngStyle]="{ left: getLabelPosition() }"
  >
    <div class="label__triangle"></div>
    <input class="label__input" [formControl]="inputControl" />
  </div>

  <mat-slider
    [disabled]="disabled"
    class="debounce-slider"
    [min]="min"
    [max]="max"
    [step]="step"
    class="slider"
    #slider
    showTickMarks="1"
    [formControl]="valueControl"
   #ngSlider><input matSliderThumb (input)="inputControl.setValue({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value}.value)" #ngSliderThumb="matSliderThumb" />
  </mat-slider>
</div>
