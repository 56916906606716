import {Drawing, initialDrawing, SegmentAndOrigin, ShapeOrigin} from '../../model/drawing.model';
import {nodeReducer} from './node.reducer';
import {
  SELECT_PERFORATION_AREA,
  SELECT_PERFORATION_AREA_BY_ID,
  SET_PERFORATION_AREA,
  SET_PERFORATION_AUTO_CENTER,
  SET_SELECTED_PERFORATION_AREA_OFFSET,
  UPDATE_DRAWING,
  UpdateDrawingParams
} from '../actions';
import {perforationReducer} from './perforation.reducer';
import {plateReducer} from './plate.reducer';
import {gridReducer} from './grid.reducer';
import {
  ADD_ANGLE_MEASUREMENT,
  ADD_HELP_LINE,
  ADD_MEASUREMENT,
  ADD_NODE_TO_SELECTION,
  ADD_PERFORATION_SEGMENT_TO_SELECTION,
  ADD_SHAPE_SEGMENT_TO_SELECTION,
  ADD_TOOL_HELP_LINE,
  HINT_DROPDOWN_LABEL_CHANGE,
  REMOVE_ALL_TOOL_HELP_LINES,
  REMOVE_ANGLE_MEASUREMENT,
  REMOVE_AUTOMATIC_MEASUREMENT,
  REMOVE_HELP_LINE,
  REMOVE_MEASUREMENT,
  REMOVE_NODE_FROM_SELECTION,
  REMOVE_PERFORATION_SEGMENT_FROM_SELECTION,
  REMOVE_SHAPE_SEGMENT_FROM_SELECTION,
  REMOVE_TOOL_HELP_LINE,
  RESET_DRAWING,
  ROTATE_IMPORT_SHAPE,
  SELECT_ANGLE_MEASUREMENT,
  SELECT_AUTOMATIC_MEASUREMENT,
  SELECT_HELP_LINE,
  SELECT_MEASUREMENT,
  SELECT_SHAPE,
  SET_AUTOMATIC_MEASUREMENTS,
  SET_CLOSEST_SEGMENT_VISIBLITY,
  SET_CURRENT_PERFORATION_AREA_ROTATION,
  SET_HINT_MESSAGE,
  SET_HINT_POSITION,
  SET_HINT_STATUS,
  SET_HINT_VISIBILITY,
  SET_NODES_SELECTION,
  SET_NODES_SELECTION_FROM_SEGMENTS,
  TOGGLE_3D_VIEW,
  UPDATE_PLATE_SHAPE_FAIL
} from '../actions/drawing.actions';
import {SET_PLATE} from '../actions/plate.actions';
import {addVectors2, sqrDistanceVector2, Vector2} from 'webcad/math';
import {AngleMeasurementModel, getEmptyAaabb, MeasurementModel, rotatePolyline, Segment} from 'webcad/models';
import {HelpLine, helpLinesEqual} from '../../model/help-line.model';
import {changeMapValue} from '../../utils/utils';
import {mountingsReducer} from './mountings.reducer';
import {snapOptionsReducer} from './snap-options.reducer';
import {updateDrawingPerforationArea, updateDrawingShape, updateDrawingShapesFromShapes} from '../store.utils';
import {getPerforationAreasGroup, PerforationAreaModel} from '../../model/perforation-area.model';
import {ShapeWithHoles} from '../../model/shape-with-holes';
import {ActionType, getNodeByPositionAndOrigin, Plate, PlateMaterialType, PointNode} from '../../model';
import {isMountingCorrectlyPlaced, MountingAttachment} from '../../model/mounting.model';
import {HintStatus} from '../../model/hint.model';
import {
  ADD_MOUNTING_TO_SELECTION,
  CHANGE_PERFORATION_OFFSET,
  REMOVE_MOUNTING,
  REMOVE_MOUNTING_FROM_SELECTION,
  REMOVE_MOUNTINGS,
  SELECT_MOUNTING,
  SET_MOUNTINGS_SELECTION
} from '../actions/mountings.actions';
import {SET_TOOL, SET_TOOL_OFFSET} from "../actions/tools.actions";
import {PerforationModeToolModel} from "../../model/tool/perforation-mode-tool.model";

export function drawingReducer(state: Drawing = initialDrawing, action: any): Drawing {
  let newState: Drawing = {...state};
  newState.nodes = nodeReducer(newState.nodes, action);
  newState.plate = plateReducer(newState.plate, action);
  newState.snappingModel = gridReducer(newState.snappingModel, action);
  newState.mountingsModel = mountingsReducer(newState.mountingsModel, action);
  newState.snapOptions = snapOptionsReducer(newState.snapOptions, action);
  switch (action.type) {
    case SELECT_SHAPE:
      newState.selectedShapeId = action.payload;
      break;
    case UPDATE_DRAWING: {
      const payload: UpdateDrawingParams = action.payload;
      newState = updateDrawingShapesFromShapes(newState, payload.shape, payload.perforationShapes);
      newState = removeIncorrectMountings(newState);
      newState = {
        ...newState,
        selectedMountings: [],
        selectedNodes: [],
        selectedMeasurementId: null,
        selectedHelpLineId: null,
        selectedAngleMeasurementId: null
      };
      break;
    }
    case RESET_DRAWING:
      return {
        ...state,
        plate: {
          position: -1,
          height: 3,
          width: 5,
          depth: 0.03,
          currentLineShapeId: -1,
          show: true,
          actionType: ActionType.REMOVE,
          plateMaterial: PlateMaterialType.shape,
          autoCenter: true,
          perforationAreas: [],
          hexColorString: '',
          mountingHoles: [],
          shapeWithHoles: {
            holes: [],
            conture: []
          },
          bendingLines: [],
          boundary:[],
        } as Plate
      } as Drawing;

    case TOGGLE_3D_VIEW:
      return{
        ...state,
        flat: action.payload,
        plate: {
          ...state.plate,
          plateMaterial: action.payload ? PlateMaterialType.shape : PlateMaterialType.real,
        },
        snapOptions:{
          ...state.snapOptions,
          bendingLines: action.payload
        }
      } as Drawing;

    case SET_PLATE:
      const newShape: ShapeWithHoles = action.payload.data[0] ? action.payload.data[0] : {
        conture: [],
        holes: [],
        hash: '',
        area: 0,
        aabb: getEmptyAaabb()
      };
      newState = updateDrawingShape(newState, newShape);
      newState = {
        ...newState,
        selectedMountings: [],
        selectedNodes: [],
        selectedMeasurementId: null,
        selectedHelpLineId: null,
        selectedAngleMeasurementId: null
      };
      break;
    case UPDATE_PLATE_SHAPE_FAIL:
      newState = updateDrawingShape(newState, action.payload.drawing.plate.shapeWithHoles);
      newState = {
        ...newState,
        selectedMountings: [],
        selectedNodes: [],
        selectedMeasurementId: null,
        selectedHelpLineId: null,
        selectedAngleMeasurementId: null
      };
      break;
    case SET_NODES_SELECTION:
      newState.selectedNodes = action.payload;
      break;
    case SET_NODES_SELECTION_FROM_SEGMENTS: {
      const segmentsAndOrigins: SegmentAndOrigin[] = action.payload;
      const newSelection: PointNode[] = [];
      for (const so of segmentsAndOrigins) {
        const begin = getNodeByPositionAndOrigin(newState.nodes, so.segment.begin, so.origin);
        const end = getNodeByPositionAndOrigin(newState.nodes, so.segment.end, so.origin);
        const hasBegin = newSelection.includes(begin);
        const hasEnd = newSelection.includes(end);
        if (!hasBegin) {
          newSelection.push(begin);
        }
        if (!hasEnd) {
          newSelection.push(end);
        }
      }
      newState.selectedNodes = newSelection;
      break;
    }
    case ADD_NODE_TO_SELECTION:
      for (const n of newState.selectedNodes) {
        if (n === action.payload) {
          return state;
        }
      }
      newState.selectedNodes = [...newState.selectedNodes, action.payload];
      break;
    case ADD_SHAPE_SEGMENT_TO_SELECTION: {
      const segment: Segment = action.payload;
      let beginNode = null;
      let endNode = null;
      for (const node of newState.nodes) {
        if (sqrDistanceVector2(node.position, segment.begin) < 0.005 && node.origin === ShapeOrigin.SHAPE) {
          beginNode = node;
        }
        if (sqrDistanceVector2(node.position, segment.end) < 0.005 && node.origin === ShapeOrigin.SHAPE) {
          endNode = node;
        }
        if (beginNode && endNode) {
          break;
        }
      }
      const nodesToAdd = [];
      if (!newState.selectedNodes.includes(beginNode)) {
        nodesToAdd.push(beginNode);
      }
      if (!newState.selectedNodes.includes(endNode)) {
        nodesToAdd.push(endNode);
      }
      newState.selectedNodes = [...newState.selectedNodes, ...nodesToAdd];
      break;
    }
    case ADD_PERFORATION_SEGMENT_TO_SELECTION: {
      const segment: Segment = action.payload;
      let beginNode = null;
      let endNode = null;
      for (const node of newState.nodes) {
        if (sqrDistanceVector2(node.position, segment.begin) < 0.005 && node.origin === ShapeOrigin.PERFORATION) {
          beginNode = node;
        }
        if (sqrDistanceVector2(node.position, segment.end) < 0.005 && node.origin === ShapeOrigin.PERFORATION) {
          endNode = node;
        }
        if (beginNode && endNode) {
          break;
        }
      }
      const nodesToAdd = [];
      if (!newState.selectedNodes.includes(beginNode)) {
        nodesToAdd.push(beginNode);
      }
      if (!newState.selectedNodes.includes(endNode)) {
        nodesToAdd.push(endNode);
      }
      newState.selectedNodes = [...newState.selectedNodes, ...nodesToAdd];
      break;
    }
    case REMOVE_NODE_FROM_SELECTION: {
      const indexOfSelectedNode = newState.selectedNodes.indexOf(action.payload);
      if (indexOfSelectedNode >= 0) {
        newState.selectedNodes.splice(indexOfSelectedNode, 1);
        newState.selectedNodes = [...newState.selectedNodes];
      }
      break;
    }
    case REMOVE_SHAPE_SEGMENT_FROM_SELECTION: {
      const segment: Segment = action.payload;
      const nodesToRemove = [];
      for (const node of newState.selectedNodes) {
        if (node.origin === ShapeOrigin.SHAPE && (sqrDistanceVector2(node.position, segment.begin) < 0.005 || sqrDistanceVector2(node.position, segment.end) < 0.005)) {
          nodesToRemove.push(node);
        }
      }
      newState.selectedNodes = newState.selectedNodes.filter((v) => !nodesToRemove.includes(v));
      newState.selectedNodes = [...newState.selectedNodes];
      break;
    }
    case REMOVE_PERFORATION_SEGMENT_FROM_SELECTION: {
      const segment: Segment = action.payload;
      const nodesToRemove = [];
      for (const node of newState.selectedNodes) {
        if (node.origin === ShapeOrigin.PERFORATION && (sqrDistanceVector2(node.position, segment.begin) < 0.005 || sqrDistanceVector2(node.position, segment.end) < 0.005)) {
          nodesToRemove.push(node);
        }
      }
      newState.selectedNodes = newState.selectedNodes.filter((v) => !nodesToRemove.includes(v));
      newState.selectedNodes = [...newState.selectedNodes];
      break;
    }
    case SET_PERFORATION_AREA:
      newState = updateDrawingPerforationArea(newState, action.payload.data);
      newState = {
        ...newState,
        selectedMountings: [],
        selectedNodes: [],
        selectedMeasurementId: null,
        selectedHelpLineId: null,
        selectedAngleMeasurementId: null
      };
      break;
    case ADD_HELP_LINE:
      const hkeys = Array.from(state.helpingLines.keys());
      const hnewKey = hkeys.length > 0 ? hkeys[hkeys.length - 1] + 1 : 0;
      newState = {
        ...newState,
        helpingLines: changeMapValue(state.helpingLines, action.payload, hnewKey)
      };
      break;
    case REMOVE_HELP_LINE:
      let index = null;
      newState.helpingLines.forEach((v, k) => {
        if (helpLinesEqual(v, action.payload)) {
          index = k;
        }
      });
      if (index !== null) {
        const newHelpLines = new Map<number, HelpLine>([...Array.from(state.helpingLines.entries())]);
        newHelpLines.delete(index);
        newState = {
          ...newState,
          helpingLines: newHelpLines,
          selectedHelpLineId: null
        };
      }
      break;
    case SELECT_HELP_LINE: {
      const helpLine: HelpLine = action.payload;
      let helpLineIndex = null;
      newState.helpingLines.forEach((v, k) => {
        if (helpLinesEqual(v, action.payload)) {
          helpLineIndex = k;
        }
      });
      newState = {
        ...newState,
        selectedHelpLineId: helpLineIndex
      };
      break;
    }
    case ADD_TOOL_HELP_LINE:
      const thkeys = Array.from(state.toolHelpLines.keys());
      const thnewKey = thkeys.length > 0 ? thkeys[thkeys.length - 1] + 1 : 0;
      newState = {
        ...newState,
        toolHelpLines: changeMapValue(state.toolHelpLines, action.payload, thnewKey)
      };
      break;
    case REMOVE_TOOL_HELP_LINE:
      let tindex = null;
      newState.toolHelpLines.forEach((v, k) => {
        if (v === action.payload || helpLinesEqual(v, action.payload)) {
          tindex = k;
        }
      });
      if (tindex) {
        const newHelpLines = new Map<number, HelpLine>([...Array.from(state.toolHelpLines.entries())]);
        newHelpLines.delete(tindex);
        newState = {
          ...newState,
          toolHelpLines: newHelpLines
        };
      }
      break;
    case REMOVE_ALL_TOOL_HELP_LINES:
      return {
        ...state,
        toolHelpLines: new Map<number, HelpLine>()
      };

    case SET_CLOSEST_SEGMENT_VISIBLITY:
      return {
        ...state,
        closestSegmentVisibility: action.payload
      };

    case SET_HINT_MESSAGE:
      return {
        ...state,
        hint: {
          ...state.hint,
          label: {
            ...state.hint.label,
            value: action.payload
          }
        }
      };
    case SET_HINT_POSITION:
      return {
        ...state,
        hint: {
          ...state.hint,
          label: {
            ...state.hint.label,
            placement: {
              ...state.hint.label.placement,
              position: action.payload
            }
          }
        }
      };
      case SET_HINT_VISIBILITY:
      return {
        ...state,
        hint: {
          ...state.hint,
          visible: action.payload,
          label: {
            ...state.hint.label,
            visible: action.payload && state.hint.status === HintStatus.Enabled
          }
        }
      };
    case SET_HINT_STATUS: {
      const newStatus: HintStatus = action.payload;
      const newHint = {...state.hint};
      switch (newStatus) {
        case HintStatus.Enabled:
          newHint.status = HintStatus.Enabled;
          newHint.label = {
            ...newHint.label,
            visible: state.hint.visible
          };
          break;
        case HintStatus.Disabled:
          newHint.status = HintStatus.Disabled;
          newHint.label = {
            ...newHint.label,
            visible: false
          };
          break;
        case HintStatus.Sticky:
          newHint.status = HintStatus.Sticky;
          newHint.label = {
            ...newHint.label,
            visible: false
          };
          break;
      }
      return {
        ...state,
        hint: newHint
      };
    }
    case HINT_DROPDOWN_LABEL_CHANGE:
      return {
        ...state,
        hintOption: action.payload as string
      };
    case ADD_MEASUREMENT:
      const keys = Array.from(state.measurements.keys());
      const newKey = keys.length > 0 ? keys[keys.length - 1] + 1 : 0;
      return {
        ...state,
        measurements: changeMapValue(state.measurements, action.payload, newKey)
      };
    case REMOVE_MEASUREMENT: {
      let keyToRemove = null;
      const measurms = new Map<number, MeasurementModel>([...Array.from(state.measurements.entries())]);
      if (action.payload) {
        state.measurements.forEach((v, k) => {
          if (v === action.payload) {
            keyToRemove = k;
          }
        });
      }
      if (keyToRemove === null && state.selectedMeasurementId !== null) {
        keyToRemove = state.selectedMeasurementId;
      }
      if (keyToRemove !== null) {
        measurms.delete(keyToRemove);
        return {
          ...state,
          measurements: measurms,
          selectedMeasurementId: null
        };
      }
      return state;
    }
    case SELECT_MEASUREMENT: {
      const currSelected: MeasurementModel = state.selectedMeasurementId !== null ? state.measurements.get(state.selectedMeasurementId) : null;
      const copiedState = {...state};
      if (currSelected !== null) {
        const newLastSelected: MeasurementModel = {
          ...currSelected,
          color: {x: 0, y: 0, z: 0}
        };
        copiedState.measurements = changeMapValue(copiedState.measurements, newLastSelected, state.selectedMeasurementId);
      }
      let modelId = null;
      if (action.payload) {
        copiedState.measurements.forEach((v, k) => {
          if (v === action.payload) {
            modelId = k;
          }
        });
        if (modelId !== null) {
          const newSelectedState: MeasurementModel = {
            ...copiedState.measurements.get(modelId),
            color: {x: 0, y: 1, z: 0}
          };
          copiedState.measurements = changeMapValue(copiedState.measurements, newSelectedState, modelId);
        }
      }
      copiedState.selectedMeasurementId = modelId;
      return copiedState;
    }
    case SELECT_AUTOMATIC_MEASUREMENT: {
      const currSelected: MeasurementModel = state.selectedAutomaticMeasurementId !== null ? state.automaticMeasurements.get(state.selectedAutomaticMeasurementId) : null;
      const copiedState = {...state};
      if (currSelected !== null) {
        const newLastSelected: MeasurementModel = {
          ...currSelected,
          color: {x: 0, y: 0, z: 0}
        };
        copiedState.automaticMeasurements = changeMapValue(copiedState.automaticMeasurements, newLastSelected, state.selectedAutomaticMeasurementId);
      }
      let modelId = null;
      if (action.payload) {
        copiedState.automaticMeasurements.forEach((v, k) => {
          if (v === action.payload) {
            modelId = k;
          }
        });
        if (modelId !== null) {
          const newSelectedState: MeasurementModel = {
            ...copiedState.automaticMeasurements.get(modelId),
            color: {x: 0, y: 1, z: 0}
          };
          copiedState.automaticMeasurements = changeMapValue(copiedState.automaticMeasurements, newSelectedState, modelId);
        }
      }
      copiedState.selectedAutomaticMeasurementId = modelId;
      return copiedState;
    }
    case ADD_ANGLE_MEASUREMENT:
      const anglekeys = Array.from(state.angleMeasurements.keys());
      const anglenewKey = anglekeys.length > 0 ? anglekeys[anglekeys.length - 1] + 1 : 0;
      return {
        ...state,
        angleMeasurements: changeMapValue(state.angleMeasurements, action.payload, anglenewKey)
      };
    case REMOVE_ANGLE_MEASUREMENT:
      let anglekeyToRemove = null;
      const anglemeasurms = new Map<number, AngleMeasurementModel>([...Array.from(state.angleMeasurements.entries())]);
      if (action.payload) {
        state.angleMeasurements.forEach((v, k) => {
          if (v === action.payload) {
            anglekeyToRemove = k;
          }
        });
      }
      if (anglekeyToRemove === null && state.selectedAngleMeasurementId !== null) {
        anglekeyToRemove = state.selectedAngleMeasurementId;
      }
      if (anglekeyToRemove !== null) {
        anglemeasurms.delete(anglekeyToRemove);
        return {
          ...state,
          angleMeasurements: anglemeasurms,
          selectedAngleMeasurementId: null
        };
      }
      return state;
    case SELECT_ANGLE_MEASUREMENT:
      const currSelectedAngle: AngleMeasurementModel = state.selectedAngleMeasurementId !== null ? state.angleMeasurements.get(state.selectedAngleMeasurementId) : null;
      const copiedAngleState = {...state};
      if (currSelectedAngle !== null) {
        const newLastSelected: AngleMeasurementModel = {
          ...currSelectedAngle,
          color: {x: 0, y: 0, z: 0}
        };
        copiedAngleState.angleMeasurements = changeMapValue(copiedAngleState.angleMeasurements, newLastSelected, state.selectedAngleMeasurementId);
      }
      let anglemodelId = null;
      if (action.payload) {
        copiedAngleState.angleMeasurements.forEach((v, k) => {
          if (v === action.payload) {
            anglemodelId = k;
          }
        });
        if (anglemodelId !== null) {
          const newSelectedState: AngleMeasurementModel = {
            ...copiedAngleState.angleMeasurements.get(anglemodelId),
            color: {x: 0, y: 1, z: 0}
          };
          copiedAngleState.angleMeasurements = changeMapValue(copiedAngleState.angleMeasurements, newSelectedState, anglemodelId);
        }
      }
      copiedAngleState.selectedAngleMeasurementId = anglemodelId;
      return copiedAngleState;
    case REMOVE_MOUNTING:
      newState = {
        ...newState,
        selectedMountings: []
      };
      break;
    case REMOVE_MOUNTINGS:
      newState = {
        ...newState,
        selectedMountings: []
      };
      break;
    case SET_MOUNTINGS_SELECTION:
      newState = {
        ...newState,
        selectedMountings: action.payload
      };
      break;
    case ADD_MOUNTING_TO_SELECTION: {
      const toAdd: MountingAttachment = action.payload;
      const includes = newState.selectedMountings.includes(toAdd);
      if (!includes) {
        newState = {
          ...newState,
          selectedMountings: [...newState.selectedMountings, toAdd]
        };
      }
      break;
    }
    case REMOVE_MOUNTING_FROM_SELECTION: {
      const toRemove: MountingAttachment = action.payload;
      const indexToRemove = newState.selectedMountings.findIndex((v) => v === toRemove);
      if (indexToRemove > -1) {
        const newMountingsSelection = [...newState.selectedMountings];
        newMountingsSelection.splice(indexToRemove, 1);
        newState = {
          ...newState,
          selectedMountings: newMountingsSelection
        };
      }
      break;
    }
    case SELECT_MOUNTING:
      const currentSelected = [...newState.selectedMountings];
      if (action.payload) {
        const mounting_index = currentSelected.findIndex(x => x === action.payload);
        if (mounting_index > -1) {
          currentSelected.splice(mounting_index, 1);
        } else {
          currentSelected.push(action.payload);
        }
      }
      newState = {
        ...newState,
        selectedMountings: currentSelected
      };
      break;
    case SELECT_PERFORATION_AREA_BY_ID:
      newState = {
        ...newState,
        selectedPerforationArea: action.payload
      };
      break;
    case SELECT_PERFORATION_AREA: {
      const selectedId = newState.plate.perforationAreas.findIndex((v) => v === action.payload);
      newState = {
        ...newState,
        selectedPerforationArea: selectedId
      };
      break;
    }
    case CHANGE_PERFORATION_OFFSET:
      if (isNaN(action.payload.x) || isNaN(action.payload.y)) {
        break;
      }
      newState = movePerforationPattern(state, action.payload);
      break;
    // case SET_DEPENDENT_MODE:
    //   newState = {
    //     ...state,
    //     dependentMode: action.payload,
    //     plate: {
    //       ...state.plate,
    //       perforationAreas: state.plate.perforationAreas.map(area => ({...area, offset: {x: 0, y: 0}, rotation: 0}))
    //     }
    //   };
    //   break;
    case SET_PERFORATION_AUTO_CENTER:
      newState = {
        ...state,
        plate: {
          ...state.plate,
          autoCenter: action.payload
        }
      };
      break;
    case SET_CURRENT_PERFORATION_AREA_ROTATION:
      const area = state.plate.perforationAreas[state.selectedPerforationArea];
      if (!!area) {
        newState = {
          ...state,
          plate: {
            ...state.plate,
            perforationAreas: [
              ...state.plate.perforationAreas
            ]
          }
        };
        newState.plate.perforationAreas[state.selectedPerforationArea] = {
          ...area,
          rotation: action.payload
        };
      } else {
        newState = {
          ...state,
          plate: {
            ...state.plate,
            perforationAreas: newState.plate.perforationAreas.map((pa)=>{return {...pa, rotation: action.payload}})
          }
        };
      }
      //newState.dependentMode = false;
      break;
    case SET_AUTOMATIC_MEASUREMENTS: {
      newState = {
        ...newState,
        plate: {
          ...newState.plate,
          bendingLinesDistances: action.payload.bendingLinesDistances
        },
        automaticMeasurements: action.payload.measurements,
      };
      break;
    }
    case REMOVE_AUTOMATIC_MEASUREMENT: {
      let keyToRemove = null;
      const measurms = new Map<number, MeasurementModel>([...Array.from(state.automaticMeasurements.entries())]);
      if (action.payload) {
        state.automaticMeasurements.forEach((v, k) => {
          if (v === action.payload) {
            keyToRemove = k;
          }
        });
      }
      if (keyToRemove === null && state.selectedAutomaticMeasurementId !== null) {
        keyToRemove = state.selectedAutomaticMeasurementId;
      }
      if (keyToRemove !== null) {
        const removed = measurms.get(keyToRemove);
        measurms.delete(keyToRemove);
        return {
          ...state,
          automaticMeasurements: measurms,
          removedMeasurements: [...state.removedMeasurements, removed],
          selectedAutomaticMeasurementId: null
        };
      }
      return state;
    }
    case ROTATE_IMPORT_SHAPE: {
      return  {
        ...state,
        importedShape: state.importedShape.map( x=> rotatePolyline( x, action.payload))
      }
    }
    case SET_SELECTED_PERFORATION_AREA_OFFSET:{
      return setSelectedPerforationAreaOffset(state, action.payload.x, action.payload.y);
    }
    case SET_TOOL: {
      return  {
        ...state,
        tool:  action.payload
      }
    }
    case SET_TOOL_OFFSET: {
      return  {
        ...state,
        tool: {
          ...state.tool,
          offset: action.payload
        } as PerforationModeToolModel
      }
    }
  }

  if (state.nodes !== newState.nodes ||
    state.plate !== newState.plate ||
    state.snappingModel !== newState.snappingModel ||
    state.selectedShapeId !== newState.selectedShapeId ||
    state.selectedNodes !== newState.selectedNodes ||
    state.helpingLines !== newState.helpingLines ||
    state.selectedHelpLineId !== newState.selectedHelpLineId ||
    state.toolHelpLines !== newState.toolHelpLines ||
    state.mountingsModel !== newState.mountingsModel ||
    state.selectedMountings !== newState.selectedMountings ||
    state.snapOptions !== newState.snapOptions ||
    state.selectedPerforationArea !== newState.selectedPerforationArea ||
    state.automaticMeasurements !== newState.automaticMeasurements ||
    state.removedMeasurements !== newState.removedMeasurements
  ) {
    return newState;
  } else {
    return state;
  }
}

function setSelectedPerforationAreaOffset(state: Drawing, x?:number, y?:number):Drawing {
  const selection = state.selectedPerforationArea;
  return {
    ...state,
    plate: {
      ...state.plate,
      perforationAreas: state.plate.perforationAreas.map((perforationArea, i) => {
        if (selection === -1 || selection === i) {
          return {
            ...perforationArea,
            offset: {
              x: x === null || x === undefined ? perforationArea.offset.x : x,
              y: y === null || y === undefined ? perforationArea.offset.y : y,
            }
          }
        } else {
          return perforationArea
        }
      }),
      defaultPerforationAreaOffset: {
        x: x === null || x === undefined ? (state.plate.defaultPerforationAreaOffset ? state.plate.defaultPerforationAreaOffset.x : 0) : x,
        y: y === null || y === undefined ? (state.plate.defaultPerforationAreaOffset ? state.plate.defaultPerforationAreaOffset.y : 0) : y
      }
    }
  }
}

function movePerforationPattern(state: Drawing, offset: Vector2): Drawing {
  const selectedAreas = getPerforationAreasGroup(state.plate.perforationAreas, state.selectedPerforationArea, state.dependentMode);

  if (selectedAreas.length > 0) {

    const newState: Drawing = {
      ...state,
      plate: {
        ...state.plate,
        perforationAreas: [...state.plate.perforationAreas]
      }
    };
    const currentOffset: Vector2 = selectedAreas[0].offset;
    const newOffset = addVectors2(currentOffset, offset);
    const allAreas = newState.plate.perforationAreas;
    for (const area of selectedAreas) {
      const newArea: PerforationAreaModel = {...area};
      newArea.offset = {...newOffset};
      allAreas[allAreas.indexOf(area)] = newArea;
    }

    if (state.dependentMode) {
      for (const area of state.plate.perforationAreas) {
        if (selectedAreas.indexOf(area) === -1) {
          const newArea: PerforationAreaModel = {...area};
          newArea.offset = {
            x: area.offset.x,
            y: newOffset.y
          };
          allAreas[allAreas.indexOf(area)] = newArea;
        }
      }
    }

    return newState;
  } else {
    const newState: Drawing = {
      ...state,
      plate: {
        ...state.plate,
        perforationAreas: [...state.plate.perforationAreas]
      }
    };
    const allAreas = newState.plate.perforationAreas;
    for (const sa of allAreas) {
      const currentOffset: Vector2 = sa.offset;
      const newOffset = addVectors2(currentOffset, offset);
      sa.offset = {...newOffset};
    }
    return newState;
  }
}

function removeIncorrectMountings(state: Drawing) : Drawing {

  const filtered : MountingAttachment[] = [];
  state.plate.mountingHoles.forEach( (ma, id) => {
    if(isMountingCorrectlyPlaced(ma, state.plate.shapeWithHoles, state.plate.depth)){
      filtered.push(ma);
    }
  });

  if(filtered.length !== state.plate.mountingHoles.length) {
    return {
      ...state,
      plate : {
        ...state.plate,
        mountingHoles: filtered
      }
    }
  }
  return state;

}


