import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslationProvider } from '../../providers/translation.provider';
import { Step } from '../../model/product-configuration/product-configuration.model';
import {
  ChangeStartPopup,
  ChangeTemplateModal,
  ProductConfigurationRemoveAllElements,
  ProductConfigurationSetOnlyElement,
  SetStep
} from '../../store';
import { MevacoState } from '../../store';
import { ElementType, Element } from '../../../../src/app/model/product-configuration/element.model';
import { TemplateName } from '../templates/template-name';
import { Guid } from 'guid-typescript';
import { createRectangle, ShapeWithHoles } from '../../model/shape-with-holes';
import { aabbOfPolyline } from 'webcad';
import * as hash from 'object-hash';
import {View3dProvider} from '../../providers/view3d.provider';

@Component({
  selector: 'design-modal',
  templateUrl: './design-modal.component.html',
  styleUrls: ['./design-modal.component.css']
})
export class DesignModalComponent implements OnInit {

  constructor(
    private store: Store<MevacoState>,
    private translationProvider: TranslationProvider,
    private view3DProvider: View3dProvider,
  ) { }

  ngOnInit() {
  const a = 850 / 1000;
  const b = 1150 / 1000;
  const contures = createRectangle({ x: 0, y: 0 }, { x: b, y: a });
  const aabb = aabbOfPolyline(contures);
  const area = b * a;
  const shape: ShapeWithHoles = {
    conture: contures,
    holes: [],
    aabb: aabb,
    area: area
  };
  shape.hash = hash(shape.aabb) + hash(shape.area);
  shape.hash = hash(shape.aabb) + hash(shape.area);
  const perfMin = { x: 0.015, y: 0.015 };
  const perfMax = { x: (b - 0.015), y: (a - 0.015) };
  const perforationArea = createRectangle(perfMin, perfMax);
  const perfAabb = aabbOfPolyline(perforationArea);
  const perfArea = (perfMax.x - perfMin.x) * (perfMax.y - perfMin.y);
  const perforationAreaShape: ShapeWithHoles = {
    conture: perforationArea,
    holes: [],
    aabb: perfAabb,
    area: perfArea
  };
  perforationAreaShape.hash = hash(perforationAreaShape);
  const element: Element = {
    type: ElementType.individual,
    templateName: TemplateName.basicRectangle,
    boundary: null,
    position: -1, // will be set inb reducer
    quantity: 1,
    a: a,
    b: b,
    aIst: a,
    bIst: b,
    e1: `${15}`,
    e1ist: `${15}`,
    e2: `${15}`,
    e2ist: `${15}`,
    f1: `${15}`,
    f1ist: `${15}`,
    f2: `${15}`,
    f2ist: `${15}`,
    openMeshE: '',
    openMeshF: '',
    toleranceWidth: 0,
    toleranceLength: 0,
    label: '',
    note: '',
    unperforated: false,
    posibleCoil: 'No',
    posiblePlate: 'No',
    shapes: null,
    nodes: null,
    verticesIndexes: null,
    visualizationShape: null,
    previewImageId: Guid.create().toString(),
    shape: shape,
    perforationAreas: [
      {
        shape: perforationAreaShape,
        rotation: 0,
        offset: { x: 0, y: 0 }
      }
    ],
    helpLines: [],
    measurements: [],
    angleMeasurements: [],
    mountings: [],
    perforationAutoCenter: true,
    area: shape.area,
    minRadius: null,
    minParallelEdgesDistance: null,
    minMarginDistance: null,
    isPerforationSimpleRect: null,
    isShapeSimpleRect: null,
    minMountingHoleEdgeDistance: null,
    minMountingHolesDistance: null,
    minOutsideAngle: null,
    minDistanceBetweenMountingAndPerforatedArea: null,
    minDistanceBetweenEdges: null,
    numberOfArcs: null,
    // perforation: null,
    possibleAllAcross: true,
    minDistanceBetweenPerforationAreas: null,
    lfbIst: undefined,
    lflIst: undefined,
    breakLines: [],
    bendingLinesDistances: [],
    // possiblePerforationZones: []
  };

  this.store.dispatch(new ProductConfigurationSetOnlyElement(element));
  this.store.dispatch(new SetStep(Step.design));
  this.view3DProvider.zoomToFitPlate();
  }

  translate(text: string, module: string = 'configurator') {
    return this.translationProvider.translate(text, module);
  }

setShapeStep() {
    this.store.dispatch(new ChangeStartPopup(false));
    this.store.dispatch(new SetStep(Step.shape));
}
simpleRectangleStep() {
  this.store.dispatch(new ChangeStartPopup(false));
  this.store.dispatch(new SetStep(Step.template));
  this.store.dispatch(new ChangeTemplateModal(true));
  this.store.dispatch(new ProductConfigurationRemoveAllElements());
}
selectTemplateStep() {
  this.store.dispatch(new ChangeStartPopup(false));
  this.store.dispatch(new SetStep(Step.template));
  this.store.dispatch(new ProductConfigurationRemoveAllElements());
}
closeOnStartPopup() {
  this.store.dispatch(new ChangeStartPopup(false));
}

}
