import {Drawing} from "../drawing.model";
import {BendingLine} from "../bending-line.model";

export interface BendingLinesViewModel {
  bendLines: BendingLine[];
  state: boolean;
}

export const BendingLinesViewModelPipeLine = (state: Drawing): BendingLinesViewModel => {
  return {
    bendLines: state.plate.bendingLines,
    state: state.snapOptions.bendingLines
  };
};
