import { TranslationProvider } from '../../providers/translation.provider';
import { roundDecimal } from '../../../app/utils/utils';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MevacoState } from '../../../app/store';
import { SetDefaultValues } from '../../store/actions/element-creator.actions';
import {DebounceInputComponent} from '../debounce-input/debounce-input.component';
import {BendingLine} from '../../model/bending-line.model';
import {
  addVectors2, getRightPerpendicularVector2, lengthVector2,
  lengthVector3,
  normalizeVector2,
  normalizeVector3, subVectors2,
  Vector2,
  vector2toVector3, Vector3
} from 'webcad/math';
import {MeasurementMask} from '../../model/measurement-mask.enum';
import {Element, ElementType} from '../../model/product-configuration/element.model';
import {ShapeWithHoles, shapeWithHolesHash} from '../../model/shape-with-holes';
import {TemplateName} from './template-name';
import {Guid} from 'guid-typescript';
import {aabbOfPolyline, LineSegmentsFromPath, MeasurementModel, PolylineArea} from 'webcad/models';
import {ParseDecimalNumber} from 'webcad/utils/parse-number';
import {movePolyline, movePolylineInPlace} from 'webcad/models';
import {addVectors3} from 'webcad/math';
import {PlateService} from '../../services/plate.service';
import {MevacoResponse} from '../../model/client-server.Params/mevaco-response.model';

@Injectable()
export class TemplateUtils {
    constructor(private translation: TranslationProvider,
                private store: Store<MevacoState>) { }

    returnToFirstElement(ev: KeyboardEvent, element: HTMLElement) {
        if (!ev.shiftKey && ev.key === 'Tab') {
            ev.preventDefault();
            element.focus();
        }
    }

    returnToLastElement(ev: KeyboardEvent, element: HTMLElement) {
        if (ev.shiftKey && ev.key === 'Tab') {
            ev.preventDefault();
            element.focus();
        }
    }

    roundInput(event, minValue?: number, maxValue?: number, decimals = 1): number {
        const value: string = event.target.value;
        const dotString = value.replace(',', '.');
        const originalNumber = isNaN(Number(dotString)) ? NaN : parseFloat( dotString );
        let numberValue = originalNumber;
        if (!!minValue && numberValue < minValue) {
          numberValue = minValue;
        }
        if (!!maxValue && maxValue !== 0) {
            if ( numberValue > maxValue) {
              numberValue = maxValue;
            }
        }
        const r = roundDecimal(numberValue, decimals);
        if (r !== originalNumber) {
          event.target.value = r.toString();
        }
        return r;
    }

    translate(s, module = 'configurator') {
        return this.translation.translate(s, module);
    }

    setDefault() {
        this.store.dispatch(new SetDefaultValues());
    }

  inputValidation(min: number, max: number, decimals: number): (string) => string {
    return (x: string) => {
      let result = Math.max( Math.min(+x, max) , min);
      result = roundDecimal(result, decimals);
      return result.toString();
    };
  }

  setupFocusTabs( inputComponents: DebounceInputComponent[]): () => void {
    const onKeyDown = function(ev) {
      if (ev.code === 'Tab') {
        let index = inputComponents.findIndex( c => c.element.nativeElement === document.activeElement);
        index++;
        const nextElement = inputComponents[index % inputComponents.length].element.nativeElement;
        nextElement.focus();
        nextElement.select();
        ev.preventDefault();
      }
    };

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }

}

export class TemplateShapeBuilder {
  private path: Vector2[];
  private bendingLines: BendingLine[] = [];
  private measurements: MeasurementModel[] = [];
  constructor(private plateService: PlateService, start: Vector2) {
    this.path = [start];
  }
  get current(): Vector2 {return this.path[this.path.length - 1]; }

  public moveBy(v: Vector2) {
    this.path.push(addVectors2(this.current, v));
  }

  public bendingBy(v: Vector2, bentParams, angle, nr) {
    this.bendingLines.push({
      begin: this.current,
      end: addVectors2(this.current, v),
      bentParams,
      angle,
      nr
    });
  }

  public measurementByWithOffset(offset: Vector2, direction: Vector2, distance, mask): void {
    this.measurement(addVectors2(this.current, offset), direction, distance, mask);
  }

  public measurementBy(direction: Vector2, distance, mask): void {
    this.measurement(this.current, direction, distance, mask);
  }
  public measurement(start: Vector2, direction: Vector2, distance, mask): void {
    this.measurements.push(  {
      editable: false,
      start: vector2toVector3(start),
      direction: vector2toVector3( normalizeVector2(getRightPerpendicularVector2(direction))),
      measurementDirection: vector2toVector3(normalizeVector2(direction)),
      maxValue: 1,
      focusable: false,
      visible: true,
      distance,
      exchange: {
        value: lengthVector2(direction),
        onInputLive: null, // to be set by tool
        onInputConfirmed: null, // to be set by tool
        inputValidation: (val) => val !== '' && ParseDecimalNumber(val) !== undefined,

        fromModel: (value: number) => (Math.round(value * 10000) / 10).toString(),
        toModel: (value: string) => (ParseDecimalNumber(value) / 1000)
      },
      mask,
    });
  }

  public addCassetteMeasurements(zero3: Vector3, size: Vector3): void {
    function create(start: Vector3, mVec: Vector3, dir: Vector3): MeasurementModel {
      return {
        editable: false,
        start: start,
        direction: dir,
        measurementDirection: normalizeVector3(mVec),
        maxValue: 1,
        focusable: false,
        visible: true,
        distance: 0,
        exchange: {
          value: lengthVector3(mVec),
          onInputLive: null, // to be set by tool
          onInputConfirmed: null, // to be set by tool
          inputValidation: (val) => val !== '' && ParseDecimalNumber(val) !== undefined,

          fromModel: (value: number) => (Math.round(value * 10000) / 10).toString(),
          toModel: (value: string) => (ParseDecimalNumber(value) / 1000)
        },
        mask: MeasurementMask.Cassette
      };
    }
    this.measurements.push(
      create(zero3, {x: size.x, y: 0, z: 0}, {x: 0, y: -1, z: 0}),
      create(zero3, {x: 0, y: size.y, z: 0}, {x: -1, y:  0, z: 0}),
      create(zero3, {x: 0, y: 0, z: size.z}, {x: 0, y: -1, z: 0}),
    );
  }
  public addSecondLegMeasurement(begin: Vector3, dir: Vector3, mDir: Vector3, l:number): void {
      const mesurement: MeasurementModel = {
        editable: false,
        start: begin,
        direction: dir,
        measurementDirection: mDir,
        maxValue: 1,
        focusable: false,
        visible: true,
        distance: 0,
        exchange: {
          value: l,
          onInputLive: null, // to be set by tool
          onInputConfirmed: null, // to be set by tool
          inputValidation: (val) => val !== '' && ParseDecimalNumber(val) !== undefined,

          fromModel: (value: number) => (Math.round(value * 10000) / 10).toString(),
          toModel: (value: string) => (ParseDecimalNumber(value) / 1000)
        },
        mask: MeasurementMask.SecondLeg
      };
    this.measurements.push(mesurement);
  }

  public async buildTemplate(): Promise<Element> {
    let shapeWithHoles: ShapeWithHoles = {
      holes: [],
      conture: LineSegmentsFromPath(this.path)
    };
    const aabb = aabbOfPolyline(shapeWithHoles.conture);
    const offset = {x: -aabb.min.x, y: -aabb.min.y, z: 0};
    shapeWithHoles.conture = movePolyline(shapeWithHoles.conture, offset);

    const bendingLines: BendingLine[] = this.bendingLines.map( bl => {
      return {
        ...bl,
        begin: addVectors2(bl.begin, offset),
        end: addVectors2(bl.end, offset),
      };
    });

    const measurements: MeasurementModel[] = this.measurements.map( m => {
      return {
        ...m,
        start: addVectors3(m.start, offset)
      };
    });

    // this will clean up design (marge segments)
    const cleaned: MevacoResponse<ShapeWithHoles[]>  = await this.plateService.applyBooleanOperation({
      perforationAreas: [],
      plateShape: [shapeWithHoles],
      clip: null,
      bendingLines: [],
      boundary: null,
      operation: 'difference',
      singleShape: true,
      subjectType: 'Plate'
    }).toPromise();
    shapeWithHoles = cleaned.data[0];
    shapeWithHoles.aabb = aabbOfPolyline(shapeWithHoles.conture);
    shapeWithHoles.area = PolylineArea(shapeWithHoles.conture);
    shapeWithHoles.hash = shapeWithHolesHash(shapeWithHoles);


    const size = subVectors2(shapeWithHoles.aabb.max, shapeWithHoles.aabb.min);
    function round(v: number): number {
      return +((v * 1000).toFixed(2));
    }
    const aIst = round(size.y );
    const bIst =  round(size.x );

    const element: Element = {
      type: ElementType.individual,
      templateName: TemplateName.singleBend,
      boundary: shapeWithHoles.conture,
      position: -1, // will be set in reducer
      quantity: 1,
      a: aIst,
      b: bIst,
      aIst: aIst,
      bIst: bIst,
      e1: '0',
      e1ist: '0',
      e2: '0',
      e2ist: '0',
      f1: '0',
      f1ist: '0',
      f2: '0',
      f2ist: '0',
      openMeshE: '',
      openMeshF: '',
      toleranceWidth: 0,
      toleranceLength: 0,
      label: '',
      note: '',
      unperforated: false,
      posibleCoil: 'No',
      posiblePlate: 'No',
      shapes: null,
      nodes: null,
      verticesIndexes: null,
      visualizationShape: null,
      previewImageId: Guid.create().toString(),
      shape: shapeWithHoles,
      perforationAreas: [],
      helpLines: [],
      measurements: measurements,
      angleMeasurements: [],
      mountings: [],
      perforationAutoCenter: true,
      area: shapeWithHoles.area,
      minRadius: null,
      minParallelEdgesDistance: null,
      minMarginDistance: null,
      isPerforationSimpleRect: null,
      isShapeSimpleRect: null,
      minMountingHoleEdgeDistance: null,
      minMountingHolesDistance: null,
      minOutsideAngle: null,
      numberOfArcs: null,
      minDistanceBetweenEdges: null,
      minDistanceBetweenMountingAndPerforatedArea: null,
      // perforation: null,
      possibleAllAcross: true,
      minDistanceBetweenPerforationAreas: null,
      lfbIst: undefined,
      lflIst: undefined,
      breakLines: bendingLines,
      bendingLinesDistances: [],
      //side: 'ridge side'
    };
    return element;
  }

}
