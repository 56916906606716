import { Component, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { ContextMenuComponent } from "@perfectmemory/ngx-contextmenu";
import { Subscription } from "rxjs";
import { TranslationProvider } from "../../providers/translation.provider";
import { MevacoState, getSelectedShapeId } from "../../store/reducers";

@Component({
  selector: "selected-shape-context-menu",
  templateUrl: "./selected-shape-context-menu.component.html",
  styleUrls: ["./selected-shape-context-menu.component.css"],
})
export class SelectedShapeContextMenuComponent implements OnInit {
  @ViewChild("basicMenu", { static: true })
  public basicMenu: ContextMenuComponent<any>; // TODO fix type

  public selectedShapeId: number;
  private selectedShapeSubscription: Subscription;
  constructor(
    private store: Store<MevacoState>,
    private translationProvider: TranslationProvider
  ) {
    this.selectedShapeId = null;
  }

  ngOnInit() {
    this.selectedShapeSubscription = this.store
      .select(getSelectedShapeId)
      .subscribe((id) => {
        this.selectedShapeId = id;
      });
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}
