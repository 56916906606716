<div class="custom-pattern-card__container">
  <div>


    <div class="custom-pattern-card__image" [ngStyle]="{'background-image': 'url(' + getPreviewImageUrl() + ')'}">
    </div>

    <div class="custom-pattern-card__btns">
      <div>
        <button type="button" class="custom-pattern-card__btn custom-pattern-card__btn--open" (click)="load()">
          <img class="custom-pattern-card__icon icon--open" src="assets/icon-open.svg" alt="icon-open">
        </button>
      </div>
      <div *ngIf="!userPattern.publicPattern || (isMevacoUser | async)">
        <button type="button" class="custom-pattern-card__btn custom-pattern-card__btn--delete" (click)="showDeleteQuestion = true">
          <img class="custom-pattern-card__icon icon--bin" src="assets/bin.svg" alt="icon-bin">
        </button>
      </div>
    </div>

    <h4 class="custom-pattern-card__name">
      {{userPattern.name}}
    </h4>

  </div>


  <div class="custom-pattern-card__delete-overlay" *ngIf="showDeleteQuestion">
    <p class="delete-overlay__question">
        {{translate('delete pattern?', 'designer')}}
    </p>
    <button class="btn btn-primary delete-overlay__btn" (click)="deleteThisPattern()">
        {{translate('delete', 'designer')}}
    </button>
    <button class="btn delete-overlay__btn-link delete-overlay__btn" (click)="showDeleteQuestion = false">
        {{translate('cancel', 'designer')}}
    </button>
  </div>
</div>
