import {SegmentType, Vector2} from 'webcad';
import {Aabb2, deepCopyPolyline, getShortestPathBetweenLineSegments, getShortestPathBetweenSegments, Segment} from 'webcad/models';
import * as hash from 'object-hash';
import {CosOfAngleBetween2Vectors2D, crossVector2, dotVector2, fModF, lengthVector2, normalizeVector2, subVectors2} from 'webcad/math';
import {
  getShortestPathBetweenTwoPolylines,
  projectPointOnSegment,
  margeTooSmallSegmentInPolyline,
  segmentLength
} from 'webcad/models';
import {distanceVector2, sqrDistanceVector2} from 'webcad/math';

export interface ShapeWithHoles {
  conture: Segment[];
  holes: Segment[][];
  area?: number;
  holesArea?: number;
  aabb?: Aabb2;
  hash?: string;
}

export function createRectangle(min: Vector2, max: Vector2): Segment[] {
  return [
    {begin: {x: min.x, y: min.y}, end: {x: max.x, y: min.y}, type: SegmentType.line},
    {begin: {x: max.x, y: min.y}, end: {x: max.x, y: max.y}, type: SegmentType.line},
    {begin: {x: max.x, y: max.y}, end: {x: min.x, y: max.y}, type: SegmentType.line},
    {begin: {x: min.x, y: max.y}, end: {x: min.x, y: min.y}, type: SegmentType.line}
  ];
}

export function foreachSegment(shapeWithHoles: ShapeWithHoles, callback: (segment: Segment) => void) {
  for (const s of shapeWithHoles.conture) {
    callback(s);
  }
  for (const c of shapeWithHoles.holes) {
    for (const s of c) {
      callback(s);
    }
  }
}

export function anySegment(shapeWithHoles: ShapeWithHoles, callback: (segment: Segment) => boolean): boolean {
  for (const s of shapeWithHoles.conture) {
    if (callback(s)) {
      return true;
    }
  }
  for (const c of shapeWithHoles.holes) {
    for (const s of c) {
      if (callback(s)) {
        return true;
      }
    }
  }
  return false;
}

export function deepCopyShapeWithHoles(shapeWithHoles: ShapeWithHoles): ShapeWithHoles {
  return {
    conture: deepCopyPolyline(shapeWithHoles.conture),
    holes: shapeWithHoles.holes.map((m) => deepCopyPolyline(m))
  };
}

export function shapeWithHolesHash(shapeWithHoles: ShapeWithHoles): string {
  return hash(shapeWithHoles.aabb) + hash( Math.trunc(shapeWithHoles.area * 10000) / 10000 );
}

export interface MinValue {
  position: Vector2;
  value: number;
}

export function areSimpleRectangles(shapes: ShapeWithHoles[]): boolean {
  const epsilon = Math.sin(0.000001 * Math.PI / 180 );
  if (!!shapes) {
    for (const shape of shapes) {
      if (shape.holes.length === 0) {
        if (shape.conture.length === 4 && shape.conture.every((v) => v.type === SegmentType.line)) {
          for (let i = 0; i < shape.conture.length; i++) {
            const s1 = shape.conture[i];
            const s2 = shape.conture[(i + 1) % shape.conture.length];
            const s1Dir = normalizeVector2(subVectors2(s1.end, s1.begin));
            const s2Dir = normalizeVector2(subVectors2(s2.end, s2.begin));
            if (Math.abs(dotVector2(s1Dir, s2Dir)) > epsilon) {
              return false;
            }
            if (Math.abs(dotVector2(s1Dir, {x: 0, y: 1})) > epsilon && Math.abs(dotVector2(s1Dir, {x: 1, y: 0})) > epsilon) {
              return false;
            }
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  }
  return false;
}


export function getMinimalRadiusOfShape(shape: ShapeWithHoles): MinValue {
  let min = Number.POSITIVE_INFINITY;
  let reult: MinValue = null;
  if (!!shape && shape.conture) {
    for (let i = 0; i < shape.conture.length; i++) {
      const segment = shape.conture[i];
      if (segment.type === SegmentType.arc && segment.radius * 1000 < min) {
        min = segment.radius * 1000;
        reult = {
          position: segment.origin,
          value: min
        };
      }
    }
    if (shape.holes) {
      for (let i = 0; i < shape.holes.length; i++) {
        const hole = shape.holes[i];
        for (let j = 0; j < hole.length; j++) {
          const segment = hole[j];
          if (segment.type === SegmentType.arc && segment.radius * 1000 < min) {
            min = segment.radius * 1000;
            reult = {
              position: segment.origin,
              value: min
            };
          }
        }

      }
    }
  }
  return reult;
}

interface Output {
  min: number;
  result: MinValue;
}

export function getMinimalParallelEdgeDistance(shape: ShapeWithHoles): MinValue {
  const output: Output = {
    min: Number.POSITIVE_INFINITY,
    result: null
  };
  if (!!shape) {
    if (shape.conture) {
      // check shape with itself
      shortestPathForParallelShapes(shape.conture, shape.holes ? shape.holes : [], output);
    }
    if (shape.holes) {
      // check holes
      for (const s of shape.holes) {
        shortestPathForParallelShapes(s, shape.holes.filter((v) => v !== s), output);
      }
    }
  }
  return output.result;
}

function shortestPathForParallelShapes(conture: Segment[], shapes: Segment[][], output: Output): void {
  for (let i = 0; i < conture.length; i++) {
    const s1 = conture[i];
    if (s1.type !== SegmentType.line || lengthVector2(subVectors2(s1.end, s1.begin)) * 1000 < 10) {
      continue;
    }
    const s1Dir = normalizeVector2(subVectors2(s1.begin, s1.end));
    for (let j = i + 2; j < conture.length; j++) {
      const s2 = conture[j];
      if (s1 !== s2) {
        shortestPathBetweenParallels(s1Dir, s1, s2, output);
      }
    }
    // check shape with holes
    if (shapes) {
      for (const shape of shapes) {
        for (const s2 of shape) {
          if (s1 !== s2) {
            shortestPathBetweenParallels(s1Dir, s1, s2, output);
          }
        }
      }
    }
  }
}

function shortestPathBetweenParallels(s1Dir: Vector2, s1: Segment, s2: Segment, output: Output): void {
  const s2Dir = subVectors2(s2.end, s2.begin);
  const s2DirNorm = normalizeVector2(s2Dir);
  const dot = dotVector2(s1Dir, s2Dir);
  if (dot >= 0 || s2.type === SegmentType.line && lengthVector2(s2Dir) * 1000 > 10) {
    const normDot = dotVector2(s1Dir, s2DirNorm);
    if (Math.abs(normDot) < 0.99984769515) {
      return;
    }
    const proj = projectPointOnSegment(s2.end, s1);
    if (!proj) {
      return;
    }
    const minDist = 0.0005 * 0.0005;
    const onLine = sqrDistanceVector2(proj, s1.begin) >= minDist && sqrDistanceVector2(proj, s1.end) >= minDist;
    if (!onLine) {
      return;
    }
    const s1BeginToS2End = normalizeVector2(subVectors2(s1.begin, s2.end));
    if (Math.abs(crossVector2(s1Dir, s1BeginToS2End)) < 0.0005) {
      return;
    }
    if (Math.abs(crossVector2(s1Dir, s2DirNorm)) < 0.05) {
      const path = getShortestPathBetweenLineSegments(s1, s2);
      const length = lengthVector2(subVectors2(path.end, path.begin));
      if (length * 1000 < output.min) {
        output.min = length * 1000;
        output.result = {
          value: length * 1000,
          position: {x: (path.end.x + path.begin.x) / 2, y: (path.end.y + path.begin.x) / 2}
        };
      }
    }
  }
}

export function getMinimalDistanceBetweenShapeWithHolesEdges(shapeWithHoles: ShapeWithHoles): MinValue {
  const output: Output = {
    min: Number.POSITIVE_INFINITY,
    result: null
  };
  if (!!shapeWithHoles) {
    checkMinDistanceForShape(shapeWithHoles.conture, shapeWithHoles.holes, output);
    for (const hole of shapeWithHoles.holes) {
      checkMinDistanceForShape(hole, shapeWithHoles.holes, output);
    }
  }
  return output.result;
}

export function getMinimalDistanceBetweenPerforationAreas(shapes: ShapeWithHoles[]): MinValue {
  const output: Output = {
    min: Number.POSITIVE_INFINITY,
    result: null
  };
  for (let i = 0; i < shapes.length - 1; i++) {
    const current = shapes[i];
    for (let j = i + 1; j < shapes.length; j++) {
      const checkWith = shapes[j];
      const path = getShortestPathBetweenTwoPolylines(current.conture, checkWith.conture);
      if (!!path) {
        const l = distanceVector2(path.begin, path.end) * 1000;
        if (l < output.min) {
          output.min = l;
          output.result = {
            value: l,
            position: {x: (path.begin.x + path.end.x) * 0.5, y: (path.begin.y + path.end.y) * 0.5}
          };
        }
      }
    }
  }
  return output.result;
}

function checkMinDistanceForShape(shape: Segment[], holes: Segment[][], output: Output) {
  for (let i = 0; i < shape.length; i++) {
    const current = shape[i];
    for (let j = i + 2; j < shape.length - 1; j++) {
      const next = shape[j];
      const path = getShortestPathBetweenSegments(current, next);
      const l = lengthVector2(subVectors2(path.end, path.begin)) * 1000;
      if (l < output.min) {
        output.min = l;
        output.result = {
          value: l,
          position: {x: (path.end.x + path.begin.x) / 2, y: (path.end.y + path.begin.y) / 2}
        };
      }
    }
    if (!!holes) {
      for (const hole of holes) {
        if (hole === shape) {
          continue;
        }
        for (const next of hole) {
          const path = getShortestPathBetweenSegments(current, next);
          const l = lengthVector2(subVectors2(path.end, path.begin)) * 1000;
          if (l < output.min) {
            output.min = l;
            output.result = {
              value: l,
              position: {x: (path.end.x + path.begin.x) / 2, y: (path.end.y + path.begin.y) / 2}
            };
          }
        }
      }
    }
  }
}

export function getNumberOfArcsInShapeWithHoles(shapeWithHoles: ShapeWithHoles) {
  if (!!shapeWithHoles) {
    let n = 0;
    for (const s of shapeWithHoles.conture) {
      if (s.type === SegmentType.arc) {
        n++;
      }
    }
    if (!!shapeWithHoles.holes) {
      for (const hole of shapeWithHoles.holes) {
        for (const s of hole) {
          if (s.type === SegmentType.arc) {
            n++;
          }
        }
      }
    }
    return n;
  }
  return null;
}
/*
export function calculateAabbOfShapeWithHoles(shapeWithHoles:ShapeWithHoles):Aabb2 {
  const aabb = getEmptyAaabb();
  for(var segment of shapeWithHoles.conture)
  {
     mergeAabbInPlace(aabb, )
  }
}
*/


export function hasTooSmallSegment(shape: ShapeWithHoles, threshold: number = 0.00005): boolean {
  return anySegment(shape, (segment) => segmentLength(segment) < threshold);
}

export function margeTooSmallSegment(shape: ShapeWithHoles, threshold: number = 0.00005): ShapeWithHoles {
  const newShape: ShapeWithHoles = {
    ...shape,
    conture: margeTooSmallSegmentInPolyline( shape.conture, threshold ),
    holes: shape.holes.map( hole => margeTooSmallSegmentInPolyline( hole, threshold ) )
  };
  return newShape;
}

export function getMinSegmentLength(shapeWithHoles: ShapeWithHoles): MinValue {
  const output: Output = {
    min: Number.POSITIVE_INFINITY,
    result: null
  };
  if (!!shapeWithHoles) {
    const callback = (segment: Segment) => {
      const l = segmentLength(segment);
      if( l < output.min) {
        output.min = l,
        output.result = {
          value: l * 1000,
          position: segment.begin
        };
      }
    };
    shapeWithHoles.conture.forEach( callback );
    shapeWithHoles.holes.forEach( hole => hole.forEach(callback ));
  }
  return output.result;
}

