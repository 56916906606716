import {MevacoState} from './reducers';
import {Store} from '@ngrx/store';
import {Drawing, ShapeOrigin} from '../model/drawing.model';
import {first} from 'rxjs/operators';
import {createNode, deepCopyPerforationAreas, MoveNodeType, Plate, PointNode} from '../model';
import {deepCopyShapeWithHoles, ShapeWithHoles, shapeWithHolesHash} from '../model/shape-with-holes';
import {
  addVectors2,
  addVectors3,
  CircleCircleIntersect2D,
  copyVector2,
  crossVector2,
  distanceVector2,
  lengthVector2,
  SinOfAngleBetween2Vectors2D,
  sqrDistanceVector2,
  subVectors2,
  Vector3
} from 'webcad/math';
import {circleIntersection} from '../utils/math.utils';
import {Segment, SegmentType} from 'webcad/models';
import {PerforationAreaModel} from "../model/perforation-area.model";
import {cloneObject} from '../utils/utils';
import {Vector2} from "webcad/math";
import {aabbOfPolyline, getEmptyAaabb, mergeAabbInPlace} from "webcad/models";
import {multiplyVector2byScalar} from "webcad/math";


let segmentUpdateQueue: {segment: Segment, update: any}[] = [];


function updateSegment(segment: Segment): Segment {
  const segUpdates = segmentUpdateQueue.filter( u => u.segment === segment);
  for (let i = 0; i < segUpdates.length; i++) {
    const segUpdate = segUpdates[i];
    segment = {
      ...segment,
      ...segUpdate.update
    };
  }
  return segment;
}

function updatePolyline(polyline: Segment[]): Segment[] {
  const newPolyline: Segment[] = [];
  let anyChange = false;
  for (let i = 0; i < polyline.length; i++) {
    const segment = polyline[i];
    const newSegment = updateSegment(segment);
    if (segment !== newSegment) {
      anyChange = true;
    }
    newPolyline.push(newSegment);
  }
  if (anyChange) {
    return newPolyline;
  }
  return polyline;
}

function updatePolylines(polylines: Segment[][]): Segment[][] {
  const newPolylines: Segment[][] = [];
  let anyChange = false;
  for (let i = 0; i < polylines.length; i++) {
    const polyline = polylines[i];
    const newPolyline = updatePolyline(polyline);
    if (polyline !== newPolyline) {
      anyChange = true;
    }
    newPolylines.push(newPolyline);
  }
  if (anyChange) {
    return newPolylines;
  }
  return polylines;
}

function updateShapeWithHoles(shapeWithHoles: ShapeWithHoles): ShapeWithHoles {
  const newConture = updatePolyline(shapeWithHoles.conture);
  const newHoles = updatePolylines(shapeWithHoles.holes);
  if (newConture !== shapeWithHoles.conture || newHoles !== shapeWithHoles.holes) {
    return {
      ...shapeWithHoles,
      conture: newConture,
      holes: newHoles,
    };
  }
  return  shapeWithHoles;
}

export function applySegmentsMove(plate: Plate): Plate {
  const newShapeWithHoles = updateShapeWithHoles(plate.shapeWithHoles);
  const newPerforationAreas: PerforationAreaModel[] = [];
  let anyPerfChanged = false;
  for (let i = 0; i < plate.perforationAreas.length; i++) {
    const perforationArea = plate.perforationAreas[i];
    const newPerforationArea = {...perforationArea}
    newPerforationArea.shape = updateShapeWithHoles(perforationArea.shape);
    newPerforationAreas.push(newPerforationArea);
    if (newPerforationArea.shape !== perforationArea.shape) {
      anyPerfChanged = true;
    }
  }
  if (newShapeWithHoles !== plate.shapeWithHoles || anyPerfChanged){
    return {
      ...plate,
      shapeWithHoles: newShapeWithHoles,
      perforationAreas: newPerforationAreas
    };
  }
  segmentUpdateQueue = [];
}

export async function copyDrawingState(store: Store<MevacoState>): Promise<Drawing> {
  const state = await store.pipe(first()).toPromise();
  const drawing = state.model.drawing;
  const copy: Drawing = {
    ...drawing,
    nodes: state.model.drawing.nodes.map((v) => cloneObject(v)),
    plate: {
      ...drawing.plate,
      shapeWithHoles: deepCopyShapeWithHoles(drawing.plate.shapeWithHoles),
      perforationAreas: deepCopyPerforationAreas( drawing.plate.perforationAreas )
    }
  };
  return copy;
}

const minDistEpsilon = 0.000001;

export function registerShapePolyline(c: Segment, retState: Drawing) {
  const z = retState.plate.depth / 2;
  let beginNode: PointNode = null;
  let endNode: PointNode = null;
  let isCircle = false;
  let modified = false;
  if (c.begin.x === c.end.x && c.begin.y === c.end.y && c.type === SegmentType.arc.toString()) {
    isCircle = true;
  }
  if (!isCircle) {
    retState.nodes.forEach((v, k, m) => {
      if (v.type !== MoveNodeType.bias && v.origin === ShapeOrigin.SHAPE &&
        sqrDistanceVector2(v.position, c.begin) < minDistEpsilon) {
        beginNode = v;
      }
      if (v.type !== MoveNodeType.bias && v.origin === ShapeOrigin.SHAPE &&
        sqrDistanceVector2(v.position, c.end) < minDistEpsilon) {
        endNode = v;
      }
    });
    if (beginNode === null) {
      beginNode = createNode({x: c.begin.x, y: c.begin.y, z: z}, ShapeOrigin.SHAPE);
      if (beginNode.type === null) {
        beginNode.type = MoveNodeType.normal;
      }
      retState.nodes.push(beginNode);
    }
    if (endNode === null) {
      endNode = createNode({x: c.end.x, y: c.end.y, z: z}, ShapeOrigin.SHAPE);
      if (endNode.type === null) {
        endNode.type = MoveNodeType.normal;
      }
      retState.nodes.push(endNode);
    }
    // retState.plate.verticesIndexes.push(beginNode.index);
    let localCopy: Segment = {...c, begin: copyVector2(c.begin), end: copyVector2(c.end)};
    if (c.type === SegmentType.line.toString()) {
      beginNode.onChangeCallbacks.push(
        (moveVec: Vector3) => {
          //c.begin = addVectors2(c.begin, moveVec);
          segmentUpdateQueue.push({
            segment: c,
            update: {begin: addVectors2(c.begin, moveVec)}
          });
        }
      );
      endNode.onChangeCallbacks.push(
        (moveVec: Vector3) => {
          //c.end = addVectors2(c.end, moveVec);
          segmentUpdateQueue.push({
            segment: c,
            update: {end: addVectors2(c.end, moveVec)}
          });
        }
      );
    } else {
      beginNode.onChangeCallbacks.push(
        (moveVec: Vector3) => {
          if (modified) {
            localCopy = {...c, begin: copyVector2(c.begin), end: copyVector2(c.end)};
            modified = false;
          }
          const newPos = addVectors2(localCopy.begin, moveVec);
          const oldDist = Math.abs(distanceVector2(localCopy.begin, localCopy.end));
          const newDist = Math.abs(distanceVector2(newPos, localCopy.end));
          const distRatio = newDist / oldDist;
          const newR = localCopy.radius * distRatio;
          const cross = crossVector2(subVectors2(localCopy.end, localCopy.begin), subVectors2(localCopy.origin, localCopy.begin));
          let newOrigin = circleIntersection(newPos.x, newPos.y, newR, localCopy.end.x, localCopy.end.y, newR, cross >= 0);
          if (newOrigin === null) {
            newOrigin = {x: (localCopy.begin.x + newPos.x) / 2.0, y: (localCopy.begin.y + newPos.y) / 2.0};
          }
          const dirToBegin = subVectors2(newPos, newOrigin);
          let newBeginAngle = Math.atan2(dirToBegin.y, dirToBegin.x);
          const dirToEnd = subVectors2(localCopy.end, newOrigin);
          let newEndAngle = Math.atan2(dirToEnd.y, dirToEnd.x);
          const clockwise = localCopy.beginAngle > localCopy.endAngle;
          if (!clockwise && newBeginAngle >= newEndAngle) {
            newEndAngle += Math.PI * 2;
          }
          if (clockwise && newBeginAngle <= newEndAngle) {
            newBeginAngle += Math.PI * 2;
          }
          // c.begin = newPos;
          // c.origin = newOrigin;
          // c.beginAngle = newBeginAngle;
          // c.endAngle = newEndAngle;
          // c.radius = newR;
          segmentUpdateQueue.push({
            segment: c,
            update: {
              begin: newPos,
              origin: newOrigin,
              beginAngle: newBeginAngle,
              endAngle: newEndAngle,
              radius: newR,
            }
          });

          modified = true;
        }
      );
      endNode.onChangeCallbacks.push(
        (moveVec: Vector3) => {
          if (modified) {
            localCopy = {...c, begin: copyVector2(c.begin), end: copyVector2(c.end)};
            modified = false;
          }
          const newPos = addVectors2(c.end, moveVec);
          const oldDist = Math.abs(distanceVector2(localCopy.begin, localCopy.end));
          const newDist = Math.abs(distanceVector2(localCopy.begin, newPos));
          const distRatio = newDist / oldDist;
          const newR = localCopy.radius * distRatio;
          const cross = crossVector2(subVectors2(localCopy.end, localCopy.begin), subVectors2(localCopy.origin, localCopy.begin));
          let newOrigin = circleIntersection(localCopy.begin.x, localCopy.begin.y, newR, newPos.x, newPos.y, newR, cross >= 0);
          if (newOrigin === null) {
            newOrigin = {x: (localCopy.begin.x + newPos.x) / 2.0, y: (localCopy.begin.y + newPos.y) / 2.0};
          }
          const dirToEnd = subVectors2(newPos, newOrigin);
          let newEndAngle = Math.atan2(dirToEnd.y, dirToEnd.x);
          const dirToBegin = subVectors2(localCopy.begin, newOrigin);
          let newBeginAngle = Math.atan2(dirToBegin.y, dirToBegin.x);
          const clockwise = localCopy.beginAngle > localCopy.endAngle;
          if (!clockwise && newBeginAngle >= newEndAngle) {
            newEndAngle += Math.PI * 2;
          }
          if (clockwise && newBeginAngle <= newEndAngle) {
            newBeginAngle += Math.PI * 2;
          }
          // c.end = newPos;
          // c.origin = newOrigin;
          // c.endAngle = newEndAngle;
          // c.beginAngle = newBeginAngle;
          // c.radius = newR;
          segmentUpdateQueue.push({
            segment: c,
            update: {
              end: newPos,
              origin: newOrigin,
              endAngle: newEndAngle,
              beginAngle: newBeginAngle,
              radius: newR
            }
          });
          modified = true;
        }
      );
    }
  }
  if (isCircle || c.type === SegmentType.arc.toString()) {
    // TODO origin node setup
    let originNode: PointNode = null;
    let biasNode: PointNode = null;
    const biasNodeAngle = (c.endAngle + c.beginAngle) * 0.5;
    const biasPosition = {
      x: c.origin.x + c.radius * Math.cos(biasNodeAngle),
      y: c.origin.y + c.radius * Math.sin(biasNodeAngle),
      z: z
    };
    for (const v of retState.nodes) {
      if (v.type !== MoveNodeType.bias && v.origin === ShapeOrigin.SHAPE &&
        sqrDistanceVector2(v.position, c.origin) < minDistEpsilon) {
        originNode = v;
      }
      if (v.type === MoveNodeType.bias && v.origin === ShapeOrigin.SHAPE &&
        sqrDistanceVector2(v.position, biasPosition) < minDistEpsilon) {
        biasNode = v;
      }
      if (originNode && biasNode) {
        break;
      }
    }
    if (originNode === null) {
      originNode = createNode({x: c.origin.x, y: c.origin.y, z: z}, ShapeOrigin.SHAPE);
      if (originNode.type === null) {
        originNode.type = MoveNodeType.origin;
      }
      retState.nodes.push(originNode);
    }
    if (biasNode === null) {
      biasNode = createNode(biasPosition, ShapeOrigin.SHAPE);
      biasNode.origin = ShapeOrigin.SHAPE;
      if (biasNode.type === null) {
        biasNode.type = MoveNodeType.bias;
      }
      retState.nodes.push(biasNode);
    }
    if (isCircle) {
      originNode.onChangeCallbacks.push(
        (moveVec: Vector3) => {
          const originToBegin = subVectors2(c.begin, c.origin);
          const originToEnd = subVectors2(c.end, c.origin);
          c.origin = addVectors2(c.origin, moveVec);
          c.end = addVectors2(c.origin, originToEnd);
          c.begin = addVectors2(c.origin, originToBegin);
        }
      );
      biasNode.onChangeCallbacks.push(
        (moveVec: Vector3) => {

        }
      );
    } else if (c.type === SegmentType.arc.toString()) {
      originNode.onChangeCallbacks.push(
        (moveVec: Vector3) => {
          // c.origin = newPos;
          // c.radius = Math.abs(distanceVector2(c.origin, c.begin));
          // const dirToBegin = subVectors2(c.begin, c.origin);
          // const dirToEnd = subVectors2(c.end, c.origin);
          // const clockwise = c.beginAngle > c.endAngle;
          // let newBeginAngle = Math.atan2(dirToBegin.y, dirToBegin.x);
          // let newEndAngle = Math.atan2(dirToEnd.y, dirToEnd.x);
          // if (!clockwise && newBeginAngle >= newEndAngle) {
          //   newEndAngle += Math.PI * 2;
          // }
          // if (clockwise && newBeginAngle <= newEndAngle) {
          //   newBeginAngle += Math.PI * 2;
          // }
          // c.beginAngle = newBeginAngle;
          // c.endAngle = newEndAngle;
        }
      );
      biasNode.onChangeCallbacks.push(
        (moveVec: Vector3) => {
          const newBias = addVectors3(biasNode.position, moveVec);
          const vecToBeginFromNewBias = subVectors2(c.begin, newBias);
          const vecToEndFromNewBias = subVectors2(c.end, newBias);
          let sinAngle = SinOfAngleBetween2Vectors2D(vecToBeginFromNewBias, vecToEndFromNewBias);
          if (sinAngle < 0) {
            sinAngle = SinOfAngleBetween2Vectors2D(vecToEndFromNewBias, vecToBeginFromNewBias);
          }
          const counterSide = lengthVector2(subVectors2(c.end, c.begin));
          const newRadius = counterSide / (2 * sinAngle);
          const intersections = CircleCircleIntersect2D(c.begin.x, c.begin.y, newRadius, c.end.x, c.end.y, newRadius);
          if (intersections.length !== 2) {
            return;
          }
          const inter0dist = Math.abs(distanceVector2(intersections[0], newBias) - newRadius);
          const inter1dist = Math.abs(distanceVector2(intersections[1], newBias) - newRadius);
          const newOrigin = inter0dist < inter1dist ?
            intersections[0] : intersections[1];
          const dirToEnd = subVectors2(c.end, newOrigin);
          let newEndAngle = Math.atan2(dirToEnd.y, dirToEnd.x);
          const dirToBegin = subVectors2(c.begin, newOrigin);
          let newBeginAngle = Math.atan2(dirToBegin.y, dirToBegin.x);
          const beginToEnd = subVectors2(c.end, c.begin);
          const beginToOldBias = subVectors2(biasNode.position, c.begin);
          const side = crossVector2(beginToEnd, beginToOldBias);
          const beginToNewBias = subVectors2(newBias, c.begin);
          const newSide = crossVector2(beginToEnd, beginToNewBias);
          const onOtherSide = newSide * side < 0;
          const wasClockwise = c.beginAngle > c.endAngle;
          const clockwise = (wasClockwise || onOtherSide) && !(wasClockwise && onOtherSide);
          if (!clockwise && newBeginAngle >= newEndAngle) {
            newEndAngle += Math.PI * 2;
          }
          if (clockwise && newBeginAngle <= newEndAngle) {
            newBeginAngle += Math.PI * 2;
          }
          // c.origin = newOrigin;
          // c.endAngle = newEndAngle;
          // c.beginAngle = newBeginAngle;
          // c.radius = newRadius;
          segmentUpdateQueue.push({
            segment: c,
            update: {
              origin: newOrigin,
              endAngle: newEndAngle,
              beginAngle: newBeginAngle,
              radius: newRadius,
            }
          });
          modified = true;
        }
      );
    }
  }
}

export function registerPerforationPolyline(c: Segment, retState: Drawing) {
  const z = retState.plate.depth / 2;
  let beginNode: PointNode = null;
  let endNode: PointNode = null;
  let isCircle = false;
  let modified = false;
  if (c.begin.x === c.end.x && c.begin.y === c.end.y && c.type === SegmentType.arc.toString()) {
    isCircle = true;
  }
  if (!isCircle) {
    retState.nodes.forEach((v, k, m) => {
      if (v.type !== MoveNodeType.bias && v.origin === ShapeOrigin.PERFORATION &&
        sqrDistanceVector2(v.position, c.begin) < minDistEpsilon) {
        beginNode = v;
      }
      if (v.type !== MoveNodeType.bias && v.origin === ShapeOrigin.PERFORATION &&
        sqrDistanceVector2(v.position, c.end) < minDistEpsilon) {
        endNode = v;
      }
    });
    if (beginNode === null) {
      beginNode = createNode({x: c.begin.x, y: c.begin.y, z: z}, ShapeOrigin.PERFORATION);
      beginNode.origin = ShapeOrigin.PERFORATION;
      if (beginNode.type === null) {
        beginNode.type = MoveNodeType.normal;
      }
      retState.nodes.push(beginNode);
    }
    if (endNode === null) {
      endNode = createNode({x: c.end.x, y: c.end.y, z: z}, ShapeOrigin.PERFORATION);
      endNode.origin = ShapeOrigin.PERFORATION;
      if (endNode.type === null) {
        endNode.type = MoveNodeType.normal;
      }
      retState.nodes.push(endNode);
    }
    // retState.plate.verticesIndexes.push(beginNode.index);
    let localCopy: Segment = {...c, begin: copyVector2(c.begin), end: copyVector2(c.end)};
    if (c.type === SegmentType.line.toString()) {
      beginNode.onChangeCallbacks.push(
        (moveVec: Vector3) => {
          //c.begin = addVectors2(c.begin, moveVec);
          segmentUpdateQueue.push({
            segment: c,
            update: {
              begin: addVectors2(c.begin, moveVec)
            }
          });
        }
      );
      endNode.onChangeCallbacks.push(
        (moveVec: Vector3) => {
          //c.end = addVectors2(c.end, moveVec);
          segmentUpdateQueue.push({
            segment: c,
            update: {
              end: addVectors2(c.end, moveVec)
            }
          });
        }
      );
    } else {
      beginNode.onChangeCallbacks.push(
        (moveVec: Vector3) => {
          if (modified) {
            localCopy = {...c, begin: copyVector2(c.begin), end: copyVector2(c.end)};
            modified = false;
          }
          const newPos = addVectors2(localCopy.begin, moveVec);
          const oldDist = Math.abs(distanceVector2(localCopy.begin, localCopy.end));
          const newDist = Math.abs(distanceVector2(newPos, localCopy.end));
          const distRatio = newDist / oldDist;
          const newR = localCopy.radius * distRatio;
          const cross = crossVector2(subVectors2(localCopy.end, localCopy.begin), subVectors2(localCopy.origin, localCopy.begin));
          let newOrigin = circleIntersection(newPos.x, newPos.y, newR, localCopy.end.x, localCopy.end.y, newR, cross >= 0);
          if (newOrigin === null) {
            newOrigin = {x: (localCopy.begin.x + newPos.x) / 2.0, y: (localCopy.begin.y + newPos.y) / 2.0};
          }
          const dirToBegin = subVectors2(newPos, newOrigin);
          let newBeginAngle = Math.atan2(dirToBegin.y, dirToBegin.x);
          const dirToEnd = subVectors2(localCopy.end, newOrigin);
          let newEndAngle = Math.atan2(dirToEnd.y, dirToEnd.x);
          const clockwise = localCopy.beginAngle > localCopy.endAngle;
          if (!clockwise && newBeginAngle >= newEndAngle) {
            newEndAngle += Math.PI * 2;
          }
          if (clockwise && newBeginAngle <= newEndAngle) {
            newBeginAngle += Math.PI * 2;
          }
          // c.begin = newPos;
          // c.origin = newOrigin;
          // c.beginAngle = newBeginAngle;
          // c.endAngle = newEndAngle;
          // c.radius = newR;
          segmentUpdateQueue.push({
            segment: c,
            update: {
              begin: newPos,
              origin: newOrigin,
              beginAngle: newBeginAngle,
              endAngle: newEndAngle,
              radius: newR,
            }
          });

          modified = true;
        }
      );
      endNode.onChangeCallbacks.push(
        (moveVec: Vector3) => {
          if (modified) {
            localCopy = {...c, begin: copyVector2(c.begin), end: copyVector2(c.end)};
            modified = false;
          }
          const newPos = addVectors2(c.end, moveVec);
          const oldDist = Math.abs(distanceVector2(localCopy.begin, localCopy.end));
          const newDist = Math.abs(distanceVector2(localCopy.begin, newPos));
          const distRatio = newDist / oldDist;
          const newR = localCopy.radius * distRatio;
          const cross = crossVector2(subVectors2(localCopy.end, localCopy.begin), subVectors2(localCopy.origin, localCopy.begin));
          let newOrigin = circleIntersection(localCopy.begin.x, localCopy.begin.y, newR, newPos.x, newPos.y, newR, cross >= 0);
          if (newOrigin === null) {
            newOrigin = {x: (localCopy.begin.x + newPos.x) / 2.0, y: (localCopy.begin.y + newPos.y) / 2.0};
          }
          const dirToEnd = subVectors2(newPos, newOrigin);
          let newEndAngle = Math.atan2(dirToEnd.y, dirToEnd.x);
          const dirToBegin = subVectors2(localCopy.begin, newOrigin);
          let newBeginAngle = Math.atan2(dirToBegin.y, dirToBegin.x);
          const clockwise = localCopy.beginAngle > localCopy.endAngle;
          if (!clockwise && newBeginAngle >= newEndAngle) {
            newEndAngle += Math.PI * 2;
          }
          if (clockwise && newBeginAngle <= newEndAngle) {
            newBeginAngle += Math.PI * 2;
          }
          // c.end = newPos;
          // c.origin = newOrigin;
          // c.endAngle = newEndAngle;
          // c.beginAngle = newBeginAngle;
          // c.radius = newR;
          segmentUpdateQueue.push({
            segment: c,
            update: {
              end: newPos,
              origin: newOrigin,
              endAngle: newEndAngle,
              beginAngle: newBeginAngle,
              radius: newR,
            }
          });
          modified = true;
        }
      );
    }
  }
  if (isCircle || c.type === SegmentType.arc.toString()) {
    // TODO origin node setup
    let originNode: PointNode = null;
    let biasNode: PointNode = null;
    const biasNodeAngle = (c.endAngle + c.beginAngle) * 0.5;
    const biasPosition = {
      x: c.origin.x + c.radius * Math.cos(biasNodeAngle),
      y: c.origin.y + c.radius * Math.sin(biasNodeAngle),
      z: z
    };
    retState.nodes.forEach((v, k, m) => {
      if (v.type !== MoveNodeType.bias && v.origin === ShapeOrigin.PERFORATION &&
        sqrDistanceVector2(v.position, c.origin) < minDistEpsilon) {
        originNode = v;
      }
      if (v.type === MoveNodeType.bias && v.origin === ShapeOrigin.PERFORATION &&
        sqrDistanceVector2(v.position, biasPosition) < minDistEpsilon) {
        biasNode = v;
      }
    });
    if (originNode === null) {
      originNode = createNode({x: c.origin.x, y: c.origin.y, z: z}, ShapeOrigin.PERFORATION);
      originNode.origin = ShapeOrigin.PERFORATION;
      if (originNode.type === null) {
        originNode.type = MoveNodeType.origin;
      }
      retState.nodes.push(originNode);
    }
    if (biasNode === null) {
      biasNode = createNode(biasPosition, ShapeOrigin.PERFORATION);
      biasNode.origin = ShapeOrigin.PERFORATION;
      if (biasNode.type === null) {
        biasNode.type = MoveNodeType.bias;
      }
      retState.nodes.push(biasNode);
    }
    if (isCircle) {
      originNode.onChangeCallbacks.push(
        (moveVec: Vector3) => {
          const originToBegin = subVectors2(c.begin, c.origin);
          const originToEnd = subVectors2(c.end, c.origin);
          // c.origin = addVectors2(c.origin, moveVec);
          // c.end = addVectors2(c.origin, originToEnd);
          // c.begin = addVectors2(c.origin, originToBegin);
          segmentUpdateQueue.push({
            segment: c,
            update: {
              origin: addVectors2(c.origin, moveVec),
              end: addVectors2(c.origin, originToEnd),
              begin: addVectors2(c.origin, originToBegin),
            }
          });

        }
      );
      biasNode.onChangeCallbacks.push(
        (moveVec: Vector3) => {

        }
      );
    } else if (c.type === SegmentType.arc.toString()) {
      originNode.onChangeCallbacks.push(
        (moveVec: Vector3) => {
          // c.origin = newPos;
          // c.radius = Math.abs(distanceVector2(c.origin, c.begin));
          // const dirToBegin = subVectors2(c.begin, c.origin);
          // const dirToEnd = subVectors2(c.end, c.origin);
          // const clockwise = c.beginAngle > c.endAngle;
          // let newBeginAngle = Math.atan2(dirToBegin.y, dirToBegin.x);
          // let newEndAngle = Math.atan2(dirToEnd.y, dirToEnd.x);
          // if (!clockwise && newBeginAngle >= newEndAngle) {
          //   newEndAngle += Math.PI * 2;
          // }
          // if (clockwise && newBeginAngle <= newEndAngle) {
          //   newBeginAngle += Math.PI * 2;
          // }
          // c.beginAngle = newBeginAngle;
          // c.endAngle = newEndAngle;
        }
      );
      biasNode.onChangeCallbacks.push(
        (moveVec: Vector3) => {
          const newBias = addVectors3(biasNode.position, moveVec);
          const vecToBeginFromNewBias = subVectors2(c.begin, newBias);
          const vecToEndFromNewBias = subVectors2(c.end, newBias);
          let sinAngle = SinOfAngleBetween2Vectors2D(vecToBeginFromNewBias, vecToEndFromNewBias);
          if (sinAngle < 0) {
            sinAngle = SinOfAngleBetween2Vectors2D(vecToEndFromNewBias, vecToBeginFromNewBias);
          }
          const counterSide = lengthVector2(subVectors2(c.end, c.begin));
          const newRadius = counterSide / (2 * sinAngle);
          const intersections = CircleCircleIntersect2D(c.begin.x, c.begin.y, newRadius, c.end.x, c.end.y, newRadius);
          if (intersections.length !== 2) {
            return;
          }
          const inter0dist = Math.abs(distanceVector2(intersections[0], newBias) - newRadius);
          const inter1dist = Math.abs(distanceVector2(intersections[1], newBias) - newRadius);
          const newOrigin = inter0dist < inter1dist ?
            intersections[0] : intersections[1];
          const dirToEnd = subVectors2(c.end, newOrigin);
          let newEndAngle = Math.atan2(dirToEnd.y, dirToEnd.x);
          const dirToBegin = subVectors2(c.begin, newOrigin);
          let newBeginAngle = Math.atan2(dirToBegin.y, dirToBegin.x);
          const beginToEnd = subVectors2(c.end, c.begin);
          const beginToOldBias = subVectors2(biasNode.position, c.begin);
          const side = crossVector2(beginToEnd, beginToOldBias);
          const beginToNewBias = subVectors2(newBias, c.begin);
          const newSide = crossVector2(beginToEnd, beginToNewBias);
          const onOtherSide = newSide * side < 0;
          const wasClockwise = c.beginAngle > c.endAngle;
          const clockwise = (wasClockwise || onOtherSide) && !(wasClockwise && onOtherSide);
          if (!clockwise && newBeginAngle >= newEndAngle) {
            newEndAngle += Math.PI * 2;
          }
          if (clockwise && newBeginAngle <= newEndAngle) {
            newBeginAngle += Math.PI * 2;
          }
          // c.origin = newOrigin;
          // c.endAngle = newEndAngle;
          // c.beginAngle = newBeginAngle;
          // c.radius = newRadius;
          segmentUpdateQueue.push({
            segment: c,
            update: {
              origin: newOrigin,
              endAngle: newEndAngle,
              beginAngle: newBeginAngle,
              radius: newRadius,
            }
          });
          modified = true;
        }
      );
    }
  }
}

export function updateDrawingShape(state: Drawing, oldShapeWithHoles: ShapeWithHoles): Drawing {
  const retState: Drawing = {
    ...state,
    selectedNodes: [],
    selectedMountings: []
  };
  retState.plate = {...state.plate};
  const shapeWithHoles = cloneObject(oldShapeWithHoles);
  retState.plate.shapeWithHoles = shapeWithHoles;
  retState.nodes = retState.nodes.filter((v) => v.origin === ShapeOrigin.PERFORATION);
  if (shapeWithHoles) {
    if (shapeWithHoles.conture) {
      for (const c of shapeWithHoles.conture) {
        registerShapePolyline(c, retState);
      }
      if (shapeWithHoles.holes) {
        for (const h of shapeWithHoles.holes) {
          for (const c of h) {
            registerShapePolyline(c, retState);
          }
        }
      }
    }
    if (!shapeWithHoles.holes) {
      retState.plate.shapeWithHoles.holes = [];
    }
  } else {
    retState.plate.shapeWithHoles.conture = null;
    retState.plate.shapeWithHoles.holes = [];
  }
  return retState;
}

export function updateDrawingPerforationArea(state: Drawing, perforationShapes: ShapeWithHoles[]): Drawing {
  const retState: Drawing = {
    ...state,
    selectedNodes: [],
    selectedMountings: []
  };
  retState.plate = {...state.plate};
  retState.plate.perforationAreas = convertShapesToPerfAreas(perforationShapes, state.plate.perforationAreas);
  if(!retState.plate.defaultPerforationAreaOffset && state.plate.shapeWithHoles.conture.length > 0){
    var aabb = aabbOfPolyline(state.plate.shapeWithHoles.conture);
    retState.plate.defaultPerforationAreaOffset = multiplyVector2byScalar(aabb.min, 1000);
  }
  retState.nodes = retState.nodes.filter((v) => v.origin === ShapeOrigin.SHAPE);
  if (retState.plate.perforationAreas) {
    for (let i = 0; i < retState.plate.perforationAreas.length; i++) {
      const perfArea = retState.plate.perforationAreas[i];
      perfArea.offset = perfArea.offset || retState.plate.defaultPerforationAreaOffset;
      const shapeWithHoles = perfArea.shape;
      if (shapeWithHoles.conture) {
        for (const c of shapeWithHoles.conture) {
          registerPerforationPolyline(c, retState);
        }
        if (shapeWithHoles.holes) {
          for (const h of shapeWithHoles.holes) {
            for (const c of h) {
              registerPerforationPolyline(c, retState);
            }
          }
        }
      }
      if (!shapeWithHoles.holes) {
        retState.plate.perforationAreas[i].shape.holes = [];
      }
    }
  } else {
    retState.plate.perforationAreas = [];
  }
  return retState;
}

export function updateDrawingShapes(state: Drawing, oldShape: ShapeWithHoles, oldPerforationAreas: PerforationAreaModel[]): Drawing {
  const retState: Drawing = {
    ...state,
    selectedNodes: [],
    nodes: [],
    selectedMountings: []
  };
  retState.plate = {...state.plate};
  if(!retState.plate.defaultPerforationAreaOffset && state.plate.shapeWithHoles.conture.length > 0){
    const aabb = aabbOfPolyline(state.plate.shapeWithHoles.conture);
    retState.plate.defaultPerforationAreaOffset = multiplyVector2byScalar(aabb.min, 1000);
  }

  const perforationAreas = oldPerforationAreas.map(v => cloneObject(v));
  retState.plate.perforationAreas = perforationAreas;
  const shape = cloneObject(oldShape);
  retState.plate.shapeWithHoles = shape;
  if (shape) {
    if (shape.conture) {
      for (const c of shape.conture) {
        registerShapePolyline(c, retState);
      }
      if (shape.holes) {
        for (const h of shape.holes) {
          for (const c of h) {
            registerShapePolyline(c, retState);
          }
        }
      }
    }
    if (!shape.holes) {
      retState.plate.shapeWithHoles.holes = [];
    }
  } else {
    retState.plate.shapeWithHoles.conture = null;
    retState.plate.shapeWithHoles.holes = [];
  }

  if (perforationAreas) {
    for (let i = 0; i < perforationAreas.length; i++) {
      const perfArea = retState.plate.perforationAreas[i];
      perfArea.offset = perfArea.offset || retState.plate.defaultPerforationAreaOffset;
      const shapeWithHoles = perfArea.shape;
      if (shapeWithHoles.conture) {
        for (const c of shapeWithHoles.conture) {
          registerPerforationPolyline(c, retState);
        }
        if (shapeWithHoles.holes) {
          for (const h of shapeWithHoles.holes) {
            for (const c of h) {
              registerPerforationPolyline(c, retState);
            }
          }
        }
      }
      if (!shapeWithHoles.holes) {
        retState.plate.perforationAreas[i].shape.holes = [];
      }
    }
  } else {
    retState.plate.perforationAreas = [];
  }

  return retState;
}

export function updateDrawingShapesFromShapes(state: Drawing, shape: ShapeWithHoles, shapesWithHoles: ShapeWithHoles[]): Drawing {
  const areas = convertShapesToPerfAreas(shapesWithHoles, state.plate.perforationAreas);
  return updateDrawingShapes(state, shape, areas);

}

function convertShapesToPerfAreas(shapesWithHoles: ShapeWithHoles[], oldAreas:PerforationAreaModel[]):PerforationAreaModel[]{
  const result:PerforationAreaModel[] = [];
  for (let i = 0; i < shapesWithHoles.length; i++) {
    const shapeWithHoles = shapesWithHoles[i];
    const oldArea = oldAreas[i];
    result.push({
      shape: shapeWithHoles,
      offset: oldArea ? oldArea.offset : null,
      rotation: oldArea ? oldArea.rotation : 0
    });
  }
  return result;
}
