import { Component, Input, OnInit } from "@angular/core";
import { TemplateUtils } from "../../templatesUtils";

@Component({
  selector: "app-bends-toggles",
  templateUrl: "./bends-toggles.component.html",
  styleUrls: ["./bends-toggles.component.css"],
})
export class BendsTogglesComponent implements OnInit {
  @Input()
  isTwoBend: boolean = false;
  public semiLeft: boolean = true;
  public left: boolean = true;
  public semiRight: boolean = true;
  public right: boolean = true;
  public semiFront: boolean = true;
  public front: boolean = true;
  public semiBack: boolean = true;
  public back: boolean = true;

  constructor(public templateUtils: TemplateUtils) {}

  ngOnInit() {}
}
