import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslationProvider } from '../../providers/translation.provider';

export interface MultiselectOption {
  image?: string,
  label?: string | number,
  disabled?: boolean,
  value: any,
  metadata: any
}

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.css'],
  providers: [
    { useExisting: forwardRef(() => MultiselectComponent), provide: NG_VALUE_ACCESSOR, multi: true }
]
})
export class MultiselectComponent implements OnInit, ControlValueAccessor {

  @Input() isFilter = false;
  @Input() showHeader = false;
  @Input() disabled = false;
  @Input() placeholder: string;
  @Input() checkboxVisible = true;
  @Input() maxSelectedLabels = 3;
  @Input() selectionLimit = 10;
  @Input() options: MultiselectOption[] = [];


  @Output() change = new EventEmitter<{originalEvent: any, value: any, itemValue:any, options: MultiselectOption[]}>();
  @Output() hide = new EventEmitter<{originalEvent: any, value: any, itemValue:any, options: MultiselectOption[]}>();

  @Input() selectedOptions;
  @Output() selectedOptionsChange = new EventEmitter<any>();

  onHide($event) {
    this.hide.emit({...$event, options: this.options.filter(x => this.selectedOptions.filter(y => y === x.value).length)});
    this.selectedOptionsChange.emit(this.selectedOptions);
  }

  onChange($event) {
    this.change.emit({...$event, options: this.options.filter(x => this.selectedOptions.filter(y => y === x.value).length)});
    this.selectedOptionsChange.emit(this.selectedOptions);
  }

  constructor(private translationProvider: TranslationProvider) { }

  ngOnInit() {
  }

  writeValue(obj) {
    this.selectedOptions = obj;
  }

  private onChangeFunctions = [];
  registerOnChange(fn: (v) => void) {
    this.onChangeFunctions.push(fn);
  }

  private onTouchedFunctions = [];
  registerOnTouched(fn: () => void) {
    this.onTouchedFunctions.push(fn);
  }

  translate(text: string, module: string = 'designer') {
    return this.translationProvider.translate(text, module);
  }

}