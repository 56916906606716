import { Vector2, Vector3, crossVector2, subVectors2 } from "webcad/math";
import { buildBendingStructure } from "../../utils/bending";
import { BendingLine } from "../bending-line.model";
import { Element } from "../product-configuration/element.model.js";
import { MinValue } from "../shape-with-holes";
import { Region } from "./region.viewModel";
import {DeepImmutable, Matrix} from "webcad/babylonjs/core";

export interface BendingNode {
  bendingLine: BendingLine;
  flatRegion: Region;
  bendingRegion: Region;
  children: BendingNode[];

  toLocal: DeepImmutable<Matrix>; // from flat shape space to region local space
  flatWorldR: Matrix; // from region local space to flat shape space
  world: Matrix; // from flat shape space to bended space (toLocal * bent world)
}

export function findNodeThatContains(
  v0: Vector2,
  node: BendingNode
): BendingNode {
  const edges = node.flatRegion.edges;
  const vertices = node.flatRegion.vertices;

  // calculate number of edges on the right
  let v1: Vector3;
  let v2: Vector3;
  let x0: number;
  const intersectionsX: number[] = [];
  for (let e = 0; e < edges.length; e += 2) {
    v1 = vertices[edges[e]];
    v2 = vertices[edges[e + 1]];
    if (
      Math.max(v1.y, v2.y) < v0.y ||
      Math.min(v1.y, v2.y) > v0.y ||
      v1.y == v2.y
    ) {
      continue;
    }
    x0 = ((v2.x - v1.x) * (v0.y - v1.y)) / (v2.y - v1.y) + v1.x;
    if (Math.abs(x0 - v0.x) < 0.00001) {
      // is on edge
      return node;
    }
    if (x0 > v0.x) {
      if (intersectionsX.indexOf(x0) === -1) {
        intersectionsX.push(x0);
      }
    }
  }
  if (intersectionsX.length % 2 > 0) {
    // if odd then we are in this shape
    return node;
  }
  for (let c = 0; c < node.children.length; c++) {
    const result = findNodeThatContains(v0, node.children[c]);
    if (!!result) {
      return result;
    }
  }
  return null;
}

function getEdgesPaths(immutableEdges: number[]): number[][] {
  const paths: number[][] = [];
  const edges: number[] = [...immutableEdges];
  const addEdge = function (i, path): boolean {
    if (path[path.length - 1] === edges[i * 2]) {
      path.push(edges[i * 2 + 1]);
    } else {
      path.push(edges[i * 2]);
    }
    edges[i * 2] = undefined;
    edges[i * 2 + 1] = undefined;
    if (path[0] === path[path.length - 1]) {
      path.pop();
      return true;
    } else {
      return false;
    }
  };
  for (;;) {
    const start = edges.findIndex((x) => x !== undefined);
    if (start === -1) {
      break;
    }
    const path = [edges[start]];
    addEdge(Math.floor(start / 2), path);
    for (;;) {
      const i = edges.indexOf(path[path.length - 1]);
      if (i !== -1) {
        const end = addEdge(Math.floor(i / 2), path);
        if (end) {
          break;
        }
      } else {
        break;
      }
    }
    paths.push(path);
  }
  return paths;
}

function getPathMinLength(
  bendingLine: BendingLine,
  paths: number[][],
  vertices: Vector2[]
): { min: MinValue; max: MinValue } {
  let minLegLength: MinValue = {
    value: Number.MAX_VALUE,
    position: null,
  };

  let maxLegLength: MinValue = {
    value: Number.NEGATIVE_INFINITY,
    position: null,
  };
  for (
    let parentPathIndex = 0;
    parentPathIndex < paths.length;
    parentPathIndex++
  ) {
    const path = paths[parentPathIndex];
    let pathMax = 0;
    let pos: Vector2 = null;
    for (let pi = 0; pi < path.length; pi++) {
      const v = vertices[path[pi]];
      const x0 = v.x;
      const x1 = bendingLine.begin.x;
      const x2 = bendingLine.end.x;
      const y0 = v.y;
      const y1 = bendingLine.begin.y;
      const y2 = bendingLine.end.y;
      const d =
        1000 *
        (Math.abs((y2 - y1) * x0 - (x2 - x1) * y0 + x2 * y1 - y2 * x1) /
          Math.sqrt((y2 - y1) * (y2 - y1) + (x2 - x1) * (x2 - x1)) +
          bendingLine.bentParams.ossb -
          bendingLine.bentParams.bendAllowance / 2);
      if (pathMax < d) {
        pathMax = d;
        pos = { x: x0, y: y0 };
      }
    }
    if (maxLegLength.value < pathMax && pathMax > 0) {
      maxLegLength = {
        value: pathMax,
        position: pos,
      };
    }
    if (minLegLength.value > pathMax && pathMax > 0) {
      minLegLength = {
        value: pathMax,
        position: pos,
      };
    }
  }
  return { min: minLegLength, max: maxLegLength };
}

function getNodesMinLegLength(
  node: BendingNode,
  minLegLength: MinValue,
  nodePaths: number[][],
  hasParent = false
): MinValue {
  let result = minLegLength;
  for (let childIndex = 0; childIndex < node.children.length; childIndex++) {
    const child = node.children[childIndex];
    // one side
    let mv = getPathMinLength(
      child.bendingLine,
      nodePaths,
      node.flatRegion.vertices
    );
    result = mv.min.value < result.value ? mv.min : result;
    child.bendingLine.baseLength = mv.max.value;
    const dir = subVectors2(child.bendingLine.end, child.bendingLine.begin);
    const otherSideBendingNode = hasParent
      ? node
      : node.children.find((c) => {
          if (c === child) {
            return false;
          }
          const dir1 = subVectors2(c.bendingLine.end, c.bendingLine.begin);
          return Math.abs(crossVector2(dir, dir1)) < 0.0005;
        });
    if (otherSideBendingNode) {
      child.bendingLine.baseLength +=
        otherSideBendingNode.bendingLine.bentParams.ossb * 1000;
    }
    child.bendingLine.baseLength =
      Math.round(child.bendingLine.baseLength * 100) / 100;

    // second side
    const childPath = getEdgesPaths(child.flatRegion.edges);
    mv = getPathMinLength(
      child.bendingLine,
      childPath,
      child.flatRegion.vertices
    );
    result = mv.min.value < result.value ? mv.min : result;
    child.bendingLine.legLength = mv.max.value;
    if (child.children.length > 0) {
      child.bendingLine.legLength +=
        child.children[0].bendingLine.bentParams.ossb * 1000;
    }
    child.bendingLine.legLength =
      Math.round(child.bendingLine.legLength * 100) / 100;

    // recursive
    result = getNodesMinLegLength(child, result, childPath, true);
  }

  return result;
}

export function setElementLegsLength(element: Element, thickness: number) {
  const bendingStructure = buildBendingStructure(
    element.shape,
    element.breakLines,
    false,
    thickness
  );
  if (bendingStructure === null) {
    element.minLegLength = null;
    if (element.breakLines) {
      element.breakLines.forEach((bl) => (bl.legLength = null));
    }
  } else {
    const minLegLength = getNodesMinLegLength(
      bendingStructure,
      {
        value: Number.MAX_VALUE,
        position: null,
      },
      getEdgesPaths(bendingStructure.flatRegion.edges)
    );
    element.minLegLength =
      minLegLength.value === Number.MAX_VALUE ? null : minLegLength;
  }
}
