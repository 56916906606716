import angleMeasurementVertex from '../glsl/angle-measurement.vertex.glsl';
import colorFragment from "../glsl/color.fragment.glsl";
import { Vector4 } from "../math/vector4";
import {Effect, Scene, ShaderMaterial, Vector4 as B_Vector4} from "@babylonjs/core";

export class AngleMeasurementMaterial extends ShaderMaterial {
  constructor(scene: Scene) {
    super("Angle-Measurement", scene, getShaderPath(), {
      attributes: ["position"],
      uniforms: ["worldViewProjection", "radius", "angle", "scale"],
    });

    this.radius = 0.3;
    this.angle = Math.PI * 0.7;
    this.scale = 0.1;
    this.color = { x: 0, y: 0, z: 0, w: 1 };
  }

  public set radius(v: number) {
    this.setFloat("radius", v);
  }

  public set angle(v: number) {
    this.setFloat("angle", v);
  }

  public set scale(v: number) {
    this.setFloat("scale", v);
  }

  public set color(v: Vector4) {
    this.setVector4("matColor", new B_Vector4(v.x, v.y, v.z, v.w));
  }
}

function getShaderPath(): any {
  if (!Effect.ShadersStore["angleVertexShader"]) {
    Effect.ShadersStore["angleVertexShader"] = angleMeasurementVertex;
  }
  if (!Effect.ShadersStore["customColorFragmentShader"]) {
    Effect.ShadersStore["customColorFragmentShader"] = colorFragment;
  }
  return {
    vertex: "angle",
    fragment: "customColor",
  };
}
