<div class="dialog-modal-header">
  <div class="dialog-modal-header-content">
    <div class="dialog-modal-title-container">
      <h4 class="dialog-modal-title-primary" id="modal-basic-title">
        {{ this.templateUtils.translate("Basic Rectangle") }}
      </h4>
      <span class="dialog-modal-title-secondary"
        >{{
          this.templateUtils.translate("EnterDimenstions", "designer")
        }}
        (mm):</span
      >
    </div>
    <div class="dialog-modal-window">
      <span>{{
        this.templateUtils.translate("SketchDoesntChange", "designer")
      }}</span>
    </div>
  </div>

  <button
    type="button"
    class="close btn-close"
    aria-label="Close"
    (click)="dismiss('Cross click'); isModalOpenMethod()"
    [tabIndex]="-1"
  ></button>
</div>

<div>
  <div class="dialog-modal-body">
    <div class="basic-inputs">
      <input
        type="text"
        id="a"
        name="a"
        class="input-small basic-a"
        [tabIndex]="2"
        [ngStyle]="
          basicRectangleTemplate.a <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="basicRectangleTemplate.a"
        (input)="setBasicA(templateUtils.roundInput($event, 0, 5000))"
        #a
        required
      />

      <input
        type="text"
        id="e1"
        class="input-small basic-e1"
        [tabIndex]="5"
        [ngStyle]="
          basicRectangleTemplate.e1 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="basicRectangleTemplate.e1"
        (input)="
          setBasicE1(
            templateUtils.roundInput($event, 0, basicRectangleTemplate.a / 2)
          )
        "
        #e1
        required
      />

      <input
        type="text"
        id="e2"
        class="input-small basic-e2"
        [tabIndex]="6"
        (keydown)="this.templateUtils.returnToFirstElement($event, b)"
        [ngStyle]="
          basicRectangleTemplate.e2 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="basicRectangleTemplate.e2"
        (input)="
          setBasicE2(
            templateUtils.roundInput($event, 0, basicRectangleTemplate.a / 2)
          )
        "
        #e2
        required
      />

      <input
        type="text"
        id="b"
        class="input-small basic-b"
        [tabIndex]="1"
        (keydown)="this.templateUtils.returnToLastElement($event, e2)"
        [ngStyle]="
          basicRectangleTemplate.b <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="basicRectangleTemplate.b"
        (input)="setBasicB(templateUtils.roundInput($event, 0, 5000))"
        #b
        required
      />

      <input
        type="text"
        id="f1"
        class="input-small basic-f1"
        [tabIndex]="4"
        [ngStyle]="
          basicRectangleTemplate.f1 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="basicRectangleTemplate.f1"
        (input)="
          setBasicF1(
            templateUtils.roundInput($event, 0, basicRectangleTemplate.b / 2)
          )
        "
        #f1
        required
      />

      <input
        type="text"
        id="f2"
        class="input-small basic-f2"
        [tabIndex]="3"
        [ngStyle]="
          basicRectangleTemplate.f2 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="basicRectangleTemplate.f2"
        (input)="
          setBasicF2(
            templateUtils.roundInput($event, 0, basicRectangleTemplate.b / 2)
          )
        "
        #f2
        required
      />
    </div>
  </div>

  <div class="dialog-modal-footer">
    <span (click)="this.templateUtils.setDefault()" class="dialog-modal-link">
      {{
        this.templateUtils.translate("Reset dimensions to default.", "designer")
      }}
    </span>
    <button
      #closeButton
      type="button"
      class="btn btn-primary btn-create"
      [disabled]="reqValBasicRectangle | async"
      [tabIndex]="1"
      (click)="
        close({
          a: a.valueAsNumber,
          b: b.valueAsNumber,
          e1: e1.valueAsNumber,
          e2: e2.valueAsNumber,
          f1: f1.valueAsNumber,
          f2: f2.valueAsNumber
        })
      "
    >
      {{ this.templateUtils.translate("Create") }}
    </button>
  </div>
</div>
