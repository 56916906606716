
   <div class="dialog-modal-header">
    <div class="dialog-modal-header-content">
      <div class="dialog-modal-title-container">
        <h4 class="dialog-modal-title-primary" id="modal-basic-title">
          {{ translate("Template Expanded Metal Title") }}
        </h4>
        <span class="dialog-modal-title-secondary"
          >{{
            translate("Template Expanded Metal Subtitle")
          }}</span
        >
      </div>
    </div>
    <button
      type="button"
      class="close btn-close"
      aria-label="Close"
      (click)="dismiss('Cross click'); isModalOpenMethod()"
      [tabIndex]="-1"
    ></button>
  </div>
<div class="dialog-modal-body">
    <div class="dialog-model-bg" >
      <input class="width" [value]="expandedMetalParams.width" (input)="setWidth($event)"  />
      <input value="1196" class="calculated-input cal-width"/>
      <input class="height" [value]="expandedMetalParams.height" (input)="setHeight($event)"  />
      <input value="496" class="calculated-input cal-height"/>
    </div>

    <div class="parameters">
      <div class="header">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_95_17046)">
          <path d="M13.155 6.90405C12.8779 5.78918 12.2356 4.79914 11.3306 4.09155C10.4256 3.38397 9.3098 2.99951 8.161 2.99951C7.0122 2.99951 5.89643 3.38397 4.99141 4.09155C4.08639 4.79914 3.44415 5.78918 3.167 6.90405H0V9.43298H3.167C3.44415 10.5479 4.08639 11.5379 4.99141 12.2455C5.89643 12.9531 7.0122 13.3375 8.161 13.3375C9.3098 13.3375 10.4256 12.9531 11.3306 12.2455C12.2356 11.5379 12.8779 10.5479 13.155 9.43298H35.284V6.90405H13.155ZM8.161 10.704C7.65943 10.704 7.16912 10.5554 6.75208 10.2767C6.33503 9.99807 6.00999 9.60194 5.81805 9.13855C5.6261 8.67516 5.57588 8.16528 5.67373 7.67334C5.77158 7.1814 6.01312 6.72954 6.36778 6.37488C6.72245 6.02021 7.17432 5.77864 7.66625 5.68079C8.15819 5.58293 8.66809 5.63313 9.13148 5.82507C9.59488 6.01702 9.99095 6.34211 10.2696 6.75916C10.5483 7.1762 10.697 7.66652 10.697 8.16809C10.6965 8.84044 10.4293 9.48496 9.95406 9.96057C9.47882 10.4362 8.83435 10.7039 8.162 10.705L8.161 10.704Z" fill="#009FE3"/>
          <path d="M27.122 12.767C25.9746 12.77 24.861 13.155 23.9567 13.8612C23.0524 14.5674 22.409 15.5545 22.128 16.6669H0V19.1969H22.129C22.4062 20.3118 23.0484 21.3018 23.9534 22.0094C24.8584 22.717 25.9742 23.1014 27.123 23.1014C28.2718 23.1014 29.3876 22.717 30.2926 22.0094C31.1976 21.3018 31.8399 20.3118 32.117 19.1969H35.284V16.6669H32.122C31.8407 15.5535 31.1964 14.5655 30.2909 13.8593C29.3854 13.153 28.2704 12.7687 27.122 12.767ZM27.122 20.472C26.6205 20.4718 26.1303 20.3228 25.7133 20.0441C25.2964 19.7653 24.9715 19.3691 24.7798 18.9056C24.588 18.4422 24.5379 17.9324 24.6359 17.4406C24.7338 16.9487 24.9754 16.497 25.3301 16.1425C25.6848 15.7879 26.1367 15.5464 26.6286 15.4486C27.1205 15.3508 27.6304 15.4012 28.0937 15.5931C28.5571 15.7851 28.9531 16.1102 29.2317 16.5272C29.5103 16.9442 29.659 17.4344 29.659 17.9359C29.6582 18.6084 29.3906 19.2532 28.915 19.7286C28.4394 20.2041 27.7945 20.4715 27.122 20.472Z" fill="#009FE3"/>
          <path d="M8.162 22.5331C7.01452 22.5361 5.9007 22.9211 4.99625 23.6273C4.0918 24.3335 3.4482 25.3207 3.167 26.4332H0V28.962H3.167C3.44415 30.0769 4.08639 31.0672 4.99141 31.7748C5.89643 32.4824 7.0122 32.8666 8.161 32.8666C9.3098 32.8666 10.4256 32.4824 11.3306 31.7748C12.2356 31.0672 12.8779 30.0769 13.155 28.962H35.284V26.4332H13.155C12.874 25.321 12.2308 24.3337 11.3267 23.6276C10.4226 22.9214 9.30918 22.5363 8.162 22.5331ZM8.162 30.233C7.66043 30.233 7.17012 30.0844 6.75307 29.8058C6.33603 29.5271 6.01099 29.131 5.81905 28.6676C5.6271 28.2042 5.57688 27.6942 5.67473 27.2023C5.77258 26.7103 6.01412 26.2586 6.36878 25.9039C6.72345 25.5493 7.17532 25.3077 7.66725 25.2098C8.15919 25.112 8.6691 25.1622 9.13249 25.3541C9.59589 25.5461 9.99195 25.8712 10.2706 26.2882C10.5493 26.7053 10.698 27.1956 10.698 27.6971C10.6985 28.3704 10.4317 29.0163 9.95612 29.4928C9.48055 29.9693 8.83521 30.2374 8.162 30.2382V30.233Z" fill="#009FE3"/>
          </g>
          <defs>
          <clipPath id="clip0_95_17046">
          <rect width="35.285" height="29.872" fill="white" transform="translate(0 3)"/>
          </clipPath>
          </defs>
        </svg>
        <div class="text">
          {{translate("Parameter")}}
        </div>
      </div>

      <div class="content">
        <div class="section">
          <div class="section-header">
            {{translate("Section Header Mesh")}}
          </div>
          <div class="section-content">
          <div class="single-parameter">
              <dropdown [model]="meshType" [dropdownMenuWidth]="232"></dropdown>
          </div>
          <div class="single-parameter">
              <dropdown [model]="meshSize" [dropdownMenuWidth]="232"></dropdown>
          </div>
          <div class="single-parameter">
            <div>
              Strand Width
            </div>
            <mat-radio-group class="perfaction-config__radio-group">
              <mat-radio-button class="fading-radio" color="primary">
                {{translate('Narrow')}}
              </mat-radio-button>
              <mat-radio-button class="fading-radio" color="primary">
                {{translate('Wide')}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="single-parameter">
              <span>1</span>
              <span>
                &#8805;
              </span>
              <input />
              <span>
                &#8805;
              </span>
              <span>4</span>
          </div>
          <div class="single-parameter">
            <dropdown [model]="typeOfCut" [dropdownMenuWidth]="232"></dropdown>
        </div>
          </div>
        </div>
        <div class="section">
          <div class="section-header">
            {{translate("Section Header Material")}}
          </div>
          <div class="section-content">
            <div class="single-parameter">
              <dropdown [model]="materialType" [dropdownMenuWidth]="232"></dropdown>
          </div>
          <div class="single-parameter">
            <dropdown [model]="thickness" [dropdownMenuWidth]="232"></dropdown>
          </div>
          <div class="single-parameter">
            <dropdown [model]="materialGrade" [dropdownMenuWidth]="232"></dropdown>
          </div>
          </div>
        </div>
        <div class="section">
          <div class="section-header">
            {{translate("Section Header Surface")}}
          </div>
          <div class="section-content">
            <div class="single-parameter">
              <dropdown [model]="materialGrade" [dropdownMenuWidth]="232"></dropdown>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-header">
            {{translate("Initial Sketch")}}
          </div>
          <div class="section-content">
            <div class="single-parameter">
              <div class="initial-sketch-bg">
                <div class="initial-text width">1200</div>
                <div class="initial-text height">500</div>
                <div class="initial-text calculated width">1196</div>
                <div class="initial-text calculated height">496</div>
                <!-- 
                
                 -->
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div>
    </div>
</div>



<div class="dialog-modal-footer">
  <span (click)="this.templateUtils.setDefault()" class="dialog-modal-link">
    {{
      this.templateUtils.translate("Reset dimensions to default.", "designer")
    }}
  </span>
  <button
    #closeButton
    type="button"
    class="btn btn-primary btn-create"
    [disabled]="expandedMetalParams.width <= 0 || expandedMetalParams.height <= 0"
    [tabIndex]="1"
    (click)="createTemplate"
  >
    {{ this.templateUtils.translate("Create") }}
  </button>
</div>