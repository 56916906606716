import {RuleAction, RuleActions} from './rule-action.model';
import {ConfigurationItem} from './product-configuration/configuration-item.model';
import {MinMaxRaender} from './product-configuration/min-max-raender.model';
import {EwzItem} from './product-configuration/configuration-ewz.model';
import {LomoeItem} from './product-configuration/configuration-lomoe.model';
import {ConfigurationMaterial} from './product-configuration/configuration-material.model';

export const configurationProperties = ['material', 'lomoe', 'ewz', 'minMaxRaender', 'stremoe', 'gitter', 'geomatrix', 'wallpaper'];

export interface ChangeStateResult {
  actions: RuleActions;
  configuration: {
    configuration: {
      material: ConfigurationMaterial;
      lomoe: LomoeItem;
      ewz: EwzItem;
      minMaxRaender: MinMaxRaender;
      stremoe: ConfigurationItem;
      gitter: ConfigurationItem;
      geomatrix: ConfigurationItem;
      wallpaper: ConfigurationItem;
      allSingleToolsFound: boolean;
      isCustomPattern: boolean;
      minFoil: number;
      minPolishing: number;
      minPowderCoating: number;
      minGalvanizing: number;
    };
    elementsConfiguration: {
      posibleCoil: string;
      posiblePlate: string;
    }[];
    root: {
      specialDate: number;
    }
  };
}
