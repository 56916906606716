export interface Bending {
  id: number;
  materialGroup: string;
  angle: number;
  materialThickness: number;
  vDie: number;
  deduction: number;
  radius: number;
}

export interface BendParams {
  bendAllowance: number;
  radius: number;
  ossb: number;
}

function toRad(deg: number): number {
  return (Math.PI * deg) / 180;
}

export function getBendParmas(
  materualCode: string,
  materialThickness: number,
  angle: number,
  bendings: Bending[]
): BendParams {
  angle = Math.abs(angle);
  const filtered = bendings.filter(
    (b) =>
      materualCode.startsWith(b.materialGroup) &&
      b.materialThickness == materialThickness
  );
  if (!filtered.length) {
    const error = new Error(
      `No bend definition found for material:  ${materualCode}  with thickness of: ${materialThickness}`
    );
    console.error(error);
    throw error;
  }
  const sorted = filtered.sort((a, b) => a.angle - b.angle);
  sorted.unshift({
    angle: 0,
    deduction: 0,
    id: null,
    materialGroup: null,
    materialThickness: null,
    radius: null,
    vDie: null,
  });
  const last = sorted[sorted.length - 1];
  if (last.angle < 180) {
    sorted.push({
      angle: 180,
      deduction: last.deduction,
      id: null,
      materialGroup: last.materialGroup,
      materialThickness: last.materialThickness,
      radius: last.radius,
      vDie: last.vDie,
    });
  }

  let index = 0;
  while (toRad(sorted[index + 1].angle) < angle) {
    index++;
  }
  const t =
    (angle - toRad(sorted[index + 0].angle)) /
    (toRad(sorted[index + 1].angle) - toRad(sorted[index + 0].angle));
  const ossb1 = calculateOssb(
    toRad(sorted[index + 0].angle),
    sorted[index + 0].radius,
    sorted[index + 0].materialThickness
  );
  const ossb2 = calculateOssb(
    toRad(sorted[index + 1].angle),
    sorted[index + 1].radius,
    sorted[index + 1].materialThickness
  );
  const a1 = calculateBendAllowance(sorted[index + 0].deduction, ossb1);
  const a2 = calculateBendAllowance(sorted[index + 1].deduction, ossb2);
  return {
    bendAllowance: (a1 * (1 - t) + a2 * t) / 1000,
    radius:
      (sorted[index + 0].radius * (1 - t) + sorted[index + 1].radius * t) /
      1000,
    ossb: (ossb1 * (1 - t) + ossb2 * t) / 1000,
  };
}

export function calculateOssb(
  angle: number,
  raduus: number,
  thickenss
): number {
  if (angle < Math.PI / 2) {
    return Math.tan(angle / 2) * (raduus + thickenss);
  }
  if (raduus < 0.05) {
    // bending company claims that for 180 bending the bend deduction is 0 but then there is no room for bending
    // this way we try to make it possible: for case 1000mm plate bend in half we will get 2 wings with 500mm flat areas and we
    // will ignore the fact that bend regions stick out of 500mm AABB (addBendingNodeToAabb function will not include them)
    // I need to have some minimal 0ssb so that cutting plate to regions will work
    // (addBendingLineContour uses libtess and it ignores line like polygons)
    return 0.05;
  } else {
    return raduus + thickenss;
  }
}

export function calculateBendAllowance(
  bendDeduction: number,
  ossb: number
): number {
  return 2 * ossb - bendDeduction;
}
