import { PointerCollider } from "webcad/collision";
import { Pointer } from "webcad/collision/pointer";
import { PointerIntersection } from "webcad/collision/pointer-intersection";
import { Vector3 } from "webcad/math";
import { IsPointInsideOfShape } from "webcad/models";
import { PerforationAreaModel } from "../../model/perforation-area.model";
import { UnderPointerType } from "../../model/pointer-state.model";
import { ColliderPriority } from "./collider-priorities";
import { CursorType } from "./cursor.type";
import { MevacoCollider } from "./mevaco.collider";

export interface PerforationAreaIntersection extends PointerIntersection {
  perforationArea: PerforationAreaModel;
}

export class PerforationAreaCollider
  implements PointerCollider, MevacoCollider
{
  cursorType: CursorType = CursorType.point;
  object: PerforationAreaModel;
  objectType = UnderPointerType.PERFORATION_AREA;
  priority = ColliderPriority.PERFORATION_AREA;
  name = "PerforationArea";

  constructor(private perforationShape: PerforationAreaModel) {
    this.object = perforationShape;
  }

  compare(other: PointerCollider): boolean {
    return false;
  }

  getIntersection(
    pointer: Pointer,
    epsilon: number
  ): PerforationAreaIntersection {
    const pos: Vector3 = pointer.onWorkingPlane;
    if (this.perforationShape) {
      if (IsPointInsideOfShape(pos, this.perforationShape.shape.conture)) {
        return {
          position: pos,
          collider: this,
          perforationArea: this.perforationShape,
        };
      }
    }
  }
}
