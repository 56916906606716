import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Action, Store, select } from "@ngrx/store";
import { Guid } from "guid-typescript";
import * as hash from "object-hash";
import { Observable, Subscription, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { aabbOfPolyline, getEmptyAaabb } from "webcad/models";
import { environment } from "../../../environments/environment";
import { PlateMaterialType } from "../../model";
import { MultiShape } from "../../model/basic-templates.model";
import { ConfigurationMaterial } from "../../model/product-configuration/configuration-material.model";
import {
  Element,
  ElementType,
} from "../../model/product-configuration/element.model";
import { ProductType, Step } from "../../model/product-configuration/product-configuration.model";
import { ShapeWithHoles, createRectangle } from "../../model/shape-with-holes";
import { Units } from "../../model/units";
import { UserTemplate } from "../../model/user-template.model";
import { TranslationProvider } from "../../providers/translation.provider";
import { PlateService } from "../../services/plate.service";
import { LoadDxf, SetSelectedUnit } from "../../store/actions";
import { SetDefaultValues } from "../../store/actions/element-creator.actions";
import { SetError } from "../../store/actions/errorable.actions";
import { ProductConfigurationAddElement } from "../../store/actions/product-configuration.actions";
import {
  ConfigureUserTemplate,
  DeleteUserTemplate,
  GetUserTemplates,
} from "../../store/actions/user-template.actions";
import {
  MevacoState,
  getMaterial,
  getProductType,
  getSelectedUnit,
  getStep,
  getUserTemplates,
  isImporting,
  isMevacoUser,
  loadTemplateModal,
} from "../../store/reducers";
import { roundDecimal } from "../../utils/utils";
import { Dropdown, DropdownOption } from "../dropdown/dropdown.component";
import { TemplateName } from "../templates/template-name";
import { ElementCreatorTemplateTypeEnum } from "../../model/product-configuration/element-creator-template-type.enum";
import {MatCheckbox} from "@angular/material/checkbox";
// import { ElementCreatorTemplateTypeEnum } from "src/app/model/product-configuration/element-creator-template-type.enum";

@Component({
  selector: "element-creator",
  templateUrl: "./element-creator.component.html",
  styleUrls: ["./element-creator.component.css"],
})
export class ElementCreatorComponent implements OnInit, OnDestroy {
  @ViewChild("basicRectangle", { static: true }) basicRectangle: ElementRef;
  @ViewChild("expandedMetalBasicRectangle", { static: true }) expandedMetalBasicRectangle: ElementRef;
  @ViewChild("rectangleNotch", { static: true }) rectangleNotch: ElementRef;
  @ViewChild("balconyCorner", { static: true }) balconyCorner: ElementRef;
  @ViewChild("multiShape", { static: true }) multiShape: ElementRef;
  @ViewChild("tilted", { static: true }) tilted: ElementRef;
  @ViewChild("trapeze", { static: true }) trapeze: ElementRef;
  @ViewChild("staircase", { static: true }) staircase: ElementRef;
  @ViewChild("umschlagCassete", { static: true }) umschlagCassete: ElementRef;
  @ViewChild("singleBendCassete", { static: true })
  singleBendCassete: ElementRef;
  @ViewChild("twoBendsCassete", { static: true }) twoBendsCassete: ElementRef;
  @ViewChild("korbCassete", { static: true }) korbCassete: ElementRef;
  @ViewChild("flatCassete", { static: true }) flatCassete: ElementRef;
  @ViewChild("fileInput", { static: true }) fileInput: ElementRef;
  @ViewChild("useBlocks") useBlocks: MatCheckbox;

  @ViewChild("fileChosen") uploadLink: ElementRef;
  @ViewChild("dragArea", { static: true }) dragArea: ElementRef;
  @ViewChild("loadingSpinner", { static: true }) loadingSpinner: ElementRef;

  public PlateMaterialType = PlateMaterialType;
  public round = Math.round;
  public a = 800;
  public b = 1250;
  public e1 = 5;
  public e2 = 5;
  public f1 = 5;
  public f2 = 5;
  public editMode = false;
  public templates: Observable<UserTemplate[]>;
  public getImageUrl: string;
  public configurableTemplate: MultiShape;
  public pi = Math.PI;

  public templatesDropDown: Dropdown;
  public readonly ElementCreatorTemplateTypeEnum = ElementCreatorTemplateTypeEnum;

  public userTemplates: UserTemplate[];
  public unitsValues: string[] = [];
  public selectedUnit: Units;
  public units = Units;
  public roundDecimal = roundDecimal;
  public isTemplateModalOpen = false;
  public productType: ProductType;


  private step: Step;
  private subscriptions: Subscription[] = [];
  public templateMenuWidth: number;
  public minf = Math.min;
  public cassettenDropDown: Dropdown;
  public unitsDropdown: Dropdown;
  private material: ConfigurationMaterial;

  public enableCassettes: boolean = environment.enable_cassettes;

  public isMevacoUser = this.store.pipe(
    select(isMevacoUser),
    map((x) => x === "Yes")
  );

  constructor(
    private store: Store<MevacoState>,
    private translation: TranslationProvider,
    private plateService: PlateService,
    private modalService: NgbModal
  ) {}

  @HostListener("window:resize", ["$event"]) onResize(event) {
    if (event.target.innerWidth >= 900 && event.target.innerWidth < 1264) {
      this.templateMenuWidth = 143;
    } else if (
      event.target.innerWidth >= 1424 &&
      event.target.innerWidth < 1630
    ) {
      this.templateMenuWidth = 193;
    } else if (
      event.target.innerWidth >= 1664 &&
      event.target.innerWidth < 1904
    ) {
      this.templateMenuWidth = 226;
    } else if (event.target.innerWidth >= 1904) {
      this.templateMenuWidth = 262;
    }
  }

  ngOnInit() {
    if (window.innerWidth >= 900 && window.innerWidth < 1264) {
      this.templateMenuWidth = 143;
    } else if (window.innerWidth >= 1424 && window.innerWidth < 1630) {
      this.templateMenuWidth = 193;
    } else if (window.innerWidth >= 1664 && window.innerWidth < 1904) {
      this.templateMenuWidth = 226;
    } else if (window.innerWidth >= 1904) {
      this.templateMenuWidth = 262;
    }
    this.subscriptions.push(
      this.store
        .pipe(select(getUserTemplates))
        .subscribe((v) => (this.userTemplates = v))
    );

    this.subscriptions.push(
      this.store
        .pipe(select(isImporting))
        .subscribe((v) =>
          v
            ? this.showLoadingModal(this.loadingSpinner)
            : this.closeLoadingModal()
        )
    );

    this.store.dispatch(new GetUserTemplates());
    this.getImageUrl = environment.api_url + "/api/mevaco/getPreviewImage/";
    this.templates = this.store.pipe(select(getUserTemplates));
    this.subscriptions.push(
      this.store.pipe(select(getStep)).subscribe((v) => (this.step = v))
    );

    this.subscriptions.push(
      this.store
        .pipe(select(getSelectedUnit))
        .subscribe((v) => (this.selectedUnit = v))
    );

    this.templatesDropDown =  new Dropdown(
      this.translate("Choose template", "designer"),
      this.getTemplateTypeOptions(),
      of(""),
      (value) => {
        this.createTemplate(value);
        this.store.dispatch(new SetDefaultValues());
        this.isTemplateModalOpen = true;
      }
    );

    this.cassettenDropDown = new Dropdown(
      this.translate("Choose template", "designer"),
      this.getCassetenTypeOptions(),
      of(""),
      (value) => {
        this.createTemplate(value);
        this.isTemplateModalOpen = true;
      }
    );

    this.unitsDropdown = new Dropdown(
      this.translate("Import Unit", "designer"),
      this.getUnitDropdownOptions(),
      this.store.pipe(select(getSelectedUnit)),
      (value: string) => this.store.dispatch(new SetSelectedUnit(value))
    );

    this.unitsValues = Object.keys(this.units);

    this.store.pipe(select(getMaterial)).subscribe((v) => (this.material = v));
    this.registerProductType();

    this.store
      .pipe(select(loadTemplateModal))
      .subscribe((isOpen) => {
        if (isOpen) {
          this.createTemplate(TemplateName.basicRectangle);
        }
      })
      .unsubscribe();
  }



  private showLoadingModal(content: any) {
    this.modalService.open(content, {
      centered: true,
      backdrop: "static",
      windowClass: "custom-modal-window",
      backdropClass: "custom-modal-backdrop",
    });
  }

  private closeLoadingModal() {
    this.modalService.dismissAll();
  }

  public onDragOver(e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }): void {
    e.preventDefault();
    e.stopPropagation();
    if (!this.dragArea.nativeElement.classList.contains("drag-state")) {
      this.dragArea.nativeElement.classList.add("drag-state");
    }
  }

  public onDragLeave(e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }): void {
    e.preventDefault();
    e.stopPropagation();
    if (this.dragArea.nativeElement.classList.contains("drag-state")) {
      this.dragArea.nativeElement.classList.remove("drag-state");
    }
  }

  public onDrop(e: {
    preventDefault: () => void;
    stopPropagation: () => void;
    dataTransfer: { files: any };
    target: { value: string };
  }): void {
    e.preventDefault();
    e.stopPropagation();
    if (this.dragArea.nativeElement.classList.contains("drag-state")) {
      this.dragArea.nativeElement.classList.remove("drag-state");
    }

    const fileToSend = e.dataTransfer.files[0];
    const extension = fileToSend.name.substr(
      fileToSend.name.lastIndexOf(".") + 1
    );
    if (extension.toLowerCase() !== "dxf") {
      this.store.dispatch(new SetError(this.translate("IncorrectFileFormat")));
    } else {
      this.uploadLink.nativeElement.files = e.dataTransfer.files;
      this.submitFile(this.uploadLink.nativeElement.files);
    }
    e.target.value = "";
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      if (!s.closed) {
        s.unsubscribe();
      }
    }
    this.subscriptions = [];
  }

  createDropDown(
    name: string,
    value: Observable<string>,
    options: Observable<DropdownOption[]>,
    actionFactory: (value: string) => Action
  ) {
    return new Dropdown(name, options, value, (value) =>
      this.store.dispatch(actionFactory(value))
    );
  }

  private getUnitDropdownOptions(): Observable<DropdownOption[]> {
    return of([
      ...Object.keys(this.units).map((unit) => {
        return new DropdownOption(
          "text",
          this.translate(unit),
          true,
          unit.toString()
        );
      }),
    ]);
  }

  private getCassetenTypeOptions(): Observable<DropdownOption[]> {
    return of([
      new DropdownOption(
        "templateDropdown",
        {
          name: this.translate(TemplateName.umschlag, "designer"),
          imgUrl: "assets/Schema Kassetten Template U-Style_mini.svg",
        },
        true,
        TemplateName.umschlag,
        ""
      ),
      new DropdownOption(
        "templateDropdown",
        {
          name: this.translate(TemplateName.singleBend, "designer"),
          imgUrl: "assets/Schema Kassetten Template L-Style_mini.svg",
        },
        true,
        TemplateName.singleBend,
        ""
      ),
      new DropdownOption(
        "templateDropdown",
        {
          name: this.translate(TemplateName.twoBend, "designer"),
          imgUrl: "assets/Schema Kassetten Template Z-Style_mini.svg",
        },
        true,
        TemplateName.twoBend,
        ""
      ),
      new DropdownOption(
        "templateDropdown",
        {
          name: this.translate(TemplateName.korb, "designer"),
          imgUrl: "assets/Schema Kassetten Template U-Flat-Style_mini.svg",
        },
        true,
        TemplateName.korb,
        ""
      ),
      new DropdownOption(
        "templateDropdown",
        {
          name: this.translate(TemplateName.flat, "designer"),
          imgUrl: "assets/Schema Kassetten Template Flat-Style_mini.svg",
        },
        true,
        TemplateName.flat,
        ""
      ),
    ]);
  }

  private getTemplateTypeOptions(): Observable<DropdownOption[]> {
    return this.store.pipe(select(getProductType), map(x => x as ProductType), switchMap(x =>{
      return of(x === 0 ? [ new DropdownOption(
        "templateDropdown",
        {
          name: this.translate("Basic Rectangle", "designer"),
          imgUrl: "assets/Icon_Icon_Rectangle.svg",
        },
        true,
        "Expanded Metal Basic Rectangle",
        ""
      ),]

       :

       [
        new DropdownOption(
          "templateDropdown",
          {
            name: this.translate("Basic Rectangle", "designer"),
            imgUrl: "assets/Icon_Icon_Rectangle.svg",
          },
          true,
          "Basic Rectangle",
          ""
        ),
        new DropdownOption(
          "templateDropdown",
          {
            name: this.translate("Rectangle Notch", "designer"),
            imgUrl: "assets/Icon_Rectangle_Notch.svg",
          },
          true,
          "Rectangle Notch",
          ""
        ),
        new DropdownOption(
          "templateDropdown",
          {
            name: this.translate("Balcony Corner", "designer"),
            imgUrl: "assets/Icon_Balcony_Corner.svg",
          },
          true,
          "Balcony Corner",
          ""
        ),
        new DropdownOption(
          "templateDropdown",
          {
            name: this.translate("Multi Shape", "designer"),
            imgUrl: "assets/Icon_Multishape.svg",
          },
          true,
          "Multi Shape",
          ""
        ),
        new DropdownOption(
          "templateDropdown",
          {
            name: this.translate("Parallelogram", "designer"),
            imgUrl: "assets/Icon_Parallelogram.svg",
          },
          true,
          "Parallelogram",
          ""
        ),
        new DropdownOption(
          "templateDropdown",
          {
            name: this.translate("Trapeze", "designer"),
            imgUrl: "assets/Icon_Trapeze.svg",
          },
          true,
          "Trapeze",
          ""
        ),
        new DropdownOption(
          "templateDropdown",
          {
            name: this.translate("Staircase", "designer"),
            imgUrl: "assets/Icon_Staircase.svg",
          },
          true,
          "Staircase",
          ""
        ),
      ]);
    }));
  }

  translate(s, module = "configurator") {
    return this.translation.translate(s, module);
  }

  addEmptyPosition() {
    const shape: ShapeWithHoles = {
      conture: [],
      holes: [],
      aabb: getEmptyAaabb(),
      area: 0,
    };
    shape.hash = hash(shape.aabb) + hash(shape.area);
    const element: Element = {
      templateName: TemplateName.none,
      boundary: null,
      type: ElementType.individual,
      position: -1, // will be set inb reducer
      quantity: 1,
      a: 0,
      b: 0,
      aIst: 0,
      bIst: 0,
      e1: this.e1.toString(),
      e1ist: this.e1.toString(),
      e2: this.e2.toString(),
      e2ist: this.e2.toString(),
      f1: this.f1.toString(),
      f1ist: this.f1.toString(),
      f2: this.f2.toString(),
      f2ist: this.f2.toString(),
      openMeshE: "",
      openMeshF: "",
      toleranceWidth: 0,
      toleranceLength: 0,
      label: "",
      note: "",
      unperforated: false,
      posibleCoil: "No",
      posiblePlate: "No",
      shapes: null,
      nodes: null,
      verticesIndexes: null,
      visualizationShape: null,
      previewImageId: Guid.create().toString(),
      shape: shape,
      perforationAreas: [],
      helpLines: [],
      measurements: [],
      angleMeasurements: [],
      mountings: [],
      perforationAutoCenter: true,
      area: 0,
      minRadius: null,
      minParallelEdgesDistance: null,
      minMarginDistance: null,
      isPerforationSimpleRect: null,
      isShapeSimpleRect: null,
      minMountingHoleEdgeDistance: null,
      minMountingHolesDistance: null,
      minOutsideAngle: null,
      numberOfArcs: null,
      minDistanceBetweenEdges: null,
      minDistanceBetweenMountingAndPerforatedArea: null,
      //perforation: null,
      possibleAllAcross: true,
      minDistanceBetweenPerforationAreas: null,
      lfbIst: undefined,
      lflIst: undefined,

      breakLines: [],
      bendingLinesDistances: [],
      //possiblePerforationZones: []
    };
    this.store.dispatch(new ProductConfigurationAddElement(element));
  }

  addPosition() {
    const shape: ShapeWithHoles = {
      conture: createRectangle(
        { x: 0, y: 0 },
        { x: this.b / 1000, y: this.a / 1000 }
      ),
      holes: [],
    };
    shape.aabb = aabbOfPolyline(shape.conture);
    shape.area = (this.b / 1000) * (this.a / 1000);
    shape.hash = hash(shape.aabb) + hash(shape.area);
    const a = this.a;
    const element: Element = {
      type: ElementType.individual,
      templateName: TemplateName.basicRectangle,
      boundary: null,
      position: -1, // will be set inb reducer
      quantity: 1,
      a: this.a,
      b: this.b,
      aIst: this.a,
      bIst: this.b,
      e1: this.e1.toString(),
      e1ist: this.e1.toString(),
      e2: this.e2.toString(),
      e2ist: this.e2.toString(),
      f1: this.f1.toString(),
      f1ist: this.f1.toString(),
      f2: this.f2.toString(),
      f2ist: this.f2.toString(),
      openMeshE: "",
      openMeshF: "",
      toleranceWidth: 0,
      toleranceLength: 0,
      label: "",
      note: "",
      unperforated: false,
      posibleCoil: "No",
      posiblePlate: "No",
      shapes: null,
      nodes: null,
      verticesIndexes: null,
      visualizationShape: null,
      previewImageId: Guid.create().toString(),
      shape: shape,
      perforationAreas: [],
      helpLines: [],
      measurements: [],
      angleMeasurements: [],
      mountings: [],
      perforationAutoCenter: true,
      area: shape.area,
      minRadius: null,
      minParallelEdgesDistance: null,
      minMarginDistance: null,
      isPerforationSimpleRect: null,
      isShapeSimpleRect: null,
      minMountingHoleEdgeDistance: null,
      minMountingHolesDistance: null,
      minOutsideAngle: null,
      numberOfArcs: null,
      minDistanceBetweenEdges: null,
      minDistanceBetweenMountingAndPerforatedArea: null,
      //perforation: null,
      possibleAllAcross: true,
      minDistanceBetweenPerforationAreas: null,
      lfbIst: undefined,
      lflIst: undefined,
      breakLines: [],
      bendingLinesDistances: [],
      //possiblePerforationZones: []
    };
    this.store.dispatch(new ProductConfigurationAddElement(element));
    // this.c('Added');
  }

  isTemplateModalOpenChange(input: boolean) {
    this.isTemplateModalOpen = input;
  }

  submitFile(file: FileList) {
    const blocks = this.useBlocks.checked;
    if (file.length === 0) {
      alert("Please choose file to upload");
      return;
    }
    const fileToSend = file.item(0);
    const extension = fileToSend.name.substr(
      fileToSend.name.lastIndexOf(".") + 1
    );
    if (extension.toLowerCase() !== "dxf") {
      this.store.dispatch(new SetError(this.translate("IncorrectFileFormat")));
    } else {
      this.store.dispatch(
        new LoadDxf({
          file: fileToSend,
          units: this.selectedUnit,
          useBlocks: blocks,
        })
      );
    }
    this.uploadLink.nativeElement.value = null;
  }

  getPreviewImageUrl(template: UserTemplate): string {
    return this.getImageUrl + template.imageGuid.toString();
  }

  createPosition(template: UserTemplate) {
    const element = JSON.parse(template.template) as Element;
    element.position = -1;
    element.previewImageId = Guid.create().toString();
    this.store.dispatch(new ProductConfigurationAddElement(element));
  }

  deleteTemplate(template: UserTemplate) {
    this.store.dispatch(new DeleteUserTemplate(template.id));
  }

  confirmPopup(content: any, template: UserTemplate) {
    this.modalService
      .open(content, { centered: true, backdrop: "static" })
      .result.then(
        (result: boolean) => {
          if (result) {
            this.deleteTemplate(template);
          }
        },
        (reason) => {}
      );
  }

  openModal(content: any) {
    this.modalService.open(content, {
      centered: true,
      backdrop: "static",
      windowClass: "custom-modal-window",
      backdropClass: "custom-modal-backdrop",
      size: "lg",
    });
  }

  public createTemplate(type: string) {
    if (type === TemplateName.basicRectangle) {
      this.openModal(this.basicRectangle);
    } else if (type === TemplateName.rectangleNotch) {
      this.openModal(this.rectangleNotch);
    } else if (type === TemplateName.balconyCorner) {
      this.openModal(this.balconyCorner);
    } else if (type === TemplateName.multiShape) {
      this.openModal(this.multiShape);
    } else if (type === TemplateName.parallelogram) {
      this.openModal(this.tilted);
    } else if (type === TemplateName.trapeze) {
      this.openModal(this.trapeze);
    } else if (type === TemplateName.staircase) {
      this.openModal(this.staircase);
    } else if (type === TemplateName.umschlag) {
      this.openModal(this.umschlagCassete);
    } else if (type === TemplateName.singleBend) {
      this.openModal(this.singleBendCassete);
    } else if (type === TemplateName.twoBend) {
      this.openModal(this.twoBendsCassete);
    } else if (type === TemplateName.korb) {
      this.openModal(this.korbCassete);
    } else if (type === TemplateName.flat) {
      this.openModal(this.flatCassete);
    } else if(type === TemplateName.expandedMetalBasicRectangle){
      this.openModal(this.expandedMetalBasicRectangle);
    }
  }

  getName(template: UserTemplate): string {
    return template.name;
  }

  updateTemplateName(template: UserTemplate, newName: string) {
    this.store.dispatch(
      new ConfigureUserTemplate({
        id: template.id,
        name: newName,
      })
    );
  }
  registerProductType() {
    this.store.pipe(select(getProductType), map(x => x as ProductType)).subscribe(res =>  this.productType = res);
  }

  isElementCreatorItemVisible(template: ElementCreatorTemplateTypeEnum ){
    if((ElementCreatorTemplateTypeEnum.CasetteTemplate === template && this.productType === ProductType.ExtendedMetals) || (template === ElementCreatorTemplateTypeEnum.CasetteTemplate && !this.enableCassettes)) return false
    return true;
  }
}
