import { select, Store } from "@ngrx/store";
import { BehaviorSubject, Observable } from "rxjs";
import { PointerState } from "webcad/collision";
import {
  Aabb2,
  Aabb2Size,
  aabbOfPolyline,
  getEmptyAaabb,
  getPointsFromSegment,
  isAabb2Empty,
  IsPointInsideOfShape,
  isPolylineClosed,
  isSegmentCircle,
  Segment,
} from "webcad/models";
import { Step } from "../../../model/product-configuration/product-configuration.model";
import { SegmentCollider } from "../../../providers/colliders/segment.collider";
import { ClosestSegments } from "../../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../../providers/scene.provider";
import { TranslationProvider } from "../../../providers/translation.provider";
import {
  CalculateShapeFromPolylines,
  SetStep,
  SubmitImport,
} from "../../../store/actions";
import { SetHintMessage } from "../../../store/actions/drawing.actions";
import { SetError } from "../../../store/actions/errorable.actions";
import {
  getImportedShape,
  getPlate,
  getStep,
  MevacoState,
} from "../../../store/reducers";
import { createLineSystemWithDepthOffset } from "../../../visualizers/line-system";
import { Tool } from "../../tool.interface";
import {Color4, LinesMesh, Scene, Vector3} from "webcad/babylonjs/core";
export interface PolylineAndAaBb {
  polyline: Segment[];
  aabb: Aabb2;
}

export class ImportSelectTool extends Tool {
  private shape: Segment[][] = [];
  private shapeToImport: Segment[][] = [];
  private currentImportOutlineMesh: LinesMesh;
  private scene: Scene;
  private currentShapeOutlineMesh: LinesMesh;
  private depth: number;
  private biggestPolylineAndAaBb: PolylineAndAaBb = {
    aabb: getEmptyAaabb(),
    polyline: [],
  };
  private canImport: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private step: Step;

  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private translationProvider: TranslationProvider
  ) {
    super();
    this.store.pipe(select(getImportedShape)).subscribe((v) => {
      this.shape = v;
    });
    this.store.pipe(select(getPlate)).subscribe((plate) => {
      this.depth = plate.depth;
    });
    this.sceneProvider.getSubscription().subscribe((v) => {
      if (this.currentImportOutlineMesh) {
        this.currentImportOutlineMesh.dispose();
        this.currentImportOutlineMesh = null;
      }
      if (this.currentShapeOutlineMesh) {
        this.currentShapeOutlineMesh.dispose();
        this.currentShapeOutlineMesh = null;
      }
      if (v) {
        this.scene = v;
        if (this.shapeToImport) {
          console.log("SCENE PROVIDER SHAPE TO IMPORT");
          this.visualizeCurrentSelection();
        }
      }
    });
    this.store.pipe(select(getStep)).subscribe((v) => {
      this.step = v;
      if (this.scene) {
        switch (v) {
          case Step.import_pattern:
          case Step.import:
            if (this.currentImportOutlineMesh) {
              this.currentImportOutlineMesh.dispose();
              this.currentImportOutlineMesh = null;
            }
            if (this.currentShapeOutlineMesh) {
              this.currentShapeOutlineMesh.dispose();
              this.currentShapeOutlineMesh = null;
            }
            if (this.shapeToImport) {
              console.log("STEP IMPORT");
              this.visualizeCurrentSelection();
            }
            break;
        }
      }
    });
  }

  public CanImport(): Observable<boolean> {
    return this.canImport;
  }

  public submitImport(): void {
    if (this.shapeToImport) {
      if (this.step === Step.import) {
        this.store.dispatch(
          new CalculateShapeFromPolylines(this.shapeToImport)
        );
      }
      this.store.dispatch(new SubmitImport());
      this.shapeToImport = [];
      if (this.currentImportOutlineMesh) {
        this.currentImportOutlineMesh.dispose();
        this.currentImportOutlineMesh = null;
      }
      if (this.currentShapeOutlineMesh) {
        this.currentShapeOutlineMesh.dispose();
        this.currentShapeOutlineMesh = null;
      }
    }
  }

  public nextStep(): void {
    if (this.step === Step.import) {
      this.store.dispatch(new CalculateShapeFromPolylines(this.shapeToImport));
      this.store.dispatch(new SetStep(Step.import_pattern));
    } else if (this.step === Step.import_pattern) {
      this.store.dispatch(new SetStep(Step.import_attachment));
    }
  }

  activate() {
    this.reset();
    this.store.dispatch(new SetHintMessage(this.translate("startImportHint")));
  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {}

  onMouseClick(pointerState: PointerState) {
    this.importShapeClick(pointerState);
  }

  onMouseDown(pointerState: PointerState) {}

  onMouseMove(pointerState: PointerState) {}

  onMouseUp(pointerState: PointerState) {}

  reset() {
    this.shapeToImport = [];
    this.findBiggestPolylineAndAabb();
    this.validateImportShape();
    this.visualizeCurrentSelection();
    console.log("RESET VISUALIZE CURRENT SELECTION");

    if (this.currentImportOutlineMesh) {
      this.currentImportOutlineMesh.dispose();
      this.currentImportOutlineMesh = null;
    }
    if (this.currentShapeOutlineMesh) {
      this.currentShapeOutlineMesh.dispose();
      this.currentShapeOutlineMesh = null;
    }
  }

  importShapeClick(pointerState: PointerState) {
    if (pointerState.intersection) {
      if ((pointerState.intersection.collider as SegmentCollider).segment) {
        const segment = (pointerState.intersection.collider as SegmentCollider)
          .segment;
        if (this.isSegmentInImport(segment)) {
          console.log("IF");
          this.removePolylineOfSegment(segment);
        } else {
          console.log("ELSE");
          this.addPolylineOfSegment(segment);
        }
        this.visualizeCurrentSelection();
        console.log("IMPORT SHAPE CLICK");
      }
    }
  }

  visualizeCurrentSelection() {
    if (this.currentImportOutlineMesh) {
      this.currentImportOutlineMesh.dispose();
      this.currentImportOutlineMesh = null;
    }
    if (this.shapeToImport) {
      const outlinePoints: Vector3[][] = [];
      const colors: Color4[][] = [];
      const color = new Color4(0, 1, 0, 1);

      for (const conture of this.shapeToImport) {
        const outline: Vector3[] = [];
        const outlineColor: Color4[] = [];
        for (const p of conture) {
          const points = getPointsFromSegment(p);
          for (const point of points) {
            outline.push(new Vector3(point.x, point.y, 0));
            outlineColor.push(color);
          }
        }
        outline.push(outline[0]);
        outlineColor.push(outlineColor[0]);
        outlinePoints.push(outline);
        colors.push(outlineColor);
      }
      this.currentImportOutlineMesh = createLineSystemWithDepthOffset(
        "import Outline",
        {
          lines: outlinePoints,
          colors: colors,
        },
        this.scene,
        -0.001
      );
      this.currentImportOutlineMesh.isPickable = true;
      this.currentImportOutlineMesh.renderingGroupId = 4;

      console.log(this.currentImportOutlineMesh);
    }
  }
  /*
  visualizeShape() {
    if (this.currentShapeOutlineMesh) {
      this.currentShapeOutlineMesh.dispose();
      this.currentShapeOutlineMesh = null;
    }
    if (this.shape && this.scene) {
      const outlinePoints: BABYLON.Vector3[][] = [];
      const colors: BABYLON.Color4[][] = [];
      const color = new BABYLON.Color4(0, 1, 0, 1);

      for (const conture of this.shape) {
        const outline: BABYLON.Vector3[] = [];
        const outlineColor: BABYLON.Color4[] = [];
        for (const p of conture) {
          const points = getPointsFromSegment(p);
          for (const point of points) {
            outline.push(new BABYLON.Vector3(point.x, point.y, 0));
            outlineColor.push(color);
          }
        }
        outline.push(outline[0]);
        outlineColor.push(outlineColor[0]);
        outlinePoints.push(outline);
        colors.push(outlineColor);
      }
      if (this.shape.length > 0 && this.scene) {
        this.currentShapeOutlineMesh = BABYLON.MeshBuilder.CreateLineSystem('shape Outline', {
          lines: outlinePoints,
          colors: colors
        }, this.scene);
        this.currentShapeOutlineMesh.isPickable = false;
        this.currentShapeOutlineMesh.renderingGroupId = 3;
        this.currentShapeOutlineMesh.onBeforeRenderObservable.add((ed, es) => {
          this.scene.getEngine().setDepthBuffer(false);
        });
        this.currentShapeOutlineMesh.onAfterRenderObservable.add((ed, es) => {
          this.scene.getEngine().setDepthBuffer(true);
        });
      }
    }
  }
*/
  isSegmentInImport(segment: Segment): boolean {
    if (this.shapeToImport) {
      for (const c of this.shapeToImport) {
        for (const s of c) {
          if (s === segment) {
            return true;
          }
        }
      }
    }
    return false;
  }

  addPolylineOfSegment(segment: Segment): void {
    if (this.shapeToImport) {
      for (const c of this.shape) {
        for (const s of c) {
          if (s === segment) {
            if (
              (c.length === 1 && !isSegmentCircle(s)) ||
              (c.length > 1 && !isPolylineClosed(c))
            ) {
              this.store.dispatch(new SetError("InvalidShapeError"));
              return;
            }
            this.checkForSmallShape(c);
            this.shapeToImport.push(c);
            this.findBiggestPolylineAndAabb();
            this.validateImportShape();
            return;
          }
        }
      }
    }
  }

  removePolylineOfSegment(segment: Segment): void {
    if (this.shapeToImport) {
      for (let c = 0; c < this.shapeToImport.length; c++) {
        for (const s of this.shapeToImport[c]) {
          if (s === segment) {
            this.shapeToImport.splice(c, 1);
            this.findBiggestPolylineAndAabb();
            this.validateImportShape();
            return;
          }
        }
      }
    }
  }

  findBiggestPolylineAndAabb(): PolylineAndAaBb {
    let biggestSize = 0;
    let biggestPolylineAndAabb: PolylineAndAaBb = null;
    for (const p of this.shapeToImport) {
      const aabb = aabbOfPolyline(p);
      const size = Aabb2Size(aabb);
      if (size > biggestSize) {
        biggestPolylineAndAabb = {
          polyline: p,
          aabb: aabb,
        };
        biggestSize = size;
      }
    }
    this.biggestPolylineAndAaBb = biggestPolylineAndAabb;
    return biggestPolylineAndAabb;
  }

  validateImportShape(): boolean {
    if (
      !this.biggestPolylineAndAaBb ||
      !this.biggestPolylineAndAaBb.aabb ||
      isAabb2Empty(this.biggestPolylineAndAaBb.aabb) ||
      !this.biggestPolylineAndAaBb.polyline ||
      this.biggestPolylineAndAaBb.polyline.length <= 0
    ) {
      this.canImport.next(false);
      return false;
    }
    for (const p of this.shapeToImport) {
      if (p !== this.biggestPolylineAndAaBb.polyline) {
        if (!this.validatePolyline(p)) {
          return false;
        }
      }
    }
    this.canImport.next(true);
    return true;
  }

  validatePolyline(p: Segment[]): boolean {
    if (
      isAabb2Empty(this.biggestPolylineAndAaBb.aabb) ||
      !this.biggestPolylineAndAaBb.polyline ||
      this.biggestPolylineAndAaBb.polyline.length <= 0
    ) {
      return true;
    }
    const aabb = aabbOfPolyline(p);
    const minIn = IsPointInsideOfShape(
      aabb.min,
      this.biggestPolylineAndAaBb.polyline
    );
    const maxIn = IsPointInsideOfShape(
      aabb.max,
      this.biggestPolylineAndAaBb.polyline
    );
    if (!minIn && !maxIn) {
      this.store.dispatch(new SetError(this.translate("MultipleContours")));
      this.canImport.next(false);
      return false;
    } else if ((!minIn && maxIn) || (minIn && !maxIn)) {
      this.store.dispatch(new SetError(this.translate("ShapesIntersection")));
      this.canImport.next(false);
      return false;
    }
    return true;
  }

  checkForSmallShape(p: Segment[], maxSize: number = 0.05): boolean {
    const aabb = aabbOfPolyline(p);
    const size = Aabb2Size(aabb);
    if (size < maxSize) {
      this.store.dispatch(new SetError(this.translate("SmallShapeAdded")));
      return true;
    }
  }

  onCancel() {}

  onConfirm() {}

  isDirty() {
    return this._dirty;
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}
