import { AssetsProvider } from "./assets.provider";
import {Engine, Mesh, Scene, SceneLoader, Texture} from "@babylonjs/core";

export class BabylonAssetsProvider implements AssetsProvider {
  private loadPromise: Promise<Scene>;
  private texturesCache: Map<string, Texture>;

  constructor(
    private engine: Engine,
    private rootUrl: string,
    private assetsFile: string,
    private textureRootPath: string
  ) {
    engine.enableOfflineSupport = false;
    this.texturesCache = new Map<string, Texture>();
  }

  /*
    init(engine:BABYLON.Engine, rootUrl:string, assetsFile:string){
      this.engine = engine;
    }*/

  private getLoadPromise(): Promise<Scene> {
    if (!this.loadPromise) {
      this.loadPromise = new Promise((resolve, reject) => {
        SceneLoader.Load(
          this.rootUrl /*'/assets/'*/,
          this.assetsFile /* 'wall.babylon'*/,
          this.engine,
          (scene) => {
            resolve(scene);
          },
          null,
          (scene, message, exception) => {
            reject(message);
          }
        );
      });
    }
    return this.loadPromise;
  }

  //lazy
  getMesh(name: string): Promise<Mesh> {
    return new Promise<Mesh>((resolve, reject) => {
      this.getLoadPromise().then((scene) => {
        const mesh = scene.getMeshByName(name) as Mesh;
        if (mesh) {
          resolve(scene.getMeshByName(name) as Mesh);
        } else {
          reject(new Error("Mesh not found in assets file: " + name));
        }
      }, reject);
    });
  }

  getTexture(name: string, scene: Scene): Texture {
    const texture = this.texturesCache.get(name);
    if (texture) {
      return texture;
    } else {
      if (this.textureRootPath) {
        this.texturesCache.set(
          name,
          new Texture(this.textureRootPath + name, scene)
        );
        return this.texturesCache.get(name);
      } else {
        return null;
      }
    }
  }
}
