import {Action} from '@ngrx/store';
import {
  BalconyCorner, BalconyCornerInputFlags,
  BasicPlateParams,
  MultiShape,
  Parallelogram,
  RectangleNotch, RectangleNotchInputFlags, Staircase, Trapeze
} from '../../model/basic-templates.model';

export const SET_BASIC_RECTANGLE_TEMPLATE = 'SET BASIC RECTANGLE TEMPALTE';

export class SetBasicRectangleTemplate implements Action {
  readonly type: string = SET_BASIC_RECTANGLE_TEMPLATE;

  constructor(public payload: BasicPlateParams) {

  }

}

export const SET_BASIC_A = 'SET BASIC A';

export class SetBasicA implements Action {
  readonly type: string = SET_BASIC_A;
  constructor(public payload: any) {
  }
}



export const SET_BASIC_E1 = 'SET BASIC E1';

export class SetBasicE1 implements Action {
  readonly type: string = SET_BASIC_E1;

  constructor(public payload: any) {

  }

}

export const SET_BASIC_E2 = 'SET BASIC E2';

export class SetBasicE2 implements Action {
  readonly type: string = SET_BASIC_E2;

  constructor(public payload: any) {

  }

}

export const SET_BASIC_B = 'SET BASIC B';

export class SetBasicB implements Action {
  readonly type: string = SET_BASIC_B;

  constructor(public payload: any) {

  }

}

export const SET_BASIC_F1 = 'SET BASIC F1';

export class SetBasicF1 implements Action {
  readonly type: string = SET_BASIC_F1;

  constructor(public payload: any) {

  }

}

export const SET_BASIC_F2 = 'SET BASIC F2';

export class SetBasicF2 implements Action {
  readonly type: string = SET_BASIC_F2;

  constructor(public payload: any) {

  }

}

export const SET_EXPANDED_METAL_WIDTH = 'SET_EXPANDED_METAL_WIDTH';
export class SetExpandedMetalWidth implements Action{
  readonly type = SET_EXPANDED_METAL_WIDTH;
  
  constructor(public payload: number){}
  
}

export const SET_EXPANDED_METAL_HEIGHT = 'SET_EXPANDED_METAL_HEIGHT';
export class SetExpandedMetalHeight implements Action{
  readonly type = SET_EXPANDED_METAL_HEIGHT;
  
  constructor(public payload: number){}
  
}

export const SET_RECTANGLE_NOTCH_TEMPLATE = 'SET RECTANGLE NOTCH TEMPLATE';

export class SetRectangleNotchTemplate implements Action {
  readonly type: string = SET_RECTANGLE_NOTCH_TEMPLATE;

  constructor(public payload: RectangleNotch) {

  }
}

export const SET_RECTANGLE_NOTCH_A = 'SET RECTANGLE NOTCH A';

export class SetRectangleNotchA implements Action {
  readonly type: string = SET_RECTANGLE_NOTCH_A;

  constructor(public payload: RectangleNotch) {

  }
}

export const SET_RECTANGLE_NOTCH_E1 = 'SET RECTANGLE NOTCH E1';

export class SetRectangleNotchE1 implements Action {
  readonly type: string = SET_RECTANGLE_NOTCH_E1;

  constructor(public payload: RectangleNotch) {

  }
}

export const SET_RECTANGLE_NOTCH_E2 = 'SET RECTANGLE NOTCH E2';

export class SetRectangleNotchE2 implements Action {
  readonly type: string = SET_RECTANGLE_NOTCH_E2;

  constructor(public payload: RectangleNotch) {

  }
}

export const SET_RECTANGLE_NOTCH_B = 'SET RECTANGLE NOTCH B';

export class SetRectangleNotchB implements Action {
  readonly type: string = SET_RECTANGLE_NOTCH_B;

  constructor(public payload: RectangleNotch) {

  }
}

export const SET_RECTANGLE_NOTCH_F1 = 'SET RECTANGLE NOTCH F1';

export class SetRectangleNotchF1 implements Action {
  readonly type: string = SET_RECTANGLE_NOTCH_F1;

  constructor(public payload: RectangleNotch) {

  }
}

export const SET_RECTANGLE_NOTCH_F2 = 'SET RECTANGLE NOTCH F2';

export class SetRectangleNotchF2 implements Action {
  readonly type: string = SET_RECTANGLE_NOTCH_F2;

  constructor(public payload: RectangleNotch) {

  }
}

export const SET_DEFAULT_VALUES = 'SET DEFAULT VALUES';

export class SetDefaultValues implements Action{
  readonly type: string = SET_DEFAULT_VALUES;

  constructor(public payload: any = null){

  }
}

export const SET_BALCONY_CORNER_TEMPLATE = 'SET BALCONY CORNER TEMPLATE';

export class SetBalconyCornerTemplate implements Action {
  readonly type: string = SET_BALCONY_CORNER_TEMPLATE;

  constructor(public payload: BalconyCorner) {

  }
}

export const SET_BALCONY_CORNER_A = 'SET BALCONY CORNER A';

export class SetBalconyCornerA implements Action {
  readonly type: string = SET_BALCONY_CORNER_A;

  constructor(public payload: BalconyCorner) {

  }
}

export const SET_BALCONY_CORNER_B = 'SET BALCONY CORNER B';

export class SetBalconyCornerB implements Action {
  readonly type: string = SET_BALCONY_CORNER_B;

  constructor(public payload: BalconyCorner) {

  }
}

export const SET_BALCONY_CORNER_B1 = 'SET BALCONY CORNER B1';

export class SetBalconyCornerB1 implements Action {
  readonly type: string = SET_BALCONY_CORNER_B1;

  constructor(public payload: BalconyCorner) {

  }
}

export const SET_BALCONY_CORNER_B2 = 'SET BALCONY CORNER B2';

export class SetBalconyCornerB2 implements Action {
  readonly type: string = SET_BALCONY_CORNER_B2;

  constructor(public payload: BalconyCorner) {

  }
}

export const SET_BALCONY_CORNER_E1 = 'SET BALCONY CORNER E1';

export class SetBalconyCornerE1 implements Action {
  readonly type: string = SET_BALCONY_CORNER_E1;

  constructor(public payload: BalconyCorner) {

  }
}

export const SET_BALCONY_CORNER_E2 = 'SET BALCONY CORNER E2';

export class SetBalconyCornerE2 implements Action {
  readonly type: string = SET_BALCONY_CORNER_E2;

  constructor(public payload: BalconyCorner) {

  }
}

export const SET_BALCONY_CORNER_F1 = 'SET BALCONY CORNER F1';

export class SetBalconyCornerF1 implements Action {
  readonly type: string = SET_BALCONY_CORNER_F1;

  constructor(public payload: BalconyCorner) {

  }
}

export const SET_BALCONY_CORNER_F2 = 'SET BALCONY CORNER F2';

export class SetBalconyCornerF2 implements Action {
  readonly type: string = SET_BALCONY_CORNER_F2;

  constructor(public payload: BalconyCorner) {

  }
}

export const SET_BALCONY_CORNER_F3 = 'SET BALCONY CORNER F3';

export class SetBalconyCornerF3 implements Action {
  readonly type: string = SET_BALCONY_CORNER_F3;

  constructor(public payload: BalconyCorner) {

  }
}

export const SET_MULTI_SHAPE_TEMPLATE = 'SET MULTI SHAPE TEMPLATE';

export class SetMultiShapeTemplate implements Action {
  readonly type: string = SET_MULTI_SHAPE_TEMPLATE;

  constructor(public payload: MultiShape) {

  }
}

export const SET_MULTI_SHAPE_A = 'SET MULTI SHAPE A';

export class SetMultiShapeA implements Action {
  readonly type: string = SET_MULTI_SHAPE_A;

  constructor(public payload: any) {

  }
}

export const SET_MULTI_SHAPE_E1 = 'SET MULTI SHAPE E1';

export class SetMultiShapeE1 implements Action {
  readonly type: string = SET_MULTI_SHAPE_E1;

  constructor(public payload: any) {

  }
}

export const SET_MULTI_SHAPE_E2 = 'SET MULTI SHAPE E2';

export class SetMultiShapeE2 implements Action {
  readonly type: string = SET_MULTI_SHAPE_E2;

  constructor(public payload: any) {

  }
}

export const SET_MULTI_SHAPE_B = 'SET MULTI SHAPE B';

export class SetMultiShapeB implements Action {
  readonly type: string = SET_MULTI_SHAPE_B;

  constructor(public payload: any) {

  }
}

export const SET_MULTI_SHAPE_F1 = 'SET MULTI SHAPE F1';

export class SetMultiShapeF1 implements Action {
  readonly type: string = SET_MULTI_SHAPE_F1;

  constructor(public payload: any) {

  }
}

export const SET_MULTI_SHAPE_F2 = 'SET MULTI SHAPE F2';

export class SetMultiShapeF2 implements Action {
  readonly type: string = SET_MULTI_SHAPE_F2;

  constructor(public payload: any) {

  }
}

export const SET_PARALLELOGRAM_TEMPLATE = 'SET PARALLELOGRAM TEMPLATE';

export class SetParallelogramTemplate implements Action {
  readonly type: string = SET_PARALLELOGRAM_TEMPLATE;

  constructor(public payload: Parallelogram) {

  }
}

export const SET_STAIRCASE_TEMPLATE = 'SET STAIRCASE TEMPLATE';

export class SetStaircaseTemplate implements Action {
  readonly type: string = SET_STAIRCASE_TEMPLATE;

  constructor(public payload: Staircase) {

  }
}

export const SET_STAIRCASE_TILT = 'SET STAIRCASE TILT';

export class SetStaircaseTilt implements Action {
  readonly type: string = SET_STAIRCASE_TILT;

  constructor(public payload: any) {

  }
}

export const SET_PARALLELOGRAM_TILT = 'SET PARALLELOGRAM TILT';

export class SetParallelogramTilt implements Action {
  readonly type: string = SET_PARALLELOGRAM_TILT;

  constructor(public payload: any) {

  }
}

export const SET_PARALLELOGRAM_A = 'SET PARALLELOGRAM A';

export class SetParallelogramA implements Action {
  readonly type: string = SET_PARALLELOGRAM_A;

  constructor(public payload: any) {

  }
}

export const SET_PARALLELOGRAM_B1 = 'SET PARALLELOGRAM B1';

export class SetParallelogramB1 implements Action {
  readonly type: string = SET_PARALLELOGRAM_B1;

  constructor(public payload: any) {

  }
}

export const SET_PARALLELOGRAM_HA = 'SET PARALLELOGRAM HA';

export class SetParallelogramHa implements Action {
  readonly type: string = SET_PARALLELOGRAM_HA;

  constructor(public payload: any) {

  }
}

export const SET_MULTISHAPE_COLUMNS_NUMBER = 'SET MULTISHAPE COLUMNS NUMBER';

export class SetMultishapeColumnsNumber implements Action {
  readonly type: string = SET_MULTISHAPE_COLUMNS_NUMBER;

  constructor(public payload: any) {

  }
}

export const SET_MULTISHAPE_ROWS_NUMBER = 'SET MULTISHAPE ROWS NUMBER';

export class SetMultishapeRowsNumber implements Action {
  readonly type: string = SET_MULTISHAPE_ROWS_NUMBER;

  constructor(public payload: any) {

  }
}

export interface MultiShapeRowOrColumn {
  value: any;
  margin: number;
  index: number;
}

export const SET_MULTISHAPE_ROW = 'SET MULTISHAPE ROW';

export class SetMultishapeRow implements Action {
  readonly type: string = SET_MULTISHAPE_ROW;

  constructor(public payload: MultiShapeRowOrColumn) {

  }
}

export const SET_MULTISHAPE_COLUMN = 'SET MULTISHAPE COLUMN';

export class SetMultishapeColumn implements Action {
  readonly type: string = SET_MULTISHAPE_COLUMN;

  constructor(public payload: MultiShapeRowOrColumn) {

  }
}

export interface BalconyNotchParams {
  x: number;
  y: number;
  index: number;
  fill: BalconyCornerInputFlags;
}

export interface RectangleNotchParams {
  x: number;
  y: number;
  index: number;
  fill: RectangleNotchInputFlags;
}

export const SET_BALCONY_CORNER_NOTCH_X = 'SET BALCONY CORNER NOTCH X';

export class SetBalconyCornerNotchX implements Action {
  readonly type: string = SET_BALCONY_CORNER_NOTCH_X;

  constructor(public payload: BalconyNotchParams) {
  }
}

export const SET_RECTANGLE_NOTCH_NOTCH_X = 'SET RECTANGLE NOTCH NOTCH X';

export class SetRectangleNotchNotchX implements Action {
  readonly type: string = SET_RECTANGLE_NOTCH_NOTCH_X;

  constructor(public payload: RectangleNotchParams) {
  }
}

export const SET_BALCONY_CORNER_NOTCH_Y = 'SET BALCONY CORNER NOTCH Y';

export class SetBalconyCornerNotchY implements Action {
  readonly type: string = SET_BALCONY_CORNER_NOTCH_Y;

  constructor(public payload: BalconyNotchParams) {
  }
}

export const SET_RECTANGLE_NOTCH_NOTCH_Y = 'SET RECTANGLE NOTCH NOTCH Y';

export class SetRectangleNotchNotchY implements Action {
  readonly type: string = SET_RECTANGLE_NOTCH_NOTCH_Y;

  constructor(public payload: RectangleNotchParams) {
  }
}

export const SET_TRAPEZE_TEMPLATE = 'SET TRAPEZE TEMPLATE';

export class SetTrapezeTemplate implements Action {
  readonly type: string = SET_TRAPEZE_TEMPLATE;

  constructor(public payload: Trapeze) {
  }

}

export const SET_TRAPEZE_A = 'SET TRAPEZE A';

export class SetTrapezeA implements Action {
  readonly type: string = SET_TRAPEZE_A;

  constructor(public payload: any) {
  }

}

export const SET_TRAPEZE_HB = 'SET TRAPEZE HB';

export class SetTrapezeHB implements Action {
  readonly type: string = SET_TRAPEZE_HB;

  constructor(public payload: any) {
  }

}

export const SET_TRAPEZE_HA1 = 'SET TRAPEZE HA1';

export class SetTrapezeHA1 implements Action {
  readonly type: string = SET_TRAPEZE_HA1;

  constructor(public payload: any) {
  }

}

export const SET_TRAPEZE_ALPHA = 'SET TRAPEZE ALPHA';

export class SetTrapezeAlpha implements Action {
  readonly type: string = SET_TRAPEZE_ALPHA;

  constructor(public payload: any) {
  }

}

export const SET_TRAPEZE_B1 = 'SET TRAPEZE B1';

export class SetTrapezeB1 implements Action {
  readonly type: string = SET_TRAPEZE_B1;

  constructor(public payload: any) {
  }

}

export const SET_TRAPEZE_B2 = 'SET TRAPEZE B2';

export class SetTrapezeB2 implements Action {
  readonly type: string = SET_TRAPEZE_B2;

  constructor(public payload: any) {
  }

}

export const SET_TRAPEZE_BETA = 'SET TRAPEZE BETA';

export class SetTrapezeBeta implements Action {
  readonly type: string = SET_TRAPEZE_BETA;

  constructor(public payload: any) {
  }

}

export const SET_TRAPEZE_HA2 = 'SET TRAPEZE HA2';

export class SetTrapezeHA2 implements Action {
  readonly type: string = SET_TRAPEZE_HA2;

  constructor(public payload: any) {
  }

}

export type ConfigurableTemplatesActions =
  SetBalconyCornerTemplate
  | SetBasicRectangleTemplate
  | SetMultishapeColumn
  | SetMultishapeColumnsNumber
  | SetMultishapeRowsNumber
  | SetMultishapeRow
  | SetMultiShapeTemplate
  | SetParallelogramA
  | SetParallelogramHa
  | SetParallelogramB1
  | SetParallelogramTilt
  | SetParallelogramTemplate
  | SetStaircaseTemplate
  | SetStaircaseTilt
  | SetRectangleNotchTemplate
  | SetRectangleNotchNotchY
  | SetBalconyCornerNotchY
  | SetRectangleNotchNotchX
  | SetBalconyCornerNotchX
  | SetTrapezeTemplate
  | SetTrapezeA
  | SetTrapezeAlpha
  | SetTrapezeB1
  | SetTrapezeB2
  | SetTrapezeBeta
  | SetTrapezeHA1
  | SetTrapezeHA2
  | SetTrapezeHB;


