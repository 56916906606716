import {
  SET_MOUNTING,
  SET_MOUNTING_FORM,
  SET_MOUNTING_POSITIONING,
  SET_MOUNTING_SIZE
} from '../actions/mountings.actions';
import {MevacoPageActions} from '../actions';
import {initialMountingModel, MountingModel} from '../../model/mounting.model';

export function mountingsReducer(state: MountingModel = initialMountingModel, action: MevacoPageActions): MountingModel {
  switch (action.type) {
    case
    SET_MOUNTING_FORM:
      return {
        ...state,
        mounting: {
          ...state.mounting,
          form: action.payload,
          id: null,
          width: null,
          length: null
        }
      };
    case
    SET_MOUNTING_POSITIONING:
      return {
        ...state,
        mounting: {
          ...state.mounting,
          positioning: action.payload
        }
      };
    case
    SET_MOUNTING_SIZE:
      return {
        ...state,
        mounting: {
          ...state.mounting,
          width: action.payload.width,
          length: action.payload.length
        }
      };
    case
    SET_MOUNTING:
      return {
        ...state,
        mounting: action.payload
      };
  }
  return state;
}
