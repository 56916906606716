import {Aabb2} from "webcad/models";
import {PerforationAreaModel} from "../model/perforation-area.model";
import {areSimpleRectangles} from "../model/shape-with-holes";

class DependentModeValidator{
  private vertical:Row[] = [];
  private horizontal:Row[] = [];

  addAreaAabb(aabb:Aabb2){
    addValue(this.vertical, aabb.min.y);
    addValue(this.vertical, aabb.max.y);
    addValue(this.horizontal, aabb.min.x);
    addValue(this.horizontal, aabb.max.x);
  }

  dependentModePossible():boolean
  {
    return dependentModePossible(this.vertical) && dependentModePossible(this.horizontal);
  }

}

class Row
{
  position:number;
  count:number;
}

function dependentModePossible(list:Row[]):boolean
{
  for(let i = 1; i < list.length; i++)
  {
    if(list[0].count != list[i].count)
    {
      return false;
    }
  }
  return true;
}

function addValue(list:Row[], value:number):void
{
  var row = list.find(x => Math.abs(x.position - value) < 0.0001);
  if (row != null) {
    row.count++;
  } else {
    list.push({ position: value, count: 1 });
  }
}

export function dependentMode(perforationAreas:PerforationAreaModel[]) : boolean
{
  const dependentModeValidator:DependentModeValidator = new DependentModeValidator();
  for (let i = 0; i < perforationAreas.length; i++) {
    const perforationArea = perforationAreas[i];
    if(perforationArea.rotation !== 0) {
      return false;
    }
    if (perforationArea.shape != null)
    {
      if (perforationArea.shape.holes != null && perforationArea.shape.holes.length > 0){
        return false;
      }
      if (!areSimpleRectangles([perforationArea.shape])){
        return false;
      }
      dependentModeValidator.addAreaAabb(perforationArea.shape.aabb);
    }
  }
  return dependentModeValidator.dependentModePossible();
}
