import {DrawingTool} from '../drawing-tool.interface';
import {ClosestSegments} from '../../providers/mevaco-pointer.provider';
import {PointerState} from 'webcad/collision';
import {/*getZones,*/ MevacoState} from '../../store/reducers';
import {select, Store} from '@ngrx/store';
import {ShapeWithHoles} from '../../model/shape-with-holes';
import {inside2D, Vector2} from 'webcad/math';
import {PolylineToVector2Array} from 'webcad/models';
import {CreatePerforationAreaFromZone} from '../../store/actions/drawing.actions';

interface ZoneAndPoints {
  zone: ShapeWithHoles;
  points: Vector2[];
}

export class CassettePerforationCreatorTool extends DrawingTool {
  private zonesAndPoints: ZoneAndPoints[] = [];
  private margin: number = 0.01;

  constructor(private store: Store<MevacoState>) {
    super();
    // this.store.pipe(select(getZones)).subscribe((v) => {
    //   const newZonesAndPoints: ZoneAndPoints[] = [];
    //   for (const zone of v) {
    //     const segments = [...zone.conture];
    //     for (const hole of zone.holes) {
    //       segments.push(...hole);
    //     }
    //     newZonesAndPoints.push({zone: zone, points: PolylineToVector2Array(segments)});
    //   }
    //   this.zonesAndPoints = newZonesAndPoints;
    // });
  }

  public setMargin(newMargin: number) {
    this.margin = newMargin;
  }

  public getMargin() {
    return this.margin;
  }

  activate() {
  }

  isDirty(): boolean {
    return false;
  }

  onCancel() {
  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {
  }

  onConfirm() {
  }

  onMouseClick(pointerState: PointerState) {
    this.store.dispatch(new CreatePerforationAreaFromZone({
      margin: this.margin,
      point: pointerState.position,
    }));
    /*
    for (const zoneAndPoints of this.zonesAndPoints) {
      if (inside2D(pointerState.position, zoneAndPoints.points)) {
        this.store.dispatch(new CreatePerforationAreaFromZone({
          margin: this.margin,
          point: pointerState.position,
          shape: zoneAndPoints.zone
        }));
        break;
      }
    }

     */
  }

  onMouseDown(pointerState: PointerState) {
  }

  onMouseMove(pointerState: PointerState) {
  }

  onMouseUp(pointerState: PointerState) {
  }

  reset() {
  }

}
