import {Action} from '@ngrx/store';
import {Dataset, SingleTool} from '../../model/dataset/dataset.model';
import {ConfigurationMaterial} from '../../model/product-configuration/configuration-material.model';
import {ConfigurationItem} from '../../model/product-configuration/configuration-item.model';
import {LomoeItem} from '../../model/product-configuration/configuration-lomoe.model';
import {EwzItem} from '../../model/product-configuration/configuration-ewz.model';

export const DATASET_LOAD = 'DATASET_LOAD';
export const DATASET_LOADED_SUCCESSFULLY = 'DATASET_LOADED_SUCCESSFULLY';
export const DATASET_MATERIA_LOADED_SUCCESSFULLY = 'DATASET_MATERIA_LOADED_SUCCESSFULLY';
export const DATASET_LOMOE_EWZ_LOADED_SUCCESSFULLY = 'DATASET_LOMOE_EWZ_LOADED_SUCCESSFULLY';
export const SET_MATERIAL_SUCCESS = 'SET MATERIAL SUCCESS';
export const DATASET_WALLPAPER_LOADED_SUCCESSFULLY = 'DATASET_WALLPAPER_LOADED_SUCECSSFULLY';
export const WALLPAPER_AND_MATERIAL_LOADED_SUCCESSFULLY = 'WALLPAPER_AND_MATERIAL_LOADED_SUCCESSFULLY';
export const SINGLE_TOOLS_AND_MATERIAL_LOADED_SUCCESSFULLY = 'SINGLE_TOOLS_AND_MATERIAL_LOADED_SUCCESSFULLY';
export const DATASET_MATERIAL_WITH_LOMOE_LOADED_SUCCESSFULLY = 'DATASET_MATERIAL_WITH_LOMOE_LOADED_SUCCESSFULLY';
export const DATASET_LOMOE_EWZ_MATERIAL_GEOMATRIX_LOADED_SUCCESSFULLY = 'DATASET_LOMOE_EWZ_MATERIAL_GEOMATRIX_LOADED_SUCCESSFULLY';
export const GEOMATRIX_LOADED_SUCCESSFULLY = 'GEOMATRIX_LOADED_SUCCESSFULLY';
export const MATERIAL_FOR_WALLPAPER_LOADED_SUCCESSFULLY = 'MATERIAL_FOR_WALLPAPER_LOADED_SUCCESSFULLY';

export class SetMaterialSuccess implements Action {
  readonly type: string = SET_MATERIAL_SUCCESS;

  constructor(public payload: {material: ConfigurationItem, lomoe: LomoeItem}) {
  }
}

export class DatasetLoad implements Action {
  readonly type: string = DATASET_LOAD;

  constructor(public payload: string = null) {
  }
}

export class DatasetLoadedSuccessfully implements Action {
  readonly type: string = DATASET_LOADED_SUCCESSFULLY;

  constructor(public payload: Dataset) {
  }
}

export class DatasetMaterialLoadedSuccessfully implements Action {
  readonly type: string = DATASET_MATERIA_LOADED_SUCCESSFULLY;

  constructor(public payload: ConfigurationMaterial) {
  }
}


export class DatasetLomoeEwzLoadedSuccessfully implements Action {
  readonly type: string = DATASET_LOMOE_EWZ_LOADED_SUCCESSFULLY;

  constructor(public payload: {lomoe: LomoeItem, ewz: EwzItem, geomatrix: ConfigurationItem}) {
  }
}

export class DatasetLomoeEwzMaterialGeomatrixLoadedSuccessfully implements Action {
  readonly type: string = DATASET_LOMOE_EWZ_MATERIAL_GEOMATRIX_LOADED_SUCCESSFULLY;

  constructor(public payload: {lomoe: LomoeItem, ewz: EwzItem, material: ConfigurationItem}) {
  }
}
export class MaterialForWallpaperLoadedSuccessfully implements Action {
  readonly type: string = MATERIAL_FOR_WALLPAPER_LOADED_SUCCESSFULLY;

  constructor(public payload: ConfigurationItem) {
  }
}

export class DatasetMaterialWithLomoeLoadedSuccessfully implements Action {
  readonly type: string = DATASET_MATERIAL_WITH_LOMOE_LOADED_SUCCESSFULLY;

  constructor(public payload: ConfigurationItem) {
  }
}

export class DatasetWallpaperLoadedSuccessfully implements Action {
  readonly type: string = DATASET_WALLPAPER_LOADED_SUCCESSFULLY;

  constructor(public payload: ConfigurationItem) {
  }
}


export class WallpaperAndMaterialLoadedSuccessfully implements Action {
  readonly type: string = WALLPAPER_AND_MATERIAL_LOADED_SUCCESSFULLY;

  constructor(public payload: {wallpaper: ConfigurationItem, material: ConfigurationMaterial}) {
  }
}

export class SingleToolsAndMaterialLoadedSuccessfully implements Action {
  readonly type: string = SINGLE_TOOLS_AND_MATERIAL_LOADED_SUCCESSFULLY;

  constructor(public payload: {singleTools: SingleTool[], material: ConfigurationMaterial}) {
  }
}

export class GeomatrixLoadedSuccessfully implements Action {
  readonly type: string = GEOMATRIX_LOADED_SUCCESSFULLY;

  constructor(public payload: ConfigurationItem) {
  }
}

export type DatasetActions =
  DatasetLoad |
  DatasetLoadedSuccessfully |
  DatasetMaterialLoadedSuccessfully |
  DatasetLomoeEwzLoadedSuccessfully |
  DatasetWallpaperLoadedSuccessfully |
  SetMaterialSuccess;
