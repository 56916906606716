import { Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { Subscription } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import {
  Direction,
  FadingType,
  LinearDirection,
  RadialDirection,
  SelectedFading,
  createLinearFadingParams,
  createRadialFadingParams,
} from "../../model/fading.model";
import {
  Subpanel,
  SubpanelCurrentState,
  Subpanels,
} from "../../model/right-panel.model";
import { TranslationProvider } from "../../providers/translation.provider";
import {
  MevacoState,
  SetFadings,
  SetSubpanelState,
  getFadingInputsSelector,
  getFadingSubpanelState,
} from "../../store";

type Shape = {
  [shapeName: string]: { isSelected: boolean; direction: Direction };
};

@Component({
  selector: "app-fading-editor",
  templateUrl: "./fading-editor.component.html",
  styleUrls: ["./fading-editor.component.css"],
})
export class FadingEditorComponent implements OnInit, OnDestroy {
  public fadingSubpanelState: Subpanel;
  public SubpanelCurrentState = SubpanelCurrentState;
  public fadingType: FadingType;
  public fadingTypeEnum = FadingType;
  public squareTriangles: Shape = {
    firstTriangle: { isSelected: false, direction: LinearDirection.TOP },
    secondTriangle: { isSelected: false, direction: LinearDirection.LEFT },
    thirdTriangle: { isSelected: false, direction: LinearDirection.BOTTOM },
    fourthTriangle: { isSelected: false, direction: LinearDirection.RIGHT },
  };
  public squareShapes: Shape = {
    firstShape: { isSelected: false, direction: RadialDirection.TOP_RIGHT },
    secondShape: { isSelected: false, direction: RadialDirection.CENTER_RIGHT },
    thirdShape: { isSelected: false, direction: RadialDirection.BOTTOM_RIGHT },
    fourthShape: { isSelected: false, direction: RadialDirection.CENTER_LEFT },
    fifthShape: { isSelected: false, direction: RadialDirection.TOP },
    sixthShape: { isSelected: false, direction: RadialDirection.BOTTOM },
    seventhShape: { isSelected: false, direction: RadialDirection.BOTTOM_LEFT },
    eighthShape: { isSelected: false, direction: RadialDirection.TOP_LEFT },
    ninthShape: { isSelected: false, direction: RadialDirection.CENTER },
  };
  public density: number = 0;
  public range: number = 0;
  public rangeControl: FormControl = new FormControl(0);
  public densityControl: FormControl = new FormControl(0);

  private ids: string[] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<MevacoState>,
    private translationProvider: TranslationProvider,
    private ren: Renderer2
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .pipe(select(getFadingSubpanelState))
        .subscribe(
          (fadingSubpanel) => (this.fadingSubpanelState = fadingSubpanel)
        ),
      this.store
        .pipe(
          select(getFadingInputsSelector),
          filter((x) => !!x)
        )
        .subscribe((res) => this.initShapes(res))
    );

    this.subscriptions.push(
      this.rangeControl.valueChanges.pipe(debounceTime(500)).subscribe((v) => {
        this.changeRange(v);
      })
    );

    this.subscriptions.push(
      this.densityControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe((v) => {
          this.changeDensity(v);
        })
    );
  }

  toggleFadingSubpanel() {
    if (this.fadingSubpanelState.state === SubpanelCurrentState.OPENED) {
      this.store.dispatch(
        new SetSubpanelState({
          name: Subpanels.FADING_SUBPANEL,
          state: SubpanelCurrentState.CLOSED,
        })
      );
    } else {
      this.store.dispatch(
        new SetSubpanelState({
          name: Subpanels.FADING_SUBPANEL,
          state: SubpanelCurrentState.OPENED,
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  initShapes(fading: SelectedFading) {
    this.ids = fading.additionalSingleToolsIds;
    /// selected
    this.fadingType = fading.fadingType;
    this.density = fading.density;
    this.range = fading.range;
    if (fading.fadingType == FadingType.LINEAR) {
      Object.values(this.squareTriangles)
        .filter(
          (shape) =>
            (shape.direction & fading.linearDirections) == shape.direction
        )
        .forEach((shape) => (shape.isSelected = true));
    }

    Object.values(this.squareShapes)
      .filter(
        (shape) =>
          (shape.direction & fading.radialDirections) == shape.direction
      )
      .forEach((shape) => (shape.isSelected = true));
  }

  autoTicks = false;
  disabled = false;
  invert = false;
  max = 200;
  min = 50;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  value = 7;
  vertical = false;
  tickInterval = 1;
  color = "primary";

  myValue: any;

  getSliderTickInterval(): number | "auto" {
    if (this.showTicks) {
      return this.autoTicks ? "auto" : this.tickInterval;
    }

    return 0;
  }

  toggleTriangle(triangle: string) {
    if (this.fadingType !== FadingType.LINEAR) {
      this.setFadingType(FadingType.LINEAR);
      for (const t in this.squareTriangles) {
        this.squareTriangles[t].isSelected = false;
      }
      this.squareTriangles[triangle].isSelected = true;
    } else {
      this.squareTriangles[triangle].isSelected =
        !this.squareTriangles[triangle].isSelected;
    }
    this.setFading();
  }

  toggleShape(shape: string) {
    if (this.fadingType != FadingType.RADIAL) {
      this.setFadingType(FadingType.RADIAL);
      for (const t in this.squareShapes) {
        this.squareShapes[t].isSelected = false;
      }
      this.squareShapes[shape].isSelected = true;
    } else {
      this.squareShapes[shape].isSelected =
        !this.squareShapes[shape].isSelected;
    }
    this.setFading();
  }

  changeDensity($event) {
    this.density = $event;
    this.setFading();
  }

  changeRange($event) {
    this.range = $event;
    this.setFading();
  }

  translate(text: string, module: string = "designer") {
    return this.translationProvider.translate(text, module);
  }

  setFadingType(fadingType: FadingType) {
    if (this.fadingType === fadingType) {
      this.resetFading();
    } else {
      this.fadingType = fadingType;
    }
    this.setFading();
  }

  getDirections(shape: Shape): Direction {
    if (Object.values(shape).every((x) => !x.isSelected)) return null;

    return Object.values(shape)
      .filter((x) => x.isSelected)
      .map((x) => x.direction)
      .reduce((x, acc) => (acc == null ? x : acc | x));
  }

  resetFading() {
    this.fadingType = undefined;
    Object.values(this.squareShapes).forEach(
      (shape) => (shape.isSelected = false)
    );
    Object.values(this.squareTriangles).forEach(
      (shape) => (shape.isSelected = false)
    );
  }

  saveFading(
    direction: Direction,
    fadingCtor: (
      direction: Direction,
      density: number,
      range: number,
      ids: string[]
    ) => SelectedFading
  ): void {
    if (direction == null) {
      this.store.dispatch(new SetFadings(null));
    } else {
      this.store.dispatch(
        new SetFadings(
          fadingCtor(direction, this.density, this.range, this.ids)
        )
      );
    }
  }

  setFading() {
    if (this.fadingType == FadingType.LINEAR) {
      this.saveFading(
        this.getDirections(this.squareTriangles),
        createLinearFadingParams
      );
    } else if (this.fadingType == FadingType.RADIAL) {
      this.saveFading(
        this.getDirections(this.squareShapes),
        createRadialFadingParams
      );
    } else {
      this.store.dispatch(new SetFadings(null));
    }
  }
}
