import {Component, OnInit} from '@angular/core';
import {TranslationProvider} from '../../providers/translation.provider';
import {ProductType, Step} from '../../model/product-configuration/product-configuration.model';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {getStep, MevacoState, isTrustedOrganizationUnit, logoUrl, getProductType} from '../../store/reducers';
import { SetStep} from '../../store/actions';
import {SvgIconProvider} from '../../providers/svg-icon.provider';
import {environment} from "../../../environments/environment";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {ProductConfigurationService} from "../../services/product-configuration.service";
import {map} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import jwtDecode from 'jwt-decode';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  animations: [
    trigger('activation', [
      state('active', style({
        transform: "rotate(90deg)"
      })),
      state('inactive', style({
        transform: "rotate(0)"
      })),
      transition('active => inactive', [
        animate('0.1s')
      ]),
      transition('inactive => active', [
        animate('0.2s')
      ])
    ])
  ],
})
export class NavigationComponent implements OnInit {

  public step: Observable<Step>;
  public Step = Step;
  public hasId = false;
  public environment = environment;
  public isTutorialModal = false;
  public logoUrl = this.store.pipe( select(logoUrl) );
  productType: ProductType;

  constructor(
    private store: Store<MevacoState>,
    private translations: TranslationProvider,
    private svgIconProvider: SvgIconProvider,
    private modalService: NgbModal,
    private productConfigurationService: ProductConfigurationService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  translate(text: string, module: string = 'configurator') {
    return this.translations.translate(text, module);
  }

  ngOnInit() {
    this.registerData();
  }

  setStep(step: Step) {
    if (step === Step.template || step === Step.cockpit || this.hasId) {
      this.store.dispatch(new SetStep(step));
    }
    // if(step === Step.pattern){
    //   this.store.dispatch(new SetPerforationAutoCenter(true));
    // }
  }

  showTutorialModal(content: any) {
    // this.isTutorialModal = true;
    this.modalService.open(content, {
      centered: true,
      backdrop: "static",
      windowClass: 'custom-modal-window custom-modal-window--show-navigation',
      backdropClass: 'custom-modal-backdrop custom-modal-backdrop--show-navigation'
      }).result.then((result: any) => {
        if (result) {
          this.closeTutorialModal(result);
        }
      }, (reason) => {

      });
  }

  closeTutorialModal(e){
    this.isTutorialModal = !e.isClose;
  }

  showExitModal(content: any) {
    this.modalService.open(content, {
      centered: true,
      backdrop: "static",
      windowClass: 'custom-modal-window custom-modal-window--show-navigation',
      backdropClass: 'custom-modal-backdrop custom-modal-backdrop--show-navigation'
      });
  }

  async exit() {
    const jwt = Object.keys(this.activatedRoute.snapshot.queryParams)[0];
    const payload: any = jwtDecode(jwt);
    await this.productConfigurationService.cancelChanges(payload.configId);
    window.parent.postMessage({
      'func': 'onCloseDesigner',
      'message': {config_status: 'dropped'}
    }, '*');
  }
  registerData() {
    this.registerStep();
    this.registerPlatePosition();
    this.registerProductType();
  }

  registerProductType() {
    this.store.pipe(select(getProductType), map(x => x as ProductType)).subscribe(res =>  this.productType = res);
  }
  registerStep() {
    this.step = this.store.pipe(select(getStep));
  }
  registerPlatePosition() {
    this.store.pipe(select((state) => state.model.drawing.plate.position)).subscribe((v) => this.hasId = v > -1);
  }

  isNavigationStepVisible(selectedStep: Step){
    if(selectedStep === Step.attachment && this.productType === ProductType.ExtendedMetals) return false;
    return true;
  }
}
