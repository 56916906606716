export interface RightPanel{
    configuratorPanel: Subpanel;
    messageVisualizerPanel: Subpanel;
    effectsPanel: Subpanel;
    fadingPanel: Subpanel
}

export interface Subpanel{
    position: {
        top?: number;
        right?: number;
    };
    width: number;
    height?: number;
    order: number;
    state: SubpanelCurrentState;
}

export enum SubpanelCurrentState{
    CLOSED = "CLOSED",
    OPENED = "OPENED"
}

export enum Subpanels{
    CONFIGURATOR_SUBPANEL = "configuratorPanel",
    MESSAGE_VISUALIZER_SUBPANEL = "messageVisualizerPanel",
    EFFECTS_SUBPANEL = "effectsPanel",
    FADING_SUBPANEL = "fadingPanel"
}

