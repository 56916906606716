import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Action, Store, select } from "@ngrx/store";
import { Observable, Subscription, combineLatest, of } from "rxjs";
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";
import { FoilOption } from "../../model/dataset/dataset.model";
import { Element } from "../../model/product-configuration/element.model";
import { Step } from "../../model/product-configuration/product-configuration.model";
import { ReloadPositionProvider } from "../../providers/reload-position.provider";
import { TranslationProvider } from "../../providers/translation.provider";
import { PlateService } from "../../services/plate.service";
import { ProductConfigurationService } from "../../services/product-configuration.service";
import {
  ClearAllElements,
  DuplicateConfiguration,
  PerforationShapeChange,
  ProductConfigurationSetP1,
  ProductConfigurationSetP2,
  SetNoteActive,
  SetStep,
} from "../../store/actions";
import {
  MevacoState,
  currentElementImportId,
  customPatternEditorIsOpened,
  getConfigurationElements,
  getConfigurationId,
  getEnableNotesStatus,
  getProductConfiguration,
  isAnyBendedElement,
  isDxfAllowedOrganizationUnit,
  isMevacoUser,
  isTrustedOrganizationUnit,
  perfactionEditorIsOpened,
} from "../../store/reducers";
import { Dropdown, DropdownOption } from "../dropdown/dropdown.component";

interface ReloadState {
  reload: boolean;
  elements: Element[];
}
@Component({
  selector: "positions-list",
  templateUrl: "./positions-list.component.html",
  styleUrls: ["./positions-list.component.css"],
})
export class PositionsListComponent implements OnInit, OnDestroy {
  public enableNotesObservable: Observable<boolean>;
  public disableNotesOptionObservable: Observable<boolean>;

  public positions: Observable<Element[]>;

  private configurationId: string;
  private configId: Observable<string>;

  public perforation: Dropdown;
  public Step = Step;

  public isAnyItemOnList: boolean;

  public foilOptions: Observable<FoilOption[]>;

  public positionsArray: Element[] = [];
  public userInfo: Observable<any>;
  public areaOfAll = 0;
  public isMevacoUser: Observable<boolean>;
  public isTrustedOrganizationUnit = this.store.pipe(
    select(isTrustedOrganizationUnit)
  );
  public isDxfAllowedOrganizationUnit = this.store.pipe(
    select(isDxfAllowedOrganizationUnit)
  );

  public isList = false;

  public isTutorialModal = false;
  private subscriptions: Subscription[] = [];

  @Input() configuratorOpen = true;
  public isLowRes: boolean;
  public regex: Observable<string>;
  hideList = combineLatest([
    this.store.pipe(select(customPatternEditorIsOpened)),
    this.store.pipe(select(perfactionEditorIsOpened)),
  ]).pipe(map(([customPattern, perfaction]) => customPattern || perfaction));
  public importId: Observable<number | null>;

  @HostListener("window:resize", ["$event"]) onResize(event) {
    if (event.target.innerWidth >= 900 && event.target.innerWidth < 1264) {
      this.isLowRes = true;
    } else if (event.target.innerWidth >= 1264) {
      this.isLowRes = false;
    }
  }

  constructor(
    private store: Store<MevacoState>,
    private translationProvider: TranslationProvider,
    private modalService: NgbModal,
    public plateService: PlateService,
    private reloadPositionProvider: ReloadPositionProvider,
    private productConfigService: ProductConfigurationService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .pipe(select(getProductConfiguration))
        .subscribe((config) => (this.configurationId = config.configId))
    );

    this.positions = combineLatest(
      this.reloadPositionProvider.reloadObservable,
      this.store.pipe(select(getConfigurationElements))
    ).pipe(
      delay(0),
      startWith([{ reload: false, elements: [] }, []]),
      switchMap(([reload, elements]) => {
        return of({
          reload: reload,
          elements: elements,
        } as ReloadState);
      }),
      distinctUntilChanged((oldState: ReloadState, newState: ReloadState) => {
        return !newState.reload;
      }),
      map((value: ReloadState) => {
        if (!!value) {
          return value.elements.filter(
            (v) =>
              !!v &&
              !!v.shape &&
              !!v.shape.conture &&
              v.shape.conture.length > 0
          );
        } else {
          return [];
        }
      }),
      tap((elements) => {
        if (!!elements) {
          this.positionsArray = elements;
          this.isAnyItemOnList = elements.length > 0;
        }
      })
    );
    // this.store.dispatch(new ProductConfigurationLoad(this.configurationId));
    // this.store.dispatch(new DatasetLoad());
    // this.store.dispatch(new LoadMountings());

    this.configId = this.store.pipe(
      select(getConfigurationId),
      map((x) => x)
    );
    this.isMevacoUser = this.store.pipe(
      select(isMevacoUser),
      map((x) => x === "Yes")
    );

    this.userInfo = combineLatest(this.configId, this.isMevacoUser).pipe(
      switchMap(([id, isMevaco]) => {
        if (isMevaco && !!id) {
          return this.productConfigService.getUserInfo(id).pipe(
            map((user) => {
              return {
                name: user[0],
                id: user[1],
              };
            })
          );
        } else {
          return of({ name: "", id: "" });
        }
      })
    );

    this.perforation = this.createDropDown(
      "PerforationModel",
      this.store.pipe(
        select(
          (state) =>
            state.model.productConfiguration.perforation.perforationType
        )
      ) as Observable<string>,
      this.getPerforationsOptions(),
      (value) => new PerforationShapeChange(value)
    );

    this.enableNotesObservable = this.store.pipe(select(getEnableNotesStatus));
    this.disableNotesOptionObservable = this.store.pipe(
      select(isAnyBendedElement)
    );

    if (window.innerWidth >= 900 && window.innerWidth < 1264) {
      this.isLowRes = true;
    } else if (window.innerWidth >= 1264) {
      this.isLowRes = false;
    }
    this.importId = this.store.pipe(select(currentElementImportId));
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  createDropDown(
    name: string,
    value: Observable<string>,
    options: Observable<DropdownOption[]>,
    actionFactory: (string) => Action
  ) {
    return new Dropdown(name, options, value, (v) =>
      this.store.dispatch(actionFactory(v))
    );
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }

  private getPerforationsOptions(): Observable<DropdownOption[]> {
    return combineLatest(
      this.store.pipe(select((store) => store.model.dataset)),
      this.store.pipe(
        select(
          (store) => store.model.productConfiguration.perforation.geometryType
        )
      )
    ).pipe(
      debounceTime(1),
      map(([dataset, geomType]) => {
        if (!dataset) {
          return [];
        }
        const perfTypeData = dataset.perforationTypes.find(
          (x) => x.name === geomType
        );
        if (!perfTypeData) {
          return [];
        }
        return perfTypeData.perforations.map(
          (x) =>
            new DropdownOption(
              "withImage",
              {
                name: this.translate(x.name),
                imgUrl:
                  dataset.perforationAssetsUrl +
                  dataset.perforationIcons[x.name],
              },
              true,
              x.name
            )
        );
      })
    );
  }

  goToTemplates() {
    this.store.dispatch(new SetStep(Step.template));
  }

  clearAll() {
    this.store.dispatch(new ClearAllElements());
  }

  confirmPopup(content: any) {
    this.modalService
      .open(content, { centered: true, backdrop: "static" })
      .result.then(
        (result: boolean) => {
          if (result) {
            this.clearAll();
          }
        },
        () => {}
      );
  }

  public setP1(p1: string) {
    this.store.dispatch(new ProductConfigurationSetP1(p1));
  }

  public setP2(p2: string) {
    this.store.dispatch(new ProductConfigurationSetP2(p2));
  }

  downloadDxfs() {
    if (this.configurationId) {
      this.plateService.downloadAllDxfs(this.configurationId);
    }
  }

  toggleNotes(content: any) {
    this.store.dispatch(new SetNoteActive(content.source.checked));
  }

  showTutorialModal() {
    this.isTutorialModal = !this.isTutorialModal;
  }

  duplicateConfig(id: any) {
    if (!!id) {
      this.store.dispatch(new DuplicateConfiguration(id));
    }
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      if (!s.closed) {
        s.unsubscribe();
      }
    }
  }
  downloadImportDxf(importId: number): void {
    this.plateService.downloadImportDxf(importId);
  }
}
