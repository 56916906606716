
export enum TemplateName {
  none= "None",

  // SIMPLE
  basicRectangle = 'Basic Rectangle',
  expandedMetalBasicRectangle = "Expanded Metal Basic Rectangle", 
  rectangleNotch = 'Rectangle Notch',
  balconyCorner = 'Balcony Corner',
  multiShape = 'Multi Shape',
  parallelogram = 'Parallelogram',
  trapeze =  'Trapeze',
  staircase = 'Staircase',

  //CASSETTES
  umschlag = 'Umschlag',
  singleBend = 'Single bend',
  twoBend = 'Two bend',
  korb = 'Korb',
  flat = 'Flat'

}
