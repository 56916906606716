<div class="statuses-container">
  <div class="status-item">
    <div class="coordinate-item">
      <span class="status-label">x: </span>
      <span class="status-text">{{(pointer|async).x.toFixed(1)}}</span>
    </div>
    <div class="coordinate-item">
      <span class="status-label"> y: </span>
      <span class="status-text">{{(pointer|async).y.toFixed(1)}}</span>
    </div>
  </div>
  
    <div *ngIf="isWorking | async" class="spinner">
      <div class="bounce-first"></div>
      <div class="bounce-second"></div>
      <div class="bounce-third"></div>
    </div>

  <div class="status-item">
    <span class="status-label">{{translate("Basic Unit")}}: </span>
    <span class="status-text">[mm]</span>
  </div>
  <div class="status-item">
    <span class="status-label">{{translate("Polishing Direction")}}:</span>
    <span class="status-arrow-container">
      <img class="status-arrow" src="assets/right-arrows.svg" alt="arrow">
    </span>
  </div>
  <div class="status-item">
    <span class="status-label">{{translate(sideDesc|async)}}</span>
  </div>
  <span class="status-text"> &#32;</span>
  <div class="status-item">
    <span class="status-label">{{translate("Grid Size")}}: </span>
    <span class="status-text">{{(gridSize | async)}} [m]</span>
  </div>
  
</div>
