import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, throwError as observableThrowError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { Vector2 } from "webcad/math";
import { Segment, getPointsFromSegment } from "webcad/models";
import { MountingsService } from "../../services/mountings.service";
import { PlateService } from "../../services/plate.service";
import { SetError } from "../actions/errorable.actions";
import {
  LOAD_MOUNTINGS,
  LoadMountings,
  LoadMountingsSuccess,
} from "../actions/mountings.actions";

@Injectable()
export class MountingsEffects {
  constructor(
    private actions: Actions,
    private mountingsService: MountingsService,
    private plateService: PlateService
  ) {}

  loadMountings: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(LOAD_MOUNTINGS),
      switchMap((action: LoadMountings) => {
        return this.mountingsService.getMountings().pipe(
          map((response) => {
            if (response.error) {
              return new SetError(response.error);
            } else {
              const mountings = response.data.mountings;
              const verticesMap = new Map<string, Vector2[]>();
              const shapeMap = new Map<string, Segment[]>();
              response.data.shapeMap
                .filter((x) => x.type.toLowerCase().includes("agraffe"))
                .map((x) => {
                  const name = x.type.toLowerCase();
                  this.plateService
                    .calculateShapeFromPolylines([x.shape])
                    .forEach((x) => {
                      const width = x.data[0].aabb.max.x - x.data[0].aabb.min.x;
                      const length =
                        x.data[0].aabb.max.y - x.data[0].aabb.min.y;
                      response.data.mountings
                        .filter((x) => x.form.toLowerCase().includes("agraffe"))
                        .filter((x) => x.form.toLowerCase() === name)
                        .map((x) => ({
                          ...x,
                          width: +width.toFixed(1),
                          length: +length.toFixed(1)
                        }));
                    });
                });
              for (const t of response.data.shapeMap) {
                const vertices: Vector2[] = [];
                for (const s of t.shape) {
                  vertices.push(...getPointsFromSegment(s));
                }
                verticesMap.set(t.type, vertices);
                shapeMap.set(t.type, t.shape);
              }
              mountings
                .filter((x) => x.length === 0)
                .map((x) => (x.length = x.width));
              return new LoadMountingsSuccess({
                mountings: mountings,
                verticesMap: verticesMap,
                shapeMap: shapeMap,
              });
            }
          }),
          catchError((error) => observableThrowError(() => error))
        );
      })
    )
  );

  // @Effect()
  // updateMountings: Observable<Action> = this.actions.pipe(
  //   ofType(DATASET_READY),
  //   switchMap((action) => {
  //     return of(new UpdateMountings());
  //   })
  // );
}
