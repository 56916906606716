export enum Units {
  Millimeters = 'Millimeters',
  Centimeters = 'Centimeters',
  Meters = 'Meters',
  Kilometers = 'Kilometers',
  Feet = 'Feet',
  Inches = 'Inches',
  Yards = 'Yards',
  Miles = 'Miles'
}
