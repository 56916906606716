// Column-Major

import {Vector4} from './vector4';
import {Vector3} from './vector3';

export interface Matrix4 {
    n11: number;
    n12: number;
    n13: number;
    n14: number;
    n21: number;
    n22: number;
    n23: number;
    n24: number;
    n31: number;
    n32: number;
    n33: number;
    n34: number;
    n41: number;
    n42: number;
    n43: number;
    n44: number;
}

export function matrix4FromBasis(xAxis: Vector3, yAxis: Vector3, zAxis: Vector3, origin: Vector3): Matrix4 {
    return {
        n11: xAxis.x, n12: yAxis.x, n13: zAxis.x, n14: origin.x,
        n21: xAxis.y, n22: yAxis.y, n23: zAxis.y, n24: origin.y,
        n31: xAxis.z, n32: yAxis.z, n33: zAxis.z, n34: origin.z,
        n41: 0, n42: 0, n43: 0, n44: 1,
    };
}

export function transformVec4(matrix: Matrix4, v: Vector4): Vector4 {
    return {
        x: matrix.n11 * v.x + matrix.n12 * v.y + matrix.n13 * v.z + matrix.n14 * v.w,
        y: matrix.n21 * v.x + matrix.n22 * v.y + matrix.n23 * v.z + matrix.n24 * v.w,
        z: matrix.n31 * v.x + matrix.n32 * v.y + matrix.n33 * v.z + matrix.n34 * v.w,
        w: matrix.n41 * v.x + matrix.n42 * v.y + matrix.n43 * v.z + matrix.n44 * v.w,
    };
}

export function transformPos(matrix: Matrix4, v: Vector3): Vector3 {
    return {
        x: matrix.n11 * v.x + matrix.n12 * v.y + matrix.n13 * v.z + matrix.n14,
        y: matrix.n21 * v.x + matrix.n22 * v.y + matrix.n23 * v.z + matrix.n24,
        z: matrix.n31 * v.x + matrix.n32 * v.y + matrix.n33 * v.z + matrix.n34,
    };
}

export function transformDirection(matrix: Matrix4, v: Vector3): Vector3 {
    return {
        x: matrix.n11 * v.x + matrix.n12 * v.y + matrix.n13 * v.z,
        y: matrix.n21 * v.x + matrix.n22 * v.y + matrix.n23 * v.z,
        z: matrix.n31 * v.x + matrix.n32 * v.y + matrix.n33 * v.z,
    };
}


export function projectPos(matrix: Matrix4, v: Vector3): Vector3 {
    const p: Vector4 = {
        x: matrix.n11 * v.x + matrix.n12 * v.y + matrix.n13 * v.z + matrix.n14,
        y: matrix.n21 * v.x + matrix.n22 * v.y + matrix.n23 * v.z + matrix.n24,
        z: matrix.n31 * v.x + matrix.n32 * v.y + matrix.n33 * v.z + matrix.n34,
        w: matrix.n41 * v.x + matrix.n42 * v.y + matrix.n43 * v.z + matrix.n44,
    };
    return {
        x: p.x / p.w,
        y: p.y / p.w,
        z: p.z / p.w,
    };
}

export function identityMatrix4() {
    return {
        n11: 1, n12: 0, n13: 0, n14: 0,
        n21: 0, n22: 1, n23: 0, n24: 0,
        n31: 0, n32: 0, n33: 1, n34: 0,
        n41: 0, n42: 0, n43: 0, n44: 1,
    };
}

export function matrix4GetOrigin(m: Matrix4): Vector3 {
    return {
        x: m.n14,
        y: m.n24,
        z: m.n34
    };
}

export function getZAxis(m: Matrix4): Vector3 {
    return {
        x: m.n13,
        y: m.n23,
        z: m.n33,
    };
}

export function getOrigin(m: Matrix4): Vector3 {
    return {
        x: m.n14,
        y: m.n24,
        z: m.n34,
    };
}

export function getInverseMatrix4(m: Matrix4): Matrix4 {

    // based on http://www.euclideanspace.com/maths/algebra/matrix/functions/inverse/fourD/index.htm
    let
        t11 = m.n23 * m.n34 * m.n42 - m.n24 * m.n33 * m.n42 + m.n24 * m.n32 * m.n43 - m.n22 * m.n34 * m.n43 - m.n23 * m.n32 * m.n44 + m.n22 * m.n33 * m.n44,
        t12 = m.n14 * m.n33 * m.n42 - m.n13 * m.n34 * m.n42 - m.n14 * m.n32 * m.n43 + m.n12 * m.n34 * m.n43 + m.n13 * m.n32 * m.n44 - m.n12 * m.n33 * m.n44,
        t13 = m.n13 * m.n24 * m.n42 - m.n14 * m.n23 * m.n42 + m.n14 * m.n22 * m.n43 - m.n12 * m.n24 * m.n43 - m.n13 * m.n22 * m.n44 + m.n12 * m.n23 * m.n44,
        t14 = m.n14 * m.n23 * m.n32 - m.n13 * m.n24 * m.n32 - m.n14 * m.n22 * m.n33 + m.n12 * m.n24 * m.n33 + m.n13 * m.n22 * m.n34 - m.n12 * m.n23 * m.n34;

    let det = m.n11 * t11 + m.n21 * t12 + m.n31 * t13 + m.n41 * t14;

    if (det === 0) {

        let msg = 'can\'t invert matrix, determinant is 0';
        throw new Error(msg);
    }

    let detInv = 1 / det;
    let result: Matrix4 = identityMatrix4();
    result.n11 = t11 * detInv;
    result.n12 = (m.n24 * m.n33 * m.n41 - m.n23 * m.n34 * m.n41 - m.n24 * m.n31 * m.n43 + m.n21 * m.n34 * m.n43 + m.n23 * m.n31 * m.n44 - m.n21 * m.n33 * m.n44) * detInv;
    result.n13 = (m.n22 * m.n34 * m.n41 - m.n24 * m.n32 * m.n41 + m.n24 * m.n31 * m.n42 - m.n21 * m.n34 * m.n42 - m.n22 * m.n31 * m.n44 + m.n21 * m.n32 * m.n44) * detInv;
    result.n14 = (m.n23 * m.n32 * m.n41 - m.n22 * m.n33 * m.n41 - m.n23 * m.n31 * m.n42 + m.n21 * m.n33 * m.n42 + m.n22 * m.n31 * m.n43 - m.n21 * m.n32 * m.n43) * detInv;

    result.n21 = t12 * detInv;
    result.n22 = (m.n13 * m.n34 * m.n41 - m.n14 * m.n33 * m.n41 + m.n14 * m.n31 * m.n43 - m.n11 * m.n34 * m.n43 - m.n13 * m.n31 * m.n44 + m.n11 * m.n33 * m.n44) * detInv;
    result.n23 = (m.n14 * m.n32 * m.n41 - m.n12 * m.n34 * m.n41 - m.n14 * m.n31 * m.n42 + m.n11 * m.n34 * m.n42 + m.n12 * m.n31 * m.n44 - m.n11 * m.n32 * m.n44) * detInv;
    result.n24 = (m.n12 * m.n33 * m.n41 - m.n13 * m.n32 * m.n41 + m.n13 * m.n31 * m.n42 - m.n11 * m.n33 * m.n42 - m.n12 * m.n31 * m.n43 + m.n11 * m.n32 * m.n43) * detInv;

    result.n31 = t13 * detInv;
    result.n32 = (m.n14 * m.n23 * m.n41 - m.n13 * m.n24 * m.n41 - m.n14 * m.n21 * m.n43 + m.n11 * m.n24 * m.n43 + m.n13 * m.n21 * m.n44 - m.n11 * m.n23 * m.n44) * detInv;
    result.n33 = (m.n12 * m.n24 * m.n41 - m.n14 * m.n22 * m.n41 + m.n14 * m.n21 * m.n42 - m.n11 * m.n24 * m.n42 - m.n12 * m.n21 * m.n44 + m.n11 * m.n22 * m.n44) * detInv;
    result.n34 = (m.n13 * m.n22 * m.n41 - m.n12 * m.n23 * m.n41 - m.n13 * m.n21 * m.n42 + m.n11 * m.n23 * m.n42 + m.n12 * m.n21 * m.n43 - m.n11 * m.n22 * m.n43) * detInv;

    result.n41 = t14 * detInv;
    result.n42 = (m.n13 * m.n24 * m.n31 - m.n14 * m.n23 * m.n31 + m.n14 * m.n21 * m.n33 - m.n11 * m.n24 * m.n33 - m.n13 * m.n21 * m.n34 + m.n11 * m.n23 * m.n34) * detInv;
    result.n43 = (m.n14 * m.n22 * m.n31 - m.n12 * m.n24 * m.n31 - m.n14 * m.n21 * m.n32 + m.n11 * m.n24 * m.n32 + m.n12 * m.n21 * m.n34 - m.n11 * m.n22 * m.n34) * detInv;
    result.n44 = (m.n12 * m.n23 * m.n31 - m.n13 * m.n22 * m.n31 + m.n13 * m.n21 * m.n32 - m.n11 * m.n23 * m.n32 - m.n12 * m.n21 * m.n33 + m.n11 * m.n22 * m.n33) * detInv;

    return result;
}

export function matrix4AreEqual(a: Matrix4, b: Matrix4): boolean {
    return    a.n11 === b.n11 && a.n12 === b.n12 && a.n13 === b.n13 && a.n14 === b.n14 &&
    a.n21 === b.n21 && a.n22 === b.n22 && a.n23 === b.n23 && a.n24 === b.n24 &&
    a.n31 === b.n31 && a.n32 === b.n32 && a.n33 === b.n33 && a.n34 === b.n34 &&
    a.n41 === b.n41 && a.n42 === b.n42 && a.n43 === b.n43 && a.n44 === b.n44;
}