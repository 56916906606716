import { Webcad } from "../core";
import { AngleMeasurementMaterial } from "../materials/angle-measurement.material";
import {
  Vector3,
  addVectors3,
  multiplyVector3byScalar,
  sqrDistanceVector2,
} from "../math";
import {
  Input3DModel,
  angleMeasurementToSegment,
  isPointInArcSegment,
  projectPointOnArcSegment,
} from "../models";
import { ObjectUnderPoint } from "../models/ObjectUnderPoint";
import { AngleMeasurementModel } from "../models/angle-measurement.model";
import { CameraModel, workingPlaneUnitSize } from "../models/camera.model";
import { vector3ToBabylon3 } from "../utils/cast";
import { Input3DVisualizer, ModelVisualizer } from "../visualizers";
import { createMeasurementMesh } from "./utils";
import {Mesh, Node} from "@babylonjs/core";

export class AngleMeasurementVisualizer
  implements ModelVisualizer<AngleMeasurementModel>
{
  private webcad: Webcad;
  private template: Mesh;
  private mesh: Mesh;
  private model: AngleMeasurementModel;
  private camera: CameraModel;
  private inputVisualizer: Input3DVisualizer<number>;

  private get material(): AngleMeasurementMaterial {
    if (this.mesh.material) {
      return this.mesh.material as AngleMeasurementMaterial;
    } else if (this.mesh) {
      this.mesh.material = new AngleMeasurementMaterial(this.webcad.scene);
      return this.mesh.material as AngleMeasurementMaterial;
    }
  }

  dispose(): void {
    if (this.mesh) {
      this.mesh.dispose();
      this.mesh = null;
    }
    if (this.inputVisualizer) {
      this.inputVisualizer.dispose();
    }
  }

  init(
    rootNode: Node,
    model: AngleMeasurementModel,
    webcad: Webcad
  ): Promise<void> {
    this.webcad = webcad;
    this.model = null;
    this.inputVisualizer = new Input3DVisualizer<number>();
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        webcad.assets.getMesh("arc-mesh"),
        this.inputVisualizer.init(
          rootNode,
          this.getInputModel(model, this.webcad.viewState.camera),
          webcad
        ),
      ]).then(([mesh]) => {
        this.template = mesh;
        this.mesh = createMeasurementMesh(mesh, webcad.scene);

        this.mesh.material = new AngleMeasurementMaterial(webcad.scene);
        this.onModelChange(model);
        this.onModelOrViewChange(model, this.webcad.viewState.camera);
        this.updateVisualization(model);
        resolve();
      }, reject);
    });
  }

  getInputModel(
    viewModel: AngleMeasurementModel,
    camera: CameraModel
  ): Input3DModel<any> {
    if (!!viewModel && viewModel.visible) {
      const unitSize = workingPlaneUnitSize(camera);
      let radius = 0.05 * unitSize.x;
      if (viewModel.radius) {
        //if undefined or 0
        radius = Math.abs(viewModel.radius);
      }

      const middleAngle =
        Math.atan2(viewModel.startDir.y, viewModel.startDir.x) +
        viewModel.exchange.value * 0.5;
      var dir: Vector3 = {
        x: Math.cos(middleAngle),
        y: Math.sin(middleAngle),
        z: 0,
      };
      var dir2: Vector3 = { x: dir.y, y: -dir.x, z: 0 };
      let pos: Vector3 = addVectors3(
        viewModel.origin,
        multiplyVector3byScalar(dir, radius + unitSize.x * 0.01)
      );
      return {
        editable: viewModel.editable,
        position: pos,
        dir: dir2,
        exchange: viewModel.exchange,
      };
    } else {
      return null;
    }
  }

  private onModelChange(newModel: AngleMeasurementModel): void {
    if (!!this.mesh) {
      if (!newModel || !newModel.visible) {
        this.mesh.isVisible = false;
      } else {
        this.mesh.isVisible = true;
        this.mesh.position = vector3ToBabylon3(newModel.origin);
        if (newModel.color) {
          this.material.color = {
            x: newModel.color.x,
            y: newModel.color.y,
            z: newModel.color.z,
            w: 1,
          };
        }
        const alpha = Math.atan2(newModel.startDir.y, newModel.startDir.x);
        //this.mesh.rotation.z = alpha;
        const angle = newModel.exchange.value;
        if (angle >= 0) {
          this.mesh.rotation.set(0, 0, alpha);
          this.material.angle = angle;
        } else {
          this.mesh.rotation.set(0, 0, alpha + angle);
          this.material.angle = -angle;
        }
      }
    }
  }

  private onModelOrViewChange(
    newModel: AngleMeasurementModel,
    newCamera: CameraModel
  ): void {
    if (!!newModel && newModel.visible) {
      //const dist = distanceVector3(multiplyVector3byScalar(matrix4GetOrigin(newView), -1), newModel.origin);
      const unitSize = workingPlaneUnitSize(newCamera);
      this.material.scale = unitSize.x / 30;
      let radius = 0.05 * unitSize.x;
      if (newModel.radius) {
        //if undefined or 0
        radius = Math.abs(newModel.radius);
      }
      this.material.radius = radius;
    }
    this.inputVisualizer.updateVisualization(
      this.getInputModel(newModel, newCamera)
    );
  }

  updateVisualization(newModel: AngleMeasurementModel): void {
    const newCamera = this.webcad.viewState.camera;
    if (!!this.mesh) {
      if (this.model !== newModel) {
        this.onModelChange(newModel);
      }
      if (this.camera !== newCamera || this.model !== newModel) {
        this.onModelOrViewChange(newModel, newCamera);
      }
    }
    this.model = newModel;
    this.camera = newCamera;
  }

  getObjectUnderPoint(point: Vector3, maxDist: number): ObjectUnderPoint {
    const measurementAsSegment = angleMeasurementToSegment(this.model);
    measurementAsSegment.radius = this.material.radius;
    const pp = projectPointOnArcSegment(point, measurementAsSegment);
    if (pp) {
      const dist = sqrDistanceVector2(pp, point);
      if (dist < maxDist * maxDist) {
        if (isPointInArcSegment(pp, measurementAsSegment)) {
          return {
            type: "AngleMeasurement",
            object: this.model,
            point: { x: pp.x, y: pp.y, z: point.z },
          };
        }
      }
    }
    return null;
  }
}
