import {Injectable} from '@angular/core';
import {Webcad} from 'webcad';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class WebcadProvider {
  private webcad: BehaviorSubject<Webcad>;

  constructor() {
    this.webcad = new BehaviorSubject(null);
  }

  setWebCad(value: Webcad) {
    this.webcad.next(value);
  }

  getWebcad() {
    return this.webcad.getValue();
  }

  getObservable():Observable<Webcad> {
    return this.webcad;
  }


}
