import {UnderPointerType} from '../../model/pointer-state.model';
import {PointerCollider, PointPointerCollider} from 'webcad/collision';
import {MevacoCollider} from './mevaco.collider';
import {CursorType} from './cursor.type';
import {NodeCollider} from './node.collider';
import {Vector3} from 'webcad/math';
import {ColliderPriority} from "./collider-priorities";

export class VirtualNodeCollider extends PointPointerCollider implements MevacoCollider {
  priority = ColliderPriority.VIRTUAL_NODE;
  objectType: UnderPointerType;
  public cursorType: CursorType;

  public get object(): any {
    return this.position;
  }

  constructor(public position: Vector3) {
    super(position, "VirtualNode");
    this.objectType = UnderPointerType.VIRTUAL_NODE;
    this.cursorType = CursorType.point;
  }

  compare(other: PointerCollider): boolean {
    if (!super.compare(other)) {
      return false;
    }
    if (other instanceof NodeCollider) {
      const nc: NodeCollider = other as NodeCollider;
      return this.position === nc.position;
    }
    return false;
  }
}
