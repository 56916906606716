import {Drawing} from "../../model/drawing.model";
import {BendingTreeProvider} from "./bending-tree.provider";
import {PerforationVisualizerViewModel} from "../../model/view-model/perforation-visualizer.viewModel";
import {MountingViewModel} from "../../model/view-model/mounting.viewModel";

export class MountingsViewModelProvider {

  constructor(private bendingTreeViewModelProvider: BendingTreeProvider){

  }
  private viewModel:MountingViewModel;

  getViewModel(newModel: Drawing): MountingViewModel {

    const bendingTree = this.bendingTreeViewModelProvider.getViewModel(newModel);
    if(!newModel || !newModel.plate){
      this.viewModel = null;
    }else if(
      !this.viewModel ||
      this.viewModel.mountings !== newModel.plate.mountingHoles ||
      this.viewModel.depth !== newModel.plate.depth ||
      this.viewModel.state !== newModel.snapOptions.mountingHoles ||
      this.viewModel.shapes !== newModel.mountingsModel.shapeMap ||
      this.viewModel.outlineState !== newModel.snapOptions.mountingHolesOutline ||
      this.viewModel.bendingTree !== bendingTree
    ){
      this.viewModel = {
        mountings: newModel.plate.mountingHoles,
        depth: newModel.plate.depth,
        state: newModel.snapOptions.mountingHoles,
        shapes: newModel.mountingsModel.shapeMap,
        outlineState: newModel.snapOptions.mountingHolesOutline,
        bendingTree: bendingTree,
      };
    }
    return this.viewModel;
  };
}
