import { Component, OnInit } from '@angular/core';
import {Vector2} from 'webcad/math';
import {MevacoPage} from '../../model/mevaco-page.model';
import {select, Store} from '@ngrx/store';
import {SetPerforationOffset} from '../../store/actions/mountings.actions';
import {multiplyVector2byScalar} from 'webcad/math';

@Component({
  selector: 'app-perforation-offset',
  templateUrl: './perforation-offset.component.html',
  styleUrls: ['./perforation-offset.component.css']
})
export class PerforationOffsetComponent implements OnInit {

  offset = 5;
  // private rotSub;
  // private rotation: number;
  constructor(private store: Store<MevacoPage>) { }

  ngOnInit() {
    // this.rotSub = this.store.pipe(
    //   select( getPerforationRotation )
    // ).subscribe(
    //   rotation => this.rotation = (+rotation)*Math.PI / 180
    // )
  }

  ngOnDestroy() {
//    this.rotSub.unsubscribe();
  }

  movePerforation(dir: Vector2) {
    // this.store.dispatch( new SetPerforationOffset( multiplyVector2byScalar( rotateVector2(dir, -this.rotation) , +this.offset) ))
    this.store.dispatch( new SetPerforationOffset( multiplyVector2byScalar( dir , +this.offset) ));
  }

}
