<div class="multiselect"
[class.multiselect--checkbox-hidden]="!checkboxVisible"
[class.multiselect--selected]="selectedOptions && selectedOptions?.length > 0">
  <p-multiSelect
    [options]="options"
    [disabled]="disabled"
    [filter]="isFilter"
    [showHeader]="showHeader"
    [(ngModel)]="selectedOptions"
    [defaultLabel]="placeholder"
    [style]="{ width: '100%' }"
    [panelStyle]="{ width: '100%' }"
    [maxSelectedLabels]="maxSelectedLabels"
    [selectionLimit]="selectionLimit"
    (onChange)="onChange($event)"
    (onPanelHide)="onHide($event)"
    appendTo="body"
  >
      <ng-template let-value pTemplate="selectedItems">
        <div class="country-item country-item-value" *ngIf="selectedOptions">
            <ng-content></ng-content>
        </div>
        <div *ngIf="!selectedOptions || selectedOptions.length === 0">
            {{translate('Select items', 'designer')}}
        </div>
    </ng-template>
    <ng-template let-option pTemplate="item">
      <div class="multiselect-option" *ngIf="option">
        <ng-container *ngIf="option.image">
            <img
              [src]="option.image"
              class="multiselect-item-img"
            />
        </ng-container>
        <ng-container *ngIf="option.label">
          <span class="multiselect-item-label">
            {{option.label}}
          </span>
        </ng-container>
        <ng-container *ngIf="option.value">
          <span class="multiselect-item-value">
            {{option.value}}
          </span>
        </ng-container>
      </div>
    </ng-template>

  </p-multiSelect>

</div>
