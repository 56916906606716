import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { initialPlate, Plate } from "../model/plate.model";
import {CSG, Mesh, Scene} from "webcad/babylonjs/core";

@Injectable()
export class PlateMeshProvider {
  private plateMesh: Mesh;
  private plateCSG: CSG;
  private shapesCSG: BehaviorSubject<CSG>;
  private perforationCSG: BehaviorSubject<CSG>;
  private plate: Plate;
  constructor() {
    this.plateCSG = new CSG();
    this.shapesCSG = new BehaviorSubject(new CSG());
    this.perforationCSG = new BehaviorSubject(new CSG());
    this.plate = initialPlate;
  }

  public setShapesCSG(value: CSG) {
    this.shapesCSG.next(value);
  }

  public getShapesCSG(): BehaviorSubject<CSG> {
    return this.shapesCSG;
  }

  public setPerforationCSG(value: CSG) {
    this.perforationCSG.next(value);
  }

  public getPerforationCSG(): BehaviorSubject<CSG> {
    return this.perforationCSG;
  }

  public setPlate(plate: Plate): void {
    this.plate = plate;
  }
  public getPlate(): Plate {
    return this.plate;
  }
  public getPlateDepth(): number {
    return this.plate.depth;
  }

  public setplateCSG(value: CSG) {
    this.plateCSG = value;
  }

  public setMesh(mesh: Mesh, scene: Scene): void {
    this.plateMesh = mesh;
  }

  public getMesh(): Mesh {
    return this.plateMesh;
  }
}
