import {Action} from '@ngrx/store';
import {ZoomOption} from "../../model/display-grid.model";
import {Vector2} from "webcad/math";

export const SET_GRID_ZOOM_OPTION = 'SET_GRID_ZOOM_OPTION';
export const SET_GRID_OFFSET = 'SET_GRID_OFFSET';
export const SET_GRID_SIZE = 'SET_GRID_SIZE';

export class SetGridZoomOption implements Action {
  readonly type = SET_GRID_ZOOM_OPTION;
  constructor (public payload: ZoomOption) {}
}

export class SetGridOffset implements Action {
  readonly type = SET_GRID_OFFSET;
  constructor (public payload: Vector2) {}
}

export class SetGridSize implements Action {
  readonly type = SET_GRID_SIZE;
  constructor (public payload: Vector2) {}
}

export type GridActions = SetGridZoomOption | SetGridOffset | SetGridSize ;
