import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Action, Store, select } from "@ngrx/store";
import { Observable, Subject, combineLatest, of } from "rxjs";
import {debounceTime, map, tap, withLatestFrom} from "rxjs/operators";
import {
  Dataset,
  FoilOption,
  getMaterialQualities,
  getPerforationSortedMaterialTypes,
} from "../../../model/dataset/dataset.model";
import { TranslationProvider } from "../../../providers/translation.provider";
import { PossibleOptionsService } from "../../../services/possible-options.service";
import {
  ProductConfigurationSetFoil,
  ProductConfigurationSetMaterial,
  ProductConfigurationSetMaterialThickness,
  ProductConfigurationSetQuality,
} from "../../../store/actions";
import {
  MevacoState,
  getConfigurationElements,
  getDataSetState,
  getSelectedMaterialCode,
  getSelectedMaterialThickness,
  getSurfaceType,
} from "../../../store/reducers";
import { Dropdown, DropdownOption } from "../../dropdown/dropdown.component";

@Component({
  selector: "material-configurator",
  templateUrl: "./material-configurator.component.html",
  styleUrls: ["./material-configurator.component.css"],
})
export class MaterialConfiguratorComponent implements OnInit {
  public foilOptions: Observable<FoilOption[]>;
  public material: Dropdown;
  public thickness: Dropdown;
  public foil: Dropdown;
  public quality: Dropdown;
  @Input() titleColor = "default";
  @ViewChild("confirmModal", { static: true })
  private confirmModal: any;

  constructor(
    private translationProvider: TranslationProvider,
    private store: Store<MevacoState>,
    private possibleOptionsService: PossibleOptionsService,
    private modalService: NgbModal
  ) {}

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }

  createDropDown(
    name: string,
    value: Observable<string>,
    options: Observable<DropdownOption[]>,
    actionFactory: (string) => Action,
    possibleOptions: Observable<string[]> = of(null)
  ) {
    const onChangeObservable: Subject<string> = new Subject<string>();

    onChangeObservable
      .pipe(withLatestFrom(this.store.pipe(select(getConfigurationElements))))
      .subscribe(([value, elements]) => {
        // if there are some bended shapes then any of material change actions will remove them from list
        // since this will change bends parameters an shape (size)
        if (
          elements.find(
            (element) => element.breakLines && element.breakLines.length > 0
          ) !== undefined
        ) {
          // one or more bend plates we need to ask user
          this.modalService
            .open(this.confirmModal, {
              centered: true,
              backdrop: "static",
              windowClass: "custom-modal-window",
              backdropClass: "custom-modal-backdrop",
            })
            .result.then((result: boolean) => {
              if (result) {
                this.store.dispatch(actionFactory(value));
              }
            });
        } else {
          // only flat shapes no confirmation required
          this.store.dispatch(actionFactory(value));
        }
      });

    return new Dropdown(
      name,
      options,
      value,
      onChangeObservable.next.bind(onChangeObservable),
      null,
      possibleOptions
    );
  }

  ngOnInit() {
    this.material = this.createDropDown(
      this.translate("MaterialType"),
      this.store.pipe(
        select(
          (store) => store.model.productConfiguration.material.materialType
        )
      ) as Observable<string>,
      this.getMaterialTypeOptions(),
      (value) => new ProductConfigurationSetMaterial(value),
      this.possibleOptionsService.possibleMaterialTypes
    );

    this.thickness = this.createDropDown(
      this.translate("Thickness"),
      this.store.pipe(
        select((store) => store.model.productConfiguration.material.thickness)
      ) as Observable<string>,
      this.getThicknessOptions(),
      (value) => new ProductConfigurationSetMaterialThickness(value),
      this.possibleOptionsService.possibleMaterialThicknesses
    );

    this.foilOptions = combineLatest([
      this.store.pipe(select(getDataSetState)),
      this.store.pipe(select(getSelectedMaterialCode)),
      this.store.pipe(select(getSelectedMaterialThickness)),
      this.store.pipe(select(getSurfaceType)),
    ]).pipe(
      map(
        ([dataset, materialCode, thickness, surfaceType]: [
          Dataset,
          string,
          string,
          string
        ]) => {
          if (dataset) {
            if (surfaceType !== "Polished") {
              // if is polished then its also with foil so there is no reason to select it in two places
              if (dataset.foiles[materialCode]) {
                if (thickness != "") {
                  const t = (+thickness).toFixed(2);
                  if (dataset.foiles[materialCode][t]) {
                    return [
                      FoilOption.None,
                      ...dataset.foiles[materialCode][t],
                    ];
                  }
                }
              }
            }
          }
          return [];
        }
      )
    );

    this.foil = this.createDropDown(
      this.translate("Foil"),
      this.store.pipe(
        select((store) => store.model.productConfiguration.material.foil)
      ) as Observable<string>,
      this.getFoilOptions(),
      (value) => new ProductConfigurationSetFoil(value)
    );

    this.quality = this.createDropDown(
      this.translate("Quality"),
      this.store.pipe(
        select((store) => store.model.productConfiguration.material.quality)
      ) as Observable<string>,
      this.getQualityOptions(),
      (value) => new ProductConfigurationSetQuality(value),
      this.possibleOptionsService.possibleMaterialCodes
    );
  }

  private getMaterialTypeOptions(): Observable<DropdownOption[]> {
    return this.store.pipe(select((store) => store.model.dataset)).pipe(
      map((dataset) => {
        if (!dataset) {
          return [];
        }
        const materialTypes = getPerforationSortedMaterialTypes(dataset);
        return materialTypes.map(
          (x) =>
            new DropdownOption(
              "text",
              this.translate("Material_" + x),
              true,
              x,
              this.translate("MaterialType")
            )
        );
      })
    );
  }

  private getThicknessOptions(): Observable<DropdownOption[]> {
    return this.possibleOptionsService.thicknessOptions.pipe(
      map((list) =>
        list.map(
          (x) =>
            new DropdownOption("text", x, true, x, this.translate("Thickness"))
        )
      )
    );
  }

  private getFoilOptions(): Observable<DropdownOption[]> {
    return this.foilOptions.pipe(
      map((options) => {
        return options.map(
          (option) =>
            new DropdownOption(
              "text",
              this.translate(option),
              true,
              option,
              this.translate("Foil")
            )
        );
      })
    );
  }

  private getQualityOptions(): Observable<DropdownOption[]> {
    return combineLatest([
      this.store.pipe(select((store) => store.model.dataset)),
      this.store.pipe(
        select(
          (store) => store.model.productConfiguration.material.materialType
        )
      ),
      this.store.pipe(
        select((store) => store.model.productConfiguration.material.thickness)
      ),
    ]).pipe(
      debounceTime(1),
      map(([dataset, materialType, thickness]) => {
        if (!dataset) {
          return [];
        }
        return getMaterialQualities(dataset, materialType, thickness).map(
          (x) =>
            new DropdownOption(
              "text",
              this.translate("Material_" + x),
              true,
              x,
              this.translate("Quality")
            )
        );
      })
    );
  }
}
