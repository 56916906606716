<div class="import-modal__container">

  <div class="import-modal__tools-container" [hidden]="stepOf === '1'">
    <tool-panel></tool-panel>
  </div>

  <div class="import-modal__main" [ngStyle]="{'left': stepOf === '1' ? '20px' : '360px'}">

    <button type="button" class="import-modal__close-btn" aria-label="Close" (click)="setStep(Step.template)">
      <span aria-hidden="true">&times;</span>
    </button>

    <div class="import-modal__header" >
      <div class="import-modal__header-left">
        <h1 class="import-modal__title">{{stepOf}}. {{title}}</h1>
        <span class="import-modal__steps">
          {{translate("step {st} out of").replace('{st}', stepOf)}}
        </span>
        <p class="import-modal__description">{{description}}</p>
      </div>
      <div class="import-modal__header-right">
        <p class="import-modal__note">
            {{note}}
        </p>
      </div>
    </div>
    
    <div class="import-modal__viewer-container" >
      <webcad-host [material]="PlateMaterialType.shape"></webcad-host>
    </div>
    
    <div class="import-modal__footer">
      <div class="footer__section">
        <ng-container *ngIf="stepOf === '1'">
          <input class="import-modal__rotation-input" type="number" [(ngModel)]="rotationAngle">
          <button class="btn btn-primary" (click)="rotate()">{{translate("rotate")}}</button>
        </ng-container>
      </div>
      <div class="footer__section">
        <button class="btn btn-primary" *ngIf="addMountings" (click)="addMountings.action()" [disabled]="!(addMountings.enable | async)">{{translate(addMountings.name, "designer")}}</button>
        <button class="btn btn-primary" *ngIf="submitImport" (click)="submitImport.action()" [disabled]="!(submitImport.enable | async)">{{translate(submitImport.name, "designer")}}</button>
        <button class="btn btn-primary" *ngIf="nextStep" (click)="nextStep.action()" [disabled]="!(nextStep.enable | async)">{{translate(nextStep.name, "designer")}}</button>
      </div>
    </div>
  </div>
</div>

<ng-template #confirmAttachments let-modal>
  <div class="dialog-modal-header dialog-modal-header--small">
    <div class="dialog-modal-title-container">
      <div class="dialog-modal-title-content">
        <h4 class="dialog-modal-title dialog-modal-title--small" id="modal-basic-title">
          <img src="assets/tooltip-warning-icon.svg" alt="warning-icon" class="dialog-modal-title-img">
          <span>{{translate("unfinished attachments header", 'designer')}}</span>
        </h4>
      </div>
    </div>

  <button type="button" class="close btn-close" aria-label="Close"  (click)="modal.close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

  <div class="dialog-modal-body dialog-modal-body--small" >
    <p class="dialog-modal-description--small">{{translate("unfinished attachments body", 'designer')}}</p>
  </div>

  <div class="dialog-modal-footer dialog-modal-footer--small dialog-modal-footer--end" >
    <button  type="button" class="btn btn-primary dialog-modal-btn--small" (click)="modal.close(true)"> {{translate('Yes')}}
    </button>
    <button  type="button" class="btn btn-outline-primary dialog-modal-btn--small" (click)="modal.close(false)"> {{translate('No')}}
    </button>
  </div>

</ng-template>
