import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Options } from "ngx-slider-v2";
import { Subscription } from "rxjs";

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.css"],
})
export class SliderComponent implements OnInit {
  @ViewChild("labelInput", { static: false }) labelInput: ElementRef;
  @Input() options: Options;
  @Input() sliderControl = new FormControl();
  @Input() isLabel = true;
  @Input() isLabelInput = true;
  @Input() labelText = "";
  @Input() customLabelText: TemplateRef<any>;
  @Output() userChangeStart = new EventEmitter();
  @Output() userChange = new EventEmitter();
  @Output() userChangeEnd = new EventEmitter();
  @Output() valueChange = new EventEmitter();
  @Output() highValueChange = new EventEmitter();
  private subscriptions = new Subscription();
  public inputValue;

  constructor() {}

  ngOnInit() {
    this.inputValue = this.sliderControl.value;
    this.subscriptions.add(
      this.sliderControl.valueChanges.pipe().subscribe((v) => {
        if (v !== null && +this.labelInput.nativeElement.value !== +v) {
          this.labelInput.nativeElement.value = v;
        }
      })
    );
  }

  inputValueChanged(event) {
    const value = +event.target.value;
    if ( !isNaN(value) && value >= this.options.floor && value <= this.options.ceil) {
      this.sliderControl.setValue(value);
    }
  }

  onUserChangeStart(event){
    this.userChangeStart.emit(event);
  }

  onUserChange(event) {
    this.userChange.emit(event);
  }

  onUserChangeEnd(event) {
    this.userChangeEnd.emit(event);
  }

  onValueChange(event) {
    this.valueChange.emit(event);
  }

  ngOnChange() {
    this.subscriptions.unsubscribe();
  }
}
