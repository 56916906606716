import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store, select } from "@ngrx/store";
import { BehaviorSubject, Observable, Subscription, combineLatest } from "rxjs";
import { delay, startWith } from "rxjs/operators";
import { DownloadDxfParams } from "../../model/client-server.Params/download-dxf-params";
import { Step } from "../../model/product-configuration/product-configuration.model";
import { ActionType } from "../../model/shape-action.model";
import { SceneProvider } from "../../providers/scene.provider";
import { NamedTool, ToolProvider } from "../../providers/tool.provider";
import { TranslationProvider } from "../../providers/translation.provider";
import { PlateService } from "../../services/plate.service";
import { WorkLoadService } from "../../services/work-load.service";
import {
  CreatePerforationAreasFomShape,
  RoundUpSelectedNodes,
  RoundUpSelectedNodesParams,
  SetCurrentPerforationAreaRotation,
} from "../../store/actions/drawing.actions";
import { ChangeActionType } from "../../store/actions/plate.actions";
import { SetSnapOptionHelpLine } from "../../store/actions/snap-options.actions";
import {
  MevacoState,
  getCurrentActionType,
  getStep,
} from "../../store/reducers";
import { HelpLineTool } from "../../tools/editTools/help-line.tool";
import { SelectTool } from "../../tools/editTools/select.tool";
import { TreatCornerTool } from "../../tools/editTools/treat-corner.tool";
import { Tool } from "../../tools/tool.interface";
import { roundDecimal } from "../../utils/utils";

@Component({
  selector: "tool-panel",
  templateUrl: "./tool-panel.component.html",
  styleUrls: ["./tool-panel.component.css"],
})
export class ToolPanelComponent implements AfterViewInit, OnDestroy, OnInit {
  public actionType: ActionType;
  public activeToolName: BehaviorSubject<string>;
  public ActionType = ActionType;
  public drawingTools: Observable<NamedTool[]>;
  public utilTools: Observable<NamedTool[]>;
  public measureTools: Observable<NamedTool[]>;
  public contureTool: Observable<NamedTool[]>;
  public eckpunkteTool: Observable<NamedTool[]>;
  public stepSub: Observable<Step>;
  public step = Step;
  public activeTool: Observable<Tool>;
  public currentActionType: Observable<ActionType>;
  public downloadDxfParams: DownloadDxfParams;
  public dependent: Observable<boolean>;
  public autoCenter: Observable<boolean>;
  private downloadDxfQueued: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private canDownload: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private downloadSub: Subscription;
  public margin: any = 10;
  public currentAreaRotation: Observable<string | number>;
  public activeToolObject: Tool;
  public selectTool: SelectTool;
  public treatCornerTool: TreatCornerTool;
  public helpLineTool: HelpLineTool;
  public numberOfPerforationAreas: number;

  constructor(
    private modalService: NgbModal,
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private toolProvider: ToolProvider,
    private translationProvider: TranslationProvider,
    public plateService: PlateService,
    private workLoadService: WorkLoadService
  ) {
    this.activeToolName = new BehaviorSubject<string>(null);
    this.store
      .pipe(select(getCurrentActionType))
      .subscribe((type) => (this.actionType = type));
    window.addEventListener("keyup", (ev: KeyboardEvent) => {
      if (ev.key === "Delete") {
        this.toolProvider.tryDelete();
      }
    });

    this.selectTool = this.toolProvider.selectTool;
    this.treatCornerTool = this.toolProvider.treatCornerTool;
    this.helpLineTool = this.toolProvider.helpLineTool;
    this.utilTools = this.toolProvider.getUtilTools();
    this.contureTool = this.toolProvider.getContureTools();
    this.eckpunkteTool = this.toolProvider.getEckpunkteTools();
    this.stepSub = this.store.pipe(select(getStep));
    this.activeTool = this.toolProvider
      .getToolObservable()
      .pipe(startWith(this.selectTool), delay(0));
    this.activeTool.subscribe((v) => (this.activeToolObject = v));
    this.currentActionType = this.store.pipe(select(getCurrentActionType));
  }

  ngAfterViewInit() {
    this.toolProvider.refreshTool();
  }

  ngOnInit() {
    this.downloadSub = combineLatest([
      this.canDownload,
      this.downloadDxfQueued,
    ]).subscribe(([canDownload, queued]) => {
      if (this.downloadDxfParams && canDownload && queued) {
        this.plateService.downloadDxf(this.downloadDxfParams);
        this.downloadDxfQueued.next(false);
      }
    });
  }

  ngOnDestroy() {
    if (this.downloadSub) {
      this.downloadSub.unsubscribe();
      this.downloadSub = null;
    }
  }

  setAddTool(tool: NamedTool) {
    this.store.dispatch(new ChangeActionType(ActionType.ADD));
    this.toolProvider.setTool(tool.tool);
    this.activeToolName.next(tool.name + "Add");
  }

  setRemoveTool(tool: NamedTool) {
    this.store.dispatch(new ChangeActionType(ActionType.REMOVE));
    this.toolProvider.setTool(tool.tool);
    this.activeToolName.next(tool.name + "Remove");
  }

  setTool(tool: NamedTool) {
    this.toolProvider.setTool(tool.tool);
    this.activeToolName.next(tool.name);
  }

  setHelpLineTool() {
    this.store.dispatch(new SetSnapOptionHelpLine(true));
    this.activeToolName.next("HelpLine");
  }

  setTreatCornerTool() {
    this.activeToolName.next("TreatCorner");
  }

  changeMode(event: any) {
    this.store.dispatch(new ChangeActionType(this.actionType));
  }

  roundUpCorners(content: any) {
    this.modalService.open(content, { centered: true }).result.then(
      (result: RoundUpSelectedNodesParams) => {
        this.store.dispatch(
          new RoundUpSelectedNodes({
            type: result.type,
            length: Number.isNaN(result.length) ? null : result.length * 0.001,
            width: Number.isNaN(result.width) ? null : result.width * 0.001,
            height: Number.isNaN(result.height) ? null : result.height * 0.001,
            radius: Number.isNaN(result.radius) ? null : result.radius * 0.001,
            rant: Number.isNaN(result.rant) ? null : result.rant * 0.001,
            notch: Number.isNaN(result.notch) ? null : result.notch * 0.001,
          })
        );
        this.toolProvider.setTool(null);
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  downloadDxf(): any {
    if (this.downloadDxfParams) {
      this.downloadDxfQueued.next(true);
    }
  }

  createPerforationAreas() {
    this.store.dispatch(
      new CreatePerforationAreasFomShape(this.margin * 0.001)
    );
  }

  setPerforationAreaRotation(r: string) {
    const v = +r;
    if (!isNaN(v)) {
      this.store.dispatch(new SetCurrentPerforationAreaRotation(v));
    }
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }

  roundInput(event, minValue?: number, maxValue?: number, decimals = 1) {
    if (!!minValue && Number(event.target.value) < minValue) {
      event.target.value = minValue;
    }
    if (!!maxValue && maxValue !== 0) {
      if (Number(event.target.value) > maxValue) {
        event.target.value = maxValue;
      }
    }
    event.target.value = roundDecimal(event.target.value, decimals);
  }
}
