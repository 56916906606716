import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSliderModule } from "@angular/material/slider";

import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { AppComponent } from "./app.component";
import { ToolPanelComponent } from "./components/tool-panel/tool-panel.component";
import { WebcadHostComponent } from "./components/webcad-host/webcad-host.component";
import { FullComponent } from "./layout/full/full.component";
import { PlateMeshProvider } from "./providers/plate-mesh.provider";
import { SceneProvider } from "./providers/scene.provider";
import { ToolProvider } from "./providers/tool.provider";
import { WebcadProvider } from "./providers/webcad.provider";
import { mevacoInitialstate, mevacoReducers } from "./store";

import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatRadioModule } from "@angular/material/radio";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { ContextMenuModule } from "@perfectmemory/ngx-contextmenu";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { NgxSliderModule } from "ngx-slider-v2";
import { ToastrModule } from "ngx-toastr";
import { AccordionModule } from "primeng/accordion";
import { CarouselModule } from "primeng/carousel";
import { MultiSelectModule } from "primeng/multiselect";
import { environment } from "../environments/environment";
import { AttachmentConfigComponent } from "./components/attachment-config/attachment-config.component";
import { BottomPanelComponent } from "./components/bottom-panel/bottom-panel.component";
import { CircularSliderComponent } from "./components/circular-slider/circular-slider.component";
import { ColorPickerComponent } from "./components/color-picker/color-picker.component";
import { MaterialConfiguratorComponent } from "./components/configurator-parts/material-configurator/material-configurator.component";
import { PerforationConfiguratorComponent } from "./components/configurator-parts/perforation-configurator/perforation-configurator.component";
import { SurfaceConfiguratorComponent } from "./components/configurator-parts/surface-configurator/surface-configurator.component";
import { ConfiguratorComponent } from "./components/configurator/configurator.component";
import { CustomPatternEditorComponent } from "./components/custom-pattern-editor/custom-pattern-editor.component";
import { CustomPatternLoadItemComponent } from "./components/custom-pattern-editor/custom-pattern-load.component/custom-pattern-load-item/custom-pattern-load-item.component";
import { CustomPatternLoadComponent } from "./components/custom-pattern-editor/custom-pattern-load.component/custom-pattern-load.component";
import { CustomPatternSaveComponent } from "./components/custom-pattern-editor/custom-pattern-save/custom-pattern-save.component";
import { DebounceInputComponent } from "./components/debounce-input/debounce-input.component";
import { DebounceRangeSliderComponent } from "./components/debounce-range-slider/debounce-range-slider.component";
import { DebounceSliderComponent } from "./components/debounce-slider/debounce-slider.component";
import { DesignModalComponent } from "./components/design-modal/design-modal.component";
import { DropdownComponent } from "./components/dropdown/dropdown.component";
import { StringOptionComponent } from "./components/dropdown/string-option/string-option.component";
import { EditableLabelComponent } from "./components/editable-label/editable-label.component";
import { EffectsEditorComponent } from "./components/effects-editor/effects-editor.component";
import { ElementCreatorComponent } from "./components/element-creator/element-creator.component";
import { ErrorsVisualizerComponent } from "./components/errors-visualizer/errors-visualizer.component";
import { ExpandableLabelComponent } from "./components/expandable-label/expandable-label.component";
import { FadingEditorComponent } from "./components/fading-editor/fading-editor.component";
import { HelpLineComponent } from "./components/help-line/help-line.component";
import { HintsComponent } from "./components/hints/hints.component";
import { ImportModalComponent } from "./components/import-modal/import-modal.component";
import { MessageVisualizerComponent } from "./components/message-visualizer/message-visualizer.component";
import { MultiselectComponent } from "./components/multiselect/multiselect.component";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { PerfactionConfigComponent } from "./components/perfaction-editor/perfaction-config/perfaction-config.component";
import { PerfactionEditorComponent } from "./components/perfaction-editor/perfaction-editor.component";
import { PerfactionImagePanelComponent } from "./components/perfaction-editor/perfaction-image-panel/perfaction-image-panel.component";
import { PerfactionImageToolsComponent } from "./components/perfaction-editor/perfaction-image-tools/perfaction-image-tools.component";
import { PerfactionPlatesComponent } from "./components/perfaction-editor/perfaction-plates/perfaction-plates.component";
import { PerforationAdjustmentComponent } from "./components/perforation-adjustment/perforation-adjustment.component";
import { PerforationOffsetComponent } from "./components/perforation-offset/perforation-offset.component";
import { PositionsItemListComponent } from "./components/positions-item-list/positions-item-list.component";
import { PositionsItemComponent } from "./components/positions-item/positions-item.component";
import { PositionsListComponent } from "./components/positions-list/positions-list.component";
import { PrintLayoutComponent } from "./components/print-layout/print-layout.component";
import { RightPanelComponent } from "./components/right-panel/right-panel.component";
import { RuleActionComponent } from "./components/rule-action/rule-action.component";
import { RuleErrorComponent } from "./components/rule-error/rule-error.component";
import { SegmentPropertiesComponent } from "./components/segment-properties/segment-properties.component";
import { SelectMoveComponent } from "./components/select-move/select-move.component";
import { SelectedShapeContextMenuComponent } from "./components/selected-shape-context-menu/selected-shape-context-menu.component";
import { SliderComponent } from "./components/slider/slider.component";
import { StatusBarComponent } from "./components/status-bar/status-bar.component";
import { SubnitButtonsComponent } from "./components/subnit-buttons/subnit-buttons.component";
import { BendsTogglesComponent } from "./components/templates/cassetes/bends-toggles/bends-toggles.component";
import { FlatComponent } from "./components/templates/cassetes/flat/flat.component";
import { KorbComponent } from "./components/templates/cassetes/korb/korb.component";
import { SingleBendComponent } from "./components/templates/cassetes/single-bend/single-bend.component";
import { TwoBendsComponent } from "./components/templates/cassetes/two-bends/two-bends.component";
import { UmschlagComponent } from "./components/templates/cassetes/umschlag/umschlag.component";
import { BalconyCornerComponent } from "./components/templates/mevaco/balcony-corner/balcony-corner.component";
import { BasicRectangleComponent } from "./components/templates/mevaco/basic-rectangle/basic-rectangle.component";
import { MultiShapeComponent } from "./components/templates/mevaco/multi-shape/multi-shape.component";
import { RectangleNotchComponent } from "./components/templates/mevaco/rectangle-notch/rectangle-notch.component";
import { StaircaseComponent } from "./components/templates/mevaco/staircase/staircase.component";
import { TiltedPlateComponent } from "./components/templates/mevaco/tilted-plate/tilted-plate.component";
import { TrapezComponent } from "./components/templates/mevaco/trapez/trapez.component";
import { TemplateUtils } from "./components/templates/templatesUtils";
import { TopBarComponent } from "./components/top-bar/top-bar.component";
import { TreatCornerComponent } from "./components/treat-corner/treat-corner.component";
import { TutorialModalComponent } from "./components/tutorial-modal/tutorial-modal.component";
import { UndoRedoComponent } from "./components/undo-redo/undo-redo.component";
import { UserTemplateItemComponent } from "./components/user-template-item/user-template-item.component";
import { ViewSwitchComponent } from "./components/view-switch/view-switch.component";
import { ViewerCarouselItemComponent } from "./components/viewer-layout/viewer-carousel-item/viewer-carousel-item.component";
import { ViewerLayoutComponent } from "./components/viewer-layout/viewer-layout.component";
import { RangeSlideDirective } from "./directives/range.directive";
import { AppErrorHandler } from "./providers/app-error-handler";
import { DrawingPerforationCollisionTreeProvider } from "./providers/colliders/drawing-perforation-collision-tree-provider.service";
import { DragArrowsManagerProvider } from "./providers/drag-arrows-manager.provider";
import { MeasurementsManagerProvider } from "./providers/measurements-manager.provider";
import { MevacoPointerProvider } from "./providers/mevaco-pointer.provider";
import { PerforationMarginsProvider } from "./providers/perforation-margins.provider";
import { PlateAabbProvider } from "./providers/plate-aabb.provider";
import { PreviewPlateProvider } from "./providers/preview-plate.provider";
import { ReloadPositionProvider } from "./providers/reload-position.provider";
import { ShapeWithHolesProvider } from "./providers/shape-with-holes.provider";
import { SvgIconProvider } from "./providers/svg-icon.provider";
import { TranslationProvider } from "./providers/translation.provider";
import { View3dProvider } from "./providers/view3d.provider";
import { DatasetService } from "./services/dataset.service";
import { MountingsService } from "./services/mountings.service";
import { PerforationService } from "./services/perforation.service";
import { PlateService } from "./services/plate.service";
import { PossibleOptionsService } from "./services/possible-options.service";
import { PreviewImageService } from "./services/preview-image.service";
import { ProductConfigurationService } from "./services/product-configuration.service";
import { TabbingService } from "./services/tabbing.service";
import { UserPatternService } from "./services/user-pattern.service";
import { UserTemplateService } from "./services/user-template.service";
import { WorkInterceptor } from "./services/work-interceptor";
import { WorkLoadService } from "./services/work-load.service";
import { mevacoEffects } from "./store/effects";
import { ConfiguratorPageComponent } from "./components/configurator-page/configurator-page.component";
import { RectangleExpandedMetalComponent } from "./components/templates/mevaco/rectangle-expanded-metal/rectangle-expanded-metal.component";
import { ExpandedMetalConfiguratorComponent } from './components/configurator-parts/expanded-metal-configurator/expanded-metal-configurator.component';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: WorkInterceptor, multi: true },
];

const appRoutes: Routes = [

  { path: "", component: FullComponent },
  ];

let storeModuleConfig = {
  initialState: mevacoInitialstate,
};
if (!environment.production) {
  const devtoolsConfig =
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__();
  if (devtoolsConfig) {
    devtoolsConfig.initialState = mevacoInitialstate;
    storeModuleConfig = devtoolsConfig;
  }
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
      // <-- debugging purposes only
    ),
    StoreModule.forRoot(mevacoReducers, {
      runtimeChecks : {
        strictActionImmutability: false,
        strictStateImmutability: false,
      }
    }),
    StoreRouterConnectingModule,
    NgbModule,
    ContextMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    ConfiguratorPageComponent,
    CarouselModule,
    AccordionModule,
    MatListModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatSelectModule,
    MatSliderModule,
    MultiSelectModule,
    MatExpansionModule,
    EffectsModule.forRoot(mevacoEffects),
    HttpClientModule,
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 100,
        })
      : [],
    NgxJsonViewerModule,
    NgxSliderModule,
  ],
  declarations: [
    AppComponent,
    WebcadHostComponent,
    FullComponent,
    ToolPanelComponent,
    ConfiguratorComponent,
    DropdownComponent,
    StringOptionComponent,
    NavigationComponent,
    PositionsListComponent,
    PositionsItemComponent,
    DebounceInputComponent,
    SelectedShapeContextMenuComponent,
    RectangleExpandedMetalComponent,
    ElementCreatorComponent,
    RuleActionComponent,
    StatusBarComponent,
    UndoRedoComponent,
    HelpLineComponent,
    TreatCornerComponent,
    SegmentPropertiesComponent,
    AttachmentConfigComponent,
    PerforationOffsetComponent,
    TopBarComponent,
    RuleErrorComponent,
    ErrorsVisualizerComponent,
    EditableLabelComponent,
    HintsComponent,
    UserTemplateItemComponent,
    SelectMoveComponent,
    ExpandableLabelComponent,
    PositionsItemListComponent,
    ImportModalComponent,
    ViewerLayoutComponent,
    ViewerCarouselItemComponent,
    PerforationConfiguratorComponent,
    MaterialConfiguratorComponent,
    SurfaceConfiguratorComponent,
    PrintLayoutComponent,
    PerforationAdjustmentComponent,
    ColorPickerComponent,
    FlatComponent,
    KorbComponent,
    TwoBendsComponent,
    SingleBendComponent,
    UmschlagComponent,
    TrapezComponent,
    StaircaseComponent,
    BalconyCornerComponent,
    MultiShapeComponent,
    TiltedPlateComponent,
    RectangleNotchComponent,
    BasicRectangleComponent,
    ColorPickerComponent,
    RangeSlideDirective,
    ViewSwitchComponent,
    BendsTogglesComponent,
    SubnitButtonsComponent,
    DesignModalComponent,
    CustomPatternEditorComponent,
    DebounceSliderComponent,
    CustomPatternSaveComponent,
    CustomPatternLoadComponent,
    CustomPatternLoadItemComponent,
    DesignModalComponent,
    TutorialModalComponent,
    BottomPanelComponent,
    MessageVisualizerComponent,
    CircularSliderComponent,
    RightPanelComponent,
    FadingEditorComponent,
    EffectsEditorComponent,
    DebounceRangeSliderComponent,
    PerfactionEditorComponent,
    PerfactionImagePanelComponent,
    PerfactionConfigComponent,
    PerfactionImageToolsComponent,
    MultiselectComponent,
    PerfactionPlatesComponent,
    SliderComponent,
    ExpandedMetalConfiguratorComponent,
  ],
  providers: [
    //  { provide: RouterStateSerializer, useClass: CustomSerializer }
    httpInterceptorProviders,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    SceneProvider,
    ToolProvider,
    PlateMeshProvider,
    WebcadProvider,
    TranslationProvider,
    DatasetService,
    ProductConfigurationService,
    MeasurementsManagerProvider,
    ProductConfigurationService,
    PerforationService,
    PreviewPlateProvider,
    PreviewImageService,
    PlateService,
    View3dProvider,
    ShapeWithHolesProvider,
    PlateAabbProvider,
    WorkLoadService,
    MevacoPointerProvider,
    MountingsService,
    UserTemplateService,
    UserPatternService,
    PerforationMarginsProvider,
    DragArrowsManagerProvider,
    SvgIconProvider,
    TabbingService,
    PossibleOptionsService,
    ReloadPositionProvider,
    TemplateUtils,
    DrawingPerforationCollisionTreeProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
