<div class="dialog-modal-header">
  <div class="dialog-modal-header-content">
    <div class="dialog-modal-title-container">
      <h4 class="dialog-modal-title-primary" id="rectangleNotch-title">
        {{ this.templateUtils.translate("Rectangle notch") }}
      </h4>
      <span class="dialog-modal-title-secondary"
        >{{
          this.templateUtils.translate("EnterDimenstions", "designer")
        }}
        (mm):</span
      >
    </div>
    <div class="dialog-modal-window">
      <span>{{
        this.templateUtils.translate("SketchDoesntChange", "designer")
      }}</span>
    </div>
  </div>

  <button
    type="button"
    class="close btn-close"
    aria-label="Close"
    (click)="dismiss('Cross click'); isModalOpenMethod()"
    [tabIndex]="-1"
  ></button>
</div>

<div>
  <div class="dialog-modal-body">
    <div class="notch-inputs">
      <input
        type="text"
        id="na"
        class="input-small rectangle-notch-a"
        [tabIndex]="2"
        [ngStyle]="
          rectangleNotchTemplate.a <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="rectangleNotchTemplate.a"
        (input)="
          setRectangleNotchA(this.templateUtils.roundInput($event, 0, 5000))
        "
        #na
        required
      />

      <input
        type="text"
        id="ne1"
        class="input-small rectangle-notch-e1"
        [tabIndex]="5"
        [ngStyle]="
          rectangleNotchTemplate.e1 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="rectangleNotchTemplate.e1"
        (input)="
          setRectangleNotchE1(
            this.templateUtils.roundInput(
              $event,
              0,
              rectangleNotchTemplate.a / 2
            )
          )
        "
        #ne1
        required
      />

      <input
        type="text"
        id="ne2"
        class="input-small rectangle-notch-e2"
        [tabIndex]="6"
        [ngStyle]="
          rectangleNotchTemplate.e2 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="rectangleNotchTemplate.e2"
        (input)="
          setRectangleNotchE2(
            this.templateUtils.roundInput(
              $event,
              0,
              rectangleNotchTemplate.a / 2
            )
          )
        "
        #ne2
        required
      />

      <input
        type="text"
        id="nb"
        class="input-small rectangle-notch-b"
        [tabIndex]="1"
        (keydown)="this.templateUtils.returnToLastElement($event, x3)"
        [ngStyle]="
          rectangleNotchTemplate.b <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="rectangleNotchTemplate.b"
        (input)="
          setRectangleNotchB(this.templateUtils.roundInput($event, 0, 5000))
        "
        #nb
        required
      />

      <input
        type="text"
        id="nf1"
        class="input-small rectangle-notch-f1"
        [tabIndex]="4"
        [ngStyle]="
          rectangleNotchTemplate.f1 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="rectangleNotchTemplate.f1"
        (input)="
          setRectangleNotchF1(
            this.templateUtils.roundInput(
              $event,
              0,
              rectangleNotchTemplate.b / 2
            )
          )
        "
        #nf1
        required
      />

      <input
        type="text"
        id="nf2"
        class="input-small rectangle-notch-f2"
        [tabIndex]="3"
        [ngStyle]="
          rectangleNotchTemplate.f2 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [value]="rectangleNotchTemplate.f2"
        (input)="
          setRectangleNotchF2(
            this.templateUtils.roundInput(
              $event,
              0,
              rectangleNotchTemplate.b / 2
            )
          )
        "
        #nf2
        required
      />

      <input
        type="text"
        id="x1"
        class="input-small rectangle-notch-x1"
        [tabIndex]="10"
        [ngStyle]="
          rectangleNotchTemplate.x1 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="rectangleNotchTemplate.x1"
        (input)="
          setRectangleNotchNotchX(
            1,
            this.templateUtils.roundInput(
              $event,
              0,
              rectangleNotchTemplate.b / 2
            )
          )
        "
        #x1
        required
      />

      <input
        type="text"
        id="y1"
        class="input-small rectangle-notch-y1"
        [tabIndex]="9"
        [ngStyle]="
          rectangleNotchTemplate.y1 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="rectangleNotchTemplate.y1"
        (input)="
          setRectangleNotchNotchY(
            1,
            this.templateUtils.roundInput(
              $event,
              0,
              rectangleNotchTemplate.a / 2
            )
          )
        "
        #y1
        required
      />

      <input
        type="text"
        id="x2"
        class="input-small rectangle-notch-x2"
        [tabIndex]="11"
        [ngStyle]="
          rectangleNotchTemplate.x2 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="rectangleNotchTemplate.x2"
        (input)="
          setRectangleNotchNotchX(
            2,
            this.templateUtils.roundInput(
              $event,
              0,
              rectangleNotchTemplate.b / 2
            )
          )
        "
        #x2
        required
      />

      <input
        type="text"
        id="y2"
        class="input-small rectangle-notch-y2"
        [tabIndex]="12"
        [ngStyle]="
          rectangleNotchTemplate.y2 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="rectangleNotchTemplate.y2"
        (input)="
          setRectangleNotchNotchY(
            2,
            this.templateUtils.roundInput(
              $event,
              0,
              rectangleNotchTemplate.a / 2
            )
          )
        "
        #y2
        required
      />

      <input
        type="text"
        id="x3"
        class="input-small rectangle-notch-x3"
        [tabIndex]="14"
        (keydown)="this.templateUtils.returnToFirstElement($event, nb)"
        [ngStyle]="
          rectangleNotchTemplate.x3 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="rectangleNotchTemplate.x3"
        (input)="
          setRectangleNotchNotchX(
            3,
            this.templateUtils.roundInput(
              $event,
              0,
              rectangleNotchTemplate.b / 2
            )
          )
        "
        #x3
        required
      />

      <input
        type="text"
        id="y3"
        class="input-small rectangle-notch-y3"
        [tabIndex]="13"
        [ngStyle]="
          rectangleNotchTemplate.y3 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="rectangleNotchTemplate.y3"
        (input)="
          setRectangleNotchNotchY(
            3,
            this.templateUtils.roundInput(
              $event,
              0,
              rectangleNotchTemplate.a / 2
            )
          )
        "
        #y3
        required
      />

      <input
        type="text"
        id="x4"
        class="input-small rectangle-notch-x4"
        [tabIndex]="7"
        [ngStyle]="
          rectangleNotchTemplate.x4 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="rectangleNotchTemplate.x4"
        (input)="
          setRectangleNotchNotchX(
            4,
            this.templateUtils.roundInput(
              $event,
              0,
              rectangleNotchTemplate.b / 2
            )
          )
        "
        #x4
        required
      />

      <input
        type="text"
        id="y4"
        class="input-small rectangle-notch-y4"
        [tabIndex]="8"
        [ngStyle]="
          rectangleNotchTemplate.y4 <= 0
            ? { 'background-color': '#f5c6cb' }
            : { 'background-color': 'white' }
        "
        [(ngModel)]="rectangleNotchTemplate.y4"
        (input)="
          setRectangleNotchNotchY(
            4,
            this.templateUtils.roundInput(
              $event,
              0,
              rectangleNotchTemplate.a / 2
            )
          )
        "
        #y4
        required
      />
    </div>
  </div>
  <div class="dialog-modal-footer">
    <span class="dialog-modal-link" (click)="this.templateUtils.setDefault()">
      {{ this.templateUtils.translate("Reset dimensions to default.") }}
    </span>
    <button
      #closeButton
      type="button"
      class="btn btn-primary btn-create"
      [tabIndex]="1"
      [disabled]="reqValRectangleNotch | async"
      (click)="close()"
    >
      {{ this.templateUtils.translate("Create") }}
    </button>
  </div>
</div>
