import {MevacoPage} from '../../model/mevaco-page.model';
import {getProductTypeFromCode, ProductType} from '../../model/product-configuration/product-configuration.model';

export function initialProductReducer(state: MevacoPage, _: any): MevacoPage {
  if (!state.productConfiguration.productType && state.dataset && state.productConfiguration.isMevacoUser !== 'Yes') {
    const allowedProducts = state.dataset.userAllowedProducts;
    if (allowedProducts.length === 1) {
      return {
        ...state,
        productConfiguration: {
          ...state.productConfiguration,
          productType: getProductTypeFromCode(allowedProducts[0])
        }
      };
    }
  }
  return state;
}
