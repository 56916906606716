import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "editable-label",
  templateUrl: "./editable-label.component.html",
  styleUrls: ["./editable-label.component.css"],
})
export class EditableLabelComponent implements OnInit {
  @Input() textModel: string;
  @Output() onTextSubmit: EventEmitter<string> = new EventEmitter();
  @ViewChild("editable", { static: true }) input: ElementRef;
  public editMode: boolean = false;

  constructor() {}

  ngOnInit() {}

  editLabel() {
    this.editMode = true;
    this.input.nativeElement.hidden = false;
    this.input.nativeElement.focus();
  }

  onEnter(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      this.emitValue();
    }
  }

  emitValue() {
    this.onTextSubmit.emit(this.input.nativeElement.value);
    this.textModel = this.input.nativeElement.value;
    this.input.nativeElement.blur();
    this.editMode = false;
  }
}
