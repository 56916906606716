import {Aabb2} from 'webcad';
import {Vector2} from 'webcad/math';

export interface PerforationCustomStump {
  // formType: string;
  enabled: boolean;
  formType: string;
  form: string;
  w: number;
  l: number;
  offsetDivX: number;
  offsetDivY: number;
  rotation: number;
  startX: number;
  startY: number;
  endX: number;
  endY: number;
}

export function stampIsReady(stamp: PerforationCustomStump): boolean {
  return stamp.enabled && stamp.form && stamp.w !== 0 && (stamp.form === 'Rund' || stamp.form === 'Quadrat' || stamp.form === 'Hv' || stamp.l !== 0) && stamp.offsetDivX !== 0 && stamp.offsetDivY !== 0;
}

export interface PerforationCustomGeom {
  offsetX: number;
  offsetY: number;
  stumps: PerforationCustomStump[];
}

export function getPerforationCustomGeomAabb(perforationCustomGeom: PerforationCustomGeom): Aabb2 {
  const size = perforationCustomGeom.stumps
    .filter(stamp => !!stamp && stamp.enabled && stamp.offsetDivX && stamp.offsetDivY && stamp.w)
    .reduce<Vector2>((vec, stamp) => {
    if (stamp && stamp.enabled) {
      vec.x = Math.max(vec.x, (perforationCustomGeom.offsetX / stamp.offsetDivX) / 200);
    }
    if (stamp && stamp.enabled) {
      vec.y = Math.max(vec.y, (perforationCustomGeom.offsetY / stamp.offsetDivY) / 200);
    }
    return vec;
  },
  {x: 0.01, y: 0.01});
  return {
    min: {x: 0, y: 0},
    max: size
  };
}

export function createDefaultStamp(): PerforationCustomStump {
  return {
    enabled: false,
    rotation: 0,
    startY: 0,
    startX: 0,
    endX: 0,
    endY: 0,
    form: '',
    formType: '',
    offsetDivX: 1,
    offsetDivY: 1,
    w: 0,
    l: 0
  };
}
