interface DesignerEffectBase {
    id: number;
    name: string;
}

export interface DesignerEffect extends DesignerEffectBase {
    parameters: any[];
}

export interface SelectedDesignerEffect extends DesignerEffectBase {
    enabled: boolean;
    savedParameters: any[];
}

export function mapToEffectWithInput(effect: DesignerEffect, deepCopy: boolean): SelectedDesignerEffect {
    const toMap: DesignerEffect = deepCopy ? JSON.parse(JSON.stringify(effect)) : effect;
    return {
      enabled: true,
      name: toMap.name,
      id: toMap.id,
      savedParameters: mapEffectParameters(toMap.parameters, true)
    }
}

function mapEffectParameters(parameters: any[], setDefaultValue: boolean): any[] {
 return parameters.map(x => {
    switch (x.type.toLowerCase()) {
      case DesignerParameterType.slider:
        return {
          ...x,
          currentMin: x.min,
          currentMax: setDefaultValue && x.default != null ? x.default : x.min
        };
      case DesignerParameterType.slider_range:
        return {
          ...x,
          currentMin: setDefaultValue && x.defaultMin != null ? x.defaultMin : x.min,
          currentMax: setDefaultValue && x.defaultMax != null ? x.defaultMax : x.max
        };
      case DesignerParameterType.toggle:
        return {
          ...x,
          currentValue: setDefaultValue && x.defaultValue != null ? x.defaultMin : x.value,
        };
      default:
        throw new Error("Not Implemented effect type");
    }
})
}

export enum DesignerParameterType {
    slider = 'slider',
    slider_range = 'slider_range',
    toggle = 'toggle'
}
