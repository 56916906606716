import {Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PlateShapeParams, UpdateShapeWithHolesParams} from '../model/client-server.Params/PlateShapeParams';
import {environment} from '../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {ShapeWithHoles} from '../model/shape-with-holes';
import {RoundUpCornersParams} from '../model/client-server.Params/RoundUpCornersParams';
import {MevacoResponse} from '../model/client-server.Params/mevaco-response.model';
import {DownloadDxfParams} from '../model/client-server.Params/download-dxf-params';
import {Segment} from 'webcad/models';
import {UploadDxfArgs, UploadDxfResponse} from '../store/actions';
import {BendingLine} from "../model/bending-line.model";
import {Vector2} from "webcad/math";

@Injectable()
export class PlateService {
  constructor(private http: HttpClient) {
  }

  applyBooleanOperation(params: PlateShapeParams): Observable<MevacoResponse<ShapeWithHoles[]>> {
    console.time('ApplyBooleanOperation');
    return this.http
      .post<MevacoResponse<ShapeWithHoles[]>>(environment.api_url + '/api/WebCad/ApplyBooleanOperation', params)
      .pipe(
        map(v => v),
        tap( v => console.timeEnd('ApplyBooleanOperation')),
        catchError(error => observableThrowError(error)
      )
    );
  }

  updatePlateShape(params: UpdateShapeWithHolesParams): Observable<MevacoResponse<ShapeWithHoles[]>> {
    return this.http.post<MevacoResponse<ShapeWithHoles[]>>(environment.api_url + '/api/WebCad/UpdateShape', params).pipe(map(v => v),
      catchError(error => observableThrowError(error))
    );
  }

  /*
  generateZones(params: GenerateZonesParams): Observable<MevacoResponse<ShapeWithHoles[]>> {
    return this.http.post<MevacoResponse<ShapeWithHoles[]>>(environment.api_url + '/api/WebCad/GenerateZones', params).pipe(map(v => v),
      catchError(error => observableThrowError(error))
    );
  }
  */
  roundUpCorners(params: RoundUpCornersParams): Observable<MevacoResponse<ShapeWithHoles[]>> {
    return this.http.post<MevacoResponse<ShapeWithHoles[]>>(environment.api_url + '/api/WebCad/RoundUpCorners', params).pipe(map(v => v),
      catchError(error => observableThrowError(error))
    );
  }

  uploadDxfFile(uploadArgs: UploadDxfArgs): Observable<MevacoResponse<UploadDxfResponse>> {
    const fd = new FormData();
    fd.append('stream', uploadArgs.file);
    fd.append('units', uploadArgs.units);
    fd.append('useBlocks', String(uploadArgs.useBlocks));
    return this.http.post<MevacoResponse<UploadDxfResponse>>(environment.api_url + '/api/mevaco/uploadDxf', fd
    ).pipe(map((v) => {
        return v;
      }),
      catchError((e) => observableThrowError(e)));
  }


  calculateShapeFromPolylines(segments: Segment[][]): Observable<MevacoResponse<ShapeWithHoles>> {
    return this.http.post<MevacoResponse<ShapeWithHoles>>(environment.api_url + '/api/mevaco/calculateShapeWithHolesFromPolylines', segments
    ).pipe(map((v) => {
        return v;
      }),
      catchError((e) => observableThrowError(e)));
  }

  downloadDxf(param: DownloadDxfParams): any {
    window.location.assign(environment.api_url + '/api/mevaco/saveDxf?id=' + param.id.toString() + '&position=' + param.position.toString());
  }

  downloadAllDxfs(id: string): any {
    window.location.assign(environment.api_url + '/api/mevaco/saveAllDxf?id=' + id.toString());
  }

  downloadImportDxf(id: number): any {
    window.location.assign(environment.api_url + '/api/mevaco/downloadImportDxf?id=' + id.toString());
  }

  createPerforationAreasFromShape(shape: ShapeWithHoles, bendingLines: BendingLine[], currentAreas:ShapeWithHoles[], point: Vector2, margin: number): Observable<MevacoResponse<ShapeWithHoles[]>> {
    return this.http.post<MevacoResponse<ShapeWithHoles[]>>(environment.api_url + '/api/WebCad/CreatePerforationAreasFromShape', {
        point,
        shape,
        margin,
        bendingLines,
        currentAreas
      }
    ).pipe(map((v) => {
        return v;
      }),
      catchError((e) => observableThrowError(e)));
  }
}
