import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { combineLatest } from "rxjs";
import {
  addVectors2,
  crossVector2,
  distanceVector2,
  dotVector2,
  getBoundingCircleFromAabb,
  getMatrixToLineLocalSpace,
  getRightPerpendicularVector2,
  lengthVector2,
  Matrix3,
  multiplyVector2byScalar,
  normalizeVector2,
  sqrDistanceVector2,
  subVectors2,
  transformPosition2,
  Vector2,
} from "webcad/math";
import { QuadNode } from "webcad/math/quad-node";
import {
  arcSegmentDistanceToCircle,
  getSegmentBoundingCircle,
  getShortestPathBetweenSegments,
  isAabb2Empty,
  isPointBetweenArcAngles,
  MeasurementModel,
  measurementsEqual,
  moveSegment,
  projectPointOnSegment,
  Segment,
  segmentSegmentIntersection,
  SegmentType,
} from "webcad/models";
import { ParseDecimalNumber } from "webcad/utils/parse-number";
import { BendingLine } from "../model/bending-line.model";
import {
  getStampList,
  Perforation,
  PositionsRow,
} from "../model/perforation.model";
import { ShapeWithHoles } from "../model/shape-with-holes";
import { getRotatedStampShape } from "../model/stamp.model";
import { SetAutomaticMeasurements } from "../store/actions/drawing.actions";
import {
  getBendingLines,
  getRemovedMeasurements,
  getShapeWithHoles,
  MevacoState,
} from "../store/reducers";
import {
  DrawingPerforationCollisionTreeProvider,
  PerforationHole,
} from "./colliders/drawing-perforation-collision-tree-provider.service";

@Injectable()
export class PerforationMarginsProvider {
  private measurementsModels: MeasurementModel[] = [];
  private bendlinesMargins: number[] = [];
  // private step: Step;

  constructor(
    private store: Store<MevacoState>,
    private perforationCollisionTreeProvider: DrawingPerforationCollisionTreeProvider
  ) {}

  public init(): void {
    combineLatest([
      this.perforationCollisionTreeProvider.quadTree,
      this.store.pipe(select(getShapeWithHoles)),
      this.store.pipe(select(getBendingLines)),
      this.store.pipe(select(getRemovedMeasurements)),
    ]).subscribe(
      ([
        perforationCollisionTree,
        shape,
        bendingLines,
        removedMeasurements,
      ]) => {
        this.updateMeasurements(
          perforationCollisionTree.holes,
          shape,
          bendingLines
        );
        this.measurementsModels = this.measurementsModels.filter(
          (v) => !removedMeasurements.find((x) => measurementsEqual(x, v))
        );
        const newAutomatics = new Map<number, MeasurementModel>();
        for (let i = 0; i < this.measurementsModels.length; i++) {
          newAutomatics.set(i, this.measurementsModels[i]);
        }
        this.store.dispatch(
          new SetAutomaticMeasurements({
            measurements: newAutomatics,
            bendingLinesDistances: this.bendlinesMargins,
          })
        );
      }
    );
  }

  private updateMeasurements(
    collisionThree: QuadNode<PerforationHole>,
    shapeWithHoles: ShapeWithHoles,
    bendingLines: BendingLine[]
  ): void {
    this.measurementsModels = [];

    if (
      !collisionThree.isEmpty() &&
      shapeWithHoles &&
      shapeWithHoles.conture &&
      shapeWithHoles.conture.length > 0
    ) {
      const segments: Segment[] = [...shapeWithHoles.conture];
      for (const hole of shapeWithHoles.holes) {
        segments.push(...hole);
      }
      this.measurementsModels = segments
        .map((segment) =>
          createSegmentMeasurement(segment, collisionThree, false, false)
        )
        .filter((mm) => mm != null)
        .filter((mm) => isNotCrossingOtherSegments(mm, segments, bendingLines));

      this.bendlinesMargins = [];
      for (const bendingLine of bendingLines) {
        let mms = createBendingMeasurement(
          bendingLine,
          collisionThree,
          false,
          false
        );
        mms = mms.filter((mm) =>
          isNotCrossingOtherSegments(mm, segments, bendingLines)
        );
        let dist = null;
        if (mms.length > 0) {
          dist = mms[0].exchange.value;
          for (let i = 1; i < mms.length; i++) {
            dist = Math.min(dist, mms[i].exchange.value);
          }
        }
        this.bendlinesMargins.push(dist);
        this.measurementsModels.push(...mms);
      }
    }
    if (shapeWithHoles.aabb && !isAabb2Empty(shapeWithHoles.aabb)) {
      this.measurementsModels.push(
        {
          editable: false,
          start: {
            x: shapeWithHoles.aabb.min.x,
            y: shapeWithHoles.aabb.min.y,
            z: 0,
          },
          measurementDirection: { x: 1, y: 0, z: 0 },
          direction: { x: 0, y: -1, z: 0 },
          maxValue: 1,
          focusable: false,
          visible: true,
          distance: 0,
          exchange: {
            value: shapeWithHoles.aabb.max.x - shapeWithHoles.aabb.min.x,
            onInputLive: null, // to be set by tool
            onInputConfirmed: null, // to be set by tool
            inputValidation: (val) =>
              val !== "" && ParseDecimalNumber(val) !== undefined,

            fromModel: (value: number) =>
              (Math.round(value * 10000) / 10).toString(),
            toModel: (value: string) => ParseDecimalNumber(value) / 1000,
          },
          mask: 0x00000004,
        },
        {
          editable: false,
          start: {
            x: shapeWithHoles.aabb.min.x,
            y: shapeWithHoles.aabb.min.y,
            z: 0,
          },
          measurementDirection: { x: 0, y: 1, z: 0 },
          direction: { x: -1, y: 0, z: 0 },
          maxValue: 1,
          focusable: false,
          visible: true,
          distance: 0,
          exchange: {
            value: shapeWithHoles.aabb.max.y - shapeWithHoles.aabb.min.y,
            onInputLive: null, // to be set by tool
            onInputConfirmed: null, // to be set by tool
            inputValidation: (val) =>
              val !== "" && ParseDecimalNumber(val) !== undefined,

            fromModel: (value: number) =>
              (Math.round(value * 10000) / 10).toString(),
            toModel: (value: string) => ParseDecimalNumber(value) / 1000,
          },
          mask: 0x00000004,
        }
      );
    }
  }
}

function isNotCrossingOtherSegments(
  mm: MeasurementModel,
  segments: Segment[],
  bendingLines: BendingLine[]
) {
  const begin = mm.start;
  const end = addVectors2(
    mm.start,
    multiplyVector2byScalar(mm.measurementDirection, mm.exchange.value)
  );
  const mmSeg = { type: SegmentType.line, begin, end };
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    if (isMeasurementCrossingSegment(mmSeg, segment)) {
      return false;
    }
  }
  for (let i = 0; i < bendingLines.length; i++) {
    const bendingLine = bendingLines[i];
    const { left, right } = getOssbLineSegments(bendingLine);
    if (
      isMeasurementCrossingSegment(mmSeg, left) &&
      isMeasurementCrossingSegment(mmSeg, right)
    ) {
      return false;
    }
  }
  return true;
}
/*
function getOssbSegment(bendingLine:BendingLine, perforationCenter:Vector2):Segment {
  //bendingLine.bentParams.ossb;
  const bendingVec = subVectors2(bendingLine.end, bendingLine.begin);
  const toCenter = subVectors2(perforationCenter, bendingLine.begin);

  const perpendicular:Vector2 = crossVector2(bendingVec, toCenter) > 0 ?
    getRightPerpendicularVector2(bendingVec) :
    getLeftPerpendicularVector2(bendingVec);

  const offset = multiplyVector2byScalar(normalizeVector2(perpendicular), bendingLine.bentParams.ossb - bendingLine.bentParams.bendAllowance/2);

  return {
    type:SegmentType.line,
    begin: addVectors2(bendingLine.begin,offset),
    end: addVectors2(bendingLine.end,offset)
  }
}
*/

function isMeasurementCrossingSegment(
  measurementLineSegment: Segment,
  segment: Segment
) {
  const intersection = segmentSegmentIntersection(
    measurementLineSegment,
    segment
  );
  return (
    intersection !== null &&
    distanceVector2(intersection, measurementLineSegment.begin) > 0.0001 &&
    distanceVector2(intersection, measurementLineSegment.end) > 0.0001
  );
}

interface OssbLineSegments {
  right: Segment;
  left: Segment;
}
function getOssbLineSegments(bendingLine: BendingLine): OssbLineSegments {
  const bendingVec = subVectors2(bendingLine.end, bendingLine.begin);
  const rightOffset = multiplyVector2byScalar(
    normalizeVector2(getRightPerpendicularVector2(bendingVec)),
    bendingLine.bentParams.ossb - bendingLine.bentParams.bendAllowance / 2
  );
  const leftOffset = multiplyVector2byScalar(rightOffset, -1);
  return {
    right: {
      type: SegmentType.line,
      begin: addVectors2(bendingLine.begin, rightOffset),
      end: addVectors2(bendingLine.end, rightOffset),
    },
    left: {
      type: SegmentType.line,
      begin: addVectors2(bendingLine.begin, leftOffset),
      end: addVectors2(bendingLine.end, leftOffset),
    },
  };
}

function createBendingMeasurement(
  bendingLine: BendingLine,
  collisionThree: QuadNode<PerforationHole>,
  skipIfOnLeft: boolean,
  skipIfOnRight: boolean
): MeasurementModel[] {
  const { left, right } = getOssbLineSegments(bendingLine);
  const lMeasurement = createSegmentMeasurement(
    right,
    collisionThree,
    true,
    false
  );
  const rMeasurement = createSegmentMeasurement(
    left,
    collisionThree,
    false,
    true
  );
  const result = [];
  if (lMeasurement) {
    result.push(lMeasurement);
  }
  if (rMeasurement) {
    result.push(rMeasurement);
  }
  return result;
}

interface ClosestHole {
  perforationHole: PerforationHole;
  distance: number;
  p1: Vector2;
  p2: Vector2;
}

function getClosestHoleToArcSegment(
  segment: Segment,
  collisionThree: QuadNode<PerforationHole>,
  currentClosest: ClosestHole
): ClosestHole {
  if (!!collisionThree.children) {
    let children = collisionThree.children.map((child) => {
      const bc = getBoundingCircleFromAabb(child.elementsSize);
      return {
        child,
        bc: bc,
        distance: arcSegmentDistanceToCircle(segment, bc),
      };
    });
    children = children.sort((a, b) => a.distance - b.distance);
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      if (!child.child.isEmpty()) {
        if (child.distance < currentClosest.distance) {
          currentClosest = getClosestHoleToArcSegment(
            segment,
            child.child,
            currentClosest
          );
        } else {
          break;
        }
      }
    }
  } else {
    for (let i = 0; i < collisionThree.elements.length; i++) {
      const hole = collisionThree.elements[i];
      const bc = getBoundingCircleFromAabb(hole.aabb);
      const distance = arcSegmentDistanceToCircle(segment, bc);
      if (distance < currentClosest.distance) {
        const rotated = getRotatedStampShape(
          hole.stamp,
          hole.shapeIndex,
          hole.perforation.rotation
        );
        for (let j = 0; j < rotated.length; j++) {
          const polyline = rotated[j];
          for (let k = 0; k < polyline.length; k++) {
            const segment2 = polyline[k];
            const bc = getSegmentBoundingCircle(segment2);
            bc.origin = addVectors2(bc.origin, hole.segmentPosition);
            const distance = arcSegmentDistanceToCircle(segment, bc);
            if (distance < currentClosest.distance) {
              const result = getShortestPathBetweenSegments(
                segment,
                moveSegment(segment2, hole.segmentPosition)
              );
              if (result) {
                const dist = distanceVector2(result.begin, result.end);
                const p1 = isPointBetweenArcAngles(result.begin, segment);
                const p2 = isPointBetweenArcAngles(result.end, segment);
                if (dist < currentClosest.distance && p1 && p2) {
                  currentClosest = {
                    distance: dist,
                    p1: result.begin,
                    p2: result.end,
                    perforationHole: hole,
                  };
                }
              }
            }
          }
        }
      }
    }
  }

  return currentClosest;
}

function closestCompare(a, b) {
  // promote left upper corner: we want to show measurement on top and left side
  const dot = dotVector2(
    normalizeVector2(subVectors2(b.bc.origin, a.bc.origin)),
    { x: 0.7071067811865475, y: 0.7071067811865475 }
  );

  // take the closets one but if the difference is less then 0.5 mm then take the one that is more on left top side
  return (
    Math.round(
      (Math.abs(a.localOrigin.y) - Math.abs(b.localOrigin.y)) * 10000
    ) *
      10 +
    dot
  );
}

function getClosestHoleToLineSegment(
  toLineMatrix: Matrix3,
  segmentLength: number,
  segment: Segment,
  collisionThree: QuadNode<PerforationHole>,
  skipIfOnLeft: boolean,
  skipIfOnRight: boolean,
  currentClosest: ClosestHole
): ClosestHole {
  if (!!collisionThree.children) {
    const children = collisionThree.children
      .map((child) => {
        const bc = getBoundingCircleFromAabb(child.elementsSize);
        return {
          child,
          bc: bc,
          localOrigin: transformPosition2(toLineMatrix, bc.origin),
        };
      })
      .sort(closestCompare);
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      if (!child.child.isEmpty()) {
        const distance = Math.max(
          0,
          Math.abs(child.localOrigin.y) - child.bc.radius
        );
        if (distance < currentClosest.distance + 0.0005) {
          if (
            (!skipIfOnRight || child.localOrigin.y < child.bc.radius) &&
            (!skipIfOnLeft || child.localOrigin.y > -child.bc.radius) && // filter out by sides
            child.localOrigin.x > -child.bc.radius &&
            child.localOrigin.x < segmentLength + child.bc.radius // skip if its before or after the line
          ) {
            currentClosest = getClosestHoleToLineSegment(
              toLineMatrix,
              segmentLength,
              segment,
              child.child,
              skipIfOnLeft,
              skipIfOnRight,
              currentClosest
            );
          }
        } else {
          break;
        }
      }
    }
  } else {
    const elements = collisionThree.elements
      .map((e) => {
        const bc = getBoundingCircleFromAabb(e.aabb);
        return {
          child: e,
          bc: bc,
          localOrigin: transformPosition2(toLineMatrix, bc.origin),
        };
      })
      .sort(closestCompare);
    for (let i = 0; i < elements.length; i++) {
      const hole = elements[i].child;
      const bc = elements[i].bc;
      const localOrigin = elements[i].localOrigin;
      const distance = Math.max(0, Math.abs(localOrigin.y) - bc.radius);
      if (
        distance < currentClosest.distance &&
        (!skipIfOnRight || localOrigin.y < bc.radius) &&
        (!skipIfOnLeft || localOrigin.y > -bc.radius) && // filter out by sides
        localOrigin.x > -bc.radius &&
        localOrigin.x < segmentLength + bc.radius // skip if its before or after the line
      ) {
        const rotated = getRotatedStampShape(
          hole.stamp,
          hole.shapeIndex,
          hole.perforation.rotation
        );
        for (let j = 0; j < rotated.length; j++) {
          const polyline = rotated[j];
          for (let k = 0; k < polyline.length; k++) {
            const segment2 = polyline[k];
            const bc = getSegmentBoundingCircle(segment2);
            bc.origin = addVectors2(bc.origin, hole.segmentPosition);
            const localOrigin = transformPosition2(toLineMatrix, bc.origin);
            const distance = Math.max(0, Math.abs(localOrigin.y) - bc.radius);
            if (
              distance < currentClosest.distance + 0.0005 &&
              (!skipIfOnRight || localOrigin.y < bc.radius) &&
              (!skipIfOnLeft || localOrigin.y > -bc.radius) && // filter out by sides
              localOrigin.x > -bc.radius &&
              localOrigin.x < segmentLength + bc.radius // skip if its before or after the line
            ) {
              const result = getShortestPathBetweenSegments(
                segment,
                moveSegment(segment2, hole.segmentPosition)
              );
              if (result) {
                const dist = distanceVector2(result.begin, result.end);
                const localP1 = transformPosition2(toLineMatrix, result.begin);
                const localP2 = transformPosition2(toLineMatrix, result.end);
                if (
                  dist < currentClosest.distance &&
                  localP1.x >= 0 &&
                  localP1.x <= segmentLength &&
                  localP2.x >= 0 &&
                  localP2.x <= segmentLength &&
                  (((!skipIfOnRight || localP1.y < 0) &&
                    (!skipIfOnLeft || localP1.y > 0)) ||
                    ((!skipIfOnRight || localP2.y < 0) &&
                      (!skipIfOnLeft || localP2.y > 0)))
                ) {
                  currentClosest = {
                    distance: dist,
                    p1: result.begin,
                    p2: result.end,
                    perforationHole: hole,
                  };
                }
              }
            }
          }
        }
      }
    }
  }

  return currentClosest;
}

function createSegmentMeasurement(
  segment: Segment,
  collisionThree: QuadNode<PerforationHole>,
  skipIfOnLeft: boolean,
  skipIfOnRight: boolean
): MeasurementModel {
  let closest: ClosestHole = null;
  if (segment.type === SegmentType.line) {
    const lm = getMatrixToLineLocalSpace(segment.begin, segment.end);
    const l = distanceVector2(segment.begin, segment.end);
    closest = getClosestHoleToLineSegment(
      lm,
      l,
      segment,
      collisionThree,
      skipIfOnLeft,
      skipIfOnRight,
      {
        distance: Number.MAX_VALUE,
        perforationHole: null,
        p1: null,
        p2: null,
      }
    );
  } else {
    closest = getClosestHoleToArcSegment(segment, collisionThree, {
      distance: Number.MAX_VALUE,
      perforationHole: null,
      p1: null,
      p2: null,
    });
  }
  if (!!closest && !!closest.perforationHole) {
    const toEnd = subVectors2(closest.p2, closest.p1);
    const l = lengthVector2(toEnd);
    const mDir = multiplyVector2byScalar(toEnd, 1 / l);
    const dir = getRightPerpendicularVector2(mDir);
    const dot = dotVector2(dir, {
      x: 0.7071067811865475,
      y: 0.7071067811865475,
    });
    if (dot < -0.0000001) {
      dir.x = -dir.x;
      dir.y = -dir.y;
    }

    let distance = 0.0001;
    if (segment.type === SegmentType.line) {
      const dirM = getMatrixToLineLocalSpace(
        closest.p2,
        addVectors2(closest.p2, dir)
      );
      const localBegin = transformPosition2(dirM, segment.begin);
      const localEnd = transformPosition2(dirM, segment.end);
      distance = Math.max(localBegin.x, localEnd.x) + 0.04;
    }

    const model: MeasurementModel = {
      editable: false,
      start: { x: closest.p1.x, y: closest.p1.y, z: 0 },
      measurementDirection: { x: mDir.x, y: mDir.y, z: 0 },
      direction: { x: dir.x, y: dir.y, z: 0 },
      maxValue: 1,
      focusable: false,
      visible: true,
      distance: distance,
      exchange: {
        value: l,
        onInputLive: null, // to be set by tool
        onInputConfirmed: null, // to be set by tool
        inputValidation: (val) =>
          val !== "" && ParseDecimalNumber(val) !== undefined,

        fromModel: (value: number) =>
          (Math.round(value * 10000) / 10).toString(),
        toModel: (value: string) => ParseDecimalNumber(value) / 1000,
      },
      mask: 0x00000004,
    };
    return model;
  }
  return null;
}
function createSegmentMeasurementOld(
  segment: Segment,
  perforations: Perforation[],
  skipIfOnLeft: boolean,
  skipIfOnRight: boolean
): MeasurementModel {
  if ((skipIfOnLeft || skipIfOnRight) && segment.type !== SegmentType.line) {
    throw new Error("Skip side option can by only used with lne Segment");
  }
  let minSqrDist = Number.MAX_VALUE;
  let start: Vector2;
  let end: Vector2;
  for (const perforation of perforations) {
    let holeMinX = Number.MAX_VALUE;
    let holeMinY = Number.MAX_VALUE;
    let holeMaxX = -Number.MAX_VALUE;
    let holeMaxY = -Number.MAX_VALUE;
    const polygons = getStampList(perforation, true).reduce(
      (acc, x) => acc.concat(x.polygons),
      []
    );
    for (const polygon of polygons) {
      for (const point of polygon) {
        holeMaxX = Math.max(point.x, holeMaxX);
        holeMaxY = Math.max(point.y, holeMaxY);
        holeMinX = Math.min(point.x, holeMinX);
        holeMinY = Math.min(point.y, holeMinY);
      }
    }

    for (const el of perforation.positions) {
      const minY = el.y + holeMinY;
      const maxY = el.y + holeMaxY;
      const minX = el.x + holeMinX;
      let maxX = el.x + holeMaxX;
      const count = (el as PositionsRow).count;
      if (count > 1) {
        maxX = el.x + (el as PositionsRow).dir.x * (count - 1) + holeMaxX;
      }
      const cos = Math.cos(perforation.rotation || 0);
      const sin = Math.sin(perforation.rotation || 0);

      let s: Vector2 = { x: minX, y: minY };
      if (perforation.rotation) {
        s = {
          x: cos * s.x - sin * s.y,
          y: sin * s.x + cos * s.y,
        };
      }

      if (skipIfOnLeft) {
        const v0 = subVectors2(segment.end, segment.begin);
        const v1 = subVectors2(s, segment.begin);
        if (crossVector2(v0, v1) <= 0) {
          continue;
        }
      }

      if (skipIfOnRight) {
        const v0 = subVectors2(segment.end, segment.begin);
        const v1 = subVectors2(s, segment.begin);
        if (crossVector2(v0, v1) >= 0) {
          continue;
        }
      }

      let p = projectPointOnSegment(s, segment);
      if (p && sqrDistanceVector2(p, s) < minSqrDist) {
        const d = sqrDistanceVector2(p, s);
        if (d < minSqrDist) {
          minSqrDist = d;
          start = s;
          end = p;
        }
      }

      s = { x: maxX, y: minY };
      if (perforation.rotation) {
        s = {
          x: cos * s.x - sin * s.y,
          y: sin * s.x + cos * s.y,
        };
      }
      p = projectPointOnSegment(s, segment);
      if (p && sqrDistanceVector2(p, s) < minSqrDist) {
        const d = sqrDistanceVector2(p, s);
        if (d < minSqrDist) {
          minSqrDist = d;
          start = s;
          end = p;
        }
      }

      s = { x: maxX, y: maxY };
      if (perforation.rotation) {
        s = {
          x: cos * s.x - sin * s.y,
          y: sin * s.x + cos * s.y,
        };
      }
      p = projectPointOnSegment(s, segment);
      if (p && sqrDistanceVector2(p, s) < minSqrDist) {
        const d = sqrDistanceVector2(p, s);
        if (d < minSqrDist) {
          minSqrDist = d;
          start = s;
          end = p;
        }
      }

      s = { x: minX, y: maxY };
      if (perforation.rotation) {
        s = {
          x: cos * s.x - sin * s.y,
          y: sin * s.x + cos * s.y,
        };
      }
      p = projectPointOnSegment(s, segment);
      if (p && sqrDistanceVector2(p, s) < minSqrDist) {
        const d = sqrDistanceVector2(p, s);
        if (d < minSqrDist) {
          minSqrDist = d;
          start = s;
          end = p;
        }
      }
    }
  }

  if (!!start && !!end) {
    const toEnd = subVectors2(end, start);
    const l = lengthVector2(toEnd);
    const mDir = multiplyVector2byScalar(toEnd, 1 / l);
    const dir = getRightPerpendicularVector2(mDir);
    const model: MeasurementModel = {
      editable: false,
      start: { x: start.x, y: start.y, z: 0 },
      measurementDirection: { x: mDir.x, y: mDir.y, z: 0 },
      direction: { x: dir.x, y: dir.y, z: 0 },
      maxValue: 1,
      focusable: false,
      visible: true,
      distance: 0,
      exchange: {
        value: l,
        onInputLive: null, // to be set by tool
        onInputConfirmed: null, // to be set by tool
        inputValidation: (val) =>
          val !== "" && ParseDecimalNumber(val) !== undefined,

        fromModel: (value: number) =>
          (Math.round(value * 10000) / 10).toString(),
        toModel: (value: string) => ParseDecimalNumber(value) / 1000,
      },
      mask: 0x00000004,
    };
    return model;
  }
  return null;
}
