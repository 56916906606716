import { select, Store } from "@ngrx/store";
import { BehaviorSubject, Subscription } from "rxjs";
import { PointerState } from "webcad/collision";
import {
  addVectors2,
  addVectors3,
  copyVector2,
  distanceVector2,
  multiplyVector3byScalar,
  normalizeVector2,
  normalizeVector3,
  sqrDistanceVector2,
  subVectors2,
  subVectors3,
  Vector2,
  Vector3,
} from "webcad/math";
import {
  MeasurementModelManager,
  MeasurementsManager,
} from "webcad/measurements";
import {
  aabbOfPolyline,
  getShortestPathBetweenTwoPolylines,
  IsPointInsideOfShape,
  movePolyline,
  projectPointOnSegment,
  Segment,
} from "webcad/models";
import { ActionType } from "../../../model";
import { HelpLine } from "../../../model/help-line.model";
import {
  createMountingMeshFrom,
  isMountingModelReady,
  Mounting,
  MountingAttachment,
  MountingPositioningType,
  scaleMounting,
} from "../../../model/mounting.model";
import { ConfigurationMaterial } from "../../../model/product-configuration/configuration-material.model";
import { ShapeWithHoles } from "../../../model/shape-with-holes";
import { MeasurementsManagerProvider } from "../../../providers/measurements-manager.provider";
import { ClosestSegments } from "../../../providers/mevaco-pointer.provider";
import { SceneProvider } from "../../../providers/scene.provider";
import { ToolProvider } from "../../../providers/tool.provider";
import { TranslationProvider } from "../../../providers/translation.provider";
import {
  AddToolHelpLine,
  RemoveAllToolHelpLines,
  RequestRender,
  SetHintMessage,
} from "../../../store/actions/drawing.actions";
import { AddMounting } from "../../../store/actions/mountings.actions";
import {
  getMaterial,
  getMountingHoles,
  getMountingsModel,
  getPerforationArea,
  getShapeWithHoles,
  MevacoState,
} from "../../../store/reducers";
import { DrawingTool } from "../../drawing-tool.interface";
import { updateXYMeasurements } from "../../utils";
import {Mesh, Scene, ShaderMaterial, Vector4, Vector3 as B_Vector3} from "webcad/babylonjs/core";
export class MountingTool extends DrawingTool {
  mode: ActionType;
  private closestSegments: ClosestSegments;
  private mountingModel: Mounting;
  private mountingModelSub: Subscription;
  private perforationArea: ShapeWithHoles[];
  private perforationAreaSub: Subscription;
  private mountingHoles: MountingAttachment[];
  private point: Vector3;
  private scene: Scene;
  private canBuildMat: ShaderMaterial;
  private cantBuildMat: ShaderMaterial;
  private currentMesh: Mesh;
  private shapeWithHoles: ShapeWithHoles;
  private contoursAreaSub: Subscription;
  private canPlace: BehaviorSubject<boolean>;
  private mountingHolesSub: Subscription;
  private canPlaceSub: Subscription;
  private measurementManager: MeasurementsManager;
  private verticalMeasurement: MeasurementModelManager;
  private horizontalMeasurement: MeasurementModelManager;
  public rotation: BehaviorSubject<number>;
  private mountingVertices: Vector2[];
  private mountingShape: Segment[];
  private materialCode: string;
  private material: ConfigurationMaterial;
  private shape: Segment[];
  private rotationSub: Subscription;

  constructor(
    private store: Store<MevacoState>,
    private sceneProvider: SceneProvider,
    private measurementManagerProvider: MeasurementsManagerProvider,
    private toolProvider: ToolProvider,
    private translationProvider: TranslationProvider
  ) {
    super();
    this.rotation = new BehaviorSubject(0);
    this.canPlace = new BehaviorSubject(false);
    this.sceneProvider.getSubscription().subscribe((scene) => {
      if (this.currentMesh) {
        this.currentMesh.dispose();
        this.currentMesh = null;
      }
      if (this.cantBuildMat) {
        this.cantBuildMat.dispose();
        this.cantBuildMat = null;
      }
      if (this.canBuildMat) {
        this.canBuildMat.dispose();
        this.canBuildMat = null;
      }
      this.scene = scene;
      if (scene) {
        this.cantBuildMat = new ShaderMaterial(
          "shader",
          this.scene,
          {
            vertex: "mountings",
            fragment: "customColor",
          },
          {
            attributes: ["position", "instancePos"],
          }
        );
        this.cantBuildMat.setVector4(
          "matColor",
          new Vector4(1.0, 0.0, 0.0, 1)
        );

        this.canBuildMat = new ShaderMaterial(
          "shader",
          this.scene,
          {
            vertex: "mountings",
            fragment: "customColor",
          },
          {
            attributes: ["position", "instancePos"],
          }
        );
        this.canBuildMat.setVector4(
          "matColor",
          new Vector4(0.0, 1.0, 0.0, 1)
        );

        if (
          this.mountingModel &&
          this.mountingModel.form &&
          this.mountingModel.width &&
          this.scene
        ) {
          this.currentMesh = createMountingMeshFrom(
            this.mountingModel,
            this.mountingShape,
            this.scene,
            this.thickness(),
            0,
            [{ x: 0, y: 0 }]
          );
          this.shape = scaleMounting(
            this.mountingModel,
            this.mountingShape,
            this.rotation.getValue()
          );
          this.currentMesh.isVisible = false;
        }
      }
    });
    this.measurementManagerProvider.getSubsciption().subscribe((value) => {
      if (value) {
        this.measurementManager = value;
        this.disposeMeasurements();
      }
    });
    // this.store.pipe(select(getMaterialCode)).subscribe((v) => this.materialCode = v);
    this.store.pipe(select(getMaterial)).subscribe((v) => {
      this.material = v;
      this.materialCode = v && v.materialcodeLomoe;
    });
  }

  thickness(): number {
    return this.material && this.material.materialThickness
      ? this.material.materialThickness / 1000
      : 0.001;
  }

  setRotation(newRotation: string) {
    const num: number = Number(newRotation);
    if (!isNaN(num) && newRotation !== "") {
      const rads = (num * Math.PI) / 180;
      this.rotation.next(rads);
      this.visualizeMounting(this.point);
    }
  }

  activate() {
    this.mountingModelSub = this.store
      .pipe(select(getMountingsModel))
      .subscribe((v) => {
        if (this.currentMesh) {
          this.currentMesh.dispose();
          this.currentMesh = null;
        }
        if (
          v &&
          v.mounting.form !== null &&
          v.mounting.width !== null &&
          this.scene
        ) {
          this.mountingModel = v.mounting;
          this.mountingVertices = v.verticesMap.get(this.mountingModel.form);
          this.mountingShape = v.shapeMap.get(this.mountingModel.form);
          this.currentMesh = createMountingMeshFrom(
            v.mounting,
            this.mountingShape,
            this.scene,
            this.thickness(),
            0,
            [{ x: 0, y: 0 }]
          );
          this.shape = scaleMounting(
            this.mountingModel,
            this.mountingShape,
            this.rotation.getValue()
          );
          this.currentMesh.isVisible = false;
        }
      });

    this.rotationSub = this.rotation.subscribe((v) => {
      if (!!this.mountingModel && !!this.mountingShape) {
        this.shape = scaleMounting(this.mountingModel, this.mountingShape, v);
      }
    });

    this.perforationAreaSub = this.store
      .pipe(select(getPerforationArea))
      .subscribe((v) => (this.perforationArea = v));
    this.contoursAreaSub = this.store
      .pipe(select(getShapeWithHoles))
      .subscribe((v) => (this.shapeWithHoles = v));
    this.mountingHolesSub = this.store
      .pipe(select(getMountingHoles))
      .subscribe((v) => (this.mountingHoles = Array.from(v.values())));
    this.canPlaceSub = this.canPlace.subscribe((v) => {
      if (this.currentMesh) {
        this.currentMesh.material = v ? this.canBuildMat : this.cantBuildMat;
      }
    });
    if (this.measurementManager) {
      if (!this.verticalMeasurement) {
        this.verticalMeasurement =
          this.measurementManager.getMeasurementModel();
        this.verticalMeasurement.setInputCallbacks(
          (value: number) => {
            this.setVertical(value);
            return value;
          },
          (value: number) => {
            this.setVertical(value);
            this.tryApplyMounting();
            return value;
          }
        );
      }
      if (!this.horizontalMeasurement) {
        this.horizontalMeasurement =
          this.measurementManager.getMeasurementModel();
        this.horizontalMeasurement.setInputCallbacks(
          (value: number) => {
            this.setHorizontal(value);
            return value;
          },
          (value: number) => {
            this.setHorizontal(value);
            this.tryApplyMounting();
            return value;
          }
        );
      }
    }
    this.store.dispatch(new SetHintMessage(this.translate("addMounting"))); // Click to add mounting
  }

  setVertical(value: number): void {
    const point = this.verticalMeasurement.getStart();
    const wall = this.verticalMeasurement.getEnd();
    const moveDir = normalizeVector3(subVectors3(point, wall));
    const newPoint = addVectors3(wall, multiplyVector3byScalar(moveDir, value));
    this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
    this.updateClosestVerticalSegment(this.point);
    this.updateXYMeasurements(
      this.horizontalMeasurement,
      this.verticalMeasurement,
      this.point
    );
    this.canPlace.next(this.canPlaceMountingHoleInPosition(newPoint));
    this.visualizeMounting(this.point);
    this.store.dispatch(new RequestRender());
  }

  setHorizontal(value: number): void {
    const point = this.horizontalMeasurement.getStart();
    const wall = this.horizontalMeasurement.getEnd();
    const moveDir = normalizeVector3(subVectors3(point, wall));
    const newPoint = addVectors3(wall, multiplyVector3byScalar(moveDir, value));
    this.point = { x: newPoint.x, y: newPoint.y, z: newPoint.z };
    this.updateClosestHorizontalSegment(this.point);
    this.updateXYMeasurements(
      this.horizontalMeasurement,
      this.verticalMeasurement,
      this.point
    );
    this.canPlace.next(this.canPlaceMountingHoleInPosition(newPoint));
    this.visualizeMounting(this.point);
    this.store.dispatch(new RequestRender());
  }

  private updateClosestVerticalSegment(point: Vector3) {
    this.closestSegments.verticalSegment =
      this.toolProvider.getNearestVerticalSegmentToPoint(point);
  }

  private updateClosestHorizontalSegment(point: Vector3) {
    this.closestSegments.horizontalSegment =
      this.toolProvider.getNearestHorizontalSegmentToPoint(point);
  }

  private updateXYMeasurements(
    XMeasurement: MeasurementModelManager,
    YMeasurement: MeasurementModelManager,
    point: Vector3
  ) {
    updateXYMeasurements(
      XMeasurement,
      YMeasurement,
      point,
      this.closestSegments
    );
  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {
    this.closestSegments = closestSegments;
    if (this.point) {
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    }
  }

  onMouseClick(pointerState: PointerState) {
    this.tryApplyMounting();
  }

  tryApplyMounting() {
    if (isMountingModelReady(this.mountingModel) && this.canPlace.getValue()) {
      const mounting: MountingAttachment = {
        mountingRef: this.mountingModel,
        vertices: this.mountingVertices,
        shape: this.mountingShape,
        position: copyVector2(this.point),
        rotation: this.rotation.getValue(),
        possible: this.mountingModel.materialCode === this.materialCode,
      };
      this.store.dispatch(new AddMounting(mounting));
      const angle = Math.PI / 4;
      const amountOfHelpLines = (Math.PI * 2) / angle / 2;
      for (let i = 0; i < amountOfHelpLines; i++) {
        const alpha = i * angle;
        const dir = { x: Math.cos(alpha), y: Math.sin(alpha) };
        const model: HelpLine = {
          visible: false,
          direction: normalizeVector2(dir),
          offset: 0,
          position: mounting.position,
        };
        this.store.dispatch(new AddToolHelpLine(model));
      }
    }
  }

  onMouseDown(pointerState: PointerState) {}

  onMouseMove(pointerState: PointerState) {
    this.point = pointerState.position;
    let canPlace: boolean;
    if (isMountingModelReady(this.mountingModel)) {
      switch (this.mountingModel.positioning) {
        case MountingPositioningType.free:
          this.canPlace.next(this.canPlaceMountingHoleInPosition(this.point));
          break;
        case MountingPositioningType.edgeCutout:
          canPlace = this.snapMountingToEdge();
          this.canPlace.next(canPlace);
          if (canPlace) {
            this.rotation.next(this.rotateMountingToEdge());
          }
      }
      this.visualizeMounting(this.point);
      this.updateXYMeasurements(
        this.horizontalMeasurement,
        this.verticalMeasurement,
        this.point
      );
    }
  }

  onMouseUp(pointerState: PointerState) {}

  reset() {
    this.onCancel();
    this.activate();
  }

  disposeMeasurements() {
    if (this.verticalMeasurement) {
      this.verticalMeasurement.disposeModel();
      this.verticalMeasurement = null;
    }
    if (this.horizontalMeasurement) {
      this.horizontalMeasurement.disposeModel();
      this.horizontalMeasurement = null;
    }
  }

  visualizeMounting(position: Vector3) {
    if (this.currentMesh && position) {
      this.currentMesh.position = new B_Vector3(
        position.x,
        position.y,
        position.z
      );
      this.currentMesh.rotation.z = this.rotation.getValue();
      this.currentMesh.isVisible = true;
      this.currentMesh.renderingGroupId = 3;
      // this.currentMesh.onBeforeRenderObservable.add((ed) => {
      //   this.scene.getEngine().setDepthBuffer(false);
      //   this.scene.getEngine().setColorWrite(true);
      //   this.scene.getEngine().setStencilFunction(BABYLON.Engine.ALWAYS);
      //   this.scene.getEngine().setStencilFunctionReference(1);
      //   this.scene.getEngine().setStencilMask(0xFF);
      // });
      // this.currentMesh.onAfterRenderObservable.add((ed) => {
      //   this.scene.getEngine().setDepthBuffer(true);
      // });
    }
    this.store.dispatch(new RequestRender());
  }

  snapMountingToEdge() {
    const horizontalDist = this.closestSegments.horizontalSegment
      ? sqrDistanceVector2(
          this.point,
          this.closestSegments.horizontalSegment.projectedPoint
        )
      : Number.MAX_VALUE;
    const verticalDist = this.closestSegments.verticalSegment
      ? sqrDistanceVector2(
          this.point,
          this.closestSegments.verticalSegment.projectedPoint
        )
      : Number.MAX_VALUE;
    if (
      horizontalDist !== Number.MAX_VALUE &&
      verticalDist !== Number.MAX_VALUE
    ) {
      this.point =
        verticalDist < horizontalDist
          ? {
              x: this.closestSegments.verticalSegment.projectedPoint.x,
              y: this.closestSegments.verticalSegment.projectedPoint.y,
              z: this.point.z,
            }
          : {
              x: this.closestSegments.horizontalSegment.projectedPoint.x,
              y: this.closestSegments.horizontalSegment.projectedPoint.y,
              z: this.point.z,
            };
      return true;
    } else {
      return false;
    }
  }

  canPlaceMountingHoleInPosition(position: Vector2): boolean {
    // const holeS = (this.mountingModel.width / 2000);
    const minDist = this.material.materialThickness / 500;
    const sqrDist = minDist * minDist;

    const globalShape = movePolyline(this.shape, position);
    if (this.closestSegments) {
      if (this.closestSegments.verticalSegment) {
        const seg = getShortestPathBetweenTwoPolylines(globalShape, [
          this.closestSegments.verticalSegment.segment,
        ]);
        const sqrDistFromVerticalSegment = sqrDistanceVector2(
          seg.begin,
          seg.end
        );
        if (sqrDistFromVerticalSegment < sqrDist) {
          return false;
        }
      }
      if (this.closestSegments.horizontalSegment) {
        const seg = getShortestPathBetweenTwoPolylines(globalShape, [
          this.closestSegments.horizontalSegment.segment,
        ]);
        const sqrDistFromVerticalSegment = sqrDistanceVector2(
          seg.begin,
          seg.end
        );
        if (sqrDistFromVerticalSegment < sqrDist) {
          return false;
        }
      }
    }
    if (this.shapeWithHoles) {
      if (this.shapeWithHoles.conture) {
        if (!IsPointInsideOfShape(position, this.shapeWithHoles.conture)) {
          return false;
        }
      }
      for (const c of this.shapeWithHoles.holes) {
        if (IsPointInsideOfShape(position, c)) {
          return false;
        }
      }
    }
    if (this.mountingHoles && this.mountingHoles.length > 0) {
      for (const p of this.mountingHoles) {
        const seg = getShortestPathBetweenTwoPolylines(
          globalShape,
          movePolyline(
            scaleMounting(p.mountingRef, p.shape, p.rotation),
            p.position
          )
        );
        const sqrDistFromMounting = sqrDistanceVector2(seg.begin, seg.end);
        if (sqrDistFromMounting < sqrDist) {
          return false;
        }
      }
    }
    if (this.perforationArea) {
      for (let i = 0; i < this.perforationArea.length; i++) {
        const shape = this.perforationArea[i];
        if (shape.conture) {
          for (const s of shape.conture) {
            const pp = projectPointOnSegment(position, s);
            if (pp) {
              const dist = distanceVector2(pp, position);
              if (dist <= minDist) {
                return false;
              }
            }
          }
        }
      }
    }
    return true;
  }

  onCancel() {
    if (this.contoursAreaSub && !this.contoursAreaSub.closed) {
      this.contoursAreaSub.unsubscribe();
      this.contoursAreaSub = null;
    }
    if (this.mountingHolesSub && !this.mountingHolesSub.closed) {
      this.mountingHolesSub.unsubscribe();
      this.mountingHolesSub = null;
    }
    if (this.mountingModelSub && !this.mountingModelSub.closed) {
      this.mountingModelSub.unsubscribe();
      this.mountingHolesSub = null;
    }
    if (this.perforationAreaSub && !this.perforationAreaSub.closed) {
      this.perforationAreaSub.unsubscribe();
      this.mountingHolesSub = null;
    }
    if (this.canPlaceSub) {
      this.canPlaceSub.unsubscribe();
      this.canPlaceSub = null;
    }
    if (this.currentMesh) {
      this.currentMesh.dispose();
      this.currentMesh = null;
    }
    this.disposeMeasurements();
    this.store.dispatch(new RemoveAllToolHelpLines());
  }

  onConfirm() {
    this.tryApplyMounting();
  }

  isDirty(): boolean {
    return this._dirty;
  }

  private rotateMountingToEdge(): number {
    const horizontalDist = this.closestSegments.horizontalSegment
      ? sqrDistanceVector2(
          this.point,
          this.closestSegments.horizontalSegment.projectedPoint
        )
      : Number.MAX_VALUE;
    const verticalDist = this.closestSegments.verticalSegment
      ? sqrDistanceVector2(
          this.point,
          this.closestSegments.verticalSegment.projectedPoint
        )
      : Number.MAX_VALUE;
    if (
      horizontalDist !== Number.MAX_VALUE &&
      verticalDist !== Number.MAX_VALUE
    ) {
      const edge =
        verticalDist < horizontalDist
          ? this.closestSegments.verticalSegment
          : this.closestSegments.horizontalSegment;
      const dir = normalizeVector2(
        subVectors2(edge.segment.begin, edge.segment.end)
      );
      const contourAaBb = aabbOfPolyline(this.shapeWithHoles.conture);
      const mountingAaBb = aabbOfPolyline(this.mountingShape);
      mountingAaBb.max = addVectors2(mountingAaBb.max, edge.projectedPoint);
      mountingAaBb.min = addVectors2(mountingAaBb.min, edge.projectedPoint);
      const angle = -Math.PI / 2 + Math.atan2(dir.y, dir.x);
      return angle;
    } else {
      return 0;
    }
  }

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }
}
