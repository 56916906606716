import {initialSnappingModel, SnappingModel} from '../../model/snapping.model';
import {MevacoPageActions} from '../actions';
import {SET_GRID_OFFSET, SET_GRID_ZOOM_OPTION} from '../actions/grid.actions';

export function gridReducer(state: SnappingModel = initialSnappingModel, action: MevacoPageActions): SnappingModel {
  switch (action.type) {
    case SET_GRID_ZOOM_OPTION:
      return {
        ...state,
        snapX: action.payload.snapX,
        snapY: action.payload.snapY,
        majorLineFrequency: action.payload.majorLineFrequency,
      };
    case SET_GRID_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };
    case "SET_GRID_SIZE":
      return {
        ...state,
        size: action.payload,
      };
  }
  return state;
}
