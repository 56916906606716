import { PointerCollider, PointPointerCollider } from "webcad/collision";
import { Pointer } from "webcad/collision/pointer";
import { PointerIntersection } from "webcad/collision/pointer-intersection";
import { rayToVector3Distance } from "webcad/math";
import { PointNode } from "../../model";
import { UnderPointerType } from "../../model/pointer-state.model";
import { ColliderPriority } from "./collider-priorities";
import { CursorType } from "./cursor.type";
import { MevacoCollider } from "./mevaco.collider";

export class NodeCollider
  extends PointPointerCollider
  implements MevacoCollider
{
  priority = ColliderPriority.NODE;
  objectType: UnderPointerType;
  public cursorType: CursorType;

  public get object(): any {
    return this.node;
  }

  constructor(public node: PointNode) {
    super(node.position, "Node");
    this.objectType = UnderPointerType.NODE;
    this.cursorType = CursorType.point;
  }

  getIntersection(pointer: Pointer, epsilon: number): PointerIntersection {
    const dist = rayToVector3Distance(
      this.position,
      pointer.ray.origin,
      pointer.ray.direction
    );
    return dist > epsilon
      ? null
      : {
          collider: this,
          position: { ...this.position },
        };
  }

  compare(other: PointerCollider): boolean {
    if (!super.compare(other)) {
      return false;
    }
    if (other instanceof NodeCollider) {
      const nc: NodeCollider = other as NodeCollider;
      return this === nc;
    }
    return false;
  }
}
