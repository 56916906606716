import { PointerCollider } from "webcad/collision";
import { Pointer } from "webcad/collision/pointer";
import { PointerIntersection } from "webcad/collision/pointer-intersection";
import { Vector3, sqrDistanceVector2 } from "webcad/math";
import { UnderPointerType } from "../../model/pointer-state.model";
import { ColliderPriority } from "./collider-priorities";
import { CursorType } from "./cursor.type";
import { MevacoCollider, SnappingContext } from "./mevaco.collider";

export class GridCollider implements PointerCollider, MevacoCollider {
  priority = ColliderPriority.GRID;
  cursorType: CursorType = CursorType.point;
  name = "Grid";

  get object(): any {
    return this.snappingModelRef.snappingModel;
  }

  objectType: UnderPointerType = UnderPointerType.GRID;

  constructor(private snappingModelRef: SnappingContext) {}

  compare(other: PointerCollider): boolean {
    return other instanceof GridCollider;
  }

  getIntersection(pointer: Pointer, epsilon: number): PointerIntersection {
    const offset = this.snappingModelRef.snappingModel.offset;
    const snapX = this.snappingModelRef.snappingModel.snapX;
    const snapY = this.snappingModelRef.snappingModel.snapY;
    let x = pointer.onWorkingPlane.x;
    x = Math.round((x - offset.x) / snapX) * snapX + offset.x;
    let y = pointer.onWorkingPlane.y;
    y = Math.round((y - offset.y) / snapY) * snapY + offset.y;
    const position: Vector3 = {
      x: x,
      y: y,
      z: 0,
    };
    if (
      sqrDistanceVector2(position, pointer.onWorkingPlane) <
      epsilon * epsilon
    ) {
      if (Math.abs(x - pointer.onWorkingPlane.x) > epsilon) {
        position.x = pointer.onWorkingPlane.x;
      }
      if (Math.abs(y - pointer.onWorkingPlane.y) > epsilon) {
        position.y = pointer.onWorkingPlane.y;
      }
      // this.snappingModelRef.snappingModel.snapX
      return {
        collider: this,
        position: position,
      };
    }
  }
}
