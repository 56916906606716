import libtess from "libtess";
import * as memoizee from "memoizee";
import {
  getRightPerpendicularVector2,
  multiplyVector2byScalar,
  normalizeVector2,
  subVectors2,
  Vector2,
  Vector3,
} from "webcad/math";
import {
  Aabb2,
  expandAabbInPlace,
  getEmptyAaabb,
  getPointsFromSegment,
  getShortestPathBetweenLineSegments,
  Segment,
  segmentLength,
  SegmentType,
  setEmptyAaabb,
} from "webcad/models";
import { BendingLine } from "../model/bending-line.model";
import { ShapeWithHoles } from "../model/shape-with-holes";
import { BendingNode } from "../model/view-model/bending-node.viewModel";
import { appendRegion, Region } from "../model/view-model/region.viewModel";
import {Matrix, Vector3 as B_Vector3} from "webcad/babylonjs/core";
function findEdges(tris: number[]) {
  const edgesMap = {};
  const edges: number[] = [];

  for (let tr = 0; tr * 3 < tris.length; tr++) {
    for (let e = 0; e < 3; e++) {
      const i1 = tris[tr * 3 + e];
      const i2 = tris[tr * 3 + ((e + 1) % 3)];
      const edge = edgesMap[i1 + "_" + i2];
      if (edge === undefined) {
        edgesMap[i2 + "_" + i1] = true;
      } else {
        edgesMap[i2 + "_" + i1] = false;
        edgesMap[i1 + "_" + i2] = false;
      }
    }
  }

  for (let tr = 0; tr * 3 < tris.length; tr++) {
    for (let b = 0; b < 3; b++) {
      const i1 = tris[tr * 3 + b];
      const i2 = tris[tr * 3 + ((b + 1) % 3)];
      if (edgesMap[i2 + "_" + i1] === true) {
        edges.push(i1, i2);
      }
    }
  }
  return edges;
}

function buildStructure(
  regions: Region[],
  bendingLines: BendingLine[],
  shapeContours: number[][]
): BendingNode {
  const toBand = {};
  const fromBand = {};
  const elimination: boolean[] = new Array(regions.length).fill(true);
  for (let r = 0; r < regions.length; r++) {
    const region = regions[r];
    for (let b = 0; b < bendingLines.length; b++) {
      const bendingLine = bendingLines[b];
      const relation = getBandRelation(bendingLine, region);
      if (relation === BandRelationType.left) {
        toBand[r] = toBand[r] ? [...toBand[r], b] : [b];
      } else if (relation === BandRelationType.right) {
        fromBand[b] = fromBand[b] ? [...fromBand[b], r] : [r];
        elimination[r] = false;
      }
    }
  }

  function getBendingRegion(bendingLine: BendingLine): Region {
    const bendTess = new Tesselator(
      libtess.windingRule.GLU_TESS_WINDING_ABS_GEQ_TWO
    );
    bendTess.addContours(shapeContours);
    bendTess.addBendingLineContour(bendingLine, true);
    const bendBoundary: number[][] = bendTess.boundary();

    let bendTris: number[] = [];
    const steps = Math.abs(
      Math.round(16 * Math.abs(bendingLine.angle / Math.PI))
    );
    for (let j = 0; j < steps; j++) {
      const segTess = new Tesselator(
        libtess.windingRule.GLU_TESS_WINDING_ABS_GEQ_TWO
      );
      segTess.addContours(bendBoundary);
      segTess.addBendingLineContour(
        bendingLine,
        true,
        undefined,
        j / steps,
        1 / steps
      );
      bendTris = bendTris.concat(segTess.triangulate());
    }
    const aabb = getEmptyAaabb();
    const indexedTris = createIndexedTriangles(bendTris, aabb);
    const tris = indexedTris.triangles;
    const edges = findEdges(tris);

    return {
      edges: edges,
      triangles: tris,
      vertices: indexedTris.vertices,
      aabb: aabb,
    };
  }

  function buildNode(bend: number): BendingNode {
    if (fromBand[bend]) {
      const fb = fromBand[bend];
      fromBand[bend] = null;

      const node: BendingNode = {
        children: [],
        flatRegion: null,
        bendingRegion: getBendingRegion(bendingLines[bend]),
        // bendingRegion: null,
        bendingLine: bendingLines[bend],
        toLocal: Matrix.Identity(),
        world: Matrix.Identity(),
        flatWorldR: Matrix.Identity(),
      };

      for (let i = 0; i < fb.length; i++) {
        const region = fb[i];
        if (node.flatRegion === null) {
          node.flatRegion = regions[region];
        } else {
          appendRegion(node.flatRegion, regions[region]);
        }
        if (toBand[region]) {
          const tb = toBand[region];
          toBand[region] = null;
          for (let j = 0; j < tb.length; j++) {
            const child = buildNode(tb[j]);
            if (child) {
              node.children.push(child);
            }
          }
        }
      }

      return node;
    }
    return null;
  }

  const root: BendingNode = {
    bendingLine: null,
    flatRegion: null,
    bendingRegion: null,
    children: [],
    toLocal: Matrix.Identity(),
    world: Matrix.Identity(),
    flatWorldR: Matrix.Identity(),
  };

  for (let i = 0; i < elimination.length; i++) {
    if (elimination[i]) {
      if (root.flatRegion === null) {
        root.flatRegion = regions[i];
      } else {
        appendRegion(root.flatRegion, regions[i]);
      }
      if (toBand[i]) {
        const tb = toBand[i];
        toBand[i] = null;
        for (let j = 0; j < tb.length; j++) {
          const child = buildNode(tb[j]);
          if (child) {
            root.children.push(child);
          }
        }
      }
    }
  }
  return root;
}

function calculateMatrices(
  node: BendingNode,
  thickness: number,
  parentFlatWorldR: Matrix = Matrix.Identity(),
  parentBend: Matrix = Matrix.Identity()
) {
  node.flatWorldR = parentFlatWorldR.clone();
  let bendWorld: Matrix = parentBend;
  if (!!node.bendingLine) {
    const d = normalizeVector2(
      subVectors2(node.bendingLine.end, node.bendingLine.begin)
    );
    const yAxis: B_Vector3 = new B_Vector3(d.x, d.y, 0);
    const xAxis: B_Vector3 = new B_Vector3(d.y, -d.x, 0);
    const zAxis: B_Vector3 = new B_Vector3(0, 0, 1);
    const bend: Matrix = new Matrix();
    Matrix.FromXYZAxesToRef(xAxis, yAxis, zAxis, bend);
    bend.setTranslation(
      new B_Vector3(node.bendingLine.begin.x, node.bendingLine.begin.y, 0)
    );
    const transL: Matrix = Matrix.Translation(
      -node.bendingLine.bentParams.bendAllowance / 2,
      0,
      0
    );
    const transR: Matrix = Matrix.Translation(
      node.bendingLine.bentParams.bendAllowance / 2,
      0,
      0
    );
    node.flatWorldR = transR.multiply(bend);
    const flatWorldL = transL.multiply(bend);
    node.toLocal = Matrix.Invert(node.flatWorldR);
    // const parentInv = parentFlat.invert();
    const local = flatWorldL
      .clone()
      .multiply(parentFlatWorldR.clone().invert());

    let offsetZ = node.bendingLine.bentParams.radius + thickness / 2;
    if (node.bendingLine.angle < 0) {
      offsetZ = -offsetZ;
    }
    const rot = Matrix.Translation(0, 0, -offsetZ)
      .multiply(Matrix.RotationY(-node.bendingLine.angle))
      .multiply(Matrix.Translation(0, 0, offsetZ));
    //    const rot = BABYLON.Matrix.Translation(node.bendingLine.bentParams.bendAllowance,0,0);

    bendWorld = rot.multiply(local.multiply(parentBend));
    //    bendWorld = local.multiply(parentBend);
    node.toLocal.multiplyToRef(bendWorld, node.world);
  }

  node.world = node.world.multiply(
    Matrix.Translation(0, 0, thickness / 2)
  );

  if (node.children) {
    for (let i = 0; i < node.children.length; i++) {
      const child = node.children[i];
      calculateMatrices(child, thickness, node.flatWorldR, bendWorld);
    }
  }
}

const max = Number.MAX_VALUE;
const min = -Number.MAX_VALUE;
export const allContainingBendingNode: BendingNode = {
  bendingLine: null,
  bendingRegion: null,
  children: [],
  flatRegion: {
    vertices: [
      { x: max, y: min, z: 0 },
      { x: min, y: max, z: 0 },
      { x: min, y: min, z: 0 },
      { x: max, y: max, z: 0 },
    ],
    triangles: [0, 1, 2, 1, 0, 3],
    edges: [1, 2, 2, 0, 0, 3, 3, 1],
    aabb: {
      min: { x: min, y: min },
      max: { x: max, y: max },
    },
  },
  toLocal: Matrix.Identity(),
  world: Matrix.Identity(),
  flatWorldR: Matrix.Identity(),
};

export const buildBendingStructure = memoizee(_buildBendingStructure, {
  max: 4,
});

function _buildBendingStructure(
  shapeWithHoles: ShapeWithHoles,
  bendingLines: BendingLine[],
  flattened: boolean,
  thickness: number
): BendingNode {
  const contour = shapeWithHoles && shapeWithHoles.conture;
  const holes = shapeWithHoles && shapeWithHoles.holes;

  if (!!contour && contour.length > 0) {
    const cutOutTess = new Tesselator(
      libtess.windingRule.GLU_TESS_WINDING_POSITIVE
    );
    const shapeContours: number[][] = [];
    cutOutTess.addPolyline(contour, shapeContours);
    if (holes) {
      cutOutTess.addPolylines(holes, shapeContours);
    }
    if (!flattened && !!bendingLines && bendingLines.length > 0) {
      cutOutTess.addBendingLinesContours(bendingLines, false);
      const tris = cutOutTess.triangulate();
      const regions = splitToRegions(tris);

      const root = buildStructure(regions, bendingLines, shapeContours);
      calculateMatrices(root, thickness);
      return root;
    } else {
      const tris = cutOutTess.triangulate();

      const aabb = getEmptyAaabb();
      const indexedTris = createIndexedTriangles(tris, aabb);
      const root = {
        bendingLine: null,
        bendingRegion: null,
        children: [],
        flatRegion: {
          vertices: indexedTris.vertices,
          triangles: indexedTris.triangles,
          edges: findEdges(indexedTris.triangles),
          aabb: aabb,
        },
        toLocal: Matrix.Identity(),
        world: Matrix.Identity(),
        flatWorldR: Matrix.Identity(),
      };
      calculateMatrices(root, thickness);
      return root;
    }
  } else {
    return null;
  }
}
enum BandRelationType {
  left,
  right,
  none,
}

function getBandRelation(
  bendingLine: BendingLine,
  region: Region
): BandRelationType {
  const dir = normalizeVector2(subVectors2(bendingLine.end, bendingLine.begin));
  const bendAllowanceDir = multiplyVector2byScalar(
    getRightPerpendicularVector2(dir),
    bendingLine.bentParams.bendAllowance / 2
  );
  const right1: Vector2 = {
    x: bendingLine.begin.x + bendAllowanceDir.x,
    y: bendingLine.begin.y + bendAllowanceDir.y,
  };
  const right2: Vector2 = {
    x: bendingLine.end.x + bendAllowanceDir.x,
    y: bendingLine.end.y + bendAllowanceDir.y,
  };
  const left1: Vector2 = {
    x: bendingLine.begin.x - bendAllowanceDir.x,
    y: bendingLine.begin.y - bendAllowanceDir.y,
  };
  const left2: Vector2 = {
    x: bendingLine.end.x - bendAllowanceDir.x,
    y: bendingLine.end.y - bendAllowanceDir.y,
  };
  const rightSegment: Segment = {
    begin: right1,
    end: right2,
    type: SegmentType.line,
  };
  const leftSegment: Segment = {
    begin: left1,
    end: left2,
    type: SegmentType.line,
  };

  for (let i = 0; i < region.edges.length; i += 2) {
    const edgeSegment: Segment = {
      begin: region.vertices[region.edges[i]],
      end: region.vertices[region.edges[i + 1]],
      type: SegmentType.line,
    };
    const rPath = getShortestPathBetweenLineSegments(edgeSegment, rightSegment);
    if (segmentLength(rPath) <= 0.00001) {
      return BandRelationType.right;
    }
    const lPath = getShortestPathBetweenLineSegments(edgeSegment, leftSegment);
    if (segmentLength(lPath) <= 0.00001) {
      return BandRelationType.left;
    }
  }
  return BandRelationType.none;
}

function createIndexedTriangles(
  triangles: number[],
  aabb?: Aabb2
): { triangles: number[]; vertices: Vector3[] } {
  const result: { triangles: number[]; vertices: Vector3[] } = {
    triangles: [],
    vertices: [],
  };

  if (aabb) {
    setEmptyAaabb(aabb);
  }

  for (let i = 0; i < triangles.length; i += 3) {
    const vertex: Vector3 = {
      x: triangles[i],
      y: triangles[i + 1],
      z: triangles[i + 2],
    };
    const newIndex = result.vertices.findIndex(
      (v) =>
        Math.abs(v.x - vertex.x) < 0.000001 &&
        Math.abs(v.y - vertex.y) < 0.000001
    );
    if (newIndex > -1) {
      result.triangles.push(newIndex);
    } else {
      result.triangles.push(result.vertices.length);
      result.vertices.push(vertex);
      if (aabb) {
        expandAabbInPlace(aabb, { x: vertex.x, y: vertex.y });
      }
    }
  }
  return result;
}

function trianglesToPath(triangles: number[]): string {
  let path = "";
  for (let i = 0; i < triangles.length; i += 9) {
    path += `M ${triangles[i + 0]} ${triangles[i + 1]} `;
    path += `L ${triangles[i + 3]} ${triangles[i + 4]} `;
    path += `L ${triangles[i + 6]} ${triangles[i + 7]} `;
    path += `L ${triangles[i + 0]} ${triangles[i + 1]} `;
  }
  return path;
}

export function splitToRegions(triangles: number[]): Region[] {
  const result: Region[] = [];
  const indexedTriangles = createIndexedTriangles(triangles);
  const path = trianglesToPath(triangles);
  const edgesMap = {};
  const triangleBoarders: number[][] = new Array(triangles.length / 3);

  for (let tr = 0; tr * 3 < indexedTriangles.triangles.length; tr++) {
    triangleBoarders[tr] = [undefined, undefined, undefined];
    for (let e = 0; e < 3; e++) {
      const i1 = indexedTriangles.triangles[tr * 3 + e];
      const i2 = indexedTriangles.triangles[tr * 3 + ((e + 1) % 3)];
      const edge = edgesMap[i1 + "_" + i2];
      if (edge === undefined) {
        edgesMap[i2 + "_" + i1] = { tr: tr, e: e };
      } else {
        triangleBoarders[tr][e] = edge.tr;

        triangleBoarders[edge.tr][edge.e] = tr;
      }
    }
  }

  function collectRegion(tr: number, region: Region, indexMapping: number[]) {
    const boarders = triangleBoarders[tr];
    if (boarders !== undefined) {
      triangleBoarders[tr] = undefined;
      for (let v = 0; v < 3; v++) {
        const i = indexedTriangles.triangles[tr * 3 + v];
        let ni = indexMapping[i];
        if (ni === undefined) {
          indexMapping[i] = ni = region.vertices.length;
          const v = indexedTriangles.vertices[i];
          region.vertices.push(v);
          expandAabbInPlace(region.aabb, { x: v.x, y: v.y });
        }
        region.triangles.push(ni);
      }
      for (let b = 0; b < 3; b++) {
        const boarder = boarders[b];
        if (boarder !== undefined) {
          collectRegion(boarder, region, indexMapping);
        } else {
          const i1 = indexMapping[indexedTriangles.triangles[tr * 3 + b]];
          const i2 =
            indexMapping[indexedTriangles.triangles[tr * 3 + ((b + 1) % 3)]];
          region.edges.push(i1, i2);
        }
      }
    }
  }

  let tr = 0;
  while (tr < triangleBoarders.length) {
    if (triangleBoarders[tr] !== undefined) {
      const region: Region = {
        edges: [],
        vertices: [],
        triangles: [],
        aabb: getEmptyAaabb(),
      };
      const indexMapping: number[] = [];
      collectRegion(tr, region, indexMapping);
      result.push(region);
    }
    tr++;
  }

  return result;
  /*return [{
    edges:[],
    triangles:indexedTriangles.triangles,
    vertices:indexedTriangles.vertices
  }]*/
}

export class Tesselator {
  private output: number[][] = [];
  private tessy: libtess.GluTesselator;

  constructor(windingRule: number) {
    this.tessy = new libtess.GluTesselator();
    this.tessy.gluTessCallback(
      libtess.gluEnum.GLU_TESS_BEGIN_DATA,
      (data: number[], polyVertArray: number[][]) => {
        polyVertArray.push([]);
      }
    );

    this.tessy.gluTessCallback(
      libtess.gluEnum.GLU_TESS_VERTEX_DATA,
      (data: number[], polyVertArray: number[][]) => {
        polyVertArray[polyVertArray.length - 1].push(data[0], data[1], data[2]);
      }
    );
    this.tessy.gluTessCallback(
      libtess.gluEnum.GLU_TESS_COMBINE,
      (coords: number[]) => {
        return [coords[0], coords[1], coords[2]];
      }
    );

    this.tessy.gluTessProperty(
      libtess.gluEnum.GLU_TESS_WINDING_RULE,
      windingRule
    );
    this.tessy.gluTessNormal(0, 0, 1);
    this.tessy.gluTessBeginPolygon(this.output);
  }

  setWindingRule(windingRule: number) {
    this.tessy.gluTessProperty(
      libtess.gluEnum.GLU_TESS_WINDING_RULE,
      windingRule
    );
  }

  addContours(contours: number[][]) {
    for (let i = 0; i < contours.length; i++) {
      const contour = contours[i];
      this.tessy.gluTessBeginContour();
      for (let j = 0; j < contour.length; j += 3) {
        const coords = [contour[j], contour[j + 1], contour[j + 2]];
        this.tessy.gluTessVertex(coords, coords);
      }
      this.tessy.gluTessEndContour();
    }
  }

  addContour(contour: Vector2[], contours: number[][] = null) {
    let record: number[] = null;
    if (!!contours) {
      record = [];
      contours.push(record);
    }
    this.tessy.gluTessBeginContour();
    let x: number;
    let y: number;
    let vec: Vector2;
    for (let j = 0; j < contour.length; j++) {
      vec = contour[j];
      x = Math.round(vec.x * 1000000) / 1000000;
      y = Math.round(vec.y * 1000000) / 1000000;

      const coords = [x, y, 0];
      this.tessy.gluTessVertex(coords, coords);
      if (!!record) {
        record.push(x, y, 0);
      }
    }
    this.tessy.gluTessEndContour();
  }

  addPolyline(polyline: Segment[], record: number[][] = null) {
    const contour: Vector2[] = [];
    for (let i = 0; i < polyline.length; i++) {
      const segment = polyline[i];
      const points = getPointsFromSegment(segment);
      for (let j = 0; j < points.length; j++) {
        const point = points[j];
        contour.push({ x: point.x, y: point.y });
      }
    }

    this.addContour(contour, record);
  }

  addBendingLineContour(
    bendingLine: BendingLine,
    ccw: boolean,
    record: number[][] = null,
    offset = 0,
    scale = 1
  ) {
    const t: Vector2 = subVectors2(bendingLine.end, bendingLine.begin);
    const dir = normalizeVector2(t);
    const e = multiplyVector2byScalar(dir, 0.0001);
    const bendAllowanceDir = multiplyVector2byScalar(
      getRightPerpendicularVector2(dir),
      bendingLine.bentParams.bendAllowance / 2
    );
    const s: Vector2 = {
      x:
        bendingLine.begin.x -
        bendAllowanceDir.x +
        offset * bendAllowanceDir.x * 2,
      y:
        bendingLine.begin.y -
        bendAllowanceDir.y +
        offset * bendAllowanceDir.y * 2,
    };
    const r: Vector2 = {
      x: bendAllowanceDir.x * 2 * scale,
      y: bendAllowanceDir.y * 2 * scale,
    };
    const contour: Vector2[] = ccw
      ? [
          { x: s.x + r.x - e.x, y: s.y + r.y - e.y },
          { x: s.x + r.x + t.x + e.x, y: s.y + r.y + t.y + e.y },
          { x: s.x + t.x + e.x, y: s.y + t.y + e.y },
          { x: s.x - e.x, y: s.y - e.y },
        ]
      : [
          { x: s.x - e.x, y: s.y - e.y },
          { x: s.x + t.x + e.x, y: s.y + t.y + e.y },
          { x: s.x + r.x + t.x + e.x, y: s.y + r.y + t.y + e.y },
          { x: s.x + r.x - e.x, y: s.y + r.y - e.y },
        ];
    this.addContour(contour, record);
  }

  addBendingLinesContours(
    bendingLines: BendingLine[],
    ccw: boolean,
    record: number[][] = null
  ) {
    for (let i = 0; i < bendingLines.length; i++) {
      const bendingLine = bendingLines[i];
      this.addBendingLineContour(bendingLine, ccw, record);
    }
  }

  triangulate(): number[] {
    this.tessy.gluTessEndPolygon();
    return this.output[0];
  }

  boundary(): number[][] {
    this.tessy.gluTessProperty(libtess.gluEnum.GLU_TESS_BOUNDARY_ONLY, true);
    this.tessy.gluTessEndPolygon();
    return this.output;
  }

  addPolylines(polylines: Segment[][], record: number[][] = null) {
    for (let i = 0; i < polylines.length; i++) {
      const polyline = polylines[i];
      this.addPolyline(polyline, record);
    }
  }
}
