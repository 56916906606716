import {Action} from '@ngrx/store';
import {
  Mounting,
  MountingAndShape,
  MountingAttachment,
  MountingPositioningType,
  MountingType
} from '../../model/mounting.model';
import {Vector2} from 'webcad/math';
import {Segment} from "webcad/models";


export const LOAD_MOUNTINGS = 'LOAD MOUNTINGS';

export class LoadMountings implements Action {
  readonly type: string = LOAD_MOUNTINGS;

  constructor(public payload: any = null) {

  }
}

export const LOAD_MOUNTINGS_SUCCESS = 'LOAD MOUNTINGS SUCCESS';

export interface LoadMountingSuccessObject {
  mountings: Mounting[];
  verticesMap: Map<string, Vector2[]>;
  shapeMap: Map<string, Segment[]>;
}

export class LoadMountingsSuccess implements Action {
  readonly type: string = LOAD_MOUNTINGS_SUCCESS;

  constructor(public payload: LoadMountingSuccessObject) {

  }
}

export const SET_MOUNTING_FORM = 'SET FORM';

export class SetMountingForm implements Action {
  readonly type: string = SET_MOUNTING_FORM;

  constructor(public payload: string) {

  }
}

export const SET_MOUNTING_POSITIONING = 'SET MOUNTING POSITIONING';

export class SetMountingPositioning implements Action {
  readonly type: string = SET_MOUNTING_POSITIONING;

  constructor(public payload: MountingPositioningType) {

  }
}

export const SET_MOUNTING_WIDTH = 'SET MOUNTING WIDTH';

export class SetMountingWith implements Action {
  readonly type: string = SET_MOUNTING_WIDTH;

  constructor(public payload: number) {

  }
}

export const SET_MOUNTING_HEIGHT = 'SET MOUNTING HEIGHT';

export class SetMountingHeight implements Action {
  readonly type: string = SET_MOUNTING_HEIGHT;

  constructor(public payload: number) {

  }
}

export const SET_MOUNTING_SIZE = 'SET MOUNTING SIZE';

export interface MountingSizePayload {
  width: number;
  length: number;
}

export class SetMountingSize implements Action {
  readonly type: string = SET_MOUNTING_SIZE;

  constructor(public payload: MountingSizePayload) {

  }
}

export const SET_MOUNTING = 'SET MOUNTING';

export class SetMounting implements Action {
  readonly type: string = SET_MOUNTING;

  constructor(public payload: Mounting) {

  }
}

export const SET_MOUNTING_SUCCESS = 'SET MOUNTING SUCCESS';

export class SetMountingSuccess implements Action {
  readonly type: string = SET_MOUNTING_SUCCESS;

  constructor(public payload: MountingAndShape) {

  }
}

export const ADD_MOUNTING = 'ADD MOUNTING';

export class AddMounting implements Action {
  readonly type: string = ADD_MOUNTING;

  constructor(public payload: MountingAttachment) {
  }

}

export const SELECT_MOUNTING = 'SELECT MOUNTING';

export class SelectMounting implements Action {
  readonly type: string = SELECT_MOUNTING;

  constructor(public payload: MountingAttachment) {

  }
}

export const SET_MOUNTINGS_SELECTION = 'SET MOUNTINGS SELECTION';

export class SetMountingsSelection implements Action {
  readonly type: string = SET_MOUNTINGS_SELECTION;

  constructor(public payload: MountingAttachment[]) {

  }
}

export const ADD_MOUNTING_TO_SELECTION = 'ADD MOUNTING TO SELECTION';

export class AddMountingToSelection implements Action {
  readonly type: string = ADD_MOUNTING_TO_SELECTION;

  constructor(public payload: MountingAttachment) {

  }
}

export const REMOVE_MOUNTING_FROM_SELECTION = 'REMOVE MOUNTING FROM SELECTION';

export class RemoveMountinFromSelection implements Action {
  readonly type: string = REMOVE_MOUNTING_FROM_SELECTION;

  constructor(public payload: MountingAttachment) {

  }
}

export const MOVE_MOUNTING_BY_VECTOR = 'MOVE MOUNTING BY VECTOR';

export interface MountingMoveParams {
  mountings: MountingAttachment[];
  vector: Vector2;
}

export class MoveMountingByVector implements Action {
  readonly type: string = MOVE_MOUNTING_BY_VECTOR;

  constructor(public payload: MountingMoveParams) {

  }
}

export const REMOVE_MOUNTING = 'REMOVE MOUNTING';

export class RemoveMounting implements Action {
  readonly type: string = REMOVE_MOUNTING;

  constructor(public payload: MountingAttachment) {

  }
}

export const REMOVE_MOUNTINGS = 'REMOVE MOUNTINGS';

export class RemoveMountings implements Action {
  readonly type: string = REMOVE_MOUNTINGS;

  constructor(public payload: MountingAttachment[]) {

  }
}

export const ADD_MOUNTINGS = 'ADD MOUNTINGS';

export class AddMountings implements Action {
  readonly type: string = ADD_MOUNTINGS;

  constructor(public payload: MountingAttachment[]) {
  }

}

export const CHANGE_PERFORATION_OFFSET = 'CHANGE_PERFORATION_OFFSET';

export class SetPerforationOffset implements Action {
  readonly type: string = CHANGE_PERFORATION_OFFSET;

  constructor(public payload: Vector2) {
  }

}

export type MountingsActions = LoadMountings
  | LoadMountingsSuccess
  | SetMountingForm
  | SetMountingHeight
  | SetMountingWith
  | SetMountingPositioning
  | SetMountingSize
  | SetMounting
  | AddMounting
  | SelectMounting
  | MoveMountingByVector
  | RemoveMountings
  | AddMountings
  | RemoveMountings
  | SetMountingsSelection;
