import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import {
  addVectors2,
  crossVector2,
  getRightPerpendicularVector2,
  multiplyVector2byScalar,
  normalizeVector2,
  subVectors2,
  Vector2,
} from "webcad/math";
import { Segment, SegmentType } from "webcad/models";
import { BendingLine } from "../model/bending-line.model";
import { MevacoPage } from "../model/mevaco-page.model";
import { Plate } from "../model/plate.model";
import { Step } from "../model/product-configuration/product-configuration.model";
import { foreachSegment, ShapeWithHoles } from "../model/shape-with-holes";
import { StepAndPlate } from "../model/view-model/step-plate.viewModel";
import { getStepAndPlate } from "../store/reducers";

@Injectable()
export class ShapeWithHolesProvider {
  private shapeWithHoles: ShapeWithHoles[];
  private subscription: Subscription;
  private plateState: Plate;
  private step: Step;

  constructor(private store: Store<MevacoPage>) {
    this.subscription = this.store
      .pipe(select(getStepAndPlate))
      .subscribe((value: StepAndPlate) => {
        const step = value.step;
        switch (value.step) {
          case Step.shape:
            this.shapeWithHoles = [value.plate.shapeWithHoles];
            break;
          case Step.pattern:
            this.shapeWithHoles = value.plate.perforationAreas.map(
              (x) => x.shape
            );
            break;
          case Step.attachment:
            this.shapeWithHoles = [value.plate.shapeWithHoles];
            break;
          default:
            this.shapeWithHoles = [value.plate.shapeWithHoles];
            break;
        }
        this.plateState = value.plate;
        this.step = value.step;
      });
  }

  public getShapeWithHoles(): ShapeWithHoles[] {
    return this.shapeWithHoles;
  }

  public foreachSegmentInModel(
    point: Vector2,
    callback: (segment: Segment) => void
  ) {
    if (this.plateState) {
      switch (this.step) {
        case Step.shape:
          foreachSegment(this.plateState.shapeWithHoles, callback);
          foreachBendSegment(this.plateState.bendingLines, point, callback);
          break;
        case Step.pattern:
          foreachSegment(this.plateState.shapeWithHoles, callback);
          for (let i = 0; i < this.plateState.perforationAreas.length; i++) {
            const shape = this.plateState.perforationAreas[i].shape;
            foreachSegment(shape, callback);
          }
          foreachBendSegment(this.plateState.bendingLines, point, callback);
          break;
        case Step.attachment:
          foreachSegment(
            { conture: this.plateState.shapeWithHoles.conture, holes: [] },
            callback
          );
          foreachBendSegment(this.plateState.bendingLines, point, callback);
          break;
      }
    }
  }
}

function foreachBendSegment(
  bendings: BendingLine[],
  point: Vector2,
  callback: (segment: Segment) => void
) {
  for (let i = 0; i < bendings.length; i++) {
    const bending = bendings[i];
    const dir = normalizeVector2(subVectors2(bending.end, bending.begin));
    const dir2 = normalizeVector2(subVectors2(point, bending.begin));
    const ossbOffset = multiplyVector2byScalar(
      getRightPerpendicularVector2(dir),
      bending.bentParams.ossb - bending.bentParams.bendAllowance / 2
    );
    if (crossVector2(dir, dir2) > 0) {
      callback({
        type: SegmentType.line,
        begin: addVectors2(bending.begin, ossbOffset),
        end: addVectors2(bending.end, ossbOffset),
      });
    } else {
      callback({
        type: SegmentType.line,
        begin: subVectors2(bending.begin, ossbOffset),
        end: subVectors2(bending.end, ossbOffset),
      });
    }
  }
}
