import { Webcad } from "webcad/core";
import {
  getPointsFromSegment,
  isPolylineClosed,
  isSegmentCircle,
  Segment,
  SegmentType,
} from "webcad/models";
import { ModelVisualizer } from "webcad/visualizers";
import { Drawing } from "../model/drawing.model";
import { createLineSystemWithDepthOffset } from "./line-system";
import { Node } from "webcad/babylonjs/core";
import {Color4, LinesMesh, Scene, Vector3} from "webcad/babylonjs/core";

export interface ImportVisualizerModel {
  shape: Segment[][];
  depth: number;
}

export const ImportVisualizerPipeline = (
  state: Drawing
): ImportVisualizerModel => {
  return {
    shape: state.importedShape,
    depth: state.plate.depth,
  };
};

export class ImportVisualizer
  implements ModelVisualizer<ImportVisualizerModel>
{
  private model: ImportVisualizerModel;
  private scene: Scene;
  private rootNode: Node;
  private webcad: Webcad;
  private currentShapeOutlineMesh: LinesMesh;

  init(
    rootNode: Node,
    model: ImportVisualizerModel,
    webcad: Webcad
  ): Promise<void> {
    this.rootNode = rootNode;
    this.model = model;
    this.webcad = webcad;
    this.scene = this.rootNode.getScene();
    this.updateVisualization(model);
    return;
  }

  updateVisualization(newModel: ImportVisualizerModel): void {
    if (newModel !== this.model) {
      if (!!this.currentShapeOutlineMesh) {
        this.currentShapeOutlineMesh.dispose();
        this.currentShapeOutlineMesh = null;
      }
      this.visualizeShape(newModel);
    }
    this.model = newModel;
  }

  dispose(): void {
    if (!!this.currentShapeOutlineMesh) {
      this.currentShapeOutlineMesh.dispose();
      this.currentShapeOutlineMesh = null;
    }
  }

  visualizeShape(model: ImportVisualizerModel) {
    if (this.currentShapeOutlineMesh) {
      this.currentShapeOutlineMesh.dispose();
      this.currentShapeOutlineMesh = null;
    }
    const ycolor = new Color4(0, 0, 1, 1);
    const nColor = new Color4(1, 0, 0, 1);
    if (model.shape && this.scene) {
      const outlinePoints: Vector3[][] = [];
      const colors: Color4[][] = [];
      for (const conture of model.shape) {
        const color = this.isClosed(conture) ? ycolor.clone() : nColor.clone();
        const outline: Vector3[] = [];
        const outlineColor: Color4[] = [];
        for (const p of conture) {
          const points = getPointsFromSegment(p, true);
          for (const point of points) {
            outline.push(new Vector3(point.x, point.y, 0));
            outlineColor.push(color);
          }
        }
        // outline.push(outline[0]);
        // outlineColor.push(outlineColor[0]);
        outlinePoints.push(outline);
        colors.push(outlineColor);
      }
      if (model.shape.length > 0 && this.scene) {
        this.currentShapeOutlineMesh = createLineSystemWithDepthOffset(
          "shape Outline",
          {
            lines: outlinePoints,
            colors: colors,
          },
          this.scene,
          -0.0006
        );
        this.currentShapeOutlineMesh.isPickable = false;
      }
    }
  }

  isClosed(segments: Segment[]) {
    if (segments.length === 1) {
      if (segments[0].type === SegmentType.arc) {
        if (isSegmentCircle(segments[0])) {
          return true;
        }
      }
      return false;
    } else {
      return isPolylineClosed(segments);
    }
  }
}
