import {AbstractMesh, Mesh, Scene, VertexBuffer} from "@babylonjs/core";

export function createMeasurementMesh(
  template: Mesh,
  scene: Scene
): Mesh {
  //lazy weights apply
  if (
    !template.geometry.isVerticesDataPresent(VertexBuffer.ColorKind)
  ) {
    const matricesIndices =
      template.geometry.getVerticesData("matricesIndices");
    const matricesWeights =
      template.geometry.getVerticesData("matricesWeights");
    const totalVertices = template.geometry.getTotalVertices();
    if (!!matricesIndices && !!matricesWeights) {
      const weights: Float32Array = new Float32Array(matricesIndices.length);
      for (let v = 0; v < totalVertices; v++) {
        const sw = v * 4;
        for (let w = 3; w >= 0; w--) {
          const index = matricesIndices[sw + w];
          const weight = matricesWeights[sw + w];
          weights[sw + index] = weight;
        }
      }
      template.geometry.setVerticesData(
        VertexBuffer.ColorKind,
        weights
      );
    } else {
      throw new Error("measurement is not a skinned mesh");
    }
  }
  template.metadata = template.metadata ? template.metadata + 1 : 1;
  const mesh = new Mesh(
    "arc" + template.metadata,
    scene,
    null,
    template,
    false,
    false
  );
  mesh.renderingGroupId = 3;
  mesh.occlusionType = AbstractMesh.OCCLUSION_TYPE_NONE;
  return mesh;
}
