<div class="dialog-modal-header casette-modal-header">
  <div class="dialog-modal-header-content">
    <div class="dialog-modal-title-container">
      <h4 class="dialog-modal-title-primary" id="modal-basic-title">
        {{ this.templateUtils.translate("Single Bend Cassete", "designer") }}
      </h4>
      <span class="dialog-modal-title-secondary"
        >{{
          this.templateUtils.translate("EnterDimenstions", "designer")
        }}
        (mm):</span
      >
    </div>
    <div class="dialog-modal-window">
      <span>{{
        this.templateUtils.translate("SketchDoesntChange", "designer")
      }}</span>
    </div>
  </div>

  <button
    type="button"
    class="close btn-close"
    aria-label="Close"
    (click)="dismiss('Cross click'); isModalOpenMethod()"
    [tabIndex]="-1"
  ></button>
</div>

<div>
  <div class="dialog-modal-body casette-modal-body">
    <div class="casette-configuration-section">
      <div class="orientation-cassette-wrapper">
        <div class="orientation-cassette-desc">
          <span class="orientation-cassette-desc-first">
            {{
              this.templateUtils.translate(
                "Schematic representation of the cartridge file",
                "designer"
              )
            }}
          </span>
          <span class="orientation-cassette-desc-second">
            {{
              this.templateUtils.translate(
                isBendingSwitched
                  ? "The stamp entry page is up"
                  : "The stamp entry page is down",
                "designer"
              )
            }}
          </span>
        </div>
        <div class="bending-switch-btns">
          <div
            class="bending-switch-button {{
              isBendingSwitched ? '' : 'bending-switch-button--active'
            }}"
            (click)="switchBending(false)"
          >
            <div class="icon-down"></div>
          </div>
          <div
            class="bending-switch-button {{
              isBendingSwitched ? 'bending-switch-button--active' : ''
            }} ml-10"
            (click)="switchBending(true)"
          >
            <div class="icon-up"></div>
          </div>
        </div>
      </div>

      <div class="bends-toggles-wrapper">
        <app-bends-toggles #bendsToggles></app-bends-toggles>
      </div>
    </div>

    <div
      class="{{
        this.isBendingSwitched
          ? 'single-bend-cassette-inputs reverse'
          : 'single-bend-cassette-inputs'
      }}"
    >
      <debounce-input
        inputType="number"
        #simple_h
        class="simple-h"
        [value]="40"
        [validate]="this.templateUtils.inputValidation(11, 50, 1)"
        [tabIndex]="1"
      ></debounce-input>
      <debounce-input
        inputType="number"
        #simple_l
        class="simple-l"
        [value]="1000"
        [validate]="this.templateUtils.inputValidation(125, 3000, 1)"
        [tabIndex]="1"
      ></debounce-input>
      <debounce-input
        inputType="number"
        #simple_b
        class="simple-b"
        [value]="1000"
        [validate]="this.templateUtils.inputValidation(125, 3000, 1)"
        [tabIndex]="1"
      ></debounce-input>
    </div>
  </div>

  <div class="dialog-modal-footer">
    <span (click)="this.templateUtils.setDefault()" class="dialog-modal-link">
      {{ this.templateUtils.translate("Reset dimensions to default.") }}
    </span>
    <button
      #closeButton
      type="button"
      class="btn btn-primary btn-create"
      [tabIndex]="1"
      [disabled]="false"
      (click)="
        close({
          h: simple_h.value,
          b: simple_b.value,
          l: simple_l.value
        })
      "
    >
      {{ this.templateUtils.translate("Create") }}
    </button>
  </div>
</div>
