import { PointerCollider } from "webcad/collision";
import { Pointer } from "webcad/collision/pointer";
import { PointerIntersection } from "webcad/collision/pointer-intersection";
import { Vector2, Vector3 } from "webcad/math";
import { aabbCenter } from "webcad/models";
import { Perforation, PerforationModel } from "../../model/perforation.model";
import { UnderPointerType } from "../../model/pointer-state.model";
import { StampModel } from "../../model/stamp.model";
import { ColliderPriority } from "./collider-priorities";
import { CursorType } from "./cursor.type";
import {
  DrawingPerforationCollisionTreeProvider,
  HolesCollisionTrees,
  pickElementFomPerforationCollisionTree,
} from "./drawing-perforation-collision-tree-provider.service";
import { MevacoCollider } from "./mevaco.collider";

export interface PerforationIntersection extends PointerIntersection {
  perforation: Perforation;
  stampRotation: number;
  pointerDelta: Vector2;
  stampPolylineIndex: number;
  rotatedStampPolylinePosition: Vector2;
  stamp: StampModel;
}

export class DrawingPerforationCollider
  implements PointerCollider, MevacoCollider
{
  cursorType: CursorType = CursorType.point;
  object: any;
  objectType = UnderPointerType.PERFORATOIN_HOLE;
  priority = ColliderPriority.PERFORATION;
  name = "Perforation";
  private latestCollisionTree: HolesCollisionTrees;

  constructor(
    private perforation: PerforationModel,
    private collisionThreeProvider: DrawingPerforationCollisionTreeProvider
  ) {
    this.object = perforation;
    this.collisionThreeProvider.quadTree.subscribe(
      (qt: HolesCollisionTrees) => {
        this.latestCollisionTree = qt;
      }
    );
  }

  compare(other: PointerCollider): boolean {
    return false;
  }

  getIntersection(pointer: Pointer, epsilon: number): PerforationIntersection {
    const pos: Vector3 = pointer.onWorkingPlane;
    let hole = pickElementFomPerforationCollisionTree(
      this.latestCollisionTree.holes,
      pos
    );
    if (!hole) {
      hole = pickElementFomPerforationCollisionTree(
        this.latestCollisionTree.borders,
        pos
      );
    }
    if (hole) {
      const perforation = hole.perforation;
      const stampPos: Vector2 = aabbCenter(hole.aabb);

      return {
        position: { x: stampPos.x, y: stampPos.y, z: 0 },
        pointerDelta: {
          x: pointer.onWorkingPlane.x - stampPos.x,
          y: pointer.onWorkingPlane.y - stampPos.y,
        },
        collider: this,
        perforation: perforation,
        stampRotation: perforation.rotation,
        stampPolylineIndex: hole.shapeIndex,
        rotatedStampPolylinePosition: hole.segmentPosition,
        stamp: hole.stamp,
      };
    }
    return null;
  }
}
