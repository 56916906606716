import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { Message } from "../../model/error.model";
import { PlateMaterialType } from "../../model/plate.model";
import { Step } from "../../model/product-configuration/product-configuration.model";
import { SnapOptions } from "../../model/snap-options.model";
import { TranslationProvider } from "../../providers/translation.provider";
import { View3dProvider } from "../../providers/view3d.provider";
import { SetHintVisibility } from "../../store/actions/drawing.actions";
import {
  MevacoState,
  getStep,
  showView3dStatusBar,
  showView3dTopBar,
} from "../../store/reducers";

@Component({
  selector: "webcad-host",
  templateUrl: "./webcad-host.component.html",
  styleUrls: ["./webcad-host.component.css"],
})
export class WebcadHostComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("viewParent", { static: true })
  private viewParent: ElementRef;

  @Input()
  public anpOptions: SnapOptions;
  @Input()
  public material: PlateMaterialType;
  public errorObservable: Observable<Message>;
  public errorMessage: string;
  public errorType: string;
  private errorSubscription: Subscription;
  private removeErrorSubscription: Subscription;
  @Input() configuratorOpen = true;
  showView3dTopBar = this.store.pipe(select(showView3dTopBar));
  showView3dStatusBar = this.store.pipe(select(showView3dStatusBar));
  private timer: any;
  private zoomSpeed = 10;
  public step: Observable<Step>;
  public Step = Step;

  constructor(
    private store: Store<MevacoState>,
    private view3dProvider: View3dProvider,
    private translationProvider: TranslationProvider
  ) {}

  ngOnInit() {
    this.step = this.store.pipe(select(getStep));
    this.view3dProvider.appendView(this.viewParent);
    const material = this.material;
    this.view3dProvider.setPlateMaterial(this.material);
  }

  ngOnDestroy() {
    if (this.errorSubscription && !this.errorSubscription.closed) {
      this.errorSubscription.unsubscribe();
    }
    if (this.removeErrorSubscription && !this.removeErrorSubscription.closed) {
      this.removeErrorSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.view3dProvider.ngAfterViewInit();
  }
  hideHint() {
    this.store.dispatch(new SetHintVisibility(false));
  }
  showHint() {
    this.store.dispatch(new SetHintVisibility(true));
  }
  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }

  zoomToFit() {
    this.view3dProvider.zoomToFitPlate();
  }

  zoomIn() {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.view3dProvider.camera.radius -= 0.001 * this.zoomSpeed;
      }, 10);
    }
  }

  zoomOut() {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.view3dProvider.camera.radius += 0.001 * this.zoomSpeed;
      }, 10);
    }
  }

  stopAction() {
    clearInterval(this.timer);
    this.timer = null;
  }
}
