import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable()
export class SvgIconProvider {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      "angle",
      sanitizer.bypassSecurityTrustResourceUrl("assets/angle.svg")
    );
    iconRegistry.addSvgIcon(
      "Icon_Uebersicht",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Icon_Uebersicht.svg")
    );
    iconRegistry.addSvgIcon(
      "Icon_Vorlage",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Icon_Vorlage.svg")
    );
    iconRegistry.addSvgIcon(
      "Icon_Form",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Icon_Form.svg")
    );
    iconRegistry.addSvgIcon(
      "Icon_Lochbild",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Icon_Lochbild.svg")
    );
    iconRegistry.addSvgIcon(
      "Icon_Befestigung",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Icon_Befestigung.svg")
    );
    iconRegistry.addSvgIcon(
      "Icon_Vorschau",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Icon_Vorschau.svg")
    );
    iconRegistry.addSvgIcon(
      "Bow",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Bow.svg")
    );
    iconRegistry.addSvgIcon(
      "Bow-",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Bow-.svg")
    );
    iconRegistry.addSvgIcon(
      "Circle-",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Circle-.svg")
    );
    iconRegistry.addSvgIcon(
      "Circle",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Circle.svg")
    );
    iconRegistry.addSvgIcon(
      "Polyline-",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Polyline-.svg")
    );
    iconRegistry.addSvgIcon(
      "Polyline",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Polyline.svg")
    );
    iconRegistry.addSvgIcon(
      "Rectangle-",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Rectangle-.svg")
    );
    iconRegistry.addSvgIcon(
      "Rectangle",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Rectangle.svg")
    );
    iconRegistry.addSvgIcon(
      "Icon_Delete",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Icon_delete.svg")
    );
    iconRegistry.addSvgIcon(
      "Icon_Pen",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Icon_Pen.svg")
    );
    iconRegistry.addSvgIcon(
      "Icon_Duplicate",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Icon__duplicate.svg")
    );
    iconRegistry.addSvgIcon(
      "Icon_Redo",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Icon_Redo.svg")
    );
    iconRegistry.addSvgIcon(
      "Icon_Undo",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Icon_Undo.svg")
    );
    iconRegistry.addSvgIcon(
      "Icon_Parameter",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Icon_Parameter.svg")
    );
    iconRegistry.addSvgIcon(
      "Icon_Eigener_Entwurf",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/Icon_Eigener_Entwurf.svg"
      )
    );
    iconRegistry.addSvgIcon(
      "Icon_eigene_Vorlage",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Icon_eigene_Vorlage.svg")
    );
    iconRegistry.addSvgIcon(
      "Icon_Mevaco_Vorlage",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Icon_Mevaco_Vorlage.svg")
    );
    iconRegistry.addSvgIcon(
      "Select",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Select.svg")
    );
    iconRegistry.addSvgIcon(
      "HelpLine",
      sanitizer.bypassSecurityTrustResourceUrl("assets/HelpLine.svg")
    );
    iconRegistry.addSvgIcon(
      "TreatCorner",
      sanitizer.bypassSecurityTrustResourceUrl("assets/TreatCorner.svg")
    );
    iconRegistry.addSvgIcon(
      "Measurement",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Measurement.svg")
    );
    iconRegistry.addSvgIcon(
      "Angle",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Angle.svg")
    );
    iconRegistry.addSvgIcon(
      "Manual",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Manual.svg")
    );
    iconRegistry.addSvgIcon(
      "Assisted",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Assisted.svg")
    );
    iconRegistry.addSvgIcon(
      "Adjustment",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Adjustment.svg")
    );
    iconRegistry.addSvgIcon(
      "CassettePerforationTool",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/CassettePerforationTool.svg"
      )
    );
    iconRegistry.addSvgIcon(
      "Kontur",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Icon_Kontur-waehlen.svg")
    );

    iconRegistry.addSvgIcon(
      "Punkte",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/Icon_Eckpunkte-waehlen.svg"
      )
    );

    iconRegistry.addSvgIcon(
      "FixingImport",
      sanitizer.bypassSecurityTrustResourceUrl("assets/Select.svg")
    );
  }
}
