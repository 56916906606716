import { Component, Input, OnInit } from "@angular/core";
import { Action, Store, select } from "@ngrx/store";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import {
  getColorGroupOptions,
  getColorOptions,
  getColorSystemOptions,
  getLevelOfGlossOptions,
} from "../../../model/dataset/dataset.model";
import { TranslationProvider } from "../../../providers/translation.provider";
import {
  ProductConfigurationSetColor,
  ProductConfigurationSetColorGroup,
  ProductConfigurationSetColorSystem,
  ProductConfigurationSetLevelOfGloss,
  ProductConfigurationSetPolishing,
  ProductConfigurationSetSurfaceType,
  ProductConfigurationSetUndercoating,
} from "../../../store/actions";
import { MevacoState } from "../../../store/reducers";
import { Dropdown, DropdownOption } from "../../dropdown/dropdown.component";

@Component({
  selector: "surface-configurator",
  templateUrl: "./surface-configurator.component.html",
  styleUrls: ["./surface-configurator.component.css"],
})
export class SurfaceConfiguratorComponent implements OnInit {
  public surfaceType: Dropdown;
  public polishedSides: Dropdown;
  public colorSystem: Dropdown;
  public colorGroup: Dropdown;
  public color: Dropdown;
  public undercoating: Dropdown;
  public levelOfGloss: Dropdown;
  @Input() titleColor = "default";

  constructor(
    private translationProvider: TranslationProvider,
    private store: Store<MevacoState>
  ) {}

  translate(text: string, module: string = "configurator") {
    return this.translationProvider.translate(text, module);
  }

  createDropDown(
    name: string,
    value: Observable<string>,
    options: Observable<DropdownOption[]>,
    actionFactory: (string) => Action
  ) {
    return new Dropdown(name, options, value, (value) =>
      this.store.dispatch(actionFactory(value))
    );
  }

  ngOnInit() {
    this.surfaceType = this.createDropDown(
      this.translate("SurfaceType"),
      this.store.pipe(
        select((store) => store.model.productConfiguration.surface.surfaceType)
      ) as Observable<string>,
      this.getSurfaceTypeOptions(),
      (value) => new ProductConfigurationSetSurfaceType(value)
    );

    this.polishedSides = this.createDropDown(
      this.translate("Sides"),
      this.store.pipe(
        select(
          (store) => store.model.productConfiguration.surface.polishedSides
        )
      ) as Observable<string>,
      this.getPolishingOptions(),
      (value) => new ProductConfigurationSetPolishing(value)
    );

    this.colorSystem = this.createDropDown(
      this.translate("Color System"),
      this.store.pipe(
        select((store) => store.model.productConfiguration.surface.colorSystem)
      ) as Observable<string>,
      this.getColorSystemOptions(),
      (value) => new ProductConfigurationSetColorSystem(value)
    );

    this.colorGroup = this.createDropDown(
      this.translate("ColorGroup"),
      this.store.pipe(
        select((store) => store.model.productConfiguration.surface.colorGroup)
      ) as Observable<string>,
      this.getColorGroupOptions(),
      (value) => new ProductConfigurationSetColorGroup(value)
    );
    this.color = this.createDropDown(
      this.translate("Color"),
      this.store.pipe(
        select((store) => store.model.productConfiguration.surface.color)
      ) as Observable<string>,
      this.getColorOptions(),
      (value) => new ProductConfigurationSetColor(value)
    );

    this.undercoating = this.createDropDown(
      this.translate("Undercoating"),
      this.store.pipe(
        select((store) => store.model.productConfiguration.surface.undercoating)
      ) as Observable<string>,
      this.getUndercoatingOptions(),
      (value) => new ProductConfigurationSetUndercoating(value)
    );

    this.levelOfGloss = this.createDropDown(
      this.translate("LevelOfGloss"),
      this.store.pipe(
        select((store) => store.model.productConfiguration.surface.levelOfGloss)
      ) as Observable<string>,
      this.getLevelOfGlossOptions(),
      (value) => new ProductConfigurationSetLevelOfGloss(value)
    );
  }

  private getSurfaceTypeOptions(): Observable<DropdownOption[]> {
    return combineLatest([
      this.store.pipe(select((store) => store.model.dataset)),
      this.store.pipe(
        select((store) => store.model.productConfiguration.material.quality)
      ),
      this.store.pipe(
        select((store) => store.model.productConfiguration.material.thickness)
      ),
    ]).pipe(
      debounceTime(1),
      map(([dataset, quality, thickness]) => {
        if (!dataset) {
          return [];
        }
        const types: DropdownOption[] = [
          new DropdownOption(
            "text",
            this.translate("No threatment"),
            true,
            "No threatment",
            this.translate("SurfaceType")
          ),
          new DropdownOption(
            "text",
            this.translate("Coated"),
            true,
            "Coated",
            this.translate("SurfaceType")
          ),
        ];
        if (
          quality === "" ||
          (dataset.polishedMaterials[quality] &&
            dataset.polishedMaterials[quality].indexOf(Number(thickness)) !==
              -1)
        ) {
          types.push(
            new DropdownOption(
              "text",
              this.translate("Polished"),
              true,
              "Polished",
              this.translate("Polished")
            )
          );
        }
        return types;
      })
    );
  }

  private getPolishingOptions(): Observable<DropdownOption[]> {
    return new BehaviorSubject<DropdownOption[]>([
      new DropdownOption(
        "text",
        this.translate("bs"),
        true,
        "bs",
        this.translate("Sides")
      ),
      new DropdownOption(
        "text",
        this.translate("es"),
        true,
        "es",
        this.translate("Sides")
      ),
    ]);
  }

  private getColorSystemOptions(): Observable<DropdownOption[]> {
    return this.store.pipe(select((store) => store.model.dataset)).pipe(
      map((dataset) => {
        if (!dataset) {
          return [];
        }
        return getColorSystemOptions(dataset).map(
          (x) =>
            new DropdownOption(
              "text",
              x,
              true,
              x,
              this.translate("Color System")
            )
        );
      })
    );
  }

  private getColorGroupOptions(): Observable<DropdownOption[]> {
    return combineLatest([
      this.store.pipe(select((store) => store.model.dataset)),
      this.store.pipe(
        select((store) => store.model.productConfiguration.surface.colorSystem)
      ),
    ]).pipe(
      debounceTime(1),
      map(([dataset, colorSystem]) => {
        if (!dataset) {
          return [];
        }
        return getColorGroupOptions(dataset, colorSystem).map(
          (x) =>
            new DropdownOption(
              "text",
              this.translate(x),
              true,
              x,
              this.translate("ColorGroup")
            )
        );
      })
    );
  }

  private getColorOptions(): Observable<DropdownOption[]> {
    return combineLatest([
      this.store.pipe(select((store) => store.model.dataset)),
      this.store.pipe(
        select((store) => store.model.productConfiguration.surface.colorSystem)
      ),
      this.store.pipe(
        select((store) => store.model.productConfiguration.surface.colorGroup)
      ),
    ]).pipe(
      debounceTime(1),
      map(([dataset, colorSystem, colorGroup]) => {
        if (!dataset) {
          return [];
        }
        return getColorOptions(dataset, colorSystem, colorGroup).map(
          (x) =>
            new DropdownOption(
              "withImage",
              {
                name: x,
                imgUrl: dataset.paintAssetsUrl + x + ".jpg",
              },
              true,
              x,
              this.translate("Color")
            )
        );
      })
    );
  }

  private getUndercoatingOptions(): Observable<DropdownOption[]> {
    return new BehaviorSubject<DropdownOption[]>([
      new DropdownOption(
        "text",
        this.translate("Yes"),
        true,
        "Yes",
        this.translate("Undercoating")
      ),
      new DropdownOption(
        "text",
        this.translate("No"),
        true,
        "No",
        this.translate("Undercoating")
      ),
    ]);
  }

  private getLevelOfGlossOptions(): Observable<DropdownOption[]> {
    return combineLatest([
      this.store.pipe(select((store) => store.model.dataset)),
      this.store.pipe(
        select((store) => store.model.productConfiguration.surface.color)
      ),
    ]).pipe(
      debounceTime(1),
      map(([dataset, color]) => {
        if (!dataset) {
          return [];
        }
        return getLevelOfGlossOptions(dataset, Number(color)).map(
          (x) =>
            new DropdownOption(
              "text",
              this.translate(x, "Pulver"),
              true,
              x,
              this.translate("LevelOfGloss")
            )
        );
      })
    );
  }
}
