<ol class="list-group" style="list-style-type: none">
  <li *ngIf="(step | async) !== Step.design && (hintStatusObs | async) === hintStatus.Sticky" style="margin: 0 0 40px 0">
    <div class="hints">
      <span style="font-weight: 500;"></span>
      <img style="padding-right: 10px" src="assets/Icon_Info.svg">
      {{translate((hintText|async))}}
    </div>
  </li>
  <!-- 
  <li style="margin: 0 0 40px 0">
    <app-expandable-label></app-expandable-label>
  </li> 
-->
  <li *ngIf="errorMessage" style="margin: 0 0 0 0">
    <ngb-alert *ngIf="errorType === 'success'" class="error" [dismissible]="false" type="{{errorType}}" [ngClass]="'success'" (close)="errorMessage = null">{{errorMessage}}</ngb-alert>
    <ngb-alert *ngIf="errorType === 'danger'" class="error" [dismissible]="false" type="{{errorType}}" [ngClass]="'danger'" (close)="errorMessage = null">{{translate( errorMessage )}}</ngb-alert>
  </li>
</ol>
