<div class="configurator-accordion-wrapper">
  <h2 class="configurator-accordion-title"
  [class.accordion-title--color-primary]="titleColor === 'primary'"
  [class.accordion-title--color-default]="titleColor === 'default'"
  >
    {{translate("Material")}}
  </h2>
  <div class="configurator">
    <dropdown [model]="material" [tooltipConfig]="{fieldName:'material.materialType', helpText:'Material Tooltip'}"></dropdown>
    <dropdown [model]="thickness" [tooltipConfig]="{fieldName:'material.thickness', helpText:'Thickness Tooltip'}"></dropdown>
    <div *ngIf="(foilOptions | async).length > 0">
      <dropdown [model]="foil" [tooltipConfig]="{fieldName:'material.foil', helpText:'Foil Tooltip'}"></dropdown>
    </div>
    <dropdown [model]="quality" [tooltipConfig]="{fieldName:'material.quality', helpText:'Quality Tooltip'}"></dropdown>
  </div>
</div>


<ng-template #confirmModal let-modal>
  <div class="dialog-modal-header dialog-modal-header--small">
    <div class="dialog-modal-title-container">
      <div class="dialog-modal-title-content">
        <h4 class="dialog-modal-title dialog-modal-title--small" id="modal-basic-title">
          <img src="assets/tooltip-warning-icon.svg" alt="warning-icon" class="dialog-modal-title-img">
          <span>{{translate("Warning", 'designer')}}</span>
        </h4>
      </div>
    </div>    
 
  <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

  <div class="dialog-modal-body dialog-modal-body--small" >
    <p class="dialog-modal-description--small">{{translate("remove bend positions", 'designer')}}</p>
  </div>

  <div class="dialog-modal-footer dialog-modal-footer--small dialog-modal-footer--one-btn" >
    <button  type="button" class="btn btn-primary dialog-modal-btn--small mr-15" (click)="modal.close(true)"> {{translate('Yes')}}
    </button>
    <button  type="button" class="btn btn-outline-primary dialog-modal-btn--small" (click)="modal.close(false)">  {{translate('No')}}
    </button>
  </div>

</ng-template>
