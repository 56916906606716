import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError as observableThrowError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Vector2, multiplyVector2byScalar } from "webcad/math";
import { Segment, getPointsFromPolyLine } from "webcad/models";
import { environment } from "../../environments/environment";
import { MevacoResponse } from "../model/client-server.Params/mevaco-response.model";
import {
  stringToFormat,
  stringToSize,
} from "../model/dataset/perforation-format.model";
import { SelectedDesignerEffect } from "../model/effect.model";
import { SelectedFading } from "../model/fading.model";
import { MevacoPage } from "../model/mevaco-page.model";
import { SelectedPerfaction } from "../model/perfaction.model";
import { Perforation } from "../model/perforation.model";
import { Element } from "../model/product-configuration/element.model";
import { ShapeWithHoles } from "../model/shape-with-holes";
import {
  getEffectInputs,
  getFadingInputs,
  getMultiSizePattern,
  getPerfactionInputsNotNull,
} from "../store/reducers";
import {PerforationCustomGeom} from "../model/product-configuration/perforation-custom-geom";

@Injectable()
export class PerforationService {
  constructor(private http: HttpClient) {}

  getPerforationForCurrentDrawing(
    state: MevacoPage
  ): Observable<Perforation[]> | null {
    console.log("getPerforationForCurrentDrawing");
    const element: Element =
      state.productConfiguration.elements[state.drawing.plate.position - 1];
    const wallpaper = state.productConfiguration.wallpaper;
    const perforationType = state.productConfiguration.activePerforation;
    const customPerforation = state.productConfiguration.customPerforation;
    let perfProvider: Observable<Perforation[]> = null;
    if (
      !!element &&
      perforationType === "wallpaper" &&
      wallpaper.name &&
      wallpaper.name !== ""
    ) {
      console.log("0");
      perfProvider = this.setWallpaper({
        perforationAreas: mapPerforationArea(element),
        name: wallpaper.name,
        zoom: wallpaper.zoomEnabled,
        halfHoles: wallpaper.halfHolesEnabled,
      });
    }
    if (perfProvider === null) {
      console.log("1");
      const posCanBeSelected = !state.productConfiguration.elements.some(
        (e) => !e.perforationAutoCenter || !e.isPerforationSimpleRect
      );
      const perf = state.productConfiguration.perforation;
      if (
        perforationType === "classic" &&
        !customPerforation &&
        !!element &&
        !!perf &&
        perf.format !== "" &&
        perf.size !== ""
      ) {
        console.log("1-1");
        const size = stringToSize(perf.size);
        const format = stringToFormat(perf.format);
        const pos: number =
          perf.position === "" || isNaN(+perf.position) ? null : +perf.position;

        const predefined: PredefinedPerforationParams = {
          dependentMode: perf.dependentMode,
          pattern: perf.perforationType,
          w: size.width / 1000,
          l: size.length / 1000,
          p1: format.p1 / 1000,
          p2: format.p2 / 1000,
          pos,
          posCanBeSelected,
        };

        perfProvider = this.setPerforation({
          predefined,
          custom: null,
          multiSizePattern: null,
          perforationAreas: mapPerforationArea(element),
          perfAreasAreSimpleRectangles: element.isPerforationSimpleRect,
          autoCenter: !!state.drawing.plate.autoCenter,
          effectParams: getEffectInputs(state.productConfiguration),
          fadingParams: getFadingInputs(state.productConfiguration),
          perfactionParams: getPerfactionInputsNotNull(
            state.productConfiguration.perfaction
          ),
          perforationOffset: element.perfactionOffset,
        });
        console.log(1, { perfProvider });
      } else if (perforationType === "perfaction" && !!element) {
        console.log("1-2");
        const multiSizePattern: MultiSizePattern = getMultiSizePattern(
          state.productConfiguration.multiSizePattern,
          state.dataset
        );
        if (!!multiSizePattern) {
          console.log("1-3");
          perfProvider = this.setPerforation({
            predefined: null,
            custom: null,
            multiSizePattern: multiSizePattern,
            perforationAreas: mapPerforationArea(element),
            perfAreasAreSimpleRectangles: element.isPerforationSimpleRect,
            autoCenter: !!state.drawing.plate.autoCenter,
            effectParams: getEffectInputs(state.productConfiguration),
            fadingParams: getFadingInputs(state.productConfiguration),
            perfactionParams: getPerfactionInputsNotNull(
              state.productConfiguration.perfaction
            ),
            perforationOffset: element.perfactionOffset,
          });
          console.log(2, { perfProvider });
        }
      } else if (
        perforationType === "classic" &&
        customPerforation &&
        perf &&
        perf.custom &&
        perf.custom.stumps &&
        perf.custom.offsetX &&
        perf.custom.offsetY
      ) {
        console.log("1-4");
        const usableStamps = perf.custom.stumps.filter(
          (stamp) =>
            !!stamp &&
            stamp.enabled &&
            stamp.offsetDivX &&
            stamp.offsetDivX &&
            stamp.w
        );
        if (usableStamps.length > 0) {
          console.log("1-5");
          const custom: CustomPerforationParams = {
            dependentMode: perf.dependentMode,
            stamps: usableStamps.map((stamp) => ({
              w: stamp.w / 1000,
              l: stamp.l / 1000,
              shape: stamp.form,
              rotation: stamp.rotation,
              offsetX: perf.custom.offsetX / stamp.offsetDivX,
              offsetY: perf.custom.offsetY / stamp.offsetDivY,
              startX: stamp.startX,
              startY: stamp.startY,
              endX: stamp.endX,
              endY: stamp.endY,
            })),
            effectParams: state.productConfiguration.effect,
            fadingParams: state.productConfiguration.fading,
          };
          if (state.customPatternEditorOpened) {
            console.log("1-6");
            perfProvider = this.customPerforationPreview(custom);
          } else {
            console.log("1-7");
            perfProvider = this.setPerforation({
              predefined: null,
              custom,
              multiSizePattern: null,
              perforationAreas: mapPerforationArea(element),
              perfAreasAreSimpleRectangles: element.isPerforationSimpleRect,
              autoCenter: !!state.drawing.plate.autoCenter,
              effectParams: getEffectInputs(state.productConfiguration),
              fadingParams: getFadingInputs(state.productConfiguration),
              perfactionParams: getPerfactionInputsNotNull(
                state.productConfiguration.perfaction
              ),
              perforationOffset: element.perfactionOffset,
            });
            console.log(3, { perfProvider });
          }
        }
      }
    }
    return perfProvider;
  }

  public setPerforation(params: PerforationParams): Observable<Perforation[]> {
    console.log("SET PERFORATION");
    return this.http
      .post<Perforation[]>(environment.api_url + "/api/perforation", params)
      .pipe(
        map((perforations: Perforation[]) =>
          mapStampPollygonsForPerforations(stringMaskToUint8Array(perforations))
        ),
        catchError((error) => observableThrowError(error))
      );
  }

  public customPerforationPreview(
    params: CustomPerforationParams
  ): Observable<Perforation[]> {
    return this.http
      .post<Perforation[]>(
        environment.api_url + "/api/customPerforationPreview",
        params
      )
      .pipe(
        map((perforations: Perforation[]) =>
          mapStampPollygonsForPerforations(stringMaskToUint8Array(perforations))
        ),
        catchError((error) => observableThrowError(error))
      );
  }
  public getCustomPerforationFromClassic(perforationType: string, size: string, format: string, position: string): Observable<PerforationCustomGeom> {
    return this.http.post<PerforationCustomGeom>( environment.api_url + '/api/customPerforationFromClassic', {perforationType, size, format, position}).pipe(
      map(custom => {
        if (!custom) {
          return {
            offsetX: 0,
            offsetY: 0,
            stumps: [null, null, null, null, null, null, null, null, null, null, null]
          };
        }
        const c = {...custom};
        while (c.stumps.length < 11) {
          c.stumps.push(null);
        }
        return c;
      }),
      catchError(error => observableThrowError(error))
    );
  }

  public setWallpaper(params: any): Observable<Perforation[]> {
    return this.http
      .post<Perforation[]>(environment.api_url + "/api/wallpaper", params)
      .pipe(
        map((perforations: Perforation[]) => {
          return perforations.map((perforation) => {
            perforation.stamp.polygons = perforation.stamp.polylines.map(
              (polyline) => getPointsFromPolyLine(polyline)
            );
            return perforation;
          });
        }),
        catchError((error) => observableThrowError(error))
      );
  }

  getWallpaperDxfByName(name: string): Observable<MevacoResponse<Segment[][]>> {
    const url =
      environment.api_url +
      "/api/mevaco/GetWallpaperDxfAsPolylineByName/" +
      name;
    return this.http.get<MevacoResponse<Segment[][]>>(url);
  }
}

function stringMaskToUint8Array(perforations: Perforation[]) {
  return perforations.map((perf) => {
    if (!perf.areEffectsEnabled) return perf;
    perf.positions = perf.positions.map((pos) => {
      const mask = atob(pos.mask.mask as string)
        .split("")
        .map((char) => char.charCodeAt(0));
      pos.mask.mask = Uint8Array.from(mask) as Uint8Array;
      return pos;
    });
    return perf;
  });
}

function mapStampPollygonsForPerforations(perforations: Perforation[]) {
  return perforations.map((perf) => {
    if (!perf.areEffectsEnabled) {
      perf.stamp.polygons = perf.stamp.polylines.map((polyline) =>
        getPointsFromPolyLine(polyline)
      );
      return perf;
    }
    perf.stamps = perf.stamps.map((stp) => {
      if (stp == null) return null;
      stp.polygons = stp.polylines.map((polyline) =>
        getPointsFromPolyLine(polyline)
      );
      return stp;
    });
    return perf;
  });
}

function mapPerforationArea(element): PerforationArea[] {
  return element.perforationAreas.map((x) => {
    return {
      shape: x.shape,
      offset: multiplyVector2byScalar(x.offset, 0.001),
      rotation: (x.rotation * Math.PI) / 180,
    };
  });
}

interface PredefinedPerforationParams {
  dependentMode: boolean;
  pattern: string;
  w: number;
  l: number;
  p1: number;
  p2: number;
  pos?: number;
  posCanBeSelected: boolean;
}

interface CustomStampParams {
  shape: string;
  w: number;
  l: number;
  rotation: number;
  offsetX: number;
  offsetY: number;
  startX: number;
  startY: number;
  endX: number;
  endY: number;
}

interface CustomPerforationParams {
  dependentMode: boolean;
  stamps: CustomStampParams[];
  effectParams: SelectedDesignerEffect;
  fadingParams: SelectedFading;
}

interface PerforationArea {
  shape: ShapeWithHoles;
  offset: { x: number; y: number };
  rotation: number;
}

export interface MultiSizePattern {
  geomatrixId: number;
  pattern: string;
  p1: number;
  p2: number;

  singleToolsIds: number[];
}

interface PerforationParams {
  predefined?: PredefinedPerforationParams;
  custom?: CustomPerforationParams;
  perforationAreas: PerforationArea[];

  lomoe?: any;
  perfAreasAreSimpleRectangles: boolean;
  autoCenter: boolean;
  effectParams: SelectedDesignerEffect;
  fadingParams: SelectedFading;
  perfactionParams: SelectedPerfaction;
  multiSizePattern: MultiSizePattern;
  perforationOffset: Vector2;
}
