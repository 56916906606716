import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "debounce-input",
  templateUrl: "./debounce-input.component.html",
  styleUrls: ["./debounce-input.component.css"],
})
export class DebounceInputComponent implements OnInit {
  @Input() value: string;
  @Input() placeholder: string;
  @Output() valueChanged = new EventEmitter<string>();
  @Input() inputType: string;
  @Input() validate: (string) => string;
  public valueControl = new FormControl();
  public sub: Subscription;
  @Input() tabIndex: number;
  @ViewChild("element", { static: true }) element: ElementRef;

  constructor() {
    this.inputType = "text";
  }

  ngOnInit() {
    this.setInitialValue();
    // debounce keystroke events
    this.sub = this.valueControl.valueChanges
      .pipe(
        debounceTime(1000)
        // filter
      )
      .subscribe((v) => {
        v = v.replace(",", ".");
        this.valueControl.setValue(this.validate ? this.validate(v) : v, {
          emitEvent: false,
        });
        this.value = this.valueControl.value;
        this.valueChanged.emit(this.valueControl.value);
      });
  }

  setInitialValue(): void {
    this.valueControl.patchValue(this.value);
  }
}
