import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Guid } from "guid-typescript";
import { Observable, Subscription, fromEvent } from "rxjs";
import { map } from "rxjs/operators";
import { clamp } from "webcad/math";
import { PolylineArea, aabbOfPolyline } from "webcad/models";
import { MultiShape } from "../../../../../../src/app/model/basic-templates.model";
import { PerforationAreaModel } from "../../../../../../src/app/model/perforation-area.model";
import {
  Element,
  ElementType,
} from "../../../../../../src/app/model/product-configuration/element.model";
import {
  ShapeWithHoles,
  createRectangle,
  shapeWithHolesHash,
} from "../../../../../../src/app/model/shape-with-holes";
import { UserTemplate } from "../../../../../../src/app/model/user-template.model";
import {
  MevacoState,
  ProductConfigurationAddElement,
  getMultiShapeTemplate,
  getUserTemplates,
  reqValMultiShape,
} from "../../../../../../src/app/store";
import { GetUserTemplates } from "../../../../../../src/app/store/actions/user-template.actions";
import {
  SetMultiShapeA,
  SetMultiShapeB,
  SetMultiShapeE1,
  SetMultiShapeE2,
  SetMultiShapeF1,
  SetMultiShapeF2,
  SetMultiShapeTemplate,
  SetMultishapeColumn,
  SetMultishapeColumnsNumber,
  SetMultishapeRow,
  SetMultishapeRowsNumber,
} from "../../../../store/actions/element-creator.actions";
import { roundDecimal } from "../../../../utils/utils";
import { TemplateName } from "../../template-name";
import { TemplateUtils } from "../../templatesUtils";

@Component({
  selector: "mevaco-multi-shape",
  templateUrl: "./multi-shape.component.html",
  styleUrls: [
    "../../../element-creator/element-creator.component.css",
    "./multi-shape.component.css",
  ],
})
export class MultiShapeComponent implements OnInit {
  @ViewChild("closeButton", { static: true })
  closeButton: ElementRef;
  @Output() dismissModal = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() isModalOpen = new EventEmitter();

  public reqValMultiShape: Observable<boolean>;
  public configurableTemplate: MultiShape;
  public templates: Observable<UserTemplate[]>;
  public roundDecimal = roundDecimal;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<MevacoState>,
    public templateUtils: TemplateUtils
  ) {}

  ngOnInit() {
    this.store.dispatch(new GetUserTemplates());
    this.templates = this.store.pipe(select(getUserTemplates));
    this.subscriptions.push(
      this.store.pipe(select(getMultiShapeTemplate)).subscribe(
        (v) =>
          (this.configurableTemplate = {
            ...v,
            plateParams: { ...v.plateParams },
          })
      )
    );
    this.reqValMultiShape = this.store.pipe(select(reqValMultiShape));
    this.createFromMultiShape();
  }

  isModalOpenMethod() {
    this.isModalOpen.emit(false);
  }

  createFromMultiShape() {
    const obs = fromEvent(this.closeButton.nativeElement, "click").pipe(
      map(() => {
        return this.configurableTemplate;
      })
    );

    obs.subscribe((result) => {
      const plateA = result.plateParams.a / 1000;
      const plateB = result.plateParams.b / 1000;
      const e1 = result.plateParams.e1 / 1000;
      const e2 = result.plateParams.e2 / 1000;
      const f1 = result.plateParams.f1 / 1000;
      const f2 = result.plateParams.f2 / 1000;
      const contures = createRectangle(
        { x: 0, y: 0 },
        { x: plateB, y: plateA }
      );
      const shapeWithHoles: ShapeWithHoles = {
        conture: contures,
        holes: [],
      };
      shapeWithHoles.aabb = aabbOfPolyline(shapeWithHoles.conture);
      shapeWithHoles.area = PolylineArea(shapeWithHoles.conture);
      shapeWithHoles.hash = shapeWithHolesHash(shapeWithHoles);
      const perfMin = { x: f1, y: e1 };
      const perfMax = { x: plateB - f2, y: plateA - e2 };
      const perforationAreas: PerforationAreaModel[] = [];
      let rowStep = 0;
      for (let i = 0; i < result.rowNumber; i++) {
        const row = result.rows[i];
        let columnStep = 0;
        for (let j = 0; j < result.columnNumber; j++) {
          const column = result.columns[j];
          const shapeA = row.size / 1000;
          const shapeB = column.size / 1000;
          const begin = { x: perfMin.x + columnStep, y: perfMin.y + rowStep };
          const end = { x: begin.x + shapeB, y: begin.y + shapeA };
          const perfContour = createRectangle(begin, end);
          const perfAabb = aabbOfPolyline(perfContour);
          const perfArea = PolylineArea(perfContour);
          const perforationAreaShape: ShapeWithHoles = {
            conture: perfContour,
            holes: [],
            aabb: perfAabb,
            area: perfArea,
          };
          perforationAreaShape.hash = shapeWithHolesHash(perforationAreaShape);
          perforationAreas.push({
            shape: perforationAreaShape,
            offset: { x: 0, y: 0 },
            rotation: 0,
          });
          columnStep += column.margin / 1000 + shapeB;
        }
        rowStep += row.margin / 1000 + row.size / 1000;
      }
      const element: Element = {
        type: ElementType.individual,
        templateName: TemplateName.multiShape,
        boundary: null,
        position: -1, // will be set inb reducer
        quantity: 1,
        a: plateA,
        b: plateB,
        aIst: plateA,
        bIst: plateB,
        e1: e1.toString(),
        e1ist: e1.toString(),
        e2: e2.toString(),
        e2ist: e2.toString(),
        f1: f1.toString(),
        f1ist: f1.toString(),
        f2: f2.toString(),
        f2ist: f2.toString(),
        openMeshE: "",
        openMeshF: "",
        toleranceWidth: 0,
        toleranceLength: 0,
        label: "",
        note: "",
        unperforated: false,
        posibleCoil: "No",
        posiblePlate: "No",
        shapes: null,
        nodes: null,
        verticesIndexes: null,
        visualizationShape: null,
        previewImageId: Guid.create().toString(),
        shape: shapeWithHoles,
        perforationAreas: perforationAreas,
        helpLines: [],
        measurements: [],
        angleMeasurements: [],
        mountings: [],
        perforationAutoCenter: true,
        area: shapeWithHoles.area,
        minRadius: null,
        minParallelEdgesDistance: null,
        minMarginDistance: null,
        isPerforationSimpleRect: null,
        isShapeSimpleRect: null,
        minMountingHoleEdgeDistance: null,
        minMountingHolesDistance: null,
        minOutsideAngle: null,
        numberOfArcs: null,
        minDistanceBetweenEdges: null,
        minDistanceBetweenMountingAndPerforatedArea: null,
        //perforation: null,
        possibleAllAcross: true,
        minDistanceBetweenPerforationAreas: null,
        lfbIst: undefined,
        lflIst: undefined,

        breakLines: [],
        bendingLinesDistances: [],
        //possiblePerforationZones: []
      };
      this.store.dispatch(new ProductConfigurationAddElement(element));
    });
  }

  setColumns(value: any) {
    if (Number(value) > 8) {
      value = 8;
    }
    this.store.dispatch(new SetMultishapeColumnsNumber(value));
  }

  setRows(value: any) {
    if (Number(value) > 8) {
      value = 8;
    }
    this.store.dispatch(new SetMultishapeRowsNumber(value));
  }

  setRow(value: any, margin: number, index: number) {
    this.store.dispatch(
      new SetMultishapeRow({
        value: clamp(Number(value), 0, 5000),
        margin: clamp(Number(margin), 0, 5000),
        index: index,
      })
    );
  }

  setColumn(value: any, margin: number, index: number) {
    this.store.dispatch(
      new SetMultishapeColumn({
        value: clamp(Number(value), 0, 5000),
        margin: clamp(Number(margin), 0, 5000),
        index: index,
      })
    );
  }

  checkIfNumber(str) {
    return /^-?\d+$/.test(str);
  }

  setMultiShapeTemplate(template: MultiShape) {
    this.store.dispatch(new SetMultiShapeTemplate({ ...template }));
  }

  setMultiShapeA(newA: any) {
    this.store.dispatch(new SetMultiShapeA(newA));
  }

  setMultiShapeE1(newE1: any) {
    this.store.dispatch(new SetMultiShapeE1(newE1));
  }

  setMultiShapeE2(newE2: any) {
    this.store.dispatch(new SetMultiShapeE2(newE2));
  }

  setMultiShapeB(newB: any) {
    this.store.dispatch(new SetMultiShapeB(newB));
  }

  setMultiShapeF1(newF1: any) {
    this.store.dispatch(new SetMultiShapeF1(newF1));
  }

  setMultiShapeF2(newF2: any) {
    this.store.dispatch(new SetMultiShapeF2(newF2));
  }

  dismiss(str: string) {
    this.dismissModal.emit(str);
  }

  close(input: {}) {
    this.closeModal.emit(input);
  }
}
