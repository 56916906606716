import {HelpLine} from "../help-line.model";
import {Drawing} from "../drawing.model";

export interface HelpLinesViewModel {
  helpLines: Map<number, HelpLine>;
  toolHelpLines: Map<number, HelpLine>;
  state: boolean;
}

export const HelpLinesViewModelPipeLine = (state: Drawing): HelpLinesViewModel => {
  return {
    helpLines: state.helpingLines,
    toolHelpLines: state.toolHelpLines,
    state: state.snapOptions.helpLines
  };
};
