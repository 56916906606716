import { MevacoPage } from "../../model/mevaco-page.model";
import { PerforationVisualizerViewModel } from "../../model/view-model/perforation-visualizer.viewModel";
import { allContainingBendingNode } from "../../utils/bending";
import { BendingTreeProvider } from "./bending-tree.provider";

export class PerforationViewModelProvider {
  constructor(private bendingTreeViewModelProvider: BendingTreeProvider) {
    console.log("111111111111", { bendingTreeViewModelProvider });
  }
  private viewModel: PerforationVisualizerViewModel;

  getViewModel(newModel: MevacoPage): PerforationVisualizerViewModel {
    const customPatternEditorOpened = newModel.customPatternEditorOpened;
    const bendingTree = customPatternEditorOpened
      ? allContainingBendingNode
      : this.bendingTreeViewModelProvider.getViewModel(newModel.drawing);
    const custom = newModel.productConfiguration.perforation.custom;
    const selectedStamp = customPatternEditorOpened
      ? custom.stumps
          .filter(
            (stamp) =>
              !!stamp &&
              stamp.enabled &&
              stamp.offsetDivX &&
              stamp.offsetDivY &&
              stamp.w &&
              custom.offsetX &&
              custom.offsetY
          )
          .indexOf(
            newModel.productConfiguration.perforation.custom.stumps[
              newModel.selectedCustomStamp
            ]
          )
      : -1;
    if (!customPatternEditorOpened && !newModel.drawing.plate) {
      this.viewModel = null;
    } else if (
      !this.viewModel ||
      this.viewModel.perforation !== newModel.lastPerforationResponse ||
      this.viewModel.plateDepth !==
        (customPatternEditorOpened ? 0.001 : newModel.drawing.plate.depth) ||
      this.viewModel.showPerforation !==
        (customPatternEditorOpened
          ? true
          : newModel.drawing.snapOptions.perforation) ||
      this.viewModel.showBorder !==
        (customPatternEditorOpened
          ? true
          : newModel.drawing.showPerforationBorder) ||
      this.viewModel.perforationIndex !==
        (!customPatternEditorOpened &&
        !newModel.drawing.dependentMode &&
        newModel.drawing.fillPerforation
          ? newModel.drawing.selectedPerforationArea
          : -1) ||
      this.viewModel.bendingTree !== bendingTree ||
      this.viewModel.fillPerforation !==
        (customPatternEditorOpened ? true : newModel.drawing.fillPerforation) ||
      this.viewModel.selectedStamp !== selectedStamp
    ) {
      this.viewModel = {
        perforation: newModel.lastPerforationResponse,
        plateDepth: customPatternEditorOpened
          ? 0.001
          : newModel.drawing.plate.depth,
        showPerforation: customPatternEditorOpened
          ? true
          : newModel.drawing.snapOptions.perforation,
        showBorder: customPatternEditorOpened
          ? true
          : newModel.drawing.showPerforationBorder,
        perforationIndex:
          !customPatternEditorOpened &&
          !newModel.drawing.dependentMode &&
          newModel.drawing.fillPerforation
            ? newModel.drawing.selectedPerforationArea
            : -1,
        bendingTree: bendingTree,
        fillPerforation: customPatternEditorOpened
          ? true
          : newModel.drawing.fillPerforation,
        selectedStamp,
      };
    }
    return this.viewModel;
  }
}
