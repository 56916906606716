import {dotVector2, normalizeVector2, Vector2} from './vector2';
import {Vector3} from './vector3';

export function fModF(value: number, enclosureValue: number): number {
    return value - (Math.floor(value / enclosureValue) * enclosureValue);
}

export function LineCircleIntersect2D(center: Vector2, radius: number, point1: Vector2, point2: Vector2, withRound: boolean = true): Vector2[] {
    let dx, dy, A, B, C, det, t;
    const intersections: Vector2[] = [];

    dx = point2.x - point1.x;
    dy = point2.y - point1.y;

    A = dx * dx + dy * dy;
    B = 2 * (dx * (point1.x - center.x) + dy * (point1.y - center.y));
    C = (point1.x - center.x) * (point1.x - center.x) + (point1.y - center.y) * (point1.y - center.y) - radius * radius;

    det = (B * B - 4 * A * C);
    const rdet = withRound ? Math.round(det) : det;
    if ((A <= 0.0000001) || (rdet < 0)) {
        // No real solutions.
        return intersections;
    } else if (rdet === 0) {
        // One solution.
        t = -B / (2 * A);
        intersections.push({x: point1.x + t * dx, y: point1.y + t * dy});

        return intersections;
    } else {
        // Two solutions.
        t = ((-B + Math.sqrt(det)) / (2 * A));
        intersections.push({x: point1.x + t * dx, y: point1.y + t * dy});
        t = ((-B - Math.sqrt(det)) / (2 * A));
        intersections.push({x: point1.x + t * dx, y: point1.y + t * dy});
        return intersections;
    }
}

export function LineIntersect2D(aDir: Vector2, aStart: Vector2, bDir: Vector2, bStart: Vector2): Vector2 {
    const l1_dir: Vector2 = normalizeVector2(aDir);
    const l2_dir: Vector2 = normalizeVector2(bDir);
    const dot = dotVector2(l1_dir, l2_dir);
    if (dot === 1 || dot === -1) {
        return null;
    }
    const l1_normal: Vector2 = {x: -l1_dir.y, y: l1_dir.x};
    const l2_normal: Vector2 = {x: -l2_dir.y, y: l2_dir.x};

    const A = l1_normal.x;
    const B = l1_normal.y;

    const C = l2_normal.x;
    const D = l2_normal.y;

    const k1 = (A * aStart.x) + (B * aStart.y);
    const k2 = (C * bStart.x) + (D * bStart.y);
    if ((A * D - B * C) === 0) {
        return null;
    }
    const x_intersect = (D * k1 - B * k2) / (A * D - B * C);
    const y_intersect = (-C * k1 + A * k2) / (A * D - B * C);

    return {x: x_intersect, y: y_intersect};
}

export function vector2toVector3(v: Vector2, z: number = 0): Vector3 {
    return {x: v.x, y: v.y, z: z};
}

export function CircleCircleIntersect2D(cx0: number, cy0: number, radius0: number, cx1: number, cy1: number, radius1: number): Vector2[] {
    var intersections: Vector2[] = [];
    // Find the distance between the centers.
    var dx = cx0 - cx1;
    var dy = cy0 - cy1;
    var dist = Math.sqrt(dx * dx + dy * dy);

    // See how many solutions there are.
    if (dist > radius0 + radius1) {
        // No solutions, the circles are too far apart.
        return intersections;
    } else if (dist < Math.abs(radius0 - radius1)) {
        // No solutions, one circle contains the other.
        return intersections;
    } else if ((dist === 0) && (radius0 === radius1)) {
        // No solutions, the circles coincide.
        return intersections;
    } else {
        // Find a and h.
        var a = (radius0 * radius0 - radius1 * radius1 + dist * dist) / (2 * dist);
        var h = Math.sqrt(radius0 * radius0 - a * a);
        if (isNaN(h)) {
            h = 0;
        }
        // Find P2.
        var cx2 = cx0 + a * (cx1 - cx0) / dist;
        var cy2 = cy0 + a * (cy1 - cy0) / dist;

        // Get the points P3.
        intersections.push({
            x: (cx2 + h * (cy1 - cy0) / dist),
            y: (cy2 - h * (cx1 - cx0) / dist)
        });
        intersections.push({
            x: (cx2 - h * (cy1 - cy0) / dist),
            y: (cy2 + h * (cx1 - cx0) / dist)
        });

        // See if we have 1 or 2 solutions.
        if (dist === radius0 + radius1) {
            intersections.splice(1, 1);
            return intersections;
        }
        return intersections;
    }
}

export function angleBetween(angle: number, a1: number, a2: number): boolean {
    var min = Math.min(a1, a2);
    var max = Math.max(a1, a2);
    var d = angle - min;
    d = fModF(d, Math.PI * 2);
    if (d < 0) {
        d += Math.PI * 2;
    }
    return d <= max - min;
}

export function clamp(value: number, min: number, max: number): number {
    const t = value < min ? min : value;
    return t > max ? max : t;
}

export function clip(value: number, max: number): number {
    const t = value > max ? max : value;
    return t;
}

export function createCircle(r: number): Vector2[] {
    let segments = 32;
    if (r <= 0.002) {
        segments = 8;
    } else if (r > 0.02) {
        segments = Math.round(8 + (32 - 8) * (r - 0.002) / (0.02 - 0.002));
    }
    const points: Vector2[] = [];
    for (let i = 0; i < segments; i++) {
        points.push(
            {
                x: Math.cos(Math.PI * 2 * i / segments) * r,
                y: Math.sin(Math.PI * 2 * i / segments) * r,
            });
    }
    return points;
}
