import {SnappingModel} from '../snapping.model';
import {Drawing} from '../drawing.model';
import {Aabb2} from 'webcad/models';

export interface SnappingGridViewModel {
  snappingGrid: SnappingModel;
  state: boolean;
  plateAabb: Aabb2;
}

export const SnappingGridViewModelPipeline = (state: Drawing): SnappingGridViewModel => {
  return {
    snappingGrid: state.snappingModel,
    state: state.snapOptions.grid,
    plateAabb: state.plate.shapeWithHoles.aabb
  };
};
