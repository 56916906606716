import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { addVectors2, subVectors2, Vector2 } from "webcad/math";
import { QuadNode, QuadTreeElement } from "webcad/math/quad-node";
import { Aabb2, polylineContainsPoint } from "webcad/models";
import {
  getPerforationPositions,
  getStampList,
  Perforation,
  perforationAabb,
  PerforationModel,
} from "../../model/perforation.model";
import {
  getRotatedStampAabb,
  getRotatedStampShape,
  StampModel,
} from "../../model/stamp.model";
import { getDrawingPerforation, MevacoState } from "../../store/reducers";

export class PerforationHole implements QuadTreeElement {
  constructor(
    public readonly segmentPosition,
    public readonly perforation: Perforation,
    public readonly shapeIndex: number,
    public readonly aabb,
    public readonly stamp: StampModel
  ) {}
}

export interface HolesCollisionTrees {
  holes: QuadNode<PerforationHole>;
  borders: QuadNode<PerforationHole>;
}

@Injectable()
export class DrawingPerforationCollisionTreeProvider {
  public quadTree: Observable<HolesCollisionTrees> = null;
  constructor(private store: Store<MevacoState>) {
    this.quadTree = store.pipe(
      select(getDrawingPerforation),
      map((perforationModel: PerforationModel) => {
        const perfAabb = perforationAabb(perforationModel);
        const expandedAabb: Aabb2 = {
          min: subVectors2(perfAabb.min, { x: 0.5, y: 0.5 }),
          max: addVectors2(perfAabb.max, { x: 0.5, y: 0.5 }),
        };
        const result = {
          holes: new QuadNode<PerforationHole>(4, 0.005, expandedAabb),
          borders: new QuadNode<PerforationHole>(4, 0.005, expandedAabb),
        };
        for (
          let perfIndex = 0;
          perfIndex < perforationModel.perforation.length;
          perfIndex++
        ) {
          const perforation: Perforation =
            perforationModel.perforation[perfIndex];
          const rotCos = Math.cos(perforation.rotation);
          const rotSin = Math.sin(perforation.rotation);
          const stamps = getStampList(perforation, false);

          const perfPos = getPerforationPositions(perforation, true);

          for (let pi = 0; pi < perfPos.holes.length; pi += 2) {
            const stamp = stamps[perfPos.maskIndexes[pi / 2] || 0];
            if (stamp == null) continue;
            const position: Vector2 = {
              x: perfPos.holes[pi],
              y: perfPos.holes[pi + 1],
            };
            const rotatedPos: Vector2 = {
              x: rotCos * position.x - rotSin * position.y,
              y: rotSin * position.x + rotCos * position.y,
            };
            for (
              let shapeIndex = 0;
              shapeIndex < stamp.polylines.length;
              shapeIndex++
            ) {
              const shapeRotatedAabb = getRotatedStampAabb(
                stamp,
                shapeIndex,
                perforation.rotation
              );
              result.holes.insert({
                perforation,
                shapeIndex,
                aabb: {
                  min: addVectors2(rotatedPos, shapeRotatedAabb.min),
                  max: addVectors2(rotatedPos, shapeRotatedAabb.max),
                },
                segmentPosition: rotatedPos,
                stamp: stamp,
              });
            }
          }

          for (let pi = 0; pi < perfPos.border.length; pi += 2) {
            const stamp = stamps[perfPos.borderMaskIndexes[pi / 2]];
            if (stamp == null) continue;
            const position: Vector2 = {
              x: perfPos.border[pi],
              y: perfPos.border[pi + 1],
            };
            const rotatedPos: Vector2 = {
              x: rotCos * position.x - rotSin * position.y,
              y: rotSin * position.x + rotCos * position.y,
            };
            for (
              let shapeIndex = 0;
              shapeIndex < stamp.polylines.length;
              shapeIndex++
            ) {
              const shapeRotatedAabb = getRotatedStampAabb(
                stamp,
                shapeIndex,
                perforation.rotation
              );
              result.borders.insert({
                perforation,
                shapeIndex,
                aabb: {
                  min: addVectors2(rotatedPos, shapeRotatedAabb.min),
                  max: addVectors2(rotatedPos, shapeRotatedAabb.max),
                },
                segmentPosition: rotatedPos,
                stamp: stamp,
              });
            }
          }

          // for (let shapeIndex = 0; shapeIndex < perforation.stamp.polylines.length; shapeIndex++) {
          //   const shapeRotatedAabb = getRotatedStampAabb(perforation.stamp, shapeIndex, perforation.rotation);

          //   for (let pi = 0; pi < perfPos.holes.length; pi += 2) {
          //     const position: Vector2 = {
          //       x: perfPos.holes[pi],
          //       y: perfPos.holes[pi + 1],
          //     };
          //     const rotatedPos: Vector2 = {
          //       x: rotCos * position.x - rotSin * position.y,
          //       y: rotSin * position.x + rotCos * position.y
          //     };
          //     result.holes.insert({
          //       perforation,
          //       shapeIndex,
          //       aabb: {
          //         min: addVectors2(rotatedPos, shapeRotatedAabb.min),
          //         max: addVectors2(rotatedPos, shapeRotatedAabb.max),
          //       },
          //       segmentPosition: rotatedPos
          //     });
          //   }

          //   for (let pi = 0; pi < perfPos.border.length; pi += 2) {
          //     const position: Vector2 = {
          //       x: perfPos.border[pi],
          //       y: perfPos.border[pi + 1],
          //     };
          //     const rotatedPos: Vector2 = {
          //       x: rotCos * position.x - rotSin * position.y,
          //       y: rotSin * position.x + rotCos * position.y
          //     };
          //     result.borders.insert({
          //       perforation,
          //       shapeIndex,
          //       aabb: {
          //         min: addVectors2(rotatedPos, shapeRotatedAabb.min),
          //         max: addVectors2(rotatedPos, shapeRotatedAabb.max),
          //       },
          //       segmentPosition: rotatedPos
          //     });
          //   }
          // }
        }
        return result;
      }),
      shareReplay(1)
    );
  }
}

export function pickElementFomPerforationCollisionTree(
  quadTree: QuadNode<PerforationHole>,
  point: Vector2
): PerforationHole {
  return (
    quadTree &&
    quadTree.pickFirstElement(point, (hole, pt) => {
      const shape = getRotatedStampShape(
        hole.stamp,
        hole.shapeIndex,
        hole.perforation.rotation
      );
      return polylineContainsPoint(
        shape[0],
        subVectors2(pt, hole.segmentPosition)
      );
    })
  );
}
