<ng-template #tipContent>
  <ng-container [ngSwitch]="type | async">
    <h2 class="tooltip-header" *ngSwitchCase="TooltipType.error">
      <img
        class="tooltip-header-img"
        src="assets/tooltip-error-icon.svg"
        alt="error-icon"
      />
      <span class="tooltip-header-text tooltip-header-text--error">{{
        headerText | async
      }}</span>
    </h2>
    <h2 class="tooltip-header" *ngSwitchCase="TooltipType.warning">
      <img
        class="tooltip-header-img"
        src="assets/tooltip-warning-icon.svg"
        alt="warning-icon"
      />
      <span class="tooltip-header-text tooltip-header-text--warning">{{
        headerText | async
      }}</span>
    </h2>
  </ng-container>

  <div [innerHTML]="contentHtml | async"></div>
</ng-template>
<img
  *ngIf="(type | async) !== TooltipType.none"
  class="tooltip-indicator-icon"
  placement="left"
  src="{{ iconUrl | async }}"
  [ngbTooltip]="tipContent"
  container="body"
/>
