import {ExpandedMetalVisualizerModel} from "../expanded-metal.vizualizer";
import {MevacoPage} from "../../model/mevaco-page.model";
import {
  getExpandedMetalGltfHash,
  ProductType,
  Step
} from "../../model/product-configuration/product-configuration.model";

export class ExpandedMetalViewModelProvider {
  private viewModel: ExpandedMetalVisualizerModel = {
    gltfId: null,
    type: "",
    shape: null,
    lwd: 100,
    swd: 100,
    offsetX: 0,
    offsetY: 0,
    feedrate: 1,
    divingDepth: 1,
    totalThickness: 1,
    materialThickness: 1
  };

  getViewModel(newModel: MevacoPage): ExpandedMetalVisualizerModel {
    const show = newModel.productConfiguration.productType === ProductType.ExtendedMetals && (newModel.productConfiguration.step === Step.pattern || newModel.productConfiguration.step === Step.design);
    if ( !show ) {
      return null;
    }

    const stremoe = newModel.productConfiguration.configuration?.stremoe;
    if (!stremoe || !stremoe.vorschubMin) {
      return null;
    }

    const strand = +newModel.productConfiguration.extendedMetal.feedrate;
    const minS = +stremoe.vorschubMin.replace(',', '.');
    const maxS = +stremoe.vorschubMax.replace(',', '.');
    let t = 1.0;
    if (minS !== maxS) {
      t = (strand - minS) / (maxS - minS);
    }

    const totalThickness = stremoe.gDickeMin * (1.0 - t) + stremoe.gDickeMin * t;

    const divingDepth = ((stremoe.sizeS - +newModel.productConfiguration.extendedMetal.feedrate - +newModel.productConfiguration.material.thickness) / 2 * 1.1);

    const viewModel: ExpandedMetalVisualizerModel = show ? {
      gltfId: getExpandedMetalGltfHash(newModel.productConfiguration),
      type: stremoe.type,
      shape: newModel.drawing?.plate?.shapeWithHoles,
      lwd: stremoe.sizeL,
      swd: stremoe.sizeS,
      feedrate: +newModel.productConfiguration.extendedMetal.feedrate,
      offsetX: 0,
      offsetY: 0,
      totalThickness: totalThickness,
      divingDepth: divingDepth,
      materialThickness: +newModel.productConfiguration.material.thickness
    } : null;

    //not loaded yet
    if ( !newModel.gltfs[viewModel.gltfId] ) {
      return null;
    }

    if ( this.viewModel.gltfId !== viewModel.gltfId || this.viewModel.shape !== viewModel.shape) {
      this.viewModel = viewModel;
    }
    return this.viewModel;
  }
}
