import {ClosestSegments, MevacoPointerProvider} from '../providers/mevaco-pointer.provider';
import {PointerState} from 'webcad/collision';
import {DifferentiationTool} from './differentiation-tool.interface';
import {Vector3} from 'webcad/math';
import {select, Store} from '@ngrx/store';
import {
  getProductPerforationType,
  getSelectedOrFirstPerforationAreaOffset,
  getWallpaperName,
  getWallpaperZoom,
  MevacoState
} from '../store/reducers';
import {MevacoCollider} from '../providers/colliders/mevaco.collider';
import {UnderPointerType} from '../model/pointer-state.model';
import {PerforationAreaCollider} from '../providers/colliders/perforation-area.collider';
import {SelectPerforationArea, SelectPerforationAreaById, SetSelectedPerforationAreaOffset} from '../store/actions';
import {
  SelectAngleMeasurement,
  SelectAutomaticMeasurement,
  SelectHelpLine,
  SelectMeasurement,
  SetNodesSelection
} from '../store/actions/drawing.actions';
import {SetMountingsSelection} from '../store/actions/mountings.actions';
import {PerforationService} from "../services/perforation.service";
import {combineLatest, Observable, of, Subscription} from "rxjs";
import {Segment} from "webcad/models";
import {map, switchMap, withLatestFrom} from "rxjs/operators";
import {PerforationModeToolModel} from "../model/tool/perforation-mode-tool.model";
import {SetTool, SetToolOffset} from "../store/actions/tools.actions";
import {Vector2} from "webcad/math";
import {addVectors2, multiplyVector2byScalar, subVectors3} from "webcad/math";
import {PerforationType} from "../model/product-configuration/perforation-type.model";

export class PerforationModeTool extends DifferentiationTool {
  private PointerClick: (pointerPosition: Vector3, ctrl?: boolean) => void;
  private PointerDown: (pointerPosition: Vector3, ctrl?: boolean) => void;
  private PointerUp: (pointerPosition: Vector3, ctrl?: boolean) => void;
  private PointerMove: (pointerPosition: Vector3, ctrl?: boolean) => void;
  private ctrl: boolean;
  private shift = false;
  private lastObjectUnderPointer: MevacoCollider;
  private pointerState: PointerState;
  private subscriptions: Subscription[] = [];
  private dragStart: PointerState;
  private selectedAreaOffset: Vector2;
  private perforationType: PerforationType;

  constructor(private pointerController: MevacoPointerProvider,
              private store: Store<MevacoState>,
              private perforationService:PerforationService) {
    super();
    this.pointerController.pointerState.subscribe(v => {
      this.ctrl = v.ctrl;
      this.shift = v.shift;
      let objectUnderPointer: MevacoCollider = this.lastObjectUnderPointer;
      this.pointerState = v;
      if (this.pointerState) {
        objectUnderPointer = this.pointerState.intersection ? this.pointerState.intersection.collider as MevacoCollider : null;
        this.lastObjectUnderPointer = objectUnderPointer;
      }
      if (!objectUnderPointer) {
        this.PointerClick = this.onMouseClickOnGridOrNone;
        this.PointerDown = this.onMouseDownOnGridOrNone;
        this.PointerUp = this.onMouseUpOnGridOrNone;
        this.PointerMove = this.onMouseMoveOnGridOrNone;
      } else {
        switch (objectUnderPointer.objectType) {
          case UnderPointerType.NODE:
          case UnderPointerType.SEGMENT:
          case UnderPointerType.HELP_LINE:
          case UnderPointerType.AUTOMATIC_MEASUREMENT:
          case UnderPointerType.MEASUREMENT:
          case UnderPointerType.ANGLE_MEASUREMENT:
          case UnderPointerType.MOUNTING:
            this.PointerClick = this.onMouseClickOnGridOrNone;
            this.PointerDown = this.onMouseDownOnGridOrNone;
            this.PointerUp = this.onMouseUpOnGridOrNone;
            this.PointerMove = this.onMouseMoveOnGridOrNone;
            break;
          case UnderPointerType.PERFORATION_AREA:
            this.PointerClick = this.onMouseClickOnPerforationArea;
            this.PointerDown = this.onMouseDownOnPerforationArea;
            this.PointerUp = this.onMouseUpOnPerforationArea;
            this.PointerMove = this.onMouseMoveOnPerforationArea;
            break;
          default:
            this.PointerClick = this.onMouseClickOnGridOrNone;
            this.PointerDown = this.onMouseDownOnGridOrNone;
            this.PointerUp = this.onMouseUpOnGridOrNone;
            this.PointerMove = this.onMouseMoveOnGridOrNone;
            break;
        }
      }
    });
  }

  activate() {
    var wallpaperShape:Observable<Segment[][]> = combineLatest([
      this.store.pipe(select(getWallpaperName)),
      this.store.pipe(select(getProductPerforationType)),
    ] ).pipe(
      switchMap( ([wallpaperName, activePerforation]) => {
        if(activePerforation === 'wallpaper') {
          return this.perforationService.getWallpaperDxfByName(wallpaperName).pipe(
            map((response => {
              if (!!response.error) {
                console.error("Failed to load wallpaper shape: ", response.error)
                return []
              } else {
                return response.data;
              }
            }))
          );
        } else {
          return of([]);
        }
      })
    );

    this.subscriptions.push(
      combineLatest([
        wallpaperShape,
        this.store.pipe( select(getSelectedOrFirstPerforationAreaOffset) ),
        this.store.pipe( select(getWallpaperZoom) ),
      ]).subscribe( ([shape, offset, zoom]) => {
        var tool:PerforationModeToolModel={
          name: "PerforationModeToolModel",
          wallpaperShape: shape,
          offset:offset || {x: 0, y: 0},
          zoom:zoom
        }
        this.store.dispatch( new SetTool( tool ))
      })
    );

    this.subscriptions.push(
      this.store.pipe(select(getSelectedOrFirstPerforationAreaOffset))
        .subscribe( offset => this.selectedAreaOffset = offset)
    );
    this.subscriptions.push(
      this.store.pipe(select(getProductPerforationType))
        .subscribe( ap => this.perforationType = ap)
    );
  }

  isDirty(): boolean {
    return false;
  }

  cleanup(){
    this.subscriptions.forEach( s => s.unsubscribe());
    this.subscriptions = [];
    this.store.dispatch( new SetTool( null));
    this.dragStart = null;

  }

  onCancel() {
    this.removeSelections();
    this.cleanup();

  }

  onClosestSegmentsChanged(closestSegments: ClosestSegments) {
  }

  onConfirm() {
    this.cleanup();
  }

  onMouseClick(pointerState: PointerState) {
    this.PointerClick(pointerState.position, pointerState.ctrl);
  }

  onMouseDown(pointerState: PointerState) {
    this.dragStart = pointerState;
  }

  onMouseMove(pointerState: PointerState) {
    if(this.perforationType === 'wallpaper' && this.dragStart){
      var offset = multiplyVector2byScalar( subVectors3( pointerState.position, this.dragStart.position) ,1000);
      this.store.dispatch( new SetToolOffset( addVectors2(offset, this.selectedAreaOffset) ))
    }
  }

  onMouseUp(pointerState: PointerState) {
    if(this.perforationType === 'wallpaper' && this.dragStart){
      var offset = multiplyVector2byScalar( subVectors3( pointerState.position, this.dragStart.position) ,1000);
      this.store.dispatch( new SetSelectedPerforationAreaOffset( addVectors2(offset, this.selectedAreaOffset)))
    }
    this.dragStart = null;
  }

  reset() {
    this.onCancel();
  }

  onMouseClickOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseClickOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {
    this.removeSelections();
  }

  onMouseClickOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseClickOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseClickOnMounting(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseClickOnNode(pointerPosition: Vector3, ctrl?: boolean) {
  }


  onMouseClickOnSegment(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseDownOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseDownOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseDownOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseDownOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseDownOnMounting(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseDownOnNode(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseDownOnSegment(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseMoveOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseMoveOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseMoveOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseMoveOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseMoveOnMounting(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseMoveOnNode(pointerPosition: Vector3, ctrl?: boolean) {
  }


  onMouseMoveOnSegment(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseUpOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseUpOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseUpOnHelpLine(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseUpOnMeasurement(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseUpOnMounting(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseUpOnNode(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseUpOnSegment(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseClickOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {
    const objectUnderPointer = (this.lastObjectUnderPointer as PerforationAreaCollider).object;
    this.store.dispatch(new SelectPerforationArea(objectUnderPointer));
  }

  onMouseDownOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {
    this.onMouseDownOnGridOrNone(pointerPosition, ctrl);
  }

  onMouseMoveOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {
  }

  onMouseUpOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean) {
  }

  private removeSelections() {
    this.store.dispatch(new SetNodesSelection([]));
    this.store.dispatch(new SetMountingsSelection([]));
    this.store.dispatch(new SelectPerforationAreaById(-1));
    this.removeSingleSelections();
  }

  private removeSingleSelections() {
    this.store.dispatch(new SelectHelpLine(null));
    this.store.dispatch(new SelectMeasurement(null));
    this.store.dispatch(new SelectAutomaticMeasurement(null));
    this.store.dispatch(new SelectAngleMeasurement(null));

  }
}
