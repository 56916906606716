import {MevacoPage} from '../../model/mevaco-page.model';
import {hasTooSmallSegment, margeTooSmallSegment} from '../../model/shape-with-holes';
import {Element} from '../../model/product-configuration/element.model';

export function shapeCorrectionReducer(state: MevacoPage, action: any): MevacoPage {
  const threshold = 0.00005;
  if ( state.productConfiguration.elements.find( el1 => hasTooSmallSegment(el1.shape, threshold)) ) {
    console.info('Detected very small segments');
    const newState: MevacoPage = {
      ...state,
      productConfiguration: {
        ...state.productConfiguration,
        elements: state.productConfiguration.elements.map<Element>( el2 => mergeTooSmallSegmentsInElement(el2))
      }
    };
    if ( !newState.productConfiguration.elements.find( el1 => hasTooSmallSegment(el1.shape, threshold)) ) {
      console.info('Very small segments ware successfully removed');
    }
    return  newState;
  } else {
    return state;
  }

}

function mergeTooSmallSegmentsInElement(element: Element): Element {
  if (hasTooSmallSegment(element.shape)) {
    return {
      ...element,
      shape: margeTooSmallSegment(element.shape)
    };
  } else {
    return element;
  }
}
