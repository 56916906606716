import {Component, OnDestroy, OnInit} from '@angular/core';
import {RedoState, UndoState} from '../../store/actions';
import {MevacoState} from '../../store/reducers';
import {Store} from '@ngrx/store';
import { TranslationProvider } from '../../providers/translation.provider';

@Component({
  selector: 'undo-redo',
  templateUrl: './undo-redo.component.html',
  styleUrls: ['./undo-redo.component.css']
})
export class UndoRedoComponent implements OnInit, OnDestroy {
  private keypressListener: (event: KeyboardEvent) => void;

  constructor(private store: Store<MevacoState>, private translationProvider: TranslationProvider) {
  }

  ngOnInit() {
    this.keypressListener = (event: KeyboardEvent) => {
      if (event.ctrlKey) {
        if (event.code === 'KeyY') {
          this.redo();
        } else if (event.code === 'KeyZ') {
          this.undo();
        }
      }
    };
    window.addEventListener('keypress', this.keypressListener);
  }

  ngOnDestroy() {
    window.removeEventListener('keypress', this.keypressListener);
  }

  undo() {
    this.store.dispatch(new UndoState());
  }

  redo() {
    this.store.dispatch(new RedoState());
  }

  translate(text: string, module: string = 'designer') {
    return this.translationProvider.translate(text, module);
  }


}
