import {Tool} from './tool.interface';
import {Vector3} from 'webcad/math';

export abstract class DifferentiationTool extends Tool {
  abstract onMouseDownOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseMoveOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseUpOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseClickOnGridOrNone(pointerPosition: Vector3, ctrl?: boolean);

  abstract onMouseDownOnNode(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseMoveOnNode(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseUpOnNode(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseClickOnNode(pointerPosition: Vector3, ctrl?: boolean);

  abstract  onMouseDownOnSegment(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseMoveOnSegment(pointerPosition: Vector3, ctrl?: boolean);
  abstract  onMouseUpOnSegment(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseClickOnSegment(pointerPosition: Vector3, ctrl?: boolean);

  abstract onMouseDownOnHelpLine(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseMoveOnHelpLine(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseUpOnHelpLine(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseClickOnHelpLine(pointerPosition: Vector3, ctrl?: boolean);

  abstract onMouseDownOnMeasurement(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseMoveOnMeasurement(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseUpOnMeasurement(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseClickOnMeasurement(pointerPosition: Vector3, ctrl?: boolean);

  abstract onMouseDownOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean);
  abstract  onMouseMoveOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseUpOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseClickOnAngleMeasurement(pointerPosition: Vector3, ctrl?: boolean);

  abstract onMouseDownOnMounting(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseMoveOnMounting(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseUpOnMounting(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseClickOnMounting(pointerPosition: Vector3, ctrl?: boolean);

  abstract onMouseDownOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseMoveOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseUpOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean);
  abstract onMouseClickOnPerforationArea(pointerPosition: Vector3, ctrl?: boolean);

}
