<div class="pattern-editor__container">
  <div class="pattern-editor__header">
    <h2 class="pattern-editor__title">
      {{ translate("Pattern Editor", "designer") }}
    </h2>

    <div class="btns-pattern-container">
      <button type="button" class="btn btn-hint">
        <img class="btn-hint-icon" src="assets/hint-icon.svg" />
        {{ translate("pattern tutorial", "designer") }}
      </button>
      <undo-redo></undo-redo>
      <button
        type="button"
        class="btn btn-pattern btn-reset"
        tooltipClass="custom-tooltip--small"
        placement="bottom-left"
        [ngbTooltip]="translate('pattern reset', 'designer')"
        (click)="resetCustomPattern()"
      ></button>
      <button
        type="button"
        class="btn btn-pattern btn-save"
        tooltipClass="custom-tooltip--small"
        placement="bottom-left"
        [ngbTooltip]="translate('pattern save', 'designer')"
        (click)="editorPopup = 'save'"
      ></button>
      <button
        type="button"
        class="btn btn-pattern btn-open"
        tooltipClass="custom-tooltip--small"
        placement="bottom-left"
        [ngbTooltip]="translate('pattern open', 'designer')"
        (click)="editorPopup = 'load'"
      ></button>
      <button type="button" class="btn btn-back" (click)="close()">
        <img class="btn-back-icon" src="assets/down-arrow.svg" alt="arrow" />
      </button>
    </div>

    <span
      class="message-visualizer-button"
      (click)="toggleMessVisualizer()"
      *ngIf="messageVisualizerStatus !== MessageVisualizerStatus.NONE"
      [class.message-visualizer-button--opened]="messageVisualizerOpened"
      [class.message-visualizer-button--error]="
        messageVisualizerStatus === MessageVisualizerStatus.ERROR
      "
      [class.message-visualizer-button--warning]="
        messageVisualizerStatus === MessageVisualizerStatus.WARNING
      "
    >
      <img
        src="assets/configurator-messages-icon.svg"
        *ngIf="!messageVisualizerOpened"
        alt="messages-icon"
        class="message-visualizer-button-img"
      />

      <div
        class="configurator-triangle"
        *ngIf="!messageVisualizerOpened"
        [class.configurator-triangle--error]="
          messageVisualizerStatus === MessageVisualizerStatus.ERROR
        "
        [class.configurator-triangle--warning]="
          messageVisualizerStatus === MessageVisualizerStatus.WARNING
        "
      ></div>

      <img
        *ngIf="messageVisualizerOpened"
        class="configurator-arrow"
        src="assets/down-arrow.svg"
        alt="arrow"
      />
    </span>
  </div>

  <div class="message-visualizer__wrapper" *ngIf="messageVisualizerOpened">
    <app-message-visualizer></app-message-visualizer>
  </div>

  <section class="pattern-editor__prebody">
    <p class="prebody__description">
      {{ translate("pattern editor description", "designer") }}
    </p>
    <div class="prebody__range-sliders">
      <ng-container *ngIf="data | async as customGeom">
        <ng-template #offsetXLabelText>
          <label class="slider-input__label">
            {{ translate("range x", "designer") }}
            <span class="slider-input__label--light">{{
              translate("in mm", "designer")
            }}</span>
          </label>
        </ng-template>
        <app-slider
          class="cp-slider mr-15"
          [customLabelText]="offsetXLabelText"
          [sliderControl]="offsetXControl"
          [options]="{
            floor: 5,
            ceil: 200,
            step:1,
            enforceStep: false,
            showSelectionBarFromValue: 0
          }"
        >
        </app-slider>

        <ng-template #offsetYLabelText>
          <label class="slider-input__label">
            {{ translate("range y", "designer") }}
            <span class="slider-input__label--light">{{
              translate("in mm", "designer")
            }}</span>
          </label>
        </ng-template>
        <app-slider
          class="cp-slider"
          [customLabelText]="offsetYLabelText"
          [sliderControl]="offsetYControl"
          [options]="{
            floor: 5,
            ceil: 200,
            step:1,
            enforceStep: false,
            showSelectionBarFromValue: 0
          }"
        >
        </app-slider>

        <!-- <div class="slider-input__container prebody__range-slider mr-15">
          <label class="slider-input__label range-slider__label">
            {{translate('range x' , 'designer')}}
            <span class="slider-input__label--light">{{translate('in mm' , 'designer')}}</span>
          </label>
          <debounce-slider
              class="slider-input range-input"
              [min]="5"
              [max]="200"
              [value]="offsetX | async"
              (valueChanged)="setOffsetX($event)">
          </debounce-slider>
        </div>
         <div class="slider-input__container prebody__range-slider ml-15">
          <label class="slider-input__label range-slider__label">
            {{ translate("range y", "designer") }}
            <span class="slider-input__label--light">{{
              translate("in mm", "designer")
            }}</span>
          </label>
          <debounce-slider
            class="slider-input range-input"
            [min]="5"
            [max]="200"
            [value]="offsetY | async"
            (valueChanged)="setOffsetY($event)"
          >
          </debounce-slider>
        </div>
      -->

        <div class="custom-pattern-hint custom-pattern-hint--range">
          <div
            class="custom-pattern-hint-img-wrapper"
            container="body"
            placement="left"
            container="body"
            [ngbTooltip]="translate('range sliders tooltip', 'designer')"
          >
            <img src="assets/hint-icon.svg" alt="hint" />
          </div>
        </div>
      </ng-container>
    </div>
  </section>

  <div class="pattern-editor__body" *ngIf="data | async as customGeom">
    <div class="pattern-editor__view-section">
      <div class="view-section__toggle-buttons">
        <div class="toggle-buttons__content">
          <div
            *ngFor="let stamp of customGeom.stumps; index as i"
            class="stamp-indicator"
          >
            <div
              class="stamp-index"
              [ngClass]="{
                'stamp-disabled': !stamp || !stamp.enabled,
                'stamp-active': (selectedStampIndex | async) == i
              }"
              (click)="selectStampIndex(i)"
            >
              {{ i + 1 }}
            </div>
            <mat-slide-toggle
              [id]="i"
              [checked]="stamp?.enabled"
              (change)="enableStamp(i)"
            ></mat-slide-toggle>
            <!--
            <div class="toggle">
              <mat-checkbox [id]="stamp+i" [checked]="stamp?.enabled" (change)='enableStamp(i)'>
              </mat-checkbox>
            </div>
            -->
          </div>
        </div>

        <!-- <div class="toggle-buttons__add-toggle-btn-container">
          <button class="add-toggle-btn">
            <img class="add-toggle-btn__img" src="assets/plus-icon.svg" alt="plus">
          </button>
        </div> -->
      </div>
      <div class="view-section__webcad-container" *ngIf="editorPopup === ''">
        <webcad-host [material]="PlateMaterialType.shape"></webcad-host>
      </div>
    </div>

    <div
      class="pattern-editor__configuration-section"
      *ngIf="customGeom.stumps[selectedStampIndex | async] as selectedStamp"
    >
      <div class="configuration-section__shape-panel">
        <div class="custom-pattern-dropdown-wrapper mb-15">
          <dropdown
            class="custom-pattern-dropdown"
            [model]="formTypeDropdown"
          ></dropdown>
          <div class="custom-pattern-hint custom-pattern-hint--dropdown">
            <div
              class="custom-pattern-hint-img-wrapper"
              container="body"
              placement="right"
              container="body"
              [ngbTooltip]="translate('form type dropdown tooltip', 'designer')"
            >
              <img src="assets/hint-icon.svg" alt="hint" />
            </div>
          </div>
        </div>
        <div class="custom-pattern-dropdown-wrapper mb-15">
          <dropdown
            class="custom-pattern-dropdown"
            [model]="formDropdown"
          ></dropdown>
          <div class="custom-pattern-hint custom-pattern-hint--dropdown">
            <div
              class="custom-pattern-hint-img-wrapper"
              container="body"
              placement="right"
              container="body"
              [ngbTooltip]="translate('form dropdown tooltip', 'designer')"
            >
              <img src="assets/hint-icon.svg" alt="hint" />
            </div>
          </div>
        </div>
        <div class="custom-pattern-dropdown-wrapper mb-15">
          <dropdown
            class="custom-pattern-dropdown"
            [model]="widthDropdown"
          ></dropdown>
          <div class="custom-pattern-hint custom-pattern-hint--dropdown">
            <div
              class="custom-pattern-hint-img-wrapper"
              container="body"
              placement="right"
              container="body"
              [ngbTooltip]="translate('width dropdown tooltip', 'designer')"
            >
              <img src="assets/hint-icon.svg" alt="hint" />
            </div>
          </div>
        </div>

        <ng-container *ngIf="showLengthDropdown | async">
          <div class="custom-pattern-dropdown-wrapper mb-15">
            <dropdown
              class="custom-pattern-dropdown"
              [model]="lengthDropdown"
            ></dropdown>
            <div class="custom-pattern-hint custom-pattern-hint--dropdown">
              <div
                class="custom-pattern-hint-img-wrapper"
                container="body"
                placement="right"
                container="body"
                [ngbTooltip]="translate('length dropdown tooltip', 'designer')"
              >
                <img src="assets/hint-icon.svg" alt="hint" />
              </div>
            </div>
          </div>
        </ng-container>

        <div class="slider-input__container rotation-input__container">
          <label class="slider-input__label">
            {{ translate("rotation", "designer") }}
            <span class="slider-input__label--light">{{
              translate("in degree", "designer")
            }}</span>
            <div class="custom-pattern-hint custom-pattern-hint--rotation">
              <div
                class="custom-pattern-hint-img-wrapper"
                container="body"
                placement="right"
                container="body"
                [ngbTooltip]="translate('rotation tooltip', 'designer')"
              >
                <img src="assets/hint-icon.svg" alt="hint" />
              </div>
            </div>
          </label>

          <div
            class="rotation-slider__wrapper"
            [ngStyle]="{ 'min-height': 80 + 'px' }"
          >
            <app-circular-slider
              [props]="{
                strokeWidth: 4,
                radius: 40
              }"
              [startAngle]="90"
              [value]="rotation | async"
              (valueChanged)="setRotation($event)"
            >
            </app-circular-slider>
          </div>
        </div>
      </div>

      <div class="configuration-section__placement-panem">
        <div class="slider-input__container placement-panem__slider">
          <!-- <label class="slider-input__label placement-panem__label">
            {{ translate("divide x", "designer") }}
            <span class="slider-input__label--light">{{
              translate("count per grid", "designer")
            }}</span>
          </label>
          <debounce-slider
            class="slider-input"
            [min]="1"
            [max]="10"
            [value]="offsetStampX | async"
            (valueChanged)="setStampOffsetX($event)"
          >
          </debounce-slider> -->

          <ng-template #offsetStampXLabelText>
            <label class="slider-input__label">
              {{ translate("divide x", "designer") }}
              <span class="slider-input__label--light">{{
                translate("count per grid", "designer")
              }}</span>
            </label>
          </ng-template>
          <app-slider
            class="cp-slider"
            [customLabelText]="offsetStampXLabelText"
            [sliderControl]="offsetStampXControl"
            [options]="{
              floor: 1,
              ceil: 10,
              showSelectionBarFromValue: 0
            }"
          >
          </app-slider>
        </div>

        <div
          class="slider-input__container placement-panem__slider custom-pattern-hint-placeholder"
        >
          <ng-template #offsetStampYLabelText>
            <label class="slider-input__label">
              {{ translate("divide y", "designer") }}
              <span class="slider-input__label--light">{{
                translate("count per grid", "designer")
              }}</span>
            </label>
          </ng-template>
          <app-slider
            class="cp-slider"
            [customLabelText]="offsetStampYLabelText"
            [sliderControl]="offsetStampYControl"
            [options]="{
              floor: 1,
              ceil: 10,
              showSelectionBarFromValue: 0
            }"
          >
          </app-slider>

          <!-- <label class="slider-input__label placement-panem__label">
            {{ translate("divide y", "designer") }}
            <span class="slider-input__label--light">{{
              translate("count per grid", "designer")
            }}</span>
          </label>
          <debounce-slider
            class="slider-input"
            [min]="1"
            [max]="10"
            [value]="offsetStampY | async"
            (valueChanged)="setStampOffsetY($event)"
          >
          </debounce-slider> -->

          <div class="custom-pattern-hint custom-pattern-hint--slider">
            <div
              class="custom-pattern-hint-img-wrapper"
              container="body"
              placement="left"
              container="body"
              [ngbTooltip]="translate('divide sliders tooltip', 'designer')"
            >
              <img src="assets/hint-icon.svg" alt="hint" />
            </div>
          </div>
        </div>

        <div class="slider-input__container placement-panem__slider">
          <ng-template #startXLabelText>
            <label class="slider-input__label">
              {{ translate("start x", "designer") }}
              <span class="slider-input__label--light">{{
                translate("share of grid", "designer")
              }}</span>
            </label>
          </ng-template>
          <app-slider
            class="cp-slider"
            [customLabelText]="startXLabelText"
            [sliderControl]="startXControl"
            [options]="{
              floor: 0,
              ceil: 3,
              step: 0.01,
              enforceStep: false,
              showSelectionBarFromValue: 0
            }"
          >
          </app-slider>

          <!-- <label class="slider-input__label placement-panem__label">
            {{ translate("start x", "designer") }}
            <span class="slider-input__label--light">{{
              translate("share of grid", "designer")
            }}</span>
          </label>
          <debounce-slider
            class="slider-input"
            [min]="0"
            [max]="3"
            [step]="0.01"
            [value]="startX | async"
            (valueChanged)="setStartX($event)"
          >
          </debounce-slider> -->
        </div>

        <div
          class="slider-input__container placement-panem__slider custom-pattern-hint-placeholder"
        >
          <!-- <label class="slider-input__label placement-panem__label">
            {{ translate("start y", "designer") }}
            <span class="slider-input__label--light">{{
              translate("share of grid", "designer")
            }}</span>
          </label>
          <debounce-slider
            class="slider-input"
            [min]="0"
            [max]="3"
            [step]="0.01"
            [value]="startY | async"
            (valueChanged)="setStartY($event)"
          >
          </debounce-slider> -->

          <ng-template #startYLabelText>
            <label class="slider-input__label">
              {{ translate("start y", "designer") }}
              <span class="slider-input__label--light">{{
                translate("share of grid", "designer")
              }}</span>
            </label>
          </ng-template>
          <app-slider
            class="cp-slider"
            [customLabelText]="startYLabelText"
            [sliderControl]="startYControl"
            [options]="{
              floor: 0,
              ceil: 3,
              step: 0.01,
              enforceStep: false,
              showSelectionBarFromValue: 0
            }"
          >
          </app-slider>

          <div class="custom-pattern-hint custom-pattern-hint--slider">
            <div
              class="custom-pattern-hint-img-wrapper"
              container="body"
              placement="left"
              container="body"
              [ngbTooltip]="translate('start sliders tooltip', 'designer')"
            >
              <img src="assets/hint-icon.svg" alt="hint" />
            </div>
          </div>
        </div>

        <div class="slider-input__container placement-panem__slider">
          <ng-template #endXLabelText>
            <label class="slider-input__label">
              {{ translate("end x", "designer") }}
              <span class="slider-input__label--light">{{
                translate("share of grid", "designer")
              }}</span>
            </label>
          </ng-template>
          <app-slider
            class="cp-slider"
            [customLabelText]="endXLabelText"
            [sliderControl]="endXControl"
            [options]="{
              floor: 0,
              ceil: 3,
              step: 0.01,
              enforceStep: false,
              showSelectionBarFromValue: 0
            }"
          >
          </app-slider>

          <!-- <label class="slider-input__label placement-panem__label">
            {{ translate("end x", "designer") }}
            <span class="slider-input__label--light">{{
              translate("share of grid", "designer")
            }}</span>
          </label>
          <debounce-slider
            class="slider-input"
            [min]="0"
            [max]="3"
            [step]="0.01"
            [value]="endX | async"
            (valueChanged)="setEndX($event)"
          >
          </debounce-slider> -->
        </div>

        <div
          class="slider-input__container placement-panem__slider custom-pattern-hint-placeholder"
        >
          <ng-template #endYLabelText>
            <label class="slider-input__label">
              {{ translate("end y", "designer") }}
              <span class="slider-input__label--light">{{
                translate("share of grid", "designer")
              }}</span>
            </label>
          </ng-template>
          <app-slider
            class="cp-slider"
            [customLabelText]="endYLabelText"
            [sliderControl]="endYControl"
            [options]="{
              floor: 0,
              ceil: 3,
              step: 0.01,
              enforceStep: false,
              showSelectionBarFromValue: 0
            }"
          >
          </app-slider>

          <!-- <label class="slider-input__label placement-panem__label">
            {{ translate("end y", "designer") }}
            <span class="slider-input__label--light">{{
              translate("share of grid", "designer")
            }}</span>
          </label>
          <debounce-slider
            class="slider-input"
            [min]="0"
            [max]="3"
            [step]="0.01"
            [value]="endY | async"
            (valueChanged)="setEndY($event)"
          >
          </debounce-slider> -->

          <div class="custom-pattern-hint custom-pattern-hint--slider">
            <div
              class="custom-pattern-hint-img-wrapper"
              container="body"
              placement="left"
              container="body"
              [ngbTooltip]="translate('end sliders tooltip', 'designer')"
            >
              <img src="assets/hint-icon.svg" alt="hint" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-custom-pattern-save
    *ngIf="editorPopup === 'save'"
    (close)="editorPopup = ''"
  >
  </app-custom-pattern-save>
  <app-custom-pattern-load
    *ngIf="editorPopup === 'load'"
    (close)="editorPopup = ''"
  >
  </app-custom-pattern-load>
</div>

<div class="pattern-editor__backdrop"></div>
