import {Vector2, crossVector2, subVectors2} from './vector2';

export interface Line2 {
    origin: Vector2;
    direction: Vector2;
}

export function getIntersectionScalar(thisLine:Line2, otherLine:Line2):number|null{
    const cross = crossVector2( thisLine.direction, otherLine.direction);
    if(cross===0){
        return null;
    } else {
        return crossVector2( subVectors2(otherLine.origin, thisLine.origin), otherLine.direction)/cross;
    }
}