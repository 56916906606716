import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {finalize, map, tap} from 'rxjs/operators';
import {WorkLoadService} from './work-load.service';

@Injectable()
export class WorkInterceptor implements HttpInterceptor {


  constructor(private workloadService: WorkLoadService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let ok: string;
    this.workloadService.incrementWorkload();
    return next.handle(req).pipe(
      tap(
        event => ok = event instanceof HttpResponse ? 'succeeded' : '',
        error => ok = 'failed',
      ),
      finalize(() => {
        this.workloadService.decrementWorkload();
      })
    );
  }

}
