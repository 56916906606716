<div class="image-panel">

  <div class="image-panel__tools">

    <div class="rotation-input__container">
      <label class="rotation-input__label">
        {{translate("picture rotation", 'designer')}}
        <span class="rotation-input__label--light">{{translate("in degree", 'designer')}}</span>
        <div class="hint hint--rotation">
          <div
          tooltipClass="custom-tooltip"
          class="hint__img"
          container="body"
          placement="right"
          container="body"
          [ngbTooltip]="translate('picture rotation tooltip' , 'designer')"
          >
          <img src="assets/hint-icon.svg" alt="hint">
          </div>
        </div>
      </label>

      <div class="rotation-input">
        <app-circular-slider
        [props]="{
          strokeWidth: 4,
          radius: 40
        }"
        [value]="rotationValue.toString()"
        (valueChanged)="rotationEvent($event)"
        [startAngle]="90">
      </app-circular-slider>
      </div>

  </div>

    <div class="slider__container">
      <div class="ngx-slider__wrapper">
        <app-slider class="custom-ngx-slider"
                    [customLabelText]="pictureSizeLabelTemplate"
                    [sliderControl]="pixelsPerCmControl"
                    [options]="sliderOneOptions"
        ></app-slider>
      </div>
    </div>

  </div>

  <div class="image-panel__upload-area" *ngIf="!imagePreview">
    <form (ngSubmit)="submitFile(fileChosen.files)" style="height: 100%">
      <label class="upload-area"
      #dragArea
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      >
        <div class="upload-area-content">
          <div class="upload-area-img-wrapper">
              <div class="upload-area-img"></div>
          </div>
          <div class="upload-area-description">
            <span class="upload-area-text">{{translate('upload area description', 'designer')}}</span>
          </div>
        </div>
        <input type="file" name="upload-file" (change)="submitFile(fileChosen.files)" #fileChosen style="display: none;">
      </label>
    </form>
  </div>

  <div class="image-panel" [ngStyle]="{'display': imagePreview ? 'block' : 'none'}">
    <div class="image-panel__preview" *ngIf="imagePreview" #imgd>
      <svg viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg" version="1.1" style="position:absolute; inset: 0">
        <g id="viewport">
          <image #svgImg [attr.xlink:href]="imagePreview" class="preview"> </image>
          <g perfaction_plates [attr.transform]="'translate(0 ' + this.imageHeight +')'" ></g>
        </g>
      </svg>
      <div class="image-panel__image-tools">
        <app-perfaction-image-tools
        [imageName]="fileName"
        (deleteImageEvent)="onConfirmDeleteImage(removeModal)"
        >
        </app-perfaction-image-tools>
      </div>
    </div>
  </div>

</div>

<ng-template #removeModal let-modal>
  <div class="dialog-modal-header dialog-modal-header--small">
    <div class="dialog-modal-title-container">
      <div class="dialog-modal-title-content">
        <h4 class="dialog-modal-title dialog-modal-title--small" id="modal-basic-title">
          <span>{{translate("Remove perfaction title", 'designer')}}</span>
        </h4>
      </div>
    </div>

  <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

  <div class="dialog-modal-body dialog-modal-body--small" >
    <p class="dialog-modal-description--small">{{translate("Remove perfaction desc", 'designer')}}</p>
  </div>

  <div class="dialog-modal-footer dialog-modal-footer--small dialog-modal-footer--one-btn" >
    <button type="button" class="btn btn-primary dialog-modal-btn--small mr-15" (click)="modal.close(true)">{{translate("Yes")}}
    </button>
    <button type="button" class="btn btn-outline-primary dialog-modal-btn--small" (click)="modal.close(false)">{{translate("No")}}
    </button>
  </div>

</ng-template>


<ng-template #pictureSizeLabelTemplate>
  <label class="slider__label">
        <span class="label__text">{{
          translate("picture pixels per cm", "designer")
          }}</span>
    <div class="label__hint">
      <div
        tooltipClass="custom-tooltip"
        class="hint"
        container="body"
        placement="right"
        container="body"
        [ngbTooltip]="
              translate('perfaction picture pixels per cm tooltip', 'designer')
            "
      >
        <img src="assets/hint-icon.svg" alt="hint" />
      </div>
    </div>
  </label>

</ng-template>


