import {basicSnapOptions, SnapOptions} from '../../model/snap-options.model';
import {MevacoPageActions} from '../actions';
import {
  SET_MEASUREMENT_VISIBILITY, SET_PERFORATION_AREA_VISIBILITY, SET_PERFORATION_COLLIDER,
  SET_SNAP_OPTION_EDGE,
  SET_SNAP_OPTION_GRID, SET_SNAP_OPTION_HELP_LINE, SET_SNAP_OPTION_MOUNTINGS,
  SET_SNAP_OPTION_NODE,
  SET_SNAP_OPTIONS
} from '../actions/snap-options.actions';


export function snapOptionsReducer(state: SnapOptions = basicSnapOptions, action: MevacoPageActions): SnapOptions {
  switch (action.type) {
    case SET_SNAP_OPTIONS:
      return {
        ...action.payload
      };
    case SET_SNAP_OPTION_EDGE:
      return {
        ...state,
        edges: action.payload
      };
    case SET_SNAP_OPTION_GRID:
      return {
        ...state,
        grid: action.payload
      };
    case SET_SNAP_OPTION_NODE:
      return {
        ...state,
        nodes: action.payload
      };
    case SET_SNAP_OPTION_HELP_LINE:
      return {
        ...state,
        helpLines: action.payload
      };
    case SET_SNAP_OPTION_MOUNTINGS:
      return {
        ...state,
        mountingHoles: action.payload
      };
    case SET_MEASUREMENT_VISIBILITY:
      return {
        ...state,
        measurements: action.payload
      };
    case SET_PERFORATION_COLLIDER:
      return {
        ...state,
        perforationCollider: action.payload
      };
    case SET_PERFORATION_AREA_VISIBILITY:
      return {
        ...state,
        perforationArea: action.payload
      };
  }
  return state;
}
