import { PointerCollider } from "webcad/collision";
import { Pointer } from "webcad/collision/pointer";
import { PointerIntersection } from "webcad/collision/pointer-intersection";
import { sqrDistanceVector2, Vector2 } from "webcad/math";
import {
  aabbOfPolyline,
  isPointInAabb2,
  movePolyline,
  projectPointToPolyline,
} from "webcad/models";
import { MountingAttachment, scaleMounting } from "../../model/mounting.model";
import { UnderPointerType } from "../../model/pointer-state.model";
import { ColliderPriority } from "./collider-priorities";
import { CursorType } from "./cursor.type";
import { MevacoCollider } from "./mevaco.collider";

export interface MountingIntersection extends PointerIntersection {
  object: MountingAttachment;
}

export class MountingCollider implements PointerCollider, MevacoCollider {
  priority = ColliderPriority.MOUNTING;
  cursorType: CursorType;
  objectType: UnderPointerType;
  name = "Mounting";

  public get object(): any {
    return this.mounting;
  }

  constructor(public mounting: MountingAttachment) {
    this.objectType = UnderPointerType.MOUNTING;
    this.cursorType = CursorType.point;
  }

  compare(other: PointerCollider): boolean {
    return (
      other instanceof MountingCollider && this.mounting === other.mounting
    );
  }

  getIntersection(pointer: Pointer, epsilon: number): MountingIntersection {
    if (this.mounting.mountingRef.form && this.mounting.mountingRef.width) {
      const mountingPolyline = movePolyline(
        scaleMounting(
          this.mounting.mountingRef,
          this.mounting.shape,
          this.mounting.rotation
        ),
        this.mounting.position
      );
      const aaBb = aabbOfPolyline(mountingPolyline);
      const projection = projectPointToPolyline(
        mountingPolyline,
        pointer.onWorkingPlane
      );
      const distToPolyline = projection
        ? sqrDistanceVector2(pointer.onWorkingPlane, projection)
        : Number.MAX_VALUE;
      if (
        isPointInAabb2(pointer.onWorkingPlane, aaBb) ||
        distToPolyline <= epsilon * epsilon
      ) {
        const sqrminDist = sqrDistanceVector2(
          pointer.onWorkingPlane,
          this.mounting.position
        );
        let point: Vector2 = this.mounting.position;
        const pp = projection;
        if (pp) {
          const sqrDist = distToPolyline;
          if (sqrDist < sqrminDist) {
            point = pp;
          }
        }
        return {
          position: { x: point.x, y: point.y, z: 0 },
          collider: this,
          object: this.mounting,
        };
      } else {
        return null;
      }
    }
    return null;
  }
}
