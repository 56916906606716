const byteSize = 8;
const byteAndValue = 255;

export interface BitIndexMask {
    mask: Uint8Array | string,
    size: number,
    numOfValues: number
}
  

export function getValueFromMaskByIndex(mask: BitIndexMask, index: number): number {
    const bitShift = getOneIndexSize(mask.numOfValues);
    const bitPositionInMask = index * bitShift;
    const maskPosition = ~~(bitPositionInMask / byteSize);
    const bitPositionInByte = bitPositionInMask % byteSize;
    const foundByte = (mask.mask as Uint8Array)[maskPosition];
    const shiftedRes = toByte(foundByte >> bitPositionInByte);
    const reverseBitShift = byteSize - bitShift;
    let res = toByte(toByte(shiftedRes << reverseBitShift) >> reverseBitShift)
    if (bitPositionInByte + bitShift > byteSize) {
        const overflowByte = (mask.mask as Uint8Array)[maskPosition + 1]
        const overFlowReverseShift = byteSize * 2 - bitPositionInByte - bitShift;
        const shiftedOverflowByte = toByte(toByte(overflowByte << overFlowReverseShift) >> overFlowReverseShift);
        res +=  toByte(shiftedOverflowByte << (byteSize - bitPositionInByte))
    }
    if (res >= mask.numOfValues) {
        debugger;
    }
    return res;
}

const getOneIndexSize = (numOfValues: number) => Math.floor(Math.log2(numOfValues - 1)) + 1

const toByte = (num: number) => (num & byteAndValue) >>> 0;